import _ from 'lodash';
import pluralize from 'pluralize';
import { createAction } from 'redux-act';

export const toggleTracklistVisibility = createAction(
  'PLAYER_TOGGLE_TRACKLIST_VISIBILITY'
);
export const setWaveformVisibility = createAction(
  'PLAYER_WAVEFORM_VISIBILITY_SET',
  bool => ({
    waveformVisibility: bool
  })
);

/**
 * [routeToSignInModal] - Generates a `history` object that redirects the
 * browser to login..
 * @param {string} audioType the type of audio resource that the player was
 * attempting to play
 * @returns {import('history').LocationDescriptor} a history object that represents a redirect
 */
export const routeToSignInModal = audioType => {
  const humanReadableAudioType = pluralize(audioType.toLowerCase());
  return {
    pathname: '/sign-in',
    state: {
      modal: true,
      formError: `Sign in for access to On Demand ${humanReadableAudioType}.`
    }
  };
};
