import { player } from 'hooks/useProtonPlayer';

import { TableColumn, TableSortDirection } from './TableTypes';

/**
 *  [onAudioTableDoubleClick] - handles playing a track on row double click provided the
 *  params below are provided
 *
 *  @param {object} clickArgs REQUIRED currently the default as provided by react-virtualized.
 *   Object must include event and rowData keys.
 *  @param {array} ignoreSelectors OPTIONAL any elements in a table row you want to ignore
 *   the double click event
 */

export const onAudioTableDoubleClick = ({
  clickArgs,
  ignoreSelectors = [],
  queueAudio,
  queueIndex
}) => {
  const { event, rowData } = clickArgs;

  // For each ignored selector, check to see if double click ocurred on
  // selector.  I.e. don't play audio if double clicking on a dropdown or play button
  const ignoreSelectorClicked = ignoreSelectors.some(selector => {
    // currentTarget = audio table row (track row / mix row)

    // 1st, check to see if clicked event.target is even within the audio table row
    // portal elements spawned from audio table row still trigger double click event,
    // but we don't want them to toggle the audio
    if (!event.currentTarget.contains(event.target)) return true;

    // get element in audio table row for passed selector
    const element = event.currentTarget.querySelector(selector);

    // Determine if element clicked (event.target) was a child of the element
    // with this selector (or child element of selector)
    return element && element.contains(event.target);
  });

  if (ignoreSelectorClicked) {
    return;
  }

  if (queueAudio) {
    player.send('playAudio', { queue: queueAudio, index: queueIndex });
  } else {
    player.send('playAudio', { queue: [rowData] });
  }
};

export const TABLE_INPUT_SELECTOR = 'Table-search-input';

/**
 * [GetNextSortBy] - returns the next sort id and direction for a columns current sort state
 */

type GetNextSortBy = (
  column: TableColumn,
  currentSortId: string,
  currentSortDirection: TableSortDirection
) => {
  id: string;
  direction: TableSortDirection;
};

export const getNextSortBy: GetNextSortBy = (
  column,
  currentSortId,
  currentSortDirection
) => {
  const oppositeDirectionFromCurrent = currentSortDirection === 'ASC' ? 'DESC' : 'ASC';
  const defaultSortDirection = column.sortDescFirst ? 'DESC' : 'ASC';
  const columnSortId = column.sortId || column.id;
  const nextSortDirection =
    columnSortId === currentSortId ? oppositeDirectionFromCurrent : defaultSortDirection;

  return { id: columnSortId!, direction: nextSortDirection };
};
