import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { arrayToCommaList } from 'helpers/text';
import Highlight from './Highlight';

const getSeparator = ({ data, index, ampersand }) => {
  // NOTE: we show a comma prior to ampersand for better division of entities but omit it when only 2 items in list
  const ampersandComma = data.length > 2 ? ',' : '';

  return ampersand && index + 1 === data.length ? `${ampersandComma} & ` : ', ';
};
/**
 *  [CommaList] - take array of data and return comma separated list
 *  @param {object[] || string[]} data - should be array of strings unless passing urlAttribute or attribute,
 * then returns html with links
 *
 *  @param {string} [attribute] - if data format is an object, attribute defines the object key to use
 *  to form string with
 *  @param {string || func(object)} [urlAttribute] - if passed, used to wrap each element in url
 *  @param {number} [limit]
 *  @param {bool} [ampersand] - will add an ampersand before the last item in array instead of a comma
 *  @param {string || func(object)} [testId]
 *  @param {bool} [title] - will add title attribute to parent span for the returned comma list
 */

const CommaList = props => {
  const {
    data,
    title,
    attribute,
    urlAttribute,
    limit,
    ampersand,
    hit,
    highlightAttribute,
    testId
  } = props;
  if (!data) return null;

  const titleString = title
    ? arrayToCommaList(data, { ...props, limit: undefined })
    : undefined;

  if (urlAttribute) {
    const commaList = data.reduce((accum, element, i) => {
      const string = attribute ? element[attribute] : element;
      if (!string) return accum;

      const url =
        typeof urlAttribute === 'string' ? element[urlAttribute] : urlAttribute(element);

      if (limit && i === limit) return `${accum}, ...`;
      if (limit && i >= limit) return accum;

      const testIdAttribute =
        testId && typeof testId === 'function' ? testId(element) : testId;

      const separator = getSeparator({ data, ampersand, index: i });

      return [
        ...accum,
        <Fragment key={string}>
          {i > 0 ? separator : ''}
          <Link to={url} data-testid={testIdAttribute}>
            {highlightAttribute ? (
              <Highlight
                hit={hit}
                attributeValue={string}
                attribute={highlightAttribute}
              />
            ) : (
              string
            )}
          </Link>
        </Fragment>
      ];
    }, []);

    return <span title={titleString}>{commaList}</span>;
  }

  const commaList = data.reduce((accum, element, i) => {
    if (limit && i === limit) return `${accum}, ...`;
    if (limit && i >= limit) return accum;

    const string = attribute ? element[attribute] : element;
    const separator = getSeparator({ data, ampersand, index: i });
    const isComma = i > 0 ? separator : '';

    if (highlightAttribute) {
      return (
        <>
          {accum}
          {isComma}
          <Highlight hit={hit} attributeValue={string} attribute={highlightAttribute} />
        </>
      );
    }
    return `${accum}${isComma}${string}`;
  }, '');

  return <span title={titleString}>{commaList}</span>;
};

CommaList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])),
  attribute: PropTypes.string,
  urlAttribute: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  limit: PropTypes.number,
  ampersand: PropTypes.bool,
  hit: PropTypes.shape(),
  highlightAttribute: PropTypes.string,
  testId: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  title: PropTypes.bool
};

export default CommaList;
