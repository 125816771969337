/**
 * Migration11: There was a UI bug where users could select the WAV download filetype despite the button
 * being disabled. This resulted in users trying to download WAV files everytime they went to download tracks
 * despite the appearance of the filetype selector highlighting MP3 option.
 *
 * This was part of work for the following ticket: https://app.asana.com/0/1201745998598113/1201764841235051/f
 */

import { AUDIO_FILE_TYPES } from 'config/constants';

const rootMigration11 = state => {
  const { uiPersist, ...rest } = state;

  return {
    ...rest,
    uiPersist: {
      ...uiPersist,
      preferredAudioDownloadType: AUDIO_FILE_TYPES.MP3.VALUE
    }
  };
};

export default rootMigration11;
