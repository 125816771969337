import { v4 as uuidv4 } from 'uuid';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { AUDIO_TYPES } from 'config/constants';

// REF - searchableAttributes: ['track', 'artist', 'label']
const TRACKLIST_ATTRIBUTE = 'algolia_tracklist';

const findMatchingTracksFromMixes = mixes => {
  const tracklists = [];

  // for each mix with matching tracklist attributes
  mixes.hits.forEach(mix => {
    const tracks = [];
    const matchingTracks = _get(mix, `_highlightResult.${TRACKLIST_ATTRIBUTE}`) || [];

    // step through mix's tracks with matching attributes
    matchingTracks.forEach(track => {
      // initialize object for storing each matched field in tracklist (could be multiple)
      let matches = {};
      let trackData;

      // Step through the searchable tracklist attributes
      Object.keys(track).forEach(trackAttribute => {
        const matchObj = track[trackAttribute];

        if (['full', 'partial'].includes(matchObj.matchLevel)) {
          matches = {
            ...matches,
            [trackAttribute]: matchObj.value
          };

          // algolia doesnt provide information on what track (i.e. index within array) the matched field
          // was from, so need to find in mix by searching the matched value w/out <em> tags
          // Since 'track' is the most unique field returned, search against that field
          if (!trackData) {
            if (track.track) {
              const matchString = track.track.value.replace(/<em>|<\/em>/g, '');
              trackData = mix[TRACKLIST_ATTRIBUTE].find(
                _track => _track.track === matchString
              );
            } else if (track.artist) {
              // If no track try matching on artist ()
              const matchString = track.artist.value.replace(/<em>|<\/em>/g, '');
              trackData = mix[TRACKLIST_ATTRIBUTE].find(
                _track => _track.artist === matchString
              );
            }
          }
        }
      });

      if (!_isEmpty(matches)) {
        tracks.push({
          ...trackData,
          matched: matches,
          key: uuidv4() // unique iterator for UI rendering
        });
      }
    });

    // add tracklist tracks that were matched to tracklist array
    if (tracks.length > 0) {
      tracklists.push({
        mix: { ...mix, __audioId: mix.id, __audioType: AUDIO_TYPES.MIX },
        category: 'tracklists',
        key: uuidv4(), // unique iterator for UI rendering
        tracks
      });
    }
  });

  return tracklists;
};

export default findMatchingTracksFromMixes;
