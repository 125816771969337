import { createAction } from 'redux-act';

import * as api from 'api';

import { createAsyncActions } from './utilities';
import { ASYNC_OPERATIONS } from 'config/constants';

export const addTracks = createAction('TRACKS_ADD', tracks => ({ tracks }));

// Should pass along array of tracksIds
export const removeTracks = createAction('TRACKS_REMOVE', trackIds => ({
  trackIds
}));

export const downloadTrack = createAsyncActions('TRACK_DOWNLOAD', ASYNC_OPERATIONS.FETCH);

// ASYNC ACTIONS:
export const getTrack = id => dispatch =>
  api.getTrack(id).then(response => {
    if (response.status === 200 && response.data) {
      dispatch(addTracks([response.data]));
      return response.data;
    }
    throw response;
  });
