import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import format from 'date-fns/format';

import history from 'config/history';
import { addAlertMessage } from 'redux/actions/ui';

class PendingDeletion extends React.Component {
  componentDidMount() {
    this._warnUserPendingDelete();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user.delete_at && this.props.user.delete_at) {
      this._warnUserPendingDelete();
    }
  }

  _warnUserPendingDelete = () => {
    const { dispatchAddAlertMessage, user } = this.props;
    const deletionDate =
      user.delete_at && format(new Date(user.delete_at), "EEE, LLL do 'at' h:mm aaa");

    if (deletionDate) {
      dispatchAddAlertMessage({
        message: `This account is scheduled for permanent deletion on ${deletionDate}. If this is an error
          please cancel deletion in your account settings.`,
        timeout: false,
        id: 'AccountPendingDeletion',
        type: 'warning',
        meta: 'version',
        onDismiss: () => history.push('/account'),
        dismissText: 'Cancel in Settings'
      });
    }
  };

  render() {
    return null;
  }
}

PendingDeletion.propTypes = {
  user: PropTypes.shape({
    delete_at: PropTypes.string
  }).isRequired,
  dispatchAddAlertMessage: PropTypes.func
};

export default connect(state => ({ user: state.user }), {
  dispatchAddAlertMessage: addAlertMessage
})(PendingDeletion);
