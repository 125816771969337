import React from 'react';

import { DEFAULT_ICON_COLOR, ICON_PROP_TYPES } from './config';

const Archive = ({ color = DEFAULT_ICON_COLOR, size = 18, className, style }) => (
  <svg
    width={size * 1.076}
    height={size}
    viewBox="0 0 448 448"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
    style={style}
  >
    <title>Archive</title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <g
        id="archive-outline"
        transform="translate(16.000000, 16.000000)"
        stroke={color}
        strokeWidth="32"
      >
        <path
          d="M32,88 L32,344 C32.065896,366.064037 49.9359627,383.934104 72,384 L344,384 C366.064037,383.934104 383.934104,366.064037 384,344 L384,88"
          id="Path"
          strokeLinecap="round"
        ></path>
        <rect id="Rectangle" x="0" y="0" width="416" height="80" rx="28"></rect>
      </g>
    </g>
  </svg>
);

Archive.propTypes = ICON_PROP_TYPES;

export default Archive;
