import React, { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { requestAccountDeletion } from 'api';
import Modal from 'components/Modal';
import { TextInput, SubmitButton } from 'components/Modal/Input';
import { isProUser } from 'helpers/user';
import { showAlert } from 'redux/actions/ui';

const SUBTITLE = 'Enter your username below to begin the account deletion process.';

const INITIAL_VALUES = {
  username: ''
};

const VALIDATION_SCHEMA = Yup.object().shape({
  username: Yup.string().required('Username is required.')
});

type Values = typeof INITIAL_VALUES;

interface Props {
  onClose?: () => void;
}

export const DeleteAccountModal: React.FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.user);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  if (isProUser(user)) {
    return <CannotDeleteModal onClose={onClose} />;
  }

  if (showSuccessModal) {
    return <SuccessModal onClose={onClose} />;
  }

  const handleSubmit = (values: Values, formik: FormikHelpers<Values>) => {
    if (values.username !== user.username) {
      return formik.setErrors({ username: 'Please enter your exact username.' });
    }

    return requestAccountDeletion(user.user_id!)
      .then(() => setShowSuccessModal(true))
      .catch((e: unknown) => dispatch(showAlert(e)));
  };

  return (
    <Modal title="Delete Account" subtitle={SUBTITLE} onClose={onClose}>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
      >
        <Form>
          <TextInput type="text" name="username" label="Username" />
          <SubmitButton type="submit" danger>
            Request Deletion
          </SubmitButton>
        </Form>
      </Formik>
    </Modal>
  );
};

const CannotDeleteModal: React.FC<Props> = ({ onClose }) => (
  <Modal
    title="Sorry, Can't Do That!"
    subtitle={
      'Pro accounts cannot be deleted since they may have royalties accruing due to music released on the platforms.'
    }
    onClose={onClose}
  >
    <Modal.Text>
      For further information please contact us at{' '}
      <a href="mailto:support@protonradio.com">support@protonradio.com</a>.
    </Modal.Text>
  </Modal>
);

const SuccessModal: React.FC<Props> = ({ onClose }) => (
  <Modal
    title="Email Sent"
    subtitle="Please check your email for a confirmation link to confirm your account's deletion."
    onClose={onClose}
  >
    <Modal.Text>
      Once accepted your account will be scheduled for deletion <b>after 2 weeks</b>. Your
      account will <u>not</u> be scheduled for deletion until this link is clicked.
    </Modal.Text>
  </Modal>
);
