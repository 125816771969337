import TracksTable from 'components/TracksTable';
import TracksTableDesktop from 'components/TracksTable/TracksTableDesktop';
import PropTypes from 'prop-types';
import React from 'react';
import { AutoSizer } from 'react-virtualized';

const TracklistTableDesktop = ({ tracks, queueName, loading, columns, userSettings }) => {
  if (loading) return <TracksTable.Tombstones count={3} />;

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <TracksTableDesktop
          tracks={tracks}
          queueName={queueName}
          showOrder
          showOrderPlayButton
          fixedHeight
          userSettings={userSettings}
          tableProps={{
            className: 'Table TracksTable',
            noRowsRenderer: () => <TracksTable.Tombstones />,
            rowCount: tracks.length,
            width
          }}
          columns={columns}
        />
      )}
    </AutoSizer>
  );
};

TracklistTableDesktop.propTypes = {
  tracks: PropTypes.arrayOf(PropTypes.shape()),
  queueName: PropTypes.string,
  loading: PropTypes.bool
};

TracklistTableDesktop.SELECTORS = {
  ROW: TracksTableDesktop.SELECTORS.ROW
};

export default TracklistTableDesktop;
