import React from 'react';
import { VerifiedIcon } from 'components/Icons';
import Alert from '../Alert';

export const VerifyMFASuccessAlert = () => (
  <Alert.Content>
    <span>
      Your number has been succesfully verified!{' '}
      <VerifiedIcon style={{ margin: '0 0 -3px 3px' }} />
    </span>
  </Alert.Content>
);
