import React from 'react';

import { selectUser } from 'redux/selectors/user';
import {
  selectPromoTrackReaction,
  selectPromoTrackArtistIds
} from 'redux/selectors/promoReleases';
import { generateSelectorsFromTestIds } from 'helpers/utilities';
import { useQuery } from 'urql';
import { ReleaseDspQuery } from 'graphql/queries/release';
import { releaseStoreInfoButtonsTransform } from 'graphql/transforms';

import Button from 'components/Button';
import DownloadTrackButton, {
  DownloadButtonActions
} from 'components/Button/DownloadTrackButton';
import { BeatportIcon } from 'components/Icons';
import PromoArtistPickerButton from 'components/PromoArtistPickerButton';
import Flex from 'components/Flex';
import PromoFeedbackForm from 'components/PromoFeedbackForm';
import PromoTrackStarRating from 'components/PromoTrackStarRating';
import StarRating from 'components/StarRating';
import useCurrentUser from 'hooks/useCurrentUser';

import {
  Wrapper,
  Row,
  RatingContainer,
  RatingContainerInner,
  ActionsButtonGroup,
  Separator
} from './styled';
import Tooltip from 'components/Tooltip';
import styled from 'styled-components';
import { trackDownloadConfig } from 'helpers/tracks';
import { useAppSelector } from 'hooks/redux';
import { DownloadFileType } from 'config/constants';
import { Audio } from 'helpers/tracks';

const StyledTooltip = styled(Tooltip)`
  display: flex;
  justify-content: center;
`;

/**
 * [PromoReactionWidget] - Content to render within the track reaction popover and track action menu.
 * Allows user to rate tracks, change artist rating the track, provide feedback, and download
 */

interface PromoReactionWidgetProps extends DownloadButtonActions {
  track: Audio;
  format?: DownloadFileType;
  /* Whether to show Beatport/Spotify links in widget */
  showStoreLinks?: boolean;
  onHeightChange?: () => null;
  close?: () => void;
}

export const PromoReactionWidget = ({
  format,
  track,
  showStoreLinks = false,
  onDownload,
  onDownloadSuccess,
  onHeightChange,
  close
}: PromoReactionWidgetProps) => {
  const { user: currentUser } = useCurrentUser();
  const [{ data, fetching, error }] = useQuery({
    query: ReleaseDspQuery,
    variables: { id: track.release.id.toString() },
    pause: !track.release.id
  });
  const dspLinks = fetching || error ? null : releaseStoreInfoButtonsTransform(data);
  const beatport = dspLinks?.find(dsp => dsp.name === 'beatport');

  const { user, reaction, promoArtistIds } = useAppSelector(state => ({
    user: selectUser(state),
    reaction: selectPromoTrackReaction(state, track.id),
    promoArtistIds: selectPromoTrackArtistIds(state, {
      trackId: track.id,
      releaseId: track.release.id
    })
  }));

  const downloadFormat = trackDownloadConfig(currentUser, {
    track,
    user,
    format
  }).filetype;

  const multipleArtists = promoArtistIds.length > 1;
  const hasRating = reaction && reaction.rating;
  const hasOptimisticRating = reaction?.__optimistic?.rating;

  return (
    <Wrapper data-testid={PromoReactionWidget.TEST_IDS.CONTAINER}>
      {showStoreLinks && !!beatport && !fetching && (
        <Row>
          <ActionsButtonGroup data-testid={PromoReactionWidget.TEST_IDS.STORE_LINKS}>
            {beatport && (
              <Button
                to={beatport.url}
                newTab
                data-testid={PromoReactionWidget.TEST_IDS.STREAM_SPOTIFY}
                color={Button.COLORS.TRANSPARENT}
                expand
                prepend={() => <BeatportIcon />}
                text={beatport.title}
              />
            )}
          </ActionsButtonGroup>
        </Row>
      )}

      <Row $gap={true}>
        <RatingContainer data-testid={PromoReactionWidget.TEST_IDS.STAR_RATING}>
          <RatingContainerInner>
            <PromoTrackStarRating
              format={downloadFormat}
              size={StarRating.SIZE.LARGE}
              track={track}
            />{' '}
          </RatingContainerInner>
        </RatingContainer>

        <Separator />

        <StyledTooltip
          enabled={!hasRating}
          content="Rating required before you can download."
          triggerContainerElement={'div'}
        >
          <ActionsButtonGroup>
            <Flex>
              <DownloadTrackButton
                track={track}
                format={downloadFormat}
                onDownload={onDownload}
                onDownloadSuccess={() => {
                  onDownloadSuccess && onDownloadSuccess();
                  close && close();
                }}
                disabled={
                  !hasRating ||
                  ((!hasRating || !hasOptimisticRating) && reaction.__updating)
                }
              />
            </Flex>
          </ActionsButtonGroup>
        </StyledTooltip>
      </Row>

      <Row>
        <Flex.Item grow={0} shrink={0}>
          <PromoArtistPickerButton
            track={track}
            renderDirection={PromoArtistPickerButton.DIRECTIONS.DOWN}
            multipleArtists={multipleArtists}
          />
        </Flex.Item>

        <Flex.Item grow={1}>
          <PromoFeedbackForm
            autoFocus={false}
            onHeightChange={onHeightChange}
            track={track}
          />
        </Flex.Item>
      </Row>
    </Wrapper>
  );
};

PromoReactionWidget.TEST_IDS = {
  CONTAINER: 'PromoReactionWidget',
  STORE_LINKS: 'PromoReactionWidget-store-links',
  STAR_RATING: 'PromoReactionWidget-StarRating',
  BUY_BEATPORT: 'ActionMenuItem-buy-on-beatport',
  STREAM_SPOTIFY: 'ActionMenuItem-stream-on-spotify'
} as const;

PromoReactionWidget.SELECTORS = generateSelectorsFromTestIds(
  PromoReactionWidget.TEST_IDS
);

export default PromoReactionWidget;
