import React from 'react';
import {
  Formik,
  Form,
  FormikProps,
  FormikValues,
  FormikHelpers,
  FormikErrors
} from 'formik';

import styled from 'styled-components';

import Button from 'components/Button';

import { BREAKPOINTS } from 'config/constants';
import { displayAlertError } from 'helpers/alert';
import { FieldErrors, formatFieldErrors } from 'helpers/form';

import loadingIcon from 'images/icon-loading.svg';

/*
 * [ButtonLoading]
 * A standalone submit button that displays loading icon on submit
 *
 * @parma {type} Button type - must be specified as `button` | `reset` if not a child of form
 * @param {string} buttonText
 * @param {bool} isSubmitting
 */

// NOTE: size selected to prevent button size from growing while loading

const StyledSubmitButton = styled(({ ...props }) => <Button {...props} />)`
  // NOTE: we force button padding so that it matches input height since it will be inline with it
  // in many cases
  padding: 1.1rem 1rem 1rem 1rem;
  min-width: 136px;

  @media screen and (max-width: ${BREAKPOINTS.MD}px) {
    padding: 1.2rem 1.5rem 1rem !important;
    width: 100%;
  }
`;

type SubmitButtonProps = {
  buttonText: string;
  isSubmitting: boolean;
  disabled?: boolean;
  inline?: boolean;
  type?: 'submit' | 'button' | 'reset';
  onClick?: () => void;
};

export const ButtonLoading = (props: SubmitButtonProps) => {
  const { buttonText, inline, isSubmitting, type, disabled, onClick, ...rest } = props;

  return (
    <StyledSubmitButton
      color={Button.COLORS.PRIMARY}
      size={Button.SIZES.MEDIUM}
      disabled={isSubmitting || disabled}
      type={type || 'submit'}
      inline={inline}
      onClick={onClick}
      {...rest}
    >
      {buttonText}{' '}
      {isSubmitting && (
        <div className="ml-2">
          <img src={loadingIcon as string} alt="Pac Man loading icon" height={13} />
        </div>
      )}
    </StyledSubmitButton>
  );
};

const StyledForm = styled(Form)`
  margin-bottom: 1rem;
  position: relative;
`;

//  NOTE: If inline content is too long and wraps, the following handles
// spacing the button and inputs properly
const InlineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${BREAKPOINTS.SM}px) {
    > div {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // We only show inline for larger screens
  @media screen and (min-width: ${BREAKPOINTS.SM}px) {
    flex-direction: row;

    > div {
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

/*
 * [FormikWrapper]
 * Wrapper component to setup Formik-based form.
 */

export type RenderActionsProps<T> = Pick<
  FormikProps<T>,
  'isSubmitting' | 'setFieldValue' | 'submitForm'
>;

type FormikWrapperProps<TValues extends FormikValues> = {
  buttonText?: string;
  buttonTestId?: string;
  children: React.ReactNode | ((props: FormikProps<TValues>) => React.ReactNode);
  className?: string;
  'data-testid'?: string;
  hideButton?: boolean;
  initialValues: TValues;
  inline?: boolean;
  disabled?: boolean;
  // TODO: Talk to Ria on how to best name this considering already defined in FormGroupRow.Formik
  onSubmit: (values: TValues, actions: FormikHelpers<TValues>) => Promise<void>;
  renderActions?: (props: RenderActionsProps<TValues>) => React.ReactNode;
  validationSchema?: object;
};

function isFieldErrorResponse(
  e: unknown
): e is { response: { data: { error: FieldErrors } } } {
  return !!(
    e &&
    typeof e === 'object' &&
    'response' in e &&
    e.response &&
    typeof e.response === 'object' &&
    'data' in e.response &&
    e.response.data &&
    typeof e.response.data === 'object' &&
    'error' in e.response.data &&
    e.response.data.error &&
    typeof e.response.data.error === 'object'
  );
}

function FormikWrapper<T extends FormikValues>(props: FormikWrapperProps<T>) {
  const {
    buttonText = 'Save',
    buttonTestId = 'FormikWrapper-submit-button',
    children,
    className,
    'data-testid': testId,
    hideButton,
    initialValues,
    inline,
    disabled,
    onSubmit,
    renderActions,
    validationSchema
  } = props;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values: T, actions: FormikHelpers<T>) => {
        return onSubmit(values, actions).catch((error: unknown) => {
          if (!error || typeof error !== 'object' || '__SILENCE__' in error) return;

          if (isFieldErrorResponse(error)) {
            const fieldErrors = error.response.data.error;
            actions.setErrors(formatFieldErrors(fieldErrors) as FormikErrors<T>);
          } else {
            // if no field specific errors are available to display, we still want to make sure
            // the error is displayed to the user.
            displayAlertError(error);
          }
        });
      }}
    >
      {formikProps => {
        const { isSubmitting, setFieldValue, submitForm, handleSubmit } = formikProps;
        const formProps = { ...formikProps, isDisabled: disabled };

        const FormContent = (
          <>
            {typeof children === 'function' ? children(formProps) : children}

            {renderActions
              ? renderActions({ isSubmitting, setFieldValue, submitForm })
              : !hideButton && (
                  <div>
                    {/* NOTE: Wrapping 'div' is required to prevent button from growing in height when
                  button is inline and an input error is displayed */}
                    <FormikWrapper.SubmitButton
                      isSubmitting={isSubmitting}
                      buttonText={buttonText}
                      inline={inline}
                      data-testid={buttonTestId}
                    />
                  </div>
                )}
          </>
        );

        return (
          <StyledForm className={className} onSubmit={handleSubmit} data-testid={testId}>
            {inline ? <InlineWrapper>{FormContent}</InlineWrapper> : FormContent}
          </StyledForm>
        );
      }}
    </Formik>
  );
}

FormikWrapper.SubmitButton = ButtonLoading;

export default FormikWrapper;
