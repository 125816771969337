import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isFallbackFlyer } from 'helpers/shows';

const MixHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const MixImage = styled.img`
  border-radius: 0.2rem;
  height: 6rem;
  width: auto;
`;

const MixContent = styled.div`
  color: #7d7d7d;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 1rem;
`;

const MixTitle = styled.div`
  color: #333333;
  font-size: 2rem;
  margin-bottom: 0.1rem;
`;

const MixSubtitle = styled.div`
  font-size: 1.4rem;
`;

const MixMeta = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 1;
`;

// NOTE: This is for the mix header for the **ACTION MENU**

const MixHeader = ({ show_name, show_image, artist_name, mix_date, mix_info }) => (
  <MixHeaderContainer>
    {!isFallbackFlyer(show_image) && (
      <MixImage alt="Show Flyer" src={encodeURI(show_image)} />
    )}
    <MixContent>
      <MixTitle>{artist_name}</MixTitle>
      <MixSubtitle>{show_name}</MixSubtitle>
      <MixMeta>
        {mix_date} • Part {mix_info}
      </MixMeta>
    </MixContent>
  </MixHeaderContainer>
);

MixHeader.propTypes = {
  show_name: PropTypes.string,
  artist_name: PropTypes.string,
  show_image: PropTypes.string,
  mix_date: PropTypes.string,
  mix_info: PropTypes.string
};

export default MixHeader;
