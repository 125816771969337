import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ActionMenuItem, FilePickerActionMenu } from 'components/ActionMenu';
import { Badge } from '@protonradio/proton-ui';
import Icon from 'components/Icon';
import { ChevronIcon } from 'components/Icons';
import useToggle from 'hooks/useToggle';

import {
  getCurrentDownloadLocationOption,
  getDownloadLocationOptions
} from 'helpers/downloadLocation';

import { downloadTrack as downloadTrackAction } from 'redux/actions/tracks';

import { Audio } from 'helpers/tracks';
import { COLORS, DownloadFileType } from 'config/constants';
import { trackDownloadConfig } from 'helpers/tracks';
import useCurrentUser from 'hooks/useCurrentUser';
import { useAppSelector } from 'hooks/redux';
import { selectUser } from 'redux/selectors/user';
import { useHistory } from 'react-router-dom';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';
import { gql } from 'urql';
import EllipsisLoader from 'components/Loader/EllipsisLoader';

const SelectFileType = styled.div.attrs(() => ({
  role: 'button',
  tabIndex: 0
}))`
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0 2rem;
  position: absolute;
  right: 0;
  top: 0;
`;

const UpdateUserSettingsMutation = gql(`
  mutation updateUserSettings ($input: UserSettingUpdateInput!) {
    userSettingUpdate (input: $input) {
      errorDetails {
        message
      }
      userSetting {
        enabled
        settingType
        value
      }
    }
  }
`);

interface DownloadTrackActionItemProps {
  track: Audio;
  closeParentMenu?: () => void;
  closeOnNavigation?: boolean;
}

const DownloadTrackActionItem = ({
  track,
  closeParentMenu,
  closeOnNavigation
}: DownloadTrackActionItemProps) => {
  const [isDownloading, setDownloading] = useState(false);
  const [{ fetching, hasErrors }, updateUserSettings] = useMutationWithAlert(
    UpdateUserSettingsMutation
  );
  const user = useAppSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const { user: currentUser } = useCurrentUser();

  const {
    isOpen: isDownloadFileMenuOpen,
    open: openDownloadFileMenu,
    close: closeDownloadFileMenu
  } = useToggle();

  const downloadLocationOptions = getDownloadLocationOptions({
    dropbox_oauth: user.dropbox_oauth,
    google_oauth: user.google_oauth
  });
  const currentLocationOption = getCurrentDownloadLocationOption(currentUser, user);
  const [trackDownloadSettings, setTrackDownloadSettings] = useState(
    trackDownloadConfig(currentUser, { track, user })
  );

  const handleDownloadTrack = () => {
    setDownloading(true);

    dispatch(
      downloadTrackAction.call(trackDownloadConfig(currentUser, { track, user }), {
        onFinally: () => {
          setDownloading(false);
          closeParentMenu && closeParentMenu();
        }
      })
    );
  };

  const handleSettingsUpdate = (settingType: string, value: string) => {
    // Optimistically update the track download settings
    if (settingType === 'DOWNLOAD_FORMAT') {
      setTrackDownloadSettings({
        ...trackDownloadSettings,
        filetype: value as DownloadFileType
      });
    }

    updateUserSettings({
      input: {
        userId: currentUser.id,
        settingType,
        value,
        enabled: true
      }
    });

    if (hasErrors && settingType === 'DOWNLOAD_FORMAT') {
      // Rollback the optimistically updated track download settings
      setTrackDownloadSettings(trackDownloadConfig(currentUser, { track, user }));
    }
  };

  return (
    <>
      <ActionMenuItem
        icon={<Icon type={Icon.TYPES.DOWNLOAD} width={20} />}
        onClick={handleDownloadTrack}
        disabled={isDownloading}
        data-testid={DownloadTrackActionItem.TEST_IDS.BUTTON}
        closeMenu={close}
      >
        <div>{isDownloading ? 'Downloading...' : 'Download'} </div>
        <SelectFileType
          onClick={e => {
            e.stopPropagation();
            openDownloadFileMenu();
          }}
          data-testid={DownloadTrackActionItem.TEST_IDS.FILE_PICKER}
        >
          <Badge variant="secondary">
            {fetching ? (
              <EllipsisLoader />
            ) : (
              <>
                {`${String(trackDownloadSettings.filetype).toUpperCase()} `}{' '}
                <ChevronIcon
                  direction={ChevronIcon.DIRECTIONS.DOWN}
                  size={6}
                  color={COLORS.GRAY_DARK}
                />
              </>
            )}
          </Badge>
        </SelectFileType>
      </ActionMenuItem>

      {/* TRACK DOWNLOAD FILE SELECT MENU */}
      <FilePickerActionMenu
        isOpen={isDownloadFileMenuOpen}
        close={closeDownloadFileMenu}
        closeOnNavigation={closeOnNavigation}
        downloadLocationOptions={downloadLocationOptions}
        currentLocationOption={currentLocationOption}
        track={track}
        handleSettingsUpdate={handleSettingsUpdate}
      />
    </>
  );
};

DownloadTrackActionItem.TEST_IDS = {
  BUTTON: 'DownloadTrackActionItem-react-download-track-action-item',
  FILE_PICKER: 'DownloadTrackActionItem-file-picker'
};

export default DownloadTrackActionItem;
