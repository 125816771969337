export const RELEASE_INITIAL_STATE = {
  id: null,
  name: '',
  slug: '',
  date: '',
  catalog: '',
  description: '',
  beatport_exclusive_period: null,
  release_mode: null,
  cover_art: {
    original: {},
    large: {},
    medium: {},
    small: {},
    thumb: {}
  },
  pre_order_date: '',
  label: {
    id: null,
    name: '',
    countries: [],
    slug: '',
    logo: {
      large: {},
      medium: {},
      small: {},
      thumb: {},
      original: {}
    },
    roster_mixes_blocked: null,
    proton_compilations_mixed_blocked: null,
    proton_compilations_unmixed_blocked: null
  },
  preorder_date: '',
  track_ids: [],
  headline_artists: [],
  genres: {},
  artist_roles: []
};
