import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import TextHeader from 'components/TextHeader';
import Well from 'components/Well';
import { generateSelectorsFromTestIds } from 'helpers';

const NoDataContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 60rem;
  min-height: 15rem;
  text-align: center;
`;

/**
 * [NoDataDisplay]
 * Used when there is no data to display on a page. Handle formatting of text and additional children content
 *
 * @prop {string} text text to be formatted, centered and displayed
 * @prop {bool} [well=true] if content should be displayed within a Well component.
 * @prop {node} children pass things such as call to action buttons
 */

const NoDataDisplay = ({ text, well = true, children, className }) => {
  const DisplayContent = (
    <NoDataContainer
      data-testid={NoDataDisplay.TEST_IDS.CONTENT}
      className={well ? '' : className}
    >
      {text && <NoDataDisplay.Text>{text}</NoDataDisplay.Text>}

      {children && <div>{children}</div>}
    </NoDataContainer>
  );

  if (well) {
    return <Well className={className}>{DisplayContent}</Well>;
  }

  return DisplayContent;
};

NoDataDisplay.Text = ({ children }) => (
  <TextHeader type={TextHeader.TYPES.LARGE} weight={TextHeader.WEIGHTS.NORMAL}>
    <div style={{ textAlign: 'center' }}>{children}</div>
  </TextHeader>
);

NoDataDisplay.TEST_IDS = {
  CONTENT: 'NoDataDisplay-content'
};

NoDataDisplay.SELECTORS = generateSelectorsFromTestIds(NoDataDisplay.TEST_IDS);

NoDataDisplay.propTypes = {
  text: PropTypes.string,
  well: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
};

export default NoDataDisplay;
