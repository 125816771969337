import { EventEmitter } from 'events';

import type { AudioQuality, PlayerState } from './AudioPlayer';
import { store } from 'config/store';
import 'soundmanager2';
import toPlaylist, { QueuedMix, QueuedTrack } from './toPlaylist';
import history from 'config/history';
import { routeToSignInModal } from 'redux/actions/player';
import { logError } from 'helpers/logError';

const SOUND_ID = 'FIREFOX_PLAYER';

soundManager.setup({
  useHTML5Audio: true
});

type SMSound = soundmanager.SMSound;

export default class FirefoxStreamingPlayer extends EventEmitter {
  queue: QueuedMix[] | QueuedTrack[];
  index: number;
  state: PlayerState;
  sound: SMSound | null;
  stalled: boolean;
  playheadTime: number;
  clearOnPosition:
    | ((id?: string, offset?: number, callback?: () => void) => SMSound | undefined)
    | null;

  constructor(state: { queue?: QueuedMix[] | QueuedTrack[]; index?: number }) {
    super();

    this.queue = state.queue || [];
    this.index = state.index || 0;
    this.state = 'STOPPED';
    this.sound = null;
    this.stalled = false;
    this.clearOnPosition = null;
  }

  serialize() {
    return {
      queue: this.queue,
      index: this.index
    };
  }

  send(messageType: string, payload: unknown): void {
    if (this[messageType]) {
      setTimeout(() => {
        this[messageType](payload);
      }, 0);
    }
  }

  _play(): void {
    const reduxState = store.getState();
    const { jwt } = reduxState.token;

    if (!jwt) {
      const firstAudioType = this.queue[0].meta.type;
      return history.push(routeToSignInModal(firstAudioType));
    }

    const firstTrack = this.queue[this.index];

    this._playTrack(firstTrack);

    this.state = 'PLAYING';

    this.emit('PLAYBACK_STARTED', { queue: this.queue, index: this.index });
    this.emit('STATE_CHANGED', this.state);
  }

  play(payload: {
    queue: [];
    index?: number;
    audioQuality: AudioQuality;
    initialPosition?: number;
  }): void {
    const { queue, index = 0 } = payload;
    const reduxState = store.getState();

    const playlist = toPlaylist(reduxState, queue, payload.audioQuality);
    this.queue = playlist;
    this.index = index;

    if (payload.initialPosition) {
      const currentTrack = this.queue[index];
      const startPosition =
        currentTrack.meta.type === 'Mix'
          ? payload.initialPosition / currentTrack.meta.mix_length
          : payload.initialPosition / currentTrack.meta.duration_seconds;
      currentTrack.initialPosition = startPosition;
    }

    this._play();
  }

  reset(): void {
    if (this.state === 'STOPPED') return;

    if (this.sound) {
      this.sound.destruct();
    }

    this.state = 'STOPPED';
    this.queue = [];
    this.index = 0;
    this.sound = null;
    this.clearOnPosition = null;

    this.emit('STATE_CHANGED', { state: this.state });
  }

  currentTime(): number {
    if (!this.sound) return 0;
    return this.sound.position / 1000;
  }

  skip(): void {
    if (this.index >= this.queue.length - 1) return;

    const track = this.queue[this.index];
    this.index++;
    const nextTrack = this.queue[this.index];

    this._playTrack(nextTrack);

    this.emit('TRACK_CHANGED', { track, nextTrack });
  }

  back(): void {
    if (this.index <= 0) return;

    const track = this.queue[this.index];
    this.index--;
    const previousTrack = this.queue[this.index];

    this._playTrack(previousTrack);

    this.emit('TRACK_CHANGED', { track, nextTrack: previousTrack });
  }

  setPlaybackPosition(payload: {
    position: number;
    newLastAllowedPosition?: number;
  }): void {
    if (this.state !== 'PLAYING') {
      Object.assign(this.queue[this.index], {
        initialPosition: payload.position,
        lastAllowedPosition: payload.newLastAllowedPosition
      });
      this._play();
    } else {
      if (this.sound === null)
        return logError(new Error('Player in PLAYING state without sound object'));
      if (payload.newLastAllowedPosition) {
        this.clearOnPosition && this.clearOnPosition();
        this.clearOnPosition = null;

        const skipAtEndOfPreviewWindow = () => this.skip();
        const to = payload.newLastAllowedPosition * (this.sound.duration ?? 0);
        this.sound.onPosition(to, skipAtEndOfPreviewWindow);
        this.clearOnPosition = () =>
          this.sound?.clearOnPosition(to, skipAtEndOfPreviewWindow);
      }
      this.sound.setPosition((this.sound.duration ?? 0) * payload.position);
    }
  }

  resume(): void {
    this.state = 'PLAYING';
    this.sound?.resume();
    this.emit('STATE_CHANGED', this.state);
  }

  pause(): void {
    this.state = 'PAUSED';

    if (!this.sound) {
      return;
    }

    if (this.sound.readyState !== 1) {
      this.sound.pause();
    } else {
      this.sound.onPosition(0, () => {
        this.sound?.pause();
      });
    }

    this.emit('STATE_CHANGED', this.state);
  }

  toggle(): void {
    if (this.state === 'PLAYING') {
      this.pause();
    } else {
      this.resume();
    }
  }

  seek(seconds: number): void {
    this.sound?.setPosition(this.sound.position + seconds * 1000);
  }

  setVolume(volume: number): void {
    if (this.sound) {
      this.sound.setVolume(volume * 100);
      this.emit('VOLUME_CHANGED', { volume });
    }
  }

  _playTrack(track: QueuedMix | QueuedTrack): void {
    const volume = this.sound?.volume || 70;

    if (this.sound) {
      this.sound.destruct();
    }

    this.playheadTime = 0;

    this.sound = soundManager.createSound({
      id: SOUND_ID,
      url: track.url,
      autoLoad: false,
      autoPlay: false,
      volume
    });

    this.sound.load();

    // Track HTML5 `stalled` state to prevent skipping to the next track
    // automatically when the data is received at a very slow rate. This makes
    // the experience better for inconsistent internet connections.
    this.stalled = false;
    this.sound._a?.addEventListener('stalled', () => {
      this.stalled = true;
    });
    this.sound._a?.addEventListener('playing', () => {
      this.stalled = false;
    });

    const trackDuration =
      track.meta.type === 'Mix' ? track.meta.mix_length : track.meta.duration_seconds;

    const from = track.initialPosition
      ? track.initialPosition * trackDuration * 1000
      : undefined;
    const to = track.lastAllowedPosition
      ? track.lastAllowedPosition * trackDuration * 1000
      : undefined;

    if (to) {
      const skipAtEndOfPreviewWindow = () => this.skip();
      this.sound.onPosition(to, skipAtEndOfPreviewWindow);
      this.clearOnPosition = () =>
        this.sound?.clearOnPosition(to, skipAtEndOfPreviewWindow);
    }

    this.sound.stop();
    this.sound.play({
      position: from,
      onfinish: () => {
        if (this.stalled) {
          // Attempt to replay the track and wait out the lack of audio data.
          const currentTrack = this.queue[this.index];
          currentTrack.initialPosition = this.playheadTime / trackDuration;
          this._play();
        } else {
          this.skip();
        }
      },
      whileplaying: () => {
        this.playheadTime = this.currentTime();
        this.emit('TICK', this.currentTime());
      }
    });
  }
}
