import { createReducer } from 'redux-act';
import { combineReducers } from 'redux';

import { topArtistsActions, fetchArtistActions } from 'redux/actions/artists';

import {
  handleFetchError,
  handleFetchSuccess,
  fetchInitialState,
  handleFetchRequest,
  handleArrayToByIdSuccess
} from './utilities';
import { ARTIST_INITIAL_STATE } from './initialState';

const byIdInitialState = {};

const byId = createReducer(
  {
    [fetchArtistActions.request]: (state, payload) =>
      handleFetchRequest(state, payload, { initialData: ARTIST_INITIAL_STATE }),
    [fetchArtistActions.success]: handleFetchSuccess,
    [fetchArtistActions.error]: handleFetchError,
    [topArtistsActions.success]: handleArrayToByIdSuccess
  },
  byIdInitialState
);

const topArtistsIds = createReducer(
  {
    [topArtistsActions.request]: handleFetchRequest,
    [topArtistsActions.success]: handleFetchSuccess,
    [topArtistsActions.error]: handleFetchError
  },
  fetchInitialState
);

export default combineReducers({
  byId,
  topArtistsIds
});
