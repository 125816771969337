import Bugsnag, { Event, NotifiableError } from '@bugsnag/js';
import { isProduction, isTesting } from 'config/constants';
import { Evaluate } from 'types';
import { CombinedError } from 'urql';

const isNotifiableError = (error: unknown): error is NotifiableError =>
  error instanceof Error ||
  typeof error === 'string' ||
  (typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    'name' in error &&
    typeof error.message === 'string' &&
    typeof error.name === 'string');

const toNotifiableError = (error: unknown): NotifiableError => {
  if (isNotifiableError(error)) return error;

  try {
    return new Error(JSON.stringify(error));
  } catch {
    return new Error(String(error));
  }
};

const isCombinedError = (error: unknown): error is CombinedError =>
  error instanceof Error && ('networkError' in error || 'graphQLErrors' in error);

const logMethod = { error: 'error', warning: 'warn', info: 'info' } as const;

type EventProperties =
  | 'app'
  | 'device'
  | 'request'
  | 'errors'
  | 'breadcrumbs'
  | 'threads'
  | 'severity'
  | 'unhandled';

type EventData = Evaluate<
  Partial<
    Pick<Event, EventProperties> & {
      metadata: Record<string, Record<string, unknown>>;
    }
  >
>;

/**
 * General purpose logger for collecting data on unexpected events, currently just wraps the `Bugsnag.notify()`
 * method [{@link https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors/ | info}], converting
 * the input first if necessary.
 *
 * Accepts an object of event properties as an optional second parameter to allow customizing what is reported
 * to Bugsnag. If this parameter includes a `metadata` object, the `event.addMetadata()` method will be called
 * once per key of that object. (Expects data in the form of the second example
 * in {@link https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#addmetadata the docs}.)
 *
 * The reported event severity defaults to `error` if not provided, except in the case of network
 * errors where severity is set to `info`.
 */

export const logError = (item: unknown, eventData?: EventData) => {
  let severity = eventData?.severity ?? 'error';
  if (isCombinedError(item)) {
    if (item.networkError) {
      severity = 'info';
    }
  }

  if (!isTesting)
    Bugsnag.notify(
      toNotifiableError(item),
      event => {
        event.severity = severity;
        for (const key in eventData) {
          if (key === 'metadata') {
            for (const mdKey in eventData.metadata) {
              event.addMetadata(mdKey, eventData.metadata[mdKey]);
            }
          } else if (key !== 'severity') event[key] = eventData[key as EventProperties];
        }
      },
      (err, event) => {
        if (!isProduction)
          if (err) {
            console.error(
              'Failed to send report because of:\n' +
                (err instanceof Error ? err.stack : String(err))
            );
          } else {
            console.info(
              'Successfully sent report "' + event.errors[0].errorMessage + '"'
            );
          }
      }
    );

  if (!isProduction) console[logMethod[severity]]('logError:', { item, eventData });
};
