import _ from 'lodash';
import { AUDIO_TYPES } from 'config/constants';

// Add __audioType and __audioId to existing mixes and tracks in the redux store

const rootMigration8 = state => {
  const { mixes, tracks, radio, ...rest } = state;

  return {
    ...rest,
    mixes: {
      ...mixes,
      byId: _.mapValues(mixes.byId, mix => ({
        ...mix,
        __audioId: mix.id,
        __audioType: AUDIO_TYPES.MIX
      }))
    },
    tracks: {
      ...tracks,
      byId: _.mapValues(tracks.byId, track => ({
        ...track,
        __audioId: track.id,
        __audioType: AUDIO_TYPES.TRACK
      }))
    },
    radio: _.mapValues(radio, radioState => ({
      ...radioState,
      playing: {
        ...radioState.playing,
        __audioId: 1,
        __audioType: AUDIO_TYPES.RADIO
      }
    }))
  };
};

export default rootMigration8;
