import React from 'react';

import { DEFAULT_ICON_COLOR, ICON_PROP_TYPES } from './config';

const MagnifyingGlass = ({ size = 18, className, color = DEFAULT_ICON_COLOR }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 512 512"
    height={size}
  >
    <title>Search</title>
    <path
      d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
      fill="none"
      stroke={color}
      strokeMiterlimit="10"
      strokeWidth="32"
    />
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="32"
      d="M338.29 338.29L448 448"
    />
  </svg>
);

MagnifyingGlass.propTypes = ICON_PROP_TYPES;

export default MagnifyingGlass;
