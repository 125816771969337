import { areActionMenusOpen, areModalsOpen, detectCollisions } from 'helpers';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Keys } from 'react-keydown';
import { useLocation } from 'react-router-dom';
import { useSpring } from 'react-spring';

import Portal from 'components/Portal';
import usePrevious from 'hooks/usePrevious';

import { DIRECTIONS, isTesting } from 'config/constants';
import { getCaretStyles, getPositioning } from './helpers';
import { Card, PopoverContent, PopoverWrapper } from './styled';

const DEFAULT_POPOVER_POSITIONING = {
  left: -9999,
  top: -9999
};

const ANIMATE_DURATION_MS = 300;

/**
 * [Popover]
 * Renders and animates arbitrary content inside a card positioned at an arbitrary DOM
 * node of choice inside a React portal layer.
 *
 * -- HOW TO OPEN THE POPOVER --
 * Pass renderToggle as a prop on use the passed open argument
 *   <Popover renderToggle={({ open }) => <button onClick={open} />} />
 *
 * NOTE: you can override the anchorNode that the popover is rendered from
 * by passing via props
 *
 *
 * @prop {node} [anchorNode] - can define the node that will be used for the popover to position off of
 * @prop {func} renderToggle - render prop that recieves (open, close, isOpen) for triggering the Popover open/close
 * if anchorNode is not passed, the element that triggers the open event will be used
 * @prop {React.ReactNode | ((props: any) => React.ReactNode)} [children] - children content of the popover
 * @prop {bool} [closeOnNavigation = true] - Closes the popover if the route changes
 * @prop {bool} [fixed] - Specifies whether to render as position fixed or absolute in the CSS
 * @prop {func} location - comes from React DOM's withRouter automatically
 * @prop {string} renderDirection - Direction in which the popover should render, specified in Popover.DIRECTIONS static
 * @prop {func} [onOpen] function to run when the popover is opened
 * @prop {func} [onClose] function to run when the popover is closed
 * @prop {string} [background] - optionaly change background color
 */

const Popover = React.memo(props => {
  const {
    anchorNode,
    background,
    caret = false,
    children,
    closeOnNavigation = true,
    dataTestId = 'Popover',
    domNode = document.getElementById('popovers-root'),
    fixed = false,
    renderDirection = Popover.DIRECTIONS.DOWN,

    renderToggle,
    onOpen,
    onClose
  } = props;

  const location = useLocation();

  const popoverRef = useRef();
  const popoverWrapperRef = useRef();
  const anchorNodeRef = useRef(anchorNode);
  const previousLocation = usePrevious(location, location);

  // Set default positions far off-screen so it can be ref'd and
  // its position properly calculated relative to DOM anchorNode.
  const [popoverPositioning, setPopoverPositioning] = useState(
    DEFAULT_POPOVER_POSITIONING
  );
  const [statefulRenderDirection, setStatefulRenderDirection] = useState(renderDirection);
  const [caretStyles, setCaretStyles] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const openPopover = useCallback(
    e => {
      // If no anchorNode is defined, define the anchor node as the target that the open event was
      // triggered from. Update anchor node on each click just in case reference was lost since last
      // time it was set.
      if (!anchorNode) {
        anchorNodeRef.current = e.currentTarget;
      }

      // NOTE: in order to prevent clickout handler to be immediately invoked, we wrap setIsVisible in a timeout
      // to decouple from click event loop
      setTimeout(() => {
        setOpen(true);
        setIsVisible(true);

        if (onOpen) onOpen();
      });
    },
    [onOpen, anchorNode]
  );

  const closePopover = useCallback(
    immediate => {
      if (immediate) {
        setIsVisible(false);
        setOpen(false);
        setPopoverPositioning(DEFAULT_POPOVER_POSITIONING);
      } else {
        setIsVisible(false);
        _.delay(() => {
          setOpen(false);
          setPopoverPositioning(DEFAULT_POPOVER_POSITIONING);
        }, 500);
      }

      if (onClose) onClose();
    },
    [onClose]
  );

  const togglePopover = useCallback(
    e => {
      if (isOpen) {
        closePopover();
      } else {
        openPopover(e);
      }
    },
    [isOpen, closePopover, openPopover]
  );

  // ////////
  // Setup basic methods for various effects to call.

  // if anchorNode updates, let make sure anchorNodeRef is set / updated
  useEffect(() => {
    if (anchorNode !== anchorNodeRef.current) anchorNodeRef.current = anchorNode;
  }, [anchorNode]);

  const recalculatePositioning = useCallback(() => {
    const popoverNode = popoverRef?.current;
    if (!popoverNode) return;

    const popoverBounds = popoverNode.getBoundingClientRect();

    // Calculate popover positioning so its centered over the passed anchor
    // in the correct direction
    const initialPopoverBounds = getPositioning(
      statefulRenderDirection,
      anchorNodeRef.current,
      popoverNode,
      fixed
    );

    const updatedPopoverBounds = {
      top: fixed ? initialPopoverBounds.top : initialPopoverBounds.top - window.scrollY,
      left: initialPopoverBounds.left,
      right: initialPopoverBounds.left + popoverBounds.width,
      bottom: fixed
        ? initialPopoverBounds.top + popoverBounds.height
        : initialPopoverBounds.top + popoverBounds.height - window.scrollY,
      width: popoverBounds.width,
      height: popoverBounds.height
    };

    const popoverCollisions = detectCollisions(
      updatedPopoverBounds,
      statefulRenderDirection
    );

    if (popoverCollisions.flipDirection) {
      setStatefulRenderDirection(Popover.INVERSE_DIRECTIONS[renderDirection]);
    }
    const popoverTop = initialPopoverBounds.top + popoverCollisions.y;
    const popoverLeft = initialPopoverBounds.left + popoverCollisions.x;

    const nextCaretStyles = getCaretStyles(statefulRenderDirection, popoverCollisions);

    setPopoverPositioning({
      top: popoverTop,
      left: popoverLeft
    });

    setCaretStyles(nextCaretStyles);
  }, [fixed, renderDirection, statefulRenderDirection]);

  useEffect(() => {
    setTimeout(() => recalculatePositioning(), 0);
  }, [fixed, isOpen, recalculatePositioning, renderDirection, statefulRenderDirection]);

  // ////////
  // Setup event listeners for ESC key and clicking out to close Popover.
  useEffect(() => {
    const onClickOut = e => {
      // NOTE on modals...
      // If a modal is open while a popover is open, the modal was likely spawned
      // from the popover. The default behavior is to only close the modal on ESC/click-outside.
      // If you wish to override, utilize popover's close method to get desired behavior
      if (areModalsOpen() || areActionMenusOpen()) return;

      // NOTE on react-select
      // If react select menu is open, the target element is removed before this code is run, and hence
      // causes the popover to close after selecting an option. This prevents that behavior.
      if (e.target.id && e.target.id.includes('react-select')) return;

      // In order to prevent clicking within nested popovers from opening the parent popover,
      // we use the popover portal DOM node as the reference container when checking clickoutside
      // TLDR: clicking one open popover doesn't close another open popover
      const clickoutsideContainer = domNode;

      if (clickoutsideContainer && !clickoutsideContainer.contains(e.target)) {
        closePopover();
      }
    };

    const onEsc = event => {
      if (event.keyCode === Keys.ESC) {
        if (areModalsOpen() || areActionMenusOpen()) return;

        const nodes = Array.prototype.slice.call(
          document.getElementById('popovers-root').children
        );
        const nodeIndex = nodes.indexOf(popoverWrapperRef.current);
        if (nodeIndex !== nodes.length - 1) return;

        closePopover();
      }
    };

    if (isVisible) {
      document.addEventListener('keydown', onEsc);
      document.addEventListener('click', onClickOut);

      return () => {
        document.removeEventListener('keydown', onEsc);
        document.removeEventListener('click', onClickOut);
      };
    }
  }, [isVisible, closePopover, domNode]);

  // ////////
  // Automatically close Popover if the window location changes.
  useEffect(() => {
    if (location.pathname === previousLocation.pathname) return;
    if (!closeOnNavigation || !isOpen) return;

    closePopover();
  }, [
    closeOnNavigation,
    closePopover,
    isOpen,
    location.pathname,
    previousLocation.pathname
  ]);

  useEffect(() => {
    const handleResize = () => {
      closePopover();
    };

    if (isOpen) {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isOpen, closePopover]);

  // ////////
  // Animation props to pass to styled(animated.div) <Card> element.
  const styleProps = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translate3d(0, 0, 0)' : 'translate3d(0, -15px, 0)',
    immediate: isTesting,
    config: {
      duration: ANIMATE_DURATION_MS
    }
  });

  const popoverProps = {
    toggle: togglePopover,
    close: closePopover,
    open: openPopover,
    isOpen,
    isVisible,
    recalculatePositioning
  };

  return (
    <>
      {renderToggle && renderToggle(popoverProps)}

      {isOpen && (
        <Portal domNode={domNode}>
          <PopoverWrapper
            $top={popoverPositioning.top}
            $left={popoverPositioning.left}
            $fixed={fixed}
            data-testid={dataTestId}
            ref={popoverWrapperRef}
          >
            <Card
              ref={popoverRef}
              style={styleProps}
              data-caretstyles={caretStyles}
              background={background}
              caret={caret || undefined}
            >
              <PopoverContent>
                {/* NOTE: if children is a render function, pass in render props */}
                {typeof children === 'function' ? children(popoverProps) : children}
              </PopoverContent>
            </Card>
          </PopoverWrapper>
        </Portal>
      )}
    </>
  );
});

Popover.DIRECTIONS = DIRECTIONS;

Popover.INVERSE_DIRECTIONS = {
  UP: DIRECTIONS.DOWN,
  DOWN: DIRECTIONS.UP,
  LEFT: DIRECTIONS.RIGHT,
  RIGHT: DIRECTIONS.LEFT
};

Popover.SELECTORS = {
  // NOTE: CONTAINER only applies if dataTestId prop not passed
  CONTAINER: '[data-testid=Popover]'
};

Popover.propTypes = {
  anchorNode: PropTypes.node,
  caret: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  closeOnNavigation: PropTypes.bool,
  dataTestId: PropTypes.string,
  domNode: PropTypes.node,
  fixed: PropTypes.bool,
  renderDirection: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  background: PropTypes.string,
  renderToggle: PropTypes.func
};

export default Popover;
