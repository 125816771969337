import React from 'react';

import { DEFAULT_ICON_COLOR, ICON_PROP_TYPES } from './config';

const Radio = ({ color = DEFAULT_ICON_COLOR, size = 18, className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    height={size}
    style={style}
    viewBox="0 0 27 48"
  >
    <title>Radio</title>
    <path
      d="M13.173 34.124a8.099 8.099 0 008.098-8.099V8.318A8.098 8.098 0 0013.173.22a8.098 8.098 0 00-8.099 8.098v17.707a8.099 8.099 0 008.099 8.099z"
      fill={color}
    />
    <path
      d="M23.666 18.849v7.176c0 5.787-4.708 10.493-10.493 10.493-5.787 0-10.494-4.706-10.494-10.493V18.85H.284v7.176c0 6.27 4.5 11.505 10.44 12.653v3.938h-6.6v4.897H22.22v-4.897h-6.599v-3.938c5.94-1.148 10.44-6.383 10.44-12.653V18.85h-2.395z"
      fill={color}
    />
  </svg>
);

Radio.propTypes = ICON_PROP_TYPES;

export default Radio;
