import { reducer as formReducer } from 'redux-form';

import { algoliaApi, protonApi } from 'api/rtkSetup';

import acknowledgements from './acknowledgements';
import artists from './artists';
import features from './features';
import feedback from './feedback';
import followed from './followed';
import labels from './labels';
import labelSubscribers from './labelSubscribers';
import mixes from './mixes';
import promoReleases from './promoReleases';
import radio from './radio';
import releases from './releases';
import search from './search';
import shows from './shows';
import subscriberSearch from './subscriberSearch';
import token from './token';
import tracks from './tracks';
import ui from './ui';
import uiPersist from './uiPersist';
import user from './user';

const rootReducer = {
  acknowledgements,
  artists,
  features,
  feedback,
  followed,
  form: formReducer,
  labels,
  labelSubscribers,
  mixes,
  promoReleases,
  radio,
  releases,
  search,
  shows,
  subscriberSearch,
  token,
  tracks,
  ui,
  uiPersist,
  user,
  [protonApi.reducerPath]: protonApi.reducer,
  [algoliaApi.reducerPath]: algoliaApi.reducer
};

export default rootReducer;
