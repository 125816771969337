import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Spring } from 'react-spring/renderprops';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import history from 'config/history';
import { impersonateUser as impersonateUserAction } from 'redux/actions/user';
import { toggleSearchResultsDisplay as toggleSearchResultsDisplayAction } from 'redux/actions/search';

import { isMobile } from 'helpers/screen';
import Screen from 'components/Screen';
import ActionMenu, { ActionMenuHeader, ActionMenuList } from 'components/ActionMenu';
import Button, { ButtonGroup } from 'components/Button';

class UserLookupSearchResult extends Component {
  constructor(props) {
    super(props);
    this.actionContainer = React.createRef();
  }

  state = {
    right: -999,
    isMenuOpen: false
  };

  getSSUrl = proUserId =>
    `https://soundsystem.protonradio.com/main.php?tab=accounts&pro_user_id=${proUserId}#artists-tab`;

  masqueradeUser = () => {
    const {
      phpbb_user: { user_id },
      impersonateUser
    } = this.props;

    return impersonateUser(user_id)
      .then(() => history.push('/account'))
      .catch(e => {
        console.warn(`Error masquerading userId ${user_id}`, e);
      });
  };

  _showActions = () => {
    this.setState({ right: 0, showActions: true });
  };

  _hideActions = () => {
    const width = this.actionContainer.current.clientWidth;
    this.setState({ right: -width, showActions: false });
  };

  _toggleActionSlider = e => {
    // Don't fire if clicking within action container
    if (this.actionContainer.current.contains(e.target)) return;

    if (this.state.showActions) {
      this._hideActions();
    } else {
      this._showActions();
    }
  };

  _getMenuActions = () => {
    const {
      phpbb_user: { user_id },
      pro_user: { id },
      pro_user_exists,
      toggleResultsDisplay
    } = this.props;

    const soundsystemAction = pro_user_exists
      ? [
          {
            name: 'SoundSystem',
            icon: 'icon-link.svg',
            to: this.getSSUrl(id),
            newTab: true
          }
        ]
      : [];

    return [
      {
        name: 'Masquerade',
        icon: 'icon-mask.svg',
        onClick: () =>
          this.masqueradeUser(user_id).then(() => toggleResultsDisplay(false)),
        disabled: !user_id
      },
      ...soundsystemAction
    ];
  };

  _handleRowClick = e => {
    if (isMobile()) {
      this._openMenu();
    } else {
      this._toggleActionSlider(e);
    }
  };

  _openMenu = () => this.setState({ isMenuOpen: true });

  _closeMenu = () => this.setState({ isMenuOpen: false });

  render() {
    const {
      phpbb_user: { user_id, username, user_email, user_active },
      pro_user: { id },
      pro_user_exists,
      phpbb_user_exists
    } = this.props;

    const { isMenuOpen } = this.state;

    if (!pro_user_exists && !phpbb_user_exists) {
      return (
        <div
          className="UniversalSearchResultRow UserSearchResult"
          role="button"
          tabIndex="0"
        >
          <div className="UniversalSearchResultRow__info">
            <div className="UserSearchResult__user__section--wide">
              <div className="UniversalSearchResultRow__info__title">No User Matches</div>
              <div className="UniversalSearchResultRow__info__subtitle">
                <p>
                  Double check your spelling, or ask them to{' '}
                  <Link to="/create-account">create an account</Link>.
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <ActionMenu isOpen={isMenuOpen} close={this._closeMenu}>
          <ActionMenuHeader>
            <div className="ListItem__title">{username}</div>
            <div className="ListItem__subtitle">{user_email}</div>
          </ActionMenuHeader>

          <ActionMenuList actions={this._getMenuActions()} closeMenu={this._closeMenu} />
        </ActionMenu>

        <div
          className="UniversalSearchResultRow UserSearchResult"
          data-testid={UserLookupSearchResult.TEST_IDS.ROW}
          role="button"
          tabIndex="0"
          onClick={this._handleRowClick}
        >
          <div className="UniversalSearchResultRow__info">
            <div className="UserSearchResult__user__section--wide">
              <div className="UniversalSearchResultRow__info__title">{username}</div>
              <div className="UniversalSearchResultRow__info__subtitle">
                <p>
                  {user_email}
                  {' • '}
                  {user_active ? 'Account Active' : 'Account Inactive'}
                </p>
              </div>
            </div>
          </div>

          <Screen.SMALL up>
            <Spring to={{ right: this.state.right }}>
              {styleProps => (
                <div
                  className="UserSearchResult__actions"
                  style={styleProps}
                  ref={this.actionContainer}
                >
                  <ButtonGroup>
                    <Button
                      color={Button.COLORS.WARNING}
                      disabled={!user_id}
                      onClick={() => this.masqueradeUser()}
                    >
                      Masquerade
                    </Button>
                    {pro_user_exists && (
                      <Button color={Button.COLORS.NEUTRAL} to={this.getSSUrl(id)} newTab>
                        SoundSystem
                      </Button>
                    )}
                  </ButtonGroup>
                </div>
              )}
            </Spring>
          </Screen.SMALL>
        </div>
      </>
    );
  }
}

UserLookupSearchResult.propTypes = {
  user_id: PropTypes.number,
  username: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  pro_user_exists: PropTypes.bool,
  phpbb_user_exists: PropTypes.bool,
  phpbb_user: PropTypes.shape(),
  pro_user: PropTypes.shape({
    id: PropTypes.number
  }),
  // redux connect
  impersonateUser: PropTypes.func,
  toggleResultsDisplay: PropTypes.func
};

UserLookupSearchResult.TEST_IDS = {
  ROW: 'UserLookupSearchResult-user-lookup'
};

export default connect(null, {
  // consider moving out of these to UniversalSearchResults component if unperformant
  impersonateUser: impersonateUserAction,
  toggleResultsDisplay: toggleSearchResultsDisplayAction
})(UserLookupSearchResult);
