import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Loader from 'components/Loader';
import Unauthorized from 'routes/Unauthorized';
import { logoutUser as logoutUserAction } from 'redux/actions/user';
import { addAlertMessage as addAlertMessageAction } from 'redux/actions/ui';
import useCurrentUser, { User } from 'hooks/useCurrentUser';
import { RequireOneOfEither } from 'types';

interface AddedRouteProps {
  // manually passed props
  routeProps?: { [x: string]: unknown };
  isAuthorized?:
    | boolean
    | ((
        props: RouteComponentProps<{ [x: string]: string | undefined }> & { user: User }
      ) => boolean);
  forceLoading?: boolean;
  redirectTo?: string;
}

type UserRouteProps = RequireOneOfEither<RouteProps, 'component', 'render'> &
  AddedRouteProps;

/**
 * Component that checks user in redux and confirms that meet any auth policies passed
 *   - One of either `component` or `render` must be defined in props
 *   - `isAuthorized` must be set explicitly to false (not undefined) to trigger unauthorized route.
 *   - `routeProps` is used to pass additional props to the rendered route component (outside of default Route props)
 */

export const UserRoute: React.FC<UserRouteProps> = ({
  component: RenderComponent,
  render,
  isAuthorized = true,
  forceLoading,
  redirectTo,
  routeProps,
  ...rest
}) => {
  const { user, fetching } = useCurrentUser();
  const jwtToken = useAppSelector(state => state.token.jwt);
  const dispatch = useAppDispatch();

  const logoutUser = () => dispatch(logoutUserAction());
  const displayUnauthorizedAlert = (pathname: string) => {
    dispatch(
      addAlertMessageAction({
        component: 'UnauthorizedMessage',
        componentProps: { redirectPathname: pathname },
        timeout: false,
        type: 'warning'
      })
    );
  };

  return (
    <Route
      {...rest}
      // NOTE: defaultRouteProps are things like location, match, history, etc for route being rendered
      render={defaultRouteProps => {
        const { location } = defaultRouteProps;
        if (forceLoading || (!user.id && (fetching || jwtToken))) return <Loader />;

        if (!jwtToken) {
          logoutUser();

          return (
            <Redirect
              to={{
                pathname: '/sign-in',
                state: { from: location }
              }}
            />
          );
        }

        const isUserAuthorized =
          typeof isAuthorized === 'function'
            ? isAuthorized({ ...defaultRouteProps, user })
            : isAuthorized;

        // If manually passing isAuthorized flag, then handle here:
        if (!isUserAuthorized) {
          displayUnauthorizedAlert(location.pathname);

          if (redirectTo) {
            return (
              <Redirect
                to={{
                  pathname: redirectTo,
                  state: { from: location }
                }}
              />
            );
          }

          return <Unauthorized />;
        }

        if (RenderComponent) {
          return <RenderComponent {...defaultRouteProps} {...routeProps} />;
        }

        return render({ ...defaultRouteProps, ...routeProps });
      }}
    />
  );
};
