import _ from 'lodash';

// LARGE PROTON COLLIDER (L.P.C)
//
// Automatically takes an arbitrary DOM element and reports back on collisions with
// either a numerical pixel value or false as x/y vectors.

const HEADER_HEIGHT = 60;
const FOOTER_HEIGHT = 60;

/**
 * detectCollisions
 * @param {object} elBounds - getBoundingClientRect() value of element to check for collisions
 * @param {string} renderDirection - render direction to check if collisions are in the primary direction
 * @param {object} [containerEl=window] - element to check elBounds against for collisions
 * @param {number} [tolerance=10] - pixel distance from edge of containerEl after which its considered a collision
 */

const SIDES = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right'
};

// mapping of object's "side" for collision and the render direction
const COLLISION_DIRECTIONS = {
  [SIDES.TOP]: 'up',
  [SIDES.BOTTOM]: 'down',
  [SIDES.LEFT]: 'left',
  [SIDES.RIGHT]: 'right'
};

// eslint-disable-next-line import/prefer-default-export
export const detectCollisions = (
  elBounds,
  renderDirection,
  containerEl = window,
  tolerance = 25
) => {
  const containerHeight = containerEl.innerHeight;
  const containerWidth = containerEl.innerWidth;

  if (!elBounds) return {};
  const collisionCheck = {
    [SIDES.LEFT]: elBounds.left < tolerance ? tolerance + Math.abs(elBounds.left) : false,
    [SIDES.RIGHT]:
      elBounds.right > containerWidth - tolerance
        ? containerWidth - tolerance - elBounds.right
        : false,
    [SIDES.TOP]:
      elBounds.top < HEADER_HEIGHT ? HEADER_HEIGHT + Math.abs(elBounds.top) : false, // Make sure it clears the header on desktop
    [SIDES.BOTTOM]:
      elBounds.bottom > containerHeight - FOOTER_HEIGHT - tolerance
        ? containerHeight - FOOTER_HEIGHT - tolerance - elBounds.bottom
        : false
  };

  const measuredCollisions = Object.keys(collisionCheck).reduce(
    (accumulator, direction) => {
      const isPrimaryDirection =
        renderDirection.toLowerCase() === COLLISION_DIRECTIONS[direction];

      if (isPrimaryDirection && _.isNumber(collisionCheck[direction])) {
        return {
          ...accumulator,
          flipDirection: true
        };
      }

      if ([SIDES.TOP, SIDES.BOTTOM].includes(direction)) {
        const yVal = collisionCheck[direction] ? { y: collisionCheck[direction] } : {};

        return {
          ...accumulator,
          ...yVal
        };
      }

      if ([SIDES.LEFT, SIDES.RIGHT].includes(direction)) {
        const xVal = collisionCheck[direction] ? { x: collisionCheck[direction] } : {};
        return {
          ...accumulator,
          ...xVal
        };
      }

      // If we get this far something is horribly wrong.
      return accumulator;
    },
    {}
  );

  return {
    x: measuredCollisions.x || 0,
    y: measuredCollisions.y || 0,
    flipDirection: measuredCollisions.flipDirection
  };
};
