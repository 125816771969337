import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';

import { fetchInitialState } from 'redux/reducers/utilities';
import { selectUserLabelProfiles } from 'redux/selectors/user';

/**
 * [selectLabelSubscribersRoot] OR [selectLabelRecommendedSubscribersRoot]
 *
 * @returns {object}
 *
 *  Example:
 * {
 *    artistIds: [50153, 50936],
 *    __isFetching: false,
 *    __lastFetched: 1575924719410,
 *    subscribersByArtistId: {
 *      50153: {
 *        artistId: 24988,
 *        date_added_epoch: 1554309980,
 *        promo_access: true,
 *        state: following
 *      },
 *      ...
 *    }
 * }
 */

const DEFAULT_LABEL_ROOT_STATE = fetchInitialState;
// ** ROOT selectors **
const selectSubscribersByLabelIdRoot = state =>
  state.labelSubscribers.subscribersByLabelId;

const selectRecommendedSubscribersByLabelIdRoot = state =>
  state.labelSubscribers.recommendedSubscribersByLabelId;

const selectAllSubscriberArtists = state => state.labelSubscribers.artistsById;

// ** By Label Id selectors **
export const selectLabelSubscribersRoot = (state, { labelId }) =>
  selectSubscribersByLabelIdRoot(state)[labelId] || DEFAULT_LABEL_ROOT_STATE;

export const selectLabelSubscriberArtistIds = (state, { labelId }) => {
  const { data } = selectLabelSubscribersRoot(state, { labelId });
  return data.map(({ id }) => id);
};

export const selectLabelSubscribersFetching = (state, { labelId }) =>
  selectLabelSubscribersRoot(state, { labelId }).__isFetching;

export const selectLabelSubscribersLastFetched = (state, { labelId }) =>
  selectLabelSubscribersRoot(state, { labelId }).__lastFetched;

export const selectLabelRecommendedSubscribersRoot = (state, { labelId }) =>
  selectRecommendedSubscribersByLabelIdRoot(state)[labelId] || DEFAULT_LABEL_ROOT_STATE;

/**
 * [makeSelectLabelSubscribers] - retrieve the subscribers for a given label id.
 *
 * Redux state used:
 * - state.labelSubscribers.subscribersByLabelId
 * - state.labelSubscribers.artistsById
 *
 * EXAMPLE USAGE:
 * const makeMapStateToProps = () => {
 *    const selectSubscribers = makeSelectLabelSubscribers();
 *
 *    return (state, ownProps) => ({
 *      data: selectSubscribers(state, { labelId: ownProps.labelId }),
 *    });
 * };
 *
 * Example of label subscriber selector response:
 * [{
 *   id: 24988
 *   ...artistData, // data from state.artist.byId for given artist
 *   subscription: {
 *     artistId: 24988,
 *     date_added_epoch: 1554309980,
 *     promo_access: true,
 *     state: following
 *   }
 * }]
 */

export const makeSelectLabelSubscribers = type => {
  const labelSubscriberRootSelector =
    type === 'recommended'
      ? selectLabelRecommendedSubscribersRoot
      : selectLabelSubscribersRoot;

  return createSelector(
    [labelSubscriberRootSelector, selectAllSubscriberArtists],
    (labelSubscriberRoot, artistsById) => {
      const { data } = labelSubscriberRoot;

      return data.map(subscriber => {
        // NOTE: recommended subscribers data attribute is just artist ids, where subscribers
        // reducer includes additional data that isn't normalized
        const artistId = type === 'recommended' ? subscriber : subscriber.artistId;

        return {
          ...artistsById[artistId],
          // subscription specific data is put in its own field
          subscription: subscriber
        };
      });
    }
  );
};

/**
 * [selectIsArtistInUserPromoPool] - checks if artist id passed is found in one of the user's label's promo pool
 * subscribers
 *
 * Argument format: (state, { artistId })
 *
 * @param {number} artistId
 * @returns {boolean}
 */

export const selectIsArtistInUserPromoPool = createSelector(
  [
    selectUserLabelProfiles,
    selectSubscribersByLabelIdRoot,
    (__, { artistId }) => artistId
  ],
  (labelProfiles, subscribersByLabelId, artistId) => {
    const isSubscriber = labelProfiles.some(({ id }) => {
      const subscribers = _.get(subscribersByLabelId, `${id}.data`, []);
      return subscribers.some(subscriber => subscriber.artistId === artistId);
    });

    return isSubscriber;
  }
);

/**
 * [selectIsFetchingAllLabelSubscribers]
 * if user is a label manager, looks to see if any of the user's label subscribers are currently being fetched.
 * This data (label subscribers) is fetched on startup by PromoDataFetcher
 *
 * @returns {boolean}
 */

export const selectIsFetchingAllLabelSubscribers = createSelector(
  [selectUserLabelProfiles, selectSubscribersByLabelIdRoot],
  (labelProfiles, subscribersByLabelId) =>
    labelProfiles.some(({ id }) => _.get(subscribersByLabelId, `${id}.__isFetching`))
);

/**
 * [selectHasFetchedAllLabelSubscribers]
 * If user is a label manager, determines if PromoDataFetcher has requested label subscribers for each of the user's
 * labels.  Likely used in conjunction with 'selectIsFetchingAllLabelSubscribers'
 *
 * @returns {boolean}
 */

export const selectHasFetchedAllLabelSubscribers = createSelector(
  [selectUserLabelProfiles, selectSubscribersByLabelIdRoot],
  (labelProfiles, subscribersByLabelId) => {
    // Checks to see if __lastFetched attribute is null or undefined for any of the users label profiles.  If so,
    // then still waiting for PromoDataFetcher to run
    return labelProfiles.some(({ id }) =>
      _.isNumber(_.get(subscribersByLabelId, `${id}.__lastFetched`))
    );
  }
);
