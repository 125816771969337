// import axios from 'axios';
// import queryString from 'query-string';
import { delay } from 'helpers/utilities';

export const getFollowedEntitiesForUserApi = entityType =>
  delay({ duration: 500 }).then(() => ({
    data: [
      {
        id: 1,
        user_id: 213,
        entity_type: 'artist',
        entity_id: 42772,
        created_at: Date.now()
      }
    ]
  }));

export const followEntityApi = (entityType, entityId) =>
  delay({ duration: 500 }).then(() => ({
    data: {
      id: 1,
      user_id: 213,
      entity_type: entityType,
      entity_id: entityId,
      created_at: Date.now()
    }
  }));

export const unfollowEntityApi = (entityType, entityId) =>
  delay({ duration: 500 }).then(() => ({ success: true, message: 'Artist unfollowed' }));

// /**
//  * [getFollowedEntitiesForUserApi]
//  *
//  * @param {string} [entityType] - if passed, followed records will be returned that match the passed 'entity_type'
//  * @returns {object[]}
//  */

// export const getFollowedEntitiesForUserApi = entityType => {
//   const qs = queryString.stringify({ entityType });
//   return axios
//     .get(`https://backstage.protonradio.com/api/user_followed_entities.php${qs}`)
//     .then(({ data }) => data);
// };
// /**
//  * [followEntityApi]
//  *
//  * @param {string} entityType - type of entity being followed
//  * @param {number} entityId - id of the entity being followed
//  * @returns {object} returns follow record
//  */

// export const followEntityApi = (entityType, entityId) =>
//   axios
//     .post(
//       `https://backstage.protonradio.com/api/user_followed_entities.php?entityType=${entityType}&entityId=${entityId}`
//     )
//     .then(({ data }) => data);

// /**
//  * [unfollowEntityApi]
//  *
//  * @param {string} entityType - type of entity being followed
//  * @param {number} entityId - id of the entity being followed
//  * @returns {object} returns success message
//  */

// export const unfollowEntityApi = (entityType, entityId) =>
//   axios.delete(
//     `https://backstage.protonradio.com/api/user_followed_entities.php?entityType=${entityType}&entityId=${entityId}`
//   );
