import axios from 'axios';
import { API_BASE_V2 } from 'config/constants';

// 201
interface ForceResetResponse {
  force_reset_password: boolean;
  reset_password_token: string;
  logged_in: boolean;
}

// 403 error
export interface MfaRequiredError {
  success: false;
  token: string;
  message: 'MFA required';
}

export interface GetTokenSuccess {
  logged_in: boolean;
  jwt: string;
  refresh_token: string;
  username: string;
  user_email: string;
  user_website: string | null;
  user_from: string | null;
}

type GetTokenResponse = GetTokenSuccess | ForceResetResponse;

export const getToken = (
  username: string,
  password: string,
  claimToken: string | undefined,
  recaptchaToken: string | undefined
) =>
  axios
    .post<GetTokenResponse>(`${API_BASE_V2}/sessions`, {
      username,
      password,
      claim_token: claimToken,
      grecaptcha_token: recaptchaToken
    })
    .then(({ data }) => data);

export const refreshToken = () =>
  axios
    .post<{ jwt: string; refresh_token?: string }>(`${API_BASE_V2}/sessions/refresh`)
    .then(({ data }) => data);
