import React, { useEffect, useState } from 'react';

import { formatSecondsDuration } from 'helpers';
import useProtonPlayer, { usePlayheadPosition } from 'hooks/useProtonPlayer';

const calculateTime = (seconds, start, current_time, length) => {
  // This is added so the radio shows current_time isn't temporarily displayed while the application
  // is still loading the new mix.
  if (current_time > length && start === true) return formatSecondsDuration(0);

  return formatSecondsDuration(seconds);
};

const templateHelpers = (current_time, length) => ({
  current_time: calculateTime(current_time, true, current_time, length),
  end_time: calculateTime(length, false, current_time, length)
});

const PlayerWaveformTimestamp = ({ hideEndTime }) => {
  const player = useProtonPlayer();
  const playing = player.currentTrack;
  const [currentTime, setCurrentTime] = useState(0);

  usePlayheadPosition(playheadTime => {
    setCurrentTime(playheadTime);
  });

  if (!playing) return;

  const helpers = templateHelpers(
    currentTime,
    playing.mix_length || playing.duration_seconds
  );

  return (
    <div>
      <div className="waveform__timestamp">
        <div className="waveform__timestamp__inner">{helpers.current_time}</div>
      </div>
      {hideEndTime && (
        <div className="waveform__timestamp waveform__timestamp--end">
          <div className="waveform__timestamp__inner">{helpers.end_time}</div>
        </div>
      )}
    </div>
  );
};

export default PlayerWaveformTimestamp;
