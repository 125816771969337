import { Table } from 'react-virtualized';
import styled from 'styled-components';

export const StyledTrackTable = styled(Table)`
  /* unless showOrderPlayButton is passed, play buttons should be hidden, and only order is shown */
  .tracklist-order .PlayerButton {
    display: none;
  }

  ${({ showOrderPlayButton }) =>
    showOrderPlayButton &&
    `
    .tracklist-order .PlayerButton {
      display: block;
    }

    .tracklist-order {
      /* In order to center the player button without getting clipped */
      padding-left: 0.7rem;
      overflow: visible !important;

      .PlayerButton {
        margin-left: -0.7rem;
      }

      .play-button--hover .PlayerButton {
        /* if showOrderPlayButton, hide play button (unless playing) */
        display: none;
      }
    }

    .ReactVirtualized__Table__row:hover {
      .tracklist-order {
        /* show player button on hover */
        .PlayerButton {
          display: block;
        }

        /* hide tracklist order on hover */
        .cell__value {
          display: none;
        }
      }
    }
  `}
`;
