import ahoy from 'ahoy.js';
import { CommunicationChannelEnum } from 'gql/graphql';
import { consoleDev } from './utilities';

/**
 * Can only be called after `ahoy` has been initialized by `useInitializeSideEffects`
 */
export const logMetric = (metric: string, properties?: Record<string, unknown>) => {
  consoleDev(
    'Logging metric:',
    `${metric}${properties ? ': ' + JSON.stringify(properties) : ''}`
  );
  ahoy.track(metric, properties);
  window.gtag('event', metric, properties);
};

type BaseFeatureMetric = 'state.enabled' | 'state.disabled' | 'state.reenabled';

type ReleaseLinkMetric =
  // Feature-level configuration events
  | BaseFeatureMetric
  | 'state.disabled_for_artists'
  | 'state.reenabled_for_artists'
  // User-level configuration events
  | 'notifications_configured.manager'
  | 'notifications_configured.artist'
  // UI events
  | 'share_modal.opened'
  | 'share_modal.link_copied'
  | 'share_modal.sign_in'
  | 'view.mobile';

/**
 * Wrapper for {@link logMetric} which accepts metrics of the {@link ReleaseLinkMetric} type.
 */
export function logFeatureMetric<Metric extends ReleaseLinkMetric>(
  feature: 'release_link',
  metric: Metric,
  properties?: Metric extends `notifications_configured${string}`
    ? {
        enabled?: boolean;
        channelType?: CommunicationChannelEnum;
      }
    : never
): void;
export function logFeatureMetric(
  feature: string,
  metric: string,
  properties?: Record<string, unknown>
) {
  logMetric(`${feature}.${metric}`, properties);
}
