import { store } from 'config/store';
import { showAlert } from 'redux/actions/ui';

/**
 * [displayAlertError]
 *
 * 🚨 WARNING 🚨 - this is deprecated in favor of using the action directly. See 'showAlert' action
 * in 'actions/ui.js'. This is because importing store directly is subject to circular dependency issues.
 *
 * Handle display of alert message component through redux without reacing for redux connect.
 * Pre-configured for handling display of error messages (typically as returned by axios).
 *
 * See 'showAlert' for param definition
 */

export const displayAlertError = error => store.dispatch(showAlert({ error }));
