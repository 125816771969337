import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { TrackActionMenu } from 'components/ActionMenu';
import { AUDIO_TYPES } from 'config/constants';
import { generateSelectorsFromTestIds } from 'helpers/utilities';
import useProtonPlayer from 'hooks/useProtonPlayer';
import useToggle from 'hooks/useToggle';
import { makeSelectUserDownloadableTracksByCategory } from 'redux/selectors';

import TracklistTableRowMobile from './TracklistTableRowMobile';

/**
 * [TracklistTableMobile]
 *
 * @param {object[]} tracks - tracks to be rendered in this table... probably tracks from a given release
 * @param {string} [queueName]
 * @param {object[]} [queueAudio] if track queue is different than displayed tracks (Promo Inbox) pass queueAudio
 * separately and it will be used instead of tracks
 * @param {boolean} [disabled] - if true, click actions will be disabled for this table
 * @param {boolean} [loading] - if true, Tombstone component will be rendered
 * @param {boolean} [inlinePromoWidget] - pass through to TracklistTableRowMobile, displays promo widget below
 * playing track
 */

const TracklistTableMobile = ({
  tracks = [],
  queueAudio,
  disabled,
  loading,
  inlinePromoWidget,
  // redux-connect
  downloadableTracks
}) => {
  const player = useProtonPlayer();
  const playing = player.currentTrack;

  const [menuTrack, setMenuTrack] = useState(null);
  const {
    isOpen: isTrackMenuOpen,
    open: openTrackMenu,
    close: closeTrackMenu
  } = useToggle();

  const toggleAudio = track => {
    if (disabled) return;

    player.send('playAudio', {
      queue: queueAudio || tracks,
      index: track.__queueIndex
    });
  };

  const openMenu = useCallback(
    track => {
      if (disabled) return;

      setMenuTrack(track);
      openTrackMenu();
    },
    [disabled, openTrackMenu]
  );

  if (loading) return <TracklistTableMobile.Tombstone />;

  return (
    <div data-testid={TracklistTableMobile.TEST_IDS.CONTAINER}>
      {menuTrack && (
        <TrackActionMenu
          track={menuTrack}
          isOpen={isTrackMenuOpen}
          close={closeTrackMenu}
        />
      )}

      {tracks.map(track => (
        <TracklistTableRowMobile
          track={track}
          key={track.id}
          disabled={disabled}
          onRowClick={toggleAudio}
          onActionClick={openMenu}
          active={playing?.id === track.id && player.type === AUDIO_TYPES.TRACK}
          inlinePromoWidget={inlinePromoWidget}
          canDownload={!!downloadableTracks.all[track.id]}
          isPromoTrack={!!downloadableTracks.promo[track.id]}
        />
      ))}
    </div>
  );
};

TracklistTableMobile.Tombstone = () => (
  <>
    {[...Array(3)].map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <TracklistTableRowMobile.Tombstone key={index} index={index} />
    ))}
  </>
);

TracklistTableMobile.TEST_IDS = {
  CONTAINER: 'TracklistTableMobile'
};

TracklistTableMobile.SELECTORS = generateSelectorsFromTestIds(
  TracklistTableMobile.TEST_IDS
);

TracklistTableMobile.propTypes = {
  disabled: PropTypes.bool,
  queueName: PropTypes.string,
  tracks: PropTypes.arrayOf(PropTypes.shape()),
  queueAudio: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
  inlinePromoWidget: PropTypes.bool,
  // redux connect props:
  downloadableTracks: PropTypes.shape()
};

export default connect(() => {
  const selectUserDownloadableTracksByCategory =
    makeSelectUserDownloadableTracksByCategory();

  return (state, ownProps) => ({
    // NOTE: selectUserDownloadableTracksByCategory takes { tracks } argument, same as props passed
    // to TracklistTableMobile. We don't deconstruct so that tracks argument isn't redefined on
    // every render
    downloadableTracks: selectUserDownloadableTracksByCategory(state, ownProps)
  });
})(TracklistTableMobile);
