import { useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { displayAlertError } from 'helpers/alert';
import { fetchPaginatedDataActions } from 'redux/actions/utilities';
import { isLocal } from 'config/constants';

const initialPaginationState = {
  data: [],
  meta: {
    current_page: 0,
    next_page: 1,
    prev_page: null,
    total_pages: null,
    total_count: null,
    per_page: null
  },
  __isFetching: false,
  __lastFetched: null,
  __fetchError: null
};

/**
 * [usePagination] - hook used for handling fetching of paginated data using Proton's API (V2).
 * Auto-handles cache invalidation and returning cached data if available, as well as fetching
 * the next available page of data.
 *
 * For examples of how to use, reference `config/pagination/README_pagination.md`
 *
 * @param {function} fetchApi - function that will hit paginated endpoint
 * @param {string} paginationReducer - path where pagination data will be stored in redux
 * @param {number} [paginationReducerId]
 * @param {string} [normalizeReducer] - path where data from pagination response will be normalized by id
 * @param {bool} [handleError=true] if false, the error will be thrown to the consumer to handle. Ex: fetch().catch(e => ...);
 *
 * @returns {object} returns methods and state for the following:
 *  - data: the latest data from union of paginated responses, or if no data fetched yet during this session, cached
 *          data is returned.
 *  - meta: the pagination state from the last success response
 *  - isFetching: boolean representing whether next page is being fetched
 *  - fetch: method to handle fetching next page of data. On first fetch, if cached data exists, the number of
 *          cached pages will be fetched in order to ensure we invalidate the cached data.
 *  - fetchCalled: will be false until fetch is called. This can be useful for managing loaded state
 *          or determining if you need to fetch intitial data still
 */

const usePagination = ({
  fetchApi,
  handleError = true,
  paginationReducer, // path for pagination data  ('feedback.labelFeedback')
  paginationReducerId,
  normalizeReducer
}) => {
  const isFetchingRef = useRef(false);
  const fetchCalledRef = useRef(false);
  const dispatch = useDispatch();

  const paginationReducerPath = paginationReducerId
    ? `${paginationReducer}.${paginationReducerId}`
    : paginationReducer;
  const paginationState = useSelector(state =>
    _.get(state, paginationReducerPath, initialPaginationState)
  );

  const {
    meta: { next_page, current_page, total_pages }
  } = paginationState;

  const data = useSelector(state => {
    if (normalizeReducer) {
      const entityIds = paginationState.data;
      return entityIds.map(id => _.get(state, `${normalizeReducer}.${id}.data`));
    }

    // if not normalized, data will be in pagination reducer's data attribute still
    return paginationState.data;
  });

  const fetchPaginatedData = useCallback(
    (args = {}) =>
      new Promise((resolve, reject) => {
        if (current_page === total_pages) {
          console.warn('Already fetched all pages');
          resolve();
        }

        if (isFetchingRef.current) {
          console.warn('Fetch already in progress');
          return;
        }

        if (!paginationReducer) {
          reject(new Error('[usePagination] must include "paginationReducer"'));
        }

        if (!fetchApi) {
          reject(new Error('[usePagination] must be provided the "fetchApi" param'));
        }

        isFetchingRef.current = true;
        fetchCalledRef.current = true;

        dispatch(
          fetchPaginatedDataActions.request({
            paginationReducer,
            paginationReducerId
          })
        );

        // TODO: I haven't figure out why yet, but it appears that next_page is occasionally null...
        // I haven't quite figured this out. It might be a weird dev condition where a page is reloaded
        // but the previous usePagination state is still there... next_page: null if all pages fetchedd
        if (next_page === null) {
          if (isLocal) {
            console.warn('Trying to fetch next page when all pages fetched!');
            debugger;
          }
          return;
        }

        fetchApi({ ...args, page: next_page || 1 })
          .then(({ data: response }) => {
            isFetchingRef.current = false;

            dispatch(
              fetchPaginatedDataActions.success({
                response,
                normalizeReducer,
                paginationReducer,
                paginationReducerId
              })
            );

            return resolve(response);
          })
          .catch(e => {
            isFetchingRef.current = false;

            dispatch(
              fetchPaginatedDataActions.error({
                paginationReducer,
                paginationReducerId,
                error: e
              })
            );

            if (handleError) {
              displayAlertError(e);
            } else {
              reject(e);
            }
          });
      }),
    [
      fetchApi,
      next_page,
      current_page,
      total_pages,
      handleError,
      normalizeReducer,
      paginationReducer,
      paginationReducerId,
      dispatch
    ]
  );

  return {
    data,
    meta: paginationState.meta,
    isFetching: paginationState.__isFetching,
    error: paginationState.__fetchError,
    lastFetched: paginationState.__lastFetched,
    fetch: fetchPaginatedData,
    fetchCalled: fetchCalledRef.current
  };
};

export default usePagination;
