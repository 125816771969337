import Icon from 'components/Icon';
import { COLORS } from 'config/constants';
import styled from 'styled-components';

export const TriggerContainer = styled.span`
  display: inline-block;
`;

export const Underline = styled.span`
  border-bottom: 1px dotted ${COLORS.GRAY_LIGHT};
`;

export const StyledIcon = styled(Icon)`
  position: relative;
  top: 0.2rem;
  left: 0.3rem;
`;

const TOOLTIP_BACKGROUND_COLOR = 'rgba(0, 0, 0, 0.87)';

export const TooltipArrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;

  &::after {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 0;
  }
`;

export const TooltipContainer = styled.div`
  background-color: ${TOOLTIP_BACKGROUND_COLOR};
  border-radius: 3px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 1.3;
  max-width: 40rem;
  padding: 1.1rem;
  transition: opacity 0.3s;
  z-index: 9999;

  &[data-popper-placement='right'] {
    ${TooltipArrow} {
      left: 0;

      &:after {
        border-color: transparent ${TOOLTIP_BACKGROUND_COLOR} transparent transparent;
        border-width: 0.5rem 0.4rem 0.5rem 0;
        left: -0.4rem;
        top: -0.4rem;
      }
    }
  }

  &[data-popper-placement='left'] {
    ${TooltipArrow} {
      right: 0;

      &:after {
        border-color: transparent transparent transparent ${TOOLTIP_BACKGROUND_COLOR};
        border-width: 0.5rem 0 0.5rem 0.4em;
        top: -4px;
      }
    }
  }

  &[data-popper-placement='top'] {
    ${TooltipArrow} {
      bottom: 0;

      &:after {
        border-color: ${TOOLTIP_BACKGROUND_COLOR} transparent transparent transparent;
        border-width: 0.4rem 0.5rem 0 0.5rem;
        left: -5px;
      }
    }
  }

  &[data-popper-placement='bottom'] {
    ${TooltipArrow} {
      top: -0.4rem;

      &:after {
        border-color: transparent transparent ${TOOLTIP_BACKGROUND_COLOR} transparent;
        border-width: 0 0.5rem 0.4rem 0.5rem;
      }
    }
  }
`;
