import React from 'react';

import { getDefaultArtistImage } from 'helpers/artists';
import Avatar, { AvatarProps } from '../Avatar';

/**
 * [ArtistAvatar] - handles rendering Avatar with defaults specific to the Artist entity
 *
 * See Avatar for other props available
 */

interface ArtistAvatarProps extends AvatarProps {
  name?: string;
}

const ArtistAvatar = ({
  imageUrl = '',
  name = '',
  lazy,
  loading,
  altTag = 'Artist Avatar',
  ...rest
}: ArtistAvatarProps) => {
  const avatarUrl = imageUrl ? encodeURI(imageUrl) : getDefaultArtistImage(name);

  return (
    <Avatar
      imageUrl={avatarUrl}
      title={name}
      circle
      altTag={altTag}
      lazy={lazy}
      loading={loading}
      {...rest}
    />
  );
};

ArtistAvatar.SIZES = Avatar.SIZES;

export default ArtistAvatar;
