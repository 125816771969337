import styled from 'styled-components';
import { animated } from 'react-spring';
import { COLORS } from 'config/constants/styles';

export const PopoverWrapper = styled.div`
  left: ${({ $left }) => `${$left}px`};
  position: ${({ $fixed }) => ($fixed ? 'fixed' : 'absolute')};
  top: ${({ $top }) => `${$top}px`};
  z-index: 9;
`;

// Because the card uses :after to create caret, need a child component that uses overflow hidden

// New comment (Rob): Are we sure we need overflow: hidden at all? Need to remove this to allow select
// Dropdowns to not get clipped
export const PopoverContent = styled.div`
  border-radius: 4px;
  /* overflow: hidden; */
`;

export const Card = styled(animated.div)`
  background: ${({ $background }) => $background || COLORS.WHITE};
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15), 0 0 4px rgba(0, 0, 0, 0.1);
  position: relative;

  &:after {
    background: ${({ $background }) => $background || COLORS.WHITE};
    border-radius: 2px;
    content: '';
    display: ${({ $caret }) => ($caret ? 'block' : 'none')};
    height: 1.4rem;
    position: absolute;
    transform: rotate(45deg);
    width: 1.4rem;
    z-index: -1;
    /* hack to prevent DOM warnings in browser console */
    ${props => props['data-caretstyles']}
  }

  & + & {
    margin-top: 1.5rem;
  }
`;
