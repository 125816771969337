import { takeEvery, put, fork, call, select } from 'redux-saga/effects';

import { dismissRelease } from 'redux/actions/promoReleases';
import {
  selectPromoReleaseRootById,
  selectPromoReleasesRoot
} from 'redux/selectors/promoReleases';
import { promos as promosApi } from 'api/promos';

function* callDismiss(action, prevReleaseState, prevPromoReleasesState) {
  const { releaseId } = action.payload;
  const { onError, optimistic } = action.meta || {};

  try {
    yield call(promosApi.dismissRelease, releaseId);

    // Leaving commented code in for debugging purposes since actually dismissing the promo releases
    // is likely undesirable.

    // ERROR TEST
    // yield call(delay, {
    //   errorMessage: `There was a problem dismissing release id ${releaseId}`
    // });

    // SUCCESS TEST
    // yield call(delay);

    // Retrieve trackIds that should be removed (currently only accessible from release state)
    const { trackIds } = yield select(selectPromoReleaseRootById, { releaseId });

    yield put(dismissRelease.success({ releaseId, trackIds }));
  } catch (e) {
    yield put(
      dismissRelease.error({
        releaseId,
        errorMessage: e.message,
        prevReleaseState,
        optimistic,
        prevPromoReleasesState
      })
    );
    if (onError) onError(e);
  }
}

/**
 * [dismissPromoRelease] - optimistically removes a release from redux before sending api request
 */

function* dismissPromoRelease(action) {
  const { releaseId } = action.payload;
  const { optimistic } = action.meta;

  const prevReleaseState = yield select(selectPromoReleaseRootById, { releaseId });
  const prevPromoReleasesState = yield select(selectPromoReleasesRoot);

  // We fork the response since we want to optimistically set state here...
  yield fork(callDismiss, action, prevReleaseState, prevPromoReleasesState);

  if (optimistic) {
    yield put(dismissRelease.success({ releaseId, optimistic: true }));
  }
}

export function* watchDismissPromoRelease() {
  yield takeEvery(dismissRelease.request().type, dismissPromoRelease);
}
