import { AUDIO_TYPES } from 'config/constants';

export const MIX_INITIAL_STATE = {
  current_time: '',
  currentlisteners: '',
  start_time: '',
  end_time: '',
  artist_id: '',
  artist_name: '',
  artist_slug: '',
  max_l: 0,
  totalListenCount: 0,
  mix_id: '',
  mix_title: '',
  mix_length: '',
  distinct_artists: [],
  order: '',
  total_sets: '',
  episode_id: '',
  episode_date: '',
  show_id: '',
  show_title: '',
  show_image: '',
  show_slug: '',
  genre_slug: '',
  tracklist_total: '',
  tracklist: [],
  type: AUDIO_TYPES.MIX,
  url: '',
  tempUrl: '',
  waveform_url: '',
  next: {}
};

export default MIX_INITIAL_STATE;
