import React from 'react';

import Alert from '../Alert';

export const MailToMessage = ({ message }: { message: string }) => (
  <Alert.Content>
    <span>{message}</span>
    <span>
      &nbsp;or contact{' '}
      <a href="mailto:support@protonradio.com" target="_top">
        support@protonradio.com
      </a>{' '}
      for help.
    </span>
  </Alert.Content>
);
