import { batch } from 'react-redux';

import * as api from 'api';
import { ALGOLIA } from 'config/constants';
import { createAsyncActions } from './utilities';
import { ASYNC_OPERATIONS } from 'config/constants';

export const fetchArtistActions = createAsyncActions('ARTIST', ASYNC_OPERATIONS.FETCH);

export const fetchArtist = artistId => dispatch => {
  dispatch(fetchArtistActions.request({ id: artistId }));

  return api
    .getArtist(artistId)
    .then(({ data }) => {
      dispatch(fetchArtistActions.success({ id: artistId, data }));
      return data;
    })
    .catch(e => {
      dispatch(fetchArtistActions.error({ id: artistId, error: e }));
    });
};

export const topArtistsActions = createAsyncActions(
  'TOP_ARTISTS',
  ASYNC_OPERATIONS.FETCH
);

// Async Actions
export const fetchTopArtists =
  ({ sinceDaysAgo }) =>
  dispatch => {
    dispatch(topArtistsActions.request);

    return api
      .getTopArtists({ sinceDaysAgo })
      .then(({ data }) => {
        dispatch(topArtistsActions.success({ data }));
      })
      .catch(error => {
        dispatch(topArtistsActions.error({ error }));
      });
  };

export const fetchArtistAlgolia = artistId => dispatch => {
  const artistIds = Array.isArray(artistId) ? artistId : [artistId];

  const requestActions = artistIds.map(id => fetchArtistActions.request({ id }));
  batch(() => requestActions.forEach(dispatch));

  const facetFilters = [artistIds.map(id => `id:${id}`)];
  return api
    .searchAlgolia({
      index: ALGOLIA.ARTIST_INDEX,
      facetFilters
    })
    .then(response => {
      if (Array.isArray(artistId)) {
        const artists = response.hits;

        if (artists.length !== artistIds.length) {
          throw new Error(`One or more artist(s) could not be found`);
        }

        const successActions = artists.map(data =>
          fetchArtistActions.success({ id: artistId, data })
        );
        batch(() => successActions.forEach(dispatch));
        return artists;
      }

      const artist = response.hits[0];
      if (!artist) throw new Error(`Artist with id ${artistId} could not be found`);
      dispatch(fetchArtistActions.success({ id: artistId, data: artist }));
      return artist;
    })
    .catch(error => {
      const errorActions = artistIds.map(id => fetchArtistActions.error({ id, error }));
      batch(() => errorActions.forEach(dispatch));
    });
};
