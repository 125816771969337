import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';
import { parseISO, differenceInSeconds } from 'date-fns';
import _ from 'lodash';

import { garbageCollect, STALE_DURATION_TOP_10_MIXES } from 'redux/actions/utilities';
import {
  addMixes,
  removeMixes,
  fetchTopMixesRequest,
  fetchTopMixesSuccess,
  fetchTopMixesError
} from 'redux/actions/mixes';
import { AUDIO_TYPES } from 'config/constants';
import { getMixTotalListenCount } from 'helpers/mixes';

import { MIX_INITIAL_STATE } from './initialState';

// returns mix with relevant data, removes irrelevant fields in algolia
const _formatMix = (mix = MIX_INITIAL_STATE) => {
  // if algolia mix, strip out _highlightResult field if passed
  const { _highlightResult, algolia_tracklist, sum_l, ondemandlistens, ...mixData } = mix;

  return {
    ...mixData,
    type: AUDIO_TYPES.MIX,
    tracklist: mix.tracklist || algolia_tracklist,
    lastFetched: Date.now(),
    algolia: !!mix.algolia_tracklist,
    totalListenCount: getMixTotalListenCount(mix)
  };
};

// -- REDUCERS --
const byIdInitialState = {};

const addMixesToMixesById = (state, { mixes }) =>
  mixes.reduce(
    (mixesById, mix) => ({
      ...mixesById,
      [mix.id]: _formatMix(mix)
    }),
    { ...state }
  );

const byId = createReducer(
  {
    [addMixes]: addMixesToMixesById,
    [removeMixes]: (state, { mixIds }) => _.omit(state, { mixIds }),
    [fetchTopMixesSuccess]: addMixesToMixesById,
    [garbageCollect]: (state, { persistMixIds }) => {
      // Clear all mixes except for those passed in by persistMixIds array
      if (persistMixIds && persistMixIds.length > 0) {
        return persistMixIds.reduce((mixesById, mixId) => {
          if (state[mixId]) {
            return {
              ...mixesById,
              [mixId]: state[mixId]
            };
          }
          return mixesById;
        }, byIdInitialState);
      }

      return byIdInitialState;
    }
  },
  byIdInitialState
);

// Top Mixes

const topMixIdsInitialState = {
  data: [],
  isFetching: false,
  lastFetched: null,
  error: null
};

const topMixIds = createReducer(
  {
    [fetchTopMixesSuccess]: (state, { mixes }) => ({
      ...state,
      data: mixes.map(mix => mix.id), // preserves order returned by api
      lastFetched: new Date().toISOString()
    }),
    [fetchTopMixesRequest]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchTopMixesError]: (state, { error }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [garbageCollect]: state => {
      if (!state.lastFetched) return topMixIdsInitialState;

      const fetchAge = differenceInSeconds(new Date(), parseISO(state.lastFetched));
      if (fetchAge > STALE_DURATION_TOP_10_MIXES) return topMixIdsInitialState;

      // only keep top 100 if not stale
      return {
        ...state,
        data: state.data.slice(0, 100)
      };
    }
  },
  topMixIdsInitialState
);

export default combineReducers({ byId, topMixIds });
