import { graphql } from 'gql';

// NOTE: This can be removed once feature is launched
export const DiscoveryModeFeatureFlagQuery = graphql(`
  query discoveryModeFeatureFlagQuery {
    viewer {
      id
      artists {
        id
        configs {
          discoveryModeFeatureEnabled
        }
      }
    }
  }
`);

export const EligibleTracksFragment = graphql(`
  fragment EligibleTracksFields on DiscoveryModeTrack {
    enabled
    id
    intentRate
    listeners
    listenersLift
    saves
    spotifyRecommend
    streams
    streamsLift
    track {
      id
      title
      version
      originalArtists {
        id
        name
      }
      featuredArtists {
        id
        name
      }
      remixArtists {
        id
        name
      }
      release {
        id
        coverArt(size: SMALL) {
          url
        }
        label {
          id
          name
        }
      }
    }
  }
`);

export const DiscoveryModeStateQuery = graphql(`
  query DiscoveryMode {
    discoveryMode {
      nextSchedulingStartsAt
      runningCampaign {
        id
        title
        startDate
        endDate
        state
        schedulingStartsAt
        schedulingEndsAt
        eligibleTracks {
          ...EligibleTracksFields
        }
      }
      upcomingCampaign {
        id
        title
        startDate
        endDate
        state
        schedulingStartsAt
        schedulingEndsAt
        eligibleTracks {
          ...EligibleTracksFields
        }
      }
    }
    viewer {
      id
      artists {
        id
        name
        configs {
          discoveryModeFeatureEnabled
          hasDiscoveryModeEnabled
        }
      }
    }
  }
`);

export const EnableDisableTrackMutation = graphql(`
  mutation EnabledDisableTrack($input: DiscoveryModeEnableDisableTrackInput!) {
    discoveryModeEnableDisableTrack(input: $input) {
      discoveryModeTrack {
        id
        enabled
        track {
          id
          title
          version
          originalArtists {
            id
            name
          }
          release {
            id
            coverArt(size: SMALL) {
              url
            }
          }
        }
      }
      errors
      errorDetails {
        message
        path
      }
    }
  }
`);
