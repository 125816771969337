import _ from 'lodash';

export const selectAllLabels = state => state.labels.byId;

// TODO: Refactor to new format used by reducer utilities i.e. { data: {}, __isFetching, etc}
const DEFAULT_LABEL_STATE = {
  __isFetching: false,
  __lastFetched: null
};

export const selectLabelById = (state, { labelId }) =>
  selectAllLabels(state)[labelId] || DEFAULT_LABEL_STATE;

const DEFAULT_LABEL_ARTIST_STATE = {
  __lastFetched: null,
  __isFetching: true,
  data: []
};

export const selectLabelArtistsByLabelIdRoot = (state, { labelId }) =>
  _.get(state, `labels.artistsByLabelId.${labelId}`, DEFAULT_LABEL_ARTIST_STATE);

export const selectLabelArtistsByLabelId = (state, { labelId }) =>
  selectLabelArtistsByLabelIdRoot(state, { labelId }).data;

export const selectLabelTopArtistsByLabelIdRoot = (state, { labelId }) =>
  _.get(state, `labels.topArtistsByLabelId.${labelId}`, DEFAULT_LABEL_ARTIST_STATE);

export const selectLabelTopArtistsByLabelId = (state, { labelId }) =>
  selectLabelTopArtistsByLabelIdRoot(state, { labelId }).data;
