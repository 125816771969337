import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Pusher from 'pusher-js';
import { pusher, isProduction } from 'config/constants';

import {
  setRadio as setRadioAction,
  setRadioListeners as setRadioListenersAction
} from 'redux/actions/radio';
import {
  addAlertMessage as addAlertMessageAction,
  incrementErrorCount as incrementErrorCountAction,
  resetErrorCount as resetErrorCountAction
} from 'redux/actions/ui';

const activateUpdate = async () => {
  const registration = navigator.serviceWorker && (await navigator.serviceWorker.ready);
  if (registration?.waiting) {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  } else {
    window.location.reload();
  }
};

export const appUpdatedMessage = {
  id: 'AppUpdatedMessage',
  message:
    'A new version of the app is available. Refresh your browser or click update to activate.',
  actions: [
    {
      text: 'Update',
      onClick: () => activateUpdate()
    }
  ],
  timeout: false,
  dismissable: false,
  type: 'notice',
  meta: 'version'
};
class PushHandler extends React.Component {
  componentDidMount() {
    const { incrementErrorCount, resetErrorCount } = this.props;

    this.socket = new Pusher(pusher.APP_KEY, {
      cluster: pusher.CLUSTER
    });

    // NOTE: uncomment line below for debugging
    // Pusher.log = (msg) => { console.log(msg); };

    this.channel = this.socket.subscribe(pusher.channels.PROTON_RADIO);

    // handle channel subscription errors
    this.socket.connection.bind('error', err => {
      if (!isProduction) console.warn('Pusher connection error: ', err);

      this.connectionError = true;
      incrementErrorCount();
    });

    this.socket.connection.bind('connected', () => {
      if (this.connectionError) {
        this.connectionError = false;
        resetErrorCount();
      }
    });

    // NOTE: may want to handle varying state changes in the future.  Reference:
    // https://pusher.com/docs/client_api_guide/client_connect#connection-states
    // https://stackoverflow.com/questions/29070453/pusher-api-how-can-i-check-if-the-connection-is-established

    // The following event are always being listened to by the app
    this.channel.bind(pusher.events.APP_UPDATE, this._handleUpdateNotification);
    this.channel.bind(pusher.events.NOW_PLAYING, this._handleNowPlayingUpdate);
    this.channel.bind(pusher.events.LISTENER_COUNT, this._handleListenerCountUpdate);
  }

  componentWillUnmount() {
    this.channel.unbind();

    this.socket.unsubscribe(this.channel);
  }

  _handleUpdateNotification = data => {
    const { addAlertMessage } = this.props;
    if ('serviceWorker' in navigator) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigator.serviceWorker.ready.then(registration => registration.update());
    }

    if (!isProduction) {
      console.warn('App update detected', data);
      if (window.location.origin !== data.url) return;
    }

    addAlertMessage({ ...appUpdatedMessage, persistAfterLogout: true });
  };

  _handleListenerCountUpdate = data => {
    const { setRadioListeners } = this.props;
    // TODO: In the future (when multiple radio stations supported),
    //  the server should pass a radio Id
    const radioId = 1;
    setRadioListeners(data.currentlisteners, radioId);
  };

  _handleNowPlayingUpdate = data => {
    const { setRadio } = this.props;
    // NOTE: data = mix/commerical/single object
    const playing = data;
    const radioId = 1;
    setRadio(playing, radioId);
  };

  render() {
    return null;
  }
}

PushHandler.propTypes = {
  addAlertMessage: PropTypes.func,
  setRadio: PropTypes.func.isRequired,
  setRadioListeners: PropTypes.func.isRequired,
  incrementErrorCount: PropTypes.func.isRequired,
  resetErrorCount: PropTypes.func.isRequired
};

export default connect(null, {
  setRadio: setRadioAction,
  setRadioListeners: setRadioListenersAction,
  addAlertMessage: addAlertMessageAction,
  incrementErrorCount: incrementErrorCountAction,
  resetErrorCount: resetErrorCountAction
})(PushHandler);
