/* global window */
import PropTypes from 'prop-types';
import React from 'react';
import _isUndefined from 'lodash/isUndefined';

class ScrollBox extends React.Component {
  constructor(props) {
    super(props);

    this.duration = props.duration || 500;
    this._scroll = this._scroll.bind(this);
    // track whether scroll is being programatically animated
    this.animateScroll = false;
  }

  componentDidMount() {
    const { x, y } = this.props;
    this.el.scrollLeft = x;
    this.el.scrollTop = y;
  }

  componentDidUpdate() {
    const nextProps = this.props;

    const scrollX = _isUndefined(nextProps.x) ? this.el.scrollLeft : nextProps.x;
    const scrollY = _isUndefined(nextProps.y) ? this.el.scrollTop : nextProps.y;

    if (this.el && this.el.scrollLeft !== scrollX) {
      this.animateScroll = true;
      window.requestAnimationFrame(time => {
        const startTime = time;
        const direction = 'horizontal';
        // requestAnimationFrame is async and this.el is not always defined
        if (this.el && !Number.isNaN(this.el.scrollLeft)) {
          this._scroll(time, this.el.scrollLeft, scrollX, startTime, direction);
        }
      });
    }

    if (this.el && this.el.scrollTop !== scrollY) {
      this.animateScroll = true;
      window.requestAnimationFrame(time => {
        const startTime = time;
        const direction = 'vertical';
        // requestAnimationFrame is async and this.el is not always defined
        if (this.el && !Number.isNaN(this.el.scrollTop)) {
          this._scroll(time, this.el.scrollTop, scrollY, startTime, direction);
        }
      });
    }
  }

  _scroll(time, startScroll, scrollTo, startTime, direction) {
    const elapsedTime = time - startTime;
    let progress = elapsedTime / this.duration;
    if (progress > 1) progress = 1;
    const scroll = startScroll + (scrollTo - startScroll) * progress;

    if (!this.el) return;

    if (direction === 'horizontal') {
      this.el.scrollLeft = scroll;
    } else {
      this.el.scrollTop = scroll;
    }
    if (elapsedTime < this.duration) {
      window.requestAnimationFrame(_time =>
        this._scroll(_time, startScroll, scrollTo, startTime, direction)
      );
    } else {
      // Make sure scrolled into position at end of animation duration
      if (direction === 'horizontal') {
        this.el.scrollLeft = scrollTo;
      } else {
        this.el.scrollTop = scrollTo;
      }
      window.cancelAnimationFrame(this._scroll);
      setTimeout(() => {
        this.animateScroll = false;
      }, 300);
    }
  }

  render() {
    const { scrollClass, children, handleScroll } = this.props;
    return (
      <div
        className={`ScrollBox ${scrollClass}`}
        ref={el => {
          this.el = el;
        }}
        onScroll={e => handleScroll(e, this.animateScroll)}
      >
        {children}
      </div>
    );
  }
}

ScrollBox.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  duration: PropTypes.number,
  // easing: PropTypes.string,
  children: PropTypes.element,
  handleScroll: PropTypes.func,
  scrollClass: PropTypes.string
};

export default ScrollBox;
