import { createReducer } from 'redux-act';
import { combineReducers } from 'redux';
import _ from 'lodash';

import {
  fetchLabelSubscribersActions,
  fetchRecommendedSubscribersActions,
  removeRecommendedSubscribers,
  setLabelSubscribers,
  updateLabelSubscriber
} from 'redux/actions/labelSubscribers';
import { clearUser } from 'redux/actions/user';
import { garbageCollect } from 'redux/actions/utilities';

import {
  handleFetchRequest,
  handleFetchSuccess,
  handleFetchError,
  restoreInitialFetchState
} from './utilities';

const handleSetSubscriberResponse = (state, { id, artistIds, entities }) => {
  const data = artistIds.map(artistId => entities.subscribers[artistId]);
  return handleFetchSuccess(state, { data, id, normalize: false, overwrite: true });
};

const subscribersByLabelId = createReducer(
  {
    [fetchLabelSubscribersActions.request]: handleFetchRequest,
    [fetchLabelSubscribersActions.success]: handleSetSubscriberResponse,
    [fetchLabelSubscribersActions.error]: handleFetchError,
    [updateLabelSubscriber]: (state, { labelId, artistId, data }) => {
      const prevData = state[labelId].data;

      return {
        ...state,
        [labelId]: {
          ...state[labelId],
          data: prevData.map(sub =>
            sub.artistId === artistId ? { ...sub, ...data } : sub
          )
        }
      };
    },
    [setLabelSubscribers]: handleSetSubscriberResponse,
    [clearUser]: () => ({}),
    [garbageCollect]: restoreInitialFetchState
  },
  {}
);

const recommendedSubscribersByLabelId = createReducer(
  {
    [fetchRecommendedSubscribersActions.request]: handleFetchRequest,
    [fetchRecommendedSubscribersActions.success]: (state, { data, id }) =>
      handleFetchSuccess(state, { data, id, normalize: true }),
    [fetchRecommendedSubscribersActions.error]: handleFetchError,
    [removeRecommendedSubscribers]: (state, { labelId, artistIds }) => {
      const prevRecommendedArtistIds = state[labelId].data;

      return {
        ...state,
        [labelId]: {
          ...state[labelId],
          data: _.difference(prevRecommendedArtistIds, artistIds)
        }
      };
    },
    [clearUser]: () => ({}),
    [garbageCollect]: restoreInitialFetchState
  },
  {}
);

const spreadArtistEntities = (state, { entities }) => ({
  ...state,
  ...entities.artists
});

const artistsById = createReducer(
  {
    [fetchLabelSubscribersActions.success]: spreadArtistEntities,
    [fetchRecommendedSubscribersActions.success]: spreadArtistEntities,
    [setLabelSubscribers]: spreadArtistEntities,
    [removeRecommendedSubscribers]: (state, { artistIds }) => _.omit(state, artistIds),
    [clearUser]: () => ({})
  },
  {}
);

export default combineReducers({
  subscribersByLabelId,
  recommendedSubscribersByLabelId,
  // NOTE: the label subscriber response returns a partial artist response. The attributes match the artist
  // attributes in artist.byId reducer, but is are limited. Because of this we store this artist data separately.
  artistsById
});

// const EXAMPLE_STATE = {
//   subscribersByLabelId: {
//     123: {
//       data: [
//         {
//           promo_access: true,
//           state: null,
//           // NOTE: Since labels will likely have common artist subscribers, artist data is
//           // normalized in state.labelSubscribers.artistsById
//           artist_id: 5082,
//           date_added_epoch: 21481351521
//         }
//       ],
//       __lastFetched: 21481351521,
//       __isFetching: false
//     }
//   },
//   recommendedSubscribersByLabelId: {
//     123: {
//       data: [984, 456, 789],
//       __lastFetched: 21481351521,
//       __isFetching: false
//     }
//   },
//   artistsById: {
//     5082: {
//       id: 5082,
//       name: 'Da Fresh',
//       slug: '/artists/5082/da-fresh',
//       verified: true,
//       image_type: 'avatar',
//       image_url:
//         'https://proton-profile-images.storage.googleapis.com/avatars/avatars-000679437041-x6wn7b-t500x500.jpg',
//       has_image: true
//     }
//   }
// };

//  NOTE: Recommended subscribers response:
// [
//   {
//     id: 52127,
//     name: 'Mayro',
//     slug: '/artists/52127/mayro',
//     verified: true,
//     image_type: 'avatar',
//     image_url:
//       'https://proton-profile-images.storage.googleapis.com/avatars/avatars-000386760449-hjrqpl-t500x500.jpg',
//     has_image: true
//   }
// ];
// //

// NOTE: Label subscribers response:
// [
//   {
//     promo_access: true,
//     state: null,
//     artist: {
//       id: 5082,
//       name: 'Da Fresh',
//       slug: '/artists/5082/da-fresh',
//       verified: true,
//       image_type: 'avatar',
//       image_url:
//         'https://proton-profile-images.storage.googleapis.com/avatars/avatars-000679437041-x6wn7b-t500x500.jpg',
//       has_image: true
//     },
//     date_added_epoch: 1594851232
//   }
// ];
