import React from 'react';
import _ from 'lodash';
import { GroupBase, OptionProps } from 'react-select';

interface SelectOptionProps {
  data: {
    label: string;
    value: string;
    testId?: string;
  };
  className: string;
  cx: (classNames: { [name: string]: boolean }, currentClass) => string;
  getStyles: (
    key: string,
    props: GroupBase<Omit<GroupBase<typeof Option>, 'options'>>
  ) => React.CSSProperties;
  isDisabled: boolean;
  isFocused: boolean;
  isSelected: boolean;
  innerRef: React.RefCallback<HTMLDivElement>;
  innerProps: OptionProps['innerProps'];
  options: any;
}
const SelectOption = (props: SelectOptionProps) => {
  const {
    data: { testId, label },
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps
  } = props;

  const style = { ...getStyles('option', props) };

  return (
    <div
      ref={innerRef}
      data-testid={testId || `option-${_.kebabCase(String(label))}`}
      style={style}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected
        },
        className
      )}
      {...innerProps}
    >
      {label}
    </div>
  );
};

export default SelectOption;
