import { createReducer } from 'redux-act';
import { combineReducers } from 'redux';
import _ from 'lodash';

import { ENTITY_TYPES } from 'config/constants';
import {
  createFollowedEntityActions,
  deleteFollowedEntityActions,
  fetchFollowedEntityActions
} from 'redux/actions/followed';

export const initialFollowedState = {
  id: null,
  phpbb_user_id: null,
  entity_id: null,
  entity_type: '',
  date_created: ''
};

// Currently just supporting artist follows
const byTypeByIdInitialState = {
  [ENTITY_TYPES.ARTIST]: {},
  [ENTITY_TYPES.SHOW]: {}
};

const byEntityTypeAndEntityId = createReducer(
  {
    [createFollowedEntityActions.success]: (state, { entityId, entityType, data }) => ({
      ...state,
      [entityType]: {
        ...state[entityType],
        [entityId]: data
      }
    }),
    [deleteFollowedEntityActions.success]: (state, { entityId, entityType }) => ({
      ...state,
      [entityType]: _.omit(state[entityType], entityId)
    }),
    [fetchFollowedEntityActions.success]: (state, { data }) => ({
      ...state,
      ...data.reduce((accum, followRecord) => {
        const { entity_type, entity_id } = followRecord;

        return {
          ...accum,
          [entity_type]: {
            ...accum[entity_type],
            [entity_id]: followRecord
          }
        };
      }, {})
    })
  },
  byTypeByIdInitialState
);

export default combineReducers({
  byEntityTypeAndEntityId
});
