import { createSelector } from '@reduxjs/toolkit';

import { FETCHING_STATE } from 'redux/reducers/utilities';

export const selectShows = state => state.shows;

const selectAllShows = state => selectShows(state).byId;

const DEFAULT_ROOT_STATE = { data: {}, ...FETCHING_STATE };
export const selectShowRootById = (state, { id }) =>
  selectAllShows(state)[id] || DEFAULT_ROOT_STATE;

export const selectShowById = (state, { id }) => selectShowRootById(state, { id }).data;

const selectTopShowsIds = (state, { limit }) => {
  const topShowsIds = state.shows.topShowsIds.data;
  return limit ? topShowsIds.slice(0, limit) : topShowsIds;
};

export const selectTopShows = createSelector(
  [selectAllShows, selectTopShowsIds],
  (showsById, topShowsIds) =>
    topShowsIds.reduce((accum, id) => {
      const show = showsById[id] && showsById[id].data;
      if (!show) return accum;
      return [...accum, show];
    }, [])
);
