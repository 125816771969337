import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field, getFormMeta, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { Keys } from 'react-keydown';
import qs from 'query-string';

// Deprecated - replace with SearchInput on a rainy day.  Some nuances with icon / background colors, etc
// that makes this tricky to swap out

/**
 * [SearchInputUniversal] - returns a redux form wrapped input with common characteristics of a search input
 *
 * @prop {string} form - name of the form that will be used by redux form
 * @prop {func} onChange - must provide onChange that sets state (or redux state) to provide new `searchText`
 * @prop {string} searchText - text currently entered in the input form.  Controlled component.
 * @prop {func} onClear - function that runs on clicking 'X' icon or pressing ESC.
 * Function passed should clear the state for `searchText` and any other desired side effects
 * @prop {func} onEsc - (optional) if there are additional functions you want to run in addition
 * to onEsc, pass them here
 * @prop {string} urlSearchParam - (optional) - if passed, component check for passed query param in url on mount.
 * If search param is present, the form will be initialize to have that value.
 * @prop {string} placeholder (optional)
 * @prop {func} onSubmit - (optional)
 * @prop {func} onFocus - (optional)
 * @prop {bool} disabled - (optional)
 * @prop {string} id = (optional)
 * @prop {string} inputClass - (optional) provide additional css rules for the clear search input. Overriding
 * @prop {string} clearIconClass - (optional) provide additional css rules for the clear icon button. Overriding
 */

// NOTE: since using redux-form, wasn't practical to make a purely presentational only component
const SEARCH_INPUT_NAME = 'search';

class SearchInputUniversal extends Component {
  constructor() {
    super();

    this.state = {
      showClearButton: false
    };

    this._onEsc = this._onEsc.bind(this);
    this._handleSearchTextClear = this._handleSearchTextClear.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }

  componentDidMount() {
    const { urlSearchParam } = this.props;
    if (urlSearchParam) {
      const { searchText, autofill, onInputChange } = this.props;
      // Check for url query for search
      const params = qs.parse(window.location.search);
      const searchParam = params[urlSearchParam];
      // If url param doesn't match search param, then likely reloading or visiting from url.
      if (searchParam && searchParam !== searchText) {
        // fill search input and toggle search
        autofill(SEARCH_INPUT_NAME, searchParam);
        onInputChange(null, searchParam);
      }
    }
  }

  componentDidUpdate(prevProps) {
    // Only implement keyboard listener when input is focused
    if (!prevProps.focused && this.props.focused && !this.keyboardListener) {
      this.keyboardListener = true;
      document.addEventListener('keydown', this._onEsc);
    }

    if (prevProps.focused && !this.props.focused && this.keyboardListener) {
      this.keyboardListener = false;
      document.removeEventListener('keydown', this._onEsc);
    }

    // active input meta changes before onClick can fire, hiding the clear icon before click event fires
    // To circumvent the issue, managing icon display using seperate attribute in component state
    if (prevProps.focused !== this.props.focused) {
      setTimeout(() => {
        this.setState({ showClearButton: this.props.focused });
      }, 200);
    }
  }

  componentWillUnmount() {
    if (this.keyboardListener) {
      document.removeEventListener('keydown', this._onEsc);
    }
  }

  _onEsc(event) {
    if (event.keyCode === Keys.ESC) {
      const { onInputClear, onEsc } = this.props;
      this._handleSearchTextClear();
      if (onInputClear) onInputClear();
      if (onEsc) onEsc();

      this.SearchInput.getRenderedComponent().blur();
    }
  }

  _handleSearchTextClear() {
    const { reset, onInputClear, form } = this.props;
    reset(form);

    // run function if passed by parent (i.e. clear search text & results)
    if (onInputClear) onInputClear();

    // Refocus on input since clicking on element outside of input field
    this.SearchInput.getRenderedComponent().focus();

    // make sure if previously scrolled, go back to top
    window.scrollTo(0, 0);
  }

  _onSubmit() {
    const { onSubmit } = this.props;
    this.SearchInput.getRenderedComponent().blur();
    if (onSubmit) onSubmit();
  }

  render() {
    const {
      handleSubmit,
      searchText,
      placeholder,
      disabled,
      onInputChange,
      onInputFocus,
      id = '',
      inputClass,
      clearIconClass,
      style = {},
      testId
    } = this.props;

    return (
      <form onSubmit={handleSubmit(this._onSubmit)}>
        <div className="SearchInput__container">
          <Field
            id={id}
            data-testid="SearchInput"
            name={SEARCH_INPUT_NAME}
            style={style}
            className={inputClass || 'SearchInput'}
            forwardRef
            ref={el => {
              this.SearchInput = el;
            }}
            component="input"
            type="text"
            placeholder={placeholder || 'Search'}
            autoComplete="off"
            disabled={disabled}
            onChange={onInputChange}
            onFocus={onInputFocus}
            data-test={testId}
          />

          {this.state.showClearButton && searchText && (
            <div
              className={clearIconClass || 'SearchInput__clear'}
              onClick={this._handleSearchTextClear}
              role="button"
              tabIndex="0"
            />
          )}
        </div>
      </form>
    );
  }
}

SearchInputUniversal.propTypes = {
  // redux form:
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  autofill: PropTypes.func,

  // connect props:
  searchText: PropTypes.string,
  focused: PropTypes.bool,

  // Container
  testId: PropTypes.string,
  form: PropTypes.string,
  onSubmit: PropTypes.func,
  onInputChange: PropTypes.func,
  onInputClear: PropTypes.func,
  onInputFocus: PropTypes.func,
  onEsc: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  inputClass: PropTypes.string,
  clearIconClass: PropTypes.string,
  urlSearchParam: PropTypes.string,
  style: PropTypes.shape()
};

SearchInputUniversal.SELECTORS = {
  INPUT: '[data-testid=SearchInput]'
};

export default compose(
  reduxForm(),
  connect((state, ownProps) => {
    const formName = ownProps.form;
    const formMeta = getFormMeta(formName)(state);

    return {
      focused: _get(formMeta, 'search.active'),
      searchText: formValueSelector(formName)(state, 'search')
    };
  }, null)
)(SearchInputUniversal);
