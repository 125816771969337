import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';

import { BREAKPOINTS, Z_INDEX } from 'config/constants';
import { addAlertMessage, removeAlertMessage } from 'redux/actions/ui';

import Alert, { AlertProps } from './Alert';
import { MakeKeyOptional } from 'types';

export const StyledAlertContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;
  position: fixed;
  right: 0;
  top: 6rem;
  z-index: ${Z_INDEX.ALERTS};

  @media (max-width: ${BREAKPOINTS.LG}px) {
    /* Adjust top position if thinner nav bar */
    top: 5rem;
  }

  @media screen and (max-width: ${BREAKPOINTS.MD}px) {
    display: block;
    left: 0;
    padding: 0rem 1rem;
    right: auto;
    width: 100%;
  }

  @media screen and (max-width: ${BREAKPOINTS.SM}px) {
    z-index: ${Z_INDEX.ALERTS_MOBILE};
  }
`;

export type AlertItem = MakeKeyOptional<AlertProps, 'dismissAlert'> & {
  id: string | number;
};

const AlertContainer = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const dispatch = useAppDispatch();
  const alerts: AlertItem[] = useAppSelector(state => state.ui.alerts);

  // watch for message parameter in the url & auto-display alert if present
  useEffect(() => {
    const { message, ...nextQueryParams } = queryString.parse(search);

    if (message) {
      const displayMessage = message instanceof Array ? message.join(' ') : message;
      dispatch(
        addAlertMessage({
          message: displayMessage,
          type: 'message',
          timeout: false,
          // jwtValidation may trigger logout after this alert is displayed
          persistAfterLogout: true
        })
      );

      // Remove message from url & history once displayed
      const nextQueryString = queryString.stringify(nextQueryParams);
      const nextQueryStringFormatted = nextQueryString ? `?${nextQueryString}` : '';

      history.replace(pathname + nextQueryStringFormatted);
    }
  }, [dispatch, search, pathname, history]);

  const transitions = useTransition(alerts, item => item.id, {
    from: { transform: 'translate3d(0,-40px,0)', opacity: 0 },
    enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
    leave: { transform: 'translate3d(0,-40px,0)', opacity: 0 }
  });

  return (
    <StyledAlertContainer data-testid={AlertContainer.TEST_IDS.CONTAINER}>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={props}>
          <Alert
            key={item.id}
            dismissAlert={() => dispatch(removeAlertMessage({ id: item.id }))}
            {...item}
          />
        </animated.div>
      ))}
    </StyledAlertContainer>
  );
};

AlertContainer.TEST_IDS = {
  CONTAINER: 'AlertContainer'
};

export default AlertContainer;
