import axios from 'axios';

import { API_BASE_V2, AUDIO_TYPES } from 'config/constants';

export const getMix = mixId =>
  axios.get(`${API_BASE_V2}/mixes/${mixId}`).then(({ data }) => data);

export const trackMixEvent = (mixId, event) =>
  axios.post(`${API_BASE_V2}/mixes/${mixId}/event`, event, {
    _unloadAlert_: false
  });

export const getTopMixes = ({ since_days_ago = 31, except = '', limit = 100 }) =>
  axios
    .get(`${API_BASE_V2}/popular/mixes`, {
      params: {
        since_days_ago,
        except,
        limit
      }
    })
    // add '__audioType' attribute
    .then(({ data: response }) => ({
      ...response,
      data: response.data.map(mix => ({
        ...mix,
        __audioId: mix.id,
        __audioType: AUDIO_TYPES.MIX
      }))
    }));

export const downloadMix = ({ id }) =>
  axios.get(`${API_BASE_V2}/mixes/${id}/download`).then(response => {
    const { success, url } = response.data;
    if (success) {
      // For now, using location.assign to trigger download in same browser window.
      // If this causes problems look into XMLHttpRequest
      window.location.assign(url);
    }

    return response.data;
  });
