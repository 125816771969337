import React from 'react';
import { Link } from 'react-router-dom';

import { selectUser } from 'redux/selectors';
import { getProUserVerificationPath } from 'routes/ProUserAccountVerification/helpers';
import { useAppSelector } from 'hooks/redux';
import { V2User } from 'types';

import Alert from '../Alert';

export const VerifyProUserAlert = ({ dismissAlert }: { dismissAlert: () => void }) => {
  const currentUser = useAppSelector<V2User>(selectUser);

  return (
    <Alert.Content data-testid="VerifyProUserAlert">
      You must verify your user details before you can perform this action. Click{' '}
      <Link onClick={dismissAlert} to={getProUserVerificationPath({ currentUser })}>
        here
      </Link>{' '}
      to get started.
    </Alert.Content>
  );
};
