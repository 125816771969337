import { isDeployPreview } from 'config/constants';

export const setProtonCookie = (
  name: string,
  value: string,
  days?: number,
  sameSite?: 'None' | 'Lax' | 'Strict'
) => {
  const domain = isDeployPreview ? 'qa.protonradio.com' : 'protonradio.com';
  const expires =
    days !== undefined && new Date(Date.now() + days * 8.64e7).toUTCString();

  let cookie = `${name}=${encodeURIComponent(value)}; samesite=${sameSite || 'Strict'}`;
  if (expires) cookie += `; expires=${expires}`;
  if (domain) cookie += `; domain=${domain}; path=/`;

  document.cookie = cookie;
};

export const removeProtonCookie = (name: string) => setProtonCookie(name, '', -1);
