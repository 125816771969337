import React, { ReactNode } from 'react';

import Bugsnag, { OnErrorCallback } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import ErrorPage, { ERROR_PAGE_STATUS } from 'routes/ErrorPage';
import { filterBugsnagErrors } from 'config/bugsnag/filterBugsnagErrors';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY!,
  plugins: [new BugsnagPluginReact(React)],
  // The value of `process.env.REACT_APP_CONTEXT` is set during a Netlify build,
  // and can be `production`, `deploy-preview`, or `branch-deploy` -- outside of
  // Netlify builds we fall back to the `process.env.REACT_APP_ENV` value
  releaseStage: process.env.REACT_APP_CONTEXT || process.env.REACT_APP_ENV,
  // unique identifier set by Netlify, undefined in local development
  appVersion: process.env.REACT_APP_BUILD_ID,
  enabledReleaseStages: ['production', 'development', 'deploy-preview', 'branch-deploy'],
  onError: filterBugsnagErrors as OnErrorCallback
});

const ErrorBoundaryBugsnag = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

const ErrorView = ({ error, clearError }: { error: Error; clearError: () => void }) => {
  Bugsnag.leaveBreadcrumb('Rendered Error Page as fallback view', { error }, 'manual');
  const errorProp =
    error.name === 'ChunkLoadError' || error.message.includes(`Unexpected token '<'`)
      ? ERROR_PAGE_STATUS.LOAD_ERROR
      : ERROR_PAGE_STATUS.UNHANDLED_EXCEPTION;
  return <ErrorPage error={errorProp} onError={clearError} />;
};

interface ErrorBoundaryProps {
  children: ReactNode;
}

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => (
  <ErrorBoundaryBugsnag FallbackComponent={ErrorView}>{children}</ErrorBoundaryBugsnag>
);

export default ErrorBoundary;
