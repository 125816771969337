import React from 'react';

import ConnectionStalker from './ConnectionStalker';
import PushHandler from './PushHandler';
import PendingDeletion from './PendingDeletion';

/**
 * [AppLoader] is a DEPRECATED component that is responsible for loading all of the app's side effects.
 *
 * NOTE: this pattern is being replaced. The few remaining hold out components here should be
 * migrated to `useAppSideEffects`, as long as any new side effects
 */

const AppLoader = () => (
  <>
    <ConnectionStalker />
    <PushHandler />
    <PendingDeletion />
  </>
);

export default AppLoader;
