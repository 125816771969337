import React, { useCallback } from 'react';
import _kebabCase from 'lodash/kebabCase';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { isUrlExternal } from 'config/routes/helpers';
import useToggle from 'hooks/useToggle';
import { ConfirmationActionMenu } from 'components/ActionMenu/ActionMenus';
import { generateSelectorsFromTestIds } from 'helpers';
import { ConfirmationActionMenuConfig } from './ActionMenus/ConfirmationActionMenu';

export interface ActionMenuItemProps {
  active?: boolean;
  border?: boolean;
  danger?: boolean;
  name?: string;
  icon?: string | React.ReactNode;
  iconWidth?: number;
  onClick?: ({ close }) => void;
  Component?: React.FC<{ actionMenu?: boolean; dropdownMenu?: boolean }>;
  confirmOnClick?: ConfirmationActionMenuConfig;
  closeOnClick?: boolean;
  to?: string;
  newTab?: boolean;
  disabled?: boolean;
  closeMenu?: () => void;
  children?: React.ReactNode;
  className?: string;
  testId?: string;
  'data-testid'?: string;
  showIcon?: boolean;
  style?: React.CSSProperties;
}

const ActionMenuItem = ({
  active,
  border,
  danger,
  name,
  icon,
  iconWidth,
  onClick,
  confirmOnClick,
  closeOnClick,
  to,
  newTab,
  disabled,
  closeMenu,
  children,
  className,
  testId,
  showIcon,
  style,
  'data-testid': testIdAttribute
}: ActionMenuItemProps) => {
  const {
    isOpen: isConfirmationMenuOpen,
    open: openConfirmationMenu,
    close: closeConfirmationMenu
  } = useToggle();

  const itemProps = {
    style: style,
    className: classNames('ActionMenuItem', className, {
      'is-disabled': disabled,
      'has-border': border,
      'is-active': active,
      'is-danger': danger
    }),
    'data-testid':
      testId || testIdAttribute || ActionMenuItem.TEST_IDS.getTestIdForItem(name)
    // ...remaining
  };

  const ActionMenuItemContent = (
    <>
      {showIcon && icon && (
        <div className="ActionMenuItem__icon">
          {typeof icon === 'string' ? (
            <img src={`/images/${icon}`} width={iconWidth} alt="" />
          ) : (
            icon
          )}
        </div>
      )}
      <div className="ActionMenuItem__title">{name || children}</div>
    </>
  );

  const handleOnClick = useCallback(() => {
    if (disabled) return null;
    if (closeOnClick && closeMenu) closeMenu();

    if (confirmOnClick) openConfirmationMenu();

    if (onClick) return onClick({ close: closeMenu });
  }, [disabled, closeOnClick, onClick, confirmOnClick, openConfirmationMenu, closeMenu]);

  if (to) {
    if (isUrlExternal(to)) {
      return (
        <a
          href={to}
          target={newTab ? '_blank' : undefined}
          rel="noreferrer"
          {...itemProps}
        >
          {ActionMenuItemContent}
        </a>
      );
    }

    return (
      <Link to={to} {...itemProps}>
        {ActionMenuItemContent}
      </Link>
    );
  }

  return (
    <>
      {confirmOnClick && (
        <ConfirmationActionMenu
          {...confirmOnClick}
          isOpen={isConfirmationMenuOpen}
          close={closeConfirmationMenu}
          closeAll={() => {
            closeConfirmationMenu();
            if (closeMenu) closeMenu();
          }}
        />
      )}

      <li role="button" tabIndex={0} onClick={handleOnClick} {...itemProps}>
        {ActionMenuItemContent}
      </li>
    </>
  );
};

ActionMenuItem.TEST_IDS = {
  getTestIdForItem: name => `ActionMenuItem-${_kebabCase(name)}`
};

ActionMenuItem.SELECTORS = generateSelectorsFromTestIds(ActionMenuItem.TEST_IDS);

export default ActionMenuItem;
