import { all } from 'redux-saga/effects';

import { watchUpdatePromoTrackReaction } from './promoReactionSagas';
import { watchUpdateUserSettings } from './userSagas';
import { watchDismissPromoRelease } from './promoReleaseSagas';
import { watchDownloadTrack } from './trackSagas';

// We only export the rootSaga which serves as the single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchUpdatePromoTrackReaction(),
    watchDismissPromoRelease(),
    watchUpdateUserSettings(),
    watchDownloadTrack()
  ]);
}
