import { useEffect } from 'react';
import { selectUser } from 'redux/selectors';

import { BREAKPOINTS } from 'config/constants';
import {
  initializeIntercom,
  showIntercomLauncher,
  shutdownIntercom,
  updateIntercomPosition
} from 'config/intercom';
import { isProUser, isSignedIn } from 'helpers/user';
import { useAppSelector } from 'hooks/redux';
import useBreakpoint from 'hooks/useBreakpoint';
import useNearBottomScroll from 'hooks/useNearBottom';
import useProtonPlayer from 'hooks/useProtonPlayer';
import { useLocation } from 'react-router-dom';

const addMobileClass = () => {
  const launcher = document.querySelector('.intercom-lightweight-app');
  if (launcher && !launcher.classList.contains('waveform-visible')) {
    launcher.classList.add('waveform-visible');
  }
};

const removeMobileClass = () => {
  const launcher = document.querySelector('.intercom-lightweight-app');
  if (launcher && launcher.classList.contains('waveform-visible')) {
    launcher.classList.remove('waveform-visible');
  }
};

function useIntercom() {
  const user = useAppSelector(selectUser);
  const { type, state } = useProtonPlayer();
  const pathname = useLocation().pathname;

  const isSmallScreen = useBreakpoint(BREAKPOINTS.MD, useBreakpoint.DIRECTIONS.DOWN);
  const isNearBottom = useNearBottomScroll({
    distance: 50
  });
  const isPlayer = (state === 'PLAYING' || state === 'PAUSED') && type !== 'radio';

  useEffect(() => {
    updateIntercomPosition(isSmallScreen, isPlayer, isNearBottom);
  }, [isSmallScreen, isPlayer, isNearBottom]);

  useEffect(() => {
    if (!isSignedIn(user)) {
      shutdownIntercom();
    } else if (isProUser(user)) {
      initializeIntercom(user, isSmallScreen ? 50 : 70);
      showIntercomLauncher();
    }
  }, [user, pathname]);

  // Intercom lacks support for styling on mobile devices
  // Further reading: https://community.intercom.com/messenger-8/is-there-a-way-to-adjust-the-position-of-the-messenger-on-mobile-devices-3026
  // As a workaround, we add a class to the intercom container when the player is active
  // and the screen is mobile.
  useEffect(() => {
    if (isSmallScreen) {
      isPlayer ? addMobileClass() : removeMobileClass();
    }
  }, [isSmallScreen, isPlayer, pathname]);
}

export default useIntercom;
