import { COLORS } from 'config/constants';
import React from 'react';
import styled, { keyframes } from 'styled-components';

const dotAnimation = keyframes`
  0%, 20% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const LoadingDotsContainer = styled.span`
  color: ${COLORS.GRAY_MEDIUM};
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.4rem;
`;

const Dot = styled.span`
  font-size: 2rem;
  opacity: 0;
  animation: ${dotAnimation} 1.4s infinite;
  animation-fill-mode: both;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const EllipsisLoader = () => (
  <LoadingDotsContainer>
    <Dot>•</Dot>
    <Dot>•</Dot>
    <Dot>•</Dot>
  </LoadingDotsContainer>
);

export default EllipsisLoader;
