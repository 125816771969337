import axios from 'axios';

import {
  ALGOLIA,
  API_BASE_V2,
  AUDIO_FILE_TYPES,
  DOWNLOAD_LOCATIONS
} from 'config/constants';

import { searchAlgolia } from './algolia';

export const getTrack = trackId =>
  searchAlgolia({
    index: ALGOLIA.TRACK_INDEX,
    facetFilters: [[`objectID:${trackId}`]]
  }).then(response => {
    const track = response.hits[0] || {};
    return track;
  });

export const getReleaseTracks = releaseId =>
  searchAlgolia({
    index: ALGOLIA.TRACK_INDEX,
    facetFilters: [[`release.id:${releaseId}`]]
  }).then(response => {
    const tracks = response.hits || [];
    return tracks;
  });

/**
 * [downloadTrack]
 * returns object with download url for given track id
 *
 * @prop {number} trackId
 * @prop {enum} [filetype = 'mp3'] if content should be displayed within a Well component.
 *
 * @returns {object} example response below
 * {
 *   status: "available",
 *   url: "https://download.protonradio.com/api/v2/download.php?key=RG9rICYgTWFydGluIC0gRW5pZ21hIChEYSBGcmVzaCBSZW1peCkgW0hvbWVzaWNrIE11c2ljXS5tcDM=&signature=ed1f2ecb871682db760c6c0615bfd820"
 * }
 */

const DOWNLOAD_API_PATH = {
  [DOWNLOAD_LOCATIONS.LOCAL.VALUE]: 'download',
  [DOWNLOAD_LOCATIONS.DROPBOX.VALUE]: 'dropbox_download',
  [DOWNLOAD_LOCATIONS.GOOGLE.VALUE]: 'drive_download'
};

export const downloadTrack = ({
  trackId,
  filetype: inputFileType,
  location: inputLocation
}) => {
  // NOTE: was running into an issue where location / filetype was null, so handling here for now
  const filetype = inputFileType || AUDIO_FILE_TYPES.MP3.VALUE;
  const location = inputLocation || DOWNLOAD_LOCATIONS.LOCAL.VALUE;
  const downloadPath = DOWNLOAD_API_PATH[location];

  return axios
    .get(`${API_BASE_V2}/tracks/${trackId}/${downloadPath}?filetype=${filetype}`)
    .then(response => {
      const { status, url } = response.data;
      if (status === 'available' && location === DOWNLOAD_LOCATIONS.LOCAL.VALUE) {
        // For now, using location.assign to trigger download in same browser window.
        // If this causes problems look into XMLHttpRequest
        window.location.assign(url);
      }
      return response.data;
    });
};

/**
 * [getTracks] - search algolia for provided track ids.  See 'searchAlgolia' for additional
 * parameters
 *
 * @param {number[]} trackIds
 * @param {string} [query]
 * @returns {object[]} algolia tracks
 */

export const getTracks = ({ trackIds, query, ...rest }) =>
  searchAlgolia({
    index: ALGOLIA.TRACK_INDEX,
    facetFilters: [trackIds.map(id => `objectID:${id}`)],
    query,
    ...rest
  }).then(({ hits }) => hits);
