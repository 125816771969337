import ProtonPlayer from '@protonradio/player/dist/proton-player.esm';
import { EventEmitter } from 'events';

import history from 'config/history';
import { store } from 'config/store';
import { routeToSignInModal } from 'redux/actions/player';

import toPlaylist from './toPlaylist';

import type { AudioQuality } from './AudioPlayer';

export default class StreamingPlayer extends EventEmitter {
  player: ProtonPlayer;

  constructor () {
    super(); 

    try {
      this.player = new ProtonPlayer({ volume: 0.7 });
    } catch (e) {
      console.error('Could not initialize Player');
    }

    this.player.on('track_changed', args => this.emit('TRACK_CHANGED', args))
    this.player.on('state_changed', state => {
      // The client player does not recognize the `READY` state.
      this.emit('STATE_CHANGED', state === 'READY' ? 'STOPPED' : state)
    })
    this.player.on('volume_changed', volume =>
      this.emit('VOLUME_CHANGED', {volume})
    )
    this.player.on('tick', () => {
      // The player currently emits 0 as the current time when it is not
      // actively playing. This is a workaround.
      if (this.player.state !== 'PLAYING') return;
      this.emit('TICK', this.currentTime())
    })
  }

  serialize() {
    return {}
  }

  send (messageType: string, payload: any) {
    if (this[messageType]) {
      setTimeout(() => {
        this[messageType](payload)
      }, 0)
    }
  }

  play (
    payload: {
      queue: [],
      index?: number,
      audioQuality: AudioQuality,
      initialPosition?: number
    }
  ) {
    const {queue, index = 0} = payload;

    const reduxState = store.getState();
    const {jwt} = reduxState.token;

    const playlist = toPlaylist(reduxState, queue, payload.audioQuality);

    if (!jwt) {
      const firstAudioType = playlist[0].meta.type;
      return history.push(routeToSignInModal(firstAudioType));
    }

    if (payload.initialPosition) {
      const currentTrack = playlist[index];
      const startPosition = currentTrack.meta.type === 'Mix'
        ? payload.initialPosition / currentTrack.meta.mix_length
        : payload.initialPosition / currentTrack.meta.duration_seconds;
      currentTrack.initialPosition = startPosition;
    }

    this.player.play(playlist, index);
    this.emit('PLAYBACK_STARTED', {queue: playlist, index})
  }

  currentTime () {
    return this.player.currentTime();
  }

  setPlaybackPosition (
    payload: {position: number, newLastAllowedPosition?: number}
  ): void {
    this.player.setPlaybackPosition(
      payload.position,
      payload.newLastAllowedPosition
    );
  }
  resume (): void { this.player.resume(); }
  skip (): void {
    const playlist = this.player.playlist.unwrap();
    if ((this.player.playlist.index + 1) >= playlist.length) {
      return;
    }
    this.player.skip();
  }
  back (): void {
    if ((this.player.playlist.index - 1) < 0) {
      return;
    }
    this.player.back();
  }
  pause (): void { this.player.pause(); }
  toggle (): void { this.player.toggle(); }
  reset (): void { this.player.reset(); }
  seek (seconds: number): void { this.player.seek(Number(seconds)); }
  setVolume (volume: number): void { this.player.setVolume(Number(volume)); }
}