import { useCallback, useEffect, useState } from 'react';

interface Config {
  memoize?: boolean;
  distance?: number;
  disabled?: boolean;
  timeoutBetweenEvents?: number; // in milliseconds
  callback?: () => void;
}

const defaultCallback = () => null;

/**
 * [useNearBottomScroll] is a hook that will call a callback function when the user has scrolled
 * within a certain distance from the bottom of the page.
 */

const useNearBottomScroll = (config?: Config) => {
  const {
    callback = defaultCallback,
    memoize = true,
    distance = 500,
    disabled = false,
    timeoutBetweenEvents = 1000
  } = config || {};

  const [isNearBottom, setIsNearBottom] = useState(false);
  const [temporarilyDisabled, setTemporarilyDisabled] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks, react-hooks/exhaustive-deps
  const memoizedCallback = memoize ? useCallback(callback, []) : callback;

  const checkScrollPosition = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);

    const isNear = distanceFromBottom <= distance;

    setIsNearBottom(isNear);

    if (isNear) {
      setTemporarilyDisabled(true);
      memoizedCallback();
    }
  }, [memoizedCallback, distance]);

  useEffect(() => {
    // Without a timeout, the scroll event will fire multiple times in quick succession. Tune as
    // needed with timeoutBetweenEvents
    if (temporarilyDisabled) {
      setTimeout(() => setTemporarilyDisabled(false), timeoutBetweenEvents);
    }
  }, [temporarilyDisabled, checkScrollPosition, timeoutBetweenEvents]);

  useEffect(() => {
    if (temporarilyDisabled || disabled) return;
    window.addEventListener('scroll', checkScrollPosition);
    checkScrollPosition();

    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
    };
  }, [checkScrollPosition, temporarilyDisabled, disabled]);

  return isNearBottom;
};

export default useNearBottomScroll;
