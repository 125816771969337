import React from 'react';
import styled from 'styled-components';

import TextHeader from 'components/TextHeader';

import { COLORS } from 'config/constants';
import Screen from 'components/Screen';
import orangeLog from 'images/logo-orange.svg';

const Wrapper = styled.div`
  align-items: center;
  background: ${COLORS.GRAY_SUPER_LIGHT};
  display: flex;
  justify-content: center;
  height: 100vh;
  padding: 1rem;
  text-align: center;
  width: 100%;
`;

const MaintenanceMode = () => (
  <Wrapper>
    <div>
      <Screen.SMALL up>
        <img src={orangeLog} alt="Proton Logo" height="50" />

        <TextHeader
          type={TextHeader.TYPES.JUMBO}
          weight={TextHeader.WEIGHTS.HEAVY}
          className="mt-3 mb-3"
        >
          Down for Maintenance
        </TextHeader>

        <TextHeader type={TextHeader.TYPES.XLARGE} weight={TextHeader.WEIGHTS.LIGHT}>
          Don't worry, we'll be right back!
        </TextHeader>
      </Screen.SMALL>
      <Screen.SMALL down>
        <img src={orangeLog} alt="Proton Logo" height="40" />

        <TextHeader
          type={TextHeader.TYPES.XLARGE}
          weight={TextHeader.WEIGHTS.HEAVY}
          className="mt-3 mb-1"
        >
          Down for Maintenance
        </TextHeader>

        <TextHeader type={TextHeader.TYPES.LARGE} weight={TextHeader.WEIGHTS.LIGHT}>
          Don't worry, we'll be right back!
        </TextHeader>
      </Screen.SMALL>
    </div>
  </Wrapper>
);

export default MaintenanceMode;
