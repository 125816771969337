import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import Modal from 'components/Modal';
import { SubmitButton, TextInput } from 'components/Modal/Input';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { addAlertMessage, showAlert } from 'redux/actions/ui';
import { logoutUser, resetPassword } from 'redux/actions/user';

const INITIAL_VALUES = {
  password: '',
  password_confirm: ''
};

const VALIDATION_SCHEMA = Yup.object().shape({
  password: Yup.string().required('Password is required.'),
  password_confirm: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Must match the password above.')
    .required('Password confirmation is required.')
});

export const ResetPasswordModal = ({ onClose }: { onClose: () => void }) => {
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const resettingPasswordRef = React.useRef(false);
  const token = queryString.get('token')!;
  const forceReset = queryString.get('force_reset');

  const user = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user.user_id && !resettingPasswordRef.current) {
      dispatch(logoutUser());
      dispatch(addAlertMessage({ message: `You've been logged out.` }));
    }
  }, [dispatch, user]);

  const handleSubmit = async (values: { password: string; password_confirm: string }) => {
    try {
      resettingPasswordRef.current = true;
      await dispatch(resetPassword({ ...values, token }));
    } catch (error: unknown) {
      resettingPasswordRef.current = false;
      return dispatch(showAlert(error));
    }
    onClose();
  };

  return (
    <Modal title="Reset Password" onClose={onClose} enableBanner>
      {forceReset && (
        <Modal.Text>
          We are improving account security here at Proton Radio and requiring all users
          to update their passwords. &nbsp;
          <a href="mailto:support@protonradio.com">We&apos;re here to help</a> if you have
          any questions!
        </Modal.Text>
      )}
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
      >
        <Form>
          {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
          <TextInput type="password" name="password" label="Password" autoFocus />
          <TextInput type="password" name="password_confirm" label="Confirm Password" />
          <SubmitButton type="submit">Sign In</SubmitButton>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ResetPasswordModal;
