import { createReducer } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { AlertItem } from 'components/Alert/AlertContainer';
import {
  addAlertMessage,
  incrementErrorCount,
  removeAlertMessage,
  resetErrorCount,
  setTouchDevice,
  setUi,
  toggleOverlay,
  updateWindowAutoScroller
} from 'redux/actions/ui';
import { clearUser } from 'redux/actions/user';

export interface UiState {
  alerts: AlertItem[];
  touchDevice: boolean;
  errorCount: number;
  updateWindowAutoScroller: number;
  jwtValidationFinished: boolean;
  tabbedHeaderNotification: boolean;
}

const initialState: UiState = {
  alerts: [],
  touchDevice: false,
  errorCount: 0,
  updateWindowAutoScroller: 0,
  jwtValidationFinished: false,
  tabbedHeaderNotification: false
};

export default createReducer(initialState, builder => {
  builder
    .addCase(updateWindowAutoScroller, state => ({
      ...state,
      updateWindowAutoScroller: state.updateWindowAutoScroller + 1
    }))
    .addCase(toggleOverlay, (state, { payload }) => ({
      ...state,
      overlayPresent: payload
    }))
    .addCase(addAlertMessage, (state, action) => {
      let messageProps: Partial<AlertItem> = {};
      let id: string | number | undefined;
      if (typeof action.payload === 'object') {
        messageProps = action.payload;
      } else {
        messageProps.message = action.payload;
      }

      // To prevent duplicate message display, derive a message id and check if it already exists.
      const messageId =
        id || messageProps.component || String(messageProps.message) || uuidv4();
      if (state.alerts.find(alert => alert.id === messageId)) return state;

      return {
        ...state,
        alerts: [{ ...messageProps, id: messageId }, ...state.alerts]
      };
    })
    .addCase(removeAlertMessage, (state, { payload: { id, all } }) => {
      if (state.alerts.length === 0) return state;
      if (all) {
        return {
          ...state,
          alerts: []
        };
      }

      const alerts = state.alerts.filter(alert => alert.id !== id);

      return {
        ...state,
        alerts
      };
    })
    .addCase(setTouchDevice, state => ({ ...state, touchDevice: true }))
    .addCase(incrementErrorCount, state => ({
      ...state,
      errorCount: state.errorCount + 1
    }))
    .addCase(resetErrorCount, state => ({ ...state, errorCount: 0 }))
    .addCase(setUi, (state, { payload }) => ({ ...state, ...payload }))
    .addCase(clearUser, state => ({
      ...state,
      alerts: state.alerts.filter(alert => alert.persistAfterLogout)
    }));
});
