import { graphql } from 'gql';

export const RemoveSubscriberFromLabel = graphql(`
  mutation removeSubscriber($labelId: ID!, $artistId: ID!) {
    labelSubscriberRemove(input: { labelId: $labelId, artistId: $artistId }) {
      artist {
        id
      }
      errorDetails {
        path
        message
      }
    }
  }
`);

export const InviteSubscriberToLabel = graphql(`
  mutation inviteSubscriber($labelId: ID!, $artistId: ID!) {
    labelSubscriberInvite(input: { labelId: $labelId, artistId: $artistId }) {
      artist {
        id
      }
      errorDetails {
        path
        message
      }
    }
  }
`);

export const GetLabelAndViewer = graphql(`
  query getLabelAndViewer($labelId: ID!) {
    label(id: $labelId) {
      id
      name
      activeEndYear
      activeStartYear
      promoAccessState
      hasPromoAccess
      description
      slug
      viewer {
        id
        hasReleasesOnLabel
        isLabelManager
      }
      genres {
        name
      }
      logo(size: MEDIUM) {
        url
      }
    }
    viewer {
      id
      promoPool {
        labels(filterBy: { labelId: { eq: $labelId } }) {
          edges {
            id
            addedAt
            state
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`);
