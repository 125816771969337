import React from 'react';

import { selectIsTrackDownloadable } from 'redux/selectors';
import { useQuery } from 'urql';
import { releaseStoreInfoButtonsTransform } from 'graphql/transforms';

import Button from 'components/Button';
import Popover from 'components/Popover';
import DropdownMenu from 'components/DropdownMenu';
import DownloadTrackButton from 'components/Button/DownloadTrackButton';
import { DspIcon } from 'components/Icons';
import { ReleaseDspQuery } from 'graphql/queries/release';
import { AlgoliaTrack } from 'types/algolia';
import { useAppSelector } from 'hooks/redux';
import { AudioType, DIGITAL_SERVICE_PROVIDERS } from 'config/constants';

interface Props {
  track: AlgoliaTrack & { hasArchiveAccess: boolean; __audioType: AudioType };
}

const PlayerGetFullTrackButton = ({ track }: Props) => {
  const canDownload = useAppSelector(state => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO: need to figure out how to get types working with redux createSelector
    return selectIsTrackDownloadable(state, { audio: track });
  });
  const [{ data, fetching, error }] = useQuery({
    query: ReleaseDspQuery,
    variables: { id: String(track.release.id) },
    pause: !track.release.id
  });
  const dspLinks = fetching || error ? null : releaseStoreInfoButtonsTransform(data);

  if (!track.id || !data) return null;

  return (
    <DropdownMenu
      renderToggle={({ open }: { open: () => void }) => (
        <div className="Player__button">
          <Button
            data-testid={PlayerGetFullTrackButton.TEST_IDS.GET_TRACK}
            color={Button.COLORS.PRIMARY}
            uppercase
            onClick={open}
          >
            Get Full Track
          </Button>
        </div>
      )}
      renderDirection={Popover.DIRECTIONS.UP}
      fixed
    >
      {dspLinks?.map((dsp, index) => {
        const testID = TEST_ID_BY_SERVICE[dsp.name];
        return (
          <DropdownMenu.Item
            icon={<DspIcon service={dsp.name} />}
            to={dsp.url}
            newTab
            testId={testID}
            key={index}
          >
            {dsp.title}
          </DropdownMenu.Item>
        );
      })}

      <div style={{ paddingLeft: 22 }}>
        {canDownload && (
          <DownloadTrackButton
            track={track}
            buttonStyles={{
              marginLeft: '-3px',
              fontWeight: '400',
              letterSpacing: 'inherit'
            }}
            dropdownStyles={{ transform: 'translateX(-5px)' }}
          />
        )}
      </div>
    </DropdownMenu>
  );
};

PlayerGetFullTrackButton.TEST_IDS = {
  GET_TRACK: 'get-full-track',
  LINK_BEATPORT: 'ActionMenuItem-beatport',
  LINK_SPOTIFY: 'ActionMenuItem-spotify',
  LINK_APPLE_MUSIC: 'ActionMenuItem-apple_music',
  DOWNLOAD: DownloadTrackButton.TEST_IDS.BUTTON
} as const;

const TEST_ID_BY_SERVICE = {
  [DIGITAL_SERVICE_PROVIDERS.APPLE_MUSIC]:
    PlayerGetFullTrackButton.TEST_IDS.LINK_APPLE_MUSIC,
  [DIGITAL_SERVICE_PROVIDERS.BEATPORT]: PlayerGetFullTrackButton.TEST_IDS.LINK_BEATPORT,
  [DIGITAL_SERVICE_PROVIDERS.SPOTIFY]: PlayerGetFullTrackButton.TEST_IDS.LINK_SPOTIFY
} as const;

export default PlayerGetFullTrackButton;
