import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

class TombstoneText extends Component {
  static COLORS = {
    LIGHT: 'Tombstone--light',
    DARK: 'Tombstone--dark'
  };

  static SIZES = {
    XSMALL: 'TombstoneText--xsmall',
    SMALL: 'TombstoneText--small',
    MEDIUM: 'TombstoneText--medium',
    LARGE: 'TombstoneText--large',
    XLARGE: 'TombstoneText--xlarge',
    ENTITY_HEADER_TITLE: 'TombstoneText--EntityHeader-title'
  };

  render() {
    const { className, style, size, grow, color, rounded } = this.props;

    const _classes = classnames(
      'Tombstone',
      className,
      color || TombstoneText.COLORS.LIGHT,
      size || TombstoneText.SIZES.SMALL,
      { 'Tombstone--grow': grow, 'Tombstone__avatar--rounded': rounded }
    );

    return <div className={_classes} style={style} />;
  }
}

TombstoneText.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape(),
  size: PropTypes.string,
  grow: PropTypes.bool
};

export default TombstoneText;
