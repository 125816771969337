import { createAction } from 'redux-act';

import * as api from 'api';

export const addMixes = createAction('MIXES_ADD', mixes => ({ mixes }));

// pass array of mixIds
export const removeMixes = createAction('MIXES_REMOVE', mixIds => ({ mixIds }));

// ASYNC ACTIONS:
export const getMix = id => dispatch =>
  api.getMix(id).then(response => {
    if (response.status === 200 && response.data) {
      dispatch(addMixes([response.data]));
      return response.data;
    }
    throw response;
  });

export const fetchTopMixesRequest = createAction('TOP_MIXES_FETCH_REQUEST');
export const fetchTopMixesSuccess = createAction('TOP_MIXES_FETCH_SUCCESS', mixes => ({
  mixes
}));
export const fetchTopMixesError = createAction('TOP_MIXES_FETCH_ERROR');

export const fetchTopMixes =
  ({ sinceDaysAgo, except, limit }) =>
  dispatch => {
    dispatch(fetchTopMixesRequest());

    return api
      .getTopMixes({ sinceDaysAgo, except, limit })
      .then(response => {
        dispatch(fetchTopMixesSuccess(response.data));
      })
      .catch(error => dispatch(fetchTopMixesError(error.message)));
  };
