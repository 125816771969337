import _ from 'lodash';
import { useEffect } from 'react';
import { Keys } from 'react-keydown';
import { useDispatch, useSelector } from 'react-redux';

import useCurrentUser from 'hooks/useCurrentUser';
import useProtonPlayer from 'hooks/useProtonPlayer';
import {
  selectFollowingPromoTrackIds,
  selectIsPromoTrackPlaying,
  selectPromoKeyboardShortcutsEnabled
} from 'redux/selectors';

import handlePromoPageKeydown from './handlePromoPageKeydown';

/**
 * [useProtonKeyboardShortcuts]
 * Custom hook that handles setting shortcuts for:
 * 1) Keyboard media keys - ties them to player actions
 * 2) Spacebar - play/pause
 * 3) Promo shortcuts - see handlePromoPageKeydown
 */

function useProtonKeyboardShortcuts() {
  const dispatch = useDispatch();
  const player = useProtonPlayer();
  const { user: currentUser } = useCurrentUser();
  const playing = player.currentTrack;

  // ENABLE MEDIA KEYS
  useEffect(() => {
    const mediaSession = _.get(window, 'navigator.mediaSession');

    if (mediaSession) {
      mediaSession.setActionHandler('play', () => {
        player.send('resume');
      });

      mediaSession.setActionHandler('pause', () => {
        player.send('pause');
      });

      mediaSession.setActionHandler('previoustrack', () => {
        player.send('back');
      });

      mediaSession.setActionHandler('nexttrack', () => {
        player.send('skip');
      });
    }

    return () => {
      if (mediaSession) {
        mediaSession.setActionHandler('play', null);
        mediaSession.setActionHandler('pause', null);
        mediaSession.setActionHandler('previoustrack', null);
        mediaSession.setActionHandler('nexttrack', null);
      }
    };
  }, [dispatch]);

  // ////////// Enable keyboard shortcuts for the Player
  const keyboardShortcutsEnabled = useSelector(selectPromoKeyboardShortcutsEnabled);
  const followingPromoTrackIDs = useSelector(selectFollowingPromoTrackIds);
  const isPromoTrackPlaying = playing
    ? followingPromoTrackIDs.includes(playing.id)
    : false;

  useEffect(() => {
    const handlePlayerDefaultKeydown = e => {
      // Ignore Player keyboard shortcuts if within input field
      if (['INPUT', 'TEXTAREA'].includes(e.target.tagName)) return;

      // Ignore any events while command key is pressed
      // NOTE: if more control is required, consider making a mapping of currently pressed
      // keys using keypress and keyup handlers
      if (e.metaKey || e.ctrlKey || e.altKey) return;

      // NOTE: Currently we only have one keyboard shortcut by default, but we may want to extend
      // in the future.
      switch (e.keyCode) {
        case Keys.SPACE:
          e.preventDefault();
          player.send('toggle');
          break;

        // no default
      }

      // Use Promo related keyboard shortcuts if promo track and promo shortcuts are enabled
      if (keyboardShortcutsEnabled && isPromoTrackPlaying) {
        handlePromoPageKeydown(e, currentUser, player);
      }
    };

    window.addEventListener('keydown', handlePlayerDefaultKeydown);

    return () => {
      window.removeEventListener('keydown', handlePlayerDefaultKeydown);
    };
  }, [dispatch, player, keyboardShortcutsEnabled, isPromoTrackPlaying]);
}

export default useProtonKeyboardShortcuts;
