import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

/**
 * [RedirectRoute]
 * conditionally renders a route unless shouldRedirect is enabled.
 * This is useful for rendering a route at a given path for certain screen sizes
 * i.e. label, release, track detail pages on mobile vs sidebar on desktop
 *
 * NOTE: React-router doesn't let you use conditional redirects within switch statement, so this is a way to
 * have similar functionality but in a way React-router will allow
 *
 * @returns {object}
 */

const RedirectRoute = ({ render, shouldRedirect, to, path }) => (
  <Route
    path={path}
    render={defaultRouteProps => {
      if (shouldRedirect) return <Redirect from={path} to={to} />;
      return render(defaultRouteProps);
    }}
  />
);

RedirectRoute.propTypes = {
  render: PropTypes.func,
  shouldRedirect: PropTypes.bool,
  to: PropTypes.string,
  path: PropTypes.string,
  exact: PropTypes.bool
};

export default RedirectRoute;
