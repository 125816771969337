import React, { ReactNode } from 'react';
import styled from 'styled-components';

import SEO from 'components/SEO';
import TextHeader from 'components/TextHeader';
import { TombstoneText } from 'components/Tombstone';
import { BREAKPOINTS, COLORS } from 'config/constants';

const StyledHeader = styled.div<{ $center?: boolean; $color?: string }>`
  display: flex;
  flex-direction: column;

  .TextHeader {
    color: ${({ $color }) => $color};
  }

  ${({ $center }) =>
    $center &&
    `
    align-items: center;
    text-align: center;
  `}

  @media screen and (max-width: ${BREAKPOINTS.MD}px) {
    margin-bottom: 2rem;
  }
`;

const CategoryTitle = styled(TextHeader)`
  margin-bottom: 0;
  margin-top: 3px;
  @media screen and (min-width: ${BREAKPOINTS.LG}px) {
    margin-top: 5px;
  }
`;

const Title = styled(TextHeader)`
  margin-bottom: 0;

  @media screen and (max-width: ${BREAKPOINTS.XL}px) {
    font-size: 5.4rem;
  }

  @media screen and (max-width: ${BREAKPOINTS.MD}px) {
    font-size: 4rem;
    text-align: center;
  }
`;

const Subtitle = styled(TextHeader)`
  line-height: 1.4;

  @media screen and (max-width: ${BREAKPOINTS.MD}px) {
    text-align: center;
  }
`;

// TODO
// 1. Add statics for colors and categories?

/**
 * [Header] - General purpose header laying out consistent titles/subtitles. Should
 *             be used on any page with a standard h1/h2 style header.
 *
 * @param {string} as - Custom value for CSS align-items
 * @param {string} category - The page entity type (artist, show, etc)
 * @param {string} color - Custom value for text colors
 * @param {boolean} loading
 * @param {string} subtitle - Page subtitle
 * @param {string} title - Page title
 * @param {boolean} center - center content
 */

type HeaderProps = {
  as?: string;
  category?: string;
  color?: string;
  children?: React.ReactNode;
  className?: string;
  center?: boolean;
  loading?: boolean;
  seo?: {
    description: string;
    title: string;
    image?: string;
    section: string;
  };
  subtitle?: ReactNode | (() => ReactNode);
  style?: object;
  title?: ReactNode;
};

const Header = ({
  as,
  category,
  color,
  children,
  className,
  center,
  loading = false,
  seo,
  subtitle,
  style,
  title
}: HeaderProps): JSX.Element => {
  if (loading) {
    return (
      <>
        <StyledHeader as={as} style={style} $center={center} className={className}>
          {category && (
            <TombstoneText
              style={{ width: 60 }}
              className="mt-2 mb-1"
              color={TombstoneText.COLORS.DARK}
            />
          )}

          <TombstoneText
            size={TombstoneText.SIZES.ENTITY_HEADER_TITLE}
            color={TombstoneText.COLORS.DARK}
            style={{ width: 220 }}
          />

          {subtitle && (
            <TombstoneText
              style={{ width: 60 }}
              className="mt-2 mb-1"
              color={TombstoneText.COLORS.DARK}
            />
          )}
        </StyledHeader>
      </>
    );
  }

  return (
    <>
      <SEO
        title={seo?.title}
        description={seo?.description}
        image={seo?.image}
        section={seo?.section}
      />

      <StyledHeader
        as={as}
        data-testid={Header.TEST_IDS.CONTAINER}
        style={style}
        $center={center}
        className={className}
      >
        {category && (
          <CategoryTitle
            type={TextHeader.TYPES.XSMALL}
            color={color || COLORS.GRAY_DARK}
            customTag="div"
            data-testid={Header.TEST_IDS.LABEL}
          >
            {category}
          </CategoryTitle>
        )}

        <Title
          type={TextHeader.TYPES.JUMBO}
          color={color || COLORS.GRAY_SUPER_DARK}
          weight={TextHeader.WEIGHTS.HEAVY}
          customTag="h1"
          data-testid={Header.TEST_IDS.TITLE}
        >
          {title}
        </Title>

        {subtitle && (
          <Subtitle
            type={TextHeader.TYPES.MEDIUM}
            color={color || COLORS.GRAY_DARK}
            weight={TextHeader.WEIGHTS.LIGHT}
            className="mt-0 mb-0"
            customTag="h2"
            data-testid={Header.TEST_IDS.SUBTITLE}
            colorLinks
          >
            {typeof subtitle === 'function' ? subtitle() : subtitle}
          </Subtitle>
        )}

        {children}
      </StyledHeader>
    </>
  );
};

Header.TEST_IDS = {
  CONTAINER: 'PageHeader-container',
  TITLE: 'PageHeader-title',
  SUBTITLE: 'PageHeader-subtitle',
  LABEL: 'PageHeader-label'
};

export default Header;
