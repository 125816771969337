export const getPositioning = (renderDirection, anchorNode, popoverNode, isFixed) => {
  if (!anchorNode || !popoverNode) return {};
  const anchorBounds = anchorNode.getBoundingClientRect();
  const popoverBounds = popoverNode.getBoundingClientRect();

  const CARET_OFFSET = 10;

  const horizontalTop =
    anchorBounds.top - (popoverBounds.height - anchorBounds.height) / 2;

  switch (renderDirection) {
    case 'UP':
      return {
        left: anchorBounds.left - (popoverBounds.width - anchorBounds.width) / 2,
        top: isFixed
          ? anchorBounds.top - popoverBounds.height
          : anchorBounds.top - popoverBounds.height + window.scrollY
      };
    case 'DOWN':
      return {
        left: anchorBounds.left - (popoverBounds.width - anchorBounds.width) / 2,
        top: isFixed ? anchorBounds.bottom : anchorBounds.bottom + window.scrollY
      };
    case 'LEFT':
      return {
        left: anchorBounds.left - popoverBounds.width - CARET_OFFSET,
        top: isFixed ? horizontalTop : horizontalTop + window.scrollY
      };
    case 'RIGHT':
      return {
        left: anchorBounds.left - popoverBounds.width - CARET_OFFSET,
        top: isFixed ? horizontalTop : horizontalTop + window.scrollY
      };
    default:
      return {};
  }
};

export const getCaretStyles = (renderDirection, collisions) => {
  switch (renderDirection) {
    case 'UP':
      return {
        bottom: '-0.5rem',
        left: '50%',
        marginLeft: `${-7 - collisions.x}px`
      };
    case 'DOWN':
      return {
        top: '-0.5rem',
        left: '50%',
        marginLeft: `${-7 - collisions.x}px`
      };
    case 'LEFT':
      return {
        right: '-0.5rem',
        top: '50%',
        marginTop: `${-7 - collisions.y}px`
      };
    case 'RIGHT':
      return {
        left: '-0.5rem',
        top: '50%',
        marginTop: `${-7 - collisions.y}px`
      };
    default:
      return {};
  }
};
