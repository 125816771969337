import { PROMO_ACCESS } from 'config/constants';
import {
  getLabelIdsUserManages,
  isAdminUser,
  isUserArtistMemberForArtist,
  isUserLabelManager,
  isUserLabelManagerForLabel
} from './user';
import { V2ProUser, ArtistProfile, ArtistMember, LabelConnection } from 'types';
import { NewMember } from 'components/Wizard/types';

// Any function that determines if X can do Y probably belongs here.
//
// Naming convention: can<entity><verb><noun>

export const canUserEditProNotifications = (user: V2ProUser) =>
  !!(isAdminUser(user) || isUserLabelManager(user));

// if user has artist id in their profiles, allow edit
export const canUserEditArtist = (user: V2ProUser, artistId: string) =>
  !!(isAdminUser(user) || isUserArtistMemberForArtist(user, artistId));

export const canUserEditLabel = (user: V2ProUser, labelId: number) =>
  !!(isAdminUser(user) || isUserLabelManagerForLabel(user, labelId));

type EditArtistMemberPolicyInput = {
  currentUser?: V2ProUser;
  artistProfile: ArtistProfile;
  member: NewMember | ArtistMember;
};
/**
 * Determine whether a user can EDIT an existing artist member.
 *
 * NOTE: The source of truth for these permissions can be found in our rails logic. The permissions
 * are also distilled in our Insomnia documentation
 * https://github.com/protonradio/server/blob/master/app/api/v2/artists.rb#L33
 */
export const canUserEditArtistMember = ({
  currentUser,
  artistProfile,
  member
}: EditArtistMemberPolicyInput) => {
  if (!currentUser) return false;
  if (isAdminUser(currentUser)) return true;
  if (!isUserLabelManager(currentUser)) return false;

  const userManagedLabelIds = getLabelIdsUserManages(currentUser);

  // NOTE: We likely don't have access to the member.releases_on if the member is part of the artistProfile,
  // since this data isn't returned as part of the artistProfile endpoint. We only get member.releases_on from
  // the user search api when adding an existing member.

  // In this case, we use artistProfile.releases_on instead of member.releases_on. On the backend, this will always
  // be based on the member.releases_on.

  const hasReleasedOnLabelManagedByUser = !('releases_on' in member)
    ? artistProfile.releases_on.some(({ id }) => userManagedLabelIds.includes(id))
    : (member.releases_on as LabelConnection[]).some(({ id }) =>
        userManagedLabelIds.includes(id)
      );

  return !!(!member.verified || hasReleasedOnLabelManagedByUser);
};

type AddArtistMemberPolicyInput = {
  user?: V2ProUser;
  artistProfile: ArtistProfile;
  memberMissingRequiredFields: boolean;
};
/**
 * Determine whether a user can ADD an existing artist member for the passed artistProfile
 */

export const canUserAddArtistMember = ({
  user,
  artistProfile,
  memberMissingRequiredFields
}: AddArtistMemberPolicyInput) => {
  if (!user) return false;
  if (isAdminUser(user)) return true;
  if (
    isUserLabelManager(user) &&
    artistProfile?.releases_on?.length === 0 &&
    !memberMissingRequiredFields
  ) {
    return true;
  }
  return false;
};

/**
 * [canLabelAccessPromoPool] - determine whether a user can add a label to their profile
 *
 * @param {object} label
 * @param {string} [accessType] - if omitted, will check if user has limited OR full access. If passed,
 * will check for specific access type.
 * @returns {bool}
 */

export const canLabelAccessPromoPool = (
  { promo_access }: { promo_access: string },
  accessType: string
) => {
  if (accessType) return promo_access === accessType;
  return [PROMO_ACCESS.LIMITED, PROMO_ACCESS.FULL].includes(promo_access);
};
