import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { load } from 'recaptcha-v3';
import * as Yup from 'yup';

import Alert from 'components/Alert';
import Modal from 'components/Modal';
import { SubmitButton, TextInput } from 'components/Modal/Input';
import { RECAPTCHA_SITE_KEY } from 'config/constants';
import { getToken as getTokenAction } from 'redux/actions/token';
import { showAlert } from 'redux/actions/ui';
import { getUser as getUserAction } from 'redux/actions/user';

const VALIDATION_SCHEMA = Yup.object().shape({
  username: Yup.string().required('Username is required.'),
  password: Yup.string().required('Password is required.')
});

const SignInModal = ({ getToken, getUser, onClose, location, jwt, user }) => {
  // This is a temporary hack due to the way the router is currently structured. When you log in,
  // the router currently tries to reload (at least) four times since it is fed by many different
  // systems (Redux, Sagas, RTKQuery, etc). To allow the user to continue on to whatever page they
  // were trying to access before signing in, we have to manually preserve the target location in
  // this component instead of globally. This can be fixed with more work.
  const initialDestinationRef = useRef(location.pathname + (location.search || ''));
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const { search, state } = useLocation();
  const genericError = state?.formError || error;
  const queryParams = new URLSearchParams(search);
  const destinationURL = queryParams.get('dest');
  const redirectUrl = queryParams.get('redirect');
  const claimToken = queryParams.get('claim_token');
  const artistId = queryParams.get('artist_id');

  const isProtonRedirect =
    redirectUrl && new URL(redirectUrl).hostname.endsWith('protonradio.com');
  if (isProtonRedirect) {
    // overwrite default close to redirect user
    onClose = () => window.location.replace(decodeURIComponent(redirectUrl));
  }

  // This has to be done this way due to tests bypassing the sign-in flow. We can't guarantee that
  // the `handleSubmit` function will be run, so we can't rely on it imperatively closing the
  // modal.
  useEffect(() => {
    if (!(jwt && user.user_id)) return;

    const initialDestination = initialDestinationRef.current;
    if (initialDestination === '/sign-in' && jwt) {
      // TODO: this is a hack to prevent visiting the sign-in route while already logged in. We will want to do a
      //  proper clean-up when migrating to React Router 6. Had to remove `NonUserRoute` that was wrapping the
      //  sign-in route and firing prior to this hook
      return window.location.replace('/');
    }

    if (isProtonRedirect) {
      onClose();
      return;
    }

    if (artistId && user.pro_user_details_confirmed === false) {
      onClose(`/users/${user.pro_user_id}/verification?` + queryParams.toString());
      return;
    }
    if (destinationURL) {
      window.location.replace(decodeURIComponent(destinationURL));
      return;
    }
    onClose(initialDestination);
  }, [jwt, user]);

  const INITIAL_VALUES = {
    username: queryParams.get('proton_id') || '',
    password: ''
  };

  const handleSubmit = async ({ username, password }, formik) => {
    const recaptcha = await load(RECAPTCHA_SITE_KEY, { autoHideBadge: true });
    const captchaToken = await recaptcha.execute('login');

    try {
      const token = await getToken({
        protonid: username,
        user_password: password,
        claim_token: claimToken || undefined,
        recaptcha_token: captchaToken || undefined
      });

      if (!token.userId) {
        return;
      }

      // We also need to fetch the current user user and store in redux
      await getUser(token.userId);
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      if (errorMessage === 'username not found or password incorrect') {
        setError('Invalid username or password. Please try again.');
        return;
      }

      if (errorMessage === 'base user is inactive') {
        return dispatch(
          showAlert({
            component: 'ResendUserActivation',
            componentProps: { protonid: username },
            dismissable: false,
            type: Alert.TYPES.MESSAGE
          })
        );
      }

      // Any other errors can show a generic message
      return setError('There was an error signing in. Please try again.');
    }
  };

  return (
    <Modal
      title="Sign In"
      onClose={onClose}
      enableBanner
      errorMessage={genericError}
      bannerFooter={
        <Modal.BannerFooter
          message="Don't have an account?"
          action="Sign Up"
          actionURL="/create-account"
        />
      }
    >
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
      >
        <Form>
          <TextInput
            name="username"
            onForgot="/forgot-username"
            label="Username"
            autoFocus={!INITIAL_VALUES.username}
          />
          <TextInput
            type="password"
            name="password"
            onForgot="/forgot-password"
            label="Password"
            autoFocus={!!INITIAL_VALUES.username}
          />
          <SubmitButton type="submit">Sign In</SubmitButton>
          <Modal.PolicyMessage>
            This site is protected by reCAPTCHA and the Google &nbsp;
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and &nbsp;
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </Modal.PolicyMessage>
        </Form>
      </Formik>
    </Modal>
  );
};

export default connect(state => ({ jwt: state.token?.jwt, user: state.user }), {
  getToken: getTokenAction,
  getUser: getUserAction
})(SignInModal);
