import React from 'react';

import Avatar, { AvatarProps } from '../Avatar';

/**
 * [ShowAvatar] - handles rendering Avatar with defaults specific to the Show entity
 *
 * See Avatar for other props available
 *
 * @param {string} [imageUrl]
 * @param {string} name
 * @param {bool} loading - renders tombstone
 */

interface ShowAvatarProps extends AvatarProps {
  imageUrl?: string;
  name?: string;
  loading?: boolean;
}

const ShowAvatar = ({
  altTag = 'Show Avatar',
  imageUrl = '',
  name = '',
  ...rest
}: ShowAvatarProps) => {
  return (
    <Avatar
      imageUrl={encodeURI(imageUrl)}
      imageRatio="50%"
      altTag={altTag}
      heightAuto
      title={name}
      circle={false}
      {...rest}
    />
  );
};

ShowAvatar.SIZES = Avatar.SIZES;

export default ShowAvatar;
