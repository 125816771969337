import React from 'react';

import { DEFAULT_ICON_COLOR } from './config';

const PhoneIcon = ({
  color = DEFAULT_ICON_COLOR,
  size = 18,
  className,
  style
}: {
  className?: string;
  style?: React.CSSProperties;
  size?: number;
  color?: string;
}) => (
  <svg
    width={size}
    height={size}
    className={className}
    style={style}
    viewBox="0 0 20.8887483 21.9282236"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      strokeWidth="1.46"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g transform="translate(-795, -536)" stroke={color}>
        <g transform="translate(634, 520)">
          <g transform="translate(161, 17)">
            <path
              d="M19.8881238,14.9200983 L19.8881238,17.9200983 C19.8904096,18.4832434 19.6551718,19.0212567 19.2402017,19.4019632 C18.8252316,19.7826697 18.2689877,19.9707864 17.7081238,19.9200983 C14.6309577,19.5857391 11.675123,18.5342429 9.0781238,16.8500983 C6.66195192,15.3147622 4.61345994,13.2662702 3.0781238,10.8500983 C1.38810186,8.2413023 0.3363668,5.27109288 0.00812379771,2.18009832 C-0.042402992,1.62097065 0.144395806,1.06635074 0.522889577,0.651719375 C0.901383349,0.237088007 1.43671804,0.000627009182 1.9981238,2.22044605e-16 L4.9981238,2.22044605e-16 C6.00198785,-0.00978183821 6.85762464,0.726065801 6.9981238,1.72009832 C7.12474681,2.68016477 7.35957325,3.6228252 7.6981238,4.53009832 C7.9728588,5.26097335 7.79713887,6.08490458 7.2481238,6.64009832 L5.9781238,7.91009832 C7.40167927,10.4136441 9.47457797,12.4865428 11.9781238,13.9100983 L13.2481238,12.6400983 C13.8033175,12.0910832 14.6272488,11.9153633 15.3581238,12.1900983 C16.2653969,12.5286489 17.2080573,12.7634753 18.1681238,12.8900983 C19.1736865,13.0319586 19.9133138,13.9048909 19.8881238,14.9200983 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PhoneIcon;
