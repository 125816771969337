import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Modal from 'components/Modal';
import { useAppDispatch } from 'hooks/redux';
import { showAlert } from 'redux/actions/ui';
import { confirmUser } from 'redux/actions/user';

const ConfirmAccount = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const username = queryParams.get('proton_id') || '';
  const confirmationToken = queryParams.get('confirmation_token') || '';

  useEffect(() => {
    dispatch(confirmUser(confirmationToken, username))
      .then(() => {
        history.push('/account');
      })
      .catch(e => dispatch(showAlert(e)));
  }, [confirmationToken, username]);

  return (
    <div>
      <Modal
        title="Confirming Account"
        subtitle="Hang tight while we finish confirming your account..."
        onClose={onClose}
      />
    </div>
  );
};

export default ConfirmAccount;
