import { fork, cancel, delay, take, put, call, select } from 'redux-saga/effects';

import {
  selectUserAutoDownloadSettings,
  selectUserAudioDownloadLocation
} from 'redux/selectors/user';

import { selectLastKnownTrackRating } from 'redux/selectors/tracks';

import {
  updateTrackReaction,
  updateTrackReactionOptimistic
} from 'redux/actions/promoReleases';

import { downloadTrack } from 'redux/actions/tracks';

import { promos as promosApi } from 'api/promos';

/**
 * [updatePromoTrackReaction] - handles sending api request to update promo track reaction.
 *
 * @param {object} [meta] - optionally can pass callback in the meta argument of redux action creator. This makes it far
 * more convenient than trying to watch redux state changes
 * @param {function} [meta.onSuccess]
 * @param {function} [meta.onError]
 * @param {function} [meta.onFinally]
 */

export function* updatePromoTrackReaction(action) {
  const { payload, meta = {} } = action;
  const { track, artistId, ...reactionPayload } = payload;
  const { id: trackId } = track;

  const { onError, onSuccess, onFinally, optimistic } = meta;

  try {
    // If this is not an optimistic update, then we need to set pending state in redux
    if (!optimistic) {
      yield put(updateTrackReaction.request({ trackId }));
    }

    const response = yield call(promosApi.updateTrackReaction, {
      trackId,
      artistId,
      ...reactionPayload
    });

    const reactionData = response.data.data;

    yield put(
      updateTrackReaction.success({
        trackId,
        reaction: reactionData
      })
    );
    if (onSuccess) onSuccess(reactionData);
  } catch (e) {
    if (onError) onError(e);
    yield put(updateTrackReaction.error({ trackId, errorMessage: e.message }));
  } finally {
    // By default, canceled tasks are handled in "finally". If we wanted to specifically handle
    // canceled tasks, we can do so with:
    // if (yield cancelled()) { ... }
    if (onFinally) onFinally();
  }
}

/**
 * [handleUpdatePromoTrackRating] - checks to see if an update request has already been set for this track, if so,
 * it cancels the previous before kicking off the next one (see watchUpdatePromoTrackRating)
 */

const PENDING_UPDATES = {
  // [trackId]: task (from saga fork effect)
};

export function* handleUpdatePromoTrackRating(action) {
  // We store each pending action by trackId since we only want to cancel if a task exists for this track.
  // If we only cared about preventing the same action type, we'd just use the takeLatest effect
  const { trackId } = action.payload;
  const pendingTask = PENDING_UPDATES[trackId];

  // Even though we debounce rating updates, it's still possible that there is a pending request. In that event
  // we cancel the pending request here. Cancel has no effect if task is complete.
  if (pendingTask) yield cancel(pendingTask);

  // We use fork so that it doesn't block more incoming updateTrackReaction actions
  const task = yield fork(updatePromoTrackReaction, action);
  PENDING_UPDATES[trackId] = task;
}

export function* handleDebounce(action) {
  yield delay(500); // debounce duration
  yield fork(handleUpdatePromoTrackRating, action);
}

/**
 * [watchUpdatePromoTrackRating] - handles optimistic update of promo track ratings. The saga does the following:
 * 1) debounce incoming rating updates for a given trackId by 500ms
 * 2) If multiple rating updates are sent to the server (before responses returned), it only takes that last one
 *
 * NOTE: the reducer also watches for the action updateTrackRatingOptimistic and optimistically sets
 * the rating state, and reverts on failure
 *
 * Action payload:
 * @param {number} trackId
 * @param {number} artistId
 * @param {number} bio_id
 * @param {number} rating
 */

const DEBOUNCE_TASKS = {
  // [trackId]: task
};

export function* watchUpdatePromoTrackReaction() {
  while (true) {
    const action = yield take(updateTrackReaction.call().type);
    const { payload = {}, meta = {} } = action;
    const { trackId } = payload;
    const { optimistic } = meta;

    if (optimistic) {
      yield put(updateTrackReactionOptimistic(action.payload));
    }

    const debounceTask = DEBOUNCE_TASKS[trackId];

    if (debounceTask) yield cancel(debounceTask); // NOTE: if task is completed, cancel will have no effect

    DEBOUNCE_TASKS[trackId] = yield fork(handleDebounce, action);
  }
}
