import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import CommaList from 'components/CommaList';
import { ARTIST_ROLES, AUDIO_TYPES } from 'config/constants';
import { getTrackArtistsByRole } from 'helpers';

const PlayerTitle = ({ playing, noLink }) => {
  if (!playing) return;

  switch (playing.type) {
    case AUDIO_TYPES.COMMERCIAL:
      return <span>{playing.commercial_title}</span>;
    case AUDIO_TYPES.SINGLE:
      return <span>{playing.artist_name}</span>;
    case AUDIO_TYPES.TRACK: {
      const originalArtists = getTrackArtistsByRole(
        playing.artists,
        ARTIST_ROLES.ORIGINAL
      );

      return (
        <>
          <CommaList
            data={originalArtists}
            attribute="name"
            urlAttribute={noLink ? null : artist => `${artist.slug}/tracks`}
          />
        </>
      );
    }
    default:
      if (noLink) return <span>{playing.artist_name}</span>;
      return <Link to={playing.artist_slug}>{playing.artist_name}</Link>;
  }
};

PlayerTitle.propTypes = {
  noLink: PropTypes.bool
};

export default PlayerTitle;
