import React from 'react';

import { DEFAULT_ICON_COLOR, ICON_PROP_TYPES } from './config';

const Connections = ({ color = DEFAULT_ICON_COLOR, size = 18, className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    height={size}
    style={style}
    viewBox="0 0 42.3 42.3"
  >
    <title>Connections</title>

    <path
      d="M18.8,21.2c-1.4-0.8-2.4-2.4-2.4-4.1c0-2.6,2.1-4.8,4.8-4.8s4.8,2.1,4.8,4.8c0,1.8-1,3.3-2.4,4.1c3,0.9,5.3,3.4,5.9,6.5H12.9C13.5,24.6,15.8,22,18.8,21.2z M20,4.9v4.4h1.5V4.9c1-0.3,1.7-1.3,1.7-2.4c0-1.4-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5C18.3,3.6,19,4.6,20,4.9z M7.7,10.7c0.4,0,0.8-0.1,1.1-0.3l3.9,3.9l1.1-1.1L10,9.4c0.5-0.9,0.3-2.1-0.4-2.9c-1-0.9-2.6-0.9-3.6,0C5.5,6.9,5.2,7.6,5.2,8.2C5.2,8.9,5.5,9.5,6,10C6.4,10.5,7.1,10.7,7.7,10.7z M10.9,20.7h-6c-0.3-1-1.3-1.7-2.4-1.7C1.1,19,0,20.1,0,21.5C0,22.9,1.1,24,2.5,24c1.1,0,2.1-0.7,2.4-1.7h6V20.7z M12.5,29.2l-3.1,3.1c-0.9-0.5-2.1-0.3-2.9,0.4c-0.5,0.5-0.7,1.1-0.7,1.8c0,0.7,0.3,1.3,0.7,1.8c0.5,0.5,1.1,0.7,1.8,0.7c0.7,0,1.3-0.3,1.8-0.7s0.7-1.1,0.7-1.8c0-0.4-0.1-0.8-0.3-1.1l3.1-3.1L12.5,29.2z M22.3,37.4v-5.2h-1.5v5.2c-1,0.3-1.7,1.3-1.7,2.4c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5C24,38.7,23.3,37.8,22.3,37.4z M33.5,31.9l-3.1-3.1l-1.1,1.1l3.1,3.1c-0.2,0.4-0.3,0.7-0.3,1.1c0,0.7,0.3,1.3,0.7,1.8s1.1,0.7,1.8,0.7c0.7,0,1.3-0.3,1.8-0.7c0.5-0.5,0.7-1.1,0.7-1.8c0-0.7-0.3-1.3-0.7-1.8C35.6,31.6,34.4,31.4,33.5,31.9z M39.8,18.3c-1.1,0-2.1,0.7-2.4,1.7h-6v1.5h6c0.3,1,1.3,1.7,2.4,1.7c1.4,0,2.5-1.1,2.5-2.5S41.2,18.3,39.8,18.3z M29.1,13.9L33,10c0.3,0.2,0.7,0.3,1.1,0.3c0.7,0,1.3-0.3,1.8-0.7c0.5-0.5,0.7-1.1,0.7-1.8c0-0.7-0.3-1.3-0.7-1.8c-1-1-2.6-1-3.6,0c-0.8,0.8-0.9,2-0.5,2.9L28,12.8L29.1,13.9z"
      fill={color}
    />
  </svg>
);

Connections.propTypes = ICON_PROP_TYPES;

export default Connections;
