import React from 'react';

import { DEFAULT_ICON_COLOR, ICON_PROP_TYPES } from './config';

const Link = ({ color = DEFAULT_ICON_COLOR, size = 18, className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    height={size}
    style={style}
    viewBox="0 0 512 512"
  >
    <title>Link</title>
    <path
      d="M208 352h-64a96 96 0 010-192h64M304 160h64a96 96 0 010 192h-64M163.29 256h187.42"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="36"
    />
  </svg>
);

Link.propTypes = ICON_PROP_TYPES;

export default Link;
