import { useState, useCallback } from 'react';

const useToggle = (initialOpenState = false) => {
  const [isOpen, setIsOpen] = useState(initialOpenState);

  const toggle = useCallback(() => setIsOpen(prevOpenState => !prevOpenState), []);

  const open = useCallback(() => setIsOpen(true), []);

  const close = useCallback(() => setIsOpen(false), []);

  return { isOpen, open, close, toggle };
};

export default useToggle;
