import React from 'react';

import { DEFAULT_ICON_COLOR, ICON_PROP_TYPES } from './config';

const Label = ({ color = DEFAULT_ICON_COLOR, size = 18, className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    height={size}
    style={style}
    viewBox="0 0 57 48"
  >
    <title>Label</title>
    <path
      d="M28.5,15.5c-7.6,0-13.8,6.2-13.8,13.8c0,7.6,6.2,13.8,13.8,13.8c7.6,0,13.8-6.2,13.8-13.8C42.3,21.7,36.1,15.5,28.5,15.5z M28.5,33.1c-2.1,0-3.9-1.7-3.9-3.9c0-2.1,1.7-3.9,3.9-3.9c2.1,0,3.9,1.7,3.9,3.9C32.3,31.4,30.6,33.1,28.5,33.1z"
      fill="transparent"
    />
    <path
      d="M28.5,0L0,19.7h9.7v27.9h37.6V19.7h9.7L28.5,0z M28.5,43.1c-7.6,0-13.8-6.2-13.8-13.8c0-7.6,6.2-13.8,13.8-13.8c7.6,0,13.8,6.2,13.8,13.8C42.3,36.9,36.1,43.1,28.5,43.1z"
      fill={color}
    />
    <path d="M24.6,29.3a3.9,3.9 0 1,0 7.8,0a3.9,3.9 0 1,0 -7.8,0" fill={color} />
  </svg>
);

Label.propTypes = ICON_PROP_TYPES;

export default Label;
