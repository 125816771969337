import React from 'react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import { useAppDispatch } from 'hooks/redux';
import { requestPassword } from 'api';
import Modal from 'components/Modal';
import { TextInput, SubmitButton } from 'components/Modal/Input';
import { showAlert } from 'redux/actions/ui';

const SUBTITLE =
  "No worries! Enter your username or email address below and we'll send you a message to help you reset it.";

const INITIAL_VALUES = {
  emailOrUsername: ''
};

const VALIDATION_SCHEMA = Yup.object().shape({
  emailOrUsername: Yup.string().required('Email address or username is required.')
});

type Values = typeof INITIAL_VALUES;

interface Props {
  onClose?: () => void;
}

export const ForgotPasswordModal: React.FC<Props> = ({ onClose }) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const dispatch = useAppDispatch();

  if (showSuccessModal) {
    return <SuccessModal onClose={onClose} />;
  }

  const handleSubmit = ({ emailOrUsername }: Values) => {
    return requestPassword(emailOrUsername)
      .then(() => setShowSuccessModal(true))
      .catch(e => dispatch(showAlert(e)));
  };

  return (
    <Modal
      title="Forgot your password?"
      subtitle={SUBTITLE}
      onClose={onClose}
      enableBanner
    >
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
      >
        <Form>
          <TextInput
            type="text"
            name="emailOrUsername"
            label="Email or Username"
            autoFocus={true /* eslint-disable-line jsx-a11y/no-autofocus */}
          />
          <SubmitButton type="submit">Send It!</SubmitButton>
        </Form>
      </Formik>
    </Modal>
  );
};

const SuccessModal: React.FC<Props> = ({ onClose }) => (
  <Modal
    title="Success!"
    subtitle="A password reset link has been emailed to you."
    onClose={onClose}
  >
    <Modal.Text>
      If you no longer have access to your email address, please contact us at{' '}
      <a href="mailto:support@protonradio.com">support@protonradio.com</a>.
    </Modal.Text>
  </Modal>
);
