import _ from 'lodash';

import { ARTIST_ROLE_IDS } from 'config/constants';
import { getOrderedTrackArtists } from './tracks';

export const getReleaseArtists = tracks => {
  // returns array of unique artist for track (don't repeat)
  const allTrackArtists = tracks.reduce(
    (artistsAccum, track) => [...artistsAccum, ...track.artists],
    []
  );
  const allTrackArtistsSorted = getOrderedTrackArtists(allTrackArtists);
  // the above can return the same artist multiple time, filter out multiples using _uniqueBy
  return _.uniqBy(allTrackArtistsSorted, 'id');
};

const LINK_ICON = 'icon-link.svg';

export const getReleaseActions = (release, label) => [
  {
    name: `View ${release.name}`,
    icon: LINK_ICON,
    to: release.slug
  },
  {
    name: `View ${label.name}`,
    icon: LINK_ICON,
    to: label.slug
  }
];

/**
  * [getReleaseHeadlineArtists] - returns the calculated headliner artists if not explicitly set by label manager
  * 
  * NOTE: If using with data stored in redux, use with a selector. Otherwise consider memoizing this if used where
  * many re-renders are expected.
  * 
  * NOTE: this does not have the logic for rendering the headliner artists or displaying "Various Artists". If you
  * are rendering in react, look at ReleaseHeadliner component.
  * 
  * Ranking Criteria
  * 1) Sort artists by their role. Original -> featured -> remix
  * 2) If same role, sort by occurrences within release
  * 3) If same number of occurrences, sort by order in tracklist
  * 4) Only show artist once
  * 5) Only show featured/remix artists if they appear on every track

  * @param {object} release
  * @param {object[]} release.[headline_artists] - optionally set by label managers to explicityly set the 
  * release headlined.
  * @param {object[]} release.artist_roles - set in algolia based on track artists. Used to calculate
  * headline artists if 'headline_artists' is not set.
  * @returns {object[]} headliner artists to use for release passed. Example of output below.
  * 
  * {
  *   id: 4307
  *   name: "Sonic Union"
  *   slug: "/artists/4307/sonic-union"
  *   verified: false
  *   image_type: "thumb"
  *   image_url: "//storage.googleapis.com/soundsystem-assets/images/bios/4307.jpg"
  *   has_image: true,
  *   // role & release specific data:
  *   role_id: 1,
  *   single_id: 141234
  * }
  */

const ARTIST_ROLE_SORT_ORDER_BY_ID = {
  [ARTIST_ROLE_IDS.ORIGINAL]: 1,
  [ARTIST_ROLE_IDS.FEATURED]: 2,
  [ARTIST_ROLE_IDS.REMIX]: 3
};

export const getReleaseHeadlineArtists = release => {
  if (!release || !release.artist_roles) return [];

  if (release.headline_artists.length > 0) return release.headline_artists;

  const artistOccurencesById = _.countBy(
    release.artist_roles,
    artist => artist.artist.id
  );

  // NOTE: Not entirely sure why, but sorting was mutating the original state, so we clone it.
  // Soon this logic is moving to the backend, so not sweating this right now...
  const roles = [...release.artist_roles];

  const sortedArtistsRoles = roles
    .sort((a, b) => {
      // 1) sort by role
      if (a.role_id !== b.role_id) {
        return (
          ARTIST_ROLE_SORT_ORDER_BY_ID[a.role_id] -
          ARTIST_ROLE_SORT_ORDER_BY_ID[b.role_id]
        );
      }

      // 2) sort by occurrences within release, with most occurances per role showing first
      if (artistOccurencesById[a.artist.id] !== artistOccurencesById[b.artist.id]) {
        return artistOccurencesById[b.artist.id] - artistOccurencesById[a.artist.id];
      }

      // 3) If # of occurrences is the same, use tracklist order to sort
      if (a.single_id !== b.single_id) {
        return (
          release.track_ids.indexOf(a.single_id) - release.track_ids.indexOf(b.single_id)
        );
      }

      // If on same single, same role, and same # of occurances, sort by order provided
      return a.order - b.order;
    })
    .map(({ artist, role_id, single_id }) => ({ ...artist, role_id, single_id }));

  // Dedupe artists after sort, just in case an artist occurs in release with different roles.
  const uniqueSortedArtists = _.uniqBy(sortedArtistsRoles, 'id');

  // remove featured and remix artists unless they appear on every track
  return uniqueSortedArtists.filter(
    artist =>
      artist.role_id === ARTIST_ROLE_IDS.ORIGINAL ||
      artistOccurencesById[artist.id] === release.track_ids.length
  );
};
