import _ from 'lodash';
import { createSelector } from '@reduxjs/toolkit';

import { isProUser } from 'helpers/user';
import { USER_PROFILE_TYPES } from 'config/constants';
import { selectArtistById } from './artists';

/**
 * @param {import('config/store').RootState} state
 * @returns {import('types').V2ProUser}
 */
export const selectUser = state => state.user;
/**
 * @param {import('config/store').RootState} state
 * @returns {bool}
 */
export const selectIsUserFetching = state => state.user.isFetching;

/**
 * @param {import('config/store').RootState} state
 * @returns {import('types').V2UserSetting[]}
 */
export const selectUserSettings = state => state.user.user_settings;
/**
 * @param {import('config/store').RootState} state
 * @returns {import('types').UserProfile[]}
 */
export const selectUserProfiles = state => state.user.profiles;

/**
 * @param {import('config/store').RootState} state
 * @returns {import('types').UserArtistProfile[]}
 */
export const selectUserArtistProfiles = state => {
  const { profiles } = state.user;
  return profiles.filter(({ type }) => type === USER_PROFILE_TYPES.ARTIST);
};

/**
 * @param {import('config/store').RootState} state
 * @returns {import('types').UserLabelProfile[]}
 */
export const selectUserLabelProfiles = state => {
  const { profiles } = state.user;
  return profiles.filter(({ type }) => type === USER_PROFILE_TYPES.LABEL);
};

/**
 * @param {import('config/store').RootState} state
 * @returns {import('types').UserShowProfile[]}
 */
export const selectUserShowProfiles = state => {
  const { profiles } = state.user;
  return profiles.filter(({ type }) => type === USER_PROFILE_TYPES.SHOW);
};

/**
 * @param {import('config/store').RootState} state
 * @returns {import('types').V2UserSetting}
 */
export const selectUserAutoDownloadSettings = createSelector(
  [selectUserSettings],
  userSettings => userSettings.find(({ setting }) => setting === 'auto_download')
);

/**
 * @param {import('config/store').RootState} state
 * @returns {import('types').V2UserSetting}
 */
export const selectUserAudioDownloadLocation = createSelector(
  [selectUserSettings],
  userSettings => userSettings.find(({ setting }) => setting === 'download_location')
);

/**
 * @param {import('config/store').RootState} state
 * @returns {string}
 */
export const selectUserAudioDownloadType = state =>
  state.uiPersist.preferredAudioDownloadType;

export const selectLabelIdsUserManages = createSelector(
  [selectUserLabelProfiles],
  labels => labels.map(({ id }) => id)
);

export const isImpersonatingUser = state => state.token.isImpersonating;

export const selectIsProUser = state => isProUser(selectUser(state));

/** [selectIsAdminUser] - Selector for determining if the current user is an admin
 *
 * @param {object} state - automatically spread into user/token objects
 * @param {bool} [includeImpersonate=false] - Flag to determine if it should return true when an admin is masquerading as another user
 */

export const selectIsAdminUser = (state, options = {}) => {
  const { includeImpersonate = false } = options;
  return (
    state.user.accesslevel === 0 || !!(includeImpersonate && state.token.isImpersonating)
  );
};

// if rendering multiple instances, us makeSelectors
export const makeSelectIsUserArtistMember = () =>
  createSelector(
    [selectUserArtistProfiles, (__, { artistId }) => artistId],
    (artistProfiles, artistId) => artistProfiles.some(({ id }) => id === artistId)
  );
export const selectIsUserArtistMember = makeSelectIsUserArtistMember();

export const selectLabelIdsManagedForArtist = createSelector(
  [selectUserLabelProfiles, selectArtistById],
  (userLabels, artist = {}) => {
    const { releases_on = [] } = artist;
    const commonLabels = _.intersectionBy(userLabels, releases_on, 'id');
    return commonLabels.map(({ id }) => id);
  }
);

/**
 * [selectIsUserLabelManager] - determines if user manages a label on Proton
 * @returns {bool}
 */

export const selectIsUserLabelManager = createSelector(
  [selectUserLabelProfiles],
  labels => labels.length > 0
);

/**
 * [selectIsUserLabelManagerForArtist] - looks for artist data in redux and checks to see if artist has releases on
 * any labels that the current user manages
 *
 * NOTE: use selectHasArtistData
 *
 * @param {number} artistId
 * @returns {bool}
 */

export const selectIsUserLabelManagerForArtist = createSelector(
  [selectLabelIdsManagedForArtist],
  commonLabelIds => commonLabelIds.length > 0
);

export const selectLabelIdsUserManagesForArtist = createSelector(
  [selectUserLabelProfiles, selectArtistById],
  (userLabels, artist = {}) => {
    const { releases_on = [] } = artist;
    const commonLabels = _.intersectionBy(userLabels, releases_on, 'id');
    return commonLabels.map(({ id }) => id);
  }
);

/**
 * [selectLabelIdsUserHasReleasesOn] - returns the label ids that the current user's artists have releases on.
 *
 * @returns {number[]} label ids
 */

export const selectLabelIdsUserHasReleasesOn = createSelector(
  [selectUserArtistProfiles],
  userArtistProfiles =>
    _.uniq(
      userArtistProfiles.reduce(
        (accum, { releases_on = [] }) => [...accum, ...releases_on.map(({ id }) => id)],
        []
      )
    )
);

/**
 * [makeSelectDoesUserHaveReleasesOnLabel] - checks if user has an artist with a release on the provided label id
 *
 * @param {number} labelId
 * @returns {boolean}
 */

export const makeSelectDoesUserHaveReleasesOnLabel = () =>
  createSelector(
    [selectLabelIdsUserHasReleasesOn, (__, { labelId }) => labelId],
    (labelIds, labelId) => labelIds.includes(labelId)
  );

/**
 * [makeSelectIsLabelManagerForLabelId] - checks if current user is label manager for passed id.
 *
 * @param {number} labelId
 * @returns {boolean}
 */

export const makeSelectIsLabelManagerForLabelId = () =>
  createSelector(
    [selectUserLabelProfiles, (__, { labelId }) => labelId],
    (labelProfiles, labelId) => labelProfiles.some(({ id }) => id === labelId)
  );
