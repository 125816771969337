import { createAction } from 'redux-act';

import * as api from 'api';

const DEFAULT_RADIO_ID = 1;

export const fetchingRadio = createAction(
  'RADIO_FETCHING',
  (radioId = DEFAULT_RADIO_ID) => ({ radioId })
);

export const fetchRadioSuccess = createAction(
  'RADIO_FETCH_SUCCESS',
  (playing, radioId = DEFAULT_RADIO_ID) => ({ playing, radioId })
);

export const fetchRadioError = createAction(
  'RADIO_FETCH_ERROR',
  (error, radioId = DEFAULT_RADIO_ID) => ({ error, radioId })
);

export const setRadio = createAction(
  'RADIO_SET',
  (playing, radioId = DEFAULT_RADIO_ID) => ({ playing, radioId })
);

export const setRadioListeners = createAction(
  'RADIO_LISTENERS_SET',
  (listeners, radioId = DEFAULT_RADIO_ID) => ({ listeners, radioId })
);

export const decrementRadioListener = createAction(
  'RADIO_LISTENERS_DECREMENT',
  (radioId = DEFAULT_RADIO_ID) => radioId
);

export const incrementRadioListener = createAction(
  'RADIO_LISTENERS_INCREMENT',
  (radioId = DEFAULT_RADIO_ID) => radioId
);

// todo add fetching/error to redux state
export const getRadioPlaying =
  (radioId = DEFAULT_RADIO_ID) =>
  dispatch => {
    dispatch(fetchingRadio(radioId));

    return api
      .getRadio()
      .then(response => {
        dispatch(fetchRadioSuccess(response.data, radioId));
        return response.data;
      })
      .catch(error => dispatch(fetchRadioError(error.message, radioId)));
  };

export const checkForRadioUpdate = () => (dispatch, getState) => {
  const radioId = DEFAULT_RADIO_ID;
  const { end_time = Infinity } = getState().radio[radioId].playing;

  // if now is greater than the radio mix end time, assume we missed the now_playing update and fetch latest radio
  // give buffer of 5 seconds in case websocket slow to reconnect
  if (Date.now() / 1000 >= end_time - 5) getRadioPlaying(radioId)(dispatch);
};
