import { createMigrate } from 'redux-persist';

import playerMigration3 from './playerMigrations/migration3';
import rootMigration10 from './rootMigrations/migration10';
import rootMigration11 from './rootMigrations/migration11';
import rootMigration12 from './rootMigrations/migration12';
import rootMigration8 from './rootMigrations/migration8';

// Migrations for redux store

export const rootMigrations = {
  1: state => {
    // Example migration
    return state;
  },
  2: state => {
    // Updated radio reducer, delete and reinitialize
    const { radio, ...otherState } = state;
    return otherState;
  },
  3: state => {
    // clear mixes (stale check on top 100 preventing refetch for bug fix)
    const { mixes, ...rest } = state;
    return rest;
  },
  4: state => {
    // Screwed up order of 2 & 3 in merge conflict resolution
    // Still needed for PR #290
    const { radio, ...rest } = state;
    return rest;
  },
  5: state => {
    const { user } = state;
    return { user };
  },
  6: state => {
    // Updated artist to include users array.  Array isinitialized empty
    // so client doesn't have to check for its existance
    const { artists, ...rest } = state;
    return rest;
  },
  7: state => {
    const { user, ...rest } = state;
    const newUser = {
      ...user,
      privacy_policy_consents: []
    };

    return {
      ...rest,
      user: newUser
    };
  },
  8: state => rootMigration8(state),
  9: () => undefined, // Force state to re-initalize
  10: rootMigration10,
  11: rootMigration11,
  12: rootMigration12
};

export const playerMigrations = {
  1: state => {
    // Example migration
    return state;
  },
  2: () => null,
  3: playerMigration3,
  4: () => undefined // Force state to re-initalize
};

const debug = process.env.REACT_APP_ENV === 'development';

export default {
  root: createMigrate(rootMigrations, { debug }),
  player: createMigrate(playerMigrations, { debug })
};
