import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { COLORS, MEDIUM_BREAK_POINT } from 'config/constants';

const StyledWell = styled.div`
  align-items: ${({ align }) => align || 'center'};
  background: ${({ type }) => type.BACKGROUND_COLOR};
  border-radius: 2rem;
  color: ${({ type }) => type.COLOR};
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 1.6rem;
  font-weight: 500;
  justify-content: ${({ justify }) => justify || 'center'};
  line-height: 1.5;
  margin: 2rem 0;
  padding: ${({ size }) => size.DESKTOP};
  text-align: ${({ $textAlign }) => $textAlign};

  @media screen and (max-width: ${MEDIUM_BREAK_POINT}px) {
    padding: ${({ size }) => size.MOBILE};
  }
`;

const Well = ({
  children,
  className,
  size = Well.SIZES.NORMAL,
  type = Well.TYPES.PRIMARY,
  textAlign = 'center',
  'data-testid': testId = 'Well',
  ...rest
}) => {
  return (
    <StyledWell
      type={type}
      className={className}
      size={size}
      data-testid={testId}
      $textAlign={textAlign}
      {...rest}
    >
      {children}
    </StyledWell>
  );
};

Well.SIZES = {
  COMPACT: {
    DESKTOP: '2rem',
    MOBILE: '2rem'
  },
  NORMAL: {
    DESKTOP: '6rem 3rem',
    MOBILE: '3rem 2rem'
  },
  MEDIUM: {
    DESKTOP: '3rem',
    MOBILE: '3rem'
  }
};

Well.TYPES = {
  PRIMARY: {
    BACKGROUND_COLOR: COLORS.GRAY_LIGHTEST,
    COLOR: COLORS.GRAY_DARK
  },
  DANGER: {
    BACKGROUND_COLOR: COLORS.DANGER,
    COLOR: COLORS.WHITE
  }
};

Well.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
  type: PropTypes.shape(),
  size: PropTypes.shape(),
  textAlign: PropTypes.string,
  'data-testid': PropTypes.string
};

export default Well;
