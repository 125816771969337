import axios from 'axios';
import { API_BASE_V2 } from 'config/constants';

export const googleCallback = (response: unknown) =>
  axios.post(`${API_BASE_V2}/google_auth/callback`, response);

interface OAuthDisconnectResponse {
  success: true;
  message: string;
}

export const disconnectDropbox = async () => {
  const { data } = await axios.delete<OAuthDisconnectResponse>(
    `${API_BASE_V2}/dropbox_auth`
  );
  return data;
};

export const disconnectGoogle = async () => {
  const { data } = await axios.delete<OAuthDisconnectResponse>(
    `${API_BASE_V2}/google_auth`
  );
  return data;
};
