import { css, keyframes } from 'styled-components';
import { darken } from 'polished';

const shimmering = keyframes`
  0% {
    background-position: -300px 0;
  }
  100% {
    background-position: 800px 0;
  }
`;

export const StyledTombstoneAnimation = css`
  animation: ${shimmering} 1.5s linear infinite;
  background-image: linear-gradient(-90deg, #f6f8f9 0%, #edf1f2 50%, #f6f8f9 100%);
  background-position: -300px 0;
  background-repeat: no-repeat;
  background-size: 300px 100%;
  background-color: #f6f8f9;
`;

export const StyledTombstoneDark = css`
  ${StyledTombstoneAnimation};
  background-image: linear-gradient(
    -90deg,
    #444 0%,
    ${darken(0.1, '#444')} 50%,
    #444 100%
  );
  background-color: #444;
`;

export const StyledTombstoneLight = css`
  ${StyledTombstoneAnimation};
  background-image: linear-gradient(
    -90deg,
    #d1d1d1 0%,
    ${darken(0.1, '#d1d1d1')} 50%,
    #d1d1d1 100%
  );
  background-color: #d1d1d1;
`;
