/**
 * [selectIsFeatureActive] - reactive approach for checking feature flags. Also avoids circular dep issue
 */

import { RootState } from 'config/store';

export const selectIsFeatureActive = (state: RootState, featureName: string) => {
  // Hacking in whitelist for Dropbox test user
  if (state.user.user_id === 326795) return true;
  // type assertion here should be removed once `feature` reducer is typed
  const featureState = state.features[featureName] as { live: boolean; enabled: boolean };

  // NOTE: live will override the enabled state
  return featureState.live || featureState.enabled;
};
