import React from 'react';

import { arrayToCommaList } from 'helpers/text';

import CommaList from '../CommaList';

/**
 * [ReleaseHeadliner] comma list that handles rendering headliner artists for a release. To determine the
 * headliner artists, reference helpers `getReleaseHeadlineArtists` or release.headlinerArtists from gql query.
 */

const ReleaseHeadliner = ({
  headlinerArtists,
  maxArtistCount = 5
}: {
  headlinerArtists: {
    name?: string | null;
    slug?: string | null;
  }[];
  maxArtistCount?: number;
}) => {
  if (headlinerArtists.length > maxArtistCount) {
    const artistTitleList = arrayToCommaList(headlinerArtists, { attribute: 'name' });

    return (
      <span data-testid={ReleaseHeadliner.TEST_IDS.CONTAINER} title={artistTitleList}>
        Various Artists
      </span>
    );
  }

  return (
    <CommaList
      data={headlinerArtists}
      attribute="name"
      urlAttribute="slug"
      testId={ReleaseHeadliner.TEST_IDS.CONTAINER}
    />
  );
};

ReleaseHeadliner.TEST_IDS = {
  CONTAINER: 'ReleaseHeadliner'
};

export default ReleaseHeadliner;
