import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { PLAYER_STATES } from 'config/constants';
import userAgent from 'helpers/userAgent';
import useProtonPlayer from 'hooks/useProtonPlayer';

class PlayerWaveformAnimated extends Component {
  constructor(props) {
    super(props);

    this.BAR_WIDTH = 2;
    this.BAR_PADDING = 2;
    this.mousePosition = 0;

    this.state = {
      height: 60,
      width: 500,
      numberOfBars: 100,
      isVisible: true,
      loaded: false
    };

    this.drawCanvas = this.drawCanvas.bind(this);
    this.resizeWaveform = this.resizeWaveform.bind(this);
    this.loop = this.loop.bind(this);
    this.renderWaveform = this.renderWaveform.bind(this);
  }

  componentDidMount() {
    this.canvasContext = this.canvas.getContext('2d');
    this.renderWaveform();
  }

  componentWillUnmount() {
    // Remove event listener, cancel the animation frame loop,
    // and close the AudioContext as browsers typically have a
    // hard 6 AudioContext limit without a full page refresh.
    cancelAnimationFrame(this.raf);
    if (this.audioContext) this.audioContext.close();
  }

  updateAudioContext() {
    // Change this to find the current audio ID, which might not be '0' if the user
    // has played/stopped the feed multiple times.
    this.audioElement = window.soundManager.sounds[window.soundManager.soundIDs['0']]._a;
    // this.audioElement.crossOrigin = 'anonymous';

    try {
      this.audioSource = this.audioContext.createMediaElementSource(this.audioElement);
      this.audioSource.crossOrigin = 'anonymous';
      this.analyser = this.audioContext.createAnalyser();
      this.analyser.smoothingTimeConstant = 0.85;
      this.analyser.fftSize = 256;
      this.audioSource.connect(this.analyser);
      this.audioSource.connect(this.audioContext.destination);
      this.frequencyData = new Uint8Array(this.analyser.frequencyBinCount);
      this.resampledData = new Uint8Array(this.state.numberOfBars);
    } catch (e) {
      // Ignore
    }
  }

  createAudioContext() {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    if (!AudioContext) throw new Error('AudioContext not supported');

    this.audioContext = new AudioContext();

    // This apparently isn't part of any official HTML5 Audio spec, but
    // it keeps Chrome and other browsers happy.
    this.audioContext.maxChannelCount = 2;
    this.updateAudioContext();
  }

  resampleData() {
    const elements = this.frequencyData.length;
    const deltaT = elements / this.state.numberOfBars;

    this.resampledData = [];

    for (let i = 0; i < this.state.numberOfBars; i++) {
      const t = deltaT * i;
      const leftWeight = t % 1;
      const leftIndex = Math.floor(t);

      // This takes the raw 0-1 value frequency data coming out of AnalyserNode
      // and interpolates between the left/right values of each to calculate a
      // new value based on the width  number of bars based on the container width.
      this.resampledData[i] =
        ((leftWeight * this.frequencyData[leftIndex] +
          (1 - leftWeight) * this.frequencyData[leftIndex + 1]) *
          (this.state.height / 255)) /
        2;

      if (i < 3) {
        this.resampledData[i] = (this.resampledData[i] * 1) / (4 - i);
      }
    }
  }

  resizeWaveform() {
    const height = this.container.clientHeight;
    const width = this.container.clientWidth;
    const numberOfBars = Math.floor(width / (this.BAR_WIDTH + this.BAR_PADDING));

    this.setState({
      width,
      height,
      numberOfBars
    });
  }

  loop() {
    // The audio node has been GC'd. This terminates the RAF loop.
    if (!this.analyser) return;

    this.analyser.getByteFrequencyData(this.frequencyData);
    this.resampleData();
    this.drawCanvas();
    this.raf = requestAnimationFrame(this.loop);
  }

  drawCanvas() {
    this.canvasContext.clearRect(0, 0, this.state.width, this.state.height);

    this.resampledData.map((value, key) => {
      const posX =
        key * (this.BAR_WIDTH + this.BAR_PADDING) +
        (this.BAR_WIDTH + this.BAR_PADDING + this.BAR_WIDTH);
      const posY = 30 - value;
      const halfHeight = this.state.height / 2;
      const halfWidth = this.state.width / 2;

      // Double mirror and draw waveform data to all four quadrants of the canvas.
      this.canvasContext.fillStyle = '#E26014';
      this.canvasContext.fillRect(halfWidth - posX, posY, this.BAR_WIDTH, value);
      this.canvasContext.fillRect(halfWidth - posX, halfHeight, this.BAR_WIDTH, value);
      this.canvasContext.fillRect(halfWidth + posX, posY, this.BAR_WIDTH, value);
      this.canvasContext.fillRect(halfWidth + posX, halfHeight, this.BAR_WIDTH, value);

      return true;
    });
    this.canvasContext.fill();
    return true;
  }

  renderWaveform() {
    // For now, don't animate on Firefox and Safari.
    // - Firefox: performance is bad.
    // - Safari: this doesn't work.
    if (userAgent.isSafari) return;

    if (_isEmpty(window.soundManager.sounds)) {
      return setTimeout(() => this.renderWaveform(), 1000);
    }

    try {
      this.createAudioContext();
    } catch (e) {
      if (e.message === 'AudioContext not supported') return;
      throw e;
    }

    this.loop();
  }

  render() {
    const { playerState } = this.props;

    const outerClasses = classNames('Player__waveform-container', {
      'is-visible': playerState === PLAYER_STATES.PLAYING
    });

    const innerClasses = classNames({
      Waveform__inner: true,
      Waveform__live: true,
      'is-visible': playerState === PLAYER_STATES.PLAYING
    });

    return (
      <div className={outerClasses} data-testid="Player-waveform">
        <div
          className="Waveform Waveform--animated"
          ref={el => {
            this.container = el;
          }}
        >
          <div className={innerClasses}>
            <ReactResizeDetector
              handleWidth
              handleHeight
              onResize={this.container ? this.resizeWaveform : undefined}
            />
            <canvas
              ref={el => {
                this.canvas = el;
              }}
              id="waveform-canvas"
              height={this.state.height}
              width={this.state.width}
            />
          </div>
        </div>
      </div>
    );
  }
}

const PlayerWaveformAnimatedWrapper = () => {
  const player = useProtonPlayer();
  const currentlyPlaying = player.currentTrack;

  return <PlayerWaveformAnimated key={currentlyPlaying?.id} playerState={player.state} />;
};

export default PlayerWaveformAnimatedWrapper;
