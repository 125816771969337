import { EventEmitter } from 'events';

import type { PlayerState } from './AudioPlayer';
import { soundManager } from 'soundmanager2';

const SOUND_ID = 'PROTON_RADIO';

soundManager.setup({
  useHTML5Audio: true
});

export default class RadioPlayer extends EventEmitter {
  audio: {} | null;
  state: PlayerState;
  position: number;
  sound: any;

  constructor() {
    super();

    this.audio = null;
    this.state = 'STOPPED';
  }

  send(messageType: string, payload: any) {
    if (this[messageType]) {
      setTimeout(() => {
        this[messageType](payload);
      }, 0);
    }
  }

  play({ audio }) {
    this.audio = {
      ...audio,
      type: 'radio'
    };
    this.state = 'PLAYING';

    this.sound = soundManager.createSound({
      id: SOUND_ID,
      url: 'https://shoutcast.protonradio.com/;',
      autoLoad: false,
      autoPlay: false,
      volume: 50
    });
    this.sound._a.crossOrigin = 'anonymous';
    soundManager.load(SOUND_ID);
    soundManager.play(SOUND_ID);

    this.emit('PLAYBACK_STARTED', { audio: this.audio });
    this.emit('STATE_CHANGED', { state: this.state });
  }

  pause() {
    this.state = 'PAUSED';
    soundManager.pause(SOUND_ID);
    this.emit('STATE_CHANGED', { state: this.state });
  }

  reset() {
    if (this.state === 'STOPPED') return;

    if (this.sound) {
      this.sound.destruct();
    }

    this.state = 'STOPPED';
    this.audio = null;
    this.sound = null;
    this.emit('STATE_CHANGED', { state: this.state });
  }

  resume() {
    if (!this.audio) return;
    this.state = 'PLAYING';

    soundManager.resume(SOUND_ID);
    this.emit('STATE_CHANGED', { state: this.state });
  }

  toggle() {
    if (this.state === 'PLAYING') {
      this.pause();
    } else {
      this.resume();
    }
  }

  setVolume(volume: number): void {
    if (this.sound) {
      this.sound.setVolume(volume * 100);
      this.emit('VOLUME_CHANGED', { volume });
    }
  }
}
