import PropTypes from 'prop-types';
import React from 'react';

import { getTrackArtistsByRole } from 'helpers';
import { ARTIST_ROLES } from 'config/constants';
import CommaList from 'components/CommaList';

const RemixArtistCell = ({ artists, highlightAttribute, hit }) => {
  const remixArtists = getTrackArtistsByRole(artists, ARTIST_ROLES.REMIX);

  if (!remixArtists.length) {
    return <strong>–</strong>;
  }
  return (
    <span data-testid="track-remix-artists">
      <CommaList
        data={remixArtists}
        title
        attribute="name"
        urlAttribute={artist => `${artist.slug}/tracks`}
        // optional props:
        highlightAttribute={highlightAttribute}
        hit={hit}
      />
    </span>
  );
};

RemixArtistCell.propTypes = {
  artists: PropTypes.arrayOf(PropTypes.shape()),
  highlightAttribute: PropTypes.string,
  hit: PropTypes.shape()
};

export default RemixArtistCell;
