import React from 'react';
import type { DspName } from 'types';

import AppleMusic from './AppleMusic';
import Beatport from './BeatportLogo';
import SoundCloud from './SoundCloudLogo';
import Spotify from './SpotifyLogo';
import { IconProps } from './iconTypes';

type DspIconProps = IconProps & {
  service: DspName;
};

const DspIcon = ({ service, ...iconProps }: DspIconProps) => {
  switch (service) {
    case 'soundcloud':
      return <SoundCloud {...iconProps} />;

    case 'spotify':
      return <Spotify {...iconProps} />;

    case 'apple-music':
    case 'apple_music':
      return <AppleMusic {...iconProps} />;

    case 'beatport':
      return <Beatport {...iconProps} />;
  }
};

export default DspIcon;
