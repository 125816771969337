import React from 'react';
import styled from 'styled-components';

import { MEDIUM_BREAK_POINT } from 'config/constants';
import ActionMenuItem, { ActionMenuItemProps } from './ActionMenuItem';

const ListContainer = styled.ul`
  padding-bottom: 1.5rem;
  padding-top: 0.5rem;

  .ActionMenuItem:first-child {
    margin-top: 0;
  }

  /* When displayed in larger screens, action menu is centered on page like a modal. 
  The confirmation menu is an action menu that is sometimes displayed on desktop. */
  @media (min-width: ${MEDIUM_BREAK_POINT}px) {
    padding-bottom: 0.5rem;
  }
`;

const Divider = styled.div`
  height: 1px;
  background: #f0f0f0;
  margin: 0.5rem 1rem;
`;

interface ActionMenuItemDividerProps {
  divider: boolean;
}

function isActionMenuItemDividerProps(
  action: ActionMenuItemProps | ActionMenuItemDividerProps
): action is ActionMenuItemDividerProps {
  return 'divider' in action;
}

interface Props {
  actions?: ActionMenuItemProps[];
  children?: React.ReactNode;
  closeMenu?: () => void;
  className?: string;
  disabled?: boolean;
}

const ActionMenuList = ({
  actions = [],
  children,
  closeMenu = () => null,
  className,
  disabled
}: Props) => (
  <ListContainer className={className}>
    {/* Extract action props not used */}
    {actions.map(({ Component, ...action }) => {
      if (isActionMenuItemDividerProps(action)) {
        if (!action.divider) return null;
        return <Divider key="divider" />;
      }
      if (Component) return <Component actionMenu />;

      return (
        <ActionMenuItem
          key={action.name}
          {...action}
          disabled={disabled}
          closeMenu={closeMenu}
        />
      );
    })}

    {children}
  </ListContainer>
);

export default ActionMenuList;
