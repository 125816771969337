import PropTypes from 'prop-types';
import React from 'react';

import ErrorPage, { ERROR_PAGE_STATUS } from '../ErrorPage';

// For now just recycling NotFound component
const Unauthorized = ({ use401 = true }) => (
  <ErrorPage
    error={use401 ? ERROR_PAGE_STATUS.NOT_FOUND : ERROR_PAGE_STATUS.UNAUTHORIZED}
  />
);

Unauthorized.propTypes = {
  use401: PropTypes.bool
};

export default Unauthorized;
