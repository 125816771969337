import { differenceInSeconds, parseISO } from 'date-fns';

import { AUDIO_TYPES, ASYNC_OPERATIONS } from 'config/constants';
import { createAction } from '@reduxjs/toolkit';
import { createAsyncActions } from './createAsyncActions';

export { createAsyncActions } from './createAsyncActions';
export const STALE_DURATION_TOP_10_MIXES = 60 * 60 * 24; // keep top 10 mix ids up to 24 hours in [seconds]

export const resetStore = createAction('RESET_STORE');

export const garbageCollect = createAction('GARBAGE_COLLECTOR');

export const rehydrateAction = 'persist/REHYDRATE';

// return mapping of mix and track ids that should remain in the redux store
export const getPersistedAudio = (state, player) => {
  const persistMixIds = [];
  const persistTrackIds = [];

  // ensure audio data for the player queue is persisted
  const queue = player?.playlist.map(x => x.meta);
  if (player.type !== 'radio' && queue && queue.length > 0) {
    const mixIds = queue
      .filter(queueItem => queueItem.audioType === AUDIO_TYPES.MIX)
      .map(queueItem => queueItem.audioId);
    const trackIds = queue
      .filter(queueItem => queueItem.audioType === AUDIO_TYPES.TRACK)
      .map(queueItem => queueItem.audioId);
    persistMixIds.push(...mixIds);
    persistTrackIds.push(...trackIds);
  }

  const { topMixIds } = state.mixes;
  if (topMixIds.lastFetched) {
    const fetchAge = differenceInSeconds(new Date(), parseISO(topMixIds.lastFetched));
    if (fetchAge < STALE_DURATION_TOP_10_MIXES) {
      const top100MixIds = topMixIds.data.slice(0, 100);
      persistMixIds.push(...top100MixIds);
    }
  }

  return {
    mixIds: persistMixIds,
    trackIds: persistTrackIds
  };
};

const getPromoReleaseIdsToPersist = state => state.releaseIdsFollowing.data;

const getPromoTrackIdsPersist = state => {
  const promoReleaseIds = getPromoReleaseIdsToPersist(state);
  return promoReleaseIds.reduce((trackIdsAccum, releaseId) => {
    const release = state.releasesById[releaseId] || {};
    const releaseTrackIds = release.trackIds || [];
    return [...trackIdsAccum, ...releaseTrackIds];
  }, []);
};

const getPersistedPromos = state => {
  const promoReleaseIds = getPromoReleaseIdsToPersist(state.promoReleases);
  const promoTrackIds = getPromoTrackIdsPersist(state.promoReleases);
  return { promoReleaseIds, promoTrackIds };
};

// To ensure we aren't storing too many mixes, only keep the top 100 plus whatever was last playing in the player
export const garbageCollectReduxStore = player => (dispatch, getState) => {
  const state = getState();
  const { promoReleaseIds, promoTrackIds } = getPersistedPromos(state);
  const { mixIds, trackIds } = getPersistedAudio(state, player);

  return dispatch(
    garbageCollect({
      persistMixIds: mixIds,
      persistTrackIds: trackIds,
      persistPromoReleaseIds: promoReleaseIds,
      persistPromoTrackIds: promoTrackIds
    })
  );
};

export const fetchPaginatedDataActions = createAsyncActions(
  'PAGINATED_DATA',
  ASYNC_OPERATIONS.FETCH
);
