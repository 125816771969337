import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';

import Button from 'components/Button';
import FormikWrapper from 'components/Form/Wrappers/FormikWrapper';
import Flex from 'components/Flex';
import TextHeader from 'components/TextHeader';

import { updateTrackReaction as updateTrackReactionAction } from 'redux/actions/promoReleases';
import {
  selectPromoTrackReactionArtistId,
  selectPromoTrackReaction
} from 'redux/selectors/promoReleases';

import { COLORS } from 'config/constants';
import { generateSelectorsFromTestIds } from 'helpers/utilities';

const SaveButtonWrapper = styled.div`
  background-color: ${COLORS.GRAY_LIGHTEST};
  border-radius: 0 0 1rem 1rem;
  bottom: 1.5rem;
  padding: 1.5rem 0.8rem 0.8rem 0.8rem;
  right: 1rem;
`;

const OptionalWrapper = styled.div`
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  transition: opacity 0.15s ease-in-out;
`;

const PromoFeedbackForm = ({
  autoFocus = false,
  onHeightChange = () => null,
  track,
  onSuccess,

  // redux connect
  artistId,
  reaction,
  updateTrackReaction
}) => {
  const comment = (reaction && reaction.comment) || '';
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <FormikWrapper
      initialValues={{ trackFeedback: comment }}
      onSubmit={values =>
        new Promise((resolve, reject) => {
          updateTrackReaction(
            {
              track,
              artistId,
              comment: values.trackFeedback
            },
            {
              onSuccess: () => {
                setIsExpanded(false);

                if (onSuccess) onSuccess();
                resolve();
              },
              onError: reject
            }
          );
        })
      }
      hideButton
      className="mb-0"
    >
      <Flex column>
        <Field name="trackFeedback">
          {({ field, form: { setFieldValue, submitForm, isSubmitting } }) => (
            <>
              <TextareaAutosize
                value={field.value || ''}
                placeholder="Add some feedback..."
                data-testid={PromoFeedbackForm.TEST_IDS.FORM}
                autoFocus={autoFocus}
                maxRows={10}
                onChange={e => {
                  if (e.target.value.length > 0 && !isExpanded) {
                    setIsExpanded(true);
                    onHeightChange();
                  }
                  setFieldValue(field.name, e.target.value);
                }}
                onBlur={e => {
                  if (!e.target.value.length > 0) setIsExpanded(false);
                  onHeightChange();
                }}
                onHeightChange={onHeightChange}
                style={{
                  background: COLORS.GRAY_LIGHTEST,
                  border: 'none',
                  borderRadius: '0.4rem',
                  color: '#555',
                  fontSize: '1.4rem',
                  lineHeight: '1.5',
                  minWidth: '25rem',
                  padding: '1rem 1rem 0.9rem 1rem',
                  resize: 'none',
                  width: '100%'
                }}
              />

              <OptionalWrapper $isVisible={field.value.length < 30}>
                <TextHeader type={TextHeader.TYPES.XSMALL} uppercase>
                  Optional
                </TextHeader>
              </OptionalWrapper>

              {isExpanded && (
                <SaveButtonWrapper>
                  <Button
                    color={Button.COLORS.SECONDARY}
                    onClick={submitForm}
                    data-testid={PromoFeedbackForm.TEST_IDS.SUBMIT}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Saving...' : 'Save'}
                  </Button>
                </SaveButtonWrapper>
              )}
            </>
          )}
        </Field>
      </Flex>
    </FormikWrapper>
  );
};

PromoFeedbackForm.TEST_IDS = {
  FORM: 'PromoFeedbackForm',
  SUBMIT: 'PromoFeedbackForm-submit'
};

PromoFeedbackForm.SELECTORS = generateSelectorsFromTestIds(PromoFeedbackForm.TEST_IDS);

PromoFeedbackForm.propTypes = {
  autoFocus: PropTypes.bool,
  onHeightChange: PropTypes.func,
  track: PropTypes.shape({
    id: PropTypes.number.isRequired,
    release: PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  }).isRequired,
  onSuccess: PropTypes.func,

  // redux connect
  reaction: PropTypes.shape({
    comment: PropTypes.string
  }),
  artistId: PropTypes.number,
  updateTrackReaction: PropTypes.func
};

export default connect(
  (state, { track }) => ({
    reaction: selectPromoTrackReaction(state, track.id),
    artistId: selectPromoTrackReactionArtistId(state, track.release.id, track.id)
  }),
  { updateTrackReaction: updateTrackReactionAction.call }
)(PromoFeedbackForm);
