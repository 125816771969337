import { createReducer } from 'redux-act';

import {
  clearSubscriberSearchQuery,
  clearSubscriberSearch,
  setSubscriberSearchResults,
  removeFromSubscriberSearchResults,
  fetchingSubscriberSearchResults
} from 'redux/actions/subscriberSearch';

const initialState = {
  query: '',
  results: [],
  count: 0,
  fetchRequested: Date.now(),
  lastFetched: null,
  isFetching: false
};

export default createReducer(
  {
    [clearSubscriberSearchQuery]: state => ({
      ...state,
      query: '',
      results: [],
      isFetching: false
    }),
    [fetchingSubscriberSearchResults]: (state, { query }) => ({
      ...state,
      query,
      isFetching: true,
      fetchRequested: Date.now()
    }),
    // clear search input and results, but don't change display preferences
    [clearSubscriberSearch]: state => ({
      ...state,
      query: initialState.query,
      results: initialState.results,
      count: initialState.count,
      isFetching: false
    }),
    [setSubscriberSearchResults]: (state, { results }) => ({
      ...state,
      results,
      count: results.length,
      lastFetched: Date.now(),
      isFetching: false
    }),
    [removeFromSubscriberSearchResults]: (state, { id }) => ({
      ...state,
      results: state.results.filter(artist => artist.objectID !== id),
      count: state.count - 1,
      isFetching: false
    })
  },
  initialState
);
