/**
 * In order to leverage Algolia's code for highlighting snippets returned from their API, we had to borrow
 * their code from:
 * https://github.com/algolia/react-instantsearch/blob/master/packages/react-instantsearch-core/src/core/highlight.js
 *
 * This code is not exposed in the algolia npm packages since it appears they assume you'll use their react library's
 * components which we currently aren't doing. If we modify our code to use the react package, then we can remove this
 * file. We were attempting to write our own parser, but it was problematic in it's own ways.
 */

/**
 * Retrieve the value at a path of the object:
 *
 * @example
 * getPropertyByPath(
 *   { test: { this: { function: [{ now: { everyone: true } }] } } },
 *   'test.this.function[0].now.everyone'
 * ); // true
 *
 * getPropertyByPath(
 *   { test: { this: { function: [{ now: { everyone: true } }] } } },
 *   ['test', 'this', 'function', 0, 'now', 'everyone']
 * ); // true
 *
 * @param object Source object to query
 * @param path either an array of properties, or a string form of the properties, separated by .
 */
export const getPropertyByPath = (object, path) =>
  (Array.isArray(path) ? path : path.replace(/\[(\d+)]/g, '.$1').split('.')).reduce(
    (current, key) => (current ? current[key] : undefined),
    object
  );

export const HIGHLIGHT_TAGS = {
  highlightPreTag: `<ais-highlight-0000000000>`,
  highlightPostTag: `</ais-highlight-0000000000>`
};

/**
 * Parses an highlighted attribute into an array of objects with the string value, and
 * a boolean that indicated if this part is highlighted.
 *
 * @param {string} preTag - string used to identify the start of an highlighted value
 * @param {string} postTag - string used to identify the end of an highlighted value
 * @param {string} highlightedValue - highlighted attribute as returned by Algolia highlight feature
 * @return {object[]} - An array of {value: string, isHighlighted: boolean}.
 */
export function parseHighlightedAttribute({
  preTag = '<em>',
  postTag = '</em>',
  highlightedValue = ''
}) {
  const splitByPreTag = highlightedValue.split(preTag);
  const firstValue = splitByPreTag.shift();
  const elements = firstValue === '' ? [] : [{ value: firstValue, isHighlighted: false }];

  if (postTag === preTag) {
    let isHighlighted = true;
    splitByPreTag.forEach(split => {
      elements.push({ value: split, isHighlighted });
      isHighlighted = !isHighlighted;
    });
  } else {
    splitByPreTag.forEach(split => {
      const splitByPostTag = split.split(postTag);

      elements.push({
        value: splitByPostTag[0],
        isHighlighted: true
      });

      if (splitByPostTag[1] !== '') {
        elements.push({
          value: splitByPostTag[1],
          isHighlighted: false
        });
      }
    });
  }

  return elements;
}

/**
 * Find an highlighted attribute given an `attribute` and an `highlightProperty`, parses it,
 * and provided an array of objects with the string value and a boolean if this
 * value is highlighted.
 *
 * In order to use this feature, highlight must be activated in the configuration of
 * the index. The `preTag` and `postTag` attributes are respectively highlightPreTag and
 * highlightPostTag in Algolia configuration.
 *
 * NOTE: By default, algolia wrapps matched/highlighted content in <em></em> tags. You can override in
 * calls to aloglia, in which case you'd pass the updated pre/post tag to parseAlgoliaHit
 *
 * @param {string} preTag - string used to identify the start of an highlighted value
 * @param {string} postTag - string used to identify the end of an highlighted value
 * @param {string} highlightProperty - the property that contains the highlight structure in the results
 * @param {string} attribute - the highlighted attribute to look for
 * @param {object} hit - the actual hit returned by Algolia.
 * @return {object[]} - An array of {value: string, isHighlighted: boolean}.
 */
export function parseAlgoliaHit({
  preTag = '<em>',
  postTag = '</em>',
  highlightProperty = '_highlightResult',
  attribute,
  hit
}) {
  if (!hit) throw new Error('`hit`, the matching record, must be provided');

  const highlightObject = getPropertyByPath(hit[highlightProperty], attribute) || {};

  if (Array.isArray(highlightObject)) {
    return highlightObject.map(item =>
      parseHighlightedAttribute({
        preTag,
        postTag,
        highlightedValue: item.value
      })
    );
  }

  return parseHighlightedAttribute({
    preTag,
    postTag,
    highlightedValue: highlightObject.value
  });
}
