import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';

const NetworkAuthSuccess = () => {
  const { network } = useParams();
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const error = queryParams.get('error');

  useEffect(() => {
    window.opener.postMessage({ network, error }, '*');
    window.close();
  }, [network, error]);

  return <p>This popup should automatically close.</p>;
};

NetworkAuthSuccess.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      network: PropTypes.string.isRequired
    })
  }),
  location: PropTypes.shape({
    search: PropTypes.string
  })
};

export default NetworkAuthSuccess;
