import React, { ReactNode, CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import { Config, usePopperTooltip } from 'react-popper-tooltip';

import 'react-popper-tooltip/dist/styles.css';

import Icon from 'components/Icon';
import {
  StyledIcon,
  TooltipArrow,
  TooltipContainer,
  Underline,
  TriggerContainer
} from './styled';

interface TooltipProps extends Partial<Config> {
  children?: ReactNode | string;
  content?: ReactNode;
  enabled?: boolean;
  forceVisible?: boolean;
  hideArrow?: boolean;
  showUnderline?: boolean;
  showInfoIcon?: boolean;
  className?: string;
  style?: CSSProperties;
  triggerContainerElement?: keyof JSX.IntrinsicElements;
  interactive?: boolean;
  offset?: [number, number];
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  enabled = true,
  hideArrow,
  showUnderline,
  showInfoIcon,
  className,
  style,
  triggerContainerElement = 'span',
  forceVisible,
  offset = [0, 8],
  ...popperTooltipConfig
}) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      offset,
      ...popperTooltipConfig
    });

  if (!enabled) {
    return (
      <TriggerContainer className={className} as={triggerContainerElement} style={style}>
        {children}
      </TriggerContainer>
    );
  }

  const TooltipTriggerContent = showUnderline ? (
    <Underline>{children}</Underline>
  ) : (
    children
  );

  return (
    <>
      <TriggerContainer
        ref={setTriggerRef}
        className={className}
        as={triggerContainerElement}
        style={style}
      >
        {TooltipTriggerContent}
        {showInfoIcon && <StyledIcon type={Icon.TYPES.INFO} width={14} />}
      </TriggerContainer>

      {(visible || forceVisible) &&
        ReactDOM.createPortal(
          <TooltipContainer
            data-testid={'Tooltip-content'}
            ref={setTooltipRef}
            {...getTooltipProps()}
          >
            {!hideArrow && <TooltipArrow {...getArrowProps()} />}
            {content}
          </TooltipContainer>,
          document.body
        )}
    </>
  );
};

export default Tooltip;
