import { createAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import queryString from 'query-string';

import * as api from 'api';
import history from 'config/history';
import { AppDispatch } from 'config/store';
import { setAuthCookies } from 'helpers';
import { ProtonJwt } from 'types';
import { logoutUser } from './user';

interface TokenParams {
  userId: number;
  jwt: string;
  refreshToken?: string;
  isImpersonating?: boolean;
}

// set token in redux store
export const setToken = createAction(
  'TOKEN_SET',
  ({ userId, jwt, refreshToken, isImpersonating }: TokenParams) => ({
    payload: {
      userId,
      jwt,
      refreshToken,
      isImpersonating
    }
  })
);

export const clearToken = createAction('TOKEN_CLEAR');

export const formatTokenState = (jwt: string, refreshToken: string) => {
  const { user_id: userId } = jwtDecode<ProtonJwt>(jwt);
  return { userId, jwt, refreshToken };
};

export const tokenHandler =
  (dispatch: AppDispatch) =>
  ({ jwt, refresh_token }: { jwt: string; refresh_token: string }) => {
    const tokenPayload = formatTokenState(jwt, refresh_token);
    setAuthCookies(tokenPayload.jwt, tokenPayload.refreshToken);
    dispatch(setToken(tokenPayload));
    return tokenPayload;
  };

interface GetTokenParams {
  protonid: string;
  user_password: string;
  claim_token?: string;
  recaptcha_token?: string;
}

// redux thunk wraps actions to provide dispatch
export const getToken =
  ({ protonid, user_password, claim_token, recaptcha_token }: GetTokenParams) =>
  (dispatch: AppDispatch) =>
    api.getToken(protonid, user_password, claim_token, recaptcha_token).then(response => {
      if (
        typeof response === 'object' &&
        'force_reset_password' in response &&
        response.force_reset_password
      ) {
        const { dest } = queryString.parse(history.location.search);

        if (dest) {
          history.push(
            `/reset-password?token=${
              response.reset_password_token
            }&force_reset=true&dest=${encodeURIComponent(dest as string)}`
          );
        } else {
          history.push(
            `/reset-password?token=${response.reset_password_token}&force_reset=true`
          );
        }

        return {};
      }

      if ('jwt' in response && 'refresh_token' in response) {
        return tokenHandler(dispatch)(response);
      }

      console.error('Unexpected response type:', response);
      return {};
    });

export const refreshToken = () => (dispatch: AppDispatch) =>
  api
    .refreshToken()
    .then(tokenHandler(dispatch))
    .catch(() => dispatch(logoutUser()));
