import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { MEDIUM_BREAK_POINT } from 'config/constants';
// Component to wrap grouping NavTab

const StyledTitle = styled.div.attrs(() => ({
  'data-testid': 'NavTabGroup-title'
}))`
  display: ${({ $showTitle }) => ($showTitle ? 'block' : 'none')};
  font-size: 0.9rem;
  font-weight: 500;
  height: 0.9rem;
  letter-spacing: 0.25em;
  margin-bottom: 0.3rem;
  opacity: 0.75;
  text-transform: uppercase;

  @media screen and (max-width: ${MEDIUM_BREAK_POINT}) {
    text-align: left;
  }
`;

const StyledNavTabGroup = styled.div`
  display: inline-block;
  flex: 0 0 auto;

  /* Adds spacing and left border when two NavTabGroups are used in a row */
  & + & {
    border-left: 1px solid rgba(255, 255, 255, 0.4);
    margin-left: 2rem;
    padding-left: 2rem;
  }

  &:last-child {
    padding-right: 1rem;
  }
`;

const NavTabGroup = ({ title, showTitle = true, children }) => (
  <StyledNavTabGroup data-testid={`NavTabGroup-${_.kebabCase(title)}`}>
    <StyledTitle $showTitle={showTitle}>{title}</StyledTitle>
    <div data-testid="NavTabGroup-tabs">{children}</div>
  </StyledNavTabGroup>
);

NavTabGroup.propTypes = {
  title: PropTypes.string,
  showTitle: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired
};

export default NavTabGroup;
