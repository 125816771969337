export default {
  image_url: '',
  bio: null,
  has_image: false,
  id: null,
  name: '',
  slug: '',
  members: [],
  releases_on: []
};
