import { COLORS } from './constants';

const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

export const updateIntercomPosition = (
  isSmallScreen,
  isPlayerActive,
  isScrolledToBottom
) => {
  const basePadding = isSmallScreen ? (isPlayerActive ? 95 : 45) : 70;
  const padding = isScrolledToBottom ? basePadding + 50 : basePadding;
  window.Intercom('update', { vertical_padding: padding });
};

export const initializeIntercom = (user = {}, initialPadding = 70) => {
  window.Intercom('boot', {
    app_id: APP_ID,
    background_color: COLORS.PRIMARY,
    action_color: COLORS.PRIMARY,
    vertical_padding: initialPadding, // modified in updateIntercomPosition
    email: user.email,
    user_id: user.user_id,
    name: `${user.first_name} ${user.last_name}`,
    hide_default_launcher: true
  });
};

// NOTE: This probably doesn't do what you think. It clears all of Intercom's
// localStorage data and is meant to be called only when a user logs out.
export const shutdownIntercom = () => window.Intercom('shutdown');

export const hideIntercomLauncher = () =>
  window.Intercom('update', {
    hide_default_launcher: true
  });

export const showIntercomLauncher = () =>
  window.Intercom('update', {
    hide_default_launcher: false
  });

export const showIntercom = () => window.Intercom('show');

// The following code includes the intercom library in the client
// REF: developers.intercom.com/installing-intercom/docs/basic-javascript#section-step-1-include-intercom-js-library
(function () {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/' + APP_ID;
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
})();
