import { SearchResponse } from '@algolia/client-search';

import { ALGOLIA, RELEASE_CONFIGS } from 'config/constants';
import { consoleDev } from 'helpers/utilities';

import { searchAlgolia } from './algolia';
import { algoliaApi, protonApi } from './rtkSetup';
import { AlgoliaRelease, V2Release } from 'types';

export const getReleaseAlgolia = async (releaseId: number | string) => {
  const response = await searchAlgolia<AlgoliaRelease>({
    index: ALGOLIA.RELEASE_INDEX,
    facetFilters: [[`objectID:${releaseId}`]]
  });

  // NOTE: our API would typically 404 on missing entities. We will simulate the same when looking
  // for a specific entity in algolia
  if (response.hits.length === 0) {
    const error = { message: `Could not find release id ${releaseId}`, status: 404 };
    throw error;
  }

  return response.hits[0];
};

/*
 * [getReleasesAlgolia]
 *
 * @param {string} id
 * @param {number} excludeId
 * @returns {object}
 */

type LabelReleasesSearch = {
  labelId: number;
  limit?: number;
  excludeId?: string;
};

export const getLabelReleasesAlgolia = async ({
  labelId,
  limit,
  excludeId
}: LabelReleasesSearch) => {
  const response = await searchAlgolia<AlgoliaRelease>({
    index: ALGOLIA.RELEASE_INDEX,
    facetFilters: [[`label.id:${labelId}`]],
    ...(excludeId ? { filters: `id!=${excludeId}` } : {}),
    limit
  });
  return response.hits;
};

export const releasesAlgoliaApi = algoliaApi.injectEndpoints({
  endpoints: build => ({
    getReleaseAlgolia: build.query<AlgoliaRelease, string>({
      query: releaseId => ({
        index: ALGOLIA.RELEASE_INDEX,
        facetFilters: [[`objectID:${releaseId}`]],
        errorOnEmpty: true
      }),
      transformResponse: (response: SearchResponse<AlgoliaRelease>) => response.hits[0]
    }),
    getReleasesByLabelIdAlgolia: build.query<AlgoliaRelease[], LabelReleasesSearch>({
      query: ({ labelId, excludeId, limit }) => ({
        index: ALGOLIA.RELEASE_INDEX,
        facetFilters: [[`label.id:${labelId}`]],
        ...(excludeId ? { filters: `id!=${excludeId}` } : {}),
        limit
      }),
      transformResponse: (response: SearchResponse<AlgoliaRelease>) => response.hits
    })
  }),
  overrideExisting: false
});

export const releasesApi = protonApi.injectEndpoints({
  endpoints: build => ({
    getRelease: build.query<V2Release, string | number>({
      query: id => ({
        url: `releases/${id}`,
        method: 'get'
      }),
      transformResponse: (response: { data: V2Release }) => response.data
    }),
    createReleaseConfig: build.mutation({
      query: ({ id, ...params }) => ({
        url: `releases/${id}/config`,
        method: 'post',
        body: params
      }),
      transformResponse: (response: { data: V2Release }) => response.data,
      async onQueryStarted(
        { config_key, config_value }: { config_key?: string; config_value?: boolean },
        { dispatch, queryFulfilled }
      ) {
        if (config_key === RELEASE_CONFIGS.CREATE_NEW_SPOTIFY_PROFILE) {
          // when a release config is created to generate a new spotify profile upon release delivery,
          // a flag is created for reference in the artistProfile.  Let's update it here so we don't need
          // to refetch and wait on that data.
          try {
            await queryFulfilled;

            const updateArtistProfileAction = protonApi.util.updateQueryData(
              // @ts-expect-error protonApi is being inferred as having no endpoints because of how it is initialized (I think)
              'getArtistProfile',
              config_value,
              doc => {
                if (doc) {
                  Object.assign(doc, { spotify_profile_creation_pending: true });
                }
              }
            );
            dispatch(updateArtistProfileAction);
          } catch (err) {
            consoleDev('Error updating ArtistProfile:', err);
            throw err;
          }
        }
      }
    })
  })
});

export const { useGetReleaseAlgoliaQuery, useGetReleasesByLabelIdAlgoliaQuery } =
  releasesAlgoliaApi;

export const { useGetReleaseQuery, useCreateReleaseConfigMutation } = releasesApi;
