import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Redirect, useLocation } from 'react-router-dom';

import { resetStore } from 'redux/actions/utilities';
import { store } from 'config/store';
import history from 'config/history';

import Button, { ButtonGroup } from 'components/Button';
import TextHeader from 'components/TextHeader';
import backgroundImage from 'images/error@2x.jpg';
import { shutdownIntercom } from 'config/intercom';

const Container = styled.div.attrs(() => ({
  className: 'container-fluid'
}))`
  margin-top: -5.4rem;
  margin-bottom: -6rem;
`;

const ErrorImage = styled.div`
  background-image: url(${backgroundImage});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vh;
  width: 100%;
`;

const clearStore = () => {
  store.dispatch(resetStore());

  reloadPage('/');
};

const reloadPage = (route?: string) => {
  if (route) {
    window.location.href = route;
  } else {
    window.location.reload();
  }
};

const navigate = (route: string) => {
  history.push(route);
};

const renderMessage = (propMessage: string | (() => ReactNode)): ReactNode => {
  if (typeof propMessage === 'function') return propMessage();
  return propMessage;
};

type ErrorCode = '401' | '404' | '500';
type ActionProp = {
  text: string;
  onClick: () => void;
  buttonType?: string;
};

type ErrorProp = {
  code?: ErrorCode;
  title: string;
  message: string | (() => ReactNode);
  actions: ActionProp[];
  testId?: string;
};

const ErrorPage = ({ error, onError }: { error: ErrorProp; onError?: () => void }) => {
  const { code, title, message, actions, testId } = error || ERROR_PAGE_STATUS.NOT_FOUND;
  shutdownIntercom();

  return (
    <Container data-testid={testId}>
      <div className="row d-flex align-items-center" style={{ minHeight: '100vh' }}>
        <div className="hidden-xs col-sm-5 col-md-4 col-lg-3">
          <ErrorImage />
        </div>
        <div className="col-sm-7 col-md-8 col-lg-9">
          <TextHeader
            type={TextHeader.TYPES.JUMBO}
            weight={TextHeader.WEIGHTS.LIGHT}
            className="mb-2"
          >
            <strong>{code || ''}</strong> {title}
          </TextHeader>

          <div className="row">
            <div className="col-sm-12 col-md-8 col-lg-7">
              <TextHeader
                type={TextHeader.TYPES.MEDIUM}
                weight={TextHeader.WEIGHTS.LIGHT}
              >
                {renderMessage(message)}
              </TextHeader>

              {actions && (
                <ButtonGroup>
                  {actions.map(action => (
                    <Button
                      color={action.buttonType || Button.COLORS.PRIMARY}
                      size={Button.SIZES.MEDIUM}
                      className="mt-3"
                      onClick={() => {
                        if (onError) {
                          onError();
                        }
                        action.onClick();
                      }}
                      key={`ErrorPageButton:${action.text}`}
                    >
                      {action.text}
                    </Button>
                  ))}
                </ButtonGroup>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

type ErrorPageVariant =
  | 'UNHANDLED_EXCEPTION'
  | 'LOAD_ERROR'
  | 'UNAUTHORIZED'
  | 'NOT_FOUND';

export const ERROR_PAGE_STATUS: { [K in ErrorPageVariant]: ErrorProp } = {
  UNHANDLED_EXCEPTION: {
    code: '500',
    title: 'Error!',
    testId: 'unhandled-exception',
    message: () => (
      <div>
        It looks like this page can&apos;t be found or there&apos;s been an application
        error. If refreshing the page doesn&apos;t resolve the issue, please email{' '}
        <a href="mailto:support@protonradio.com">support@protonradio.com</a> with your
        username, the URL, and any details leading up to seeing this page.
      </div>
    ),
    actions: [
      {
        text: 'Reload Page',
        onClick: () => reloadPage()
      },
      {
        text: 'Sign Out & Clear Cache',
        onClick: () => clearStore(),
        buttonType: Button.COLORS.NEUTRAL
      }
    ]
  },
  LOAD_ERROR: {
    title: 'Load Error!',
    testId: 'load-error',
    message: () => (
      <div>
        It looks like there&apos;s been a problem loading this page. Click the{' '}
        <strong>Reload</strong> button below to try again. If this doesn&apos;t resolve
        the issue, please email{' '}
        <a href="mailto:support@protonradio.com">support@protonradio.com</a> with your
        username, the URL, and any details leading up to seeing this page.
      </div>
    ),
    actions: [
      {
        text: 'Reload',
        onClick: () => reloadPage()
      }
    ]
  },
  UNAUTHORIZED: {
    code: '401',
    title: 'Unauthorized',
    testId: 'unauthorized',
    message: () => (
      <div>
        It looks like you don&apos;t have access to this page. If you are confident you
        are logged in as the correct user and believe there has been a mistake, please
        email <a href="mailto:support@protonradio.com">support@protonradio.com</a> with
        your username, the URL, and any details leading up to seeing this page.
      </div>
    ),
    actions: [
      {
        text: 'Go to Homepage',
        onClick: () => navigate('/')
      }
    ]
  },
  NOT_FOUND: {
    code: '404',
    title: 'Not Found',
    testId: 'not-found',
    message: () => (
      <>
        It looks like the page you&apos;re looking for doesn&apos;t exist! If you believe
        this is an error, please email{' '}
        <a href="mailto:support@protonradio.com">support@protonradio.com</a> with the URL
        you&apos;re trying to access and any relevant details.
      </>
    ),
    actions: [
      {
        text: 'Go to Homepage',
        onClick: () => navigate('/')
      }
    ]
  }
};

export const getErrorPropsForCode = (code: string | number | undefined) => {
  const stringCode = code ? code.toString() : '';

  switch (stringCode) {
    case '401':
      return ERROR_PAGE_STATUS.UNAUTHORIZED;
    case '404':
      return ERROR_PAGE_STATUS.NOT_FOUND;
    default:
      return ERROR_PAGE_STATUS.UNHANDLED_EXCEPTION;
  }
};

ErrorPage.STATUS = ERROR_PAGE_STATUS;
ErrorPage.SELECTORS = {
  NOT_FOUND: '[data-testid=not-found]',
  UNAUTHORIZED: '[data-testid=unauthorized]',
  UNHANDLED_EXCEPTION: '[data-testid=unhandled-exception]'
};

export const RouteNotFound = () => {
  // NOTE: We want to preserve the url, so we spread the same current location data, but force the notFound state
  const location = useLocation();
  return <Redirect to={{ ...location, state: { notFound: true } }} />;
};

export default ErrorPage;
