import axios from 'axios';
import qs from 'query-string';

import { API_BASE_V2 } from 'config/constants';
import {
  AccountNotificationType,
  RequireKey,
  V2ProUser,
  V2User,
  V2UserSetting
} from 'types';
import { protonApi } from './rtkSetup';

/**
 * if searching for user that isn't the current user on the client,
 * you can pass a valid jwt to look them up. Used when referencing SS jwt cookies
 */

export const getUser = async (userId: number, jwt?: string) => {
  if (!userId) {
    throw 'Parameter `userId` is not defined';
  }

  const config = jwt
    ? {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    : {};

  const {
    data: { data: response }
  } = await axios.get<{ data: V2User }>(`${API_BASE_V2}/users/${userId}`, config);

  return {
    profiles: [],
    ...response
  };
};

/**
 * API call to update properties of a user - requires `user_id` in passed user object, all other properties optional
 */
export const updateUser = async (user: RequireKey<Partial<V2User>, 'user_id'>) => {
  const {
    data: { data: response }
  } = await axios.patch<{ data: V2User }>(`${API_BASE_V2}/users/${user.user_id}`, user);
  return response;
};

/**
 * API call to update one `user_settings` setting on the user object
 */

export const updateUserSettings = async (setting: V2UserSetting) => {
  const {
    data: { data: response }
  } = await axios.patch<{ data: V2UserSetting[] }>(`${API_BASE_V2}/users/settings`, {
    settings: [setting]
  });
  return response;
};

// ****** AUTHENTICATION / ACCOUNT CREATION / ACCOUNT CONFIRMATION ******
export interface ChangePasswordInput {
  current_password: string;
  new_password: string;
  new_password_confirm: string;
}

interface PasswordUpdateResponse {
  status: string;
  jwt: string;
  refresh_token: string;
}

type ChangePasswordResponse = PasswordUpdateResponse & { status: 'Password changed.' };

export const changePassword = (values: ChangePasswordInput) =>
  axios.patch<ChangePasswordResponse>(`${API_BASE_V2}/users/password`, {
    current_password: values.current_password,
    password: values.new_password,
    password_confirmation: values.new_password_confirm
  });

export interface ResetPasswordInput {
  password: string;
  password_confirm: string;
  token: string;
}

type ResetPasswordResponse = PasswordUpdateResponse & { status: 'Password reset.' };

export const resetPassword = (payload: ResetPasswordInput) =>
  axios.put<ResetPasswordResponse>(`${API_BASE_V2}/users/password/new`, payload);

/**
 * Sends user an email with a link to go to the reset-password page. Input `value` can be an email address or username.
 */
export const requestPassword = (value: string) =>
  axios.post(`${API_BASE_V2}/users/password/new`, { email_or_proton_id: value });

/**
 * Sends user an email with their username
 */
export const requestUsername = (email: string) =>
  axios.post(`${API_BASE_V2}/users/request_protonid`, { email });

/**
 * Request sent during account creation process from confirmation page linked in email sent to new user
 */
interface ConfirmUserResponse {
  status: string;
  user: V2User;
  jwt: string;
  refresh_token: string;
}

export const confirmUser = (payload: { token: string; proton_id: string }) =>
  axios.post<ConfirmUserResponse>(`${API_BASE_V2}/users/confirm`, payload);

export const confirmUserResend = (protonid: string) =>
  axios.get(`${API_BASE_V2}/users/confirmations/new`, {
    params: { proton_id: protonid }
  });

export interface BasicUserInfo {
  username: string;
  password: string;
  email: string;
}

export const registerUser = async (payload: BasicUserInfo) => {
  const {
    data: { data: response }
  } = await axios.post<{ data: { notice: string } }>(`${API_BASE_V2}/users`, payload);
  return response;
};

type Frequency = 'instant' | 'daily' | 'weekly' | null;

// ****** NOTIFICATION PREFERENCES ******
export const updateNotifications = async (
  notificationName: AccountNotificationType,
  value: boolean,
  frequency: Frequency = null
) => {
  const { data } = await axios.patch<{ data: V2User }>(
    `${API_BASE_V2}/users/notifications`,
    {
      name: notificationName,
      toggle: value,
      frequency
    }
  );
  return data;
};

// ****** DSP CONNECTIONS ******
export const disconnectSoundCloud = async (authId: number) => {
  const { data } = await axios.post<{
    status: 'ok' | 'label_connected' | 'artist_connected';
  }>(`${API_BASE_V2}/users/disconnect_soundcloud`, {
    soundcloud_auth_id: authId
  });
  return data;
};

export const disconnectSpotify = () =>
  axios.post(`${API_BASE_V2}/users/disconnect_spotify`);

// ****** ACCOUNT DELETION ******
export const requestAccountDeletion = async (userId: number) => {
  const { data } = await axios.post<{ success: boolean; message: string }>(
    `${API_BASE_V2}/users/${userId}/request_deletion`
  );
  return data;
};

export const confirmAccountDeletion = async (userId: number, delete_token: string) =>
  axios.delete<{ success: boolean; delete_at: string; message: string }>(
    `${API_BASE_V2}/users/${userId}`,
    {
      params: { delete_token }
    }
  );

export const cancelAccountDeletion = (userId: number) =>
  axios.post<{ success: boolean; message: string }>(
    `${API_BASE_V2}/users/${userId}/cancel_deletion`
  );

// ****** ACCOUNT MASQUERADE ******
export const impersonateUser = (impersonateUserId: number) =>
  axios.post<{ impersonate_jwt: string; impersonate_refresh_token: string }>(
    `${API_BASE_V2}/users/${impersonateUserId}/impersonate`
  );

export const endImpersonateUser = impersonateUserId =>
  axios.post<{ jwt: string; refresh_token: string }>(
    `${API_BASE_V2}/users/${impersonateUserId}/unimpersonate`
  );

export interface UnverifiedProfileId {
  artist_id: string;
  artist_name: string;
  can_be_verified: boolean;
}

interface QueryParams {
  pro_email?: string;
  first_name?: string;
  last_name?: string;
  country?: string;
  key?: unknown;
}

export const userApi = protonApi.injectEndpoints({
  endpoints: build => ({
    searchUsers: build.query<V2ProUser[], QueryParams>({
      query: ({ pro_email, first_name, last_name, country }) => ({
        url: `users?${qs.stringify({
          email: pro_email,
          first_name: first_name,
          last_name: last_name,
          country: country
        })}`,
        method: 'get',
        keepUnusedDataFor: 5
      }),
      transformResponse: (response: { data: V2ProUser[] }) => response.data
    }),
    getSpotifyVerifiableArtists: build.query<UnverifiedProfileId[], number>({
      query: userId => ({
        url: `users/${userId}/spotify-verifiable-artists`,
        method: 'get'
      }),
      transformResponse: (response: { data: UnverifiedProfileId[] }) => response.data
    })
  }),
  overrideExisting: false
});

export const { useGetSpotifyVerifiableArtistsQuery, useSearchUsersQuery } = userApi;
