import { DOWNLOAD_LOCATIONS } from 'config/constants';
import { User } from 'hooks/useCurrentUser';
import { DropboxOAuth } from 'types/apiv2';

interface DownloadOptionsProps {
  dropbox_oauth?: DropboxOAuth | null;
  google_oauth: boolean;
}

export type DownloadLocationOption = { value?: string; label: string; key?: string };

/**
 * getDownloadLocationOptions - Helper that returns array of current download options for display in UI.
 */
export const getDownloadLocationOptions = ({
  dropbox_oauth,
  google_oauth
}: DownloadOptionsProps): DownloadLocationOption[] => {
  const GOOGLE_OPTION = google_oauth
    ? {
        value: DOWNLOAD_LOCATIONS.GOOGLE.VALUE,
        label: DOWNLOAD_LOCATIONS.GOOGLE.LABEL
      }
    : {
        label: `Connect ${DOWNLOAD_LOCATIONS.GOOGLE.LABEL}`,
        key: 'must-connect-google-account'
      };

  const DROPBOX_OPTION = dropbox_oauth
    ? {
        value: DOWNLOAD_LOCATIONS.DROPBOX.VALUE,
        label: DOWNLOAD_LOCATIONS.DROPBOX.LABEL
      }
    : {
        label: `Connect ${DOWNLOAD_LOCATIONS.DROPBOX.LABEL}`,
        key: 'must-connect-dropbox-account'
      };

  return [
    {
      value: DOWNLOAD_LOCATIONS.LOCAL.VALUE,
      label: DOWNLOAD_LOCATIONS.LOCAL.LABEL
    },
    GOOGLE_OPTION,
    DROPBOX_OPTION
  ];
};

/**
 * getCurrentDownloadLocation - Helper that returns the current download location setting
 *
 * @param {object} - user_settings
 * @param {object} - google_oauth
 * @param {object} - dropdox_oauth
 */

export const getCurrentDownloadLocationOption = (
  user: User,
  { google_oauth, dropbox_oauth }: DownloadOptionsProps
): DownloadLocationOption => {
  const currentLocationSetting = user.settings['DOWNLOAD_LOCATION'];
  const downloadLocationOptions = getDownloadLocationOptions({
    google_oauth,
    dropbox_oauth
  });

  return (
    downloadLocationOptions.find(
      option => option.value === currentLocationSetting.value
    ) || downloadLocationOptions[0]
  ); // If all else fails default to index 0 aka Direct Download
};
