import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Spring } from 'react-spring/renderprops';
import { connect } from 'react-redux';

import { impersonateUser as impersonateUserAction } from 'redux/actions/user';
import { toggleSearchResultsDisplay as toggleSearchResultsDisplayAction } from 'redux/actions/search';
import history from 'config/history';

import { isMobile } from 'helpers/screen';
import Screen from 'components/Screen';
import ActionMenu, {
  ActionMenuList,
  ActionMenuHeader,
  UserHeader
} from 'components/ActionMenu';
import Button, { ButtonGroup } from 'components/Button';
import { UserSummaryTable } from 'components/Entities/Users';

import { highlightMatchedField } from '../helpers';

class UserSearchResult extends Component {
  constructor(props) {
    super(props);
    this.actionContainer = React.createRef();
  }

  state = {
    right: -999,
    isMenuOpen: false
  };

  getSSUrl = proUserId =>
    `https://soundsystem.protonradio.com/main.php?tab=accounts&pro_user_id=${proUserId}#artists-tab`;

  masqueradeUser = () => {
    const { impersonateUser, user_id } = this.props;

    return impersonateUser(user_id)
      .then(() => history.push('/account'))
      .catch(e => {
        console.warn(`Error masquerading userId ${user_id}`, e);
      });
  };

  _showActions = () => this.setState({ right: 0, showActions: true });

  _hideActions = () => {
    const width = this.actionContainer.current.clientWidth;
    this.setState({ right: -width, showActions: false });
  };

  _toggleActionSlider = e => {
    // Don't fire if clicking within action container
    if (this.actionContainer.current.contains(e.target)) return;

    if (this.state.showActions) {
      this._hideActions();
    } else {
      this._showActions();
    }
  };

  _openMenu = () => this.setState({ isMenuOpen: true });

  _closeMenu = () => this.setState({ isMenuOpen: false });

  _handleRowClick = e => {
    if (isMobile()) {
      this._openMenu();
    } else {
      this._toggleActionSlider(e);
    }
  };

  render() {
    const { user_id, pro_user_id, dropdownDisplay, toggleResultsDisplay } = this.props;
    const { isMenuOpen } = this.state;

    return (
      <>
        <ActionMenu isOpen={isMenuOpen} close={this._closeMenu}>
          <ActionMenuHeader>
            <UserHeader {...this.props} />
          </ActionMenuHeader>

          <ActionMenuList
            actions={[
              {
                name: 'Masquerade',
                icon: 'icon-mask.svg',
                onClick: () =>
                  this.masqueradeUser(user_id).then(() => toggleResultsDisplay(false)),
                disabled: !user_id
              },
              {
                name: 'SoundSystem',
                icon: 'icon-link.svg',
                to: this.getSSUrl(pro_user_id),
                newTab: true
              }
            ]}
            closeMenu={this._closeMenu}
          />
        </ActionMenu>

        <div
          className="UniversalSearchResultRow UserSearchResult"
          data-testid={UserSearchResult.TEST_IDS.ROW}
          role="button"
          tabIndex="0"
          onClick={this._handleRowClick}
        >
          <div className="UniversalSearchResultRow__info">
            <div className="UserSearchResult__user__section">
              <div className="UniversalSearchResultRow__info__title">
                {/* eslint-disable react/no-danger */}
                <span
                  dangerouslySetInnerHTML={{
                    __html: highlightMatchedField('name', this.props)
                  }}
                />
              </div>
              <div className="UniversalSearchResultRow__info__subtitle">
                @
                <span
                  dangerouslySetInnerHTML={{
                    __html: highlightMatchedField('username', this.props)
                  }}
                />
              </div>
            </div>
          </div>

          <Screen.SMALL up>
            {!dropdownDisplay && <UserSummaryTable {...this.props} />}

            <Spring to={{ right: this.state.right }}>
              {styleProps => (
                <div
                  className="UserSearchResult__actions"
                  style={styleProps}
                  ref={this.actionContainer}
                >
                  <ButtonGroup>
                    <Button
                      color={Button.COLORS.WARNING}
                      data-testid={UserSearchResult.TEST_IDS.MASQUERADE_BUTTON}
                      disabled={!user_id}
                      onClick={() => this.masqueradeUser(user_id)}
                    >
                      Masquerade
                    </Button>
                    <Button
                      color={Button.COLORS.NEUTRAL}
                      to={this.getSSUrl(pro_user_id)}
                      newTab
                    >
                      SoundSystem
                    </Button>
                  </ButtonGroup>
                </div>
              )}
            </Spring>
          </Screen.SMALL>
        </div>
      </>
    );
  }
}

UserSearchResult.propTypes = {
  name: PropTypes.string,
  pro_user_id: PropTypes.number,
  user_id: PropTypes.number,
  username: PropTypes.string,
  // redux connect
  dropdownDisplay: PropTypes.bool,
  impersonateUser: PropTypes.func,
  toggleResultsDisplay: PropTypes.func
};

UserSearchResult.TEST_IDS = {
  ROW: 'UniversalSearchResultRow-pro-user',
  MASQUERADE_BUTTON: 'masquerade-user-button'
};

export default connect(
  state => ({
    dropdownDisplay: state.search.displayResults
  }),
  {
    // consider moving out of these to UniversalSearchResults component if unperformant
    impersonateUser: impersonateUserAction,
    toggleResultsDisplay: toggleSearchResultsDisplayAction
  }
)(UserSearchResult);
