import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';

import {
  fetchAcknowledgementsActions,
  createAcknowledgementActions
} from 'redux/actions/acknowledgements';
import { clearUser } from 'redux/actions/user';

import {
  fetchInitialState,
  handleArrayToByIdSuccess,
  handleFetchError,
  handleFetchRequest,
  handleFetchSuccess
} from './utilities';

const byId = createReducer(
  {
    [fetchAcknowledgementsActions.success]: handleArrayToByIdSuccess,
    [createAcknowledgementActions.success]: handleFetchSuccess,
    [clearUser]: () => ({})
  },
  {}
);

// all acks for current user
const all = createReducer(
  {
    [createAcknowledgementActions.success]: (state, { id }) => ({
      ...state,
      data: [...state.data, id]
    }),
    [fetchAcknowledgementsActions.request]: handleFetchRequest,
    [fetchAcknowledgementsActions.success]: handleFetchSuccess,
    [fetchAcknowledgementsActions.error]: handleFetchError,
    [clearUser]: () => fetchInitialState
  },
  fetchInitialState
);

export default combineReducers({
  byId,
  all
});

// *** EXAMPLE REDUCER: ***
// {
//   acknowledgements: {
//     byId: {
//       123: {
//         id: 1082,
//         message_type: "dj_mix_intro_confirmation",
//         acknowledged_at: "2021-03-17T20:18:12.000Z",
//         phpbb_user_id: 303222
//       }
//     },
//     all: {
//       data: [123, 4241, 5812],
//       __lastFetched: 124235235,
//       __isFetching: false,
//       __error: nulll
//     }
//   }
// }
