import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/Button';
import Popover from 'components/Popover';
import PromoReactionWidget from 'components/PromoReactionWidget';
import DownloadTrackButton from 'components/Button/DownloadTrackButton';
import { generateSelectorsFromTestIds } from 'helpers';

const PlayerReactDownloadButton = ({ track }) => (
  <Popover
    renderToggle={({ open }) => (
      <div className="Player__button">
        <Button
          data-testid={PlayerReactDownloadButton.TEST_IDS.BUTTON}
          color={Button.COLORS.PRIMARY}
          uppercase
          onClick={open}
        >
          React & Download
        </Button>
      </div>
    )}
    renderDirection={Popover.DIRECTIONS.UP}
    fixed
  >
    {({ close, recalculatePositioning }) => (
      <PromoReactionWidget
        track={track}
        close={close}
        onHeightChange={recalculatePositioning}
        showStoreLinks
      />
    )}
  </Popover>
);

PlayerReactDownloadButton.propTypes = {
  track: PropTypes.shape()
};

PlayerReactDownloadButton.TEST_IDS = {
  BUTTON: 'react-and-download-track',
  GET_TRACK: 'get-full-track',
  BUY_BEATPORT: PromoReactionWidget.TEST_IDS.BUY_BEATPORT,
  STREAM_SPOTIFY: PromoReactionWidget.TEST_IDS.STREAM_SPOTIFY,
  DOWNLOAD: DownloadTrackButton.TEST_IDS.BUTTON
};

PlayerReactDownloadButton.SELECTORS = generateSelectorsFromTestIds(
  PlayerReactDownloadButton.TEST_IDS
);

export default PlayerReactDownloadButton;
