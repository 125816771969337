import React from 'react';

import { DEFAULT_ICON_COLOR, ICON_PROP_TYPES } from './config';

const Caret = props => {
  const { style, direction = Caret.DIRECTIONS.UP } = props;

  switch (direction) {
    case Caret.DIRECTIONS.LEFT:
      return <Caret.UP {...props} style={{ transform: 'rotate(270deg)', ...style }} />;

    case Caret.DIRECTIONS.RIGHT:
      return <Caret.UP {...props} style={{ transform: 'rotate(90deg)', ...style }} />;

    case Caret.DIRECTIONS.UP:
      return <Caret.UP {...props} />;

    case Caret.DIRECTIONS.DOWN:
      return <Caret.UP {...props} style={{ transform: 'rotate(180deg)', ...style }} />;

    default:
      return null;
  }
};

Caret.UP = ({
  className,
  color = DEFAULT_ICON_COLOR,
  size = 18,
  'data-testid': testId,
  direction,
  style
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 512 512"
    height={size}
    width={size}
    data-testid={testId}
    style={style}
  >
    <title>Caret {direction}</title>
    <path
      fill={color}
      d="M414 321.94L274.22 158.82a24 24 0 00-36.44 0L98 321.94c-13.34 15.57-2.28 39.62 18.22 39.62h279.6c20.5 0 31.56-24.05 18.18-39.62z"
    />
  </svg>
);

Caret.UP.propTypes = ICON_PROP_TYPES;

Caret.DIRECTIONS = {
  UP: 'up',
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right'
};

Caret.propTypes = ICON_PROP_TYPES;

export default Caret;
