import PropTypes from 'prop-types';
import React from 'react';

const TrackItem = ({ artist, track, label, index, trackClass }) => (
  <li className={`tracklist__track track track--horizontal ${trackClass}`} key={index}>
    <div className="track__cover" />
    <div className="track__meta">
      <div className="track__id js__tracklist-scroll">{index + 1}</div>
      <div className="track__artist">{artist}</div>
      <div className="track__title">{track}</div>
      <div className="track__label">{label}</div>
    </div>
  </li>
);

TrackItem.propTypes = {
  trackClass: PropTypes.string,
  artist: PropTypes.string,
  track: PropTypes.string,
  label: PropTypes.string,
  index: PropTypes.number
};

export default TrackItem;
