const GENRE_MAP = {
  'Tech House': {
    imageUrl: 'https://storage.googleapis.com/proton-genres/ondemand-techhouse-800.jpg',
    path: '/genres/2/tech-house'
  },
  Progressive: {
    imageUrl:
      'https://storage.googleapis.com/proton-genres/ondemand-progressivehouse-800.jpg',
    path: '/genres/1/progressive'
  },
  Techno: {
    imageUrl: 'https://storage.googleapis.com/proton-genres/ondemand-techno-800.jpg',
    path: '/genres/20/techno'
  },
  Electronica: {
    imageUrl: 'https://storage.googleapis.com/proton-genres/ondemand-electronica-800.jpg',
    path: '/genres/21/electronica'
  },
  'Deep House': {
    imageUrl: 'https://storage.googleapis.com/proton-genres/ondemand-deephouse-800.jpg',
    path: '/genres/72/deep-house'
  },
  Downtempo: {
    imageUrl: 'https://storage.googleapis.com/proton-genres/ondemand-downtempo-800.jpg',
    path: '/genres/4/downtempo'
  },
  Breaks: {
    imageUrl: 'https://storage.googleapis.com/proton-genres/ondemand-breaks-800.jpg',
    path: '/genres/3/breaks'
  },
  Electro: {
    imageUrl: 'https://storage.googleapis.com/proton-genres/ondemand-electro-800.jpg',
    path: '/genres/5/electro'
  }
};

export default GENRE_MAP;
