import React from 'react';
import { NavLink } from 'react-router-dom';

const AppNav = () => (
  <ul className="nav__list">
    <li>
      <NavLink exact to="/" className="nav__list__item" activeClassName="is-active">
        <span className="hidden-md hidden-lg i i-radio" aria-hidden="true" />
        Radio
      </NavLink>
    </li>
    <li>
      <NavLink
        exact
        to="/explore"
        className="nav__list__item"
        activeClassName="is-active"
      >
        <span className="hidden-md hidden-lg i i-mixes" aria-hidden="true" />
        Mixes
      </NavLink>
    </li>
  </ul>
);

export default AppNav;
