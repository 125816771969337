import _ from 'lodash';

import { DIGITAL_SERVICE_PROVIDERS } from 'config/constants';
import { consoleDev } from 'helpers/utilities';
import { protonApi, TAG_TYPES, defaultTransformResponse } from './rtkSetup';

const { SOUNDCLOUD, APPLE_MUSIC, SPOTIFY } = DIGITAL_SERVICE_PROVIDERS;
const TAG = TAG_TYPES.ARTIST_PROFILES;

/**
 * [setArtistProfileResponse] function used to handle fullfilling RTK query mutations where the artist profile
 * is returned in the response. Writes the artist profile data to the artist profile query cache
 */

const setArtistProfileFromResponse = ({ queryFulfilled, dispatch }) => {
  queryFulfilled
    .then(({ data: artistProfile }) => {
      dispatch(
        artistProfileApi.util.updateQueryData(
          'getArtistProfile',
          artistProfile.id,
          draft => {
            Object.assign(draft, artistProfile);
          }
        )
      );
    })
    // not handling the error causes cypress test failures, so for now catching with no specific action
    .catch(e => {});
};

const mutationPropsDefault = {
  transformResponse: defaultTransformResponse,
  // If mutation returns the artist profile, then this function will ensure the response is set into the cache instead
  // of invalidating the cache
  onQueryStarted: (args, { dispatch, queryFulfilled }) =>
    setArtistProfileFromResponse({ queryFulfilled, dispatch })
};

export const artistProfileApi = protonApi.injectEndpoints({
  endpoints: build => ({
    getArtistProfile: build.query({
      query: id => ({
        url: `artists/${id}/full`,
        method: 'GET'
      }),
      transformResponse: defaultTransformResponse,
      providesTags: (result, error, id) => [{ type: TAG, id }]
    }),
    /**
     * [createArtistProfile] - create a new Proton artist profile. Checks that name passed is unique.
     */
    createArtistProfile: build.mutation({
      query: name => ({
        url: `artists`,
        method: 'POST',
        body: { name }
      }),
      ...mutationPropsDefault
    }),
    /**
     * [updateArtistProfile] - allows updates of specific artist attributes.
     * User must be artist member, admin, or label manager to use.
     */
    updateArtistProfile: build.mutation({
      query: ({ id, ...body }) => ({
        url: `artists/${id}`,
        method: 'PUT',
        body
      }),
      ...mutationPropsDefault
    }),
    /**
     * [upsertArtistMember] - will add or update an artist member. If no user id passed,
     * the api will attempt to create a new user before adding them as an artist member.
     *
     * @param {number} artistId - artist that the member is being added to
     * @param {object} member
     * @param {number} [member.pro_user_id] - phpbb2 user id. Passed if adding an existing user to artist members
     * @param {string} [member.pro_email]* required if no user.id passed
     * @param {string} [member.first_name]
     * @param {string} [member.last_name]
     * @param {string} [member.country]
     * @param {string} [member.city]
     */
    upsertArtistMember: build.mutation({
      query: ({ artistId, member }) => ({
        url: `artists/${artistId}/members`,
        method: 'POST',
        body: member
      }),
      ...mutationPropsDefault
    }),
    uploadArtistAvatar: build.mutation({
      // TODO: How do we prevent storing arguments into redux for this one?  It's causing serialization bugs with the
      // file being stored
      query: ({ userId, artistId, file }) => {
        const fileExtension = file.name.split('.').pop();
        const renamedFile = new File([file], `${userId}_${artistId}.${fileExtension}`, {
          type: file.type,
          lastModified: file.lastModified
        });
        const formData = new FormData();
        formData.append('avatar', renamedFile);

        return {
          url: `artists/${artistId}/avatar`,
          method: 'POST',
          body: formData
        };
      },
      ...mutationPropsDefault
    }),
    deleteArtistAvatar: build.mutation({
      query: artistId => ({
        url: `artists/${artistId}/avatar`,
        method: 'DELETE'
      }),
      ...mutationPropsDefault
    }),
    /**
     * [updateArtistConfig] - Admin only endpoint to set the artist-configs.
     */
    updateArtistConfig: build.mutation({
      query: ({ artistId, configKey, configValue }) => ({
        url: `artists/${artistId}/config`,
        method: 'PATCH',
        body: {
          config_key: configKey,
          config_value: configValue
        }
      }),
      ...mutationPropsDefault
    }),
    /**
     * [updateArtistSetting] - artist member / label manager endpoint to configure artist specific settings.
     */
    updateArtistSetting: build.mutation({
      query: ({ id, payload }) => ({
        url: `artists/${id}/setting`,
        method: 'PATCH',
        body: payload
      }),
      transformResponse: defaultTransformResponse,
      onQueryStarted({ id, payload, __isOptimistic }, { dispatch, queryFulfilled }) {
        // NOTE: Because I'm not sure how updateArtistSetting for future settings, I left "payload" as a generic
        // object, but for optimistic updates we will require 'setting' & 'enabled' attributes - BTR
        const { setting, enabled } = payload;

        if (__isOptimistic && setting && enabled) {
          const patchResult = dispatch(
            artistProfileApi.util.updateQueryData('getArtistProfile', id, draft => {
              Object.assign(draft, { [setting]: enabled });
            })
          );

          queryFulfilled.catch(patchResult.undo);
        } else {
          setArtistProfileFromResponse({ queryFulfilled, dispatch });
        }
      }
    }),
    /**
     * [createArtistDspConnection] - creates a record of a dsp profile to associate with a proton artist profile
     *
     * TODO: the dsp connection endpoints are a hodge podge of endpoints that we might clean up some day:
     * https://app.asana.com/0/1156630881956667/1200619247396515/f
     *
     * @param {string} service - service to create association for (i.e. spotify, soundcloud, etc)
     * @param {string} artistProfile - full artist profile object
     * @param {string} dspProfile - profile returned by dsp search endpoint.  Should include `dsp_id` and
     * @returns {object} dsp connection
     */
    createArtistDspConnection: build.mutation({
      query: ({ service, artistProfile, dspProfile }) => {
        switch (service) {
          case SOUNDCLOUD:
            return {
              url: `artists/${artistProfile.id}/soundcloud`,
              method: 'POST',
              body: {
                soundcloud_url: dspProfile.dsp_url
              }
            };

          case APPLE_MUSIC:
          case SPOTIFY:
            return {
              url: `${_.snakeCase(service)}/artists/${dspProfile.dsp_id}`,
              method: 'POST',
              body: {
                artist_id: artistProfile.id
              }
            };

          default:
            const error = new Error(`No method for service type of ${service}`);
            consoleDev(error);
            throw error;
        }
      },
      transformResponse: defaultTransformResponse,
      invalidatesTags: (result, error, { artistProfile }) => [
        { type: TAG, id: artistProfile.id }
      ]
    }),
    disconnectArtistDspConnection: build.mutation({
      queryFn: (
        { artistProfile, service, dspProfile },
        { getState },
        options,
        baseQuery
      ) => {
        const artistId = artistProfile.id;
        const userId = getState().user.user_id;

        switch (service) {
          case SOUNDCLOUD:
            return baseQuery({
              url: `users/${userId}/artists/${artistId}/connections`,
              method: 'DELETE',
              body: {
                soundcloud_profile_id: dspProfile.id
              }
            });

          case APPLE_MUSIC:
          case SPOTIFY:
            return baseQuery({
              url: `${_.snakeCase(service)}/artists/${dspProfile.dsp_id}`,
              method: 'DELETE',
              body: {
                artist_id: artistId
              }
            });

          default:
            const error = new Error(`No method for service type of ${service}`);
            consoleDev(error);
            throw error;
        }
      },
      invalidatesTags: (result, error, { artistProfile }) => [
        { type: TAG, id: artistProfile.id }
      ]
    }),
    /**
     * [connectExistingSoundCloudConnection] - if a user already has a verified SoundCloud profile tied to their
     * account, this endpoint is used to associate that profile to a specified artist
     */
    connectExistingSoundCloudConnection: build.mutation({
      query: ({ artistId, soundcloudProfileId, userId }) => ({
        url: `users/${userId}/artists/${artistId}/connections`,
        method: 'POST',
        body: {
          soundcloud_profile_id: soundcloudProfileId
        }
      }),
      invalidatesTags: (result, error, { artistId }) => [{ type: TAG, id: artistId }]
    })
  })
});

export const {
  useGetArtistProfileQuery,
  useUploadArtistAvatarMutation,
  useDeleteArtistAvatarMutation,
  useCreateArtistProfileMutation,
  useUpdateArtistConfigMutation,
  useUpdateArtistProfileMutation,
  useUpdateArtistSettingMutation,
  useCreateArtistDspConnectionMutation,
  useDisconnectArtistDspConnectionMutation
} = artistProfileApi;
