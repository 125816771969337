import { IS_PROTON_HIRING } from 'config/constants';
import routeMap from 'config/routes';
import { useEffect } from 'react';

const hiringAscii = `%c
      @@@@@@@@@@@
  @@@@@@@@@@  @@@@@@                  @@
 @@@   @@@@     @@@@@@@              @@@
       @@@   @@@@   @@@@@@@ @@@@@@@ @@@@@ @@@@@@  @@@@@@ 
      @@@           @@ @@  @@@ @@@@ @@@  @@ @@@@ @@@  @@ @
     @@@               @@@@ @@@@@  @@@@@ @@@@@  @@  @@@@@
     @@
     
 __   __    ___ ___   _  _ ___ ___ ___ _  _  ___    __   __
 \\ \\  \\ \\  |   / __| | || |_ _| _ \\_ _| \\| |/ __|  / /  / /
  > >  > >  | |\\__ \\ | __ || ||   /| || .\` | (_ | < <  < < 
 /_/  /_/  |___|___/ |_||_|___|_|_\\___|_|\\_|\\___|  \\_\\  \\_\\

               ${routeMap.external.hiring}`;

const useHiringConsolePost = () => {
  useEffect(() => {
    if (IS_PROTON_HIRING) {
      console.log(hiringAscii, 'font-family: monospace');
    }
  }, []);
};

export default useHiringConsolePost;
