import { v4 as uuidv4 } from 'uuid';

import GENRE_MAP from './genreMap';

const findMatchingGenres = searchText => {
  const genreResults = [];
  const genres = Object.keys(GENRE_MAP);

  genres.forEach(genre => {
    const genreText = genre.toLowerCase();
    const queryText = searchText.toLowerCase();

    // for now, if query text is more than 4 characters and matches genre
    // text (looking at beginning and end of string), show search genre in search results
    const condition1 =
      queryText.length >= 4 && genreText.slice(0, queryText.length) === queryText;
    const condition2 =
      queryText.length >= 4 && genreText.slice(-queryText.length) === queryText;

    if (condition1 || condition2) {
      const regex = new RegExp(queryText, 'ig');

      genreResults.push({
        genre,
        slug: GENRE_MAP[genre].path,
        image_url: GENRE_MAP[genre].imageUrl,
        matched: {
          genre: genre.replace(regex, match => `<em>${match}</em>`) // Preserves case
        },
        category: 'genres',
        key: uuidv4() // unique iterator
      });
    }
  });

  return genreResults;
};

export default findMatchingGenres;
