import PropTypes from 'prop-types';
import React from 'react';
import _isUndefined from 'lodash/isUndefined';
import { connect } from 'react-redux';

import { addAlertMessage as addAlertMessageAction } from 'redux/actions/ui';
import { isProduction } from 'config/constants';
import Loader from 'components/Loader';

const RouteLoader = ({ pastDelay, error, addAlertMessage }) => {
  if (error) {
    if (!isProduction) console.warn(`[RouteLoader] error: `, error);
    addAlertMessage({
      message:
        'There has been an error loading part of this page. Please refresh and try again.'
    });
    return <Loader />;
  }

  if (_isUndefined(pastDelay) || pastDelay) {
    return <Loader />;
  }

  return null;
};

RouteLoader.propTypes = {
  pastDelay: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  addAlertMessage: PropTypes.func
};

export default connect(null, { addAlertMessage: addAlertMessageAction })(RouteLoader);
