import { normalize } from 'normalizr';
import { createAction } from 'redux-act';

import { promos as promosApi } from 'api/promos';

import { createAsyncActions } from './utilities';
import { promoReleaseList } from './schema';
import { ASYNC_OPERATIONS } from 'config/constants';

export const fetchPromoReleasesFollowing = createAsyncActions(
  'PROMO_RELEASES_FOLLOWING',
  ASYNC_OPERATIONS.FETCH
);

// Used for create & updated actions for track reactions
export const updateTrackReaction = createAsyncActions(
  'PROMO_TRACK_REACTION',
  ASYNC_OPERATIONS.UPDATE
);

export const updateTrackReactionOptimistic = createAction(
  'PROMO_TRACK_REACTION_OPTIMISITC_UPDATE'
);

export const dismissRelease = createAsyncActions('PROMO_RELEASE', 'DISMISS');

/**
 * [getPromoReleasesFollowing]
 * Fetches current promos releases for passed label subscription type for current user.
 * Includes release tracks (only promo specific track info)
 *
 * @returns {array} array of releases each containing array of tracks. Sorted by release date (desc)
 *  example: [{
 *    id: 123,
 *    bio_ids: [31, 11],
 *    tracks: [{
 *      id: 321,
 *      reaction: {
 *        bio_id: 31,
 *        comment: 'Boooooo! *Throws tomoato',
 *        rating: 1
 *      }
 *    }]
 *  }]
 */

export const getPromoReleasesFollowing = () => dispatch => {
  dispatch(fetchPromoReleasesFollowing.request());

  return promosApi
    .getPromoTracksFollowing()
    .then(({ releases }) => {
      // Save promo tracks and release data in redux
      const promoReleaseData = normalize(releases, promoReleaseList);

      dispatch(
        fetchPromoReleasesFollowing.success({
          data: promoReleaseData,
          rawData: releases
        })
      );

      return releases;
    })
    .catch(e => {
      dispatch(
        fetchPromoReleasesFollowing.error({
          errorMessage: e.message || e
        })
      );
      throw e;
    });
};
