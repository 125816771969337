import { ObjectValues } from 'types';

export * from './externalLinks';
export * from './styles';

///////
// VERSIONED COOKIES
export const FINGERPRINT_COOKIE = 'fingerprintVisitorId_v1';

///////
// ENVVARS
export const DROPBOX_KEY = process.env.REACT_APP_DROPBOX_KEY;

export const isProduction = process.env.REACT_APP_ENV === 'production';
export const isDeployPreview = process.env.REACT_APP_CONTEXT === 'deploy-preview';
export const isLocal = process.env.REACT_APP_ENV === 'development';
export const isStaging = process.env.REACT_APP_ENV === 'staging';
export const isTesting = process.env.REACT_APP_ENV === 'testing' || !!window['Cypress'];

export const API_BASE = process.env.REACT_APP_RAILS_API_BASE;
export const API_BASE_V2 = process.env.REACT_APP_RAILS_API_BASE_V2;
export const API_GRAPHQL_BASE = process.env.REACT_APP_RAILS_API_BASE + '/graphql';
export const RELEASE_LINKS_REDIRECT_BASE = isProduction
  ? 'https://go.protonrad.io'
  : 'https://stg.go.protonrad.io';
export const RELEASE_LINKS_BASE = isProduction
  ? 'https://go.protonradio.com'
  : 'staging-main.go.qa.protonradio.com';

///////
// AUDIO CONSTANTS

export const TRACK_PREVIEW_LENGTH_SECONDS = 120;

export type AudioType = ObjectValues<typeof AUDIO_TYPES>;
export const AUDIO_TYPES = {
  RADIO: 'radio',
  MIX: 'Mix',
  TRACK: 'track',
  COMMERCIAL: 'COMMERCIALS',
  SINGLE: 'SINGLES'
} as const;

export type DownloadFileType = 'mp3' | 'wav' | 'aiff';
export const AUDIO_FILE_TYPES = {
  MP3: {
    LABEL: 'MP3',
    VALUE: 'mp3',
    PREMIUM: false
  },
  WAV: {
    LABEL: 'WAV',
    VALUE: 'wav',
    PREMIUM: true
  },
  AIFF: {
    LABEL: 'AIFF',
    VALUE: 'aiff',
    PREMIUM: true
  }
} as const;

export type AudioQuality = ObjectValues<typeof AUDIO_QUALITIES>;
export const AUDIO_QUALITIES = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high'
} as const;

export type PlayerState = ObjectValues<typeof PLAYER_STATES>;
export const PLAYER_STATES = {
  PLAYING: 'PLAYING',
  STOPPED: 'STOPPED',
  PAUSED: 'PAUSED'
} as const;

export const RADIO_URLS = {
  SHOUTCAST: '//shoutcast.protonradio.com/;',
  ICECAST: '//icecast.protonradio.com'
} as const;

///////
// ARTIST CONSTANTS

export type ArtistRole = ObjectValues<typeof ARTIST_ROLES>;
export const ARTIST_ROLES = {
  ORIGINAL: 'Original Artist',
  REMIX: 'Remix Artist',
  FEATURED: 'Featured Artist'
} as const;

export const ARTIST_ROLE_IDS = {
  ORIGINAL: 1,
  REMIX: 2,
  FEATURED: 11
} as const;

export const DEFAULT_ARTIST_IMAGES = [
  'https://storage.googleapis.com/proton-profile-images/avatar-purple.jpg',
  'https://storage.googleapis.com/proton-profile-images/avatar-blue.jpg',
  'https://storage.googleapis.com/proton-profile-images/avatar-orange.jpg',
  'https://storage.googleapis.com/proton-profile-images/avatar-red.jpg',
  'https://storage.googleapis.com/proton-profile-images/avatar-yellow.jpg'
];

///////
// PROMO CONSTANTS

// Available types via promo pool's inbox subscription page
export const SUBSCRIPTION_STATES = {
  NEW_INVITES: 'INVITED',
  FOLLOWING: 'FOLLOWING',
  DISMISSED: 'DISMISSED'
} as const;
export type SubscriptionState = ObjectValues<typeof SUBSCRIPTION_STATES>;

export const PROMO_ACCESS = {
  FULL: 'full_access',
  LIMITED: 'limited_access'
} as const;

///////
// ALGOLIA CONSTANTS

export const ALGOLIA = {
  APP_ID: 'WEKPFM6E49',
  API_KEY: '865e9c9ec5f55484536a41052c1e381e', // public key
  MIX_INDEX: 'Mix_production',
  TRACK_INDEX: 'Track_production',
  SHOW_INDEX: 'Show',
  ARTIST_INDEX: 'Artist',
  USER_INDEX: 'private_pro_users_production',
  LABEL_INDEX: 'Label_production',
  RELEASE_INDEX: 'Release_production'
} as const;

export type AlgoliaIndex = ObjectValues<Omit<typeof ALGOLIA, 'APP_ID' | 'API_KEY'>>;

export const algoliaEntitiesByIndex = {
  [ALGOLIA.MIX_INDEX]: 'Mix',
  [ALGOLIA.TRACK_INDEX]: 'Track',
  [ALGOLIA.SHOW_INDEX]: 'Show',
  [ALGOLIA.ARTIST_INDEX]: 'Artist',
  [ALGOLIA.USER_INDEX]: 'User',
  [ALGOLIA.RELEASE_INDEX]: 'Release'
};

///////
// DIGITAL_SERVICE_PROVIDERS (DSP)

export const DIGITAL_SERVICE_PROVIDERS = {
  SOUNDCLOUD: 'soundcloud',
  SPOTIFY: 'spotify',
  APPLE_MUSIC: 'apple-music',
  BEATPORT: 'beatport'
} as const;

export const DSP_NAMES_FORMATTED = {
  [DIGITAL_SERVICE_PROVIDERS.SOUNDCLOUD]: 'SoundCloud',
  [DIGITAL_SERVICE_PROVIDERS.SPOTIFY]: 'Spotify',
  [DIGITAL_SERVICE_PROVIDERS.APPLE_MUSIC]: 'Apple Music',
  apple_music: 'Apple Music',
  [DIGITAL_SERVICE_PROVIDERS.BEATPORT]: 'Beatport'
};

export const DSP_CONNECT_ROLES = {
  SS_LABEL_MANAGER: 'ss_label_manager'
};

///////
// MISC CONSTANTS

export const pusher = {
  APP_KEY: process.env.REACT_APP_PUSHER_APP_KEY,
  CLUSTER: process.env.REACT_APP_PUSHER_CLUSTER,
  channels: {
    PROTON_RADIO: 'proton-radio'
  },
  events: {
    NOW_PLAYING: 'now_playing',
    LISTENER_COUNT: 'listener_count',
    APP_UPDATE: `app-update-${process.env.REACT_APP_ENV}`
  }
};

export const ERROR_MESSAGES = {
  MISSING_ARTIST_VERIFICATION: 'Artist validations failed'
} as const;

export const ARTIST_PROFILE_VALIDATIONS = {
  MISSING_MEMBERS: 'members',
  MISSING_SOUNDCLOUD_PROFILE: 'soundcloud_profile',
  UNVERIFIED_USERS: 'unverified_users'
};

export const SEARCH_TYPES = {
  users: 'Pro Users',
  userLookup: 'Account Lookup'
};

export const USER_SETTINGS = {
  AUTO_DOWNLOAD: 'AUTO_DOWNLOAD',
  DOWNLOAD_FORMAT: 'DOWNLOAD_FORMAT',
  DOWNLOAD_LOCATION: 'DOWNLOAD_LOCATION',
  PROMO_LABEL_INVITE_NOTIFICATION: 'PROMO_LABEL_INVITE_NOTIFICATION',
  PROMO_TRACK_NOTIFICATION: 'PROMO_TRACK_NOTIFICATION'
};

export const USER_PROFILE_TYPES = {
  ARTIST: 'artist',
  LABEL: 'label',
  SHOW: 'show'
};

export const USER_ACCOUNT_TYPES = {
  PRO: 'pro',
  BASIC: 'basic'
};

export type DownloadLocation = 'google' | 'dropbox' | 'local';

export const DOWNLOAD_LOCATIONS = {
  GOOGLE: {
    VALUE: 'google',
    LABEL: 'Google Drive'
  },
  DROPBOX: {
    VALUE: 'dropbox',
    LABEL: 'Dropbox'
  },
  LOCAL: {
    VALUE: 'local',
    LABEL: 'Direct Download'
  }
} as const;

export const ENTITY_TYPES = {
  ARTIST: 'artist',
  LABEL: 'label',
  RELEASE: 'release',
  TRACK: 'track',
  SHOW: 'show',
  MIX: 'mix',
  DSP_ARTIST_PROFILE: 'dsp_profile',
  USER: 'user'
} as const;

export const DIRECTIONS = {
  UP: 'UP',
  DOWN: 'DOWN',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
};

export const ALERT_TYPES = {
  MESSAGE: 'message',
  NOTICE: 'notice',
  WARNING: 'warning'
};

export const RELEASE_CONFIGS = {
  CREATE_NEW_SPOTIFY_PROFILE: 'create_new_spotify_profile'
};

export const ARTIST_CONFIGS = {
  SKIP_SOUNDCLOUD_VERIFICATION: 'skip_soundcloud_verification',
  IS_CURATOR: 'is_curator'
};

export const PAGINATION_CONFIGS = {
  DEFAULT_ITEMS_PER_PAGE: 25,
  ITEMS_PER_PAGE_OPTIONS: [10, 25, 50, 100],
  // API limits for perPage variable in requests as of 10/5/22 - BTR
  API_DEFAULT_MIN_PER_PAGE: 10,
  API_DEFAULT_MAX_PER_PAGE: 50
};

export const RECAPTCHA_SITE_KEY = '6LeqDS0kAAAAAE5UN_mVJmnv8lYQz28zboALDWgO';

// Local stograge key for last version of the privacy policy that was accepted
export const LAST_POLICY_CONSENT_STORAGE_KEY = 'lastPrivacyPolicyConsent';

// conditional below is only necessary for transition to new format, we can delete after Jan 26 update
export const POLICY_LAST_UPDATED = (function () {
  const a = new Date(process.env.REACT_APP_PRIVACY_POLICY_VERSION!);
  return a instanceof Date && !isNaN(a.getTime());
})()
  ? new Date(`${process.env.REACT_APP_PRIVACY_POLICY_VERSION}T12:00:00Z`)
  : new Date('July 1, 2018');

export const IS_PROTON_HIRING = isTesting ? true : false;

export const ASYNC_OPERATIONS = {
  CREATE: 'CREATE',
  FETCH: 'FETCH', // i.e. READ
  UPDATE: 'UPDATE',
  DELETE: 'DELETE'
};

export const NOTIFICATIONS = {
  PROTON_NEWSLETTER: 'proton_newsletter_subscription',
  SOUNDSYSTEM_DEMOS: 'soundsystem_demos_subscription',
  SOUNDSYSTEM_NEWSLETTER: 'soundsystem_newsletter_subscription',
  PROMO_LABEL_INVITE: 'promo_label_invite_notification',
  PROMO_TRACK: 'promo_track_notification'
} as const;
