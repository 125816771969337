import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { impersonateUser } from 'redux/actions/user';

export default () => {
  const queryParams = new URLSearchParams(location.search);
  const userID = queryParams.get('masquerade_as');

  const dispatch = useDispatch();
  useEffect(() => {
    if (userID) {
      dispatch(impersonateUser(userID));
    }
  }, []);
};
