import React from 'react';

import appleMusic from 'images/apple-music.png';

const AppleMusic = ({ size = 18, className = '' }) => (
  <img
    alt="Apple Music Logo"
    src={appleMusic}
    style={{ height: size, width: size }}
    className={className}
  />
);

export default AppleMusic;
