import React from 'react';
import { useSelector } from 'react-redux';

import { TrackActionMenu } from 'components/ActionMenu/ActionMenus';
import { Badge } from '@protonradio/proton-ui';
import Icon from 'components/Icon';
import Screen from 'components/Screen';
import { AUDIO_TYPES, COLORS } from 'config/constants';
import { generateSelectorsFromTestIds } from 'helpers';
import useProtonPlayer from 'hooks/useProtonPlayer';
import useToggle from 'hooks/useToggle';
import moreIcon from 'images/icon-more.svg';
import { selectIsTrackDownloadable } from 'redux/selectors/tracks';

import PlayerDetailsPopover from './PlayerDetailsPopover';
import PlayerSubtitle from './PlayerSubtitle';
import PlayerTitle from './PlayerTitle';
import { useAppSelector } from 'hooks/redux';
import { selectIsTrackOnPromo } from 'redux/selectors';
import { Audio } from 'helpers/tracks';

const Title = ({ playing, noLink }: { playing: Audio; noLink?: boolean }) => (
  <div className="Player__title" data-testid={PlayerMeta.TEST_IDS.TITLE}>
    <PlayerTitle playing={playing} noLink={noLink} />
  </div>
);

const Subtitle = ({ playing, noLink }: { playing: Audio; noLink?: boolean }) => (
  <div className="Player__subtitle" data-testid={PlayerMeta.TEST_IDS.SUBTITLE}>
    <PlayerSubtitle playing={playing} noLink={noLink} />
  </div>
);

const PlayerMeta = () => {
  const commonProps = {
    className: 'Player__meta',
    'data-testid': PlayerMeta.TEST_IDS.CONTAINER
  };

  const player = useProtonPlayer();
  const playing = player.currentTrack;
  const isTrackDownloadable = useSelector(state =>
    playing ? selectIsTrackDownloadable(state, { audio: playing }) : false
  );
  const isPromo = useAppSelector(state =>
    selectIsTrackOnPromo(state, { audio: playing })
  );

  const { isOpen, open, close } = useToggle();
  const isTrackPlaying = playing?.type === AUDIO_TYPES.TRACK;
  const playerTrackMetaProps = isTrackPlaying
    ? {
        onClick: open,
        role: 'button',
        tabIndex: 0
      }
    : {};

  return (
    <>
      <Screen.MEDIUM down>
        {isTrackPlaying && (
          <TrackActionMenu
            track={playing}
            isOpen={isOpen}
            close={close}
            canDownload={isTrackDownloadable}
            isPromo={isPromo}
          />
        )}

        <div {...commonProps} {...playerTrackMetaProps}>
          <div className="Player__meta__inner">
            <div style={{ width: '100%', overflow: 'hidden' }}>
              <Title playing={playing} noLink />
              <Subtitle playing={playing} noLink />
            </div>

            {player.isPreview && (
              <Badge variant="secondary" data-testid={PlayerMeta.TEST_IDS.PREVIEW}>
                PREVIEW
              </Badge>
            )}
          </div>

          {isTrackPlaying && (
            <>
              <div
                className="Player__meta__more-icon"
                data-testid={PlayerMeta.TEST_IDS.MORE_TOGGLE}
              >
                {isTrackDownloadable && (
                  <Icon
                    type={Icon.TYPES.DOWNLOAD}
                    width={14}
                    color={COLORS.GRAY_MEDIUM}
                    className="mr-3"
                    data-testid={PlayerMeta.TEST_IDS.DOWNLOAD}
                  />
                )}

                <img src={moreIcon} alt="More Icon" />
              </div>
            </>
          )}
        </div>
      </Screen.MEDIUM>

      <Screen.MEDIUM>
        <div {...commonProps}>
          <div className="Player__meta__inner">
            {playing && <Title playing={playing} />}
            {playing && <Subtitle playing={playing} />}
          </div>

          {isTrackPlaying && <PlayerDetailsPopover playing={playing} />}
        </div>
      </Screen.MEDIUM>
    </>
  );
};

PlayerMeta.TEST_IDS = {
  CONTAINER: 'Player-meta',
  TITLE: 'player-title',
  SUBTITLE: 'player-subtitle',
  PREVIEW: 'Preview-badge',
  DOWNLOAD: 'player-download-icon',
  MORE_TOGGLE: 'PlayerMeta-more-toggle'
};

PlayerMeta.SELECTORS = generateSelectorsFromTestIds(PlayerMeta.TEST_IDS);

export default PlayerMeta;
