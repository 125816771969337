import { AUDIO_TYPES } from 'config/constants';

const playerMigration3 = state => {
  // Ensure we migrate existing player state to use the queue, doing so will prevent clearing
  // any track or mix that might have been playing
  const audioType = state.type;
  const audioId = state.id;

  if ([AUDIO_TYPES.TRACK, AUDIO_TYPES.MIX].includes(audioType)) {
    return {
      ...state,
      queue: [{ audioId, audioType }],
      queueIndex: 0,
      queueName: `${audioId}:${audioType}`
    };
  }

  return {
    ...state,
    queue: null,
    queueIndex: null
  };
};

export default playerMigration3;
