import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { TrackActionMenu } from 'components/ActionMenu/ActionMenus';
import { default as OldIcon } from 'components/Icon';
import { DownloadIcon, PacmanIcon } from 'components/Icons';
import MenuToggler from 'components/MenuToggler';
import PromoReactionWidget from 'components/PromoReactionWidget';
import Tooltip from 'components/Tooltip';
import { COLORS } from 'config/constants/styles';
import { generateSelectorsFromTestIds } from 'helpers';
import { downloadTrack } from 'redux/actions/tracks';
import { selectPromoTrackReaction } from 'redux/selectors/promoReleases';

import { trackDownloadConfig } from '../helpers/tracks';
import useCurrentUser from '../hooks/useCurrentUser';

const StyledDownloadButtonWrapper = styled.button`
  background: inherit;
  outline: none;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.7;
  `}

  transition: filter 0.3s;

  &:hover {
    filter: brightness(75%);
  }
`;

const DownloadTrackMenuToggler = ({
  track,
  children,
  showRatingTooltip,
  showPromoWidget,
  format,
  onOpen,
  onClose,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { user: currentUser } = useCurrentUser();

  const [isDownloading, setIsDownloading] = useState(false);
  const [didDownload, setDidDownload] = useState(false);

  const { user, reaction } = useSelector(state => ({
    user: state.user,
    reaction: selectPromoTrackReaction(state, track?.id)
  }));

  const hasReactedToTrack = !!reaction?.downloaded_at;

  if (hasReactedToTrack || !showPromoWidget) {
    const downloadCurrentTrack = () => {
      setIsDownloading(true);

      dispatch(
        downloadTrack.call(trackDownloadConfig(currentUser, { track, user, format }), {
          onFinally: () => {
            setIsDownloading(false);
            setDidDownload(true);
          }
        })
      );
    };

    return (
      <DownloadTrackMenuToggler.ToggleButton
        hasDownloaded={hasReactedToTrack}
        onClick={downloadCurrentTrack}
        format={format}
        isDownloading={isDownloading}
        didDownload={didDownload}
      />
    );
  } else {
    return (
      <MenuToggler
        onOpen={onOpen}
        onClose={onClose}
        renderToggle={({ open, isOpen }) => {
          if (!reaction?.rating && showRatingTooltip) {
            return (
              <Tooltip
                placement="top"
                trigger="click"
                content="Please rate to download promo."
              >
                <DownloadTrackMenuToggler.ToggleButton
                  hasDownloaded={hasReactedToTrack}
                  format={format}
                  disabled
                />
              </Tooltip>
            );
          }

          return (
            <DownloadTrackMenuToggler.ToggleButton
              hasDownloaded={hasReactedToTrack}
              onClick={open}
              active={isOpen}
              format={format}
              isDownloading={isDownloading}
              didDownload={didDownload}
            />
          );
        }}
        renderActionMenu={({ isOpen, close }) => (
          <TrackActionMenu isOpen={isOpen} close={close} track={track} />
        )}
        renderPopoverContent={({ close }) => (
          <PromoReactionWidget
            onDownload={() => setIsDownloading(true)}
            onDownloadSuccess={() => {
              setIsDownloading(false);
              setDidDownload(true);
            }}
            close={close}
            format={format}
            track={track}
          />
        )}
        {...rest}
      />
    );
  }
};

DownloadTrackMenuToggler.propTypes = {
  track: PropTypes.shape({
    id: PropTypes.number
  }),
  children: PropTypes.func,
  showPromoWidget: PropTypes.bool,
  showRatingTooltip: PropTypes.bool,
  hasRating: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};

// Optional toggle button... commonly used Download Icon
DownloadTrackMenuToggler.ToggleButton = ({
  onClick = () => null,
  active = false,
  disabled,
  hasDownloaded,
  isDownloading = false,
  didDownload = false,
  format,
  ...rest
}) => {
  const componentColor = active || didDownload || hasDownloaded ? COLORS.PRIMARY : '#999'; //COLORS.GRAY_MEDIUM;

  const icon = didDownload ? (
    <OldIcon type={OldIcon.TYPES.CHECK_MARK} color={componentColor} width={12} />
  ) : isDownloading ? (
    <PacmanIcon color={componentColor} size={12} />
  ) : (
    <DownloadIcon color={componentColor} size={12} />
  );

  return (
    <StyledDownloadButtonWrapper
      data-testid={DownloadTrackMenuToggler.TEST_IDS.TOGGLE}
      disabled={disabled}
      onClick={e => {
        if (disabled) return;
        onClick(e);
      }}
      {...rest}
    >
      {icon}
      <div
        style={{
          fontSize: 12,
          color: componentColor
        }}
      >
        {String(format).toUpperCase()}
      </div>
    </StyledDownloadButtonWrapper>
  );
};

DownloadTrackMenuToggler.TEST_IDS = {
  TOGGLE: 'download-track-menu-toggle-button'
};

DownloadTrackMenuToggler.SELECTORS = generateSelectorsFromTestIds(
  DownloadTrackMenuToggler.TEST_IDS
);

DownloadTrackMenuToggler.ToggleButton.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool
};

export default DownloadTrackMenuToggler;
