import { createReducer } from 'redux-act';
import { combineReducers } from 'redux';

import { topShowsActions, fetchShowActions } from 'redux/actions/shows';

import {
  handleFetchRequest,
  handleFetchError,
  handleFetchSuccess,
  fetchInitialState,
  handleArrayToByIdSuccess
} from './utilities';

export const initialShowState = {
  flyer: '',
  flyer_url: '',
  host: {},
  id: null,
  name: '',
  slug: '',
  summary: '',
  thumb: '',
  thumb_url: ''
};

const byIdInitialState = {};

const byId = createReducer(
  {
    [fetchShowActions.request]: (state, payload) =>
      handleFetchRequest(state, payload, { initialData: initialShowState }),
    [fetchShowActions.success]: handleFetchSuccess,
    [fetchShowActions.error]: handleFetchError,
    [topShowsActions.success]: handleArrayToByIdSuccess
  },
  byIdInitialState
);

const topShowsIds = createReducer(
  {
    [topShowsActions.request]: handleFetchRequest,
    [topShowsActions.success]: handleFetchSuccess,
    [topShowsActions.error]: handleFetchError
  },
  fetchInitialState
);

export default combineReducers({
  byId,
  topShowsIds
});
