import React, { useCallback, useState, useRef, useMemo } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import ReactResizeDetector from 'react-resize-detector';

import Icon from 'components/Icon';
import { Badge, BadgeVariant, BadgeVariants } from '@protonradio/proton-ui';

const DEFAULT_LINE_HEIGHT = 22;

/**
 * TextExpander
 * - shows/hides longer blocks of text.
 * - auto grows/shrink to proper size based on bounding container
 * @prop {number} lines OPTIONAL - number of lines to display by default
 * @prop {number} lineHeight OPTIONAL - useful if calculating initial height via lines
 * and default line height is not 2.2rem
 * @prop {number} startHeight OPTIONAL
 * @prop {string} text REQUIRED - text to be expanded / collapsed around
 * @prop {function} onResize OPTIONAL - Will run when resize animation finishes
 * (99% finished since last 1% is slow to finish)... currently used for windowScroller
 * updating w/ React Virtualized
 */

const StyledTextExpander = styled.div<{ $clickable: boolean }>`
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};
`;

const StyledIcon = styled(Icon)<{ $reversed: boolean }>`
  ${({ $reversed }) => $reversed && `transform: rotate(180deg);`}
`;

const StyledTextWindow = styled(animated.div)<{ $height: string }>`
  overflow: hidden;
  position: relative;

  :before {
    content: '';
    position: absolute;
    bottom: 0;
    height: ${({ $height }) => $height};
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }
`;

const StyledTextBody = styled.div`
  line-height: 1.5;
  white-space: pre-wrap;
`;

interface TextExpanderProps {
  children: React.ReactNode;
  lineHeight?: number;
  startHeight?: number;
  onResize?: () => void;
  lessText?: string;
  moreText?: string;
  testId?: string;
}

const TextExpander = ({
  children,
  lineHeight = DEFAULT_LINE_HEIGHT,
  startHeight,
  onResize,
  lessText = 'Read Less ',
  moreText = 'Read More ',
  testId = 'TextExpander'
}: TextExpanderProps) => {
  const initialHeight = useMemo(
    () => startHeight || 1 * lineHeight,
    [startHeight, lineHeight]
  );
  const textBody = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(initialHeight);
  const [expanded, setExpanded] = useState(false);
  const [isExpandable, setExpandable] = useState(false);

  // In the event that the text height is less than the window, use height auto
  // to prevent weird animation
  const textBodyHeight = textBody.current?.clientHeight;
  const useHeightAuto = !textBodyHeight || textBodyHeight < height;

  const windowStyleProps = useSpring({
    height: useHeightAuto ? 'auto' : height
  });

  const onExpanderClick = useCallback(() => {
    if (!isExpandable || !textBody.current) return;
    const bodyHeight = textBody.current.clientHeight;
    const nextHeight = expanded ? initialHeight : bodyHeight;
    setHeight(nextHeight);
    setExpanded(!expanded);
  }, [expanded, isExpandable, initialHeight]);

  // NOTE: fires on 1st render
  // When resizing window, checks to ensure the text window height is properly and whether
  // window is expandable still (if applicable)
  const onTextResize = useCallback(() => {
    if (!textBody.current) return;

    const bodyHeight = textBody.current.clientHeight;
    if (expanded && height !== bodyHeight) {
      setHeight(bodyHeight);
    }

    if (!expanded && bodyHeight < initialHeight) {
      setHeight(bodyHeight);
    }

    const isExpandableCheck = bodyHeight > initialHeight;
    if (isExpandableCheck !== isExpandable) setExpandable(isExpandableCheck);
  }, [isExpandable, height, expanded, initialHeight]);

  return (
    <StyledTextExpander
      onClick={onExpanderClick}
      $clickable={isExpandable}
      data-testid={testId}
    >
      <StyledTextWindow
        $height={expanded || !isExpandable ? '0' : '3rem'}
        style={windowStyleProps}
      >
        <StyledTextBody ref={textBody}>
          <ReactResizeDetector handleHeight onResize={onTextResize} />
          {children}
        </StyledTextBody>
      </StyledTextWindow>

      {isExpandable && (
        <Badge variant={BadgeVariants.secondary}>
          {expanded ? lessText : moreText}
          <StyledIcon
            $reversed={expanded}
            type={Icon.TYPES.ARROW_DOWN_CIRCLE}
            width={8}
            color="#666666"
          />
        </Badge>
      )}
    </StyledTextExpander>
  );
};

export default TextExpander;
