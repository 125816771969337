import React from 'react';

import { DEFAULT_ICON_COLOR, ICON_PROP_TYPES } from './config';

const MusicNote = ({ color = DEFAULT_ICON_COLOR, size = 18, className, style }) => (
  <svg
    width={size * 0.783}
    height={size}
    viewBox="0 0 352 449"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
    style={style}
  >
    <title>Music Note</title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="musical-notes-outline"
        transform="translate(16.000000, 16.000000)"
        stroke={color}
        strokeWidth="32"
      >
        <path
          d="M96,170 L96,164 C96,149.16 106,137 120.24,133.41 L294.83,86.73 C300.826822,85.1252582 307.228842,86.3961549 312.157962,90.1698593 C317.087082,93.9435637 319.984416,99.7921965 320,106 L320,128"
          id="Path"
        />
        <path
          d="M320,247.94 L320,327.94 C320,341.85 311.07,353.53 298,357.94 L276,365.94 C250.1,374.66 224,355.52 224,327.94 L224,327.94 C223.877229,313.401925 233.181057,300.457468 247,295.94 L298,277.79 C311.07,273.39 320,261.85 320,247.94 L320,10 C319.985708,6.89706054 318.531789,3.97655756 316.06453,2.0947835 C313.597272,0.213009443 310.396248,-0.416805729 307.4,0.39 L108,54 C100.851047,56.0191638 95.9366904,62.5716393 96,70 L96,296 C96,309.91 87.07,321.6 74,326 L22,344 C8.12,348.68 2.84217094e-14,361.22 2.84217094e-14,376 L2.84217094e-14,376 C2.84217094e-14,403.58 26.52,422.55 52,414 L74,406 C87.07,401.6 96,389.92 96,376 L96,296"
          id="Path"
        />
      </g>
    </g>
  </svg>
);

MusicNote.propTypes = ICON_PROP_TYPES;

export default MusicNote;
