import { AxiosError } from 'axios';
import { EVENTS, ProtonEventEmitter } from 'config/events';
import { store } from 'config/store';

import { showAlert } from 'redux/actions/ui';

/**
 * Global error handling for unique errors thrown by the proton api
 */

const handleProtonApiErrors = (
  error: AxiosError<{ message?: string; token?: string }>
): { silence?: true } => {
  const errorMessage = error.response?.data?.message || error.message;
  const status = error.response?.status;

  if (errorMessage === 'PRO_USER_DETAILS_NOT_CONFIRMED') {
    store.dispatch(
      showAlert({
        component: 'VerifyProUserAlert'
      })
    );
    return { silence: true };
  }

  if (errorMessage === 'MFA required' && status === 403) {
    const token = error.response?.data?.token;
    ProtonEventEmitter.emit(EVENTS.MFA_REQUIRED, token);

    return { silence: true };
  }

  return {};
};

export default handleProtonApiErrors;
