/* eslint-disable max-len */

export const en = {
  account: {
    accountUpdateSuccess: 'Your account has been saved!',
    artistAlreadyConnected:
      'This account is currently connected with an artist. Please disconnect this account from the artist profile editor and try again.',
    confirmed: 'Your account has been successfully confirmed!',
    confirmationEmailResent:
      'A new confirmation email has been sent to the registered address.',
    disconnectDropboxSuccess: 'Your Dropbox account has been successfully disconnected.',
    disconnectGoogleSuccess: 'Your Google account has been successfully disconnected.',
    disconnectSoundcloudSuccess:
      'Your SoundCloud account has been successfully disconnected',
    disconnectSpotifySuccess: 'Your Spotify account has been successfully disconnected.',
    emailNotFound: 'This email address is not found in our system. Try again?',
    musicLabelAlreadyConnected:
      'This account is currently connected with a music label. Please disconnect this account from your label within SoundSystem and try again.',
    loadingUserError: 'Error retrieving user. Please wait a moment and try again.',
    resetPasswordSuccess:
      'Success! Your password has been reset and you have been automatically signed in.',
    passwordUpdated: 'Your password has been successfully updated.'
  },
  errors: {
    generic:
      'Uh oh, there has been an error. Please wait a minute to try again or contact support@protonradio.com if the issue persists.',
    cannotVerifySpotify:
      'Verification failed. Please try again after the release date of your 1st release through Proton.'
  },
  warnings: {
    unsavedChangesLeave: 'You have unsaved changes, are you sure you want to leave?',
    unsavedChangesContinue: 'You have unsaved changes, are you sure you want to continue?'
  },
  artistProfile: {
    hideMemberNamesOnSpotify: `Turn this on to hide individual member names in Spotify's "Credits" area. When enabled, the artist name will be used instead.`,
    hideMemberNamesArtist:
      'This change will be applied to upcoming releases via Proton labels that have not already been scheduled. To make changes to past releases, please contact your label manager for help.',
    hideMemberNamesLabelManager:
      'This change will be applied to any upcoming releases that have not already been scheduled. To apply these changes to past releases, just click "Edit/Fix" and "Submit Corrections" on any release needed.'
  },
  addSubscriber: {
    NO_ARTIST: 'Can’t find the DJ you’re looking for? They may not be on Proton yet!',
    NO_ARTIST_BETA:
      'Can’t find the DJ you’re looking for? You’ll be able to create & invite artists after the beta.',
    NO_ARTIST_LIMITED_ACCESS:
      "Want to add more artists & DJs? New invites for full access to the promo pool are sent each week based on a label's sales and streaming performance.",
    LIMITED_ACCESS_EXPLAINER:
      "Your label is currently on the waitlist to join the promo pool. For now, you're limited to adding artists from your own music label(s). Search & add them here!",
    FULL_ACCESS_EXPLAINER:
      'Search for an artist to invite or choose from the list of recommended subscribers.'
  },
  artistProfileWizard: {
    NO_TRANSACTION:
      "We couldn't find the page you were looking for. We started you back at the beginning of this workflow."
  }
};
