import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from 'config/constants';

const HeaderWrapper = styled.div`
  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    bottom: 0;
    postion: absolute;
    background: ${({ $noUnderline }) =>
      $noUnderline ? 'transparent' : COLORS.GRAY_LIGHT};
  }
`;

const Header = styled.div`
  padding: 1.5rem;
`;

const ActionMenuHeader = ({ children, noUnderline }) => (
  <HeaderWrapper data-testid="ActionMenu-header" $noUnderline={noUnderline}>
    <Header>{children}</Header>
  </HeaderWrapper>
);

ActionMenuHeader.Title = ({ children }) => (
  <div className="ListItem__title">{children}</div>
);

ActionMenuHeader.Subtitle = ({ children }) => (
  <div className="ListItem__subtitle">{children}</div>
);

ActionMenuHeader.propTypes = {
  children: PropTypes.node,
  noUnderline: PropTypes.bool
};

export default ActionMenuHeader;
