import { useEffect } from 'react';
import { fetchAcknowledgements } from 'redux/actions/acknowledgements';
import { useDispatch, useSelector } from 'react-redux';

import { selectUser } from 'redux/selectors';
import { selectIsJwtExpired } from 'redux/selectors/token';

const useAcknowledgements = () => {
  const dispatch = useDispatch();
  const { user_id: userId } = useSelector(selectUser);
  const isJwtExpired = useSelector(selectIsJwtExpired);

  useEffect(() => {
    if (!userId || isJwtExpired) return;

    dispatch(fetchAcknowledgements());
  }, [dispatch, isJwtExpired, userId]);
};

export default useAcknowledgements;
