import { useState, useEffect } from 'react';
import { BREAKPOINTS } from 'config/constants';

const getMediaQuery = (size, direction) => {
  const minOrMax = direction === 'up' ? 'min' : 'max';
  return window.matchMedia(`(${minOrMax}-width: ${size}px)`);
};

const useBreakpoint = (size, direction = 'up') => {
  const [matches, setMatches] = useState(getMediaQuery(size, direction).matches);

  useEffect(() => {
    const mediaQueryList = getMediaQuery(size, direction);
    setMatches(mediaQueryList.matches);

    const handleChange = () => {
      setMatches(!!mediaQueryList.matches);
    };

    mediaQueryList.addListener(handleChange);

    return () => mediaQueryList.removeListener(handleChange);
  }, [size, direction]);

  return matches;
};

useBreakpoint.BREAKPOINTS = BREAKPOINTS;

useBreakpoint.DIRECTIONS = {
  UP: 'up',
  DOWN: 'down'
};

export default useBreakpoint;
