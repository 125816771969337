import algoliasearch from 'algoliasearch';
import jwtDecode from 'jwt-decode';

import { ALGOLIA, isTesting } from 'config/constants';
import { store } from 'config/store';
import { isAdminUser } from 'helpers/user';

const formatMatches = matches =>
  Object.entries(matches).reduce((accum, [fieldName, match]) => {
    // handle nested array (bios, labels, etc)
    if (Array.isArray(match)) {
      const matchesInArray = match.map(formatMatches);
      return { ...accum, [fieldName]: matchesInArray };
    }

    // for now we will use fuzzy matching
    // if (match.matchLevel === 'full') {
    //   return { ...accum, [fieldName]: match.value };
    // }

    return { ...accum, [fieldName]: match.value };
  }, {});

const formatUsersResponse = response =>
  response.hits.map(hit => ({
    ...hit,
    matched: formatMatches(hit._highlightResult),
    // used in UniversalSearchResultRow
    category: 'users',
    key: hit.user_id
  }));

const searchUsers = ({ query, hitsPerPage }) => {
  const {
    user,
    token: { jwt }
  } = store.getState();

  if (!isAdminUser(user)) return Promise.resolve([]);

  const { private_algolia_key: userApiKey } = jwtDecode(jwt);

  // NOTE: We assume if testing as an admin you are stubbing the API and may want to test it
  if (!userApiKey && !isTesting) {
    console.warn('No key found user search key found!');
    return Promise.resolve([]);
  }

  const client = algoliasearch(ALGOLIA.APP_ID, userApiKey);

  const userIndex = client.initIndex(ALGOLIA.USER_INDEX);

  return userIndex
    .search(query, {
      restrictSearchableAttributes: [
        'name',
        'username',
        'user_email',
        'paypal_email',
        'pro_email',
        'bios.name',
        'labels.name',
        'shows.name'
      ],
      hitsPerPage,
      restrictHighlightAndSnippetArrays: true
    })
    .then(formatUsersResponse, err => {
      console.warn('Error searching users', err);
    });
};

export default searchUsers;
