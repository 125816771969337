/* eslint-disable react/display-name */
import React, { FC } from 'react';
import styled from 'styled-components';
import Truncate from 'react-truncate';

import { COLORS } from 'config/constants/styles';
import TextHeader from 'components/TextHeader';
import { darken } from 'polished';

const CARD_PADDING = 1.5;
const CARD_PADDING_COMPACT = 1;

const BORDER_COLOR = COLORS.GRAY_LIGHTEST;
export const CARD_RADIUS_REM = '0.8rem';

const CardHeader = styled.div<{ $compact?: boolean; $children?: React.ReactNode }>`
  background: ${BORDER_COLOR};
  color: ${COLORS.GRAY_DARK};
  font-size: 1.4rem;
  font-weight: 600;
  padding: ${({ $compact }) => {
    const sidePadding = $compact ? CARD_PADDING_COMPACT : CARD_PADDING;
    const vertPadding = 1;
    return `${vertPadding}rem ${sidePadding}rem`;
  }};
`;

export const CardTitle = styled(TextHeader)`
  line-height: 1;
  margin-bottom: 0;
  padding-bottom: 0.4rem;
`;

const CardContent = styled.div<{ $compact?: boolean }>`
  padding: ${({ $compact }) =>
    $compact ? `${CARD_PADDING_COMPACT}rem` : `${CARD_PADDING}rem`};
`;

const CardContainer = styled.div<{
  $shadow?: boolean;
  $border?: boolean;
  $isActive?: boolean;
  $fitHeight?: boolean;
  $compact?: boolean;
  $height?: number;
  $minWidth?: number;
  $disabled?: boolean;
}>`
  background-color: #ffffff;

  border-radius: ${CARD_RADIUS_REM};
  background: ${COLORS.WHITE};
  box-shadow: ${({ $shadow }) =>
    $shadow &&
    `
    0 0 1px 1px ${BORDER_COLOR}, 0 0 15px 3px rgba(0, 0, 0, 0.05);
  `};
  overflow: hidden;
  position: relative;
  ${({ $border, $isActive }) =>
    $border &&
    `
    border: 1px solid ${$isActive ? COLORS.PRIMARY : COLORS.GRAY_LIGHT};
  `}

  ${({ $fitHeight, $compact }) =>
    !$fitHeight &&
    `
    min-height: ${$compact ? '5rem' : '10rem'};
  `}
  ${({ onClick, $isActive }) =>
    onClick &&
    `
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.05), 0 0 5px rgba(0, 0, 0, 0.05);
      border-color: ${darken(0.05, $isActive ? COLORS.PRIMARY : COLORS.GRAY_LIGHT)}
    }
  `}
  ${({ $height }) =>
    $height &&
    `
    height: ${$height}px;
  `}
  ${({ $minWidth }) =>
    $minWidth &&
    `
      min-width: ${$minWidth}px
  `}
  ${({ $disabled }) =>
    $disabled &&
    `
      &:after {
        background: rgba(255, 255, 255, 0.4);
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
      }
  `}
  /* in order to override TextHeader css, we nest line-height here */
  ${CardTitle} {
    line-height: 1.4;
  }
`;

interface CardProps {
  children: React.ReactNode; // card content (should probably be wrapped in Card.Content component)
  onClick?: () => void; // if passed card will be clickable and have pointer cursor
  disabled?: boolean; // if clickable card, applies disable cursor and overlay to card
  compact?: boolean; // reduced padding on card content if true
  height?: number; // define fixed height of card.
  fitHeight?: boolean; // Card will shrink/grow to fit height of content (removes min height)
  isActive?: boolean; // if true, the card will be given an orange border (likely to indicate selection)
  shadow?: boolean;
  border?: boolean;
  className?: string;
  minWidth?: number;
}

interface StaticFields {
  Header: FC<{ children: React.ReactNode }>;
  Title: FC<{ children: React.ReactNode; lines?: number }>;
  Content: FC<{
    children: React.ReactNode;
    className?: string;
    compact?: boolean;
    isActive?: boolean;
    style?: React.CSSProperties;
  }>;
  SELECTORS: {
    ACTIVE: string;
  };
}

const Card: FC<CardProps> & StaticFields = ({
  height,
  minWidth,
  fitHeight,
  children,
  compact,
  className,
  disabled,
  onClick,
  shadow = true,
  border = true,
  isActive,
  ...rest
}: CardProps) => (
  <CardContainer
    onClick={onClick}
    $fitHeight={fitHeight}
    $height={height}
    $minWidth={minWidth}
    $compact={compact}
    className={className}
    $disabled={disabled}
    $isActive={isActive}
    data-active={isActive} // add active attribute for tests to check for
    $shadow={shadow}
    $border={border}
    {...rest}
  >
    {/* If one child node, assume it is Card.Content and clone relevant style props */}
    {/* {React.Children.count(children) === 1
      ? React.cloneElement(children, { isActive, compact })
      : children} */}
    {children}
  </CardContainer>
);

Card.Header = ({ children, ...rest }: { children: React.ReactElement }) => (
  <CardHeader {...rest}>{children}</CardHeader>
);

Card.Title = ({
  children,
  lines,
  ...rest
}: {
  children: React.ReactNode;
  lines?: number;
}) => (
  <CardTitle type={TextHeader.TYPES.LARGE} {...rest}>
    {lines ? <Truncate lines={lines}>{children}</Truncate> : children}
  </CardTitle>
);

Card.Content = ({ children, compact, className, style }) => (
  <CardContent $compact={compact} className={className} style={style}>
    {children}
  </CardContent>
);

Card.SELECTORS = {
  ACTIVE: '[data-active="true"]'
};

export default Card;
