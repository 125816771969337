import React from 'react';

import { DEFAULT_ICON_COLOR, ICON_PROP_TYPES } from './config';

const WIDTH_RATIO = 448 / 417;

const Comment = ({ color = DEFAULT_ICON_COLOR, size = 18, className, style }) => (
  <svg
    width={WIDTH_RATIO * size}
    height={size}
    viewBox="0 0 448 417"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
    style={style}
  >
    <title>Chatbox</title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <g
        id="chatbox-outline"
        transform="translate(16.000000, 16.000000)"
        fill={color}
        stroke={color}
        strokeWidth="32"
      >
        <path
          d="M360,0 L56,0 C25.1085305,0.0878852257 0.0878852257,25.1085305 -2.84217094e-14,56 L-2.84217094e-14,248 C0.0878852257,278.89147 25.1085305,303.912115 56,304 L96,304 L96,384 L189.72,305.86 C191.15917,304.657944 192.97486,303.999623 194.85,304 L360,304 C390.89147,303.912115 415.912115,278.89147 416,248 L416,56 C415.912115,25.1085305 390.89147,0.0878852257 360,0 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);

Comment.propTypes = ICON_PROP_TYPES;

export default Comment;
