import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from 'components/Tooltip';

const TracklistTrigger = ({ tracklistCount, toggleTracklistVisibility }) => (
  <div className="Player__action">
    {tracklistCount < 3 ? (
      <Tooltip placement="top" content="No Tracklist Available">
        <span className="Player__action__trigger is-disabled">Tracklist</span>
      </Tooltip>
    ) : (
      <span
        className="Player__action__trigger"
        onClick={toggleTracklistVisibility}
        tabIndex="0"
        role="button"
      >
        Tracklist
      </span>
    )}
  </div>
);

TracklistTrigger.propTypes = {
  tracklistCount: PropTypes.number.isRequired
};

export default TracklistTrigger;
