import React, { ReactNode } from 'react';

import { ActionMenuHeader, TrackHeader } from 'components/ActionMenu';
import Well from 'components/Well';
import { EXTERNAL_LINKS } from 'config/constants';
import { Audio } from 'helpers/tracks';

interface TrackMenuHeaderProps {
  children?: ReactNode;
  track: Audio;
  showCompile: boolean;
  isPromo: boolean;
}

const TrackMenuHeader = ({
  children,
  track,
  showCompile,
  isPromo
}: TrackMenuHeaderProps) => (
  <ActionMenuHeader noUnderline={isPromo}>
    <TrackHeader {...track} />

    {children}

    {showCompile && track.no_compile && (
      <Well size={Well.SIZES.COMPACT} align="flex-start" className="mt-3">
        <p className="mb-1">
          This track is locked and requires permission to play in DJ mixes distributed to
          Spotify and Apple Music.
        </p>
        <a
          href={EXTERNAL_LINKS.INTERCOM.LOCKED_TRACKS}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </a>
      </Well>
    )}
  </ActionMenuHeader>
);

export default TrackMenuHeader;
