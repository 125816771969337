import React from 'react';

import { getPromoReleaseActions } from 'helpers/promos';

import ActionMenu, { ActionMenuToggleState } from 'components/ActionMenu/ActionMenu';
import ActionMenuList from 'components/ActionMenu/ActionMenuList';
import { Label, Release } from 'gql/graphql';

/**
 * Action menu for releases in user's Promo Inbox
 */

interface PromoReleaseActionMenuProps extends ActionMenuToggleState {
  label: Label;
  release: Release;
}

const PromoReleaseActionMenu = ({
  label,
  release,
  isOpen,
  close,
  closeOnNavigation
}: PromoReleaseActionMenuProps) => {
  const actions = getPromoReleaseActions(release, label);

  return (
    <>
      <ActionMenu isOpen={isOpen} close={close} closeOnNavigation={closeOnNavigation}>
        <ActionMenuList actions={actions} closeMenu={close} />
      </ActionMenu>
    </>
  );
};

export default PromoReleaseActionMenu;
