/* eslint-disable import/prefer-default-export */
import { searchConfig } from './getSearchResults';

// determine if displaying all search results per config settings in algolia
export const isSearchExhaustive = (searchCategory, resultCount) => {
  // Genres is not searched from algolia (it's always exhaustive)
  const config = searchConfig[searchCategory] || {};
  const resultLimit = config.resultCount;
  if (!resultLimit) return true;

  // for now we'll assume if =, then there were more search results to be displayed
  return resultCount >= resultLimit;
};
