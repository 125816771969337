import React from 'react';

import Alert, { AlertType } from '../Alert';

interface SoundCloudConnectErrorProps {
  type: AlertType;
  dismissAlert: () => void;
  artist: { name: string; id: number };
  connected_proton_artist_id: number;
}

export const SoundCloudProfileConnectError = ({
  dismissAlert,
  type,
  artist,
  connected_proton_artist_id
}: SoundCloudConnectErrorProps) => {
  const showIntercomChat = () => {
    // NOTE: formatting below is important for intercom to display message with linebreaks
    window.Intercom(
      'showNewMessage',
      `I'm trying to connect the SoundCloud profile for ${artist.name}, ` +
        `but I'm getting an error that it's already connected to artist ID: ${connected_proton_artist_id}.` +
        `

Can you help look into this for me? Thank you!`
    );
  };

  return (
    <>
      <Alert.Content data-testid="SoundCloudProfileConnectError">
        This SoundCloud profile is already connected to a different artist.{' '}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={showIntercomChat} role="button" tabIndex={0}>
          Contact our team for help?
        </a>
      </Alert.Content>
      <Alert.ButtonGroup>
        <Alert.DismissButton onClick={dismissAlert} type={type} />
      </Alert.ButtonGroup>
    </>
  );
};
