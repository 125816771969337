import { createReducer } from 'redux-act';
import { combineReducers } from 'redux';
import _ from 'lodash';

import { fetchReleaseTracksActions } from 'redux/actions/releases';
import { addTracks, removeTracks } from 'redux/actions/tracks';
import { garbageCollect } from 'redux/actions/utilities';

import { AUDIO_TYPES } from 'config/constants';

import trackInitialState from './initialState/trackInitialState';

// returns track with additional formatting
const _formatTrack = (track = trackInitialState) => ({
  ...track,
  type: AUDIO_TYPES.TRACK,
  lastFetched: Date.now()
});

// -- REDUCERS --
const byIdInitialState = {};

const addTracksToTracksById = (state, { tracks, data }) => {
  // might be called with success utility
  const _tracks = tracks || data;

  return _tracks.reduce(
    (tracksById, track) => ({
      ...tracksById,
      [track.id]: _formatTrack(track)
    }),
    { ...state }
  );
};

const byId = createReducer(
  {
    [addTracks]: addTracksToTracksById,
    [fetchReleaseTracksActions.success]: addTracksToTracksById,
    [removeTracks]: (state, { trackIds }) => _.omit(state, trackIds),
    [garbageCollect]: (state, { persistTrackIds }) => {
      // Clear all tracks except for those passed in by persistTrackIds array
      if (persistTrackIds && persistTrackIds.length > 0) {
        return persistTrackIds.reduce((tracksById, trackId) => {
          if (state[trackId]) {
            return {
              ...tracksById,
              [trackId]: state[trackId]
            };
          }
          return tracksById;
        }, byIdInitialState);
      }

      return byIdInitialState;
    }
  },
  byIdInitialState
);

export default combineReducers({ byId });
