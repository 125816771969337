import { calculateAverageColor } from 'helpers/color';
import { isMobile } from 'helpers/screen';
import { AUDIO_TYPES } from 'config/constants';

export const BAR_WIDTH = 2;
export const BAR_PADDING = 2;

const BAR_COLORS = {
  LIGHT_GRAY: '#888888',
  DARK_GRAY: '#666666',
  VERY_DARK_GRAY: '#333333',
  LIGHT_ORANGE: '#976F59',
  ORANGE: '#E26014'
};

/** [getFillColor()] – Function that returns a color hex code to draw to the canvas.
 * @param {number} index - Index within the frequency data array.
 * @param {object} dimensions - React ref to the width/height dimensions of the canvas.
 * @param {number} mousePosition - X position in pixels of the mouse relative to canvas left edge. Defaults to `null`.
 * @param {number} numberOfBars - Number of bars to draw in the waveform. Correlates to frequencyData array length.
 * @param {number} positionInSeconds - Current position of the song in seconds.
 * @param {number} lengthInSeconds - Length of currently playing song in seconds.
 */

/* Bar Color States
/  Mouse Hover Bar = Light Gray (#888888)
/  Played = Orange (#E26014)
/  Unavailable = Dark Gray (#666666)
/  Available = Light Orange (#976F59)...actually kinda brown but whatev.
*/

const getFillColor = (
  index,
  dimensions,
  mousePosition,
  numberOfBars,
  positionInSeconds,
  lengthInSeconds,
  isPreview,
  previewRatioRef,
  hasValidWaveformData
) => {
  const { width } = dimensions;

  const playerRatio = positionInSeconds / lengthInSeconds;

  const waveformRatio = index / numberOfBars;
  const stepProgress = playerRatio - waveformRatio;

  // If mix is over...
  if (positionInSeconds > lengthInSeconds) return BAR_COLORS.DARK_GRAY;

  if (
    isPreview &&
    (waveformRatio < previewRatioRef.current[0] ||
      waveformRatio > previewRatioRef.current[1])
  )
    return '#333333';

  // If the mix is within 1000ms of the next bar transition start fading it.
  if (stepProgress > 0 && stepProgress < 0.0005) {
    const color = calculateAverageColor('666666', 'E26014', stepProgress * 2000);
    return `#${color}`;
  }

  // If mix is partially complete, color bars to left of current position orange.
  if (playerRatio > waveformRatio) return BAR_COLORS.ORANGE;

  // If mouse is hovering over the waveform color bars to left of mouse position
  // with light orange.
  if (mousePosition / width > index / (numberOfBars + 1)) return BAR_COLORS.LIGHT_ORANGE;

  // Use a very dull tone when the waveform is a placeholder.
  if (!hasValidWaveformData) {
    return BAR_COLORS.VERY_DARK_GRAY;
  }

  // Default - this shouldn't ever actually get used.
  return BAR_COLORS.DARK_GRAY;
};

/** [drawCanvas()] – Function that draws waveform bars to canvas element.
 * @param {object} canvasContext - context from canvasRef.current.getContext('2d') in parent
 * @param {object} frequencyDataRef - React ref to the UInt8Array of frequency data from the web audio analyserNode.
 * @param {object} dimensionsRef - React ref to the width/height dimensions of the canvas.
 * @param {object} mousePositionRef - React ref to the position of the mouse relative to the left edge of the canvas. Defaults to `null`.
 * @param {object} numberOfBarsRef - React ref to the number of bars to draw in the waveform. Correlates to frequencyData array length.
 * @param {number} positionRefInSeconds - Current position of the song in seconds.
 * @param {object} playing - Object from Redux store containing all data of playing song (excluding position).
 */

export const drawCanvas = (
  canvasContext,
  frequencyDataRef,
  dimensionsRef,
  mousePositionRef,
  numberOfBarsRef,
  positionRefInSeconds,
  playing,
  isPreview,
  previewRatioRef,
  hasValidWaveformData
) => {
  const { width, height } = dimensionsRef;
  const data = frequencyDataRef.current;
  const mousePosition = mousePositionRef.current;
  const numberOfBars = numberOfBarsRef.current;
  const positionInSeconds = positionRefInSeconds.current;

  canvasContext.clearRect(0, 0, width, height);

  data.forEach((value, index) => {
    const halfHeight = height / 2;
    const posX = index * (BAR_WIDTH + BAR_PADDING);
    const posY = halfHeight - value;
    const drawPosition = positionInSeconds;

    const lengthInSeconds = playing.mix_length || playing.duration_seconds;

    canvasContext.fillStyle = getFillColor(
      index,
      dimensionsRef,
      mousePosition,
      numberOfBars,
      drawPosition,
      lengthInSeconds,
      isPreview,
      previewRatioRef,
      hasValidWaveformData
    );

    canvasContext.fillRect(posX, posY, BAR_WIDTH, value);
    canvasContext.fillRect(posX, halfHeight, BAR_WIDTH, value);

    // Draw rectangle where the mouse is hovering over the waveform bars.
    if (mousePosition && !isMobile()) {
      canvasContext.fillStyle = '#888888';
      canvasContext.fillRect(mousePosition, 0, 0.5, height);
    }
  });

  canvasContext.fill();
};

// This is a temporary hack since some mixes currently are missing their
// mix_length attribute due to encoding issues that will hopefully be
// addressed in the near future. For the time being this will pass a bool
// to the Timestamp to disable the ending time so it no longer renders as
// "NaN:NaN", and disables seeking entirely within the waveform.
export const hasDurationValue = playing => {
  if (!playing) return false;

  const { mix_length, type } = playing;
  const isMix = type === AUDIO_TYPES.MIX;

  // If not a mix we don't care!
  if (!isMix) return true;

  return isMix && mix_length;
};
