import PropTypes from 'prop-types';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';

import routeMap from 'config/routes';

// list of routes to override scroll to top
const omitRoutes = [routeMap.search];

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const currentPathname = this.props.location?.pathname;
    const prevPathname = prevProps.location?.pathname;

    if (
      currentPathname !== prevPathname &&
      !_get(this.props, 'location.state.modal') &&
      !_get(prevProps, 'location.state.modal')
    ) {
      const shouldScroll = !_get(this.props, 'location.state.noScroll');

      if (!omitRoutes.includes(currentPathname) && shouldScroll) {
        window.scrollTo(0, 0);
      }
    }
  }

  render() {
    return this.props.children;
  }
}

ScrollToTop.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape()
  })
};

export default withRouter(ScrollToTop);
