import { createSelector } from '@reduxjs/toolkit';

import {
  selectUser,
  makeSelectIsLabelManagerForLabelId,
  selectIsAdminUser,
  selectUserLabelProfiles
} from './user';
import { selectLabelById, selectAllLabels } from './labels';
import { PROMO_ACCESS } from 'config/constants';

/**
 * [selectHasPromoInboxAccess]
 * This selector should be used for logic related to whether a user can see the pages and content related
 * to the Inbox.
 *
 * Per @Yan, these requirements must be true for user.has_promo_access? to be true
 * 1) is pro user (has a user object not just a phpbbuser object)
 * 2) has user_label_subscriptions *OR* has a bio that has artist_label_subscriptions
 */

export const selectHasPromoInboxAccess = createSelector(
  [selectUser],
  user => !!user['has_promo_access?']
);

/**
 * [selectLabelHasPromoSubscriberAccess] - checks if label's promo_access attribute is limited or full access
 *
 * @param {number} labelId
 */

export const makeSelectLabelHasPromoSubscriberAccess = () =>
  createSelector([selectLabelById], label =>
    [PROMO_ACCESS.FULL, PROMO_ACCESS.LIMITED].includes(label.promo_access)
  );

export const selectLabelHasPromoSubscriberAccess =
  makeSelectLabelHasPromoSubscriberAccess();

/**
 * [selectLabelHasLimitedPromoAccess]
 *
 * @param {number} labelId
 */

export const makeSelectLabelHasLimitedPromoAccess = () => {
  const selectIsLabelManager = makeSelectIsLabelManagerForLabelId();

  return createSelector(
    [selectIsAdminUser, selectIsLabelManager, selectLabelById],
    (isAdmin, isLabelManager, label) => {
      // TODO: Remove... For debugging, in order to force limited access condition, uncomment line below
      // return true;

      return (isAdmin || isLabelManager) && label.promo_access === PROMO_ACCESS.LIMITED;
    }
  );
};

export const selectLabelHasLimitedPromoAccess = makeSelectLabelHasLimitedPromoAccess();

/**
 * [selectLabelHasFullPromoAccess] - ensures that for the label id passed, the label has "full access" to
 * the promo pool
 *
 * @param {number} labelId
 */

export const makeSelectLabelHasFullPromoAccess = () => {
  const selectIsLabelManager = makeSelectIsLabelManagerForLabelId();

  return createSelector(
    [selectIsAdminUser, selectIsLabelManager, selectLabelById],
    (isAdmin, isLabelManager, label) =>
      (isAdmin || isLabelManager) && label.promo_access === PROMO_ACCESS.FULL
  );
};

export const selectLabelHasFullPromoAccess = makeSelectLabelHasLimitedPromoAccess();

/**
 * [selectAllUserLabelsHaveLimitedPromoAccess]
 *
 * @returns {bool} true if all of users managed labels have either limited or full access
 * to promo pool
 */

export const selectAllUserLabelsHaveLimitedPromoAccess = createSelector(
  [selectUserLabelProfiles, selectAllLabels],
  (labelProfiles, labelsById) =>
    labelProfiles.reduce((accum, { id }) => {
      // TODO: Remove... For debugging, in order to force limited access condition, uncomment line below
      // return true;

      // all labels must have limited/full access for this to return true
      if (!accum) return false;

      const label = labelsById[id];
      if (!label) return false;

      return [PROMO_ACCESS.FULL, PROMO_ACCESS.LIMITED].includes(label.promo_access);
    }, true)
);
