import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import PlayerButton from '../../PlayerButton';
import { highlightMatchedField } from './helpers';

const _formatOrder = order => {
  if (order < 10) {
    return `0${order}`;
  }
  return order;
};

const MAX_DISPLAY_TRACKS = 2;

const TracklistSearchResult = ({ mix, tracks }) => (
  <div className="UniversalSearchResultRow">
    <div className="UniversalSearchResultRow__icon">
      {/* TODO: Fix player button size */}
      <PlayerButton audio={mix} classes="PlayerButton--light" />
    </div>
    <div className="UniversalSearchResultRow__info">
      <div>
        <div className="UniversalSearchResultRow__info__title">
          <Link to={mix.artist_slug}>{mix.artist_name}</Link>
        </div>

        <div className="UniversalSearchResultRow__info__subtitle">
          <Link to={mix.show_slug}>
            {mix.show_name}
            <span className="UniversalSearchResultRow__mix__date">
              ({mix.episode_date})
            </span>
          </Link>
        </div>

        {tracks &&
          tracks.map((track, k) => {
            // Show max of two tracks followed by ellipses
            if (k === MAX_DISPLAY_TRACKS) {
              return (
                <div
                  key={`track-elipsis-${mix.id}`}
                  className="UniversalSearchResultRow__info__text"
                >
                  ...
                </div>
              );
            }

            if (k > MAX_DISPLAY_TRACKS) return null;

            return (
              <div
                key={`${mix.id}-${track.key}-${track.order}`}
                className="UniversalSearchResultRow__info__text"
              >
                <span className="track__order">{_formatOrder(track.order)}</span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: highlightMatchedField('artist', track)
                  }}
                />

                <span> - </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: highlightMatchedField('track', track)
                  }}
                />

                {track.label && (
                  <Fragment>
                    <span> - </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: highlightMatchedField('label', track)
                      }}
                    />
                  </Fragment>
                )}
              </div>
            );
          })}
      </div>
    </div>
  </div>
);

TracklistSearchResult.propTypes = {
  mix: PropTypes.shape({
    id: PropTypes.number,
    artist_name: PropTypes.string,
    show_name: PropTypes.string,
    episode_date: PropTypes.string
  }),
  tracks: PropTypes.arrayOf(
    PropTypes.shape({
      track: PropTypes.string,
      order: PropTypes.number,
      artist: PropTypes.string,
      label: PropTypes.string,
      matched: PropTypes.shape({
        track: PropTypes.string,
        artist: PropTypes.string,
        label: PropTypes.string
      })
    })
  )
};

export default TracklistSearchResult;
