import PropTypes from 'prop-types';
import React from 'react';
import { DEFAULT_ICON_COLOR, ICON_PROP_TYPES } from './config';

const Chevron = props => {
  const { style, direction = Chevron.DIRECTIONS.LEFT } = props;

  switch (direction) {
    case Chevron.DIRECTIONS.LEFT:
      return <Chevron.LEFT {...props} style={style} />;

    case Chevron.DIRECTIONS.RIGHT:
      return (
        <Chevron.LEFT {...props} style={{ transform: 'rotate(180deg)', ...style }} />
      );

    case Chevron.DIRECTIONS.UP:
      return <Chevron.LEFT {...props} style={{ transform: 'rotate(90deg)', ...style }} />;

    case Chevron.DIRECTIONS.DOWN:
      return (
        <Chevron.LEFT {...props} style={{ transform: 'rotate(270deg)', ...style }} />
      );

    default:
      return null;
  }
};

Chevron.LEFT = ({
  style,
  className,
  size = 16,
  color = DEFAULT_ICON_COLOR,
  direction
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 512 512"
    style={style}
    width={size}
  >
    <title>Chevron {direction}</title>
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="48"
      d="M328 112L184 256l144 144"
    />
  </svg>
);

Chevron.LEFT.propTypes = ICON_PROP_TYPES;

Chevron.DIRECTIONS = {
  UP: 'up',
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right'
};

Chevron.propTypes = {
  ...ICON_PROP_TYPES,
  direction: PropTypes.string
};

export default Chevron;
