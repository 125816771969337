import React from 'react';
import _capitalize from 'lodash/capitalize';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';

import { toggleSearchResultsDisplay } from 'redux/actions/search';
import { isSearchExhaustive } from 'redux/actions/search/helpers';
import { SEARCH_TYPES } from 'config/constants';

import TextHeader from 'components/TextHeader';
import UniversalSearchResultRow from 'components/UniversalSearchResultRow';
import searchStartIcon from 'images/icon-search-start.svg';
import { getOrderedResultCategories } from './helpers';
import { Badge } from '@protonradio/proton-ui';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

const areResultsHidden = (results, maxResultsPerCategory, categories) => {
  if (!maxResultsPerCategory) return false;
  let resultsHidden = false;

  categories.forEach(type => {
    const resultTypeCount = results[type].length;
    if (maxResultsPerCategory && resultTypeCount > maxResultsPerCategory) {
      resultsHidden = true;
    }
  });
  return resultsHidden;
};

type SearchState = 'SHOW_RESULTS' | 'NO_RESULTS' | null;

interface UniversalSearchResultsProps {
  className?: string;
  maxResultsPerCategory: number;
}

export const UniversalSearchResults = ({
  className = '',
  maxResultsPerCategory
}: UniversalSearchResultsProps) => {
  const dispatch = useAppDispatch();
  let state: SearchState = null;
  const user = useAppSelector(state => state.user);
  const {
    query: searchQuery,
    count: resultCount,
    results,
    isFetching
  } = useAppSelector(state => state.search);

  if (searchQuery && resultCount === 0 && !isFetching) {
    state = 'NO_RESULTS';
  } else if (resultCount > 0) {
    state = 'SHOW_RESULTS';
  }

  const resultCategories = getOrderedResultCategories(user);

  switch (state) {
    case 'SHOW_RESULTS':
      return (
        <div className={`UniversalSearchResults__container ${className}`}>
          <div className="UniversalSearchResults__results">
            {resultCategories.map(type => {
              const resultTypeCount = results[type].length;
              if (resultTypeCount === 0) return null;

              const classes = `${type} section section--small`;
              const typeTitle = SEARCH_TYPES[type] || _capitalize(type);

              const isExhaustive = isSearchExhaustive(type, resultTypeCount);
              const badgeText = isExhaustive ? `${resultTypeCount}+` : resultTypeCount;

              return (
                <div key={type}>
                  <Element name={type} className={classes}>
                    <div className="UniversalSearchResults__results__label">
                      <TextHeader type={TextHeader.TYPES.LARGE} className="mb-0 mr-2">
                        {typeTitle}
                      </TextHeader>
                      <Badge>{badgeText}</Badge>
                    </div>

                    <div className={`UniversalSearchResults__results__content ${type}`}>
                      {results[type].map((result, i) => {
                        if (maxResultsPerCategory && i >= maxResultsPerCategory)
                          return null;

                        return (
                          <UniversalSearchResultRow
                            key={`${type}-result-row-${i}`}
                            className={`js-${type}-result-row`}
                            searchQuery={searchQuery}
                            result={result}
                            hideSearchResults={() =>
                              dispatch(toggleSearchResultsDisplay(false, false))
                            }
                          />
                        );
                      })}
                    </div>

                    {resultTypeCount > maxResultsPerCategory && (
                      <Link
                        to={{
                          pathname: '/search',
                          hash: `#${type}`
                        }}
                        onClick={() => dispatch(toggleSearchResultsDisplay(false, true))}
                      >
                        <div className="UniversalSearchResults__show-more js-show-more">
                          More {_capitalize(type)}
                        </div>
                      </Link>
                    )}
                  </Element>
                </div>
              );
            })}
          </div>
          {areResultsHidden(results, maxResultsPerCategory, resultCategories) && (
            <Link
              to="/search"
              onClick={() => dispatch(toggleSearchResultsDisplay(false, true))}
            >
              <div className="UniversalSearchResults__see-all">See All Results</div>
            </Link>
          )}
        </div>
      );

    case 'NO_RESULTS':
      return (
        <div className={`UniversalSearchResults__container ${className}`}>
          <div className="UniversalSearchResults__get-started">
            <div>We couldn&apos;t find any results</div>
          </div>
        </div>
      );

    default:
      return (
        <div className={`UniversalSearchResults__container ${className}`}>
          <div className="UniversalSearchResults__get-started">
            <div className="UniversalSearchResults__get-started__inner">
              <div className="UniversalSearchResults__get-started__avatar">
                <img src={searchStartIcon} alt="" />
              </div>
              <div>{isFetching ? 'Searching...' : 'What are you looking for?'}</div>
            </div>
          </div>
        </div>
      );
  }
};

export default UniversalSearchResults;
