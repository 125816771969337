import {
  ActionCreatorWithPreparedPayload,
  PrepareAction,
  createAction
} from '@reduxjs/toolkit';

const prepareAction = <P>(payload: P, meta: unknown) =>
  meta ? { payload, meta } : { payload };

interface AsyncActionCreatorApi<
  P,
  RP = P,
  EP = P,
  SP = P,
  T extends `${string}_${string}` = `${string}_${string}`
> {
  call: ActionCreatorWithPreparedPayload<unknown[], P, T, unknown, unknown>;
  request: ActionCreatorWithPreparedPayload<
    unknown[],
    RP,
    `${T}_REQUEST`,
    unknown,
    unknown
  >;
  error: ActionCreatorWithPreparedPayload<unknown[], EP, `${T}_ERROR`, unknown, unknown>;
  success: ActionCreatorWithPreparedPayload<
    unknown[],
    SP,
    `${T}_SUCCESS`,
    unknown,
    unknown
  >;
}

/**
 * Helper used for consistent generation of async redux actions to use for various crud-like actions.
 * Each returned action creator takes the payload as the 1st argument, and an optional meta argument as the second.
 * Meta argument can be used for control logic used by sagas/reducers, but is unrelated to the payload.
 *
 * @param {string} entityName - Name of the entity being acted upon (i.e. 'ARTIST', 'PROMO_TRACK', etc)
 * @param {string} operationName - operation being performed (i.e. CREATE, FETCH, etc... see ASYNC_OPERATIONS)
 * Try to use from ASYNC_OPERATIONS, but can pass a custom operation name if it adds clarity
 *
 * The following action creators are exposed:
 *  - `call`: in some cases you might not handle every call to fetch or update. For example,
 *  if you have a saga that debounces or takes the latest request
 *  - `request`: use to initialize state for when request is pending (on request send)
 *  - `error`: use to handle async failure
 *  - `success`: use to handle async success
 *
 * @example
 * const fetchSomeStuff = createAsyncActions('STUFF', ASYNC_OPERATIONS.FETCH);
 * store.dispatch(fetchSomeStuff.request({ id: 123 }, { random: 'you can put meta data here' }))
 */

export function createAsyncActions(
  entityName: string,
  operationName: string
): AsyncActionCreatorApi<unknown>;
export function createAsyncActions<P>(
  entityName: string,
  operationName: string
): AsyncActionCreatorApi<P>;
export function createAsyncActions<P, RP>(
  entityName: string,
  operationName: string
): AsyncActionCreatorApi<P, RP>;
export function createAsyncActions<P, RP, EP>(
  entityName: string,
  operationName: string
): AsyncActionCreatorApi<P, RP, EP>;
export function createAsyncActions<P, RP, EP, SP>(
  entityName: string,
  operationName: string
): AsyncActionCreatorApi<P, RP, EP, SP>;
export function createAsyncActions<P = unknown, RP = P, EP = P, SP = P>(
  entityName: string,
  operationName: string
) {
  if (!operationName) {
    throw 'Action operation name is required.';
  }

  return {
    call: createAction<PrepareAction<P>>(`${entityName}_${operationName}`, prepareAction),
    request: createAction<PrepareAction<RP>>(
      `${entityName}_${operationName}_REQUEST`,
      prepareAction
    ),
    error: createAction<PrepareAction<EP>>(
      `${entityName}_${operationName}_ERROR`,
      prepareAction
    ),
    success: createAction<PrepareAction<SP>>(
      `${entityName}_${operationName}_SUCCESS`,
      prepareAction
    )
  };
}
