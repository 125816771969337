/* eslint-disable import/prefer-default-export */

// Global window/document configurations. Needs to load before vendor js
export const paceOptions = {
  target: '.js__pace',
  restartOnPushState: false,
  ajax: {
    trackWebSockets: false, // wasn't working with pusher-js channel
    trackMethods: ['GET', 'POST', 'DELETE', 'PUT', 'PATCH'],
    ignoreURLs: [
      /now_playing/,
      /listener_count/,
      /websocket/,
      /pusher/,
      /appspot\.com\/(stream|single)/
    ]
  }
};
