import { createSelector } from '@reduxjs/toolkit';

export const getMixes = state => state.mixes || {};
export const getMixById = (state, id) => state.mixes.byId[id] || {};

export const getTopMixIds = (state, limit) => {
  const { topMixIds } = state.mixes;
  return limit ? topMixIds.data.slice(0, limit) : topMixIds.data;
};

export const getTopMixes = createSelector(
  [getMixes, getTopMixIds],
  (mixes, topMixIds) => {
    return topMixIds.map(id => mixes.byId[id]).filter(mix => !!mix);
  }
);
