import React from 'react';

import DefaultLogoImage from 'images/icon-track-cover.svg';
import Avatar, { AvatarProps } from '../Avatar';

interface LabelAvatarProps extends AvatarProps {
  name?: string;
}

const LabelAvatar = ({
  imageUrl,
  name,
  lazy,
  loading,
  altTag = 'Label Avatar',
  ...rest
}: LabelAvatarProps) => {
  const avatarUrl: string = imageUrl ? encodeURI(imageUrl) : (DefaultLogoImage as string);

  return (
    <Avatar
      imageUrl={avatarUrl}
      altTag={altTag}
      title={name}
      lazy={lazy}
      loading={loading}
      {...rest}
    />
  );
};

export default LabelAvatar;
