import { createReducer } from '@reduxjs/toolkit';

import {
  setUser,
  setUserSettings,
  clearUser,
  fetchingUser,
  replaceUser
} from 'redux/actions/user';

import { garbageCollect } from 'redux/actions/utilities';
import { MaybeEmpty, PrivacyPolicyState, V2User } from 'types';

interface FetchingState {
  // fetching state:
  isFetching: boolean;
  lastFetched: number | null;
}

type UserState = Omit<MaybeEmpty<V2User>, 'privacy_policy_consents'> &
  PrivacyPolicyState &
  FetchingState;

const userInitState: UserState = {
  id: null,
  releases_on: [],
  verified: false,
  claimed: false,
  has_promo_access: false,
  pro_user_details_confirmed: false,
  account_type: null,
  activated: false,
  accesslevel: null,
  address: '',
  authentication_token: '',
  country: undefined,
  dropbox_oauth: undefined,
  google_oauth: false,
  email: undefined,
  first_name: undefined,
  last_name: undefined,
  paypal_email: undefined,
  privacy_policy_consents: [],
  profiles: [],
  proton_newsletter_subscription: false,
  soundcloud_auths: [],
  soundcloud_profiles: [],
  // subscriber: {},
  user_email: '',
  user_id: null,
  user_settings: [],
  username: '',
  // TODO: these flags need to be converted over to the newer format used in our reducer utilities
  isFetching: false,
  lastFetched: null
};

export default createReducer<UserState>(userInitState, builder => {
  builder
    .addCase(fetchingUser, state => ({
      ...state,
      isFetching: true
    }))
    .addCase(setUser, (state, { payload }) => ({
      ...state,
      ...payload,
      isFetching: false,
      lastFetched: Date.now()
    }))
    .addCase(replaceUser, (_, { payload }) => ({
      ...payload,
      isFetching: false,
      lastFetched: Date.now()
    }))
    .addCase(setUserSettings, (state, { payload: settings }) => {
      const dedupedSettings = [settings, state.user_settings]
        .flat()
        .filter((item, index, self) => {
          return index === self.findIndex(t => t.setting === item.setting);
        });

      return {
        ...state,
        user_settings: dedupedSettings
      };
    })
    .addCase(clearUser, state => {
      const PRIVACY_POLICY_VERSION = process.env.REACT_APP_PRIVACY_POLICY_VERSION;
      const consentMatch = state.privacy_policy_consents.filter(
        item => item.version === PRIVACY_POLICY_VERSION
      );

      if (consentMatch.length) {
        return {
          ...userInitState,
          privacy_policy_consents: [...consentMatch]
        };
      }

      return {
        ...userInitState
      };
    })
    .addCase(garbageCollect, state => ({
      ...state,
      isFetching: false
    }));
});
