import { getIn } from 'formik';
import _ from 'lodash';
import { Schema, SchemaObjectDescription } from 'yup';
import { arrayToCommaList } from './text';
/**
 * [formatFieldErrors] - handles formatting error messages keyed by field name commonly returned by the api
 *
 * @param {object} fieldErrors (see example below)
 * {
 *   first_name: ['is too long'],
 *   email: ['has already been taken', 'is not allowed']
 * }
 * @param {object} [modifiers] key/value pairing to rename field errors (see example below):
 * In this example, any errors returned by the api as 'user_email' will be returned as errors for 'email'
 * { 'user_email': 'email' }
 * @returns {object} formatted version of api response
 * {
 *   first_name: 'First name is too long',
 *   email: 'Email has already been taken & is not allowed'
 * }
 */

export type FieldErrors = Record<string, string[]>;
type Modifiers = Record<string, string>;
type FormattedFieldErrors = Record<string, string>;

export const formatFieldErrors = (
  fieldErrors: FieldErrors,
  modifiers?: Modifiers
): FormattedFieldErrors => {
  const modifierObj = modifiers || {};

  return Object.keys(fieldErrors).reduce((accum, field) => {
    const errors = fieldErrors[field];
    const fieldName = modifierObj[field] || field;
    const fieldFormatted = _.upperFirst(_.lowerCase(fieldName));

    return {
      ...accum,
      [fieldName]: `${fieldFormatted} ${arrayToCommaList(errors, { ampersand: true })}`
    };
  }, {});
};

/**
 * [isRequiredField] - function that checks if required validation exists with yup validation schema for
 * the given field
 */

// // TODO: Not sure why this is not working with just Schema. Something to do with being and object schema
export interface ValidationSchema extends Schema {
  describe: (options?: unknown) => SchemaObjectDescription;
}

export const isRequiredField = (
  fieldName: string,
  validationSchema: ValidationSchema
) => {
  const { fields } = validationSchema.describe();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { tests: validations } = getIn(fields, fieldName) || { tests: [] }; // formik types this return value as `any` 😢
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  return !!validations.find(({ name }) => name === 'required');
};

/**
 * [getRequiredFieldState] - for use with Formik controlled fields to determine whether the field is required based
 * on the validation schema, and whether or not we want to display the "required" indicator
 */

type GetRequiredFieldState = {
  fieldName: string;
  required?: boolean;
  showRequiredFields?: boolean;
  validationSchema?: ValidationSchema;
};

export const getRequiredFieldState = ({
  fieldName,
  required,
  showRequiredFields,
  validationSchema
}: GetRequiredFieldState) => {
  if (!showRequiredFields) return false;
  if (typeof required === 'boolean') return required;
  if (validationSchema) return isRequiredField(fieldName, validationSchema);

  return false;
};
