import React from 'react';

import { DEFAULT_ICON_COLOR, ICON_PROP_TYPES } from './config';

const PayPalLogo = ({ color = DEFAULT_ICON_COLOR, size = 18, className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    height={size}
    style={style}
    viewBox="0 0 16 19"
  >
    <title>PayPal Logo</title>

    <g id="Account-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-34.000000, -253.000000)" fillRule="nonzero">
        <g id="PayPal-Logo.wine" transform="translate(34.000000, 253.000000)">
          <path
            d="M4.69821063,18 L5.00992121,15.9923928 L4.31557546,15.9760757 L1,15.9760757 L3.30415508,1.16196499 C3.31833308,1.06841116 3.39795494,0.999503623 3.49130063,1 L9.08182693,1 C10.9377863,1 12.2186009,1.39161301 12.8873184,2.16455992 C13.200817,2.52716207 13.4004787,2.90608131 13.4970315,3.32307378 C13.5983523,3.7606137 13.6001404,4.28336513 13.5012035,4.92094057 L13.4940515,4.96747451 L13.4940515,5.37600627 L13.8075501,5.55609867 C14.0468801,5.679045 14.2620042,5.84535747 14.4422953,6.04682024 C14.7104975,6.35684507 14.883935,6.75087274 14.9572436,7.21802517 C15.0329362,7.69847302 15.007904,8.27017573 14.883935,8.91742056 C14.7408938,9.66196364 14.5096439,10.3104171 14.1973373,10.841025 C13.9217851,11.316364 13.5512941,11.7280574 13.1096283,12.0496988 C12.6948089,12.3482412 12.2019128,12.5748675 11.6446482,12.7199084 C11.1046677,12.8625319 10.4889946,12.934448 9.81372099,12.934448 L9.37863739,12.934448 C9.06752281,12.934448 8.76534831,13.0480634 8.52813835,13.2517249 C8.2912647,13.4575595 8.13412814,13.7419609 8.08471067,14.0542843 L8.0519304,14.234981 L7.50122184,17.7733737 L7.47618964,17.9033061 C7.46963358,17.944401 7.45830949,17.9649485 7.44162135,17.9788482 C7.42545527,17.9922538 7.40528663,17.9997097 7.38440487,18 L4.69821063,18 Z"
            id="Path"
            fill="#253B80"
          />
          <path
            d="M14.5655055,5 C14.5496194,5.1113283 14.5314638,5.22514438 14.5110388,5.34207019 C13.8092135,9.29204798 11.4081427,10.6565971 8.34155759,10.6565971 L6.7801807,10.6565971 C6.40515521,10.6565971 6.08913525,10.9551311 6.03069709,11.3606397 L5.23128574,16.9183474 L5.00490878,18.4937361 C4.98665879,18.6202006 5.0198197,18.7490853 5.09566117,18.8464585 C5.17150264,18.9438317 5.28242466,19 5.39922453,19 L8.16851255,19 C8.49644708,19 8.77502123,18.7387828 8.82665106,18.3842737 L8.85388438,18.2300311 L9.37528895,14.6028432 L9.40876324,14.4038205 C9.45982571,14.0480675 9.73896722,13.7868503 10.0669018,13.7868503 L10.4810751,13.7868503 C13.1641243,13.7868503 15.2644939,12.5927143 15.8783783,9.13718347 C16.1348254,7.69364727 16.002063,6.4883163 15.3234995,5.64060418 C15.1084301,5.37836885 14.8515702,5.16128854 14.5655055,5 L14.5655055,5 Z"
            id="Path"
            fill="#179BD7"
          />
          <path
            d="M13.2258193,4.27803659 C12.9895126,4.20654413 12.749121,4.15074501 12.5060826,4.110973 C12.0258512,4.03378702 11.5405015,3.9967531 11.0546434,4.00022297 L6.65605265,4.00022297 C6.31017087,4 6.01576212,4.26348058 5.96264043,4.62092368 L5.02692282,10.8191709 L5,11 C5.06023362,10.5922864 5.39586296,10.2914945 5.79033437,10.2917004 L7.43681447,10.2917004 C10.6705444,10.2917004 13.2024862,8.91827495 13.9425646,4.94503957 C13.9647012,4.82740678 13.983248,4.71290251 14,4.60090107 C13.804711,4.49379803 13.6011795,4.40400753 13.3915442,4.33247305 C13.3365846,4.31340142 13.2813359,4.29525359 13.2258193,4.27803659 L13.2258193,4.27803659 Z"
            id="Path"
            fill="#222D65"
          />
          <path
            d="M5.80550586,4.59127842 C5.85867522,4.25045009 6.15619057,3.99922724 6.50538525,4.00029817 L10.9449998,4.00029817 C11.4709661,4.00029817 11.9619082,4.03428997 12.409976,4.10585166 C12.7131811,4.15291077 13.0121991,4.2232952 13.3042999,4.31636228 C13.5247105,4.38852031 13.7294207,4.47379799 13.9184304,4.5721953 C14.1406527,3.17256802 13.9166188,2.21960492 13.1503143,1.35669027 C12.3055074,0.406708908 10.7807485,0 8.82966114,0 L3.16540863,0 C2.76685782,0 2.42688189,0.286246739 2.36528768,0.675661573 L0.00598761526,15.4442042 C-0.0162517826,15.5829667 0.0240972168,15.7243919 0.116420778,15.8312779 C0.208744338,15.9381639 0.343799607,15.99981 0.48606019,16 L3.98304167,16 L4.8610612,10.4986955 L5.80550586,4.59127842 Z"
            id="Path"
            fill="#253B80"
          />
        </g>
      </g>
    </g>
  </svg>
);

PayPalLogo.propTypes = ICON_PROP_TYPES;

export default PayPalLogo;
