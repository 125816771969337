import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setTouchDevice } from 'redux/actions/ui';

function useDetectTouchDevice() {
  const dispatch = useDispatch();

  // ////////// Set class 'touch-enabled' for touch supported devices
  // REF: https://codeburst.io/the-only-way-to-detect-touch-with-javascript-7791a3346685
  useEffect(() => {
    const _onFirstTouch = () => {
      dispatch(setTouchDevice());
      document.body.classList.add('touch-enabled');

      // we only need to know once that a human touched the screen, so we can stop listening now
      window.removeEventListener('touchstart', _onFirstTouch, false);
    };

    window.addEventListener('touchstart', _onFirstTouch);

    return () => {
      window.removeEventListener('touchstart', _onFirstTouch);
    };
  }, [dispatch]);
}

export default useDetectTouchDevice;
