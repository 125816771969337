import Button, { ButtonGroup } from 'components/Button';
import { LinkIcon } from 'components/Icons';
import { COLORS } from 'config/constants';
import { FragmentType, getFragmentData, graphql } from 'gql';
import { logFeatureMetric } from 'helpers/logMetric';
import { Location } from 'history';
import useReleaseLinksSharer from 'hooks/useReleaseLinksSharer';
import React from 'react';
import styled from 'styled-components';

const FooterText = styled.div`
  font-size: 14px;
  color: grey;
  margin-bottom: 10px;
`;

const MobileButtons_ReleaseFragment = graphql(`
  fragment MobileButtonsReleaseFields on Release {
    id
    date
    label {
      name
      slug
    }
  }
`);

interface ReleaseLinksButtonsProps {
  release: FragmentType<typeof MobileButtons_ReleaseFragment> | null | undefined;
  shareLocation?: Partial<Location<{ modal?: boolean; noScroll?: boolean }>>;
}

const ReleaseLinksButtonsMobile = (props: ReleaseLinksButtonsProps) => {
  const { generateReleaseLink, fetching } = useReleaseLinksSharer();
  const release = getFragmentData(MobileButtons_ReleaseFragment, props.release);
  if (!release || !release.date || !release.label) return null;

  const { id, date, label } = release;

  const buttonText =
    Date.parse(date) > Date.now() ? 'Get Notified on Release Day' : 'Download • Stream';

  const footerText =
    Date.parse(date) > Date.now()
      ? `Coming Soon on ${new Date(date).toLocaleDateString('en-us', {
          month: 'short',
          day: 'numeric',
          timeZone: 'UTC'
        })}, from ${label.name}`
      : `Released ${new Date(date).toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          timeZone: 'UTC'
        })}, on ${label.name}`;

  return (
    <div>
      <ButtonGroup className="mt-0">
        <Button
          data-testid={ReleaseLinksButtonsMobile.TEST_IDS.VISIT_RELEASE_LINK_BTN}
          disabled={fetching}
          onClick={async () => {
            logFeatureMetric('release_link', 'view.mobile');
            await generateReleaseLink(release.id).then(({ url, target }) => {
              window.open(url, target);
            });
          }}
        >
          {buttonText}
        </Button>
        <Button
          to={props.shareLocation}
          data-testid={ReleaseLinksButtonsMobile.TEST_IDS.SHARE_RELEASE_LINK_BTN}
        >
          <LinkIcon color={COLORS.WHITE} size={18} className="mr-1" />
          Share
        </Button>
      </ButtonGroup>
      <FooterText>{footerText}</FooterText>
    </div>
  );
};

ReleaseLinksButtonsMobile.TEST_IDS = {
  VISIT_RELEASE_LINK_BTN: 'releaselinks-buttons-visit-release-link-btn',
  SHARE_RELEASE_LINK_BTN: 'releaselinks-buttons-share-release-link-btn'
};

export default ReleaseLinksButtonsMobile;
