import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { COLORS } from 'config/constants';
import { generateSelectorsFromTestIds } from 'helpers/utilities';
import useBreakpoint from 'hooks/useBreakpoint';

import { EllipsisButton } from 'components/Button';
import Card from 'components/Card';
import CommaList from 'components/CommaList';
import Icon from 'components/Icon';
import { TombstoneText } from 'components/Tombstone';

import FeedbackCardHeader from './FeedbackCardHeader';
import FeedbackMenuToggler from './FeedbackMenuToggler';
import { getTrackOriginalArtists } from './helpers';

const FeedbackComment = styled.div``;

const FeedbackFooter = styled.div`
  color: ${COLORS.GRAY};
  display: flex;
  font-size: 1.1rem;
  justify-content: space-between;
  margin-top: 1.1rem;
`;

const ToggleContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const FeedbackDate = styled.div`
  margin-left: 1rem;
  min-width: 6rem;
`;

/**
 * [FeedbackCard]
 * Component for displaying track feedback from artists
 *
 * @param {object} artist artist leaving the feedback
 * @param {object} user user/member of the artist leaving feedback
 * @param {object} track
 * Reaction specific props:
 * @param {number} rating
 * @param {string} comment
 * @param {string} reacted_at
 */

const FeedbackCard = props => {
  const { id, track, comment, reacted_at } = props;

  const isSmallScreen = useBreakpoint(
    useBreakpoint.BREAKPOINTS.SMALL,
    useBreakpoint.DIRECTIONS.DOWN
  );

  const ogTrackArtists = getTrackOriginalArtists(track);

  const TrackTitleContent = (
    <>
      <Icon
        type={Icon.TYPES.MUSIC_NOTE}
        color={Icon.COLORS.DARK}
        width={8}
        style={{ marginRight: '0.4rem' }}
      />
      <CommaList data={ogTrackArtists} attribute="name" />
      {' - '}
      <span>
        {track.title}
        {track.version && ` (${track.version})`}
      </span>
    </>
  );

  return (
    <FeedbackMenuToggler {...props}>
      {({ toggle }) => (
        <Card
          onClick={isSmallScreen ? toggle : null}
          data-testid={FeedbackCard.TEST_IDS.CARD}
          data-selector={`FeedbackCard--${id}`}
        >
          <Card.Content>
            <FeedbackCardHeader {...props} linkToArtist={!isSmallScreen}>
              <ToggleContainer>
                <EllipsisButton
                  onClick={isSmallScreen ? null : toggle}
                  data-testid={FeedbackCard.TEST_IDS.TOGGLE}
                />
              </ToggleContainer>
            </FeedbackCardHeader>

            <FeedbackComment data-testid={FeedbackCard.TEST_IDS.COMMENT}>
              {comment}
            </FeedbackComment>

            <FeedbackFooter data-testid={FeedbackCard.TEST_IDS.FOOTER}>
              <div>{TrackTitleContent}</div>
              <FeedbackDate>{format(new Date(reacted_at), 'MMM d, yyyy')}</FeedbackDate>
            </FeedbackFooter>
          </Card.Content>
        </Card>
      )}
    </FeedbackMenuToggler>
  );
};

FeedbackCard.TEST_IDS = {
  CARD: 'FeedbackCard',
  TITLE: FeedbackCardHeader.TEST_IDS.TITLE,
  SUBTITLE: FeedbackCardHeader.TEST_IDS.SUBTITLE,
  COMMENT: 'FeedbackCard--comment',
  FOOTER: 'FeedbackCard--footer',
  TOGGLE: 'FeedbackCard--toggle'
};

FeedbackCard.SELECTORS = generateSelectorsFromTestIds(FeedbackCard.TEST_IDS);
FeedbackCard.SELECTORS.getSelectorForId = id => `[data-selector=FeedbackCard--${id}]`;

FeedbackCard.propTypes = {
  id: PropTypes.number,
  artist: PropTypes.shape({
    name: PropTypes.string,
    image_url: PropTypes.string,
    slug: PropTypes.string
  }),
  user: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string
  }),
  track: PropTypes.shape({
    title: PropTypes.string,
    version: PropTypes.string,
    slug: PropTypes.string,
    formatted_artists: PropTypes.string,
    artists: PropTypes.arrayOf(
      PropTypes.shape({
        role_id: PropTypes.number,
        position: PropTypes.number,
        name: PropTypes.string
      })
    )
  }),
  rating: PropTypes.number,
  comment: PropTypes.string,
  reacted_at: PropTypes.string
};

const isEven = n => n % 2 === 0;

const TombstoneCard = styled(Card)`
  ${({ $invisible }) =>
    $invisible &&
    `
    visibility: hidden;
  `}
`;

// eslint-disable-next-line react/display-name
FeedbackCard.Tombstone = ({ index, invisible }) => (
  <TombstoneCard $invisible={invisible}>
    <Card.Content>
      {/* <FeedbackCardHeader.Tombstone /> */}

      <FeedbackComment>
        <TombstoneText
          size={TombstoneText.SIZES.MEDIUM}
          // Unset max-width
          style={{ maxWidth: 'initial' }}
        />

        {/* If index is passed, alternate lines of text to appear more random */}
        {index && isEven(index) ? (
          <>
            <TombstoneText
              size={TombstoneText.SIZES.MEDIUM}
              // Unset max-width
              style={{ maxWidth: 'initial', marginTop: '0.3rem' }}
            />
            <TombstoneText
              size={TombstoneText.SIZES.MEDIUM}
              style={{ marginTop: '0.3rem', width: '70%' }}
            />
          </>
        ) : (
          <TombstoneText
            size={TombstoneText.SIZES.MEDIUM}
            style={{ marginTop: '0.3rem', width: '70%' }}
          />
        )}
      </FeedbackComment>

      <FeedbackFooter>
        <div>
          <TombstoneText size={TombstoneText.SIZES.SMALL} style={{ width: '8rem' }} />
        </div>

        <div>
          <TombstoneText
            size={TombstoneText.SIZES.SMALL}
            color={TombstoneText.COLORS.LIGHT}
            style={{ width: '5rem' }}
          />
        </div>
      </FeedbackFooter>
    </Card.Content>
  </TombstoneCard>
);

FeedbackCard.Tombstone.propTypes = {
  index: PropTypes.number
};

export default FeedbackCard;
