import React, { ReactNode } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import ReactSelect, {
  StylesConfig,
  OptionProps,
  CSSObjectWithLabel,
  GroupBase
} from 'react-select';
import classNames from 'classnames';
import { lighten } from 'polished';

import { COLORS } from 'config/constants';
import InputGroup from 'components/Form/InputGroup';
import SelectOption from './SelectOption';
import {
  BORDER_COLOR,
  BORDER_RADIUS_REM,
  DEFAULT_BACKGROUND_COLOR,
  DISABLED_BACKGROUND_COLOR,
  TEXT_COLOR
} from '../styled';
import { SelectProps } from './types';

export function customSelectStyles<TOption extends BaseOption>(): StylesConfig<TOption> {
  return {
    option: (provided, { isSelected, isFocused }) => ({
      ...provided,
      backgroundColor: (() => {
        if (isSelected) return lighten(0.45, COLORS.PRIMARY);
        if (isFocused) return COLORS.GRAY_SUPER_LIGHT;
        return '#ffffff';
      })(),
      color: (() => {
        if (isSelected) {
          if (isFocused) return COLORS.PRIMARY;
          return lighten(0.1, COLORS.PRIMARY);
        }

        if (isFocused) return COLORS.GRAY_SUPER_DARK;
        return COLORS.GRAY_DARK;
      })(),
      cursor: 'pointer',
      maxHeight: '35px',
      display: 'inline-flex',
      alignItems: 'center',
      padding: '10px'
    }),
    menu: provided => ({
      ...provided,
      width: 'max-content',
      minWidth: '250px',
      borderRadius: '4px',
      zIndex: 6 // above footer
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: '200px',
      maxWidth: '300px'
    }),
    control: (provided, state) => {
      const { customTheme, active, menuIsOpen } = state.selectProps;
      return {
        ...provided,
        background: state.isDisabled
          ? DISABLED_BACKGROUND_COLOR
          : DEFAULT_BACKGROUND_COLOR,
        border: `1px solid ${BORDER_COLOR}`,
        borderRadius: BORDER_RADIUS_REM,
        cursor: 'pointer',
        minWidth: '100px',
        boxShadow: 'none',
        borderColor: (() => {
          if (active) return BORDER_COLOR;
          if (menuIsOpen || active) return COLORS.PRIMARY;
          return BORDER_COLOR;
        })(),
        ':hover': {
          borderColor: `${COLORS.GRAY_MEDIUM_LIGHT} !important`
        },
        ...(state.isDisabled ? { pointerEvents: 'none' } : {}),
        ...(customTheme === 'button'
          ? {
              borderRadius: '99rem',
              border: `1px solid ${active ? COLORS.PRIMARY : COLORS.BORDER} !important`,
              minHeight: 41
            }
          : {})
      } as CSSObjectWithLabel;
    },
    container: (provided, state) => ({
      ...provided,
      ...(state.isDisabled ? { pointerEvents: 'auto', cursor: 'not-allowed' } : {})
    }),
    valueContainer: (provided, { selectProps }) => {
      const { customTheme } = selectProps;
      if (customTheme === 'text') {
        return {
          ...provided,
          paddingLeft: 0
        };
      }
      return provided;
    },
    singleValue: (provided, { isDisabled, selectProps }) => ({
      ...provided,
      color: (() => {
        if (isDisabled) return '#A9A9A9';
        if (selectProps.customTheme === 'button' && selectProps.active) {
          return COLORS.PRIMARY;
        }
        return TEXT_COLOR;
      })()
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (_provided, { selectProps }) =>
      ({
        marginRight: '10px',
        display: 'inherit',
        width: 0,
        height: 0,
        borderLeft: '4px solid transparent',
        borderRight: '4px solid transparent',
        borderTop: `4px solid ${
          selectProps.active ? COLORS.PRIMARY : COLORS.GRAY_MEDIUM_LIGHT
        }`,
        transform: selectProps.menuIsOpen && 'rotateX(180deg)',
        transition: selectProps.menuIsOpen && 'all .1s ease-out'
      } as CSSObjectWithLabel),
    placeholder: provided => ({
      ...provided,
      color: COLORS.GRAY_DARK
    })
  };
}

export interface BaseOption {
  label: ReactNode;
  value: string | number;
  testId?: string;
}

function Select<TOption extends BaseOption = BaseOption>({
  'data-testid': dataTestId,
  value,
  placeholder,
  customTheme = 'default',
  style,
  className,
  name,
  disabled,
  ...rest
}: SelectProps<TOption>) {
  const { label } = rest;
  const inputName = name || _.snakeCase(label);

  // We conditionally render a div or a fragment so that when customTheme === 'button` is passed,
  // ButtonGroup can properly format the Select spacing using the outer div.
  const ConditionalWrapper = ['button', 'text'].includes(customTheme)
    ? 'div'
    : React.Fragment;

  return (
    <ConditionalWrapper>
      <InputGroup
        name={inputName}
        style={customTheme === 'button' ? { marginBottom: 0 } : undefined}
        {...rest}
      >
        <div style={style} data-testid={dataTestId}>
          <ReactSelect
            placeholder={placeholder || 'Select...'}
            inputId={inputName}
            name={inputName}
            value={value}
            components={{
              Option: SelectOption as React.ComponentType<
                OptionProps<TOption, false, GroupBase<TOption>>
              >
            }}
            className={classNames('Select-js', `Select-${inputName}-js`, className)}
            classNamePrefix={'proton'}
            styles={customSelectStyles<TOption>()}
            customTheme={customTheme}
            isDisabled={disabled}
            {...rest}
          />
        </div>
      </InputGroup>
    </ConditionalWrapper>
  );
}

export default Select;
