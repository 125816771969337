import React, { ReactNode } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

const StyledFlex = styled.div<StyledFlexProps>`
  align-items: ${({ $align }) => $align && $align};
  display: ${({ $block }) => ($block ? 'block' : 'flex')};
  flex-basis: ${({ $basis }) => $basis || 'auto'};
  flex-direction: ${({ $column }) => ($column ? 'column' : 'row')};
  flex-grow: ${({ $grow }) => (_.isNumber($grow) ? $grow : 1)};
  flex-shrink: ${({ $shrink }) => (_.isNumber($shrink) ? $shrink : 1)};
  flex-wrap: ${({ $wrap, $wrapReverse }) => getWrapValue({ $wrap, $wrapReverse })};
  justify-content: ${({ $justify }) => $justify && $justify};
  overflow: ${({ $overflowHidden }) => $overflowHidden && 'hidden'};
  width: ${({ $expand }) => $expand && '100%'};
`;

const StyledFlexItem = styled.div<StyledFlexProps>`
  flex-basis: ${({ $basis }) => $basis || 'auto'};
  flex-grow: ${({ $grow }) => (_.isNumber($grow) ? $grow : 1)};
  flex-shrink: ${({ $shrink }) => (_.isNumber($shrink) ? $shrink : 1)};
  width: ${({ $width }) => $width && $width};
`;

const getWrapValue = ({
  $wrap,
  $wrapReverse
}: {
  $wrap?: boolean;
  $wrapReverse?: boolean;
}) => {
  if ($wrap) return 'wrap';
  if ($wrapReverse) return 'wrap-reverse';
  return 'nowrap';
};

type FlexAttributes = {
  column?: boolean;
  align?: string;
  block?: boolean;
  basis?: number;
  expand?: boolean;
  grow?: number;
  shrink?: number;
  style?: React.CSSProperties;
  justify?: string;
  width?: string;
  wrap?: boolean;
  wrapReverse?: boolean;
  overflowHidden?: boolean;
};

interface FlexProps extends FlexAttributes {
  children?: ReactNode;
  className?: string;
}

type addPrefixToObject<T, P extends string> = {
  [K in keyof T as K extends string ? `${P}${K}` : never]: T[K];
};

type StyledFlexProps = addPrefixToObject<FlexAttributes, '$'>;

const Flex = ({
  wrap,
  wrapReverse,
  grow,
  justify,
  align,
  block,
  children,
  basis,
  column,
  shrink,
  overflowHidden,
  expand,
  ...rest
}: FlexProps): JSX.Element => (
  <StyledFlex
    $wrap={wrap}
    $wrapReverse={wrapReverse}
    $grow={grow}
    $justify={justify}
    $align={align}
    $block={block}
    $basis={basis}
    $column={column}
    $shrink={shrink}
    $overflowHidden={overflowHidden}
    $expand={expand}
    {...rest}
  >
    {children}
  </StyledFlex>
);

Flex.Item = ({ children, width, shrink, basis, grow }: FlexProps) => (
  <StyledFlexItem $width={width} $grow={grow} $shrink={shrink} $basis={basis}>
    {children}
  </StyledFlexItem>
);

export default Flex;
