import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';

import { RELEASE_INITIAL_STATE } from 'redux/reducers/initialState/releaseInitialState';
import { FETCHING_STATE } from 'redux/reducers/utilities';
import { getReleaseHeadlineArtists } from 'helpers/release';

// ****************** byId ******************
// mapping of release data by Id

const DEFAULT_RELEASE_BY_ID_STATE = { data: RELEASE_INITIAL_STATE, ...FETCHING_STATE };

const selectAllReleases = state => state.releases.byId;

export const selectReleaseById = (state, { releaseId }) =>
  state.releases.byId[releaseId] || DEFAULT_RELEASE_BY_ID_STATE;

export const selectReleaseByIdData = (state, { releaseId }) =>
  selectReleaseById(state, { releaseId }).data;

/**
 * [makeSelectHeadlinerArtist]
 *
 * @param {number} releaseId
 * @returns {object[]} headline artist (see getReleaseHeadlineArtists)
 */

export const makeSelectHeadlinerArtist = () =>
  createSelector([selectReleaseByIdData], release => getReleaseHeadlineArtists(release));

// cache of 1
export const selectHeadlinerArtist = makeSelectHeadlinerArtist();

// ****************** byLabelId ******************
// mapping of releleases for label by label id
const DEFAULT_RELEASE_BY_LABEL_ID_STATE = { data: [], ...FETCHING_STATE };

export const selectReleasesByLabelId = (state, { labelId }) =>
  state.releases.byLabelId[labelId] || DEFAULT_RELEASE_BY_LABEL_ID_STATE;

export const selectReleasesByLabelIdFetching = (state, { labelId }) =>
  selectReleasesByLabelId(state, { labelId }).__isFetching;

const selectReleaseIdsByLabelId = (state, { labelId }) =>
  selectReleasesByLabelId(state, { labelId }).data;

export const selectReleasesByLabelIdData = createSelector(
  [selectAllReleases, selectReleaseIdsByLabelId, (__, { limit }) => limit],
  (releasesById, releaseIds, limit) => {
    const releaseIdsLimited = limit ? releaseIds.slice(0, limit) : releaseIds;

    return releaseIdsLimited.map(releaseId =>
      _.get(releasesById, `${releaseId}.data`, {})
    );
  }
);

// ****************** tracksByReleaseId ******************
// mapping of release tracks beeing fetched
const DEFAULT_TRACKS_BY_RELEASE_ID_STATE = { data: [], ...FETCHING_STATE };

export const selectReleaseTrackIdsByReleaseId = (state, { releaseId }) =>
  state.releases.tracksByReleaseId[releaseId] || DEFAULT_TRACKS_BY_RELEASE_ID_STATE;

export const selectReleaseTrackIdsDataByReleaseId = (state, { releaseId }) =>
  selectReleaseTrackIdsByReleaseId(state, { releaseId }).data;

// see multiReducer for 'selectReleaseTracksByReleaseId'
