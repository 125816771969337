import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { getWaveform } from 'api/waveform';
import {
  MEDIUM_BREAK_POINT,
  PLAYER_STATES,
  TRACK_PREVIEW_LENGTH_SECONDS
} from 'config/constants';
import { resampleData } from 'helpers/arrayData';
import { createPlaceholderWaveform } from 'helpers/waveform';
import useCurrentUser from 'hooks/useCurrentUser';
import useProtonPlayer, { usePlayheadPosition } from 'hooks/useProtonPlayer';
import { routeToSignInModal } from 'redux/actions/player';

import PlayerWaveformTimestamp from '../PlayerWaveformTimestamp';
import { BAR_PADDING, BAR_WIDTH, drawCanvas, hasDurationValue } from './helpers';

const Container = styled.div`
  ${({ $hasDuration }) => !$hasDuration && `cursor: not-allowed;`}
  flex: 5 1 60rem;
  overflow: hidden;

  @media screen and (max-width: ${MEDIUM_BREAK_POINT}px) {
    height: 4rem;
    left: 0;
    position: absolute;
    top: -4rem;
    width: 100%;
  }
`;

const ContainerInner = styled.div`
  height: 60px;
  position: relative;

  @media screen and (max-width: ${MEDIUM_BREAK_POINT}px) {
    height: 4rem;
  }
`;

const StyledPlayerPreviewHighlight = styled.div`
  border: 1px solid #fff;
  border-radius: 2px;
  height: calc(100% - 4px);
  left: ${({ $left }) => `${$left}px`};
  pointer-events: none;
  position: absolute;
  top: 0.2rem;
  user-select: none;
  width: ${({ $width }) => `${$width}px`};
`;

const WaveformUnavailableMessage = styled.div`
  position: absolute;
  top: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const calcPreviewWidth = (pixelsPerSecond, left, dimensions) => {
  const width = pixelsPerSecond * TRACK_PREVIEW_LENGTH_SECONDS;
  const totalWidth = width + left;

  if (totalWidth > dimensions.width) {
    return width - (left + width - dimensions.width);
  }
  return width;
};

/** [PlayerWaveformStatic] - Component that renders out a static waveform based on a
 *  JSON file of waveform data. Used by the PlayerContainer and the Promo MiniPlayer.
 */

const PlayerWaveformStatic = () => {
  const player = useProtonPlayer();
  const playing = player.currentTrack;
  const history = useHistory();

  const { user } = useCurrentUser();

  const [waveformData, setWaveformData] = useState([]);

  const [dimensions, setDimensions] = useState({ width: null, height: null });
  const [previewLeftPos, setPreviewLeftPos] = useState(0);
  const [previewWidth, setPreviewWidth] = useState(0);

  const numberOfBarsRef = useRef(100); // Sane default number of bars
  const containerElRef = useRef(); // Canvas wrapper DOM node
  const canvasRef = useRef(); // Canvas DOM node
  const frequencyDataRef = useRef([]); // Becomes storage spot for the UInt8Array of audio frequency data
  const mousePositionRef = useRef(null); // X mouse position in pixels relative to the canvas left edge
  const positionRefSeconds = useRef(0);
  const previewRatioRef = useRef([0, 1]);

  const hasValidWaveformData = waveformData.length > 0;

  const getMousePosition = e => {
    if (!hasDurationValue(playing)) return;
    const { left } = canvasRef.current.getBoundingClientRect();
    return e.pageX - left;
  };

  const setPosition = e => {
    if (!hasDurationValue(playing)) return;

    if (!user.id) {
      if (player.state === PLAYER_STATES.PLAYING) {
        player.send('pause');
      }
      return history.push(routeToSignInModal(playing?.type));
    }

    const { width } = dimensions;
    const clickPosition = getMousePosition(e);

    const length = playing.mix_length || playing.duration_seconds;
    const clickPositionInSeconds = (clickPosition / width) * length;
    const clickPositionInPercent = clickPosition / width;

    const updatePreview =
      player.isPreview &&
      (clickPositionInSeconds < playing.previewPositionSeconds ||
        clickPositionInSeconds >
          playing.previewPositionSeconds + TRACK_PREVIEW_LENGTH_SECONDS);

    const previewExceedsTrackLength =
      clickPositionInSeconds + TRACK_PREVIEW_LENGTH_SECONDS > playing.duration_seconds;

    if (updatePreview) {
      const newPreviewPosition = previewExceedsTrackLength
        ? playing.duration_seconds - TRACK_PREVIEW_LENGTH_SECONDS
        : clickPositionInSeconds;

      player.send('updateTrack', {
        id: playing.id,
        delta: { previewPositionSeconds: newPreviewPosition }
      });
    }

    const newLastAllowedPosition = previewExceedsTrackLength
      ? 1
      : (clickPositionInSeconds + TRACK_PREVIEW_LENGTH_SECONDS) /
        playing.duration_seconds;

    const updateLastAllowedPosition =
      clickPositionInSeconds < playing.previewPositionSeconds ||
      clickPositionInSeconds >
        playing.previewPositionSeconds + TRACK_PREVIEW_LENGTH_SECONDS;

    player.send('setPlaybackPosition', {
      position: clickPositionInPercent,
      newLastAllowedPosition: updateLastAllowedPosition
        ? newLastAllowedPosition
        : undefined
    });
    positionRefSeconds.current = clickPositionInSeconds;
  };

  const handleResize = (width, height) => {
    setDimensions({ width, height });
    // Create new resampledData Uint8Array when number of bars changes due to resize of screen.
    numberOfBarsRef.current = Math.floor(width / (BAR_WIDTH + BAR_PADDING));
    frequencyDataRef.current = new Uint8Array(numberOfBarsRef.current);
  };

  useEffect(() => {
    if (!playing) return;

    const pixelsPerSecond = dimensions.width / playing.duration_seconds;
    const positionInPixels = playing.previewPositionSeconds * pixelsPerSecond;

    const calculatedPreviewWidth = calcPreviewWidth(
      pixelsPerSecond,
      positionInPixels,
      dimensions
    );

    previewRatioRef.current = [
      positionInPixels / dimensions.width,
      (positionInPixels + pixelsPerSecond * TRACK_PREVIEW_LENGTH_SECONDS) /
        dimensions.width
    ];

    setPreviewWidth(calculatedPreviewWidth);
    setPreviewLeftPos(positionInPixels);
  }, [dimensions, playing]);

  // Maintain internal position
  usePlayheadPosition(playheadTime => {
    positionRefSeconds.current = playheadTime;
  });

  // Setup animation loop when component mounts
  useEffect(() => {
    if (!playing) return;

    const cancelAnimationFrame =
      window.cancelAnimationFrame || window.mozCancelAnimationFrame;

    const requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame;

    const frequencyData = hasValidWaveformData
      ? waveformData
      : createPlaceholderWaveform();

    resampleData(frequencyDataRef, frequencyData, numberOfBarsRef, dimensions);
    const canvasContext = canvasRef.current.getContext('2d');

    let animationRequest;
    const loop = () => {
      drawCanvas(
        canvasContext,
        frequencyDataRef,
        dimensions,
        mousePositionRef,
        numberOfBarsRef,
        positionRefSeconds,
        playing,
        player.isPreview,
        previewRatioRef,
        hasValidWaveformData
      );

      animationRequest = requestAnimationFrame(loop);
    };

    animationRequest = requestAnimationFrame(loop);

    return () => {
      cancelAnimationFrame(animationRequest);
    };
  }, [waveformData, dimensions, player.isPreview, playing]);

  // Get waveform data when playing song changes.
  useEffect(() => {
    if (!playing) return;
    getWaveform(playing.waveform_url).then(response => {
      // Frequency data is on a scale of 0-1 and need 0-256 for audio processing.
      setWaveformData(response.data);
    });
  }, [playing?.id]);

  return (
    <Container
      data-testid="Player-waveform"
      $hasDuration={playing ? hasDurationValue(playing) : 0}
    >
      <ContainerInner
        ref={containerElRef}
        onMouseMove={e => {
          mousePositionRef.current = getMousePosition(e);
        }}
        onMouseOut={() => {
          mousePositionRef.current = null;
        }}
        onBlur={() => {
          mousePositionRef.current = null;
        }}
        onClick={setPosition}
      >
        <ReactResizeDetector handleWidth handleHeight onResize={handleResize} />
        {player.isPreview && !!previewLeftPos && !!previewWidth && (
          <StyledPlayerPreviewHighlight $left={previewLeftPos} $width={previewWidth} />
        )}
        <PlayerWaveformTimestamp hideEndTime={playing && !!hasDurationValue(playing)} />
        <canvas
          ref={canvasRef}
          id="waveform-canvas"
          width={dimensions.width}
          height={dimensions.height}
        />
        {!hasValidWaveformData && (
          <WaveformUnavailableMessage>Waveform unavailable.</WaveformUnavailableMessage>
        )}
      </ContainerInner>
    </Container>
  );
};

export default PlayerWaveformStatic;
