import React from 'react';

import useBreakpoint from 'hooks/useBreakpoint';
import useToggle from 'hooks/useToggle';

import Popover from 'components/Popover';
import { DIRECTIONS } from 'config/constants';

/**
 * [MenuToggler] - Wrapper that handles render logic for showing action menu vs dropdown
 * NOTE: This component is largely deprecated. We should handle this functionality by breaking up mobile
 * and desktop functionality into separate components.
 */

type ToggleProps = ReturnType<typeof useToggle>;

interface MenuTogglerProps {
  renderActionMenu: (props: ToggleProps) => React.ReactNode;
  renderPopoverContent: (props: ToggleProps) => React.ReactNode;
  forceActionMenuDisplay: boolean;
  /* The button to be rendered that will toggle the action menu or the popover to be displayed */
  renderToggle: (props: ToggleProps) => React.ReactNode;
  /* Renders popover with fixed positioning */
  fixedPopover?: boolean;
  /* Render direction for popover */
  renderDirection?: keyof typeof DIRECTIONS;
  onOpen?: () => void;
  onClose?: () => void;
}

const MenuToggler = React.memo(function MenuToggler(props: MenuTogglerProps) {
  const {
    renderActionMenu,
    renderPopoverContent,
    forceActionMenuDisplay,
    renderToggle,
    fixedPopover,
    renderDirection = DIRECTIONS.DOWN,
    onOpen,
    onClose
  } = props;

  const shouldDisplayActionMenu =
    useBreakpoint(useBreakpoint.BREAKPOINTS.SMALL, useBreakpoint.DIRECTIONS.DOWN) ||
    forceActionMenuDisplay;

  const toggleProps = useToggle();
  const { isOpen, open, close, toggle } = toggleProps;

  if (shouldDisplayActionMenu) {
    return (
      <>
        {renderToggle && renderToggle({ open, close, isOpen, toggle, actionMenu: true })}
        {renderActionMenu({ open, close, isOpen, toggle })}
      </>
    );
  }

  return (
    <Popover
      onOpen={onOpen}
      onClose={onClose}
      renderToggle={renderToggle ? toggleProps => renderToggle(toggleProps) : null}
      renderDirection={renderDirection}
      fixed={fixedPopover}
    >
      {popoverProps => {
        return renderPopoverContent(popoverProps);
      }}
    </Popover>
  );
});

export default MenuToggler;
