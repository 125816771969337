/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';

import Screen from 'components/Screen';
import { highlightMatchedField } from 'components/UniversalSearchResultRow/SearchResultItems/helpers';

const UserSummaryTable = props => {
  const { bios, labels, shows, matched } = props;

  return (
    <div className="UserSummaryTable">
      <Screen.SMALL up>
        <div className="UserSummaryTable__row">
          <div className="UserSummaryTable__block">
            <div>U</div>
            <div>{UserSummaryTable.renderEmail('user_email', props)}</div>
          </div>

          <div className="UserSummaryTable__block">
            <div>A</div>
            <div>{UserSummaryTable.renderList(bios, matched.bios)}</div>
          </div>
        </div>

        <div className="UserSummaryTable__row">
          <div className="UserSummaryTable__block">
            <div>P</div>
            <div>{UserSummaryTable.renderEmail('pro_email', props)}</div>
          </div>

          <div className="UserSummaryTable__block">
            <div>L</div>
            <div>{UserSummaryTable.renderList(labels, matched.labels)}</div>
          </div>
        </div>

        <div className="UserSummaryTable__row">
          <div className="UserSummaryTable__block">
            <div>$</div>
            <div>{UserSummaryTable.renderEmail('paypal_email', props)}</div>
          </div>

          <div className="UserSummaryTable__block">
            <div>S</div>
            <div>{UserSummaryTable.renderList(shows, matched.shows)}</div>
          </div>
        </div>
      </Screen.SMALL>

      <Screen.SMALL down>
        <div className="UserSummaryTable__row--mobile">
          <div className="UserSummaryTable__block">
            <div>U</div>
            <div>{UserSummaryTable.renderEmail('user_email', props)}</div>
          </div>

          <div className="UserSummaryTable__block">
            <div>P</div>
            <div>{UserSummaryTable.renderEmail('pro_email', props)}</div>
          </div>

          <div className="UserSummaryTable__block">
            <div>$</div>
            <div>{UserSummaryTable.renderEmail('paypal_email', props)}</div>
          </div>
        </div>

        <div className="UserSummaryTable__row--mobile">
          <div className="UserSummaryTable__block">
            <div>A</div>
            <div>{UserSummaryTable.renderList(bios, matched.bios)}</div>
          </div>

          <div className="UserSummaryTable__block">
            <div>L</div>
            <div>{UserSummaryTable.renderList(labels, matched.labels)}</div>
          </div>

          <div className="UserSummaryTable__block">
            <div>S</div>
            <div>{UserSummaryTable.renderList(shows, matched.shows)}</div>
          </div>
        </div>
      </Screen.SMALL>
    </div>
  );
};

// TODO: Update this to use react-string-replace instead
UserSummaryTable.renderEmail = (emailName, props) => (
  <span
    title={props[emailName]}
    dangerouslySetInnerHTML={{
      __html: highlightMatchedField(emailName, props, '-')
    }}
  />
);

// Returns comma seperated list for user array attributes
UserSummaryTable.renderList = (list, matches = []) => {
  const formattedList = list.reduce((accum, item, i) => {
    const matchedText =
      matches.find(({ name = '' }) => name.replace(/<em>*|<\/em>*/g, '') === item.name) ||
      {};

    return `${accum}${i > 0 ? ', ' : ''}${matchedText.name || item.name || ''}`;
  }, '');

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: formattedList || '-'
      }}
    />
  );
};

const entityPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string
});

UserSummaryTable.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  user_email: PropTypes.string,
  pro_email: PropTypes.string,
  paypal_email: PropTypes.string,
  shows: PropTypes.arrayOf(entityPropType),
  bios: PropTypes.arrayOf(entityPropType),
  labels: PropTypes.arrayOf(entityPropType),
  matched: PropTypes.shape({
    // fields that were matched from search
  })
};

export default UserSummaryTable;
