import { Event } from '@bugsnag/js';

// Lists of Bugsnag Event values to filter by
const bugFilterables: { messages: string[]; stackTraces: string[] } = {
  messages: [
    "Unexpected token '<'",
    "expected expression, got '<'",
    "The fetching process for the media resource was aborted by the user agent at the user's request."
  ],
  stackTraces: ['netlify-rum.js']
};

export const filterBugsnagErrors = (event: Event) => {
  if (!(event.originalError instanceof Error)) return;
  const { message, stack } = event.originalError;
  const messageFilter = bugFilterables.messages;
  if (messageFilter.includes(message)) {
    return false;
  }

  const stackTraceFilter = bugFilterables.stackTraces;
  if (
    event.originalError.stack &&
    stackTraceFilter.some(partial => !!stack?.includes(partial))
  ) {
    return false;
  }
};
