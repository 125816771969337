import { useQuery } from 'urql';
import { DiscoveryModeFeatureFlagQuery } from 'graphql/queries/discoveryMode';

// Enables Discovery Mode MVP when any of the users artists have the feature flag enabled
// This will be removed once we support opt ins
export const useDiscoveryModeMVPEnabled = () => {
  const [{ data }] = useQuery({ query: DiscoveryModeFeatureFlagQuery });

  return Boolean(
    data?.viewer?.artists?.some(a => a?.configs?.discoveryModeFeatureEnabled)
  );
};
