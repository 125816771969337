import PropTypes from 'prop-types';
import React from 'react';

import { promos } from 'api/promos';
import FeedbackList from 'components/FeedbackList';

const ReleaseFeedback = ({ releaseId }) => {
  return (
    <div className="pt-3">
      <FeedbackList
        paginationReducer="feedback.byReleaseId"
        paginationReducerId={releaseId}
        fetchApi={args => promos.getReleaseReactions({ releaseId, ...args })}
      />
    </div>
  );
};

ReleaseFeedback.propTypes = {
  releaseId: PropTypes.string
};

export default ReleaseFeedback;
