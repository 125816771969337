import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import ReactResizeDetector from 'react-resize-detector';

import { BREAKPOINTS } from 'config/constants';
import EntityElement from './index';

const Grid = styled.div`
  display: grid;
  grid-gap: 4rem 3rem;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${({ $minWidth }) => `${$minWidth / 10}rem`}, 1fr)
  );

  @media screen and (max-width: ${BREAKPOINTS.MEDIUM}px) {
    grid-gap: 3rem 1.5rem;
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 2000px) {
    grid-template-columns: repeat(auto-fill, minmax(20rem, 30rem));
  }
`;

/*
 * [EntityGrid] - grid list of entities
 *
 * NOTE: this was designed for Large Entity types and will probably need work for smaller entity types
 *
 * @param {string} type - See EntityElement.TYPES
 * @param {object} size - See EntityElement.SIZES
 * @param {object[]} data - data for entity elements
 * @param {bool} [loading] - if true, tombstones will be rendered. If data exists, the tombsones are rendered
 * after the elements for the passed data
 * @returns {object}
 */

const EntityGrid = ({ type, size, data, loading = false }) => (
  <Grid $minWidth={size.minWidth}>
    {loading ? (
      <EntityGrid.TombstoneDynamic size={size} type={type} />
    ) : (
      data.map(elementData => (
        <EntityElement
          data={elementData}
          type={type}
          size={size}
          key={elementData.id}
          fluid
        />
      ))
    )}
  </Grid>
);

EntityGrid.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  data: PropTypes.arrayOf(PropTypes.shape),
  type: PropTypes.string,
  size: PropTypes.shape().isRequired,
  loading: PropTypes.bool
};

EntityGrid.TYPES = {
  ...EntityElement.TYPES
};

EntityGrid.SIZES = {
  ...EntityElement.SIZES
};

const EntityGridTombstone = ({ count, type, size }) =>
  [...Array(count)].map((_, index) => (
    <EntityElement.Tombstone
      type={type}
      size={size}
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      index={index}
      fluid
    />
  ));

const EntityGridTombstoneDynamic = ({ size, type }) => {
  const [count, setCount] = useState(1);

  // NOTE: dynamically calculate how many tombstones to show in grid.
  // Will attempt to display roughly 1.5 rows of tombstone elements
  const handleResize = useCallback(
    containerWidth => {
      const loadingRow = Math.floor(containerWidth / size.minWidth);
      const loadingCount = Math.ceil(loadingRow * 1.5);

      setCount(loadingCount);
    },
    [size]
  );

  return (
    <ReactResizeDetector handleWidth onResize={handleResize}>
      <EntityGridTombstone count={count} size={size} type={type} />
    </ReactResizeDetector>
  );
};

EntityGrid.TEST_IDS = {
  ITEMS: EntityElement.TEST_IDS.CONTAINER
};

EntityGrid.TombstoneDynamic = EntityGridTombstoneDynamic;
EntityGrid.Tombstone = EntityGridTombstone;

export default EntityGrid;
