import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import { AlertContainer } from 'components/Alert';
import Loader from 'components/Loader';
import MaintenanceMode from 'components/MaintenanceMode';
import Player from 'components/Player';
import ScrollToTop from 'components/ScrollToTop';
import SEO from 'components/SEO';
import { logError } from 'helpers/logError';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useInitializeSideEffects from 'hooks/useInitializeSideEffects';
import useTokenValidator from 'hooks/useInitializeSideEffects/useTokenValidator';
import useProtonPlayer from 'hooks/useProtonPlayer';
import { getRadioPlaying } from 'redux/actions/radio';
import { garbageCollectReduxStore } from 'redux/actions/utilities';

import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import AppLoader from './AppLoader';
import PromoDataFetcher from './AppLoader/PromoDataFetcher';
import AppSwitch from './AppSwitch';
import GlobalModals from './GlobalModals';

import '../../index.scss';

const MAINTENANCE_MODE = false;

/**
 * [App] - Checks for a jwt and that it is not expired. If expired, it shows the loader
 * while it refreshes the token prior to loading the app. This prevents any authenticated endpoints
 * in the app from returning 401 errors due to an expired jwt
 */

const App = () => {
  useInitializeSideEffects();
  useTokenValidator();

  const jwtValidationFinished = useAppSelector(state => state.ui.jwtValidationFinished);
  const dispatch = useAppDispatch();
  const player = useProtonPlayer();

  //   Redux actions
  useEffect(() => {
    dispatch(garbageCollectReduxStore(player));
    dispatch(getRadioPlaying()).catch(logError);
  }, [dispatch]);

  if (MAINTENANCE_MODE) return <MaintenanceMode />;

  return (
    <>
      <AppLoader />

      <ScrollToTop>
        <SEO image="https://www.protonradio.com/images/proton-opengraph.jpg" />

        <div className="App" id="js-app">
          <AppHeader />

          <div id="full-overlay" />

          <AlertContainer />

          <GlobalModals />

          {!jwtValidationFinished ? (
            <Loader testId={App.TEST_IDS.LOADER} />
          ) : (
            <>
              <PromoDataFetcher />
              <Route component={AppSwitch} />
            </>
          )}

          <Player />
        </div>

        <AppFooter />
      </ScrollToTop>
    </>
  );
};

App.TEST_IDS = {
  LOADER: 'token-validation-loader'
};

export default App;
