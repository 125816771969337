import { createReducer } from 'redux-act';
import { combineReducers } from 'redux';

import {
  fetchLabel,
  fetchLabelArtists,
  fetchLabelTopArtists,
  addLabels,
  updateLabel
} from 'redux/actions/labels';

import { handleFetchRequest, handleFetchSuccess, handleFetchError } from './utilities';

const labelInitialState = {
  id: null,
  image: '',
  name: '',
  slug: ''
};

// returns mix with relevant data, removes irrelevant fields in algolia
const _formatLabelResponse = (label = labelInitialState) => ({
  ...label,
  __lastFetched: Date.now(),
  __isFetching: false
});

const _addLabels = (state, { labels }) => {
  if (Array.isArray(labels)) {
    return labels.reduce(
      (labelsById, label) => ({
        ...labelsById,
        [label.id]: _formatLabelResponse(label)
      }),
      { ...state }
    );
  }
  // single label being added.
  const label = labels;
  if (label.id) {
    return {
      ...state,
      [label.id]: _formatLabelResponse(label)
    };
  }
  return state;
};

const byIdInitialState = {};
const byId = createReducer(
  {
    [addLabels]: _addLabels,
    [updateLabel]: (state, { id, data }) => ({
      ...state,
      [id]: {
        ...state[id],
        ...data
      }
    }),
    [fetchLabel.request]: (state, { id }) => ({
      ...state,
      [id]: {
        ...(state[id] || {}),
        __isFetching: true
      }
    }),
    [fetchLabel.success]: _addLabels,
    [fetchLabel.error]: handleFetchError
  },
  byIdInitialState
);

// TODO: Address if artistsByLabelId and topArtistsByLabelId can normalize
// their artist data into one place. Unclear currently if responses are going
// to remain identical or not, so best to keep separate.
const artistsByLabelId = createReducer(
  {
    [fetchLabelArtists.request]: handleFetchRequest,
    [fetchLabelArtists.success]: handleFetchSuccess,
    [fetchLabelArtists.error]: handleFetchError
  },
  byIdInitialState
);

const topArtistsByLabelId = createReducer(
  {
    [fetchLabelTopArtists.request]: handleFetchRequest,
    [fetchLabelTopArtists.success]: handleFetchSuccess,
    [fetchLabelTopArtists.error]: handleFetchError
  },
  byIdInitialState
);

export default combineReducers({
  byId,
  artistsByLabelId,
  topArtistsByLabelId
});
