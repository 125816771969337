import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _debounce from 'lodash/debounce';
import qs from 'query-string';
import classNames from 'classnames';

import routeMap from 'config/routes';
import {
  clearSearch as clearSearchAction,
  getSearchResults as getSearchResultsAction
} from 'redux/actions/search';
import searchIcon from 'images/icon-search-start.svg';
import UniversalSearchResults from '../UniversalSearchResults';
import SearchInputUniversal from './SearchInputUniversal';

const SEARCH_PARAM = 's';
const SEARCH_FORM_NAME = 'Universal_Search';

export class UniversalSearchInput extends React.Component {
  constructor(props) {
    super(props);

    this.handleSearchChange = _debounce(this.handleSearchChange.bind(this), 300);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    // if changing to search page, add search query param
    const prevPathname = prevProps.location.pathname;
    const { pathname } = this.props.location;

    if (pathname === routeMap.search && prevPathname !== pathname) {
      this._updateSearchQuery(this.props.searchText, pathname);
    }
  }

  onSubmit() {
    // user probably hit enter, go to full search page and close search drop down
    const { history, toggleSearchResultsDisplay } = this.props;
    toggleSearchResultsDisplay(false);
    history.push({ pathname: routeMap.search });
  }

  handleSearchChange(event, newValue) {
    const {
      getSearchResults,
      location: { pathname }
    } = this.props;

    if (pathname === routeMap.search) this._updateSearchQuery(newValue);
    return getSearchResults(newValue);
  }

  _updateSearchQuery(searchText, pathnameOptional) {
    const {
      location: { search, pathname },
      history
    } = this.props;
    const params = qs.parse(search) || {};
    // Only update search query in URL if changed and not empty
    if (searchText && params[SEARCH_PARAM] !== searchText) {
      params[SEARCH_PARAM] = searchText;
      const queryString = `?${qs.stringify(params)}`;
      history.replace(`${pathnameOptional || pathname}${queryString}`, history.state);
    }
  }

  render() {
    const {
      displayResults,
      clearSearch,
      toggleSearchResultsDisplay,
      mobile,
      searchText = ''
    } = this.props;

    const searchPage = window.location.pathname === routeMap.search;

    const showResultsClass = displayResults ? 'show-results' : '';
    const clearIconClass = classNames('UniversalSearchInput__clear', {
      'on-search-page': searchPage
    });

    return (
      <div className="UniversalSearchInput__container">
        <SearchInputUniversal
          id={mobile ? 'mobile' : 'desktop'}
          inputClass={`UniversalSearchInput ${showResultsClass}`}
          form={SEARCH_FORM_NAME}
          onInputChange={this.handleSearchChange}
          onInputFocus={() => toggleSearchResultsDisplay(true)}
          onInputClear={() => clearSearch()}
          onEsc={() => toggleSearchResultsDisplay(false)}
          onSubmit={this.onSubmit}
          initialValues={{ search: searchText }}
          placeholder="Search"
          clearIconClass={clearIconClass}
          urlSearchParam={SEARCH_PARAM}
        />

        {/* Pre-render asset for SearchResults to delay lag on result display */}
        <img src={searchIcon} alt="" style={{ display: 'none' }} />

        {displayResults && (
          <div className={`Search__results ${showResultsClass}`}>
            <UniversalSearchResults maxResultsPerCategory={2} />
          </div>
        )}
      </div>
    );
  }
}

UniversalSearchInput.propTypes = {
  // passed props
  toggleSearchResultsDisplay: PropTypes.func,
  mobile: PropTypes.bool,

  // withRouter
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    replaceState: PropTypes.func
  }).isRequired,

  // connect (props)
  displayResults: PropTypes.bool,
  searchText: PropTypes.string,

  // connect (actions)
  getSearchResults: PropTypes.func,
  clearSearch: PropTypes.func
};

UniversalSearchInput.SELECTORS = SearchInputUniversal.SELECTORS;

export default compose(
  withRouter,
  connect(
    state => ({
      displayResults: state.search.displayResults,
      searchText: state.search.query
    }),
    {
      getSearchResults: getSearchResultsAction,
      clearSearch: clearSearchAction
    }
  )
)(UniversalSearchInput);
