import { useLocation } from 'react-router-dom';
import React from 'react';

import Modal from 'components/Modal';
import CopyToClipboardTextInput from 'components/Form/Inputs/CopyToClipboardTextInput';

const ShareFeedbackModal = () => {
  const location = useLocation();

  return (
    <Modal title="Share Feedback">
      <div style={{ fontSize: 16 }}>
        Share this URL with artists so they can see feedback they&apos;ve received.
        Artists will <strong>ONLY</strong> be able to see feedback on their own tracks.
      </div>
      <CopyToClipboardTextInput className="mt-3" text={location.state.shareURL} />
    </Modal>
  );
};

export default ShareFeedbackModal;
