import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { COLORS, MEDIUM_BREAK_POINT, LARGE_BREAK_POINT } from 'config/constants';
import { setUi } from 'redux/actions/ui';
import { generateSelectorsFromTestIds } from 'helpers/utilities';

const StyledHeader = styled.div`
  align-items: center;
  background: ${COLORS.GRAY_SUPER_LIGHT};
  color: ${COLORS.GRAY_DARK};
  display: flex;
  justify-content: center;
  min-height: 5.2rem;
  padding: 1rem 1rem 1rem 28rem;
  position: relative;
  width: 100%;

  @media screen and (max-width: ${LARGE_BREAK_POINT}px) {
    padding-left: 1rem;
  }

  @media screen and (max-width: ${MEDIUM_BREAK_POINT}px) {
    flex-direction: column;
    padding: 1.5rem 1rem;
  }
`;

const HeaderNotification = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUi({ tabbedHeaderNotification: true }));
    return () => dispatch(setUi({ tabbedHeaderNotification: false }));
  }, [dispatch]);

  return <StyledHeader data-testid="HeaderNotification">{children}</StyledHeader>;
};

HeaderNotification.TEST_IDS = {
  CONTAINER: 'HeaderNotification'
};
HeaderNotification.SELECTORS = generateSelectorsFromTestIds(HeaderNotification.TEST_IDS);

HeaderNotification.propTypes = {
  children: PropTypes.node
};

export default HeaderNotification;
