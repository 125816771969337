import React from 'react';
import { Link } from 'react-router-dom';

import { Badge } from '@protonradio/proton-ui';
import Flex from 'components/Flex';
import Screen from 'components/Screen';
import Tooltip from 'components/Tooltip';
import TrackTitleWithRemixers from 'components/TrackTitleWithRemixers';
import { AUDIO_TYPES } from 'config/constants';

const PlayerSubtitle = ({ playing, noLink }) => {
  if (!playing) return;

  const label = playing.label_name ? playing.label_name.replace('_', ' ') : '';

  switch (playing.type) {
    case AUDIO_TYPES.SINGLE:
      return (
        <div>
          <div className="show__title">{`${playing.track_title} [${label}]`}</div>
        </div>
      );

    case AUDIO_TYPES.MIX: {
      const title = playing.show_title || playing.show_name;

      return (
        <div>
          {noLink ? (
            <div className="show__title">
              {title} ({playing.episode_date}) Part {playing.mix_info.split(' ')[0]}
            </div>
          ) : (
            <div className="show__title">
              <Link to={playing.show_slug}>
                {title} ({playing.episode_date}) Part {playing.mix_info.split(' ')[0]}
              </Link>
            </div>
          )}
        </div>
      );
    }

    case AUDIO_TYPES.COMMERCIAL:
      return 'Listener Supported Internet Radio';

    case AUDIO_TYPES.TRACK:
      return (
        <Flex>
          <TrackTitleWithRemixers
            playing={playing}
            noLink={noLink}
            highlightColor="#ffffff"
          />
          {playing.isPreview && (
            <Screen.MEDIUM>
              <Tooltip
                placement="top"
                trigger={['click']}
                content={
                  <div style={{ width: 220 }}>
                    Tracks from labels you are not signed to can only be played as 120
                    second needle drop previews.
                  </div>
                }
              >
                <div className="mt-0 ml-2">
                  <Badge variant="secondary" data-testid="Preview-badge">
                    PREVIEW
                  </Badge>
                </div>
              </Tooltip>
            </Screen.MEDIUM>
          )}
        </Flex>
      );

    default:
      return false;
  }
};

export default PlayerSubtitle;
