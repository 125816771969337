/**
 * Migration12: In an attempt to preserve the current user's session, we are grabbing any persisted redux state
 * with a jwt / refresh token and saving it to a cookie under the protonradio.com domain
 */

import { RootState } from 'config/store';
import { setProtonCookie } from 'helpers';

const rootMigration12 = (state: RootState) => {
  const { token } = state;

  if (token.jwt) {
    console.log('migrating jwt');
    setProtonCookie('jwt', token.jwt, 1, 'Lax');
  }

  if (token.refreshToken) {
    console.log('migrating refresh token');
    setProtonCookie('refresh_token', token.refreshToken, 30, 'Lax');
  }

  return state;
};

export default rootMigration12;
