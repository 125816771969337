import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import TextHeader from 'components/TextHeader';
import TracklistTable from 'components/TracklistTable';
import EntityElement from 'components/EntityElement';
import { TextEmphasis } from '@protonradio/proton-ui';
import EntityGrid from 'components/EntityElement/EntityGrid';
import {
  selectReleasesByLabelIdData,
  selectReleasesByLabelIdFetching
} from 'redux/selectors/releases';
import { TombstoneText } from 'components/Tombstone';

const ReleaseTracklist = ({
  release,
  queueName,
  tracks,
  loading,
  // REDUX:
  labelReleases,
  isLabelReleasesFetching
}) => {
  const displayLoading =
    (labelReleases.length === 0 && isLabelReleasesFetching) || loading;

  return (
    <div data-testid={ReleaseTracklist.TEST_IDS.CONTAINER} style={{ width: '100%' }}>
      <TracklistTable
        tracks={tracks}
        queueName={queueName}
        loading={displayLoading}
        columns={{ release_date: false, label: false }}
      />

      {(labelReleases.length > 0 || displayLoading) && (
        <div className="mt-4 mb-4">
          {displayLoading ? (
            <TombstoneText
              size={TombstoneText.SIZES.MEDIUM}
              className="mb-2"
              style={{ width: '20rem' }}
            />
          ) : (
            <TextHeader
              type={TextHeader.TYPES.MEDIUM}
              className="mb-2"
              data-testid={ReleaseTracklist.TEST_IDS.LABEL_RELEASES_HEADER}
            >
              More from{' '}
              <TextEmphasis to={release.label.slug}>{release.label.name}</TextEmphasis>
            </TextHeader>
          )}

          <EntityGrid
            size={EntityElement.SIZES.LARGE}
            data={labelReleases}
            type={EntityElement.TYPES.RELEASE}
            loading={isLabelReleasesFetching}
          />
        </div>
      )}
    </div>
  );
};

ReleaseTracklist.TEST_IDS = {
  CONTAINER: 'ReleaseTracklist-container',
  LABEL_RELEASES_HEADER: 'ReleaseTracklist-label-releases-header',
  LABEL_RELEASES: EntityGrid.TEST_IDS.ITEMS
};

ReleaseTracklist.propTypes = {
  tracks: PropTypes.arrayOf(PropTypes.shape()),
  release: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string
    })
  }),
  releaseId: PropTypes.string,
  labelReleases: PropTypes.arrayOf(PropTypes.shape()),
  queueName: PropTypes.string,
  isLabelReleasesFetching: PropTypes.bool,
  loading: PropTypes.bool
};

export default connect((state, { release }) => ({
  labelReleases: selectReleasesByLabelIdData(state, {
    labelId: release.label.id,
    limit: 5
  }),
  isLabelReleasesFetching: selectReleasesByLabelIdFetching(state, {
    labelId: release.label.id
  })
}))(ReleaseTracklist);
