import { createReducer } from 'redux-act';

import { AUDIO_TYPES } from 'config/constants';
import {
  setRadio,
  fetchingRadio,
  fetchRadioSuccess,
  fetchRadioError,
  setRadioListeners,
  incrementRadioListener,
  decrementRadioListener
} from 'redux/actions/radio';
import { RADIO_MIX_INITIAL_STATE } from './initialState';

const initialState = {
  1: {
    playing: RADIO_MIX_INITIAL_STATE,
    playingUpdated: null, // date epoch
    listenersUpdated: null, // date epoch
    isFetching: false,
    error: null
  }
};

const updateRadioPlaying = (state, radioId, playing) => ({
  ...state[radioId],
  playing: {
    ...RADIO_MIX_INITIAL_STATE,
    ...playing,
    __audioType: AUDIO_TYPES.RADIO,
    __audioId: radioId
  },
  playingUpdated: Date.now(),
  isFetching: false,
  error: null
});

export default createReducer(
  {
    [setRadio]: (state, { radioId, playing }) => ({
      ...state,
      [radioId]: {
        ...updateRadioPlaying(state, radioId, playing)
      }
    }),
    [fetchingRadio]: (state, { radioId }) => ({
      ...state,
      [radioId]: {
        ...state[radioId],
        isFetching: true,
        error: null
      }
    }),
    [fetchRadioSuccess]: (state, { radioId, playing }) => ({
      ...state,
      [radioId]: {
        ...updateRadioPlaying(state, radioId, playing)
      }
    }),
    [fetchRadioError]: (state, { radioId, error }) => ({
      ...state,
      [radioId]: {
        ...state[radioId],
        isFetching: false,
        error
      }
    }),
    [setRadioListeners]: (state, { radioId, listeners }) => ({
      ...state,
      [radioId]: {
        ...state[radioId],
        listenersUpdated: Date.now(),
        playing: {
          ...state[radioId].playing,
          currentlisteners: listeners
        }
      }
    }),
    [incrementRadioListener]: (state, radioId) => ({
      ...state,
      [radioId]: {
        ...state[radioId],
        playing: {
          ...state[radioId].playing,
          currentlisteners: state[radioId].playing.currentlisteners + 1
        }
      }
    }),
    [decrementRadioListener]: (state, radioId) => ({
      ...state,
      [radioId]: {
        ...state[radioId],
        playing: {
          ...state[radioId].playing,
          currentlisteners: state[radioId].playing.currentlisteners - 1
        }
      }
    })
  },
  initialState
);
