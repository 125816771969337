import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { displayAlertError, generateSelectorsFromTestIds, getMixActions } from 'helpers';
import { makeSelectCanUserDownloadMix } from 'redux/selectors/multiReducer';
import { downloadMix } from 'api/mix';

import Icon from 'components/Icon';
import ActionMenu from 'components/ActionMenu/ActionMenu';
import ActionMenuList from 'components/ActionMenu/ActionMenuList';
import ActionMenuItem from 'components/ActionMenu/ActionMenuItem';
import MixMenuHeader from './MixMenuHeader';

/**
 * [MixActionMenu] - Generic action menu used for MixTables across app
 *
 * @param {object} mix
 *
 * Action menu props
 * @param {bool} isOpen open state - likely from useToggle in parent component
 * @param {function} close toggle function for open state - likely from useToggle in parent component
 * @param {function} closeOnNavigation
 */

const MixActionMenu = ({
  mix,
  canDownload,
  // action menu props
  isOpen,
  close,
  closeOnNavigation
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const mixActions = getMixActions(mix);

  return (
    <>
      <ActionMenu isOpen={isOpen} close={close} closeOnNavigation={closeOnNavigation}>
        <MixMenuHeader mix={mix} />

        <ActionMenuList actions={mixActions} closeMenu={close}>
          {canDownload && (
            <ActionMenuItem
              testId={MixActionMenu.TEST_IDS.DOWNLOAD_MIX}
              icon={<Icon type={Icon.TYPES.DOWNLOAD} width={20} />}
              disabled={isDownloading}
              onClick={() => {
                setIsDownloading(true);

                downloadMix({ id: mix.id })
                  .then(() => {
                    setTimeout(() => {
                      setIsDownloading(false);
                      close();
                    }, 1000);
                  })
                  .catch(displayAlertError);
              }}
              closeMenu={close}
            >
              {isDownloading ? 'Downloading...' : 'Download Mix'}
            </ActionMenuItem>
          )}
        </ActionMenuList>
      </ActionMenu>
    </>
  );
};

MixActionMenu.TEST_IDS = {
  DOWNLOAD_MIX: 'MixActionMenu-download-mix'
};

MixActionMenu.SELECTORS = generateSelectorsFromTestIds(MixActionMenu.TEST_IDS);

MixActionMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  closeOnNavigation: PropTypes.bool,
  mix: PropTypes.shape(),
  // redux connect
  canDownload: PropTypes.bool
};

const makeMapStateToProps = () => {
  const selectCanUserDownloadMix = makeSelectCanUserDownloadMix();

  return (state, { mix }) => ({
    canDownload: selectCanUserDownloadMix(state, { mix })
  });
};

export default connect(makeMapStateToProps)(MixActionMenu);
