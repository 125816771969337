import ahoy from 'ahoy.js';
import axios, { AxiosError } from 'axios';
import { getCookie } from 'react-use-cookie';

import { API_BASE, FINGERPRINT_COOKIE } from 'config/constants';
import { AppStore } from 'config/store';
import { getCurrentAuthCookies } from 'helpers';
import { logError } from 'helpers/logError';
import { incrementErrorCount, resetErrorCount, showAlert } from 'redux/actions/ui';
import {
  handleCancelableRequest,
  handleCancelableRequestCleanup
} from './cancelRequests';
import handleProtonApiErrors from './handleProtonApiErrors';
import {
  handlePendingRequestDecrement,
  handlePendingRequestIncrement,
  initializePendingRequestListener
} from './pendingRequestAlert';
import { RequestConfig } from './requestConfig';

export const configRequestHeaders = () => {
  const { jwt, refreshToken } = getCurrentAuthCookies();

  const headers: Record<string, string | number | boolean> = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    'x-fingerprint-visitor-id': getCookie(FINGERPRINT_COOKIE)
  };
  if (ahoy.getVisitToken()) {
    headers['Ahoy-Visit'] = ahoy.getVisitToken();
  }
  if (ahoy.getVisitorToken()) {
    headers['Ahoy-Visitor'] = ahoy.getVisitorToken();
  }

  if (jwt && !headers.Authorization) {
    headers.Authorization = `Bearer ${jwt}`;
  }
  if (refreshToken && !headers['X-Refresh-Token']) {
    headers['X-Refresh-Token'] = refreshToken;
  }
  return headers;
};

export const configAxios = (store: AppStore) => {
  initializePendingRequestListener();

  axios.interceptors.request.use((config: RequestConfig) => {
    // Only intercept requests to Proton's API api
    if (!(config.url && config.url.includes(API_BASE!))) {
      return config;
    }

    handleCancelableRequest(config);

    // Note: the shape of `headers` in >1.0 axios is changed so this logic may need
    // to be refactored when axios is updated from our current v0.27.2

    const headers = configRequestHeaders();
    config.headers?.common
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore axios 0.27.2 type for `AxiosRequestConfig` is not right :(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        (config.headers.common = { ...config.headers.common, ...headers })
      : config.headers
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore see above
        (config.headers = { ...config.headers, common: { ...headers } })
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore see above
        (config.headers = { common: { ...headers } });

    // check to see if we should add beforeunload listener
    handlePendingRequestIncrement(config);

    return config;
  });

  const isMaybeProtonApiError = (
    e: unknown
  ): e is AxiosError<{ message?: string; token?: string }> => {
    return !!(
      axios.isAxiosError(e) &&
      e.response?.data &&
      typeof e.response.data === 'object'
    );
  };

  axios.interceptors.response.use(
    response => {
      handlePendingRequestDecrement(response.config);
      handleCancelableRequestCleanup(response.config);

      if (store.getState().ui.errorCount > 0) store.dispatch(resetErrorCount());

      return response;
    },
    (error: unknown) => {
      if (!axios.isAxiosError(error)) {
        logError(error);
        return;
      }
      handlePendingRequestDecrement(error.config);
      handleCancelableRequestCleanup(error.config);

      // Because axios error is a special Contructor instance, redux throws exceptions about handling non-serializable
      // data when passing the error in a redux action.
      const isCanceled = axios.isCancel(error);
      if (isCanceled) {
        return Promise.reject({
          message: 'This request was canceled',
          __CANCEL__: true
        });
      }

      if (isMaybeProtonApiError(error)) {
        const { silence } = handleProtonApiErrors(error);

        if (silence) {
          return Promise.reject({
            message: 'This error was already handled and silenced',
            __SILENCE__: true
          });
        }

        if (error.response && error.response.status >= 500) {
          const radioDown =
            error.response.data &&
            error.response.data.message === 'Radio schedule empty.';

          if (radioDown) {
            store.dispatch(
              showAlert({
                type: 'notice',
                message: 'Radio stream is temporarily unavailable.'
              })
            );
          } else if (error.response.status === 503) {
            store.dispatch(
              showAlert({
                error:
                  'Proton is currently offline for maintenance. Please, come back later.'
              })
            );
          } else {
            store.dispatch(incrementErrorCount());
          }
        }
      }

      return Promise.reject(error);
    }
  );
};
