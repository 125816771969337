import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import ActionMenu, { ActionMenuToggleState } from 'components/ActionMenu/ActionMenu';
import { Button } from '@protonradio/proton-ui';
import { displayAlertError } from 'helpers/alert';

import { generateSelectorsFromTestIds } from 'helpers';
import ActionMenuList from '../ActionMenuList';
import Flex from 'components/Flex';

const ConfirmationContent = styled.div`
  padding: 0.75rem 1.5rem;
`;

export interface ConfirmationActionMenuConfig {
  cancelText?: string;
  confirmText?: string;
  message: string | React.ReactNode;
  /* Function to call when the confirm button is clicked. It must return a promise.
   * The confirm button will be disabled until the onConfirm function resolves.
   */
  onConfirm: (props?: OnConfirmProps) => Promise<void>;
  /* Function to call when the cancel button is clicked. If nothing is passed, the close method will be used. */
  onCancel?: ({ close }) => void;
}

type ConfirmationActionMenuProps = ActionMenuToggleState & ConfirmationActionMenuConfig;

type OnConfirmProps = {
  close: () => void;
  closeAll?: () => void;
  setSubmitting: (submitting: boolean) => void;
};

/**
 * [ConfirmationActionMenu] - Action menu displayed to confirm an action. Can be used on desktopand will be rendered as a modal.
 */
const ConfirmationActionMenu = ({
  cancelText,
  confirmText,
  message,
  onConfirm,
  onCancel,
  isOpen,
  close,
  closeOnNavigation,
  closeAll
}: ConfirmationActionMenuProps) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const handleOnConfirm = useCallback(async () => {
    setSubmitting(true);
    try {
      await onConfirm({ close, closeAll, setSubmitting });
      if (closeAll) {
        closeAll();
      } else {
        close();
      }
    } catch (e) {
      setSubmitting(false);
      displayAlertError(e);
    }
  }, [onConfirm, close, closeAll]);

  // ensure submitting state reset to false when closed
  useEffect(() => {
    if (!isOpen && isSubmitting) setSubmitting(false);
  }, [isOpen, isSubmitting]);

  const handleOnCancel = useCallback(() => {
    // if onCancel method passed, give close method as an argument
    if (onCancel) {
      onCancel({ close });
      return;
    }

    close();
  }, [onCancel, close]);

  return (
    <ActionMenu
      isOpen={isOpen}
      close={close}
      closeOnNavigation={closeOnNavigation}
      disabled={isSubmitting}
      showCancel={false}
    >
      <ActionMenuList>
        <ConfirmationContent>
          <h3>{message}</h3>
          <Flex>
            <Button
              variant="secondary"
              isDisabled={isSubmitting}
              onPress={handleOnCancel}
              data-testid={ConfirmationActionMenu.TEST_IDS.CANCEL}
            >
              {cancelText || 'Cancel'}
            </Button>
            <div className="mr-1" />
            <Button
              variant="primary"
              isDisabled={isSubmitting}
              onPress={handleOnConfirm}
              data-testid={ConfirmationActionMenu.TEST_IDS.CONFIRM}
            >
              {confirmText || 'Yes'}
            </Button>
          </Flex>
        </ConfirmationContent>
      </ActionMenuList>
    </ActionMenu>
  );
};

ConfirmationActionMenu.TEST_IDS = {
  CONFIRM: 'ActionMenuConfirm-confirm',
  CANCEL: 'ActionMenuConfirm-cancel'
};

ConfirmationActionMenu.SELECTORS = generateSelectorsFromTestIds(
  ConfirmationActionMenu.TEST_IDS
);

export default ConfirmationActionMenu;
