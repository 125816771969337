import React from 'react';
import Markdown from 'react-markdown';

import policy from '../../docs/privacy-policy.md';
import changelog from '../../docs/change-log.md';
import useBreakpoint from 'hooks/useBreakpoint';
import { useAppSelector } from 'hooks/redux';
import { POLICY_LAST_UPDATED } from 'config/constants';

const PrivacyPolicy = () => {
  const isMedium = useBreakpoint(
    useBreakpoint.BREAKPOINTS.MEDIUM,
    useBreakpoint.DIRECTIONS.DOWN
  );
  const isSmall = useBreakpoint(
    useBreakpoint.BREAKPOINTS.SMALL,
    useBreakpoint.DIRECTIONS.DOWN
  );
  const alertCount = useAppSelector(state => state.ui.alerts).length;

  return (
    <div
      className="container container--medium mt-5"
      // Ensure the text begins below the Policy Consent alert on smaller screens
      style={{
        paddingTop:
          (isSmall || isMedium) && alertCount > 0
            ? isSmall
              ? '4rem'
              : '2.5rem'
            : undefined
      }}
    >
      <div className="ArticleText">
        <h2>
          Changelog -{' '}
          {POLICY_LAST_UPDATED.toLocaleDateString(undefined, {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
          })}
        </h2>
        <Markdown>{changelog}</Markdown>
        <Markdown>{policy}</Markdown>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
