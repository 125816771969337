import React from 'react';

import { DEFAULT_ICON_COLOR, ICON_PROP_TYPES } from './config';

const Notification = ({ color = DEFAULT_ICON_COLOR, size = 18, className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    height={size}
    style={style}
    viewBox="0 0 44 44"
  >
    <title>Notification</title>
    <path
      d="M38.374,22.001l2.344-2.965l-3.146-2.096l1.313-3.544l-3.639-1.021L35.4,8.6l-3.776,0.154l-1.021-3.64 L27.06,6.428l-2.096-3.146l-2.965,2.344l-2.965-2.344l-2.095,3.146l-3.543-1.313l-1.021,3.64L8.599,8.6l0.154,3.776l-3.64,1.021 l1.313,3.544l-3.146,2.096l2.345,2.965l-2.345,2.964l3.146,2.096l-1.313,3.543l3.64,1.021l-0.154,3.776l3.776-0.154l1.021,3.639 l3.543-1.313l2.095,3.146l2.965-2.345l2.965,2.345l2.096-3.146l3.544,1.313l1.021-3.639l3.776,0.154l-0.154-3.776l3.639-1.021 l-1.313-3.543l3.146-2.096L38.374,22.001z M21.997,32.105c-1.472,0-2.654-1.159-2.654-2.631s1.183-2.63,2.654-2.63 c1.448,0,2.631,1.158,2.631,2.63S23.445,32.105,21.997,32.105z M23.951,24.39h-3.931l-0.65-11.874h5.285L23.951,24.39z"
      fill={color}
    />
  </svg>
);

Notification.propTypes = ICON_PROP_TYPES;

export default Notification;
