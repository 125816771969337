import { useEffect, useRef } from 'react';

/**
 * Saves the previous render value for the passed value.
 * Useful when comparing values from previous updates.
 *
 */
function usePrevious<Value>(value: Value, initialValue?: Value) {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default usePrevious;
