import React from 'react';
import PropTypes from 'prop-types';

import { UserSummaryTable } from 'components/Entities/Users';

const UserHeader = props => {
  const { name, username, ...rest } = props;
  return (
    <div>
      <div className="mb-2">
        <div className="ListItem__title">{name}</div>
        <div className="ListItem__subtitle">@{username}</div>
      </div>
      <UserSummaryTable {...rest} />
    </div>
  );
};

UserHeader.propTypes = {
  name: PropTypes.string,
  username: PropTypes.string
};

export default UserHeader;
