/* global Image */

import React from 'react';
import PropTypes from 'prop-types';
import * as StackBlur from 'stackblur-canvas';
import ReactResizeDetector from 'react-resize-detector';
import classnames from 'classnames';

import userAgent, { OS_NAMES } from '../../helpers/userAgent';

/**
 * @param {string} imageSrc - path for image
 * @param {bool} elementId - (optional) Track the resize of a specific element id, if exlcuded,
 * tracks  element resize for className passed
 * @param {bool} className - Class that should define the size of the background image
 */

class BlurredBackground extends React.Component {
  constructor() {
    super();

    this.canvasEl = React.createRef();
    this.mounted = false;
  }

  state = {
    visible: false
  };

  componentDidMount() {
    this.mounted = true;
    this._blurBackground(this.props);
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.imageSrc && !nextProps.imageSrc) return false;
    // BlurredBackground sometimes doesn't unmount/remount between page changes, so we have to account
    // for that here. If it's visible, this.imgInitialized is true, and the imageSrc hasn't changed,
    // prevent re-renders so we aren't making a ton of image HTTP calls in the background.
    if (
      this.state.visible &&
      this.imgInitialized &&
      this.props.imageSrc === nextProps.imageSrc
    ) {
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imageSrc !== this.props.imageSrc) {
      this._blurBackground(this.props);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  // Resizes the background canvas element to maintain img proportions
  // while centering and stretching canvas to fill fullscreen.
  _onResize = (width, height) => {
    this.container_ratio = width / height;
    this.width = width;
    this.height = height;

    this._resize();
  };

  _resize = () => {
    const canvas = this.canvasEl.current;
    if (!canvas || !this.mounted) return;

    // Why all the manual left/topOffset calcs?
    //
    // Browser APIs incorrectly report the original width/height of a <canvas> element with methods like
    // el.clientHeight, el.scrollHeight, or el.height when it's been stretched using CSS (which we're doing
    // in our stylesheets and here with canvas.style.height) — instead we need to manually calculate the
    // height/width for offsets using a ratio of the container dimensions versus the original image
    // dimensions, and offset accordingly.

    if (this.img_ratio > this.container_ratio) {
      const realCanvasWidth = (this.height / canvas.clientHeight) * canvas.clientWidth;
      const leftOffset = ((realCanvasWidth - this.width) / 2) * -1;

      canvas.style.height = `${this.height}px`;
      canvas.style.width = 'auto';
      canvas.style.left = `${leftOffset}px`;
      canvas.style.top = '0px';
    } else if (this.img_ratio < this.container_ratio) {
      const realCanvasHeight = (this.width / canvas.clientWidth) * canvas.clientHeight;
      const topOffset = ((realCanvasHeight - this.height) / 2) * -1;

      canvas.style.height = 'auto';
      canvas.style.width = `${this.width}px`;
      canvas.style.left = 'auto';
      canvas.style.top = `${topOffset}px`;
    }
  };

  _blurBackground = ({ imageSrc }) => {
    if (userAgent.isOS(OS_NAMES.Android) && userAgent.osVersionLowerOrEqualThan('4.4'))
      return;

    if (!imageSrc || !this.mounted) return;

    const img = new Image();
    this.imgInitialized = true;

    const radius = 50;
    // Make it work with CORS
    img.crossOrigin = '';

    // Add a query param to end of image Url so it always fetches a new copy with proper
    // CORS headers as browsers sometimes decide to use a cached copy.
    img.src = `${imageSrc}?forceLoad`;

    // Wait till image loads
    img.onload = () => {
      // Setup image vars
      this.img_ratio = img.naturalWidth / img.naturalHeight;

      const canvas = this.canvasEl.current;

      if (!canvas || !this.mounted) return;
      StackBlur.image(img, canvas, radius, false);

      if (!this.state.visible) {
        this.setState({ visible: true });
      } else {
        this._resize();
      }
    };
  };

  render() {
    const { className, imageSrc, elementId, style } = this.props;

    const _outerClasses = classnames(className, 'BlurredBackground');

    const _innerClasses = classnames('BlurredBackground__canvas', {
      'is-visible': this.state.visible
    });

    // Only render the ReactResizeDetector once we know the component is visually
    // visible so we can pass in the width/height at that moment rather than at
    // component mount prior to the image being finished loading.
    return (
      <div className={_outerClasses} style={style}>
        {imageSrc && this.state.visible && (
          <ReactResizeDetector
            handleWidth
            handleHeight
            onResize={this._onResize}
            resizableElementId={elementId}
          />
        )}
        <canvas ref={this.canvasEl} className={_innerClasses} id="dataCanvas" />
      </div>
    );
  }
}

BlurredBackground.propTypes = {
  imageSrc: PropTypes.string,
  // Track the resize of a specific element id, (optional) if exlcuded, tracks parent element resize
  elementId: PropTypes.string,
  className: PropTypes.string
};

export default BlurredBackground;
