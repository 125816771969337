import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Icon from 'components/Icon';
import TextHeader from 'components/TextHeader';
import Tooltip from 'components/Tooltip';
import TrackPlayButton from 'components/TrackPlayButton';

import { EXTERNAL_LINKS } from 'config/constants';

const StyledIconWrapper = styled.div`
  margin-left: 0.5rem;
  position: relative;
  top: 0.4rem;
`;

const TracksTableTitle = React.memo(
  ({ tracks, track, showCompile, showOrderPlayButton }) => {
    const isMultiline = !!track.version;
    const titleClasses = classNames('TrackTable__title__text', {
      'TrackTable__title__text--multiline': isMultiline
    });

    return (
      <div className="TrackTable__title">
        {!showOrderPlayButton && (
          <TrackPlayButton
            track={track}
            queueIndex={track.__queueIndex}
            queueAudio={tracks}
          />
        )}
        <div className={titleClasses}>
          <div className="d-flex">
            <Link to={track.release.slug} data-testid="track-title">
              <TextHeader
                type={TextHeader.TYPES.MEDIUM}
                weight={TextHeader.WEIGHTS.NORMAL}
                style={{ marginTop: track.version ? '-0.2rem' : '0' }}
                truncate
              >
                {track.title}
              </TextHeader>
            </Link>
            {showCompile && track.no_compile && (
              <Tooltip
                placement="top"
                content={
                  <div>
                    <p className="mb-1">
                      This track is locked and requires permission to play in DJ mixes
                      distributed to Spotify and Apple Music.
                    </p>

                    <a
                      href={EXTERNAL_LINKS.INTERCOM.LOCKED_TRACKS}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn More
                    </a>
                  </div>
                }
              >
                <StyledIconWrapper>
                  <Icon type={Icon.TYPES.LOCK} color={Icon.COLORS.DARK} width={16} />
                </StyledIconWrapper>
              </Tooltip>
            )}
          </div>

          <div
            className="TrackTable__subtitle__text"
            title={track.version}
            data-testid="track-version"
          >
            {track.version}
          </div>
        </div>
      </div>
    );
  }
);

TracksTableTitle.propTypes = {
  tracks: PropTypes.arrayOf(PropTypes.shape({})),
  track: PropTypes.shape({}),
  showCompile: PropTypes.bool,
  showOrderPlayButton: PropTypes.bool,
  isTrackPageActive: PropTypes.bool
};

export default TracksTableTitle;
