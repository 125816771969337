import React from 'react';
import { Link } from 'react-router-dom';
import Alert from '../Alert';

type UnauthorizedMessageProps = { redirectPathname: string };

export const UnauthorizedMessage = ({ redirectPathname }: UnauthorizedMessageProps) => (
  <Alert.Content>
    <span>
      Oops! You don&apos;t have access to that page. Are you signed in with the right
      account?&nbsp;&nbsp;
    </span>
    <Link to={`/logout?dest=${encodeURIComponent(`/sign-in?dest=${redirectPathname}`)}`}>
      Sign out and try another account
    </Link>
    <span>
      &nbsp;or contact{' '}
      <a href="mailto:support@protonradio.com" target="_top">
        support@protonradio.com
      </a>{' '}
      for help.
    </span>
  </Alert.Content>
);
