import { createAction } from 'redux-act';

import { searchForArtistsAlgolia } from 'api/artist';

export const clearSubscriberSearchQuery = createAction('SUBSCRIBER_SEARCH_QUERY_CLEAR');

export const fetchingSubscriberSearchResults = createAction(
  'SUBSCRIBERS_SEARCH_RESULTS_FETCHING',
  query => ({ query })
);

export const setSubscriberSearchResults = createAction(
  'SUBSCRIBER_SEARCH_RESULTS_SET',
  results => ({ results })
);

export const removeFromSubscriberSearchResults = createAction(
  'SUBSCRIBER_SEARCH_RESULTS_PLUCK',
  id => ({ id })
);

export const clearSubscriberSearch = createAction('SUBSCRIBER_SEARCH_CLEAR');

export const getSubscriberSearchResults =
  ({ searchQuery, limit, omitArtistIds, labelIdsReleasedOn }) =>
  dispatch => {
    if (!searchQuery) return dispatch(clearSubscriberSearchQuery());

    dispatch(fetchingSubscriberSearchResults(searchQuery));

    return searchForArtistsAlgolia({
      query: searchQuery,
      limit,
      omitArtistIds,
      labelIdsReleasedOn
    }).then(artists => dispatch(setSubscriberSearchResults(artists)));
  };
