import React from 'react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import * as ProtonAPI from 'api';
import { formatFieldErrors } from 'helpers/form';
import { showAlert } from 'redux/actions/ui';
import { getUser } from 'redux/actions/user';
import { getToken } from 'redux/actions/token';

import Modal from 'components/Modal';
import { TextInput, SubmitButton, CheckboxInput } from 'components/Modal/Input';
import routeMap from 'config/routes';

const CREATE_ACCOUNT_SUBTITLE =
  'Your free account is the gateway to all things Proton... For listeners, producers, DJs, and label managers!';

const CreateAccountModal = ({ onClose }) => {
  const [showEmailVerificationReminder, setShowEmailVerificationReminder] =
    useState(false);

  const [isUnclaimable, setUnclaimableError] = useState(false);

  // Parse any account creation context out of the URL.
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const claimToken = query.get('claim_token');
  const artistId = query.get('artist_id');
  const destinationURL = query.get('dest');
  const isEmailRequired = !query.has('claim_token');

  const dispatch = useDispatch();

  const handleSubmit = async (
    { username, password, email, newsletter_subscription },
    formik
  ) => {
    // Build the request to register the user. Some fields need to be assigned
    // manually only if they exist because `undefined` keys do not pass API-side
    // validation.
    const userRegistrationRequest = {
      username,
      password,
      proton_newsletter_subscription: newsletter_subscription
    };
    if (claimToken) {
      userRegistrationRequest['claim_token'] = claimToken;
    } else {
      userRegistrationRequest['user_email'] = email;
    }

    let user;
    try {
      user = await ProtonAPI.registerUser(userRegistrationRequest);
    } catch (error) {
      const fieldErrors = formatFieldErrors(error.response.data.error, {
        user_email: 'email'
      });

      if (!isEmailRequired && fieldErrors['email']) {
        return setUnclaimableError(true);
      }

      if (Object.keys(fieldErrors).length === 0) {
        return dispatch(showAlert(error));
      } else {
        return formik.setErrors(fieldErrors);
      }
    }

    // If the user is not activated, it means that they have not confirmed their
    // email address. This means that they are either signing up with no claim
    // token present or their claim token is invalid.
    if (!user.activated) {
      // If there is a destination URL, we assume that the next step in the flow
      // understands that it is receiving an unactivated user and immediately
      // redirect.
      if (destinationURL) {
        return window.location.replace(destinationURL);
      }
      // Otherwise the flow is complete. Remind the user that they need to
      // verify their email address with a second modal.
      else {
        return setShowEmailVerificationReminder(true);
      }
    }

    // Since the user is already activated at this point, we can automatically
    // log them in to prepare for the next step.
    await dispatch(getToken({ protonid: username, user_password: password }));
    await dispatch(getUser(user.user_id));

    // If the user has not confirmed their pro account details, they must be
    // forwarded to the verification step. The `dest` parameter will be
    // included if it exists.
    if (artistId && user.pro_user_details_confirmed === false) {
      const params = new URLSearchParams({ artistId });
      if (destinationURL) {
        params.append('dest', destinationURL);
      }
      return history.replace(
        `/users/${user.pro_user_id}/verification?` + params.toString()
      );
    }
    // Otherwise either redirect to the destination URL (if it exists), or
    // close the modal.
    else {
      if (destinationURL) {
        return window.location.replace(decodeURIComponent(destinationURL));
      } else {
        // TODO(rocco): Perhaps show a different sort of completion modal here?
        return onClose();
      }
    }
  };

  if (showEmailVerificationReminder) {
    return <CreateAccountSuccess onClose={onClose} />;
  }

  const INITIAL_VALUES = {
    username: '',
    password: '',
    newsletter_subscription: false
  };

  const VALIDATION_SCHEMA = Yup.object().shape({
    username: Yup.string().required('Username is required.'),
    password: Yup.string().required('Password is required.'),
    newsletter_subscription: Yup.boolean()
  });

  if (isEmailRequired) {
    INITIAL_VALUES['email'] = '';
    VALIDATION_SCHEMA['email'] = Yup.string()
      .email('Please enter a valid email address.')
      .required('Email address is required.');
  }

  return (
    <Modal
      enableBanner
      title="Create An Account"
      subtitle={CREATE_ACCOUNT_SUBTITLE}
      onClose={onClose}
      errorMessage={
        isUnclaimable && (
          <>
            <div>This invite is for an existing user on Proton.</div>
            <Link
              to={location => ({
                ...location,
                pathname: '/sign-in',
                state: { modal: true }
              })}
            >
              Try to sign in instead?
            </Link>
          </>
        )
      }
      bannerFooter={
        <Modal.BannerFooter
          message="Already have an account?"
          action="Sign In"
          actionURL="/sign-in"
        />
      }
    >
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
      >
        <Form>
          <TextInput name="username" label="Username" autoFocus />
          <TextInput type="password" name="password" label="Password" />
          {isEmailRequired && (
            <TextInput type="text" name="email" label="Email Address" />
          )}
          <CheckboxInput
            name="newsletter_subscription"
            label="Email me news and announcements!"
            whiteOnMobile
          />
          <SubmitButton type="submit">Create Account</SubmitButton>
        </Form>
      </Formik>
      <Modal.PolicyMessage>
        By creating an account you agree to Proton's{' '}
        <Link to={routeMap.privacyPolicy} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </Link>
        .
      </Modal.PolicyMessage>
    </Modal>
  );
};

const CreateAccountSuccess = ({ onClose }) => (
  <Modal
    title="Success!"
    subtitle="Please activate your account by checking your email address."
    onClose={onClose}
  >
    <Modal.PolicyMessage>
      Need help? Feel free to contact us at{' '}
      <a href="mailto:support@protonradio.com">support@protonradio.com</a>.
    </Modal.PolicyMessage>
  </Modal>
);

export default CreateAccountModal;
