import styled from 'styled-components';

import { MEDIUM_BREAK_POINT, COLORS } from 'config/constants';

export const StyledInputGroup = styled.div<{ $inline?: boolean; $formik?: boolean }>`
  display: flex;
  margin: 0 0 1rem 0;
  width: 100%;

  ${({ $inline, $formik }) =>
    $inline &&
    $formik &&
    `
    margin: 0;
  `}

  @media screen and (max-width: ${MEDIUM_BREAK_POINT}px) {
    margin-right: 0;
  }
`;

export const StyledInputGroupLabel = styled.label`
  color: #666;
  display: block;
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 0.2rem;
`;

export const InputFooterText = styled.div`
  font-size: 1.2rem;
`;

export const StyledInputRequiredIndicator = styled.span`
  color: ${COLORS.GRAY_LIGHT};
  flex: 1;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-align: right;
`;
