import { isProduction } from '../constants';

/**
 * Feature Flags (config):
 * @param {object} [featureName] - name of the feature.  Define as exportable constant first.
 * @param {bool}   featureName.live - whether the feature is live or not (consider checking environment)
 * @param {bool}   featureName.adminEnabledByDefault - if true, admins can view even if live: false
 *
 * Optional fields
 * @param {string} featureName.description - being feature name may be short, this could be helpful in
 * describing what this flag is for
 * @param {string} featureName.owner - who did this
 */

// -- Feature Flag Names --
// Tracks / Promo pool flags described here: https://app.asana.com/0/0/620375408123843/f
export const LINER_NOTES = 'linerNotes';
export const RELEASE_CONTRACT_SPOTIFY_VERIFICATION =
  'RELEASE_CONTRACT_SPOTIFY_VERIFICATION';
export const ADD_ANY_PROMO_ARTISTS = 'ADD_ANY_PROMO_ARTISTS';
export const ACCOUNT_PAYMENT_SETTINGS = 'ACCOUNT_PAYMENT_SETTINGS';
export const RELEASE_LINK_TREE = 'RELEASE_LINK_TREE';
export const LOSSLESS_DOWNLOADS = 'LOSSLESS_DOWNLOADS';

// -- Feature Flag Configurations --
const featureFlags = {
  [ADD_ANY_PROMO_ARTISTS]: {
    live: true,
    name: 'Promo Pool - Add Any Artists',
    description:
      'Enables features for creating new or editing existing artist profiles when adding promo' +
      ' subscribers, including adding/editing artist members.',
    owner: 'Brad Ryan'
  },
  [LINER_NOTES]: {
    live: false,
    name: 'Liner Notes',
    description:
      'New liner notes addition to releases - adding just to mock out additional buttons.',
    owner: 'Rob Nealan'
  },
  [RELEASE_CONTRACT_SPOTIFY_VERIFICATION]: {
    live: true,
    name: 'Release Contract - Spotify Verification',
    description:
      'Enables the Spotify for Artists verification flow on the confirmation page for release contracts. Verification is only displayed if user has unverified Spotify artist profiles.',
    owner: 'Brad Ryan'
  },
  [ACCOUNT_PAYMENT_SETTINGS]: {
    live: true,
    name: 'Account Payment Settings'
  },
  [LOSSLESS_DOWNLOADS]: {
    live: true,
    name: 'Lossless Downloads MVP',
    description: 'Allows curators to see the lossless download UI',
    owner: 'Rocco Nicosia'
  },
  DISCOVERY_MODE_NOTIFICATIONS: {
    live: !isProduction,
    name: 'Discovery Mode Notifications',
    description: 'Recieve notifications for Discovery Mode.',
    owner: 'Brad'
  }
};

export default featureFlags;
