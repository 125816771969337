import axios, { Canceler } from 'axios';
import { API_BASE, isProduction } from 'config/constants';
import { consoleDev } from 'helpers/utilities';

import { handlePendingRequestDecrement } from './pendingRequestAlert';
import { RequestConfig } from './requestConfig';

const { CancelToken } = axios;

const PENDING_AXIOS_TRANSACTIONS: Record<string, Canceler> = {
  // [`${method}:${url}:${requestParams}]:  <Axios Canceler>
  // OR
  // [config.cancelKey]:  <Axios Canceler>
};

const getCancelTransactionKey = (config: RequestConfig) => {
  if (config.cancelKey) return config.cancelKey;

  // in the response interceptor, the request params are stringified
  const params = config.data
    ? typeof config.data === 'string'
      ? (JSON.parse(config.data) as object)
      : config.data
    : {};

  const requestParamString = Object.keys(params).join('|');

  return `${config.method}:${config.url}:${requestParamString}`;
};

/**
 * Looks for `cancelSimilarRequests` or `cancelKey` attributes in an intercepted axios config. If present,
 * a transaction key is set in `PENDING_AXIOS_TRANSACTIONS` whose value is a `CancelToken` instance for the
 * request. If a new request is created with the same transaction key, the pending request will be canceled.
 */

export const handleCancelableRequest = (config: RequestConfig) => {
  // For now, let's only assume we'll cancel proton rails api calls. We don't want to cancel requests to
  // media server, algolia, assets, etc
  if (!(config.url && config.url.includes(API_BASE!))) return;

  const transactionKey = getCancelTransactionKey(config);

  if (config.cancelSimilarRequests || config.cancelKey) {
    const cancelPendingRequest = PENDING_AXIOS_TRANSACTIONS[transactionKey];
    if (cancelPendingRequest) {
      consoleDev(`Canceling duplicate transaction ${transactionKey}`);
      cancelPendingRequest();
      // Because the config object is wiped out on cancellation, we need to decrement the count upon canceling
      // Since the request being canceled should be very similar to the new request, we can use the new request
      // config object to determine if the previous request was included in the request counter
      handlePendingRequestDecrement(config);
    }
  }

  // Save cancel token into memory
  config.cancelToken = new CancelToken(cancel => {
    PENDING_AXIOS_TRANSACTIONS[transactionKey] = cancel;
  });

  // Add a flag so that we know we need to remove this from PENDING_AXIOS_TRANSACTIONS once complete
  config.__transactionKey = transactionKey;
};

/**
 * When a request resolves, remove the request from `PENDING_AXIOS_TRANSACTIONS`
 */

export const handleCancelableRequestCleanup = (config: RequestConfig) => {
  // config gets wiped if a request is canceled
  if (!config) return;

  if (config.cancelToken && config.__transactionKey) {
    delete PENDING_AXIOS_TRANSACTIONS[config.__transactionKey];
  }
};

/**
 * Cancels all pending requests stored in `PENDING_AXIOS_TRANSACTIONS`
 */

export const cancelPendingRequests = () => {
  if (!isProduction) {
    console.warn('Canceling all pending requests', { ...PENDING_AXIOS_TRANSACTIONS });
  }

  Object.keys(PENDING_AXIOS_TRANSACTIONS).forEach(transactionKey => {
    const cancelRequestFunction = PENDING_AXIOS_TRANSACTIONS[transactionKey];

    if (cancelRequestFunction) {
      cancelRequestFunction();
      delete PENDING_AXIOS_TRANSACTIONS[transactionKey];
    }
  });
};
