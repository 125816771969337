/* eslint-disable import/prefer-default-export */
import differenceInMinutes from 'date-fns/differenceInMinutes';
import _get from 'lodash/get';

import { kFormatter } from './text';

export const isDataStale = (lastFetched, minutesToStale) => {
  // if lastFetched is null or undefined, then assume it was never fetched
  if (!lastFetched) return true;

  // NOTE: differenceInMinutes expects js dates.  lastFetched is the result of Date.now() and
  // can be converted back to a js date using new Date(lastFetched)
  return differenceInMinutes(new Date(), new Date(lastFetched)) > minutesToStale;
};

// linter frowns in use of isNaN directly...
// TODO: deprecate for lodash isNumber if it handles null / undefined
// https://stackoverflow.com/questions/46677774/eslint-unexpected-use-of-isnan
export const isNumber = value => !Number.isNaN(parseFloat(value));

/**
 *  [filterListData] - takes array of objects and query to filter specific list attributes
 *  returns data where query exact matches part of a value for any attribute passed
 *  @param {[object]} list REQUIRED
 *  @param {string} query REQUIRED value/partial value to search for in passed list
 *  @param {[string]} attributes REQUIRED array of attributes (strings) that the query will be tested against
 */

export const filterListData = (list = [], query, attributes) => {
  if (!query) return list;

  const regex = new RegExp(query, 'gi');
  return list.filter(item =>
    attributes.some(attr => {
      if (typeof attr === 'string') {
        const field = _get(item, attr);
        // must be string to use .match
        const matched = typeof field === 'string' && field.match(regex);
        return matched;
      }
      return false;
    })
  );
};

export const formatBeatportFollowerCount = followerCount => {
  if (!followerCount || followerCount < 500) return '<500';

  return kFormatter(followerCount);
};
