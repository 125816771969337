import React, { useState, useRef, useEffect, ReactNode } from 'react';
import { Keys } from 'react-keydown';
import { useSpring } from 'react-spring';

import history from 'config/history';
import { isTesting } from 'config/constants';

import Portal from 'components/Portal';
import Button from 'components/Button';
import LockBodyScroll from 'components/LockBodyScroll';
import closeIconWhite from 'images/icon-close--white.svg';

import ActionMenuList from './ActionMenuList';

import {
  ActionMenuBackground,
  ActionMenuWrapper,
  ActionMenuCardWrapper,
  ActionMenuCard,
  ButtonWrapper,
  ActionMenuContainer,
  ActionMenuCloseIcon
} from './styles';
import { ActionMenuItemProps } from './ActionMenuItem';
import { hideIntercomLauncher, showIntercomLauncher } from 'config/intercom';

export const ACTION_MENU_ANIMATE_DURATION_MS = 300; // time in ms

/*
 * [ActionMenu] - React hooks-based ActionMenu that renders into a Portal layer. Heck yeah.
 * @prop {node} [children] - react components to be wrapped and passed through
 * @prop {bool} [disabled] - lock down menu and menu items. Show disabled stat
 * @prop {object} location - copy of location object from withRouter
 * @prop {bool} [showCancel=true] - passed to ActionMenuList to show "Cancel" action
 * @prop {bool} [closeOnNavigation=true] - auto-closes when route changes
 * @prop {array} [actions] - array of actions to be auto-passed to ActionMenuList
 * @prop {bool} isOpen - boolean passed in from the parent, typically via the `useToggle` hook
 * @prop {func} close - function passed in from the parent, typically via the `useToggle`
 */

/* Special Dev Notes
 * There's four variables in this component that can be easy to confuse:
 * 1. `isOpen` - This is passed in from the parent and is what triggers the ActionMenu to actually open/close.
 * 2. `isRendered` - Are the DOM elements are actually rendered?
 * 4. `isAnimating` - Is the component currently animating open or closed? Prevents component from de-rendering
 *  itself before react-spring finishes its animations.
 */

export interface ActionMenuToggleState {
  isOpen: boolean;
  close: () => void;
  closeOnNavigation?: boolean;
  closeAll?: () => void;
}

interface Props extends ActionMenuToggleState {
  children?: ReactNode | ((props: { close: () => void }) => ReactNode);
  disabled?: boolean;
  showCancel?: boolean;
  actions?: ActionMenuItemProps[];
  testId?: string;
}

const ActionMenu = (props: Props) => {
  const {
    children,
    closeOnNavigation = true,
    disabled,
    showCancel = true,
    actions = [],
    isOpen,
    close,
    testId
  } = props;

  const [isRendered, setIsRendered] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const prevLocation = useRef(history.location.pathname);

  const actionMenuRef = useRef<HTMLDivElement>(null);

  // handle opening
  useEffect(() => {
    if (isOpen && !isRendered) {
      hideIntercomLauncher();
      setIsRendered(true);
      setIsAnimating(true);
    }
  }, [isOpen, isRendered]);

  // handle closing
  useEffect(() => {
    if (!isOpen && isRendered) {
      setIsAnimating(true);
      //short timeout to wait for close animation to finish
      setTimeout(() => showIntercomLauncher(), 300);
    }
  }, [isOpen, isRendered]);

  // ////////
  // Setup event listeners for ESC key and clicking out to close Popover.
  useEffect(() => {
    const onEsc = event => {
      if (disabled) return;

      if (event.keyCode === Keys.ESC) {
        close();
      }
    };

    if (isRendered) {
      document.addEventListener('keydown', onEsc);
    }

    return () => {
      document.removeEventListener('keydown', onEsc);
    };
  }, [close, disabled, isRendered]);

  const isScrollable = actionMenuRef.current
    ? actionMenuRef.current.getBoundingClientRect().height > window.innerHeight &&
      !isAnimating
    : false;

  const paddingTop = window.innerHeight * 0.33;

  // ////////
  // Automatically close ActionMenu if the window location changes.
  useEffect(() => {
    if (closeOnNavigation) {
      const unlisten = history.listen(location => {
        if (prevLocation.current && prevLocation.current !== location.pathname) {
          close();
        }
        prevLocation.current = location.pathname;
      });
      return () => unlisten();
    }
  }, [close, closeOnNavigation]);

  const handleBackgroundClick = e => {
    // NOTE: was having an issue with close event not working without this:
    e.stopPropagation();

    if (disabled) return;
    close();
  };

  // ////////
  // Animation props to pass to styled(animated.div) <Card> element.
  const animateOpen = isOpen && isRendered;
  const styleProps = useSpring({
    transform: animateOpen ? 'translate3d(0, 0%, 0)' : 'translate3d(0, 200%, 0)',
    onRest: () => {
      setIsAnimating(false);
      if (!isOpen && isRendered) {
        setIsRendered(false);
      }
    },
    immediate: isTesting
  });

  const backgroundStyleProps = useSpring({
    opacity: animateOpen ? '1' : '0',
    immediate: isTesting
  });

  if (!isRendered) return null;

  return (
    <>
      <LockBodyScroll />

      <Portal domNode={document.getElementById('action-menus-root')}>
        <ActionMenuContainer data-testid={testId || 'ActionMenu-container'}>
          <ActionMenuBackground
            style={backgroundStyleProps}
            onClick={handleBackgroundClick}
          >
            <ActionMenuCloseIcon>
              {' '}
              <img src={closeIconWhite} width="20" height="20" alt="X Icon" />
              <div data-testid="ActionMenu-close">ESC</div>
            </ActionMenuCloseIcon>
          </ActionMenuBackground>

          <ActionMenuWrapper $isScrollable={isScrollable}>
            <ActionMenuCardWrapper $paddingTop={paddingTop}>
              <ActionMenuCard style={styleProps} data-testid="ActionMenu-content">
                <div ref={actionMenuRef}>
                  {/* TODO: WHY IS THIS AUTO-CLOSING??? */}
                  {typeof children === 'function' ? children({ close }) : children}

                  {actions.length > 0 && (
                    <ActionMenuList actions={actions} closeMenu={close} />
                  )}

                  {showCancel && (
                    <ButtonWrapper>
                      <Button
                        color={Button.COLORS.NEUTRAL}
                        size={Button.SIZES.LARGE}
                        onClick={e => {
                          e.stopPropagation();
                          close();
                        }}
                        disabled={disabled}
                        expand
                        data-testid="ActionMenuItem-cancel"
                      >
                        Cancel
                      </Button>
                    </ButtonWrapper>
                  )}
                </div>
              </ActionMenuCard>
            </ActionMenuCardWrapper>
          </ActionMenuWrapper>
        </ActionMenuContainer>
      </Portal>
    </>
  );
};

// NOTE: For some reason, appears you cannot assign statics to React Memo components
// https://reactjs.org/docs/higher-order-components.html#static-methods-must-be-copied-over
export const ACTION_MENU_SELECTORS = {
  CONTAINER: '[data-testid=ActionMenu-container]',
  CONTENT: '[data-testid=ActionMenu-content]',
  CANCEL_BTN: '[data-testid=ActionMenuItem-cancel]'
};

export default ActionMenu;
