import queryString from 'query-string';

import _omit from 'lodash/omit';
import history from 'config/history';

// eslint-disable-next-line import/prefer-default-export
export const getTrackUrlFilters = (location = window.location) => {
  const { labels: queryLabelIds = [] } = queryString.parse(location.search, {
    arrayFormat: 'comma'
  });
  const filterLabelIds = Array.isArray(queryLabelIds) ? queryLabelIds : [queryLabelIds];
  return {
    labelIds: filterLabelIds
    // returning object as we'll likely add more filters in the future
  };
};

/**
 * [getNextQueryString] - returns the query string for adding, removing, or replacing params in the url search params
 *
 * @param {object} [paramsToAdd] - key value pair to add to the existing search params
 * @param {string[]} [paramsToRemove] - array of param keys to remove from exisiting search params
 * @param {object} [params] - key value pair of params to replace existing search params
 * @returns {string}
 */

export const getNextQueryString = ({ paramsToAdd, paramsToRemove, params }) => {
  const { location } = history;

  if (paramsToAdd) {
    return queryString.stringify({
      ...queryString.parse(location.search),
      ...paramsToAdd
    });
  }

  if (paramsToRemove) {
    const queryParams = queryString.parse(location.search);
    const newQueryParams = _omit(queryParams, paramsToRemove);
    return queryString.stringify(newQueryParams);
  }

  return queryString.stringify(params);
};

/**
 * [addQueryParams] - adds query param(s) to the current url
 *
 * @param {object} params - key value pairing of new params to add
 */

export const addQueryParams = (params = {}) => {
  const { location } = history;

  const newSearchString = getNextQueryString({ paramsToAdd: params });

  history.replace({
    pathname: location.pathname,
    search: newSearchString
  });
};

/**
 * [replaceQueryParams] - replace the current query param(s) with passed params
 *
 * @param {object} params - key value pairing of new params
 */

export const replaceQueryParams = params => {
  const { location } = history;

  const newSearchString = getNextQueryString({ params });

  history.replace({
    pathname: location.pathname,
    search: newSearchString
  });
};

/**
 * [removeQueryParam] - remove query param(s) from the current url
 *
 * @param {string | string[]} params - name of query param. Pass array of names if multiple
 */

export const removeQueryParams = params => {
  const { location } = history;

  const newSearchString = getNextQueryString({ paramsToRemove: params });

  history.replace({
    pathname: location.pathname,
    search: newSearchString
  });
};
