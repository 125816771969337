import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';

import { AUDIO_TYPES } from 'config/constants';
import { isProUser } from 'helpers/user';
import { selectUser } from './user';
import Bugsnag from '@bugsnag/js';
import { Audio } from 'helpers/tracks';
import { V2ProUser } from 'types';
import { RootState } from 'config/store';
import { ReleaseEdge } from 'gql/graphql';

export const selectPromoReleasesRoot = state => state.promoReleases;

const selectAllPromoReleases = state => selectPromoReleasesRoot(state).releasesById;

/**
 * [selectPromoReleaseIdsFollowingRoot] - releaseIdsFollowing root state
 *
 * @returns {object}
 * {
 *   data: [73371, 73372, 73374], // releaseIds on promo for labels user is following
 *   __fetchError: null,
 *   __isFetching: false,
 *   __lastFetched: 1570217612387
 * }
 */

export const selectPromoReleaseIdsFollowingRoot = state =>
  selectPromoReleasesRoot(state).releaseIdsFollowing;

export const selectFollowingPromoReleaseIds = state =>
  selectPromoReleaseIdsFollowingRoot(state).data;

interface ReleasesById {
  [releaseId: string]:
    | {
        trackIds: number[];
      }
    | undefined;
}

export const selectFollowingPromoTrackIds = createSelector(
  [selectFollowingPromoReleaseIds, selectAllPromoReleases],
  (releaseIds: number[], releasesById: ReleasesById) => {
    // NOTE: During logout, it appears the releasesById can be cleared prior to releaseIdsFollowing, resulting in
    // releasesById[releaseId] being undefined, so we include the optional chaining (?.) operator. Adding logging
    // to bugsnag to see if we can track down the root cause.
    const missingReleases: number[] = [];

    const trackIds = _.uniq(
      releaseIds
        .map(releaseId => {
          if (!releasesById[releaseId]) {
            missingReleases.push(releaseId);
            return [];
          }
          return releasesById[releaseId]?.trackIds;
        })
        .flat()
    );

    if (missingReleases.length) {
      const metaData = {
        missingReleases,
        releaseIds,
        releasesByIdCount: Object.keys(releasesById).length,
        trackIdsCount: trackIds.length
      };

      Bugsnag.notify(
        new Error('Missing releases in promoReleases.releasesById'),
        event => {
          event.addMetadata('data', metaData);
        }
      );
    }

    return trackIds;
  }
);

/**
 * [selectPromoTrackRootById]
 *
 * @returns {object || undefined}
 * {
 *   id: 323881
 *   reaction: { bio_id: 42772, rating: 1, comment: null }
 *   releaseId: 78631
 * }
 */

export const selectPromoTrackRootById = (
  state: RootState,
  { trackId }: { trackId: number }
) => state.promoReleases.tracksById[trackId];

/**
 * [selectPromoReleaseRootById || selectPromoReleaseRootByTrackId]
 *
 * @returns {object || undefined}
 * {
 *   id: 124,
 *   label_id: 55,
 *   bio_ids: [12, 124],
 *   trackIds: [303726, 303727]
 * }
 */

export const selectPromoReleaseRootById = (
  state: RootState,
  { releaseId }: { releaseId: number }
) => selectAllPromoReleases(state)[releaseId];

export const selectPromoReleaseRootByTrackId = (
  state,
  { trackId }: { trackId: number }
) => {
  const trackRoot = selectPromoTrackRootById(state, { trackId });
  const releaseId = trackRoot && trackRoot.releaseId;

  return selectPromoReleaseRootById(state, { releaseId });
};

export type PromoTrackReaction = {
  bio_id: number;
  comment: string | null;
  dismissed_at: string | null;
  downloaded_at: string | null;
  id: number;
  listened_at: string | null;
  rating: number;
  reacted_at: string | null;
  track_id: number;
  __optimistic: PromoTrackReaction | null;
  __updating: boolean;
};

/**
 * [selectPromoTrackReaction] - returns track reaction (default reaction is null)
 *
 * @param {object} state
 * @param {number} trackId
 *
 * @returns {object || null}
 * {
 *   rating: 5,
 *   bio_id: 12,
 *   comment: 'cool',
 *   __optimistic: null // represents last known state from api, field only exists if optimistic update in progress
 * }
 */
export const selectPromoTrackReaction = (
  state: RootState,
  trackId: number
): PromoTrackReaction | null => {
  const promoTrack = state.promoReleases.tracksById[trackId] || {};
  return promoTrack.reaction || null;
};

export const promoTrackHasReaction = (state: RootState, trackId: number): boolean => {
  const reaction = selectPromoTrackReaction(state, trackId);
  // if an optimistic update was in progress on a track that previously didn't have a reaction,
  // '__optimistic' will be set to null
  const lastState =
    reaction && '__optimistic' in reaction ? reaction.__optimistic : reaction;
  return !!lastState;
};

/**
 * [selectPromoTrackReactionArtistId] - use to return the artist Id that should be used for reacting to
 * a promo track.  If a reaction already exists from an artist, that artist id is used,
 * otherwise the first artist id in the attribute 'bio_ids' is used. bio_ids represents
 * all artists the user manages that have access to review a given promo.
 */
export const selectPromoTrackReactionArtistId = (
  state: RootState,
  releaseId: number,
  trackId: number
): number => {
  const promoRelease = selectPromoReleaseRootById(state, { releaseId });
  const promoTrackReaction =
    selectPromoTrackReaction(state, trackId) || ({} as PromoTrackReaction);

  // NOTE: it's possble in some states (like masquerade) promoRelease are temporarily undefined
  return promoTrackReaction.bio_id || promoRelease?.bio_ids[0];
};

export const selectInboxReleaseIdsFollowingRoot = (
  state: RootState
): { data: number[] } => selectPromoReleasesRoot(state).releaseIdsForInbox;

export const selectInboxReleaseIds = (state: RootState): number[] =>
  selectInboxReleaseIdsFollowingRoot(state).data;

/**
 * [selectPromoReleasesWithTrackIds] -returns promoReleases for labels following
 *
 * [
 *  {
 *    id: 4124,
 *    bio_ids: [123, 5241],
 *    trackIds: [124124, 124125, 124126]
 *  }
 * ]
 */
export const selectPromoReleasesWithTrackIds = createSelector(
  [selectInboxReleaseIds, selectAllPromoReleases],
  (releaseIds: number[], releasesById: Record<number, ReleaseEdge>) =>
    releaseIds.map(releaseId => releasesById[releaseId])
);

/**
 * [selectPromoTrackArtistIds] - returns the bio_ids allowed to react to a given promo release.
 * If no promo release is found, returns empty array.
 *
 * Accepts either promo trackId or releaseId
 */

export const selectPromoTrackArtistIds = (
  state,
  { trackId, releaseId }: { trackId: number; releaseId: number }
): number[] => {
  const releaseRoot = trackId
    ? selectPromoReleaseRootByTrackId(state, { trackId })
    : selectPromoReleaseRootById(state, { releaseId });

  return releaseRoot.bio_ids || [];
};

/**
 * [selectIsTrackOnPromo] - selector that will return boolean for passed audio indicating whether user
 * has this track available to them in their promo releases
 *
 * NOTE: can pass either the audio object from redux if available, or just the trackId if it's known
 * you are dealing w/ a track
 * @param {object} state - Redux state
 * @param {object} options - Options object
 * @param {Audio} [options.audio] - Audio object from redux
 * @param {number} [options.trackId] - Track ID
 */

export const selectIsTrackOnPromo = createSelector(
  [
    selectFollowingPromoTrackIds,
    selectUser,
    (__, { audio }) => audio,
    (__, { trackId }) => trackId // optional
  ],
  (promoTrackIds: number[], user: V2ProUser, audio: Audio, trackId: number): boolean => {
    if (!isProUser(user)) return false;
    if (audio && audio.__audioType !== AUDIO_TYPES.TRACK) return false;

    return promoTrackIds.includes(audio?.id || trackId);
  }
);
