import classnames from 'classnames';
/* global window */
import PropTypes from 'prop-types';
import React, { Component, useEffect, useState } from 'react';

import ScrollBox from 'components/ScrollBox';
import { AUDIO_TYPES } from 'config/constants';
import useProtonPlayer, { usePlayheadPosition } from 'hooks/useProtonPlayer';

import TrackItem from '../PlayerTracklistItem';

const NAV_HEADER = 30;
const TRACKLIST_HEIGHT = 40;
const TRACK_WIDTH = 370;
const SCROLL_PAD_X = TRACK_WIDTH / 2;
const TRACK_HEIGHT = 80 + 15;
const BREAKPOINT = 768;

const _getOrientation = () =>
  window.innerWidth > BREAKPOINT ? 'horizontal' : 'vertical';

class PlayerTracklist extends Component {
  static _toggleBodyClass(isVisible) {
    if (isVisible) {
      document.body.classList.add('has-open-tracklist');
    } else {
      document.body.classList.remove('has-open-tracklist');
    }
  }

  // based on received props, determine if tracklist should be hidden
  static _shouldHideTracklist(nextProps, prevProps) {
    const pathChanged = prevProps.location.pathname !== nextProps.location.pathname;
    const isVertical = _getOrientation() === 'vertical';
    const hasTracklist =
      nextProps.playing.tracklist && nextProps.playing.tracklist.length;

    const shouldHide =
      ((pathChanged && isVertical) || !hasTracklist) && nextProps.tracklistVisibility;
    return shouldHide;
  }

  constructor(props) {
    super(props);
    this.state = {
      currentTrack: 0, // starts at 0
      scrollPositionX: 0,
      scrollPositionY: 0,
      orientation: _getOrientation()
    };

    this._handleScroll = this._handleScroll.bind(this);
    this._updateWindowWidth = this._updateWindowWidth.bind(this);
    this.userScroll = null;
    this.lastCalcPosition = 0;
  }

  componentDidMount() {
    window.addEventListener('resize', this._updateWindowWidth);
    PlayerTracklist._toggleBodyClass(this.props.tracklistVisibility);

    this._setRadioTracker();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.tracklistVisibility !== nextProps.tracklistVisibility) return true;
    if (this.state.currentTrack !== nextState.currentTrack) return true;
    if (this.props.audioType !== nextProps.audioType) return true;
    if (this.state.scrollPositionX !== nextState.scrollPositionX) return true;
    if (PlayerTracklist._shouldHideTracklist(nextProps, this.props)) return true;
    if (this._mixTrackShouldBeCalculated(this.props, nextProps)) return true;

    const oldTrackList = this.props.playing.tracklist;
    const newTrackList = nextProps.playing.tracklist;

    if (!newTrackList || !oldTrackList) return false;
    if (oldTrackList.length !== newTrackList.length) return true;

    return oldTrackList
      .map(
        (t, i) => t.artist !== newTrackList[i].artist || t.track !== newTrackList[i].track
      )
      .some(isTrue => isTrue);
  }

  componentDidUpdate(prevProps) {
    // Ensure interval for radio tracking is set or cleared
    if (this.props.audioType === AUDIO_TYPES.RADIO) this._setRadioTracker();
    if (this.props.audioType !== AUDIO_TYPES.RADIO) this._clearRadioTracker();

    // re-check with _mixTrackShouldBeCalculated because componentDidUpdate triggered for various condtions
    if (this._mixTrackShouldBeCalculated(prevProps, this.props)) {
      this._calculateCurrentTrack();
    }

    PlayerTracklist._toggleBodyClass(this.props.tracklistVisibility);

    if (PlayerTracklist._shouldHideTracklist(this.props, prevProps)) {
      this.props.setTracklistVisibility(false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    PlayerTracklist._toggleBodyClass(false);

    this._clearRadioTracker();
  }

  // if user scrubbed a significant amount, update track calculation
  // Only update if position has changed by 5s or more since position is set frequently
  _mixTrackShouldBeCalculated = (prevProps, nextProps) => {
    const isMix = nextProps.audioType === AUDIO_TYPES.MIX;
    const wasMix = prevProps.audioType === AUDIO_TYPES.MIX;
    const positionChanged =
      nextProps.position !== prevProps.position || !Number.isNaN(prevProps.position);

    if (isMix && prevProps.playing.id !== nextProps.playing.id) {
      return true;
    }

    if (isMix && positionChanged) {
      // if change is more than 5 seconds, update
      if (Math.abs(nextProps.position - this.lastCalcPosition) > 5) return true;
      // if switching from radio to mix, ensure run calculation is re-run
      if (isMix && !wasMix) return true;
    }

    return false;
  };

  _updateWindowWidth() {
    const orientation = _getOrientation();
    if (orientation !== this.state.orientation) {
      // If orientation changed and scroll position is still 0, try centering
      const initHorizontal =
        orientation === 'horizontal' && this.state.scrollPositionX === 0;
      const initVertical = orientation === 'vertical' && this.state.scrollPositionY === 0;
      if (initHorizontal || initVertical) {
        this._scrollToCurrentTrack();
      }
      this.setState({ orientation });
    }
  }

  // Because radio isn't polled for current time, we need to recalculate track position occasionally
  _setRadioTracker = () => {
    if (this.props.audioType === AUDIO_TYPES.RADIO && !this.radioInterval) {
      this._calculateCurrentTrack();
      this.radioInterval = setInterval(this._calculateCurrentTrack, 5000);
    }
  };

  _clearRadioTracker = () => {
    if (this.radioInterval) {
      clearInterval(this.radioInterval);
      this.radioInterval = null;
    }
  };

  _handleScroll({ scrollLeft, scrollTop }, animatedScroll) {
    if (!animatedScroll) this.userScroll = Date.now();

    if (_getOrientation() === 'horizontal') {
      this.setState({ scrollPositionX: scrollLeft });
    } else {
      this.setState({ scrollPositionY: scrollTop });
    }
  }

  // Detects if user has scrolled the tracklist in last 2 seconds
  _userScrolledRecently() {
    if (this.userScroll) {
      const scrollTime = this.userScroll;
      const elapsedSeconds = Date.now() - scrollTime;
      return elapsedSeconds < 2;
    }
    return false;
  }

  _calculateTrackOffset = _props => {
    const props = _props || this.props;

    if (props.audioType === AUDIO_TYPES.RADIO) {
      const {
        radioLastUpdated,
        playing: { current_time, start_time }
      } = props;

      return current_time - start_time + (Date.now() - radioLastUpdated) / 1000; // radio
    }

    const { position } = props;
    this.lastCalcPosition = position;
    return position; // mixes
  };

  _calculateCurrentTrack = _props => {
    const props = _props || this.props;
    const {
      tracklist,
      mix_length // time in seconds
    } = props.playing;

    if (
      ![AUDIO_TYPES.RADIO, AUDIO_TYPES.MIX].includes(props.audioType) ||
      !tracklist.length
    )
      return;

    const currentOffset = this._calculateTrackOffset(props);
    const currentTrack = Math.floor((currentOffset * tracklist.length) / mix_length); // kruis-tabel

    if (this.state.currentTrack !== currentTrack) {
      this.setState(
        {
          currentTrack
        },
        () => {
          // don't scroll to track if user recently scrolling
          if (!this._userScrolledRecently()) {
            this._scrollToCurrentTrack();
          }
        }
      );
    }
  };

  _scrollToCurrentTrack() {
    const { currentTrack } = this.state;
    // if (currentTrack > 0) return;

    // NOTE — About scrollDistance:
    // Scroll methods are based on absolute scroll positioning, so we calculate
    // scroll distance based on the current track number, track widths/heights, and
    // tracklist container padding, with half a track width/height subtracted to
    // center it properly.
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const trackNumber = currentTrack + 1;

    if (windowWidth > BREAKPOINT) {
      const scrollPositionX =
        SCROLL_PAD_X + TRACK_WIDTH * (trackNumber - 0.5) - windowWidth / 2;
      this.setState({ scrollPositionX });
    } else {
      const viewAreaCenter = (windowHeight - NAV_HEADER - TRACKLIST_HEIGHT) / 2;
      const scrollPositionY = TRACK_HEIGHT * (trackNumber - 0.5) - viewAreaCenter;
      this.setState({ scrollPositionY });
    }
  }

  _calculateTrackClass(trackPosition) {
    const trackDiff = this.state.currentTrack - trackPosition + 1;
    const trackClasses = {
      0: 'is-next',
      1: 'is-playing',
      2: 'is-previous'
    };
    return trackClasses[trackDiff];
  }

  _handleScrollClick(direction) {
    // Currently only supporting horizontal
    const scroll = direction === 'forward' ? TRACK_WIDTH : -TRACK_WIDTH;
    let scrollPosition = this.state.scrollPositionX + scroll;

    // TODO: should scroll center to the nearest track?  Doesn't on production

    // -- prevent adjusting scroll position if scrolled to first/last track --
    // first track:
    if (scrollPosition < 0) scrollPosition = 0;
    // last track:
    const { tracklist } = this.props.playing;
    const maxScroll = TRACK_WIDTH * (tracklist.length - 1);
    if (scrollPosition > maxScroll) scrollPosition = maxScroll;

    this.setState({ scrollPositionX: scrollPosition });
  }

  _renderScrollButton(direction) {
    return (
      <div
        className={`Tracklist__scroll-btn Tracklist__scroll-btn--${direction}`}
        onClick={() => this._handleScrollClick(direction)}
        role="button"
        tabIndex="0"
      />
    );
  }

  render() {
    const {
      tracklistVisibility,
      playing: { tracklist = [] }
    } = this.props;
    if (!tracklist || tracklist.length < 3) return false;

    const visibleClass = tracklistVisibility ? 'is-visible' : '';
    const classes = classnames(['Tracklist', 'Player__tracklist', visibleClass]);

    return (
      <div className={classes}>
        {this._renderScrollButton('backward')}
        {this._renderScrollButton('forward')}

        <div className="Tracklist__container">
          <ScrollBox
            scrollClass="Tracklist__scroll"
            x={this.state.scrollPositionX}
            y={this.state.scrollPositionY}
            duration={500}
            handleScroll={(e, animated) => {
              const { scrollLeft, scrollTop } = e.target;
              this._handleScroll({ scrollLeft, scrollTop }, animated);
            }}
          >
            <ul className="Tracklist__list">
              {tracklist.map((track, i) => (
                <TrackItem
                  key={`${track.track}-${i}`} // eslint-disable-line
                  index={i}
                  artist={track.artist}
                  label={track.label}
                  track={track.track}
                  trackClass={this._calculateTrackClass(i)}
                />
              ))}
            </ul>
          </ScrollBox>
        </div>
      </div>
    );
  }
}

PlayerTracklist.propTypes = {
  // From parent
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  tracklistVisibility: PropTypes.bool.isRequired,

  // TODO (Rob): Fix commented out proptypes
  // redux state
  position: PropTypes.number,
  audioType: PropTypes.string.isRequired // mixes or radio
};

export default props => {
  const player = useProtonPlayer();
  const playing = player.currentTrack;
  const [position, setPosition] = useState(0);

  usePlayheadPosition(playheadTime => {
    setPosition(playheadTime);
  });

  const normalizedPlaying = {
    ...playing,
    tracklist: playing.tracklist || playing.algolia_tracklist
  };

  return (
    <PlayerTracklist
      {...props}
      audioType={playing?.type}
      position={position}
      playing={normalizedPlaying}
    />
  );
};
