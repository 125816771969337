import PropTypes from 'prop-types';
import { COLORS } from 'config/constants';

/**
 * [DEFAULT_ICON_COLOR] - by default, unless an icon is overwhelmingly used with a particular color (i.e. logos)
 * we should strive to use the same default icon color.  You can override on a case-by-base basis
 */

export const DEFAULT_ICON_COLOR = COLORS.GRAY_MEDIUM;

// common props for svg icon components (components/icons)
export const ICON_PROP_TYPES = {
  className: PropTypes.string,
  color: PropTypes.string,
  'data-testid': PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
  title: PropTypes.string
};
