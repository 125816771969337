import { AUDIO_TYPES } from 'config/constants';

const RADIO_MIX_INITIAL_STATE = {
  artist_id: null,
  artist_image: '',
  artist_name: '',
  artist_slug: '',
  authorize_url: '',
  current_time: null,
  currentlisteners: null,
  date: '',
  end_time: null,
  episode_date: '',
  error_count: 0,
  filename: '',
  genre_id: null,
  genre_name: '',
  genre_slug: '',
  has_artist_image: null,
  id: null,
  listeners: '',
  loaded: false,
  max_l: null,
  md5: '',
  mix_date: '',
  mix_info: '',
  mix_length: null,
  mix_name: '',
  mix_slug: '',
  next: {},
  ondemandlistens: null,
  order: null,
  playing: null,
  show_id: null,
  show_image: null,
  show_name: '',
  show_slug: '',
  show_title: '',
  showdate_id: null,
  start_time: null,
  stream: null,
  sum_l: null,
  tempUrl: '',
  total_sets: '',
  track_artists: [],
  tracklist: [],
  tracklist_total: '',
  tracktion: null,
  type: AUDIO_TYPES.RADIO,
  url: '',
  waveform_url: ''
};

export default RADIO_MIX_INITIAL_STATE;
