export const EXTERNAL_LINKS = {
  INTERCOM: {
    ARTIST_MIXES_GUIDE:
      'https://intercom.help/proton-radio/en/articles/3363181-artist-mixes-guide-faq',
    LOCKED_TRACKS:
      'https://intercom.help/proton-radio/en/articles/3372277-dj-mixes-locked-tracks-on-proton',
    MIX_DEMO_SUBMIT:
      'https://intercom.help/proton-radio/proton-radio/how-do-i-get-a-mix-or-radio-show-on-proton',
    SOUNDSYSTEM_FAQ:
      'https://intercom.help/proton-radio/en/articles/3078974-proton-soundsystem-faq',
    SUBMIT_UNSIGNED_DEMOS:
      'https://intercom.help/proton-radio/en/articles/2872819-how-can-i-submit-unsigned-demos-to-proton-s-labels',
    TRACK_DEMO_SUBMIT:
      'https://intercom.help/proton-radio/soundsystem/how-can-i-submit-unsigned-demos-to-protons-labels'
  },
  SOUNDSYSTEM: {
    HOME: 'https://soundsystem.protonradio.com/',
    LABELS: 'https://soundsystem.protonradio.com/main.php?tab=labels',
    MIXES: 'https://soundsystem.protonradio.com/main.php?tab=mixes'
  }
};
