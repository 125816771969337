import styled from 'styled-components';
import { animated } from 'react-spring';
import { MEDIUM_BREAK_POINT } from 'config/constants';

export const ActionMenuContainer = styled.div`
  display: flex;
  height: 100vh;
  justify-content: flex-end;
  width: 100vw;

  @media (min-width: ${MEDIUM_BREAK_POINT}px) {
    align-items: center;
    justify-content: center;
  }
`;

export const ActionMenuBackground = styled(animated.div)`
  background: rgba(0, 0, 0, 0.75);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  // Popover z-index + 1
  z-index: 10;
`;

export const ActionMenuWrapper = styled.div<{ $isScrollable?: boolean }>`
  flex: 1;
  max-height: 100vh;
  overflow-y: ${({ $isScrollable }) => ($isScrollable ? 'scroll' : 'none')};
  position: fixed;
  z-index: 10;

  @media (max-width: ${MEDIUM_BREAK_POINT}px) {
    bottom: 0;
    left: 0;
    width: 100%;
  }

  @media (min-width: ${MEDIUM_BREAK_POINT}px) {
    width: auto;
  }
`;

export const ActionMenuCardWrapper = styled.div<{ $paddingTop?: number }>`
  /* margin-top: ${({ $paddingTop }) => `${$paddingTop}px`}; */
`;

export const ActionMenuCard = styled(animated.div)`
  background: #ffffff;
  border-radius: 0.8rem 0.8rem 0 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 0 20px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 1;

  @media (min-width: ${MEDIUM_BREAK_POINT}px) {
    border-radius: 0.8rem;
    min-width: 300px;
  }
`;

export const ActionMenuCloseIcon = styled.div`
  color: #ffffff;
  padding: 1.5rem 1rem 1rem 1rem;
  position: fixed;
  pointer-events: none;
  right: 0rem;
  text-align: center;
  top: 1rem;
  transform: translate3d(0, 0, 0); /* GPU accelerates this element */
  transition: visibility 0ms ease-out 0ms, opacity 0.35s ease-out;

  div {
    display: block;
    font-weight: 500;
    letter-spacing: 0.1em;
    margin: 0.5rem;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 0 1.5rem;
  padding-bottom: 1.5rem;
`;
