import React from 'react';

import Portal from 'components/Portal';
import MagicLinkVerificationModal from './MagicLinkVerificationModal';
import MFARequiredModal from './MFARequiredModal';
import { PrivacyPolicyChangeLogModal } from './PrivacyPolicyChangeLog';

const GlobalModals = () => (
  <Portal domNode={document.getElementById('modals-root')}>
    <MFARequiredModal />
    <MagicLinkVerificationModal />
    <PrivacyPolicyChangeLogModal />
  </Portal>
);

export default GlobalModals;
