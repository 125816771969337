import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import ENTITY_PROPTYPES from 'config/constants/propTypes';
import { generateSelectorsFromTestIds } from 'helpers/utilities';
import { selectPromoTrackReaction } from 'redux/selectors';

import Icon from 'components/Icon';
import { EllipsisButton } from 'components/Button';
import TrackPlayTile from 'components/TrackPlayButton/TrackPlayTile';
import TrackMobileTileMeta from './TrackMobileTileMeta';

const StyledTileContainer = styled.div`
  background: #ffffff;
  display: flex;
`;

const StyledPlayTile = styled.div`
  ${({ loading }) =>
    !loading &&
    `
    cursor: pointer;
  `};

  display: flex;
  flex: 1;
  outline: none;
  padding: 0.8rem 0.8rem 0.8rem 0;
`;

const TrackMobileTile = ({ track, onClick, onToggle, isDownloadable, showCompile }) => {
  const reaction = useSelector(state => selectPromoTrackReaction(state, track.id));

  return (
    <StyledTileContainer data-testid={TrackMobileTile.TEST_IDS.CONTAINER}>
      <StyledPlayTile onClick={onClick}>
        <TrackPlayTile track={track} width={60} />

        <TrackMobileTileMeta track={track} showCompile={showCompile} />
      </StyledPlayTile>

      <EllipsisButton onClick={onToggle} data-testid={TrackMobileTile.TEST_IDS.TOGGLE}>
        {isDownloadable && (
          <Icon
            type={Icon.TYPES.DOWNLOAD}
            color={reaction?.downloaded_at ? Icon.COLORS.PRIMARY : Icon.COLORS.LIGHT}
            width={16}
            className="mr-3"
            data-testid="track-download-icon"
          />
        )}
      </EllipsisButton>
    </StyledTileContainer>
  );
};

TrackMobileTile.Tombstone = () => (
  <StyledTileContainer>
    <StyledPlayTile loading="true">
      <TrackPlayTile.Tombstone width={60} />

      <TrackMobileTileMeta.Tombstone />
    </StyledPlayTile>
  </StyledTileContainer>
);

TrackMobileTile.TEST_IDS = {
  CONTAINER: 'TrackMobileTile',
  TOGGLE: 'TrackMobileTile-toggle'
};

TrackMobileTile.SELECTORS = generateSelectorsFromTestIds(TrackMobileTile.TEST_IDS);

TrackMobileTile.propTypes = {
  track: ENTITY_PROPTYPES.TRACK.isRequired,
  onToggle: PropTypes.func,
  onClick: PropTypes.func,
  isDownloadable: PropTypes.bool,
  showCompile: PropTypes.bool
};

export default TrackMobileTile;
