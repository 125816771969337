import styled from 'styled-components';
import { StyledTombstoneLight } from 'components/Tombstone/styles';

export const StyledButtonTombstone = styled.div`
  ${StyledTombstoneLight};
  border-radius: 1px;
  height: ${({ $width }) => `${$width}px`};
  margin: 0.2rem;
  width: ${({ $width }) => `${$width}px`};
`;
