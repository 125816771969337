import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
  SidebarGroup,
  SidebarGroupSection,
  Label,
  StyledTextBody
} from 'components/TabbedHeaderLayout/Sidebar';
import TextExpander from 'components/TextExpander';
import CommaList from 'components/CommaList';
import { TombstoneText } from 'components/Tombstone';

import Button, { ButtonGroup } from 'components/Button';
import { DspIcon, LinkIcon } from 'components/Icons';
import { releaseStoreInfoButtonsTransform } from 'graphql/transforms';
import { Location } from 'history';
import { FragmentType, getFragmentData, graphql } from 'gql';

interface ReleaseDetailsProps {
  releaseDetail: FragmentType<typeof SideBar_ReleaseFragment> | null | undefined;
  loading?: boolean;
  sidebar?: boolean;
  shareLocation?: Partial<Location<{ modal?: boolean; noScroll?: boolean }>>;
}

const SideBar_ReleaseFragment = graphql(`
  fragment ReleaseDetailsFields on Release {
    date
    description
    label {
      name
      slug
    }
    genres {
      name
      id
      occurrences
    }
    storeInfo {
      name
      url
      status
    }
  }
`);

const ReleaseDetails = ({
  releaseDetail,
  loading,
  sidebar,
  shareLocation
}: ReleaseDetailsProps) => {
  const release = getFragmentData(SideBar_ReleaseFragment, releaseDetail);
  const sortedGenres = useMemo(() => {
    const genres = release?.genres || [];
    return genres.sort((a, b) => b.occurrences - a.occurrences);
  }, [release?.genres]);

  if (loading || !release) return <ReleaseDetails.Tombstone />;
  const { description, label, date } = release;
  const dspLinks = releaseStoreInfoButtonsTransform({ release });

  return (
    <div data-testid={ReleaseDetails.TEST_IDS.CONTAINER}>
      {sidebar && (
        <ButtonGroup vertical noMargin>
          <Button
            to={shareLocation}
            data-testid={ReleaseDetails.TEST_IDS.RELEASE_LINK_SHARE_BTN}
            style={{ justifyContent: 'flex-start' }}
          >
            <LinkIcon color="#fff" size={20} className="mr-1" />
            Share Release Link
          </Button>

          {dspLinks.map(link => (
            <Button
              key={link.name}
              to={link.url}
              style={{ justifyContent: 'flex-start' }}
              newTab
              color={Button.COLORS.SUPER_LIGHT}
              data-testid={ReleaseDetails.TEST_IDS[link.name]}
            >
              <DspIcon service={link.name} size={22} className="mr-1" />
              {link.title}
              <div style={{ flex: 1 }} />
            </Button>
          ))}
        </ButtonGroup>
      )}

      {description && (
        <SidebarGroup data-testid={ReleaseDetails.TEST_IDS.DESCRIPTION}>
          {sidebar ? (
            <TextExpander startHeight={120}>{description}</TextExpander>
          ) : (
            <StyledTextBody>
              <TextExpander startHeight={200}>{description}</TextExpander>
            </StyledTextBody>
          )}
        </SidebarGroup>
      )}

      <SidebarGroup>
        {label?.name && (
          <SidebarGroupSection>
            <Label>Label</Label>
            {label.slug ? (
              <Link data-testid={ReleaseDetails.TEST_IDS.LABEL} to={label.slug}>
                {label.name}
              </Link>
            ) : (
              label.name
            )}
          </SidebarGroupSection>
        )}

        <SidebarGroupSection>
          <Label>Release Date</Label>
          <div data-testid={ReleaseDetails.TEST_IDS.DATE}>{date}</div>
        </SidebarGroupSection>

        <SidebarGroupSection>
          <Label>Genre</Label>
          <div data-testid={ReleaseDetails.TEST_IDS.GENRES}>
            <CommaList data={sortedGenres} attribute="name" />
          </div>
        </SidebarGroupSection>
      </SidebarGroup>
    </div>
  );
};

ReleaseDetails.Tombstone = () => (
  <div>
    <SidebarGroup>
      <TombstoneText
        size={TombstoneText.SIZES.SMALL}
        color={TombstoneText.COLORS.LIGHT}
        grow
      />

      <TombstoneText
        className="mt-1"
        size={TombstoneText.SIZES.SMALL}
        color={TombstoneText.COLORS.LIGHT}
      />
    </SidebarGroup>
  </div>
);

ReleaseDetails.TEST_IDS = {
  CONTAINER: 'ReleaseDetails-container',
  DESCRIPTION: 'ReleaseDetails-description',
  LABEL: 'ReleaseDetails-label',
  DATE: 'ReleaseDetails-date',
  GENRES: 'ReleaseDetails-genres',
  RELEASE_LINK_SHARE_BTN: 'ReleaseDetails-release-link-share-btn',
  beatport: 'ReleaseDetails-beatport-link',
  'apple-music': 'ReleaseDetails-apple-music-link',
  spotify: 'ReleaseDetails-spotify-link'
};

export default ReleaseDetails;
