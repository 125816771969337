const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // IPv6 localhost
    window.location.hostname === '[::1]' ||
    // IPv4 localhost
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    ) ||
    // ᭷ Proton localhost ᭷
    // If you need to test SW using our local hostname in chrome, use this flag
    // to whitelist while testing: chrome://flags/#unsafely-treat-insecure-origin-as-secure
    window.location.hostname === 'local.protonradio.com'
);

type Config = {
  onInitialSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
  onActivated?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => void onLoad(config));
  }
}

async function onLoad(config?: Config) {
  const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

  if (isLocalhost) {
    // This is running on localhost. Let's check if a service worker still exists or not.
    await checkValidServiceWorker(swUrl, config);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigator.serviceWorker.ready.then(() => {
      // Warning to make sure someone running this locally knows the app is being managed
      console.warn('The app is being served cache-first by a service worker.');
    });
  } else {
    // This is not localhost. Just register service worker
    const registration = await registerValidSW(swUrl, config);
    if (registration) console.debug('Service worker registered');
  }
}

async function registerValidSW(swUrl: string, config?: Config) {
  try {
    const registration = await navigator.serviceWorker.register(swUrl);
    registration.onupdatefound = () => {
      const installingWorker = registration.installing;
      if (installingWorker == null) {
        return;
      }
      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            // At this point, the updated precached content has been fetched,
            // but the previous service worker will still serve the older
            // content until all client tabs are closed or waiting is skipped.
            console.debug('Update is available and will be activated on reload.');

            if (config?.onUpdate) {
              config.onUpdate(registration);
            }
          } else {
            // First sw activation, at this point, everything has been precached.
            console.debug('App prepared to serve from cache.');

            if (config?.onInitialSuccess) {
              config.onInitialSuccess(registration);
            }
          }
        } else if (installingWorker.state === 'activated' && config?.onActivated) {
          config.onActivated(registration);
        }
      };
    };

    // detect controller change and refresh the page (ensures controller and content version match)
    let refreshing = false;
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (!refreshing) {
        window.location.reload();
        refreshing = true;
      }
    });
    return registration;
  } catch (error) {
    console.error('Error during service worker registration:', error);
  }
}

async function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  try {
    const response = await fetch(swUrl, {
      headers: { 'Service-Worker': 'script' }
    });
    // Ensure service worker exists, and that we really are getting a JS file.
    const contentType = response.headers.get('content-type');
    if (
      response.status === 404 ||
      (contentType != null && contentType.indexOf('javascript') === -1)
    ) {
      // No service worker found. Probably a different app. Reload the page.
      const registration = await navigator.serviceWorker.ready;
      try {
        await registration.unregister();
        window.location.reload();
      } catch (e) {
        console.log(e);
      }
    } else {
      // Service worker found. Proceed as normal.
      return registerValidSW(swUrl, config);
    }
  } catch {
    console.log('No internet connection found. App is running in offline mode.');
  }
}

export async function unregister() {
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.ready;
    return registration.unregister();
  }
}
