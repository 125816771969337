import React from 'react';

import { DEFAULT_ICON_COLOR } from './config';
import { IconProps } from './iconTypes';

const Envelope = ({
  color = DEFAULT_ICON_COLOR,
  size = 18,
  className,
  style,
  onClick
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    height={size}
    style={style}
    viewBox="0 0 512 512"
    onClick={onClick}
  >
    <rect
      x="48"
      y="96"
      width="416"
      height="320"
      rx="40"
      ry="40"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      d="M112 160l144 112 144-112"
    />
  </svg>
);

export default Envelope;
