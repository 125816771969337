import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { TrackActionMenu } from 'components/ActionMenu/ActionMenus';
import DownloadTrackButton from 'components/Button/DownloadTrackButton';
import PromoReactionWidget from 'components/PromoReactionWidget';
import MenuToggler from 'components/MenuToggler';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import Flex from 'components/Flex';

import { selectPromoTrackReaction } from 'redux/selectors/promoReleases';
import { generateSelectorsFromTestIds } from 'helpers';

const StyledDownloadButtonWrapper = styled.button`
  background: inherit;
  outline: none;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.7;
  `}
`;

/**
 * DEPRECATED
 * [DownloadTrackMenuToggler] - wrap around track download button. Will determine whether to show download
 * action menu or popover (mobile vs desktop UX). If promoDownload passed, will handle display of promo reaction
 * menu/popover
 *
 * @param {object} track - track to render feedback form for.
 * @param {function} children - button that will trigger display of menu
 * children receives ({ open, close, isOpen })
 * @param {bool} [showPromoWidget] - if true, will display promo reaction menus
 * @param {string} [renderDirection] - render direction for dropdown
 * @param {function} [onOpen] - function run when open called
 * @param {function} [onClose] - function run when close called
 */

const DownloadTrackMenuToggler = ({
  track,
  children,
  showRatingTooltip,
  showPromoWidget,
  onOpen,
  onClose,
  reaction,
  ...rest
}) => (
  <MenuToggler
    onOpen={onOpen}
    onClose={onClose}
    renderToggle={({ open, isOpen }) => {
      if (!reaction?.rating && showRatingTooltip) {
        return (
          <Tooltip
            placement="top"
            trigger="click"
            content="Please rate to download promo."
          >
            <DownloadTrackMenuToggler.ToggleButton
              hasDownloaded={!!reaction?.downloaded_at}
              disabled
            />
          </Tooltip>
        );
      }

      return (
        <DownloadTrackMenuToggler.ToggleButton
          hasDownloaded={!!reaction?.downloaded_at}
          onClick={open}
          active={isOpen}
        />
      );
    }}
    renderActionMenu={({ isOpen, close }) => (
      <TrackActionMenu isOpen={isOpen} close={close} track={track} />
    )}
    renderPopoverContent={({ close }) => {
      if (showPromoWidget) {
        return <PromoReactionWidget close={close} track={track} />;
      }

      return (
        <Flex style={{ paddingLeft: 16, paddingRight: 16 }}>
          <DownloadTrackButton track={track} onDownloadSuccess={close} />
        </Flex>
      );
    }}
    {...rest}
  />
);

DownloadTrackMenuToggler.propTypes = {
  track: PropTypes.shape({
    id: PropTypes.number
  }),
  children: PropTypes.func,
  showPromoWidget: PropTypes.bool,
  showRatingTooltip: PropTypes.bool,
  hasRating: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};

// Optional toggle button... commonly used Download Icon
DownloadTrackMenuToggler.ToggleButton = ({
  onClick = () => null,
  active,
  disabled,
  hasDownloaded,
  ...rest
}) => (
  <StyledDownloadButtonWrapper
    data-testid={DownloadTrackMenuToggler.TEST_IDS.TOGGLE}
    disabled={disabled}
    onClick={e => {
      if (disabled) return;
      onClick(e);
    }}
    {...rest}
  >
    <Icon
      type={Icon.TYPES.DOWNLOAD}
      color={active || hasDownloaded ? Icon.COLORS.PRIMARY : Icon.COLORS.LIGHT}
      width={16}
    />
  </StyledDownloadButtonWrapper>
);

DownloadTrackMenuToggler.TEST_IDS = {
  TOGGLE: 'download-track-menu-toggle-button'
};

DownloadTrackMenuToggler.SELECTORS = generateSelectorsFromTestIds(
  DownloadTrackMenuToggler.TEST_IDS
);

DownloadTrackMenuToggler.ToggleButton.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool
};

export default connect((state, { track }) => ({
  reaction: selectPromoTrackReaction(state, track?.id)
}))(DownloadTrackMenuToggler);
