import axios from 'axios';
import queryString from 'query-string';

import { API_BASE_V2 } from 'config/constants';

export const promos = {
  // **** GET ****
  // Promo releases/tracks
  getPromoTracksFollowing: () =>
    axios.get(`${API_BASE_V2}/promos/tracks/following`).then(({ data }) => data),

  // Feedback / reactions
  getLabelReactions: ({ labelId, page }) => {
    const urlParam = page ? `?page=${page}` : '';

    return axios.get(
      `${API_BASE_V2}/promos/reactions/label/${labelId}/received${urlParam}`
    );
  },
  /**
   * [getArtistReactions] - paginated endpoint that can return an artists received or given reactions
   * and also filter by label(s)
   *
   * @param {number} artistId - artist id to filter reactions by
   * @param {number} page - page number of paginated endpoint to return
   * @param {string} type - pass whether you want artist feedback 'given' or 'received'
   * @param {number[]} labelIds - filter reactions by particular label ids
   */
  getArtistReactions: ({ artistId, page = 1, type = 'given', labelIds = [] }) => {
    if (!['given', 'received'].includes(type)) {
      const e = new Error(`getArtistReactions does not support type ${type}`);
      console.warn(e);
      return Promise.reject(e);
    }

    const urlParam = queryString.stringify(
      { page, label_ids: labelIds },
      { arrayFormat: 'bracket' }
    );

    return axios.get(
      `${API_BASE_V2}/promos/reactions/artist/v2/${artistId}/${type}?${urlParam}`
    );
  },
  getReleaseReactions: ({ releaseId, page }) => {
    const urlParam = page ? `?page=${page}` : '';
    return axios.get(
      `${API_BASE_V2}/promos/reactions/release/v2/${releaseId}/received${urlParam}`
    );
  },
  getTrackReactions: ({ trackId, page }) => {
    const urlParam = page ? `?page=${page}` : '';
    return axios.get(`${API_BASE_V2}/promos/track/${trackId}/reactions${urlParam}`);
  },

  // **** POST ****
  // Promo releases/tracks
  dismissRelease: releaseId =>
    axios.post(`${API_BASE_V2}/promos/reactions/${releaseId}/dismiss`),
  updateTrackReaction: ({ trackId, artistId, ...reactionPayload }, config) => {
    // only pass defined fields to payload
    const payload = { bio_id: artistId, ...reactionPayload };

    // This endpoint handles both create and update. Don't even think about changing it.
    return axios.post(`${API_BASE_V2}/promos/reactions/${trackId}`, payload, config);
  }
};

export default promos;
