import jwtDecode from 'jwt-decode';
import { getCookie } from 'react-use-cookie';

import { ProtonJwt } from 'types';
import { removeProtonCookie, setProtonCookie } from './cookies';

const DEFAULT_EMPTY_JWT = {
  user_id: null,
  phpbb_user: {
    user_active: null,
    username: null,
    authentication_token: null,
    proton_subscribe: null
  },
  user: {
    id: null,
    accesslevel: null
  },
  exp: null,
  iat: null
} as const;

type EmptyJwt = typeof DEFAULT_EMPTY_JWT;

/**
 * Returns a decoded jwt object. If no jwt or if jwt is invalid, returns a jwt object
 * with null values rather than throwing an exception
 */

export const getProtonJwtPayload = (jwt?: string | null): ProtonJwt | EmptyJwt => {
  if (!jwt) return DEFAULT_EMPTY_JWT;

  try {
    return jwtDecode<ProtonJwt>(jwt);
  } catch (e) {
    console.warn('Error decoding jwt');
    return DEFAULT_EMPTY_JWT;
  }
};

export const getCurrentAuthCookies = () => {
  const jwt = getCookie('masquerade_jwt') || getCookie('jwt');
  const refreshToken =
    getCookie('masquerade_refresh_token') || getCookie('refresh_token');

  return { jwt, refreshToken };
};

export function getInitialAuthState() {
  const { jwt, refreshToken } = getCurrentAuthCookies();
  const { user_id } = getProtonJwtPayload(jwt);

  if (!jwt || !refreshToken || !user_id) {
    return {
      jwt: null,
      refreshToken: null,
      userId: null
    };
  }

  return {
    jwt,
    refreshToken,
    userId: user_id,
    isImpersonating: !!getCookie('masquerade_jwt')
  };
}

export function clearMasqueradeCookies() {
  removeProtonCookie('masquerade_jwt');
  removeProtonCookie('masquerade_refresh_token');
}

export function setMasqueradeCookie(jwt: string, refresh_token: string) {
  setProtonCookie('masquerade_jwt', jwt, 1, 'Lax');
  setProtonCookie('masquerade_refresh_token', refresh_token, 1, 'Lax');
}

export function setAuthCookies(jwt: string, refreshToken: string) {
  setProtonCookie('jwt', jwt, 1, 'Lax');
  setProtonCookie('refresh_token', refreshToken, 30, 'Lax');
}

export function clearAuthCookies() {
  removeProtonCookie('jwt');
  removeProtonCookie('refresh_token');
  removeProtonCookie('masquerade_jwt');
  removeProtonCookie('masquerade_refresh_token');
}
