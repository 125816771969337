import axios from 'axios';
import { API_BASE_V2 } from 'config/constants';

export const getTopShows = ({ sinceDaysAgo = 7, limit = 100 }) =>
  axios
    .get(`${API_BASE_V2}/popular/shows`, {
      params: {
        since_days_ago: sinceDaysAgo,
        limit
      }
    })
    .then(({ data }) => data);

export const getShow = showId =>
  axios.get(`${API_BASE_V2}/shows/${showId}`).then(({ data }) => data);
