import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: inherit;
  width: 100%;
`;

const NavTabContainer = ({ children, className }) => (
  <StyledContainer className={className}>{children}</StyledContainer>
);

NavTabContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired
};

export default NavTabContainer;
