import axios from 'axios';
import { SearchResponse } from '@algolia/client-search';

import { algoliaApi, protonApi } from './rtkSetup';
import { ALGOLIA, API_BASE_V2 } from '../config/constants';
import { AlgoliaLabel } from 'types/algolia';
import { V2Label, V2LabelArtist, V2LabelPromoSubscriber, V2LabelTopArtist } from 'types';

export const labelsAlgoliaApi = algoliaApi.injectEndpoints({
  endpoints: build => ({
    getLabelAlgolia: build.query<AlgoliaLabel, number>({
      query: labelId => ({
        index: ALGOLIA.LABEL_INDEX,
        facetFilters: [[`objectID:${labelId}`]],
        errorOnEmpty: true
      }),
      transformResponse: (response: SearchResponse<AlgoliaLabel>) => response.hits[0]
    })
  }),
  overrideExisting: false
});

export const { useGetLabelAlgoliaQuery } = labelsAlgoliaApi;

export const labelsApi = protonApi.injectEndpoints({
  endpoints: build => ({
    getLabel: build.query<V2Label, string | number>({
      query: id => ({
        url: `labels/${id}`,
        method: 'get'
      }),
      transformResponse: (response: { data: V2Label }) => response.data
    })
  }),
  overrideExisting: false
});

export const { useGetLabelQuery } = labelsApi;

export const getLabel = async (labelId: string | number) => {
  const { data } = await axios.get<{ data: V2Label }>(`${API_BASE_V2}/labels/${labelId}`);
  return data;
};

export const getLabelSubscribers = async (labelId: string | number) => {
  const { data } = await axios.get<{ data: V2LabelPromoSubscriber[] }>(
    `${API_BASE_V2}/labels/${labelId}/subscribers`
  );
  return data;
};

export const getLabelArtists = async (labelId: string | number) => {
  const { data } = await axios.get<{ data: V2LabelArtist[] }>(
    `${API_BASE_V2}/labels/${labelId}/artist-roster`
  );
  return data;
};

export const getLabelTopArtists = async (
  labelId: string | number,
  options: { limit?: number }
) => {
  const { data } = await axios.get<{ data: V2LabelTopArtist[] }>(
    `${API_BASE_V2}/labels/${labelId}/top-artists`,
    {
      params: { ...options }
    }
  );
  return data;
};

export const addSubscriberToLabel = async (
  labelId: string | number,
  artistId: string | number
) => {
  const { data } = await axios.post<{ data: V2LabelPromoSubscriber[] }>(
    `${API_BASE_V2}/labels/${labelId}/subscribers`,
    {
      bio_id: artistId
    }
  );
  return data;
};

export const removeSubscriberFromLabel = async (
  labelId: string | number,
  artistId: string | number
) => {
  const { data } = await axios.delete<{ data: V2LabelPromoSubscriber[] }>(
    `${API_BASE_V2}/labels/${labelId}/subscribers`,
    {
      params: { bio_id: artistId }
    }
  );
  return data;
};

export const getLabelRecommendedSubscribers = async (labelId: string | number) => {
  const { data } = await axios.get<{ data: V2LabelArtist[] }>(
    `${API_BASE_V2}/labels/${labelId}/recommended-artists`
  );
  return data;
};

export const removeRecommendedLabelSubscriber = async (
  labelId: string | number,
  artistId: string | number
) => {
  const { data } = await axios.delete<{ data: V2LabelArtist[] }>(
    `${API_BASE_V2}/labels/${labelId}/recommended-artists/${artistId}`
  );
  return data;
};

/*
 * [requestLimitedPromoAccess]
 * for current user, set all their labels to have limited promo access.  If a label has "full_access",
 * it is not touched
 *
 * @returns {object} see example response below:
 * {
 *  labels_whitelisted: [6, 1, 46, 55]
 * }
 */

export const requestLimitedPromoAccess = async () => {
  const { data } = await axios.post<{ data: { labels_whitelisted: number[] } }>(
    `${API_BASE_V2}/labels/promo-waitlist`
  );
  return data;
};
