import { useEffect } from 'react';

import useCurrentUser, { User } from './useCurrentUser';
import usePrevious from './usePrevious';

/**
 * [useEffectOnInitialUserLoad] works like [useEffect] but only runs once on the initial load of the user.
 */

function useEffectOnInitialUserLoad(
  effectFunction: (arg: User) => void,
  dependencies: React.DependencyList
) {
  const { user: currentUser, fetching } = useCurrentUser();

  const prevUserId = usePrevious(currentUser.id);
  const userLoaded = !fetching && prevUserId !== currentUser.id && !!currentUser.id;

  useEffect(() => {
    if (userLoaded) {
      effectFunction(currentUser);
    }
  }, [userLoaded, currentUser, ...dependencies]);
}

export default useEffectOnInitialUserLoad;
