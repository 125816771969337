import React from 'react';

import { DEFAULT_ICON_COLOR } from './config';

const EmailIcon = ({
  color = DEFAULT_ICON_COLOR,
  size = 18,
  className,
  style
}: {
  className?: string;
  style?: React.CSSProperties;
  size?: number;
  color?: string;
}) => (
  <svg
    className={className}
    height={size}
    width={size}
    style={style}
    viewBox="0 0 20 21"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      strokeWidth="1.46"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g transform="translate(-670, -538)" stroke={color}>
        <g transform="translate(634, 520)">
          <g transform="translate(36, 21)">
            <path d="M2,0 L18,0 C19.1,0 20,0.9 20,2 L20,14 C20,15.1 19.1,16 18,16 L2,16 C0.9,16 0,15.1 0,14 L0,2 C0,0.9 0.9,0 2,0 Z"></path>
            <polyline points="20 2 10 9 0 2"></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default EmailIcon;
