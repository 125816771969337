import React from 'react';

import { DEFAULT_ICON_COLOR, ICON_PROP_TYPES } from './config';

const Lightning = ({ color = DEFAULT_ICON_COLOR, size = 18, className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    height={size}
    style={style}
    viewBox="0 0 44 44"
  >
    <title>Lightning</title>
    <path
      fill={color}
      d="M32.931,19.932c-0.181-0.378-0.562-0.617-0.979-0.617h-7.184l3.945-11.691 c0.166-0.497-0.044-1.043-0.505-1.296c-0.163-0.09-0.344-0.134-0.521-0.134c-0.318,0-0.63,0.14-0.842,0.401L12.084,24.799 c-0.263,0.324-0.315,0.771-0.135,1.148c0.18,0.378,0.56,0.617,0.977,0.617h7.184l-3.945,11.691 c-0.167,0.497,0.045,1.043,0.504,1.297c0.166,0.09,0.346,0.133,0.522,0.133c0.317,0,0.63-0.14,0.843-0.4l14.76-18.205 C33.057,20.755,33.109,20.309,32.931,19.932z"
    />
  </svg>
);

Lightning.propTypes = ICON_PROP_TYPES;

export default Lightning;
