import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';
import {
  fetchLabelReleasesActions,
  fetchReleaseActions,
  fetchReleaseTracksActions
} from 'redux/actions/releases';

import { RELEASE_INITIAL_STATE } from './initialState/releaseInitialState';
import {
  handleArrayToByIdSuccess,
  handleFetchError,
  handleFetchRequest,
  handleFetchSuccess
} from './utilities';

const byId = createReducer(
  {
    [fetchReleaseActions.request]: (state, payload) =>
      handleFetchRequest(state, payload, { initialData: RELEASE_INITIAL_STATE }),
    [fetchReleaseActions.success]: handleFetchSuccess,
    [fetchReleaseActions.error]: handleFetchError,
    [fetchLabelReleasesActions.success]: handleArrayToByIdSuccess
  },
  {}
);

const byLabelId = createReducer(
  {
    [fetchLabelReleasesActions.request]: handleFetchRequest,
    [fetchLabelReleasesActions.success]: handleFetchSuccess,
    [fetchLabelReleasesActions.error]: handleFetchError
  },
  {}
);

const tracksByReleaseId = createReducer(
  {
    [fetchReleaseTracksActions.request]: handleFetchRequest,
    [fetchReleaseTracksActions.success]: handleFetchSuccess,
    [fetchReleaseTracksActions.error]: handleFetchError
  },
  {}
);

export default combineReducers({
  byId,
  byLabelId,
  tracksByReleaseId
});
