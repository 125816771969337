/* eslint-disable max-len */
import { COLORS } from 'config/constants';
import React from 'react';
import { ICON_PROP_TYPES } from './config';

const Danger = props => {
  const { className, size, style, color = COLORS.DANGER, filled } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={className}
      height={size}
      style={style}
    >
      <title>Warning</title>
      {filled && (
        <path
          fill={color}
          d="M449.07 399.08L278.64 82.58c-12.08-22.44-44.26-22.44-56.35 0L51.87 399.08A32 32 0 0080 446.25h340.89a32 32 0 0028.18-47.17zm-198.6-1.83a20 20 0 1120-20 20 20 0 01-20 20zm21.72-201.15l-5.74 122a16 16 0 01-32 0l-5.74-121.95a21.73 21.73 0 0121.5-22.69h.21a21.74 21.74 0 0121.73 22.7z"
        />
      )}

      {!filled && (
        <>
          <path
            d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="32"
          />
          <path
            d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="32"
          />
          <path fill={color} d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" />
        </>
      )}
    </svg>
  );
};

Danger.propTypes = ICON_PROP_TYPES;

export default Danger;
