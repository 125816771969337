import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getDownloadableTracksByCategory, isTrackDownloadable } from 'helpers/tracks';

import { selectIsTrackOnPromo, selectFollowingPromoTrackIds } from './promoReleases';
import { selectUser } from './user';

export const selectTracks = state => state.tracks;
export const selectAllTracks = state => state.tracks.byId;
export const selectTrackById = (state, id) => state.tracks.byId[id] || {};

export const selectPromoReleases = state => state.promoReleases;
export const selectPromoReleaseTrackById = (state, id) =>
  state.promoReleases.tracksById[id] || null;

export const selectTrackRating = createSelector([selectPromoReleaseTrackById], track =>
  _.get(track, 'reaction.rating', null)
);

/**
 * [selectLastKnownTrackRating] - if a rating was made optimistically, then
 * the last rating returned from the api is saved in the __optimistic attribute
 * in redux.  If no optimistic rating, will return the current rating as stored
 * in the reaction data.
 *
 * @param {number} trackId
 * @returns {number}
 */

export const selectLastKnownTrackRating = createSelector(
  [selectPromoReleaseTrackById],
  promoTrack => {
    // if no reaction previously existed and a new rating was optimistically set, then __optimistic = null
    if (promoTrack.reaction && promoTrack.reaction.__optimistic === null) return null;

    const storedRating = _.get(promoTrack, 'reaction.__optimistic.rating');
    const currentRating = _.get(promoTrack, 'reaction.rating', null);

    return storedRating || currentRating;
  }
);

/*
 * [selectIsTrackDownloadable] - reactive selector for determining if a track is downloadable
 *
 * @returns {bool}
 */

export const selectIsTrackDownloadable = createSelector(
  [selectIsTrackOnPromo, (state, { audio }) => audio, selectUser],
  (isPromo, audio, user) => !!isTrackDownloadable(isPromo, audio, user)
);

/**
 * [selectUserHasPremiumTrackDownload] - Determines if the user can download only mp3 filetype or all filetypes.
 */

export const selectUserHasPremiumTrackDownload = createSelector(
  [(__, { audio }) => audio, selectUser],
  (audio, user) => !!isTrackAvailableForPremiumDownload({ track: audio, user })
);

/**
 * [selectIsTrackOnPreview] - currently this is just determined by if a track is downloadable,
 * but made a separate selector in case the logic changes
 */

export const selectIsTrackOnPreview = createSelector(
  [selectIsTrackOnPromo, (__, { audio }) => audio, selectUser],
  (isPromo, audio, user) => !isTrackDownloadable(isPromo, audio, user)
);

/**
 * [selectUserDownloadableTracksByCategory]
 *
 * Determines which of passed tracks are downloadable base on the criteria:
 * 1) Does the track contain artists that the user manages?
 * 2) Does the track contain labels that the user manages?
 * 3) Is the track available in current user's available promo tracks
 *
 * @param {object[]} tracks array of tracks to check if downloadable
 *
 * @returns {object} category mapping of tracks that user can download.  Each category
 * is a byId mapping of tracks. Response format:
 * {
 *   all: {},     // all downloadable tracks (byId) from supplied tracks param. All tracks from
 *                // 'artist', 'label', 'promo' categories
 *   artist: {},  // tracks downloadable because by the user manages an artist in this track
 *   label: {},   // tracks downloadable because by the user manages the label for this track
 *   promo: {}    // tracks downloadable because by the user manages the label for this track
 * }
 */

export const makeSelectUserDownloadableTracksByCategory = () =>
  createSelector(
    [(__, { tracks = [] }) => tracks, selectFollowingPromoTrackIds, selectUser],
    (tracks, promoTracksIds, user) =>
      getDownloadableTracksByCategory({
        tracks,
        user,
        promoTracksIds
      })
  );
