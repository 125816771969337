import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'urql';

import Screen from 'components/Screen';
import { TABLE_INPUT_SELECTOR } from 'components/Lists/helpers';
import TracksTableDesktop from './TracksTableDesktop';
import TracksTableMobile from './TracksTableMobile';
import TrackMobileTile from './TrackMobileTile';
import { PAGINATION_CONFIGS } from 'config/constants';
import useCurrentUser from 'hooks/useCurrentUser';
import { viewerLabelSubscriptionsPaginationTransform } from 'graphql/transforms';

const ARCHIVE_ACCESS_QUERY = `
  query getLabelArchiveAccess($page: Int, $perPage: Int) {
    viewer {
      promoPool {
        following: labels(status: FOLLOWING, perPage: $perPage, page: $page, filterBy: { archiveAccess: { is: true } }) {
          pageInfo {
            page
            nodeCount
            hasNextPage
          }
          edges {
            hasArchiveAccess
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const TracksTable = props => {
  const { tracks, windowScroller, showCompile = false } = props;

  const [promoLabelsPage, setPromoLabelsPage] = useState(1);
  const [archiveAccessLabelIds, setArchiveAccessLabelIds] = useState([]);
  const archiveAccessLabelIdsRef = useRef([]);
  const processedPageRef = useRef(null);

  const { user: currentUser } = useCurrentUser();
  const promoPoolAccess = currentUser.permissions.accessPromoPool;

  // NOTE: Currently we have to manually paginate through these results to ensure we get all promo archive access
  // labels. Once we transition track tables to be powered by graphql, we can remove this logic.
  const [{ data: promoLabelsFollowing }] = useQuery({
    query: ARCHIVE_ACCESS_QUERY,
    variables: {
      perPage: PAGINATION_CONFIGS.API_DEFAULT_MAX_PER_PAGE,
      page: promoLabelsPage
    },
    pause: !promoPoolAccess
  });

  useEffect(() => {
    const { pageInfo, data: labels } = viewerLabelSubscriptionsPaginationTransform(
      promoLabelsFollowing,
      'following'
    );

    if (pageInfo.page === processedPageRef.current || labels.length === 0) return;

    const labelIds = labels.filter(l => l.hasArchiveAccess).map(({ node }) => node.id);

    archiveAccessLabelIdsRef.current = [...archiveAccessLabelIdsRef.current, ...labelIds];
    processedPageRef.current = pageInfo.page;

    if (pageInfo.hasNextPage) {
      setPromoLabelsPage(p => p + 1);
    } else {
      setArchiveAccessLabelIds(archiveAccessLabelIdsRef.current);
    }
  }, [promoLabelsFollowing]);

  // TODO (Rob): This table re-renders constantly when scrolling due to the
  // InfiniteScroller/AutoResizer wrappers – should investigate again if it's
  // possible to avoid this.
  //
  // console.log('[[TRACKS TABLE RERENDER]]');

  // NOTE (Brad): React is smart enough to know tracks is unchanged even when window scroller
  // triggers re-renders
  const queueAudio = useMemo(() => {
    return tracks.map((track, index) => ({
      ...track,
      hasArchiveAccess: archiveAccessLabelIds.includes(track.label.id.toString()),
      __queueIndex: index
    }));
  }, [tracks, archiveAccessLabelIds]);

  if (promoPoolAccess && !promoLabelsFollowing) {
    return <div />;
  }

  const commonProps = {
    showCompile,
    tracks: queueAudio,
    userSettings: currentUser.settings,
    userRoles: currentUser.roles,
    tableProps: {
      autoHeight: true,
      className: 'Table TracksTable',
      noRowsRenderer: () => <TracksTable.Tombstones />,
      rowCount: tracks.length,
      ...windowScroller,
      onScroll: windowScroller.onScroll
    }
  };

  return (
    <>
      <Screen.MEDIUM down>
        <TracksTableMobile {...commonProps} />
      </Screen.MEDIUM>

      <Screen.MEDIUM up>
        <TracksTableDesktop {...commonProps} />
      </Screen.MEDIUM>
    </>
  );
};

TracksTable.ROW_SELECTOR = '.Table__row.ReactVirtualized__Table__row';
TracksTable.MOBILE_ROW_SELECTOR = TrackMobileTile.SELECTORS.CONTAINER;
TracksTable.INPUT_SELECTOR = `[data-testid=${TABLE_INPUT_SELECTOR}]`;

// These are the attributes displayed by track table. We don't want to search on fields not displayed
TracksTable.SEARCHABLE_ATTRIBUTES = [
  'title',
  'version',
  'artists.name',
  'label.name',
  'release.date'
];

TracksTable.propTypes = {
  tracks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showCompile: PropTypes.bool,

  // WindowAutoScroller props
  windowScroller: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    isScrolling: PropTypes.bool.isRequired,
    onScroll: PropTypes.func.isRequired,
    scrollTop: PropTypes.number.isRequired
  })
};

TracksTable.Tombstone = () => (
  <>
    <Screen.MEDIUM down>
      <TracksTableMobile.Tombstone />
    </Screen.MEDIUM>

    <Screen.MEDIUM up>
      <TracksTableDesktop.Tombstone />
    </Screen.MEDIUM>
  </>
);

/*
 * [Tombstones] render multiple tombstone rows for TrackTable
 *
 * @param {number} [count = 3] number of tiles to render
 * @param {bool} wrapped if rendering outside of the track table, you'll need to passed
 * wrapped = true to have the same margin/padding as the table. noRowsRenderer prop for
 * react virtualized renders tombstones inside the table, but the pagination component
 * renders them outside.
 */

TracksTable.Tombstones = props => {
  const { count = 5, wrapped } = props;
  const TombstoneTiles = [...Array(count)].map((_, index) => (
    /* eslint-disable-next-line react/no-array-index-key */
    <TracksTable.Tombstone key={index} />
  ));

  if (wrapped) return <div className="Table TracksTable">{TombstoneTiles}</div>;

  return <>{TombstoneTiles}</>;
};

export default TracksTable;
