import Bowser from 'bowser';
// https://github.com/lancedikson/bowser/blob/master/src/constants.js

const parser = Bowser.getParser(window.navigator.userAgent);

const versionLowerOrEqualThan = (a, b) => a.toString() <= b.toString();

const isOS = os => parser.getOSName(true) === os.toLowerCase();

export const OS_NAMES = {
  WindowsPhone: 'Windows Phone',
  Windows: 'Windows',
  MacOS: 'macOS',
  iOS: 'iOS',
  Android: 'Android',
  WebOS: 'WebOS',
  BlackBerry: 'BlackBerry',
  Bada: 'Bada',
  Tizen: 'Tizen',
  Linux: 'Linux',
  ChromeOS: 'Chrome OS',
  PlayStation4: 'PlayStation 4',
  Roku: 'Roku'
};

const browserName = parser.getBrowserName().toLowerCase();
const deviceType = parser.getPlatformType(true);

const osVersionLowerOrEqualThan = version =>
  versionLowerOrEqualThan(parser.getOSVersion(), version);

const browserVersionLowerOrEqualThan = version =>
  versionLowerOrEqualThan(parser.getBrowserVersion(), version);

export const isSafari = browserName === 'safari';
const isIOS = isOS(OS_NAMES.iOS);

export const isCommonMobileOS = isIOS || isOS(OS_NAMES.Android);
export const isIosSafari = isSafari && isIOS;

export default {
  isOS,
  osVersionLowerOrEqualThan,
  browserVersionLowerOrEqualThan,
  isIOS,
  isSafari,
  isIosSafari,
  isCommonMobileOS,
  isFirefox: browserName === 'firefox',
  isChrome: browserName === 'chrome',
  isMobile: deviceType === 'mobile',
  isTablet: deviceType === 'tablet'
};
