import React from 'react';

import { DEFAULT_ICON_COLOR, ICON_PROP_TYPES } from './config';

const Download = ({ color = DEFAULT_ICON_COLOR, size = 18, className, style }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 10 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
    style={style}
  >
    <title>Download Icon</title>
    <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Inbox---React-&amp;-Download-2.0---Change-Artist"
        transform="translate(-463.000000, -527.000000)"
        fill={color}
      >
        <polygon
          id="Path-Copy"
          points="473 531.234375 470.14375 531.234375 470.14375 527 465.85625 527 465.85625 531.234375 463 531.234375 468 536.175"
        />
      </g>
    </g>
  </svg>
);

Download.propTypes = ICON_PROP_TYPES;

export default Download;
