import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { PLAYER_STATES } from 'config/constants';
import useProtonPlayer from 'hooks/useProtonPlayer';

export const StaticButton = ({}) => {
  const player = useProtonPlayer();

  const handleClick = () => player.send('toggle');

  const btnClass = classnames(['PlayerButton', 'PlayerButton--primary', player.state]);

  return (
    <span
      className={btnClass}
      data-playing={player.state === PLAYER_STATES.PLAYING}
      data-selector="PlayerButton"
      onClick={handleClick}
      role="button"
      tabIndex="0"
    />
  );
};

const PlayerButton = ({
  audio,
  queueAudio = [],
  queueIndex,
  classes,
  disabled, // mutes button color
  displayOnly, // remove button onClick
  testId,
  'data-testid': dataTestId
}) => {
  const player = useProtonPlayer();
  const playing = player.currentTrack;

  const track = queueAudio[queueIndex] || audio;
  const isCurrentlyPlaying = track && track.id === playing?.id;
  const buttonState = isCurrentlyPlaying ? player.state : '';

  const btnClass = classnames(['PlayerButton', buttonState, classes], {
    'is-disabled': disabled,
    'display-only': displayOnly
  });

  const handleClick = () => {
    if (!isCurrentlyPlaying || (isCurrentlyPlaying && player.state !== 'PLAYING')) {
      const queue = (queueAudio.length && queueAudio) || [audio];
      player.send('playAudio', { queue, index: queueIndex });
    } else {
      player.send('toggle');
    }
  };

  return (
    <span
      className={btnClass}
      data-playing={isCurrentlyPlaying && player.state === PLAYER_STATES.PLAYING}
      data-selector="PlayerButton"
      onClick={!displayOnly ? handleClick : undefined}
      role="button"
      tabIndex="0"
      data-testid={testId || dataTestId}
    />
  );
};

const PlayerButton_Tombstone = ({ className }) => {
  const _class = classnames(
    'PlayerButton',
    'PlayerButton__tombstone',
    'Tombstone',
    'Tombstone--light'
  );
  return <div className={_class} />;
};

Object.assign(PlayerButton, {
  SELECTOR: '[data-selector=PlayerButton]',
  Tombstone: PlayerButton_Tombstone
});

const audioPropType = PropTypes.shape({
  __audioId: PropTypes.number.isRequired,
  __audioType: PropTypes.string.isRequired
});

PlayerButton.propTypes = {
  // OPTION 1
  audio: audioPropType,
  // OPTION 2
  queueAudio: PropTypes.arrayOf(audioPropType),
  queueIndex: PropTypes.number,
  queueName: PropTypes.string,
  // Additional classes to pass to player button
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  displayOnly: PropTypes.bool,
  testId: PropTypes.string,
  'data-testid': PropTypes.string
};

PlayerButton.SELECTORS = {
  BUTTON: '[data-selector=PlayerButton]',
  IS_PLAYING: '[data-playing=true]'
};

export default PlayerButton;
