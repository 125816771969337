import { EventEmitter } from 'events';
import { isEqual } from 'lodash';

import userAgent from 'helpers/userAgent';

import FirefoxStreamingPlayer from './FirefoxStreamingPlayer';
import RadioPlayer from './RadioPlayer';
import StreamingPlayer from './StreamingPlayer';

type PlaybackMode = 'STREAM' | 'RADIO';
export type AudioQuality = 'low' | 'medium' | 'high';
export type PlayerState = 'PLAYING' | 'STOPPED' | 'PAUSED';

type PlayerCache = {
  audioPlayer: {
    queue?: any[];
    index?: number;
    mode?: PlaybackMode;
    quality?: AudioQuality;
    playheadTime?: number;
  };
  streamingPlayer: {
    queue?: any[];
    index?: number;
  };
};

EventEmitter.defaultMaxListeners = 50;

export const PLAYER_ID = Date.now();
export const PLAYER_CACHE = 'PLAYER_CACHE';

export default class AudioPlayer extends EventEmitter {
  id: number;
  volume: number;
  previousVolume: number | null;
  mode: PlaybackMode;
  queue: any[];
  index: number;
  quality: AudioQuality;
  state: PlayerState;
  playheadTime: number;

  streamingPlayer: StreamingPlayer | FirefoxStreamingPlayer;
  radioPlayer: RadioPlayer;

  constructor() {
    super();

    this.id = PLAYER_ID;
    this.volume = 0.7;
    this.previousVolume = null;
    this.mode = 'STREAM';
    this.queue = [];
    this.index = 0;
    this.quality = 'medium';
    this.state = 'STOPPED';
    this.playheadTime = 0;

    /**
     * NOTE (rocco): Temporarily disabled to address localStorage quota issues.
     *
     * const cachedStateString = localStorage.getItem(PLAYER_CACHE)
     * const cachedState = cachedStateString
     * ? JSON.parse(cachedStateString) as PlayerCache
     * : { audioPlayer: {}, streamingPlayer: {} };
     *
     * Object.assign(this, cachedState.audioPlayer)
     */

    if (localStorage.getItem(PLAYER_CACHE)) {
      localStorage.removeItem(PLAYER_CACHE);
    }

    const cachedState = { audioPlayer: {}, streamingPlayer: {} };

    // On iOS (and whenever the Proton Player fails to load), the player falls
    // back to using SoundManager. For iOS, this is due to unaddressed issues
    // with streaming large files programmatically instead of via an <audio />
    // tag. It is possible that this may be an issue within the Proton Player
    // library that can be addressed, but for now this is the better option.
    if (userAgent.isIOS) {
      this.streamingPlayer = new FirefoxStreamingPlayer(cachedState.streamingPlayer);
    } else {
      try {
        this.streamingPlayer = new StreamingPlayer();
      } catch (e) {
        this.streamingPlayer = new FirefoxStreamingPlayer(cachedState.streamingPlayer);
      }
    }

    // This was added on June 29, 2023 as a way to clear up some unused
    // localStorage space that was utilized by the old version of the player.
    // This can and should be removed in the future.
    if (localStorage.getItem('persist:player')) {
      localStorage.removeItem('persist:player');
    }

    this.streamingPlayer.on('PLAYBACK_STARTED', ({ queue, index }) => {
      this.queue = queue;
      this.index = index;
      this._emitChange();
      this._updateCache();
    });
    this.streamingPlayer.on('TRACK_CHANGED', args => {
      this.index = args.nextTrack.meta.index;
      this._emitChange();
      this._updateCache();
    });
    this.streamingPlayer.on('STATE_CHANGED', state => {
      this.state = state;
      this._emitChange();
      this._updateCache();
    });
    this.streamingPlayer.on('VOLUME_CHANGED', ({ volume }) => {
      this.volume = volume;
      this._emitChange();
      this._updateCache();
    });
    this.streamingPlayer.on('TICK', playheadTime => {
      this.playheadTime = playheadTime;
      this.emit('TICK', this.playheadTime);
      this._updateCache();
    });

    this.radioPlayer = new RadioPlayer();
    this.radioPlayer.on('PLAYBACK_STARTED', ({ audio }) => {
      this.queue = [audio];
      this.index = 0;
      this._emitChange();
      this._updateCache();
    });
    this.radioPlayer.on('STATE_CHANGED', ({ state }) => {
      this.state = state;
      this._emitChange();
      this._updateCache();
    });
    this.radioPlayer.on('VOLUME_CHANGED', ({ volume }) => {
      this.volume = volume;
      this._emitChange();
      this._updateCache();
    });
  }

  _emitChange(): void {
    this.emit('STATE_CHANGED', {
      track: this.currentTrack(),
      state: this.state,
      volume: this.volume
    });
  }

  _updateCache(): void {
    const cache = JSON.stringify({
      audioPlayer: {
        queue: this.queue,
        index: this.index,
        mode: this.mode,
        quality: this.quality,
        playheadTime: this.playheadTime
      },
      streamingPlayer: this.streamingPlayer.serialize()
    });

    // NOTE (rocco): Temporarily disabled.
    // localStorage.setItem(PLAYER_CACHE, cache)
  }

  _isSameQueue(queue: any[], index: number): boolean {
    const queueIDs = queue.map(x => x.id);
    const currentQueueIDs = this.queue.map(x => x.meta?.id || x.id);

    return isEqual(queueIDs, currentQueueIDs) && index === this.index;
  }

  send(messageType: string, payload?: any): void {
    if (this[messageType]) {
      setTimeout(() => {
        this[messageType](payload);
      }, 0);
    } else {
      this.forward(messageType, payload);
    }
  }

  forward(messageType: string, payload: any): void {
    switch (this.mode) {
      case 'STREAM':
        this.streamingPlayer.send(messageType, payload);
      case 'RADIO':
        this.radioPlayer.send(messageType, payload);
      default:
        return;
    }
  }

  playAudio(payload: { queue: any[]; index: number; initialPosition?: number }): void {
    const firstAudio = payload.queue[0];
    if (firstAudio.__audioType === 'radio') {
      this.playRadio({ audio: firstAudio });
    } else {
      this.playTracks(payload);
    }
  }

  playRadio(payload: {}): void {
    this.mode = 'RADIO';
    this.playheadTime = 0;
    this.streamingPlayer.send('reset', {});
    this.radioPlayer.send('play', { ...payload, playerID: this.id });
  }

  playTracks(payload: { queue: any[]; index: number; initialPosition?: number }): void {
    this.mode = 'STREAM';
    this.radioPlayer.send('reset', {});

    if (this.state !== 'STOPPED' && this._isSameQueue(payload.queue, payload.index)) {
      this.streamingPlayer.send('toggle', {});
    } else {
      this.streamingPlayer.send('play', {
        ...payload,
        initialPosition: payload.initialPosition || 0,
        playerID: this.id,
        audioQuality: this.quality
      });
    }
  }

  toggle(): void {
    if (this.state === 'STOPPED') {
      return this.resumePlayback();
    }

    this.forward('toggle', {});
  }

  resumePlayback(): void {
    if (!this.queue.length) return;

    if (this.mode === 'RADIO') {
      this.playRadio({ audio: this.queue[0] });
    } else {
      const queue = this.queue.map(x => x.meta);
      this.playTracks({
        queue,
        index: this.index,
        initialPosition: this.playheadTime
      });
    }
  }

  updateTrack(payload: { id: number; delta: {} }): void {
    const index = this.queue.findIndex(x => x.meta.id === payload.id);
    const track = this.queue[index];
    this.queue[index] = {
      ...track,
      meta: {
        ...track.meta,
        ...payload.delta
      }
    };
    this._emitChange();
  }

  toggleMute(): void {
    if (this.previousVolume) {
      this.volume = this.previousVolume;
      this.forward('setVolume', this.previousVolume);
      this.previousVolume = null;
    } else {
      this.previousVolume = this.volume;
      this.volume = 0;
      this.forward('setVolume', 0);
    }
  }

  setVolume(volume: number): void {
    this.streamingPlayer.setVolume(volume);
    this.radioPlayer.setVolume(volume);
  }

  currentTrack() {
    return this.queue[this.index];
  }

  currentTime(): number {
    return this.playheadTime;
  }
}
