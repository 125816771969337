/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { COLORS } from 'config/constants/styles';
import { isUrlExternal } from 'config/routes/helpers';
import { generateSelectorsFromTestIds } from 'helpers/utilities';
import useToggle from 'hooks/useToggle';

import { ConfirmationActionMenu } from 'components/ActionMenu';

const ListItem = styled.li`
  list-style: none;
`;

const ListItemInner = styled.div`
  align-items: center;
  color: ${({ $danger }) => ($danger ? COLORS.DANGER : COLORS.GRAY_MEDIUM)};
  display: flex;
  font-size: ${({ $size }) => $size.fontSize};
  padding: ${({ $size }) => $size.padding};
  white-space: nowrap; // meh
  &:hover,
  &:focus,
  &:active {
    background: ${COLORS.GRAY_SUPER_LIGHT};
    color: ${COLORS.GRAY_DARK};
    outline: none;
    text-decoration: none;
  }

  ${({ disabled }) =>
    disabled &&
    `
      background: ${COLORS.GRAY_SUPER_LIGHT};
      cursor: not-allowed;
      color: ${COLORS.GRAY_MEDIUM_LIGHT};
  `}
`;

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
`;

/**
 * [DropdownMenuItem]
 *
 * @param {node} children - content to display in menu item
 * @param {boolean} [closeOnClick] - auto close popover when menu item is clicked
 * @param {node} [icon] - icon component to render prior to item text
 * @param {boolean} [showIcon] - if true, icon will be displayed
 * @param {func} [onClick] - function to run when menu item is clicked. Required if 'to' or 'confirmOnClick'
 * is not passed
 * @param {object} [confirmOnClick] - props to pass to ConfirmationActionMenu (renders as modal on desktop)
 * @param {string} [to] - make menu item a link to an internal or external url
 * @param {string} [newTab] - if 'to' passed, causes link to open in a new browser tab
 *
 * Popover props:
 * @param {func} [closeDropdown] - closes the popover menu (DropdownMenu)
 */

const DropdownMenuItem = ({
  children,
  closeDropdown,
  closeOnClick,
  confirmOnClick: confirmMenuProps,
  disabled,
  icon,
  newTab,
  name,
  onClick,
  size = DropdownMenuItem.SIZES.NORMAL,
  showIcon = true,
  testId,
  style,
  danger,
  to
}) => {
  const { isOpen, close, open } = useToggle();

  const commonAttributes = {
    'data-testid': testId || `DropdownMenuItem-${_.kebabCase(name)}`,
    role: 'button',
    tabIndex: '0',
    $size: size,
    style,
    $danger: danger
  };

  const displayIcon = icon && typeof icon === 'object' && showIcon;

  if (to) {
    if (isUrlExternal(to) || newTab) {
      return (
        <ListItem>
          <ListItemInner
            as={disabled ? 'p' : 'a'}
            href={disabled ? '' : to}
            size={size}
            disabled={disabled}
            target="_blank"
            rel="noreferrer noopener"
            {...commonAttributes}
          >
            {displayIcon && (
              <IconWrapper style={disabled ? { opacity: '50%' } : {}}>{icon}</IconWrapper>
            )}
            {children}
          </ListItemInner>
        </ListItem>
      );
    }

    return (
      <ListItem>
        <ListItemInner as={Link} to={to} {...commonAttributes}>
          {displayIcon && <IconWrapper>{icon}</IconWrapper>}
          {children}
        </ListItemInner>
      </ListItem>
    );
  }

  return (
    <ListItem
      onClick={e => {
        if (confirmMenuProps) {
          if (!isOpen) open();
          return;
        }

        onClick({ e, close: closeDropdown });
        if (closeOnClick) closeDropdown();
      }}
    >
      <ListItemInner {...commonAttributes}>{children}</ListItemInner>

      {confirmMenuProps && (
        <ConfirmationActionMenu
          {...confirmMenuProps}
          isOpen={isOpen}
          close={close}
          closeAll={close}
        />
      )}
    </ListItem>
  );
};

DropdownMenuItem.SIZES = {
  SMALL: {
    fontSize: '1.4rem',
    padding: '0.5rem'
  },
  NORMAL: {
    fontSize: '1.4rem',
    padding: '1rem 3rem 1rem 2rem'
  },
  LARGE: {
    fontSize: '1.6rem',
    padding: '1.25rem 2.5rem 1.25rem 1.25rem'
  }
};

DropdownMenuItem.TEST_IDS = {
  getTestIdForItem: name => `DropdownMenuItem-${_.kebabCase(name)}`
};

DropdownMenuItem.SELECTORS = generateSelectorsFromTestIds(DropdownMenuItem.TEST_IDS);

DropdownMenuItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  closeOnClick: PropTypes.bool,
  confirmOnClick: PropTypes.shape({}),
  testId: PropTypes.string,
  name: PropTypes.string,
  newTab: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  showIcon: PropTypes.bool,
  size: PropTypes.shape(),
  style: PropTypes.shape(),
  to: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),

  // Cloned prop from Dropdown or manually passed via render prop
  closeDropdown: PropTypes.func
};

export default DropdownMenuItem;
