import _ from 'lodash';
import queryString from 'query-string';

import {
  AUDIO_QUALITIES,
  AUDIO_TYPES,
  PLAYER_STATES,
  RADIO_URLS,
  TRACK_PREVIEW_LENGTH_SECONDS
} from 'config/constants';

// eslint-disable-next-line import/prefer-default-export
export const getNextPlayerState = (state, audioChanged) => {
  const { state: playerState, type: playerType } = state;

  if (
    playerState === PLAYER_STATES.STOPPED ||
    playerState === PLAYER_STATES.PAUSED ||
    audioChanged
  ) {
    return PLAYER_STATES.PLAYING;
  }

  if (playerState === PLAYER_STATES.PLAYING && playerType === AUDIO_TYPES.RADIO) {
    return PLAYER_STATES.STOPPED;
  }

  if (playerState === PLAYER_STATES.PLAYING) {
    return PLAYER_STATES.PAUSED;
  }

  return playerState;
};

/**
 * [calculatePreviewPosition] - Calculate the preview starting point based on the midpoint of the track
 *
 * @param {number} durationSeconds
 */

export const calculatePreviewPosition = durationSeconds => {
  // Find midpoint of preview track, subtrack half preview length, and play from there.
  if (durationSeconds) {
    return _.clamp(
      durationSeconds / 2 - TRACK_PREVIEW_LENGTH_SECONDS / 2,
      0,
      durationSeconds
    );
  }

  return 0;
};

/**
 * [calculateLastAllowedPosition]
 *
 * @param {number} previewPositionSeconds
 * @param {number} durationSeconds
 * @returns {number}
 */

export const calculateLastAllowedPosition = (previewPositionSeconds, durationSeconds) => {
  const lastAllowedPosition =
    (previewPositionSeconds + TRACK_PREVIEW_LENGTH_SECONDS) / durationSeconds;
  return Math.min(lastAllowedPosition || 1, 1);
};

/**
 * [calculatePlayerPositions] - Calculate the initial and last allowed position for the Player in percentage (0...1)
 *
 * @param {boolean} isPreview
 * @param {number} durationSeconds - audio file duration in seconds
 * @param {number} positionSeconds - initial or current player position in seconds
 * @param {number} previewPositionSeconds - initial "preview" player position in seconds
 */

export const calculatePlayerPositions = (
  isPreview,
  durationSeconds,
  positionSeconds,
  previewPositionSeconds
) => {
  const initialPosition = positionSeconds / durationSeconds || 0;
  const lastAllowedPosition = isPreview
    ? calculateLastAllowedPosition(previewPositionSeconds, durationSeconds)
    : 1;
  return { initialPosition, lastAllowedPosition };
};

/**
 * [getAudioFileData] - returns streaming file data regardless if track or mix.  Accepts player quality for tracks,
 * and handles fallback to low quality file if audio data for provided track quality doesn't exist
 *
 * @param {object} audioEntity
 * @param {string} [audioQuality]
 * @returns {object} { fn, md5, filesize }
 */

export const getAudioFileData = (audioEntity, audioQuality) => {
  if (audioEntity.__audioType === AUDIO_TYPES.TRACK) {
    const quality = audioQuality || AUDIO_QUALITIES.MEDIUM;
    // Some assets are missing medium quality so fallback to low in those instances.
    const audioFiles = audioEntity.audio[quality].filename
      ? audioEntity.audio[quality]
      : audioEntity.audio[AUDIO_QUALITIES.LOW];

    return audioFiles;
  }

  return {
    filename: audioEntity.filename,
    md5: audioEntity.md5,
    filesize: audioEntity.filesize
  };
};

/**
 * [getStreamingUrl] - builds the streaming url that is used to play (or preload) audio from
 * ProtonPlayer or SoundManager
 */

export const getStreamingUrl = ({
  playerId,
  audioQuality,
  jwt,
  audio,
  radioURL = ''
}) => {
  const { filename, md5 } = getAudioFileData(audio, audioQuality);

  const qs = queryString.stringify({
    fn: filename,
    md5,
    player_id: playerId,
    token: jwt
  });

  const { __audioType: audioType } = audio;
  if (jwt && audioType === AUDIO_TYPES.TRACK) {
    return `${process.env.REACT_APP_MEDIA_SERVER_BASE}/single?${qs}`;
  }

  if (jwt && audioType === AUDIO_TYPES.MIX) {
    return `${process.env.REACT_APP_MEDIA_SERVER_BASE}/stream?${qs}`;
  }

  if (audioType === AUDIO_TYPES.RADIO) {
    return radioURL || RADIO_URLS.SHOUTCAST;
  }

  return null;
};
