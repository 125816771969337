import axios from 'axios';
import { API_BASE_V2, DIGITAL_SERVICE_PROVIDERS as DSP } from 'config/constants';

/**
 * [parseSpotifyArtistId] - Takes spotify share links and parses out the artist id
 * Supported share link formats:
 * Spotify URL: https://open.spotify.com/artist/{artist_id}
 * Spotify URI: spotify:artist:{artist_id}
 *
 * @param {string} linkString - share link provided by spotify
 * @returns {string} artistId
 */

const parseSpotifyArtistId = linkString => {
  if (linkString.includes('spotify:artist:')) {
    return linkString.split(':')[2];
  }

  if (linkString.includes('open.spotify.com/artist/')) {
    const inputWithoutQuery = linkString.split('?')[0];
    const splitString = inputWithoutQuery.split('/');
    return splitString[splitString.length - 1];
  }

  return null;
};

/**
 * [parseAppleMusicArtistId] - Takes apple artist links and parses out the artist id
 * Supported share link formats:
 * URL: https://music.apple.com/us/artist/{artist_name_slug}/{artist_id}
 *
 * @param {string} linkString - share link provided by apple music
 * @returns {string} artistId
 */

const parseAppleMusicArtistId = linkString => {
  if (linkString.includes('music.apple.com/')) {
    const inputWithoutQuery = linkString.split('?')[0];
    const splitString = inputWithoutQuery.split('/');
    return splitString[splitString.length - 1];
  }

  return null;
};

const parseSoundCloudUrl = linkString => {
  if (linkString.includes('soundcloud.com/')) {
    // NOTE: just checks to see if contains soundcloud url.  Entire url (not just artistId) is passed
    // to searchProfilesByUrl for SoundCloud
    return linkString;
  }
  return null;
};

export const DSP_URL_PARSER = {
  [DSP.SPOTIFY]: parseSpotifyArtistId,
  [DSP.APPLE_MUSIC]: parseAppleMusicArtistId,
  [DSP.SOUNDCLOUD]: parseSoundCloudUrl
};

/**
 * [DSP_API] - mapping of endpoints for each Digital Service Provider (DSP) used in verifying / connecting
 * DSP artists to a Proton artist profile.
 *
 * Supported DSPs:
 * - Spotify
 * - Apple Music
 * - SoundCloud
 *
 * Endpoints:
 * - searchProfilesByName
 * - searchProfilesByUrl
 * - associateProfileToUser
 */

const URL_LOOKUP_ERROR =
  "We couldn't find a valid artist ID in the input provided. Please make sure you entered a valid URL/URI.";

export const DSP_API = {
  [DSP.SPOTIFY]: {
    searchProfilesByName: query =>
      axios.get(`${API_BASE_V2}/spotify/artists?q=${query}`).then(({ data }) => data),
    searchProfilesByUrl: query => {
      const artistId = parseSpotifyArtistId(query);
      if (!artistId) {
        return Promise.reject(new Error(URL_LOOKUP_ERROR));
      }

      return axios
        .get(`${API_BASE_V2}/spotify/artists/${artistId}`)
        .then(({ data: axiosData }) => ({
          data: [axiosData.data]
        }))
        .catch(e => {
          const { status } = e.response;
          if (status === 404) return { data: [] };
          throw e;
        });
    }
  },
  [DSP.SOUNDCLOUD]: {
    searchProfilesByName: query =>
      axios.get(`${API_BASE_V2}/soundcloud/users?q=${query}`).then(({ data }) => data),
    searchProfilesByUrl: url =>
      axios
        .get(`${API_BASE_V2}/soundcloud/users?q=${decodeURI(url)}`)
        .then(({ data }) => data)
  },
  [DSP.APPLE_MUSIC]: {
    searchProfilesByName: query =>
      axios.get(`${API_BASE_V2}/apple_music/artists?q=${query}`).then(({ data }) => data),
    searchProfilesByUrl: query => {
      const artistId = parseAppleMusicArtistId(query);
      if (!artistId) {
        return Promise.reject(new Error(URL_LOOKUP_ERROR));
      }

      return axios
        .get(`${API_BASE_V2}/apple_music/artists/${artistId}`)
        .then(({ data: axiosData }) => ({
          data: [axiosData.data]
        }))
        .catch(e => {
          if (e.status === 404) return [];
          throw e;
        });
    }
  }
};
