import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Avatar from 'components/Avatar';
import TrackMobileTileMeta from 'components/TracksTable/TrackMobileTileMeta';

// NOTE: This is for the track header for the **ACTION MENU**

const StyledTrackHeader = styled.div`
  display: flex;

  .TextHeader {
    line-height: 1;
  }
`;

const TrackHeader = track => {
  const coverArtUrl = track.release.cover_art.thumb.url;

  return (
    <StyledTrackHeader>
      {coverArtUrl && (
        <Avatar
          alt="Release cover art"
          imageUrl={encodeURI(coverArtUrl)}
          size={Avatar.SIZES.LARGE}
          lazy={false}
        />
      )}
      <TrackMobileTileMeta track={track} />
    </StyledTrackHeader>
  );
};

TrackHeader.propTypes = {
  release: PropTypes.shape({
    cover_art: PropTypes.object
  })
};

export default TrackHeader;
