/* eslint-disable consistent-return */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'redux/selectors';
import { getUser } from 'redux/actions/user';
import { selectIsJwtExpired } from 'redux/selectors/token';
import { isDataStale } from 'helpers';

const USER_STALE_MINUTES = 5;
const USER_POLLING_INTERVAL_MS = 60 * 60 * 1000; // 1 hour

// NOTE: Because we don't want the useEffect to have so many dependencies, we instead setup an
// interval that continuously dispatches a thunk action, and will check for relevant values at
// run time since reactivity is not important.
const fetchUserDataThunkAction = polling => (dispatch, getState) => {
  const state = getState();
  const { user_id: userId, isFetching, lastFetched } = selectUser(state);
  const isJwtExpired = selectIsJwtExpired(state);

  if (isFetching || !userId || isJwtExpired) return;

  // NOTE: Stale check ensures we don't fetch user data if already recent when polling
  if (polling && !isDataStale(lastFetched, USER_STALE_MINUTES)) return;

  dispatch(getUser(userId, polling));
};

/**
 * [useUserDataPolling]
 *
 * This hook ensures we fetch the most recent user data on app startup (if valid jwt),
 * and then every 1 hour after that.  If there is no user the polling interval is cleared.
 * 'userTokenValidator' handles refetching user after token is refreshed.
 *
 * This hook was created because we have attributes in the user response that determine if a user
 * can have access to certain feature flagged content (i.e. promo inbox).
 */

function useUserDataPolling() {
  const dispatch = useDispatch();
  const { user_id: userId } = useSelector(selectUser);

  // On app startup - try to refetch user regardless if stale data
  useEffect(() => {
    dispatch(fetchUserDataThunkAction());
  }, [dispatch]);

  // Start polling user data if userId is set.
  useEffect(() => {
    if (!userId) return;

    const id = setInterval(() => {
      dispatch(fetchUserDataThunkAction(true));
    }, USER_POLLING_INTERVAL_MS);

    return () => {
      clearInterval(id);
    };
  }, [dispatch, userId]);
}

export default useUserDataPolling;
