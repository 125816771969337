import { useEffect } from 'react';
import Bugsnag from '@bugsnag/js';

import { useAppSelector } from 'hooks/redux';
import { selectJwtJson } from 'redux/selectors/token';
import { selectUser } from 'redux/selectors/user';
import { V2User } from 'types';

type NullUser = { [K in keyof V2User]: null };

function useBugSnag() {
  // adding a type parameter here to correctly account for initial state, if the file where `selectUser`
  // is defined gets converted to typescript we can remove this annotation and the NullUser type above
  const user = useAppSelector<V2User | NullUser>(state => selectUser(state));
  const jwtJson = useAppSelector(state => selectJwtJson(state));

  useEffect(() => {
    const { exp } = jwtJson;

    const { user_id, first_name, last_name, email, username, account_type } = user;

    if (user_id) {
      const name =
        first_name || last_name
          ? `${first_name || ''}${first_name && last_name ? ' ' : ''}${last_name || ''}`
          : 'N/A';
      // User data fields are limited to id, name, and email
      // https://docs.bugsnag.com/platforms/javascript/react/customizing-error-reports/#adding-user-data
      Bugsnag.setUser(`${user_id}`, name, email);

      // Set additional user data in the meta attribute
      Bugsnag.addMetadata('user', {
        id: user_id,
        username,
        account_type,
        jwt_expire: exp
      });
    } else {
      Bugsnag.setUser();
      Bugsnag.clearMetadata('user');
    }
  }, [user, jwtJson]);
}

export default useBugSnag;
