import React from 'react';
import styled from 'styled-components';

import TextHeader from 'components/TextHeader';
import { BREAKPOINTS } from 'config/constants';
import { COLORS } from 'config/constants/styles';
import { useAppSelector } from 'hooks/redux';
// Includes common styled components used in the TabbedHeader Sidebar

export const Label = ({ children }: { children: React.ReactNode }) => (
  <TextHeader
    type={TextHeader.TYPES.XSMALL}
    color={TextHeader.COLORS.SECONDARY}
    uppercase
  >
    {children}
  </TextHeader>
);

export const StyledDivider = styled.div`
  border-bottom: 1px solid ${COLORS.BORDER};
  margin: 0.5rem 0 2.4rem 0;
`;

export const StyledTextBody = styled.div`
  white-space: pre-wrap;
`;

export const SidebarGroup = styled.div`
  border-bottom: 1px solid ${COLORS.BORDER};
  color: #333333;
  font-size: 1.4rem;
  line-height: 1.8rem;
  padding: 1.9rem 0;

  a {
    color: inherit;
    &:hover {
      color: ${COLORS.PRIMARY};
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const SidebarGroupSection = styled.div`
  margin-bottom: 1.9rem;
`;

export const StyledSection = styled.div`
  color: #333333;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-bottom: 1.9rem;
  opacity: 0.9;

  a {
    color: inherit;
    &:hover {
      color: ${COLORS.PRIMARY};
    }
  }
`;

export const StyledDetailsContainerMobile = styled.div`
  padding: 1.7rem 1rem;

  @media screen and (max-width: ${BREAKPOINTS.MD}) {
    margin-top: 2rem;
    padding: 0;
  }
`;

const StyledSidebar = styled.div<{ $collapse: boolean }>`
  margin-top: ${({ $collapse }) => ($collapse ? '2rem' : '5rem')};

  @media screen and (max-width: ${BREAKPOINTS.LG}px) {
    margin-top: 1.2rem;
  }
`;

type SidebarProps = {
  children: React.ReactNode;
  collapse?: boolean;
};

export const Sidebar = ({ children, collapse = false }: SidebarProps) => {
  const hasNotification = useAppSelector<boolean>(
    state => state.ui.tabbedHeaderNotification
  );
  const _collapse = hasNotification || collapse;

  return (
    <StyledSidebar $collapse={_collapse} data-testid="Sidebar">
      {children}
    </StyledSidebar>
  );
};
