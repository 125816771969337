import axios from 'axios';

import { isProduction } from 'config/constants';
import { intervalToDuration } from 'date-fns';

// Only log warning in development environments
export const consoleDev = (message, ...args) => {
  if (!isProduction) console.warn(message, ...args);
};

/*
 * [delay]
 * promise based timeout. Useful for mocking api responses
 *
 * @param {number} [duration=3000] (miliseconds)
 * @param {string || object} [response] promise will resolve returning response payload
 * @param {string} [errorMessage] if passed, promise reject returning error payload
 */

export const delay = (options = {}) => {
  const { duration = 3000, response, errorMessage } = options;
  if (errorMessage) {
    return new Promise((_, reject) =>
      setTimeout(() => reject(new Error(errorMessage)), duration)
    );
  }

  return new Promise(resolve => setTimeout(() => resolve(response), duration));
};

export const areModalsOpen = () => {
  const modalPortalNode = document.getElementById('modals-root');
  return modalPortalNode.childElementCount > 0;
};

export const areActionMenusOpen = () => {
  const actionMenuPortalNode = document.getElementById('action-menus-root');
  return actionMenuPortalNode.childElementCount > 0;
};

export const DEFAULT_ERROR_MESSAGE =
  'There was a problem with your request. Please try again or contact support@proton.com';

/*
 * [formatError] - over time the client has had to deal with error messages from a variety of sources. This function
 * attempts to handle most use cases with some additional options if the default logic isn't desired.
 *
 * Common error sources that are handled
 * - axios: https://github.com/axios/axios#handling-errors
 * - RTK Query: https://redux-toolkit.js.org/rtk-query/usage/error-handling
 * - any thrown JS Exception (i.e. throw new Error('Whoopsies!'))
 *
 *
 * @param {Error | string} error - usually an axios error unless a custom thrown exception on the client.
 * Alternatively can be the error message itself (string)
 * @param {string} [fallbackMessage] - if no error message found (or deemed to be a poor message),
 * this will be used in it's place
 *
 * @returns {object}
 * {
 *   status: 404,
 *   message: 'Artist not found'
 * }
 */

export const formatError = (error, fallbackMessage = DEFAULT_ERROR_MESSAGE) => {
  // If this is an error resulting from a canceled promise, we want to signal
  // to the client that the error should be silenced by default
  if (axios.isCancel(error) || error?.error?.__SILENCE__) {
    return {
      silence: true,
      message: 'Request was silenced'
    };
  }

  const status = error?.status || error?.response?.status;
  const message =
    // Handles use case where just an error message is passed for brevity. I.e. showAlert({ error: "Warning!" })
    (typeof error === 'string' && error) ||
    // RTK Query formatting:
    error?.data?.message ||
    // Axios formatting:
    error?.response?.data?.message ||
    error?.response?.data?.error ||
    error?.message;

  // NOTE: The following scenarios don't yield very helpful for the user
  // - "Request failed with status code XXX" is a default set by axios
  // - By default the server message for 500 errrors aren't helpful
  const badMessage =
    status === 500 || !message || message.includes('Request failed with status code');

  return {
    message: badMessage ? fallbackMessage : message,
    status
  };
};

/**
 * [generateSelectorsFromTestIds] - creates an object of 'data-testid' selector for provided object where the key is
 * the selector reference and the value is the testid
 *
 * This was created to in order to repeat the testid naming in various places.
 * NOTE: unit tests and the component itself reference the testid, where as E2E test reference the selector
 * (i.e. '[data-testid=...]'). With this helper, we can just name the testids in one static variable
 *
 * @param {object} componentTestIds - object of testIds by name. Example:
 * {
 *   CONTAINER: 'MyComponent',
 *   TITLE: 'MyComponent-title',
 *   getTestIdForThing: value = `MyComponent-thing-${value}`
 * }
 * @returns {object} selector mapping of test ids
 * {
 *   CONTAINER: '[data-testid=MyComponent]',
 *   TITLE: '[data-testid=MyComponent-title]',
 *   getSelectorForThing: value => `[data-testid=MyComponent-thing-${value}]`
 * }
 */

export const generateSelectorsFromTestIds = componentTestIds =>
  Object.keys(componentTestIds).reduce((accum, key) => {
    const testId = componentTestIds[key];

    // if a function is passed with 'getTestId...' prefix, automagically create the selector equivalent.
    if (typeof testId === 'function') {
      if (!key.includes('getTestId')) return accum;
      const newKey = key.replace('getTestId', 'getSelector');

      return {
        ...accum,
        [newKey]: value => `[data-testid=${testId(value)}]`
      };
    }

    return {
      ...accum,
      [key]: `[data-testid=${testId}]`
    };
  }, {});

export const formatDuration = (startDate, endDate = new Date()) => {
  if (!startDate) return '';

  const { years, months, days, hours } = intervalToDuration({
    start: new Date(startDate),
    end: endDate
  });

  if (years) return `${years} yr`;
  if (months) return `${months} mo`;
  if (days) return `${days} d`;
  if (hours) return `${hours} hr`;
  return '1 hr';
};
