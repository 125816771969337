import _ from 'lodash';

/** [resampleData()] – Takes an array of waveform data and rescales it to any length
 * @param {array} uintArray – React ref of uInt8Array to set rescaled data to
 * @param {array} initialData - Self explanatory. Typically the waveform array passed from the API.
 * @param {number} finalArrayLength - Self explanatory. How long do you want the returned array? Usually based on the numberOfBars in waveforms.
 * @param {object} dimensions - React ref to dimensions of parent DOM element.
 */
export const resampleData = (uintArray, initialData, finalArrayLength, dimensions) => {
  const initialArrayLength = initialData.length;
  const deltaT = initialArrayLength / finalArrayLength.current;
  const filteredData = initialData.filter(i => i > 0.05);

  const resampledData = [...Array(initialArrayLength)].map((value, key) => {
    const t = deltaT * key;
    const leftWeight = t % 1;
    const leftIndex = Math.floor(t);

    // This takes the raw 0-1 value frequency data coming out of AnalyserNode
    // and interpolates between the left/right values of each to calculate a
    // new value based on the width number of bars based on the container width.
    const newVal =
      (leftWeight * filteredData[leftIndex] +
        (1 - leftWeight) * filteredData[leftIndex + 1]) /
      256;

    return newVal;
  });

  const scaledData = scaleData(resampledData, dimensions);
  uintArray.current = scaledData;
};

/** [scaleData()] – Takes an array of waveform data and scales it based on the desired pixel height
 * @param {array} data – Array of waveform data
 * @param {object} dimensions - React ref of dimensions from parent DOM node. Used to calculate height.
 */
export const scaleData = (data, dimensions) => {
  const min = _.min(data);
  const max = _.max(data);

  const { height } = dimensions;

  return data.map((item, index) => {
    const scaledVal = Math.abs(((data[index] - min) * (height / -2)) / (max - min) + 2);
    if (scaledVal < 2) return 2;
    return scaledVal;
  });
};
