import { useEffect } from 'react';
/**
 * useOnKeyPress
 * @param {string || number} key - the name of the key to respond to (or keyCode), compared against event.key
 * or event.keyCode
 * @param {function} action - the action to perform on key press
 * @param {object} [options]
 * @param {string} [options.keyActionDirection] - customize whether to execute passed action on keydown or keyup
 */
export default function useOnKeyPress(key, action, options = {}) {
  const { keyActionDirection = 'keyup' } = options;

  useEffect(() => {
    function onKeyPress(e) {
      const keyCode = typeof key === 'number';
      const keyCodeMatch = keyCode && e.keyCode === key;
      const keyMatch = e.key === key;

      if (keyCodeMatch || keyMatch) action();
    }
    window.addEventListener(keyActionDirection, onKeyPress);
    return () => window.removeEventListener(keyActionDirection, onKeyPress);
  }, [key, action, keyActionDirection]);
}
