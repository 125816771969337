import _ from 'lodash';
import { formatError } from 'helpers/utilities';

// Below are the some helpers for creating common reducer pattern
// where endpoints that return an array of data.

// byId: {
//   [id]: {
//     ...data
//   }
// },
// fetchedCollection: {
//   data: [], // or {}
//   __lastFetched: null
//   __fetchError, null
//   __isFetching, false
// }

/**
 * [handleDataByIdSuccess] - DEPRECATED - for new reducers use 'handleArrayToByIdSuccess'
 * Receives collection/array of data and maps their ids as object keys
 */

export const handleDataByIdSuccess = (state, { data: dataArray }, dataFormatter) =>
  dataArray.reduce(
    (accum, dataItem) => ({
      ...accum,
      // Overwrite any new label data since fields could be different
      [dataItem.id]: {
        ...(dataFormatter ? dataFormatter(dataItem) : dataItem)
      }
    }),
    state
  );

export const fetchInitialState = {
  data: [], // array of ids
  __lastFetched: null,
  __fetchError: null,
  __fetchErrorDate: null,
  __fetchErrorStatus: null,
  __isFetching: false
};

// Note (ROB)
// handleFetch() is DEPRECATED!
//
// Leaving in place to convert reducers using it to the newer handleFetchRequest()
// function separately

export const handleFetch = state => ({
  ...state,
  __fetchError: null,
  __fetchErrorDate: null,
  __isFetching: true
});

export const FETCHING_STATE = {
  __fetchError: null,
  __fetchErrorDate: null,
  __fetchErrorStatus: null,
  __isFetching: true
};

/**
 * [restoreInitialFetchState] - use in conjunction with app startup event "garbageCollect", which will ensure any
 * __isFetching state is initialized back to false. Could happen if user refreshes while pending fetch request.
 *
 * @returns {object} returns new state with __isFetching set to false
 */

export const restoreInitialFetchState = state => {
  // if __isFetching is stored in state, then we can assume this is not a byId reducer
  if (state.__isFetching) {
    return {
      ...state,
      __isFetching: false
    };
  }

  return Object.entries(state).reduce(
    (accum, [key, value]) => ({
      ...accum,
      [key]: {
        ...value,
        __isFetching: false
      }
    }),
    {}
  );
};

/**
 * [handleFetchRequest] - will initialize state for reducer
 *
 * @param {string} [id]
 * @param {object} [initialData] - if there is not any data in redux when fetch request made,
 * you can pass an initial state.  This is usually used when data is an object and you want to set
 * the available fields without data. ex: initialData = { id: null, name: '' }
 * @param {object} [initialState] - in the event that you would like to include some initial state that
 * is not part of the default 'FETCHING_STATE' object, you can do so here. This is used with handlePaginationFetchRequest
 * to include additional initial state for pagination.
 * @returns {object}
 * {
 *  [id]: {
 *    data: [], // or initialData
 *    __fetchError: null,
 *    __fetchErrorDate: null,
 *    __isFetching: true
 *  }
 * }
 *
 * OR:
 *
 * {
 *    data: [], // or initialData
 *    __fetchError: null,
 *    __fetchErrorDate: null,
 *    __isFetching: true
 * }
 */

export const handleFetchRequest = (state, args = {}, meta = {}) => {
  const { id } = args;
  const { initialState, initialData } = meta;
  const data = initialData || []; // will be overwritten if data exists

  if (id) {
    return {
      ...state,
      [id]: {
        data,
        ...initialState,
        ...state[id],
        ...FETCHING_STATE
      }
    };
  }

  return {
    data,
    ...initialState,
    ...state,
    ...FETCHING_STATE
  };
};

/**
 * [handleFetchError] - reducer utility for setting error state
 *
 * @param {object | string} error - can be the actual Error object or the error message
 * @param {number} [status] - error status code
 * @returns {object}
 */

export const handleFetchError = (state, { id, error }) => {
  const { status, message, silence } = formatError(error);

  const fetchErrorState = {
    __fetchError: message,
    __fetchErrorStatus: status,
    __fetchErrorDate: Date.now(),
    __isFetching: false,
    __silenceError: silence
  };

  if (id) {
    return {
      ...state,
      [id]: {
        ...state[id],
        ...fetchErrorState
      }
    };
  }

  return {
    ...state,
    ...fetchErrorState
  };
};

/**
 * [getNextData] - determines what the next data attribute in the fetch reducer should be returned.
 * See 'handleFetchSuccess' for more details about the different parameters.
 *
 * @returns {object || object[]}
 */

const getNextData = ({ state, data, id, normalize, overwrite, equalityCheck }) => {
  const prevData = id ? state[id].data : state.data;

  // When 'data' is an array, we can either overwrite the array, or add to it.
  if (Array.isArray(data)) {
    if (normalize) {
      const dataIds = data.map(d => d.id);
      const dataChanged = equalityCheck ? _.isEqual(dataIds, prevData) : true;

      if (overwrite) {
        // NOTE: we will keep the old data if unchanged so that selectors will not be triggered again
        return dataChanged ? dataIds : prevData;
      }

      return dataChanged ? _.union(prevData, dataIds) : prevData;
    }

    // Below is for an array of data that is not normalized to another reducer (storing more than just ids)
    const dataChanged = equalityCheck ? _.isEqual(data, prevData) : true;
    if (!dataChanged) return prevData;
    if (overwrite) return data;

    // TODO: handle replacement of specific data within the data attribute. I'm not sure the exact
    // logic that will be needed so just leaving a warning message so that we no it is not configured.
    console.warn(
      'This reducer is configured to add/replace only new data, but the code has not been written. Fix this.'
    );
    return data;
  }

  // When 'data' is an object, it can either be overwritten entirely or spread into the existing data
  const dataChanged = equalityCheck ? _.isEqual(data, prevData) : true;
  if (!dataChanged) return prevData;

  if (overwrite) return data;

  // if data is an object, and we aren't overwriting, we'll spread any new data into the
  // existing data attribute
  return {
    ...prevData,
    ...data
  };
};

const getFetchSuccessState = () => ({
  __fetchError: null,
  __fetchErrorDate: null,
  __fetchErrorStatus: null,
  __isFetching: false,
  __lastFetched: Date.now()
});

/**
 * [handleFetchSuccess]
 *
 * Receives a collection or array of data and writes information as either
 * normalized ids or as an arbitrary data object.
 *
 * @param {object[] || object} data- Collection of data, must either have `id` attributes or `normalize: false`.
 * Data can also be a single object if pertains to single entity
 * @param {number} [id] - Single id number for collection
 * @param {bool} [normalize=true] - When data is an array, `normalize: true` means it will only store the ids from
 * passed data, and assumes the normalized data is being stored in another reducer (i.e. {entity}.byId).
 * Sometimes, it's not practical to normalize the data, in that case pass `normalize: false` and all information
 * in the 'data' argument will be retained
 * @param {bool} [overwrite=true/false] - determines whether the existing data attribute will be overwritten.
 * If data is an array, this defaults to true.  If data is an object then this will default to false.
 * @param {bool} [equalityCheck=false] - if true, will check that new data is different from old data. If same,
 * the previous state will be returned to prevent selectors from updating
 *
 * @returns {object} returns next state
 */

export const handleFetchSuccess = (
  state,
  { data, id, normalize = true, overwrite: overwriteArg, equalityCheck = false }
) => {
  const overwriteDefault = Array.isArray(data);
  const overwrite = typeof overwriteArg === 'boolean' ? overwriteArg : overwriteDefault;

  const fetchSuccessState = {
    data: getNextData({
      state,
      data,
      normalize,
      overwrite,
      equalityCheck
    }),
    ...getFetchSuccessState()
  };

  if (id) {
    return {
      ...state,
      [id]: {
        ...state[id],
        ...fetchSuccessState
      }
    };
  }

  return {
    ...state,
    ...fetchSuccessState
  };
};

/**
 * [handleArrayToByIdSuccess] - helper that handles mapping array of normalized data to a
 * byId reducer
 *
 * @param {object[]} data
 * @param {bool} [overwrite=false] if true, will replace any all existing data for given entity
 * @returns {object} See example below:
 *
 * {
 *   ...state,
 *   [id]: {
 *     data: {},
 *     ...fetchSuccessMetaData
 *     __lastFetched: Date.now()
 *   }
 * }
 */

export const handleArrayToByIdSuccess = (
  state,
  { data: dataArray, overwrite = false }
) => {
  const FETCH_SUCCESS_STATE = getFetchSuccessState();

  return dataArray.reduce((accum, dataItem) => {
    const prevState = state[dataItem.id] || { data: {} };
    return {
      ...accum,
      [dataItem.id]: {
        ...prevState,
        data: overwrite
          ? dataItem
          : {
              ...prevState.data,
              ...dataItem
            },
        ...FETCH_SUCCESS_STATE
      }
    };
  }, state);
};

// //////////////// PAGINATION UTILITIES
export const initialPaginationState = {
  meta: {
    current_page: 0,
    next_page: 1,
    prev_page: null,
    total_pages: null,
    total_count: null,
    per_page: null
  },
  ...fetchInitialState
};

export const handlePaginationFetchSuccess = (state, payload, config) => {
  const {
    response: { data, meta },
    paginationReducer,
    paginationReducerId,
    normalizeReducer
  } = payload;

  // handle storing of data from the response
  if (config.normalizeReducer && config.normalizeReducer === normalizeReducer) {
    return handleArrayToByIdSuccess(state, { data });
  }

  // handle storing of meta and data (or data.id's) from response
  if (config.paginationReducer && config.paginationReducer === paginationReducer) {
    const paginationState = paginationReducerId
      ? state[paginationReducerId] || initialPaginationState
      : state;
    const prevData = paginationState.data || [];

    // If normalizeReducer param included, we only want to store the ids
    const responseData = normalizeReducer ? data.map(d => d.id) : data;
    const nextData = normalizeReducer
      ? _.union(prevData, responseData)
      : _.unionBy(prevData, responseData, 'id');

    // TODO: Need to see if existing reducer utility can be used here
    const nextPaginationState = {
      ...paginationState,
      data: nextData,
      meta,
      __isFetching: false,
      __error: null,
      __lastFetched: Date.now()
    };

    if (!paginationReducerId) {
      return nextPaginationState;
    }

    return {
      ...state,
      [paginationReducerId]: nextPaginationState
    };
  }

  return state;
};

export const handlePaginationFetchRequest = (state, payload, config) => {
  const { paginationReducer, paginationReducerId } = payload;

  if (paginationReducer === config.paginationReducer) {
    return handleFetchRequest(
      state,
      { id: paginationReducerId },
      { initialState: initialPaginationState }
    );
  }
  return state;
};

export const handlePaginationFetchError = (state, payload, config) => {
  const { paginationReducer, paginationReducerId, error } = payload;

  if (paginationReducer === config.paginationReducer) {
    return handleFetchError(
      state,
      { id: paginationReducerId, error },
      { initialState: initialPaginationState }
    );
  }
  return state;
};
