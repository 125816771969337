import React from 'react';

import { DEFAULT_ICON_COLOR, ICON_PROP_TYPES } from './config';

const RemoveCircle = ({ color = DEFAULT_ICON_COLOR, size = 18, className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    height={size}
    style={style}
    viewBox="0 0 512 512"
  >
    <title>RemoveCircle</title>
    <path
      d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
      fill="none"
      stroke={color}
      strokeMiterlimit="10"
      strokeWidth="32"
    />
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      d="M336 256H176"
    />
  </svg>
);

RemoveCircle.propTypes = ICON_PROP_TYPES;

export default RemoveCircle;
