import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useQuery } from 'urql';

import { NavTab, NavTabContainer, NavTabGroup } from 'components/Nav';
import ReleaseHeadliner from 'components/ReleaseHeadliner';
import RedirectRoute from 'components/Router/RedirectRoute';
import { UserRoute } from 'components/Router/UserRoute';
import TabbedHeaderLayout from 'components/TabbedHeaderLayout';
import { Sidebar } from 'components/TabbedHeaderLayout/Sidebar';
import routeMap from 'config/routes';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useBreakpoint from 'hooks/useBreakpoint';
import {
  fetchLabelReleases as fetchLabelReleasesAction,
  fetchReleaseTracksAction
} from 'redux/actions/releases';
import { makeSelectReleaseTracksByReleaseId } from 'redux/selectors/multiReducer';
import ErrorPage, { RouteNotFound, getErrorPropsForCode } from 'routes/ErrorPage';
import { AlgoliaTrack } from 'types';

import ReleaseDetails from './ReleaseDetails';
import ReleaseFeedback from './ReleaseFeedback';
import ReleaseLinksButtonsMobile from './ReleaseLinksButtonsMobile';
import ReleaseTracklist from './ReleaseTracklist';
import { handleReleaseError } from './handleReleaseError';
import { graphql } from 'gql';
import { logError } from 'helpers';

export const ReleaseQuery = graphql(`
  query getRelease($id: ID!) {
    release(id: $id) {
      uid
      id
      name
      description
      date
      slug
      preorderDate
      headlineArtists {
        id
        name
        slug
      }
      permissions {
        viewFeedback
      }
      label {
        id
        name
        slug
      }
      coverArt(size: MEDIUM) {
        url
      }
      viewer {
        isLabelManager
        isArtistMember
      }
      ...ReleaseDetailsFields
      ...MobileButtonsReleaseFields
    }
  }
`);

const Release = () => {
  const match = useRouteMatch(routeMap.releases.root);
  const rootUrl = match?.url || '';

  const dispatch = useAppDispatch();
  const { id: releaseId } = useParams<{ id: string }>();

  // TODO: Move release tracks query to the release gql query once we have migrated track tables to support it.
  const selectReleaseTracksByReleaseId = useMemo(makeSelectReleaseTracksByReleaseId, []);
  const releaseTracksState = useAppSelector<{
    data: AlgoliaTrack[];
    __isFetching: boolean;
  }>(state => selectReleaseTracksByReleaseId(state, { releaseId }));
  const { data: releaseTracks, __isFetching: isFetchingReleaseTracks } =
    releaseTracksState;

  const [{ data: releaseResponse, fetching: isFetchingRelease, error }] = useQuery({
    query: ReleaseQuery,
    variables: { id: releaseId }
  });
  const release = releaseResponse?.release;

  const labelId = release?.label?.id;
  const canViewFeedback = release?.permissions?.viewFeedback;
  const isFetching = isFetchingRelease || isFetchingReleaseTracks;
  const queueName = `ReleasePage:${releaseId}:Tracks`;

  const isLargeScreen = useBreakpoint(
    useBreakpoint.BREAKPOINTS.LARGE,
    useBreakpoint.DIRECTIONS.UP
  );
  const isMediumScreen = useBreakpoint(
    useBreakpoint.BREAKPOINTS.MEDIUM,
    useBreakpoint.DIRECTIONS.UP
  );
  const isSmallScreen = !isLargeScreen && !isMediumScreen;

  const releaseRoutes = routeMap.releases;
  const paths = {
    root: releaseRoutes.root,
    feedback: rootUrl + releaseRoutes.feedback,
    tracklist: rootUrl + releaseRoutes.tracks,
    details: rootUrl + releaseRoutes.details,
    default: rootUrl + releaseRoutes.tracks
  };

  const releaseTracksQueue = useMemo(
    () =>
      releaseTracks.map((_track, index) => ({
        ..._track,
        __queueIndex: index
      })),
    [releaseTracks]
  );

  useEffect(() => {
    dispatch(fetchReleaseTracksAction(releaseId)).catch(logError);
  }, [dispatch, releaseId]);

  // TODO: Should this be handled in the release tracks page?
  useEffect(() => {
    if (!labelId) return;
    dispatch(fetchLabelReleasesAction({ id: labelId, limit: 5, excludeId: releaseId }));
  }, [labelId, dispatch, releaseId]);

  const errorConfig = handleReleaseError(error);
  if (errorConfig || (!release && !isFetchingRelease)) {
    return <ErrorPage error={getErrorPropsForCode(errorConfig?.code)} />;
  }

  //TODO: Configure graphql codegen to not type Date scalar as `any` so that type assertions below can be removed
  //if preorder date hasn't come, don't show Link Tree button
  const showLinkTreeButton =
    (release?.preorderDate as string | undefined) &&
    Date.parse(release?.preorderDate as string) < Date.now();

  const shareModalLocation = {
    pathname: `${rootUrl}/share`,
    state: { modal: true, noScroll: true }
  };

  return (
    <TabbedHeaderLayout
      sidebarBreakpoint={useBreakpoint.BREAKPOINTS.MEDIUM}
      header={{
        entityType: 'release',
        imageUrl: release?.coverArt?.url || '',
        title: release?.name || '',
        subtitle: (
            <ReleaseHeadliner headlinerArtists={release?.headlineArtists || []} />
        ),
        titleLabel: 'Release',
        loading: isFetching,
        renderTabs: () => (
          <div>
            {isSmallScreen && showLinkTreeButton && (
              <ReleaseLinksButtonsMobile
                release={release}
                shareLocation={shareModalLocation}
              />
            )}

            <NavTabContainer>
              <NavTabGroup>
                {isSmallScreen && <NavTab title="Details" path={paths.details} />}

                <NavTab title="Tracklist" path={paths.tracklist} />

                {canViewFeedback && <NavTab title="Feedback" path={paths.feedback} />}
              </NavTabGroup>
            </NavTabContainer>
          </div>
        )
      }}
      renderSidebar={() => (
        <Sidebar>
          <ReleaseDetails
            loading={isFetching}
            releaseDetail={release}
            shareLocation={shareModalLocation}
            sidebar
          />
        </Sidebar>
      )}
      renderMain={() => (
        <Switch>
          <RedirectRoute
            exact
            path={paths.details}
            shouldRedirect={isLargeScreen}
            to={paths.default}
            render={() => <ReleaseDetails loading={isFetching} releaseDetail={release} />}
          />

          <Route
            exact
            path={paths.tracklist}
            render={() => {
              if (isFetchingRelease) return null;

              return (
                <ReleaseTracklist
                  tracks={releaseTracksQueue}
                  queueName={queueName}
                  release={release}
                  releaseId={releaseId}
                  loading={isFetching}
                />
              );
            }}
          />

          <UserRoute
            exact
            path={paths.feedback}
            render={() => <ReleaseFeedback releaseId={releaseId} />}
            isAuthorized={!!canViewFeedback}
            forceLoading={!canViewFeedback && isFetching}
          />

          {/* See app switch for modal */}
          <Route exact path={'/releases/:id/:name/share'} render={() => <div />} />

          <Redirect from={paths.root} to={paths.default} />

          <RouteNotFound />
        </Switch>
      )}
    />
  );
};

export default Release;
