import React from 'react';
import PropTypes from 'prop-types';

import ArtistFullNames from 'components/ArtistFullNames';

const ArtistHeader = ({ image_url, name, members, subtitle }) => (
  <div className="ListItem__content">
    {image_url && (
      <div
        className="ListItem__image"
        style={
          image_url && {
            backgroundImage: `url(${encodeURI(image_url)})`
          }
        }
      />
    )}
    <div>
      <div className="ListItem__title">{name}</div>
      {subtitle && <div className="ListItem__subtitle">{subtitle}</div>}
      {members && <ArtistFullNames className="ListItem__subtitle" members={members} />}
    </div>
  </div>
);

ArtistHeader.propTypes = {
  image_url: PropTypes.string,
  name: PropTypes.string,
  members: PropTypes.arrayOf(PropTypes.shape()),
  subtitle: PropTypes.node
};

export default ArtistHeader;
