import PropTypes from 'prop-types';
import React from 'react';

import { getTrackArtistsByRole } from 'helpers';
import { ARTIST_ROLES } from 'config/constants';
import CommaList from 'components/CommaList';

const OriginalArtistCell = ({ artists, highlightAttribute, hit }) => {
  const originalArtists = getTrackArtistsByRole(artists, ARTIST_ROLES.ORIGINAL);
  const featuredArtists = getTrackArtistsByRole(artists, ARTIST_ROLES.FEATURED);
  const combinedArtists = [...originalArtists, ...featuredArtists];

  return (
    // NOTE: Truncate component does not work with HTML elements
    <span data-testid="track-original-artists">
      <CommaList
        data={combinedArtists}
        title
        attribute="name"
        urlAttribute={artist => `${artist.slug}/tracks`}
        // optional props:
        highlightAttribute={highlightAttribute}
        hit={hit}
      />
    </span>
  );
};

OriginalArtistCell.propTypes = {
  artists: PropTypes.arrayOf(PropTypes.shape()),
  highlightAttribute: PropTypes.string,
  hit: PropTypes.shape()
};

export default OriginalArtistCell;
