import { isAdminUser } from 'helpers/user';

type SearchCategory =
  | 'genres'
  | 'artists'
  | 'labels'
  | 'shows'
  | 'tracklists'
  | 'users'
  | 'userLookup';

// Define search categories and the order for results to be displayed
const SEARCH_CATEGORIES: SearchCategory[] = [
  'genres',
  'artists',
  'labels',
  'shows',
  'tracklists'
];
const ADMIN_SEARCH_CATEGORIES: SearchCategory[] = [
  ...SEARCH_CATEGORIES,
  'users',
  'userLookup'
];

export const getOrderedResultCategories = (user): SearchCategory[] => {
  if (isAdminUser(user)) return ADMIN_SEARCH_CATEGORIES;
  return SEARCH_CATEGORIES;
};