import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';

import { COLORS, BOX_SHADOW, MEDIUM_BREAK_POINT, ALERT_TYPES } from 'config/constants';

import Button, { ButtonGroup } from 'components/Button';
import Flex from 'components/Flex';
import TextHeader from 'components/TextHeader';

import * as CustomAlerts from './CustomAlerts';
import { ObjectValues } from 'types';

export type AlertType = 'message' | 'notice' | 'warning';
export type TypeProp = { type: AlertType };
export type CustomAlertName = keyof typeof CustomAlerts;
type CustomAlertComponent = ObjectValues<typeof CustomAlerts>;

interface AlertStyle {
  background: string;
  color: string;
  dismissButtonType: string;
  timerBackground: string;
}

const STYLES_BY_TYPE: { [A in AlertType]: AlertStyle } = {
  message: {
    background: COLORS.WHITE,
    color: 'inherit',
    dismissButtonType: Button.COLORS.LIGHT,
    timerBackground: COLORS.GRAY_MEDIUM
  },
  notice: {
    background: COLORS.WHITE,
    color: 'inherit',
    dismissButtonType: Button.COLORS.LIGHT,
    timerBackground: COLORS.GRAY_MEDIUM
  },
  warning: {
    background: COLORS.DANGER,
    color: COLORS.WHITE,
    dismissButtonType: Button.COLORS.TRANSLUCENT,
    timerBackground: COLORS.WHITE
  }
};

const StyledFlex = styled(Flex)`
  @media (max-width: ${MEDIUM_BREAK_POINT}px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const Container = styled.div<TypeProp>`
  background: ${({ type }) => STYLES_BY_TYPE[type].background};
  border-radius: 0.6rem;
  box-shadow: ${BOX_SHADOW};
  color: ${({ type }) => STYLES_BY_TYPE[type].color};
  margin-bottom: 1.5rem;
  max-width: 74rem;
  min-width: 20rem;
  overflow: hidden;
  padding: 1rem;
  position: relative;

  /* Link color on warning alert is very difficult to see, override below: */
  ${({ type }) =>
    type === ALERT_TYPES.WARNING &&
    `
    a {
      color: ${COLORS.WHITE};
      text-decoration: underline;
    }
  `}

  @media screen and (max-width: ${MEDIUM_BREAK_POINT}px) {
    margin: 1rem 0;
    padding: 1.5rem;
    width: 100%;
  }
`;

const Content = styled.div`
  flex: 1 1 auto;
  line-height: 1.3;
  padding: 0.5rem;
  text-align: left;

  @media screen and (max-width: ${MEDIUM_BREAK_POINT}px) {
    padding: 0;
  }
`;

const StyledButtonGroup = styled(ButtonGroup).attrs(() => ({ fit: true }))`
  flex: 0 0 auto;
  height: 100%;
  margin: 0 0 0 3rem;

  @media screen and (max-width: ${MEDIUM_BREAK_POINT}px) {
    margin: 1rem 0 0 0;
    width: 100%;
  }
`;

const TimerProgress = styled(animated.div)<TypeProp>`
  background: ${({ type }) => STYLES_BY_TYPE[type].timerBackground};
  bottom: 0;
  height: 0.2rem;
  left: 0;
  opacity: 0.5;
  position: absolute;
`;

const DismissButton: React.FC<{
  onClick: () => void;
  type: AlertType;
  disabled?: boolean;
  children?: ReactNode;
}> = ({ onClick, type, children, disabled }) => (
  <Button
    data-testid={Alert.TEST_IDS.DISMISS_BUTTON}
    onClick={onClick}
    disabled={disabled}
    color={STYLES_BY_TYPE[type].dismissButtonType}
  >
    {children || 'Dismiss'}
  </Button>
);

export interface AlertProps {
  title?: string;
  message?: ReactNode;
  component?: CustomAlertName;
  componentProps?: unknown; // refer to individual custom alerts for their required props
  type?: AlertType;
  dismissable?: boolean;
  dismissAlert: () => void;
  onDismiss?: () => void;
  dismissText?: string;
  timeout?: number | boolean;
  persistAfterLogout?: boolean; // by default, alerts are cleared on logout
  actions?: {
    text: string;
    onClick: () => void;
  }[];
}

interface CustomAlertProps {
  type: AlertType;
  message: string;
  dismissAlert: () => void;
  protonid: string;
  action: 'added' | 'removed';
  artist: { name: string; id: number };
  label: { name: string; id: number };
  connected_proton_artist_id: number;
  code: number;
  description: string;
  redirectPathname: string;
}

const Alert = ({
  title,
  message,
  actions = [],
  dismissable = true,
  dismissText,
  onDismiss,
  type = 'message',
  dismissAlert,
  timeout,
  component,
  componentProps
}: AlertProps) => {
  // Passing `timeout: true` should use the default timeout
  const timeoutDuration =
    typeof timeout === 'number' ? timeout : timeout === false ? 0 : 5000;

  const handleDismiss = () => {
    if (onDismiss) onDismiss();
    dismissAlert();
  };

  const timerProps = useSpring({
    from: { width: '0%' },
    to: { width: '100%' },
    config: { duration: timeoutDuration },
    onRest: timeout ? handleDismiss : () => null
  });

  const CustomAlert: CustomAlertComponent | null = component
    ? CustomAlerts[component]
    : null;

  if (CustomAlert) {
    return (
      <Container type={type} data-testid={Alert.TEST_IDS.ALERT} data-testtype={type}>
        <StyledFlex align="center">
          <CustomAlert
            {...(componentProps as CustomAlertProps)}
            type={type}
            dismissAlert={dismissAlert}
          />
        </StyledFlex>

        {timeout && <TimerProgress style={timerProps} type={type} />}
      </Container>
    );
  }

  const hasButtons = dismissable || actions.length > 0;

  return (
    <Container type={type} data-testid={Alert.TEST_IDS.ALERT} data-testtype={type}>
      <StyledFlex align="center">
        <Alert.Content>
          {title && (
            <TextHeader className="mb-0" type={TextHeader.TYPES.MEDIUM}>
              {title}
            </TextHeader>
          )}
          {message instanceof String ? <p>{message}</p> : message}
        </Alert.Content>

        {hasButtons && (
          <Alert.ButtonGroup>
            {dismissable && (
              <Alert.DismissButton onClick={handleDismiss} type={type}>
                {dismissText}
              </Alert.DismissButton>
            )}

            {actions.map(({ onClick, text }) => (
              <Button key={text} onClick={onClick}>
                {text}
              </Button>
            ))}
          </Alert.ButtonGroup>
        )}
      </StyledFlex>

      {timeout && <TimerProgress style={timerProps} type={type} />}
    </Container>
  );
};

Alert.TEST_IDS = {
  ALERT: 'Alert',
  DISMISS_BUTTON: 'Alert-dismiss-button'
} as const;

Alert.Content = Content;
Alert.ButtonGroup = StyledButtonGroup;
Alert.DismissButton = DismissButton;

Alert.TYPES = {
  ...ALERT_TYPES
};

export default Alert;
