import PropTypes from 'prop-types';
import React from 'react';

import {
  GenreSearchResult,
  ShowSearchResult,
  ArtistSearchResult,
  TracklistSearchResult,
  UserSearchResult,
  UserLookupSearchResult,
  LabelSearchResult
} from './SearchResultItems';

// Determines which result component to render based on result category

const renderSearchResult = (result, rest) => {
  const props = { ...result, ...rest };
  return {
    genres: <GenreSearchResult {...props} />,
    shows: <ShowSearchResult {...props} />,
    artists: <ArtistSearchResult {...props} />,
    tracklists: <TracklistSearchResult {...props} />,
    labels: <LabelSearchResult {...props} />,
    users: <UserSearchResult {...props} />,
    userLookup: <UserLookupSearchResult {...props} />
  };
};

const UniversalSearchResultRow = ({ result, ...rest }) => {
  const { category } = result;

  return (
    <div className={`UniversalSearchResultRow__container ${category}__row`}>
      {renderSearchResult(result, rest)[category]}
    </div>
  );
};

UniversalSearchResultRow.propTypes = {
  result: PropTypes.shape({
    // Result category returned from algolia: genres, shows, artists, tracklists
    category: PropTypes.string,
    mix: PropTypes.object // entire mix obect returned from algolia
    // ... see SearchResult components for other props
  }),
  hideSearchResults: PropTypes.func
};

export default UniversalSearchResultRow;
