import React from 'react';

import { DEFAULT_ICON_COLOR } from './config';
import { IconProps } from './iconTypes';

const Checkmark = ({
  color = DEFAULT_ICON_COLOR,
  size = 18,
  strokeWidth = 32,
  className,
  style,
  title
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    height={size}
    style={style}
    viewBox="0 0 512 512"
  >
    <title>{title || 'Checkmark'}</title>
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      d="M416 128L192 384l-96-96"
    />
  </svg>
);

export default Checkmark;
