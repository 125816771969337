import axios from 'axios';
import { API_BASE_V2 } from 'config/constants';

export const getAcknowledgements = () =>
  axios.get(`${API_BASE_V2}/acknowledgements`).then(({ data }) => data);

export const createAcknowledgement = message_type =>
  axios
    .post(`${API_BASE_V2}/acknowledgements`, { message_type })
    .then(({ data }) => data);
