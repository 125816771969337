import axios from 'axios';

import { ALGOLIA, API_BASE_V2 } from 'config/constants';
import { searchAlgolia } from './algolia';

export const getTopArtists = ({ sinceDaysAgo = 7, limit = 100 }) =>
  axios
    .get(`${API_BASE_V2}/popular/artists`, {
      params: {
        since_days_ago: sinceDaysAgo,
        limit
      }
    })
    .then(({ data }) => data);

/**
 * [getArtist] - Get full user bio, if the bio is the users own or they have the permission to see the
 * full object with connections & users, it will return the full serializer. Otherwise it will return
 * the basic artist serializer.
 *
 * NOTE: GET /api/users/:phpbb_user_id/artists/:artist_id has been deprecated in place of this endpoint
 *
 * @param {number} artistId
 * @returns {object} Full bio, or basic artist serializer, depending on auth of user
 */

export const getArtist = artistId =>
  axios.get(`${API_BASE_V2}/artists/${artistId}`).then(({ data }) => data);

export const getArtistAlgolia = artistId =>
  searchAlgolia({
    index: ALGOLIA.ARTIST_INDEX,
    facetFilters: [[`objectID:${artistId}`]]
  }).then(({ hits }) => {
    const artist = hits[0];

    // NOTE: our API would typically 404 on missing entities. We will simulate the same when looking
    // for a specific entity in algolia
    if (!artist) {
      // eslint-disable-next-line no-throw-literal
      throw { message: `Could not find release id ${artistId}`, status: 404 };
    }

    return artist;
  });

/**
 * [searchForArtistsAlgolia] search algolia index for matching query. See additional params for refined
 * filtering
 *
 * @param {string} query - text to match on
 * @param {number} [limit] - override default search results per page
 * @param {number[]} [omitArtistIds] - array of artist ids.  Specifically omit a artists from search results
 * @param {number[]} [labelIdsReleasedOn] - if passed, will limit to artists with releases on the labelIds passed
 * @param {number[]} [withinArtistIds] - only perform textual search for matching artists within passed artist ids.
 * @returns {object[]} hits
 */

export const searchForArtistsAlgolia = ({
  query,
  omitArtistIds = [],
  limit,
  withinArtistIds = [],
  labelIdsReleasedOn
}) => {
  // create filter that omits artist ids passed
  // REF: https://www.algolia.com/doc/api-reference/api-parameters/filters/
  const omitArtistIdsFilter = omitArtistIds.reduce(
    (filterAccum, id, i) => {
      const last = i === omitArtistIds.length - 1;
      const artistString = `id:${id}`;

      if (!last) return `${filterAccum}${artistString} AND NOT `;
      return `${filterAccum}${artistString})`;
    },
    omitArtistIds.length > 0 ? '(NOT ' : ''
  );

  // create filter that will only textual search within the artist ids passed
  const withinArtistIdsFilter = withinArtistIds.reduce(
    (filterAccum, id, i) => {
      const artistString = `id:${id}`;
      const last = i === withinArtistIds.length - 1;
      if (!last) return `${filterAccum}${artistString} OR `;
      return `${filterAccum}${artistString})`;
    },
    withinArtistIds.length > 0 ? '(' : ''
  );

  const bothFilters = withinArtistIdsFilter && omitArtistIdsFilter;
  const filters = `${omitArtistIdsFilter}${
    bothFilters ? ' AND ' : ''
  }${withinArtistIdsFilter}`;

  const facetFilters = labelIdsReleasedOn && [
    labelIdsReleasedOn.map(labelId => `releases_on.id:${labelId}`)
  ];

  return searchAlgolia({
    query,
    index: ALGOLIA.ARTIST_INDEX,
    limit,
    filters,
    facetFilters
  }).then(response =>
    // Currently, algolia doesn't have an id attribute for artistId, so add one here.
    response.hits.map(artistHit => ({
      ...artistHit,
      id: Number(artistHit.objectID)
    }))
  );
};

/**
 * [validateArtist] check for validation errors on for artist attributes passed
 *
 * @param {object} attributes - Ex: { name: "Charles Navi" }
 * @returns {object}
 * if valid artist attributes, returns:
 * { success: true }
 *
 * if validations fail, a 422 error is thrown with:
 * {
 *   "validation_errors": {
 *      "name": ["has already been taken"]
 *   }
 * }
 */

export const validateArtist = attributes =>
  axios.post(`${API_BASE_V2}/validate/artist`, attributes).then(({ data }) => data);
