import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { COLORS } from 'config/constants/styles';
import { ICON_TYPES } from './iconTypes';

/*
11/18/20 – DEPRECATED, use `component/Icons` folder instead!

How to Icon For Dummies, First Edition

This will probably get moved into a Github wiki page eventually, but for now colocating
it with the component. If you have issues just message me, aka Rob the Icon Lord. Each icon
needs the attributes in the static below, the most important of which is the `path`. This is
generated by joining multiple same colored objects in an SVG in Illustrator, creating a
compound object, and then performing a Union within the Pathfinder window. After this
you can copy the path and then immediately paste it into your code editor.

For non illustrator users... was able to convert to a single path using https://www.vecteezy.com/editor
Just import and re-download your svg of interest.
*/

class Icon extends Component {
  static TYPES = ICON_TYPES;

  static COLORS = {
    LIGHT: COLORS.GRAY_LIGHT,
    DARK: COLORS.GRAY_DARK,
    PRIMARY: COLORS.PRIMARY,
    WHITE: COLORS.WHITE
  };

  render() {
    const { type, width = 30, color, height, ...rest } = this.props;

    const { path, name, aspectRatio, title, defaultColor } = type;

    const fillColor = color || defaultColor || Icon.COLORS.LIGHT;

    return (
      <svg
        width={width}
        height={height || width}
        viewBox={`0 0 ${aspectRatio[0]} ${aspectRatio[1]}`}
        preserveAspectRatio="xMidYMid meet"
        aria-labelledby={name}
        role="img"
        {...rest}
      >
        <title id={name}>{title}</title>
        {Array.isArray(path) ? (
          path.map((_path, index) => (
            // Support optionally passing per-path fillColors
            // eslint-disable-next-line react/no-array-index-key

            <path
              key={index}
              d={_path.path || _path}
              fill={_path.fillColor || fillColor}
            />
          ))
        ) : (
          <path d={path} fill={fillColor} />
        )}
      </svg>
    );
  }
}

Icon.propTypes = {
  type: PropTypes.shape({
    aspectRatio: PropTypes.arrayOf(PropTypes.number),
    defaultColor: PropTypes.string,
    name: PropTypes.string,
    path: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            path: PropTypes.string,
            fillColor: PropTypes.string
          })
        ])
      )
    ]),
    title: PropTypes.string
  }).isRequired,
  width: PropTypes.number,
  color: PropTypes.string,
  height: PropTypes.number
};

export default Icon;
