import axios, { AxiosResponse, Method } from 'axios';
import { createApi } from '@reduxjs/toolkit/query/react';

import { API_BASE_V2 } from 'config/constants';
import { delay } from 'helpers/utilities';
import { BaseHit, SearchAlgoliaParams, searchAlgolia } from './algolia';

/**
 * In this file we setup any api services that we'd like to cache and invoke using RTK query.  For each
 * api, we are just initializing the base url and reducer path. Then we create "slices" in the appropriate
 * api file which extends the relevant rtk api.
 *
 * See 'injectEndpoints' in the RTK docs:
 * https://redux-toolkit.js.org/rtk-query/usage/code-splitting
 *
 * For information regarding the custom base queries see:
 * https://redux-toolkit.js.org/rtk-query/usage/customizing-queries
 */

export const TAG_TYPES = {
  ARTIST_PROFILES: 'ArtistProfiles',
  RELEASES: 'Releases',
  PRO_USER: 'ProUser'
} as const;

export const defaultTransformResponse = (response: { data: unknown }) => response.data;

interface AxiosBaseQueryParams<ReqData = unknown, RespData = unknown> {
  url: string;
  method: Method;
  body?: ReqData;
  mockResponse?: RespData;
}

const axiosBaseQuery =
  ({ baseUrl = '' }: { baseUrl: string }) =>
  async <RequestData, ResponseData>({
    url,
    method,
    body,
    mockResponse
  }: AxiosBaseQueryParams<RequestData, ResponseData>) => {
    if (mockResponse) {
      const result = (await delay({
        response: { data: mockResponse }
      })) as AxiosResponse<ResponseData, RequestData>;
      return result;
    }

    // This is the format expected by RTK Query
    // https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#fetchbasequery-defaults
    try {
      const result = (await axios({
        url: baseUrl + url,
        method,
        data: body
      })) as AxiosResponse<ResponseData, RequestData>;
      return result;
    } catch (err: unknown) {
      if (!axios.isAxiosError(err)) throw err;
      const data = err.response?.data;
      const errorData = typeof data === 'object' ? data : { data };
      return { error: { status: err.response?.status, ...errorData } };
    }
  };

export const protonApi = createApi({
  tagTypes: Object.values(TAG_TYPES),
  reducerPath: 'api.proton',
  baseQuery: axiosBaseQuery({ baseUrl: `${API_BASE_V2}/` }),
  // endpoints will be injected in standalone api files for each entity
  endpoints: () => ({})
});

/**
 * Base query function for using rtk query with algolia
 *
 * `errorOnEmpty` triggers an error on an empty hits array response, to be used when
 * searching by entity id and seeking to have similar functionality to the proton api
 */

interface AlgoliaBaseQueryParams<RespData extends BaseHit = BaseHit>
  extends SearchAlgoliaParams {
  mockResponse?: RespData;
  errorOnEmpty?: boolean;
}

const algoliaBaseQuery = async <TData extends BaseHit = BaseHit>({
  mockResponse,
  errorOnEmpty,
  ...params
}: AlgoliaBaseQueryParams<TData>) => {
  if (mockResponse) return delay({ response: { data: mockResponse } });

  try {
    const response = await searchAlgolia<TData>(params);

    if (errorOnEmpty && !response.hits[0]) {
      const error = {
        message: 'No results found',
        statusCode: 404
      };
      throw error;
    }

    return { data: response };
  } catch (error: unknown) {
    if (error instanceof Error) {
      return { error: { status: error['statusCode'] as number, data: error.message } };
    }
  }
};

export const algoliaApi = createApi({
  reducerPath: 'api.algolia',
  baseQuery: algoliaBaseQuery,
  endpoints: () => ({})
});
