import UrlPattern from 'url-pattern';

import routeMap from './routeMap';

// replaces url params (i.e. :id, :name) with params object passed in
// TODO: Deprecate with "generatePath" from 'react-router'
export const generateRouteString = (urlTemplate, params) => {
  const pattern = new UrlPattern(urlTemplate);

  return pattern.stringify(params);
};

// For use in endpoints where we are still only providing slug (i.e. "homesick-music")
export const generateLabelUrl = (label, appendUrl) => {
  const urlString = generateRouteString(routeMap.labels.root, {
    id: label.id,
    name: label.label_slug
  });

  return appendUrl ? urlString + appendUrl : urlString;
};

export const generateSoundCloudUrl = artistSlug => `https://soundcloud.com/${artistSlug}`;

// Referenced from is-url-external npm package
// https://github.com/mrded/is-url-external/blob/master/index.js
const getLinkHost = url => {
  if (/^https?:\/\//.test(url)) {
    // Absolute URL.
    // The easy way to parse an URL, is to create <a> element.
    // @see: https://gist.github.com/jlong/2428561
    const parser = document.createElement('a');
    parser.href = url;
    return parser.hostname;
  }
  return window.location.hostname;
};

/**
 * [isUrlExternal] - determine if passed absolute url is external to the current domain.
 */

export const isUrlExternal = url => {
  const host = window.location.hostname;
  const linkHost = getLinkHost(url);

  return host !== linkHost;
};
