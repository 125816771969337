/**
 * Migration10: in refactoring the token reducer to use userId instead of xAuthUserId
 *
 * This was part of a PR which simplified setToken and removed a bunch of confusing parameter renaming.
 */

const rootMigration10 = state => {
  const { token, ...rest } = state;

  const { xAuthUserId, userId, ...tokenState } = token;
  return {
    ...rest,
    token: {
      ...tokenState,
      // In case userId was already saved to token for some reason, we include that as
      // a just-in-case fallback
      userId: xAuthUserId || userId || null
    }
  };
};

export default rootMigration10;
