/**
 * Test to see if hairline borders are supported (i.e. retina device)
 * http://dieulot.net/css-retina-hairline
 */

if (window.devicePixelRatio && window.devicePixelRatio >= 2) {
  const testElem = document.createElement('div');
  testElem.style.border = '.5px solid transparent';
  document.body.appendChild(testElem);
  if (testElem.offsetHeight === 1) {
    document.querySelector('html')?.classList.add('retina-screen');
  }

  document.body.removeChild(testElem);
}
