import { CombinedError } from 'urql';

type FindInOptions = {
  extensionCode: string;
  path?: string[];
};

export const findInGraphQLError = (
  error: CombinedError,
  { extensionCode, path }: FindInOptions
) => {
  const extensionMatches = error.graphQLErrors.filter(
    ({ extensions }) => extensions.code === extensionCode
  );

  if (path) {
    const pathMatch = extensionMatches.find(matchingError => {
      return matchingError?.path?.join('.') === path.join('.');
    });
    return !!pathMatch;
  }

  return extensionMatches.length > 0;
};
