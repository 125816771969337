import PropTypes from 'prop-types';
import React from 'react';

const ArtistFullNames = ({ members, className }) => {
  if (!members || !members.length) return null;

  return (
    <div className={className}>
      {members.map(({ pro_email, first_name, last_name }, i) => (
        <span key={pro_email}>
          {i > 0 && ' | '}
          <span>
            {first_name} {last_name}
          </span>
        </span>
      ))}
    </div>
  );
};

ArtistFullNames.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstname: PropTypes.string,
      lastname: PropTypes.string
    })
  ),
  className: PropTypes.string
};

export default ArtistFullNames;
