import React, { ReactElement, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from 'urql';
import routeMap from 'config/routes';
import { logError } from 'helpers/logError';
import { logFeatureMetric } from 'helpers/logMetric';
import { useAppDispatch } from 'hooks/redux';
import useCurrentUser from 'hooks/useCurrentUser';
import useReleaseLinksSharer from 'hooks/useReleaseLinksSharer';
import { showAlert } from 'redux/actions/ui';

import {
  DISABLED_BACKGROUND_COLOR,
  BORDER_RADIUS_REM
} from 'components/Form/Inputs/styled';
import Modal from 'components/Modal';
import { StyledDivider } from 'components/TabbedHeaderLayout/Sidebar';
import FacebookLogo from 'components/Icons/Facebook';
import MessengerLogo from 'components/Icons/Messenger';
import XTwitterLogo from 'components/Icons/X-Twitter';
import WhatsAppLogo from 'components/Icons/WhatsApp';
import { COLORS } from 'config/constants';
import EmailIcon from 'components/Icons/Email';
import PhoneIcon from 'components/Icons/Phone';
import Avatar from 'components/Avatar';
import TelegramLogo from 'components/Icons/Telegram';
import BlurredBackground from 'components/BlurredBackground';
import { StyledTombstoneAnimation } from 'components/Tombstone';
import { ReleaseQuery } from './Release';
import CopyToClipboardTextInput from 'components/Form/Inputs/CopyToClipboardTextInput';
import { GetReleaseQuery } from 'gql/graphql';
import CommaList from 'components/CommaList';
import { arrayToCommaList } from 'helpers';

const ModalBanner = styled.p`
  background: ${DISABLED_BACKGROUND_COLOR};
  border-radius: ${BORDER_RADIUS_REM};
  padding: 1rem;
  margin: -0.5rem 0 1.5rem 0;
`;

const ReleaseName = styled.h1`
  font-size: 3.5rem;
  font-weight: 900;
  color: #fff;
  margin: 0;
`;

const SocialIconWrapper = styled.div<{ $background?: string }>`
  width: 60px;
  height: 60px;
  background: ${({ $background }) => ($background ? $background : 'transparent')};

  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
    box-shadow: none;
  }
`;

const SocialIconList = styled.div<{ $loading: boolean }>`
  ${({ $loading }) => $loading && StyledTombstoneAnimation}

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.6rem;
  min-height: 4rem;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const ReleasePreviewContainer = styled.div`
  width: 100%;
  z-index: 10;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
`;

const ReleasePreviewInfo = styled.div<{ $loading: boolean }>`
  ${({ $loading }) => $loading && StyledTombstoneAnimation}

  display: flex;
  padding: 10px;
  color: #fff;
  align-items: center;
  position: relative;
  min-height: 4rem;
`;

const SocialMediaIcon = ({
  href,
  background,
  icon,
  serviceName
}: {
  href: string;
  background: string;
  icon: ReactElement;
  serviceName: string;
}) => {
  return (
    <a
      href={href}
      title={`Share via ${serviceName}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <SocialIconWrapper $background={background}>{icon}</SocialIconWrapper>
    </a>
  );
};

const SignInLink = () => (
  <Link
    to={location => ({
      ...location,
      pathname: routeMap.authentication.signIn,
      state: { modal: true }
    })}
    onClick={() => {
      logFeatureMetric('release_link', 'share_modal.sign_in');
    }}
  >
    Sign In
  </Link>
);

const ShareReleaseLinkModal = () => {
  const { user } = useCurrentUser();
  const signedIn = !!user.id;
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  // we are reusing the same request as the release page since it's likely already been requested (cached)
  // when visiting this modal
  const [{ data, fetching, error }] = useQuery({
    query: ReleaseQuery,
    variables: { id }
  });

  const { url } = useReleaseLinksSharer(id);

  const release = data?.release;
  const label = release?.label;
  const isUserRelease = !!(
    release?.viewer?.isArtistMember || release?.viewer?.isLabelManager
  );

  useEffect(() => {
    if (error) {
      logError(error);
      dispatch(showAlert({ error }));
    }
  }, [dispatch, error]);

  useEffect(() => {
    logFeatureMetric('release_link', 'share_modal.opened');
  }, []);

  const previewText = generatePreviewText(release);
  const labelHashtag = label
    ? label?.name?.replaceAll(/\s/g, '').toLocaleLowerCase()
    : '';
  const protonHashtags = 'newrelease,newmusic,proton';
  const hashtags = `${labelHashtag},${protonHashtags}`;

  return (
    <Modal title="Share This Release">
      {generateModalBanner(signedIn, isUserRelease)}

      <ReleasePreviewContainer>
        <BlurredBackground imageSrc={release?.coverArt?.url} />
        <ReleasePreviewInfo
          $loading={fetching}
          data-testId={ShareReleaseLinkModal.TEST_IDS.RELEASE_PREVIEW}
        >
          {!error && (
            <>
              <Avatar
                size={'68px'}
                className="mr-2"
                imageUrl={release?.coverArt?.url}
                altTag="Cover Art"
              />
              <div>
                <ReleaseName>{release?.name}</ReleaseName>
                <CommaList data={release?.headlineArtists} attribute="name" />
              </div>
            </>
          )}
        </ReleasePreviewInfo>
      </ReleasePreviewContainer>

      <StyledDivider style={{ margin: '2rem 0' }} />

      <SocialIconList
        $loading={fetching}
        data-testId={ShareReleaseLinkModal.TEST_IDS.SOCIAL_ICON_LIST}
      >
        {!fetching && (
          <>
            <SocialMediaIcon
              serviceName="Email"
              href={`mailto:?subject=${previewText}&body=${url}%0D%0A%20`}
              background={COLORS.PRIMARY}
              icon={<EmailIcon color="#fff" size={29} />}
            />
            <SocialMediaIcon
              serviceName="SMS"
              href={`sms:?&body=${previewText}%0D%0A${url}`}
              background={COLORS.PRIMARY}
              icon={<PhoneIcon color="#fff" size={29} />}
            />
            <SocialMediaIcon
              serviceName="WhatsApp"
              href={`https://wa.me/?text=${previewText}%0A${url}`}
              background="#24D366"
              icon={<WhatsAppLogo color="#fff" size={36} />}
            />
            <SocialMediaIcon
              serviceName="Telegram"
              href={`https://t.me/share/url?url=${url}&text=${previewText}`}
              background="#0088cc"
              icon={<TelegramLogo color="#fff" size={34} />}
            />
            <SocialMediaIcon
              serviceName="Facebook"
              href={`https://www.facebook.com/share.php?u=${url}`}
              background="#316FF6"
              icon={<FacebookLogo color="#fff" size={30} />}
            />
            <SocialMediaIcon
              serviceName="Facebook Messenger"
              href={`http://www.facebook.com/dialog/send?link=${url}&app_id=1081671593098022&redirect_uri=https://www.protonradio.com`}
              background="#0084ff"
              icon={<MessengerLogo color="#fff" size={30} />}
            />
            <SocialMediaIcon
              serviceName="Twitter"
              href={`https://www.twitter.com/intent/tweet?url=${url}&text=${previewText}%0A&hashtags=${hashtags}`}
              background="#000"
              icon={<XTwitterLogo color="#fff" size={30} />}
            />
          </>
        )}
      </SocialIconList>

      {!error && (
        <CopyToClipboardTextInput
          onCopy={() => logFeatureMetric('release_link', 'share_modal.link_copied')}
          className="mt-3"
          testId={ShareReleaseLinkModal.TEST_IDS.COPY_URL_INPUT}
          text={fetching ? 'Loading...' : url || ''}
          title={'Go to release link!'}
          hyperlink
        />
      )}
    </Modal>
  );
};

function generateModalBanner(isSignedIn: boolean, isUserRelease: boolean) {
  if (!isSignedIn) {
    return (
      <ModalBanner data-testid={ShareReleaseLinkModal.TEST_IDS.SIGN_IN_BANNER}>
        Is this your release? <SignInLink /> for a personalized Share URL for stats and
        insights from your fans!
      </ModalBanner>
    );
  }

  if (isUserRelease) {
    return (
      <ModalBanner data-testid={ShareReleaseLinkModal.TEST_IDS.SIGN_IN_BANNER}>
        Copy your personalized Release Link to share with fans! View insights on clicks
        and views anytime by visiting the link yourself.
      </ModalBanner>
    );
  }
}

export const generatePreviewText = (release: GetReleaseQuery['release']) => {
  const headlineArtistsFormatted = arrayToCommaList(release?.headlineArtists || [], {
    attribute: 'name'
  });
  const isBeforeReleaseDate = new Date(release?.date as string) > new Date();
  const isPreOrder =
    !!release?.preorderDate &&
    new Date(release.preorderDate as string) < new Date() &&
    isBeforeReleaseDate;

  if (isPreOrder) {
    return `Pre-order ${release?.name} from ${headlineArtistsFormatted} on ${release?.label?.name} now!`;
  }

  if (isBeforeReleaseDate) {
    return `Coming soon on ${release?.label?.name}, ${release?.name} from ${headlineArtistsFormatted}!`;
  }

  return `Listen to ${release?.name} from ${headlineArtistsFormatted} released on ${release?.label?.name}`;
};

ShareReleaseLinkModal.TEST_IDS = {
  CONTAINER: 'share-release-link-modal',
  SIGN_IN_BANNER: 'share-release-link-modal-sign-in-link',
  COPY_URL_INPUT: 'share-release-link-modal-copy-url-input',
  RELEASE_PREVIEW: 'share-release-link-modal-release-preview',
  SOCIAL_ICON_LIST: 'share-release-link-modal-social-icon-list'
};

export default ShareReleaseLinkModal;
