import ReactDOM from 'react-dom';

/**
 * [Portal]
 * Simple wrapper component to append passed children into Portal DOM Node.
 * Now you're thinking with Portals.
 *
 * @prop {node} [domNode] - Target DOM node to append the portal into.
 */

const Portal = ({ domNode = document.getElementById('portals-root'), children }) =>
  ReactDOM.createPortal(children, domNode);

export default Portal;
