import { v4 as uuidv4 } from 'uuid';

/**
 * @param {object} algoliaResponse - response from searching algolia show index
 * @param {string} entityName - will be used as the as the category attribute
 * used to identify which UniversalSearchResultRow to display
 */

const formatSearchResultByEntity = (algoliaResponse, entityName) => {
  const results = [];
  if (!algoliaResponse) return results;

  algoliaResponse.hits.forEach(entity => {
    const matchObjs = entity._highlightResult;
    const matched = {};
    let entityData;

    // for each searchable field - capture matched text with highlighting tags
    Object.entries(matchObjs).forEach(([matchedField, matchObj]) => {
      // field might not have highlight tags, but handled in UI
      matched[matchedField] = matchObj.value;

      if (['full', 'partial'].includes(matchObj.matchLevel)) {
        // if we haven't grabbed entity data, do so now
        if (!entityData) {
          // extract include highlight result info
          const { _highlightResult, ...rest } = entity;
          entityData = rest;
        }
      }
    });

    if (entityData) {
      results.push({
        ...entityData,
        matched,
        category: entityName,
        key: uuidv4() // unique iterator for UI rendering
      });
    }
  });

  return results;
};

export default formatSearchResultByEntity;
