import PropTypes from 'prop-types';
import React from 'react';

import useBreakpoint from 'hooks/useBreakpoint';
import TracklistTableMobile from './TracklistTableMobile';
import TracklistTableDesktop from './TracklistTableDesktop';
import TracklistTableRowMobile from './TracklistTableRowMobile';
import useCurrentUser from '../../hooks/useCurrentUser';

/**
 * [TracklistTable] - used when rendering a list of tracks from a given release.  Includes tracklist order.
 * If listing a random assortment of tracks (not by release) see TrackTable
 *
 * @param {object[]} tracks - array of tracks. Must have queue attributes __audioType, __audioId, & __queueIndex
 * @param {string} queueName
 * @param {string} loading - if tracks are loading
 */

const TracklistTable = props => {
  const isMobile = useBreakpoint(
    useBreakpoint.BREAKPOINTS.SMALL,
    useBreakpoint.DIRECTIONS.DOWN
  );

  const { user } = useCurrentUser();

  const tableProps = {
    ...props,
    userSettings: user.settings
  };

  if (isMobile) {
    return <TracklistTableMobile {...tableProps} />;
  }

  return <TracklistTableDesktop {...tableProps} />;
};

TracklistTable.SELECTORS = {
  ROW_MOBILE: TracklistTableRowMobile.SELECTORS.CONTAINER,
  ROW_DESKTOP: TracklistTableDesktop.SELECTORS.ROW
};

TracklistTable.propTypes = {
  tracks: PropTypes.arrayOf(PropTypes.shape()),
  queueName: PropTypes.string,
  loading: PropTypes.bool
};

export default TracklistTable;
