import { createReducer } from '@reduxjs/toolkit';
import { getInitialAuthState } from 'helpers';
import { REHYDRATE } from 'redux-persist';

import { clearToken, setToken } from 'redux/actions/token';

interface TokenState {
  jwt: string | null;
  refreshToken: string | null;
  userId: number | null;
  // Masquerade attributes (admin only)
  isImpersonating?: boolean;
}

const initialState: TokenState = {
  jwt: null,
  refreshToken: null,
  userId: null,
  isImpersonating: false
};

export default createReducer(initialState, builder => {
  builder
    .addCase(REHYDRATE, () => {
      const { jwt, refreshToken, userId, isImpersonating } = getInitialAuthState();
      if (!jwt || !refreshToken || !userId) return initialState;

      return {
        isImpersonating,
        jwt,
        refreshToken,
        userId
      };
    })
    .addCase(
      setToken,
      (state, { payload: { jwt, refreshToken, userId, isImpersonating } }) => ({
        ...state,
        isImpersonating,
        jwt,
        refreshToken: refreshToken || state.refreshToken,
        userId
      })
    )
    .addCase(clearToken, () => initialState);
});
