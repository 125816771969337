import { createReducer } from 'redux-act';
import _ from 'lodash';

import featureFlags from 'config/features/featureFlags';

import { toggleFeatureFlag, resetFeatureFlags } from 'redux/actions/features';
import { rehydrateAction } from 'redux/actions/utilities';

const createInitialState = () =>
  Object.keys(featureFlags).reduce(
    (accum, flag) => ({
      ...accum,
      [flag]: {
        ...featureFlags[flag],
        enabled: featureFlags[flag].live
      }
    }),
    {}
  );

const initialState = createInitialState();

const featureFlagReducer = createReducer(
  {
    [toggleFeatureFlag]: (state, { payload: { flag } }) => ({
      ...state,
      [flag]: {
        ...state[flag],
        enabled: !state[flag].enabled
      }
    }),
    [resetFeatureFlags]: state => {
      const resetFlags = createInitialState();

      return {
        ...state,
        ...resetFlags
      };
    },
    [rehydrateAction]: (state, { payload = {}, key }) => {
      // This redux action fires for each individual persist reducer (i.e. root or player)
      if (key !== 'root') return state;

      // NOTE: If no persisted data, payload will be undefined
      const { features: persistedFlags = {} } = payload;

      // If a new feature flag is added/removed/modified, we need to modify flag state in redux.
      // For simplicity, just blow it away and create again.
      if (_.difference(Object.keys(featureFlags), Object.keys(state)).length > 0) {
        const resetFlags = createInitialState();

        return {
          ...state,
          ...resetFlags
        };
      }

      // Otherwise, ensure we capture updates to the live flag, but persist the enabled flag.
      const nextState = Object.keys(featureFlags).reduce((accum, flag) => {
        const prevEnabled = persistedFlags[flag]?.enabled;
        const isFeatureLive = featureFlags[flag].live;
        const prevLive = persistedFlags[flag]?.live;
        const wasFeatureDisabled = !isFeatureLive && prevLive;

        return {
          ...accum,
          [flag]: {
            ...persistedFlags[flag],
            enabled: prevEnabled && !wasFeatureDisabled ? true : false,
            live: isFeatureLive
          }
        };
      }, {});

      return nextState;
    }
  },
  initialState
);

featureFlagReducer.options({ payload: false });

export default featureFlagReducer;
