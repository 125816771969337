import { isAdminUser } from 'helpers/user';
import { selectUserArtistProfiles, selectUserShowProfiles } from 'redux/selectors/user';

export const getMixTotalListenCount = mix =>
  (mix.sum_l || 0) + (mix.ondemandlistens || 0);

export const formatNumber = number => {
  if (typeof number === 'number') {
    return number.toLocaleString('en-US');
  }
  return number || 0;
};

export const getMixActions = mix => {
  const { show_slug, artist_slug, genre_name, genre_slug } = mix;

  const genreAction = genre_slug
    ? [
        {
          name: `View ${genre_name} Genre`,
          icon: 'icon-link.svg',
          to: genre_slug
        }
      ]
    : [];

  return [
    {
      name: 'View Show',
      icon: 'icon-link.svg',
      to: show_slug
    },
    {
      name: 'View Artist',
      icon: 'icon-link.svg',
      to: artist_slug
    },
    ...genreAction
  ];
};

/**
 * [getMixDownloadOptionsForUser] - returns which criteria allows a user to download a mix,
 * if any.
 *
 * @param {object} mix
 * @param {object} user
 * @returns {object}
 *
 * {
 *   downloadable - user can or cannot download this mix
 *   showHost     - user can download this mix because they are a show host for the show the mix has released on
 *   mixArtist    - user can download this mix because they are a member of the artist profile that created this mix
 *   admin        - user can download this mix because they are admin
 * }
 */

export const getMixDownloadOptionsForUser = (mix, user) => {
  const isAdmin = isAdminUser(user);

  const userArtistProfiles = selectUserArtistProfiles({ user });
  const userShowProfiles = selectUserShowProfiles({ user });

  const isMixArtist = userArtistProfiles.some(({ id }) => mix.artist_id === id);
  const isShowHost = userShowProfiles.some(({ id }) => mix.show_id === id);

  const downloadable = isAdmin || isMixArtist || isShowHost;

  return {
    downloadable,
    showHost: isShowHost,
    mixArtist: isMixArtist,
    admin: isAdmin
  };
};

export const isMixDownloadableForUser = (mix, user) => {
  const { downloadable } = getMixDownloadOptionsForUser(mix, user);
  return downloadable;
};
