import PropTypes from 'prop-types';
import React from 'react';

import { PLAYER_STATES } from 'config/constants';
import useProtonPlayer from 'hooks/useProtonPlayer';
import pauseIcon from 'images/icon-pause.svg';
import playIcon from 'images/icon-play.svg';

import Button from './Button';

/**
 * [SimplePlayButton] - normal button that shows play or pause state based on the passed audio
 * Currently does NOT support queues
 *
 * @param {object} audio
 * @param {object} className
 */

const SimplePlayButton = ({ audio, className }) => {
  const player = useProtonPlayer();

  const isAudioInPlayer = audio.id === player.currentTrack?.id;
  const isPlaying = isAudioInPlayer && player.state === PLAYER_STATES.PLAYING;

  return (
    <Button
      data-testid="SimplePlayButton"
      className={className}
      onClick={() => {
        player.send('playAudio', { queue: [audio] });
      }}
      size={Button.SIZES.SMALL}
    >
      {isPlaying ? (
        <>
          <img alt="play" src={pauseIcon} />
          Pause
        </>
      ) : (
        <>
          <img alt="play" src={playIcon} />
          Play
        </>
      )}
    </Button>
  );
};

SimplePlayButton.SELECTOR = '[data-testid=SimplePlayButton]';

SimplePlayButton.propTypes = {
  audio: PropTypes.shape({
    __audioType: PropTypes.string,
    id: PropTypes.number
  }),
  className: PropTypes.string
};

export default SimplePlayButton;
