import React from 'react';

import { selectIsTrackOnPromo } from 'redux/selectors/promoReleases';
import { selectIsTrackDownloadable } from 'redux/selectors/tracks';
import { useQuery } from 'urql';

import { ReleaseDspQuery } from 'graphql/queries/release';
import { releaseStoreInfoButtonsTransform } from 'graphql/transforms';
import { getTrackActions } from 'helpers';

import ActionMenu, { ActionMenuToggleState } from 'components/ActionMenu/ActionMenu';
import ActionMenuList from 'components/ActionMenu/ActionMenuList';
import Container from 'components/Layout/Container';
import DownloadTrackActionItem from './DownloadTrackActionItem';
import TrackMenuHeader from './TrackMenuHeader';
import { Audio } from 'helpers/tracks';
import { ActionMenuItemProps } from 'components/ActionMenu/ActionMenuItem';
import { useAppSelector } from 'hooks/redux';
import PromoReactionWidget from 'components/PromoReactionWidget';

interface TrackActionMenuProps extends ActionMenuToggleState {
  track: Audio;
  showCompile?: boolean;
  canDownload: boolean;
  isPromo: boolean;
}

const TrackActionMenu = ({
  track,
  showCompile,
  isOpen,
  close,
  closeOnNavigation
}: TrackActionMenuProps) => {
  // Get DSP info for track
  //TODO: move to tracksTable once GQL is suppoted
  const [{ data, fetching, error }] = useQuery({
    query: ReleaseDspQuery,
    variables: { id: track.release.id.toString() },
    pause: !track.release.id && !isOpen
  });
  const dspLinks = fetching || error ? null : releaseStoreInfoButtonsTransform(data);
  const isPromo = useAppSelector(state => selectIsTrackOnPromo(state, { audio: track }));
  const canDownload = useAppSelector(state =>
    selectIsTrackDownloadable(state, { audio: track })
  );

  const trackActions = getTrackActions({
    track,
    dspLinks
  }) as ActionMenuItemProps[];

  return (
    <>
      <ActionMenu isOpen={isOpen} close={close} closeOnNavigation={closeOnNavigation}>
        <TrackMenuHeader
          track={track}
          showCompile={Boolean(showCompile)}
          isPromo={isPromo}
        />

        {isPromo && (
          <Container>
            <PromoReactionWidget track={track} close={close} />
          </Container>
        )}

        <ActionMenuList actions={trackActions} closeMenu={close}>
          {canDownload && !isPromo && (
            <DownloadTrackActionItem track={track} closeParentMenu={close} />
          )}
        </ActionMenuList>
      </ActionMenu>
    </>
  );
};

export default TrackActionMenu;
