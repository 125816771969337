import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';

import soundsystemLogo from 'images/soundsystem-sign-in-logo.svg';

const StyledButtonWrapper = styled(Button)`
  background: #333 !important;
  max-width: 50rem;

  border-radius: 2px;
  display: flex;
  font-size: 2.4rem !important;
  padding: 1.5rem 0 !important;
  width: 100%;

  & > span {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
  }

  &:focus {
    border-radius: 2px;
  }
`;

const StyledText = styled.span`
  align-items: flex-end;
  justify-content: flex-end;
`;

const StyledLogo = styled.span`
  justify-content: flex-start;

  img {
    margin-left: 0.5rem;
    max-width: 20rem;
    max-height: 2.4rem;
    position: relative;
    top: 0.1rem;
  }
`;

const SoundSystemButton = () => (
  <StyledButtonWrapper to="https://soundsystem.protonradio.com" newTab append={false}>
    <StyledText>
      <strong>Sign In</strong>
      <span>&thinsp;&#8202;to</span>
    </StyledText>
    <StyledLogo>
      <img src={soundsystemLogo} height="30" alt="" />
    </StyledLogo>
  </StyledButtonWrapper>
);

export default SoundSystemButton;
