import React from 'react';

import { ICON_PROP_TYPES } from './config';

const Verified = ({
  color = '#4285f4',
  checkColor = '#fff',
  size = 18,
  className,
  style
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    height={size}
    style={style}
    viewBox="0 0 512 512"
  >
    <title>Verified</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M256 472.153L176.892 512l-41.725-81.129-86.275-16.654 11.596-91.422L0 256l60.488-66.795-11.596-91.422 86.275-16.654L176.892 0 256 39.847 335.108 0l41.725 81.129 86.275 16.654-11.596 91.422L512 256l-60.488 66.795 11.596 91.422-86.275 16.654L335.108 512z"
        fill={color}
      />
      <path
        d="M211.824 284.5L171 243.678l-36 36 40.824 40.824-.063.062 36 36 .063-.062.062.062 36-36-.062-.062L376.324 192l-36-36z"
        fill={checkColor}
      />
    </g>
  </svg>
);

Verified.propTypes = ICON_PROP_TYPES;

export default Verified;
