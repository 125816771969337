import React from 'react';
import { CountryCode, getCountry } from 'countries-and-timezones';
import Tooltip from './Tooltip/Tooltip';

interface BasicMemberData {
  pro_email?: string;
  country?: CountryCode;
}

export const MemberDataSubtitle: React.FC<BasicMemberData> = ({ pro_email, country }) => {
  const separator = pro_email && country ? ' • ' : '';
  const countryName = country ? getCountry(country).name : undefined;
  const maskedEmail = !!pro_email?.includes('*****');
  return (
    <>
      <Tooltip
        enabled={maskedEmail}
        content="To protect user privacy, the # of asterisks (*****) is not the length of actual email address."
      >
        <span data-testid="EntityElement-pro_email-subtitle">{pro_email}</span>
      </Tooltip>
      {separator}
      {countryName}
    </>
  );
};
