import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { USER_PROFILE_TYPES } from 'config/constants';

const PROFILES_BY_TYPE = {
  [USER_PROFILE_TYPES.LABEL]: {
    title: 'Labels',
    iconClass: 'i i-label'
  },
  [USER_PROFILE_TYPES.ARTIST]: {
    title: 'Artists',
    iconClass: 'i i-user'
  }
};

const UserNavProfiles = ({
  user,
  profileType = USER_PROFILE_TYPES.LABEL,
  toggleUserMenu
}) => {
  const profiles = user.profiles.filter(({ type }) => type === profileType);
  if (profiles.length === 0) return null;

  const { title, iconClass } = PROFILES_BY_TYPE[profileType];

  return (
    <div data-testid={`UserNavProfiles-${profileType}`}>
      <div className="flex">
        <h4 className="label--small-caps header--small UserNav__header flex__grow">
          {title}
        </h4>
        {profiles.length > 3 && (
          <Link
            to="/account/pro-access"
            className="label--small-caps header--small UserNav__header flex__shrink"
            data-testid={`UserNavProfiles--show-more--${profileType}`}
          >
            Show All
          </Link>
        )}
      </div>

      <ul className="Nav__section">
        {profiles.slice(0, 3).map(profile => {
          return (
            <li key={profile.id} data-testid={`UserNavProfile--item-${profileType}`}>
              <Link
                onClick={toggleUserMenu}
                to={profile.slug}
                className="nav__list__item"
              >
                <span className={iconClass} aria-hidden="true" />
                {profile.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

UserNavProfiles.TYPES = {
  ...USER_PROFILE_TYPES
};

UserNavProfiles.SELECTORS = {
  LABEL_ITEM: `[data-testid=UserNavProfile--item-${USER_PROFILE_TYPES.LABEL}]`,
  ARTIST_ITEM: `[data-testid=UserNavProfile--item-${USER_PROFILE_TYPES.ARTIST}]`,
  ARTIST_SHOW_MORE: `[data-testid=UserNavProfiles--show-more--${USER_PROFILE_TYPES.ARTIST}]`,
  LABEL_SHOW_MORE: `[data-testid=UserNavProfiles--show-more--${USER_PROFILE_TYPES.LABEL}]`,
  LABEL_CONTAINER: `[data-testid=UserNavProfiles-${USER_PROFILE_TYPES.LABEL}]`,
  ARTIST_CONTAINER: `[data-testid=UserNavProfiles-${USER_PROFILE_TYPES.ARTIST}]`
};

UserNavProfiles.propTypes = {
  profileType: PropTypes.string,
  user: PropTypes.shape({
    profiles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        slug: PropTypes.string,
        name: PropTypes.string
      })
    )
  }),
  toggleUserMenu: PropTypes.func
};

export default UserNavProfiles;
