import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Screen from 'components/Screen';
import { generateSelectorsFromTestIds } from 'helpers/utilities';

import Button, { ButtonGroup } from 'components/Button';
import UserNavMenu from './UserNavMenu';
import { selectUser } from 'redux/selectors';

const UserNav = ({ user, userMenuIsVisible, toggleUserMenu }) => {
  if (user.user_id) {
    return (
      <div className="UserNav">
        <ul className="nav__list">
          <li>
            <span
              onClick={toggleUserMenu}
              role="button"
              tabIndex="0"
              data-testid={UserNav.TEST_IDS.TOGGLE}
              className="menu__trigger nav__list__item hidden-xs hidden-sm"
            >
              <span>{user.username}</span>
            </span>
          </li>
          {userMenuIsVisible && (
            <UserNavMenu user={user} toggleUserMenu={toggleUserMenu} />
          )}
        </ul>
      </div>
    );
  }

  return (
    <div className="UserNav">
      <Screen.LARGE down>
        <ButtonGroup className="UserNav__button-group--mobile" vertical>
          <Button
            onClick={toggleUserMenu}
            color={Button.COLORS.WHITE}
            size={Button.SIZES.MEDIUM}
            to={{
              pathname: '/create-account',
              state: { modal: true }
            }}
          >
            Create Account
          </Button>

          <Button
            data-testid="UserNav-sign-in-button"
            onClick={toggleUserMenu}
            color={Button.COLORS.PRIMARY}
            size={Button.SIZES.MEDIUM}
            to={{
              pathname: '/sign-in',
              state: { modal: true }
            }}
          >
            Sign In
          </Button>
        </ButtonGroup>
      </Screen.LARGE>

      {/* DESKTOP */}
      <Screen.LARGE up>
        <ButtonGroup className="UserNav__button-group--desktop" noMargin fit>
          <Button
            onClick={toggleUserMenu}
            color={Button.COLORS.WHITE}
            size={Button.SIZES.MEDIUM}
            to={{
              pathname: '/create-account',
              state: { modal: true }
            }}
          >
            Create Account
          </Button>

          <Button
            data-testid="UserNav-sign-in-button"
            onClick={toggleUserMenu}
            color={Button.COLORS.WHITE}
            size={Button.SIZES.MEDIUM}
            outline
            to={{
              pathname: '/sign-in',
              state: { modal: true }
            }}
          >
            Sign In
          </Button>
        </ButtonGroup>
      </Screen.LARGE>
    </div>
  );
};

UserNav.propTypes = {
  user: PropTypes.shape(),
  toggleUserMenu: PropTypes.func,
  userMenuIsVisible: PropTypes.bool
};

UserNav.TEST_IDS = {
  TOGGLE: 'UserNav-toggle'
};

UserNav.SELECTORS = generateSelectorsFromTestIds(UserNav.TEST_IDS);

export default connect(state => ({
  user: selectUser(state)
}))(UserNav);
