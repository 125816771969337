import { getReleaseAlgolia, getLabelReleasesAlgolia, getReleaseTracks } from 'api';
import { createAsyncActions } from './utilities';
import { ASYNC_OPERATIONS } from 'config/constants';

export const fetchReleaseActions = createAsyncActions('RELEASE', ASYNC_OPERATIONS.FETCH);

export const fetchLabelReleasesActions = createAsyncActions(
  'LABEL_RELEASES',
  ASYNC_OPERATIONS.FETCH
);

export const fetchReleaseAlgolia =
  ({ id, throwError }) =>
  dispatch => {
    dispatch(fetchReleaseActions.request({ id }));

    return getReleaseAlgolia(id)
      .then(response => {
        dispatch(fetchReleaseActions.success({ id, data: response }));
        return response.data;
      })
      .catch(e => {
        dispatch(fetchReleaseActions.error(e));
        if (throwError) throw e;
      });
  };

export const fetchLabelReleases =
  ({ id, limit, excludeId, throwError = false }) =>
  dispatch => {
    dispatch(fetchLabelReleasesActions.request({ id }));

    return getLabelReleasesAlgolia({ labelId: id, limit, excludeId })
      .then(response => {
        dispatch(fetchLabelReleasesActions.success({ id, data: response }));
        return response.data;
      })
      .catch(e => {
        dispatch(fetchLabelReleasesActions.error(e));
        if (throwError) throw e;
      });
  };

export const fetchReleaseTracksActions = createAsyncActions(
  'RELEASE_TRACKS',
  ASYNC_OPERATIONS.FETCH
);

export const fetchReleaseTracksAction = releaseId => dispatch => {
  dispatch(fetchReleaseTracksActions.request({ id: releaseId }));

  return getReleaseTracks(releaseId)
    .then(data => {
      const sortedTracks = data.sort((a, b) => a.tracklist_order - b.tracklist_order);
      dispatch(fetchReleaseTracksActions.success({ id: releaseId, data: sortedTracks }));
    })
    .catch(error => fetchReleaseTracksActions.error({ id: releaseId, error }));
};
