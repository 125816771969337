import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Modal from 'components/Modal';
import { confirmUserDelete } from 'redux/actions/user';

export default ({ onClose }) => {
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const deleteToken = queryString.get('delete_token');

  if (!deleteToken) {
    return <DeletionErrorModal onClose={onClose} message="No delete_token found." />;
  }

  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);
  const [deletionError, setDeletionError] = useState(null);

  useEffect(() => {
    dispatch(confirmUserDelete(user.user_id, deleteToken))
      .then(() => setDeletionConfirmed(true))
      .catch(e => setDeletionError(e.message));
  });

  if (deletionConfirmed) {
    return <DeletionScheduledModal onClose={onClose} />;
  }

  if (deletionError) {
    return <DeletionErrorModal onClose={onClose} message={deletionError} />;
  }

  return (
    <Modal title="Scheduling Deletion..." onClose={onClose}>
      <Modal.Text>We are confirming your deletion request now.</Modal.Text>
    </Modal>
  );
};

const DeletionScheduledModal = ({ onClose }) => (
  <Modal title="Deletion Scheduled" noBanner onClose={onClose}>
    <Modal.Text>
      Your account has been scheduled for permanent deletion in 2 weeks. If you think
      you&apos;ve made a horrible mistake, please contact us at{' '}
      <a href="mailto:support@protonradio.com">support@protonradio.com</a> immediately.
    </Modal.Text>
  </Modal>
);

const DeletionErrorModal = ({ onClose, message }) => (
  <Modal
    title="Deletion Failed"
    subtitle="We encountered the following error when attempting to delete your account:"
    onClose={onClose}
  >
    <Modal.Text>{message}</Modal.Text>
    <Modal.Text>
      If you believe there is a problem please contact us at{' '}
      <a href="mailto:support@protonradio.com">support@protonradio.com</a>.
    </Modal.Text>
  </Modal>
);
