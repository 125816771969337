import { createAction } from 'redux-act';

export { toggleSearchResultsDisplay } from './toggleSearchResultsDisplay';
export { getSearchResults } from './getSearchResults';

export const setSearchQuery = createAction('SEARCH_QUERY_SET', query => ({
  query
}));

export const setSearchResults = createAction('SEARCH_RESULTS_SET', results => ({
  results
}));

// remove search query and results
export const clearSearch = createAction('SEARCH_CLEAR');

export const setSearchResultsDisplay = createAction(
  'SEARCH_RESULTS_DISPLAY_SET',
  display => ({ display })
);

export const setMobileSearchInputDisplay = createAction(
  'SEARCH_MOBILE_INPUT_DISPLAY_SET',
  display => ({ display })
);

export const handleSearchError = createAction('SEARCH_SET_ERROR');
