import React from 'react';
import styled from 'styled-components';
import Pacman from 'components/Icons/Pacman';

const StyledLoader = styled.div<{ $overlay?: boolean }>`
  align-items: center;
  display: flex;
  background: ${({ $overlay }) => ($overlay ? 'rgba(255, 255, 255, 0.85)' : '#ffffff')};
  backdrop-filter: ${({ $overlay }) => ($overlay ? 'blur(8px)' : 'none')};
  justify-content: center;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: ${({ $overlay }) => ($overlay ? '1500' : '3')};
`;

interface LoaderProps {
  overlay?: boolean;
  testId?: string;
}

interface LoaderComponent extends React.FC<LoaderProps> {
  Icon: typeof Pacman;
}

const Loader: LoaderComponent = ({ overlay = false, testId }) => (
  <StyledLoader $overlay={overlay} data-testid={testId}>
    <Loader.Icon />
  </StyledLoader>
);

Loader.Icon = Pacman;

export default Loader;
