import { css } from 'styled-components';

import { COLORS } from 'config/constants';

// NOTE: Since we don't currently have a non-formik component to style / modify, exporting css variables
// for now...  We should break out the input to it's own component some day.
export const BORDER_COLOR = COLORS.GRAY_LIGHT;
export const TEXT_COLOR = COLORS.GRAY_DARK;
export const DEFAULT_BACKGROUND_COLOR = COLORS.WHITE;
export const DISABLED_BACKGROUND_COLOR = COLORS.GRAY_SUPER_LIGHT;
export const BORDER_RADIUS_REM = '0.3rem';
export const BORDER_THICKNESS_REM = '0.1rem';

// CSS that can by used by non-formik input
export const InputStyling = css`
  background: ${({ disabled }) =>
    disabled ? DISABLED_BACKGROUND_COLOR : DEFAULT_BACKGROUND_COLOR};
  border: ${BORDER_THICKNESS_REM} solid ${BORDER_COLOR};
  border-radius: ${BORDER_RADIUS_REM};
  box-sizing: border-box;
  color: ${TEXT_COLOR};
  font-size: 1.6rem;
  font-weight: 400;
  padding: 1rem 1rem 0.9rem 1rem;
  width: 100%;
  max-height: 37px;
  transition: border-color 0.1s ease-in-out;

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? DISABLED_BACKGROUND_COLOR : DEFAULT_BACKGROUND_COLOR};
    cursor: ${({ disabled }) => disabled && 'not-allowed'};

    border-color: ${COLORS.GRAY_MEDIUM_LIGHT};
  }

  &:read-only {
    background-color: ${DISABLED_BACKGROUND_COLOR};
  }

  &:focus {
    border: 0.1rem solid #d7d7d7;
    outline: none;
    outline: -webkit-focus-ring-color auto 0;
  }

  ::placeholder {
    color: ${COLORS.GRAY_LIGHT};
  }
`;
