import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import useProtonPlayer from 'hooks/useProtonPlayer';

import { StyledButtonTombstone } from './styled';
import TrackPlayTile from './TrackPlayTile';

const StyledButtonWrapper = styled.div`
  &:focus {
    outline: none;
  }
`;

const TrackPlayButton = ({ track, queueAudio, queueIndex }) => {
  const player = useProtonPlayer();

  return (
    <StyledButtonWrapper
      role="button"
      tabIndex="0"
      onClick={() => {
        player.send('playAudio', { queue: queueAudio, index: queueIndex });
      }}
      data-selector="track-play-button"
    >
      <TrackPlayTile track={track} />
    </StyledButtonWrapper>
  );
};

TrackPlayButton.Tombstone = ({ width = 40 }) => <StyledButtonTombstone $width={width} />;

TrackPlayButton.SELECTOR = '[data-selector=track-play-button]';

TrackPlayButton.propTypes = {
  track: PropTypes.shape({
    id: PropTypes.number,
    release: PropTypes.shape({
      cover_art: PropTypes.shape()
    })
  }),
  queueAudio: PropTypes.arrayOf(PropTypes.shape()),
  queueIndex: PropTypes.number,
  queueName: PropTypes.string
};

export default TrackPlayButton;
