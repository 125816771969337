import { call, put, select, takeEvery } from 'redux-saga/effects';

import { updateUserSettings, setUserSettings } from 'redux/actions/user';
import { addAlertMessage } from 'redux/actions/ui';

import { updateUserSettings as updateUserSettingsApi } from 'api/user';

import { selectUserSettings } from 'redux/selectors/user';

import { en } from 'config/constants/localization';

function* updateUserSettingsSaga({ payload, meta = {} }) {
  const {
    displayConfirmation = true,
    onError,
    onSuccess,
    onFinally,
    optimistic = false
  } = meta;

  let prevState;

  try {
    if (optimistic) {
      prevState = yield select(selectUserSettings);
      yield put(setUserSettings([payload]));
    }

    const { data } = yield call(updateUserSettingsApi, payload);
    if (!optimistic) yield put(setUserSettings(data));

    if (displayConfirmation)
      yield put(addAlertMessage('Your account has been successfully updated!'));
    if (onSuccess) onSuccess();
  } catch (e) {
    // If updated optimistically and there's an error then
    // rollback to the locally stored previous state.
    if (optimistic) yield put(setUserSettings(prevState));

    if (onError) onError(e);
    yield put(addAlertMessage(en.errors.generic));
  } finally {
    if (onFinally) onFinally();
  }
}

export function* watchUpdateUserSettings() {
  // TODO (Rob)
  // Refactor to support prevState optimistic setting + rollbacks so we dont
  // have to compromise between takeLatest and takeEvery for various components
  yield takeEvery(updateUserSettings?.call().type, updateUserSettingsSaga);
}
