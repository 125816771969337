import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { ENTITY_TYPES } from 'config/constants';

import Avatar, {
  ArtistAvatar,
  LabelAvatar,
  ReleaseAvatar,
  ShowAvatar
} from 'components/Avatar';
import { getEntityPath } from './helpers';

const ImageLink = styled(Link)`
  display: block;
`;

// EntityGrid passes the `fluid = true` prop as we want the images to dynamically
// adapt to the grid width without any opinion. Setting any min-width breaks
// CSS Grid
const ImageContainer = styled.div`
  min-width: ${({ $fluid, $width }) => !$fluid && `${$width / 10}rem`};
  width: ${({ $fluid, $width }) => ($fluid ? '100%' : `${$width / 10}rem`)};
`;

export const getImageAvatar = ({ type, data, imageUrl, loading, lazy }) => {
  const commonProps = {
    size: Avatar.SIZES.FULL,
    loading,
    lazy,
    altTag: 'Artist Image'
  };

  switch (type) {
    case ENTITY_TYPES.ARTIST:
      return (
        <ArtistAvatar
          imageUrl={imageUrl || data.image_url}
          name={data.name}
          {...commonProps}
        />
      );

    case ENTITY_TYPES.DSP_ARTIST_PROFILE:
      return (
        <ArtistAvatar
          imageUrl={data.avatar_url}
          // NOTE: SoundCloud uses "username"
          name={data.username || data.value}
          {...commonProps}
        />
      );

    case ENTITY_TYPES.LABEL:
      return (
        <LabelAvatar
          // If passing a label from release index, image url is in data.logo...
          imageUrl={imageUrl || data.image || data.logo?.small?.url}
          name={data.name}
          {...commonProps}
        />
      );

    case ENTITY_TYPES.RELEASE:
    case ENTITY_TYPES.TRACK:
      return (
        <ReleaseAvatar
          imageUrl={imageUrl || data?.cover_art?.small?.url}
          name={data.name}
          {...commonProps}
        />
      );

    case ENTITY_TYPES.SHOW:
      return (
        <ShowAvatar
          imageUrl={imageUrl || data.show_image}
          name={data.name}
          {...commonProps}
        />
      );

    default:
      return null;
  }
};

/*
 * [EntityImage]
 * - returns the appropriate avatar image for a given entity type
 *
 * @param {string} type
 * @param {object} data
 * @param {bool} [enableLinks] - image will link to entity if path found in data attribute by default
 * @returns {object}
 */

const EntityImage = ({
  type,
  imageUrl,
  path,
  data,
  enableLinks,
  fluid,
  size = {},
  loading,
  lazy
}) => {
  if (loading) return <EntityImage.Tombstone type={type} size={size} $fluid={fluid} />;

  if (type === ENTITY_TYPES.USER) return null;

  const ImageAvatar = getImageAvatar({ type, data, lazy, imageUrl });
  const entityPath = path || getEntityPath(type, data);

  return (
    <ImageContainer
      $width={size.minWidth}
      $fluid={fluid}
      data-testid="EntityImage-container"
    >
      {entityPath && enableLinks ? (
        <ImageLink to={entityPath} data-testid="EntityImage-link">
          {ImageAvatar}
        </ImageLink>
      ) : (
        ImageAvatar
      )}
    </ImageContainer>
  );
};

EntityImage.propTypes = {
  data: PropTypes.shape(),
  enableLinks: PropTypes.bool,
  fluid: PropTypes.bool,
  lazy: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.shape(),
  type: PropTypes.string
};

EntityImage.Tombstone = ({ fluid, type, size }) => {
  const ImageAvatar = getImageAvatar({ type, data: {}, loading: true });

  return (
    <ImageContainer $width={size.minWidth} $fluid={fluid}>
      {ImageAvatar}
    </ImageContainer>
  );
};

EntityImage.Tombstone.propTypes = {
  fluid: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.shape()
};

EntityImage.SELECTORS = {
  CONTAINER: '[data-testid=EntityImage-container]',
  LINK: '[data-testid=EntityImage-link]'
};

export default EntityImage;
