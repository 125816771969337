import { useDispatch, useSelector } from 'react-redux';

import { downloadTrack as downloadTrackAction } from 'redux/actions/tracks';
import useCurrentUser from 'hooks/useCurrentUser';
import { trackDownloadConfig } from 'helpers/tracks';

// This is meant to be external to the component as the downloading state should
// be shared between all instances of this hook.
let isDownloading = false;

export default function useAudioDownloader() {
  const { user: currentUser } = useCurrentUser();

  // Ideally we will phase out this dependence upon Redux by building out a full
  // udio download system as part of this hook.
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const startDownload = (track, { format, onDownloadSuccess = () => {} }) => {
    if (isDownloading) return;
    isDownloading = true;

    dispatch(
      downloadTrackAction.call(
        trackDownloadConfig(currentUser, { track, user, format }),
        {
          onFinally: () => {
            isDownloading = false;
            if (onDownloadSuccess) onDownloadSuccess();
          }
        }
      )
    );
  };

  return { startDownload, isDownloading };
}
