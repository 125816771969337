import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { parseAlgoliaHit, parseHighlightedAttribute } from './highlightHelpers';

const HighlightedText = styled.mark`
  background-color: #ff7134;
  border-radius: 0.2rem;
  padding: 0 0.1rem;
  font-style: normal;
  color: #000;
  font-weight: bold;

  /**
   * The code below is for accessibility. 
   * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/mark#Accessibility_concerns
  */

  &::before,
  &::after {
    clip-path: inset(100%);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  &::before {
    content: ' [highlight start] ';
  }

  &::after {
    content: ' [highlight end] ';
  }
`;

const Highlighter = ({ highlightArray }) =>
  highlightArray.map((part, index) => {
    const key = `${part.value}-${index}`;

    if (part.isHighlighted) {
      return <HighlightedText key={key}>{part.value}</HighlightedText>;
    }

    return <span key={key}>{part.value}</span>;
  });

/**
 * [Highlight] - Returns the passed attribute with highlighted strings. For use with Algolia results.
 *
 * @param {object} hit - result returned from algolia. Must include the '_highlightResult' attribute
 * @param {string} attribute - attribute that we are highlighting within the hit. This attribute should also
 * be included in _highlightResult, assuming it is a searchable attribute within algolia. Use dot notation for
 * nested fields. If highlighting nested attributes within an array, use a $ to denote the array (see examples)
 *
 * attribute examples: 'title', 'release.name', 'artists.$.name'
 *
 * NOTE: only supports one level of searching attributes within an array
 *
 * @param {string | number} [attributeValue] - if the 'attribute' is within an array, you need to pass the value of
 * array item you are highlighting. Checkout the 'EXAMPLE_HIT' below to see what the structure for _highlightResult of
 * artist names within the track objects looks like.
 *
 * An attributeValue example for highlighting an artist name could be "Kotu (UK)"
 *
 * @returns {node[]} - array of react components. Highlighted (matched) text will be wrapped in <mark></mark> tags, and
 * all other text will be wrapped in <span></span> tags
 */

// const EXAMPLE_HIT = {
//   title: 'Endless Way',
//   artists: [
//     { id: 1123, name: "Erdi Irmak", roles: [...], ... },
//     { id: 5234, name: "Kotu (UK)", roles: [...], ... },
//   ],
//    ...otherTrackData,
//   _highlightResult: {
//     artists: [
//       { name: { value: 'Erdi Irmak', matchLevel: 'none' } },
//       { name: { value: '<em>Kotu</em> (UK)', matchLevel: 'none' } }
//     ],
//     title: {
//       value: 'Endless Way',
//       matchLevel: 'none'
//     }
//   }
// };

const Highlight = ({
  hit,
  attribute,
  attributeValue,
  preTag = '<em>',
  postTag = '</em>'
}) => {
  if (!hit._highlightResult) {
    console.warn('No highlight field provided');
  }

  if (attribute.includes('.$.')) {
    if (!attributeValue) {
      console.warn(
        "'attributeValue' value must be provided to find highlight result within collection attributes"
      );
      return null;
    }

    const attributes = attribute.split('.$.');
    const highlightResults = _.get(hit._highlightResult, attributes[0]) || [];
    const highlight = highlightResults
      // we're only interested in the highlight results for the attributes after '$'
      .map(result => _.get(result, attributes[1]))
      // find the specific highlight result that matches the passed attributeValue
      .find(({ value }) => {
        // In order to compare highlight result with attributeValue, we need to ensure
        // any highlight tags are removed
        const regex = new RegExp(`${preTag}*|${postTag}`, 'g');
        return value.replace(regex, '') === attributeValue;
      });

    // If for some reason there wasn't a highlight result for the attributeValue provides,
    // just return the attributeValue
    if (!highlight) return attributeValue;

    const highlightArray = parseHighlightedAttribute({
      preTag,
      postTag,
      highlightedValue: highlight.value
    });

    return <Highlighter highlightArray={highlightArray} />;
  }

  const value = _.get(hit, attribute);
  if (!value && value !== null) {
    console.warn(
      `The attribute ${attribute} was not found in the hit passed to Highlight. ` +
        'If this attribute is an array, use the syntax {array_attribute}.$.{attribute}'
    );
    return null;
  }

  const highlight = _.get(hit._highlightResult, attribute);

  if (!highlight || highlight.matchLevel === 'none') return value;

  const highlightArray = parseAlgoliaHit({
    attribute,
    hit,
    preTag,
    postTag
  });

  return <Highlighter highlightArray={highlightArray} />;
};

Highlight.propTypes = {
  hit: PropTypes.shape({
    _highlightResult: PropTypes.shape({
      matchLevel: PropTypes.string,
      value: PropTypes.string
    }).isRequired
  }).isRequired,
  attribute: PropTypes.string.isRequired,
  attributeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  preTag: PropTypes.string,
  postTag: PropTypes.string
};

export default Highlight;
