import React from 'react';

import { DEFAULT_ICON_COLOR, ICON_PROP_TYPES } from './config';

const Calendar = ({ color = DEFAULT_ICON_COLOR, size = 18, className, style }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 448 448"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
    style={style}
  >
    <title>Calendar</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="calendar-outline" transform="translate(16.000000, 16.000000)">
        <rect
          id="Rectangle"
          stroke={color}
          strokeWidth="32"
          strokeLinejoin="round"
          x="0"
          y="32"
          width="416"
          height="384"
          rx="48"
        ></rect>
        <path
          d="M349.82,32 L66.18,32 C29.69,32 0,62.15 0,99.2 L0,160 L16,160 C16,144 32,128 48,128 L368,128 C384,128 400,144 400,160 L416,160 L416,99.2 C416,62.15 386.31,32 349.82,32 Z"
          id="Path"
          fill={color}
          fillRule="nonzero"
        ></path>
        <circle
          id="Oval"
          fill={color}
          fillRule="nonzero"
          cx="248"
          cy="184"
          r="24"
        ></circle>
        <circle
          id="Oval"
          fill={color}
          fillRule="nonzero"
          cx="328"
          cy="184"
          r="24"
        ></circle>
        <circle
          id="Oval"
          fill={color}
          fillRule="nonzero"
          cx="248"
          cy="264"
          r="24"
        ></circle>
        <circle
          id="Oval"
          fill={color}
          fillRule="nonzero"
          cx="328"
          cy="264"
          r="24"
        ></circle>
        <circle
          id="Oval"
          fill={color}
          fillRule="nonzero"
          cx="88"
          cy="264"
          r="24"
        ></circle>
        <circle
          id="Oval"
          fill={color}
          fillRule="nonzero"
          cx="168"
          cy="264"
          r="24"
        ></circle>
        <circle
          id="Oval"
          fill={color}
          fillRule="nonzero"
          cx="88"
          cy="344"
          r="24"
        ></circle>
        <circle
          id="Oval"
          fill={color}
          fillRule="nonzero"
          cx="168"
          cy="344"
          r="24"
        ></circle>
        <circle
          id="Oval"
          fill={color}
          fillRule="nonzero"
          cx="248"
          cy="344"
          r="24"
        ></circle>
        <line
          x1="80"
          y1="0"
          x2="80"
          y2="32"
          id="Path"
          stroke={color}
          strokeWidth="32"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
        <line
          x1="336"
          y1="0"
          x2="336"
          y2="32"
          id="Path"
          stroke={color}
          strokeWidth="32"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
      </g>
    </g>
  </svg>
);

Calendar.propTypes = ICON_PROP_TYPES;

export default Calendar;
