import React, { useEffect, useState } from 'react';

import { EVENTS, ProtonEventEmitter } from 'config/events';
import SendMagicLinkModal from './SendMagicLinkModal';

/**
 * [MFARequiredModal]
 * Currently there are 2 scenarios when a user might be required to
 * verify their identity:
 *
 * 1) When a user is trying to perform a sensitive action, they may be required to
 * verify their identity.
 * 2) When a user is trying to login and their account has been flagged as needing
 * verification.
 */

const MFARequiredModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [magicLinkToken, setMagicLinkToken] = useState<string | null>(null);
  const [expiredToken, setExpiredToken] = useState(false);

  useEffect(() => {
    const handleError = (requestToken: string, invalidToken?: boolean) => {
      setMagicLinkToken(requestToken);
      setExpiredToken(!!invalidToken);
      setIsOpen(true);
    };

    ProtonEventEmitter.addListener(EVENTS.MFA_REQUIRED, handleError);

    return () => {
      ProtonEventEmitter.removeListener(EVENTS.MFA_REQUIRED, handleError);
    };
  }, []);

  if (!isOpen) return null;

  return (
    <SendMagicLinkModal
      requestMagicLinkToken={magicLinkToken!}
      expiredToken={expiredToken}
      close={() => setIsOpen(false)}
    />
  );
};

export default MFARequiredModal;
