import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const ArtistSearchResult = ({
  slug = '',
  image_url,
  mixes_count,
  tracks_count,
  matched,
  hideSearchResults
}) => (
  <Link to={slug} onClick={hideSearchResults}>
    <div
      className="UniversalSearchResultRow"
      data-testid={ArtistSearchResult.TEST_IDS.ROW}
    >
      <div className="UniversalSearchResultRow__icon-circle">
        <img src={image_url} alt="Artist" />
      </div>
      <div className="UniversalSearchResultRow__info">
        <div>
          <div dangerouslySetInnerHTML={{ __html: matched.name }} />
          <div className="ArtistSearchResult__mix-count">
            {`${mixes_count} Mixes | ${tracks_count} Tracks`}
          </div>
        </div>
      </div>
    </div>
  </Link>
);

ArtistSearchResult.TEST_IDS = {
  ROW: 'artist-search-result-row'
};

ArtistSearchResult.propTypes = {
  slug: PropTypes.string,
  image_url: PropTypes.string,
  mixes_count: PropTypes.number,
  tracks_count: PropTypes.number,
  matched: PropTypes.shape({
    // artist name
    name: PropTypes.string
  }),
  hideSearchResults: PropTypes.func
};

export default ArtistSearchResult;
