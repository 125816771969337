import React from 'react';
import styled from 'styled-components';

import ActionMenu, { ActionMenuToggleState } from 'components/ActionMenu/ActionMenu';
import ActionMenuList from 'components/ActionMenu/ActionMenuList';
import ActionMenuItem from 'components/ActionMenu/ActionMenuItem';
import { COLORS } from 'config/constants';
import Icon from 'components/Icon';
import PromoArtistPickerButton from 'components/PromoArtistPickerButton';
import { Artist } from 'gql/graphql';
import Flex from 'components/Flex';

const ArtistImage = styled.img<{ active: boolean }>`
  border: ${({ active }) => (active ? `solid 1px ${COLORS.PRIMARY}` : 'none')};
  border-radius: 99999rem;
  height: 3.4rem;
  margin-right: 0.5rem;
  padding: 0.2rem;
  width: 3.4rem;
`;

/**
 * [ArtistPickerActionMenu] - Menu that shows list of artists as action items.
 * Supports highlighting currently selected artist.
 *
 * Ex: used for choosing artists reacting to a track if user manages multiple artist that have
 * promo access to that track
 */

interface ArtistPickerActionMenuProps extends ActionMenuToggleState {
  artists: Artist[];
  onArtistSelect: (artist: Artist) => void;
  selectedArtistId: string;
  disabled: boolean;
}

const ArtistPickerActionMenu = ({
  artists,
  onArtistSelect,
  selectedArtistId,
  disabled,
  isOpen,
  close,
  closeOnNavigation
}: ArtistPickerActionMenuProps) => (
  <ActionMenu isOpen={isOpen} close={close} closeOnNavigation={closeOnNavigation}>
    <ActionMenuList closeMenu={close}>
      {artists.map(artist => {
        const { id, image, name } = artist;
        const active = id === selectedArtistId;

        return (
          <ActionMenuItem
            key={id}
            onClick={() => onArtistSelect(artist)}
            disabled={disabled}
            data-testid={PromoArtistPickerButton.TEST_IDS.getTestIdForArtistOption(id)}
          >
            <ArtistImage src={image?.url ?? ''} alt="artist avatar" active={active} />{' '}
            <Flex grow={1} shrink={1} justify="space-between">
              <div>{name}</div>
              {active && (
                <Icon
                  type={Icon.TYPES.CHECK_MARK}
                  color={Icon.COLORS.PRIMARY}
                  width={15}
                />
              )}
            </Flex>
          </ActionMenuItem>
        );
      })}
    </ActionMenuList>
  </ActionMenu>
);

export default ArtistPickerActionMenu;
