import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { BREAKPOINTS, COLORS } from 'config/constants';

import NoDataDisplay from 'components/NoDataDisplay';
import TextHeader from 'components/TextHeader';
import FormGroupRow from './FormGroupRow';
import FormGroupRowFormik from './FormGroupRow.Formik';
import { StyledInputGroup } from '../InputGroup/styled';

export const FORM_ROW_MAXWIDTH = 480;

const StyledFormGroup = styled.div`
  border-radius: 0.4rem;
  margin: 3rem 0;
  position: relative;

  @media screen and (max-width: ${BREAKPOINTS.SM}px) {
    margin: 2rem 0;
    &:first-child {
      margin: 1rem 0 2rem 0;
    }
  }

  ${StyledInputGroup} {
    @media screen and (min-width: ${BREAKPOINTS.LG}px) {
      max-width: ${FORM_ROW_MAXWIDTH}px;
    }
  }
`;

const StyledFormGroupHeader = styled.div`
  padding: 0.8rem 0;

  a {
    color: ${COLORS.PRIMARY};
  }
`;

const StyledFormGroupList = styled.ul`
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 0.8rem;

  > li:first-child {
    border-top: none;
  }
`;

const Divider = styled.div`
  height: 1px;
  background: #ddd;
  margin: 1rem -1.2rem;
`;

/**
 * [FormGroup] - Element for wrapping sections of forms like account settings.
 */

type FormGroupProps = {
  children: React.ReactNode;
  className?: string;
};

const FormGroup = ({ children, className }: FormGroupProps) => (
  <StyledFormGroup className={className}>{children}</StyledFormGroup>
);

type FormGroupHeaderProps = {
  title: string;
  subtitle?: string;
};

FormGroup.Header = ({ title, subtitle }: FormGroupHeaderProps) => (
  <StyledFormGroupHeader>
    <TextHeader
      type={TextHeader.TYPES.LARGE}
      weight={TextHeader.WEIGHTS.HEAVY}
      className="mb-0"
    >
      {title}
    </TextHeader>

    {subtitle && (
      <TextHeader
        type={TextHeader.TYPES.SMALL}
        weight={TextHeader.WEIGHTS.NORMAL}
        color={TextHeader.COLORS.SECONDARY}
        className="mb-1"
      >
        {subtitle}
      </TextHeader>
    )}
  </StyledFormGroupHeader>
);

/**
 * List items can be passed explicitly as `children` or created by passing a map
 * function to the `listItemMap` prop which will be applied to the `data` array.
 */
type FormGroupListProps<T = unknown> = {
  children?: ReactNode;
  className?: string;
  data?: T[];
  noDataMessage?: string;
  listItemMap?: (item: T) => ReactNode;
};

FormGroup.List = (props: FormGroupListProps) => {
  const { children, className, data, noDataMessage, listItemMap } = props;
  if (data?.length === 0) {
    return noDataMessage ? <NoDataDisplay className="mt-0" text={noDataMessage} /> : null;
  }

  return (
    <StyledFormGroupList className={className}>
      {listItemMap && data ? data.map(listItemMap) : children}
    </StyledFormGroupList>
  );
};

FormGroup.Row = FormGroupRow;

FormGroup.FormikRow = FormGroupRowFormik;

// Row Divider
FormGroup.Divider = Divider;

export default FormGroup;
