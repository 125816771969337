import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * [InfiniteScroller]
 *
 * @param {bool} hasMore
 * @param {func} fetchMoreData
 * @param {number} [fetchOffsetPixels] number of pixels from screen bottom to start scrolling
 * @param {bool} [disabled] - if true, fetchMoreData will not be called even if it would have otherwise been triggered
 * @param {node} [loader] - custom loading element to display while loading
 * @returns {object}
 */

class InfiniteScroller extends Component {
  state = {
    loading: false
  };

  // eslint-disable-next-line react/sort-comp
  static DEFAULT_FETCH_OFFSET = 200;

  componentDidMount() {
    this._rootEl = document.getElementById('root');
    window.addEventListener('scroll', this._onScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._onScroll, false);
  }

  _onScroll = () => {
    const { loading } = this.state;
    const {
      hasMore,
      disabled,
      fetchOffsetPixels = InfiniteScroller.DEFAULT_FETCH_OFFSET
    } = this.props;

    const shouldLoadNextPage =
      !loading &&
      hasMore &&
      !disabled &&
      window.innerHeight + window.scrollY >=
        this._rootEl.offsetHeight - fetchOffsetPixels;

    if (!shouldLoadNextPage) return;

    this.setState({ loading: true });
    this.props.fetchMoreData().finally(() => {
      this.setState({ loading: false });
    });
  };

  _renderLoader = () => {
    if (this.state.loading && this.props.loader) {
      return this.props.loader;
    }
    return null;
  };

  render() {
    const { children } = this.props;

    return (
      <Fragment>
        {typeof children === 'function' ? children() : children}
        {this._renderLoader()}
      </Fragment>
    );
  }
}

InfiniteScroller.propTypes = {
  children: PropTypes.node,
  loader: PropTypes.node,
  hasMore: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  fetchMoreData: PropTypes.func.isRequired, // should be a promise
  fetchOffsetPixels: PropTypes.number
};

export default InfiniteScroller;
