import { createSelector } from '@reduxjs/toolkit';

import { isUserArtistMemberOnRelease } from 'helpers/user';
import { isMixDownloadableForUser } from 'helpers/mixes';
import { isTrackDownloadable } from 'helpers/tracks';
import { AUDIO_TYPES } from 'config/constants';

import {
  selectReleaseTrackIdsDataByReleaseId,
  selectReleaseTrackIdsByReleaseId
} from './releases';
import { selectAllTracks, selectTrackById } from './tracks';
import { selectUser, selectUserLabelProfiles } from './user';
import { selectIsTrackOnPromo } from './promoReleases';
import { selectAllLabels } from './labels';

/**
 * [makeSelectCanUserDownloadMix]
 *
 * TODO: Once all mixes are stored in redux, convert to pass mix id
 * @param {object} mix
 * @returns {bool}
 */

export const makeSelectCanUserDownloadMix = () =>
  createSelector([(__, { mix }) => mix, selectUser], (mix, user) =>
    isMixDownloadableForUser(mix, user)
  );

/**
 * [makeSelectReleaseTracksDataByReleaseId]
 *
 * @param {string} releaseId
 * @returns {object[]} release tracks data
 */

export const makeSelectReleaseTracksDataByReleaseId = () =>
  createSelector(
    [selectReleaseTrackIdsDataByReleaseId, selectAllTracks],
    (releaseTrackIds, tracksById) =>
      releaseTrackIds.map(trackId =>
        selectTrackById({ tracks: { byId: tracksById } }, trackId)
      )
  );

/**
 * [makeSelectReleaseTracksByReleaseId]
 *
 * @param {string} releaseId
 * @returns {object} release tracks state (w/ track data)
 * {
 *   data: [{...}],
 *   __isFetching: false,
 *   __lastFetched: 1231231231
 * }
 */

export const makeSelectReleaseTracksByReleaseId = () => {
  const selectReleaseTracksDataByReleaseId = makeSelectReleaseTracksDataByReleaseId();

  return createSelector(
    [selectReleaseTracksDataByReleaseId, selectReleaseTrackIdsByReleaseId],
    (releaseTracks, releaseTrackState) => ({
      ...releaseTrackState,
      data: releaseTracks
    })
  );
};

/**
 * [makeSelectIsUserArtistMemberOnRelease]
 *
 * @param {number} releaseId
 * @returns {boolean}
 */

export const makeSelectIsUserArtistMemberOnRelease = () => {
  const selectReleaseTracksDataByReleaseId = makeSelectReleaseTracksDataByReleaseId();

  return createSelector(
    [selectUser, selectReleaseTracksDataByReleaseId],
    (user, releaseTracks) => isUserArtistMemberOnRelease(user, releaseTracks)
  );
};

/**
 * [selectIsAudioOnPreview] - used to determine if audio object would be in preview mode.
 *
 * @param {object} audio
 * @returns {bool}
 */
export const selectIsAudioOnPreview = createSelector(
  [selectIsTrackOnPromo, (__, { audio }) => audio, selectUser],
  (isPromo, audio, user) => {
    if (audio.__audioType === AUDIO_TYPES.TRACK) {
      return !isTrackDownloadable(isPromo, audio, user);
    }
    // currently there is no preview mode for anything but tracks
    return false;
  }
);

/**
 * [selectUsersManagedLabelData] - returs the label data (labels.byId reducer) for each label the
 * current user manages
 *
 * @returns {object[]}
 */

export const selectUsersManagedLabelData = createSelector(
  [selectUserLabelProfiles, selectAllLabels],
  (labelProfiles, labelsById) =>
    labelProfiles.reduce((accum, { id }) => {
      const label = !!labelsById[id]?.id && labelsById[id];
      return label ? [...accum, label] : accum;
    }, [])
);
