import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { updateTrackReaction as updateTrackReactionAction } from 'redux/actions/promoReleases';
import { displayAlertError, generateSelectorsFromTestIds } from 'helpers';
import { selectUserArtistProfiles } from 'redux/selectors/user';

import { ArtistPickerActionMenu } from 'components/ActionMenu/ActionMenus';
import { ArtistAvatar } from 'components/Avatar';
import DropdownMenu from 'components/DropdownMenu';
import Icon from 'components/Icon';
import MenuToggler from 'components/MenuToggler';
import TextHeader from 'components/TextHeader';

import { COLORS, DIRECTIONS } from 'config/constants';

import {
  selectPromoTrackReaction,
  selectPromoTrackReactionArtistId,
  selectPromoTrackArtistIds
} from 'redux/selectors/promoReleases';

const ArtistDropdownWrapper = styled.div`
  padding: 0.5rem 0.5rem 0 0.5rem;
`;
const ArtistDropdownTitle = styled.div`
  padding: 1rem 1rem 0 1rem;
`;

const ArtistDropdownList = styled.ul`
  padding: 0 0 1rem 0;
`;

const ArtistName = styled.div`
  font-size: 1.6rem;
`;

const ArtistPickerAvatar = styled.div`
  background: none;
  margin-right: 1rem;
  padding: 0;
  position: relative;
`;

const MultipleArtistIndicator = styled.div`
  align-items: center;
  background: ${COLORS.GRAY_MEDIUM};
  border: 1px solid #fff;
  border-radius: 5rem;
  bottom: -0.2rem;
  display: flex;
  height: 1.4rem;
  justify-content: center;
  right: -0.2rem;
  position: absolute;
  width: 1.4rem;
`;

const ActiveArtistIndicator = styled.div`
  background: ${COLORS.PRIMARY};
  border-radius: 0 0.2rem 0.2rem 0;
  height: 4rem;
  left: 0;
  position: absolute;
  width: 0.4rem;
`;

/**
 * [PromoArtistPickerButton] - button tuned specifically for rendering the artist picker action menu / dropdown for
 *  promo tracks where a user has multiple artists with access to a given promo track
 *
 * For mobile screens, triggers action menu, otherwise dropdown is displayed
 *
 * @param {object} track
 *
 * @param {string} [renderDirection=DOWN]
 * @param {bool} [fixed] - passed to artist picker popover. Renders popover with fixed positioning
 */

const PromoArtistPickerButton = ({
  track,
  renderDirection = DropdownMenu.DIRECTIONS.DOWN,
  fixed,
  multipleArtists,

  // redux connect
  artistId,
  artistIds,
  userArtists,
  updateTrackReaction,
  onOpen = () => null,
  onClose = () => null
}) => {
  const [selectingArtistId, setSelectingArtistId] = useState(null);

  // don't render if not multiple artists with access to this promo release

  const singleArtist = artistIds.length <= 1;

  const artists = userArtists
    .filter(artist => artistIds.includes(artist.id))
    .map(artist => ({
      ...artist,
      imageUrl: artist.image_url
    }));

  // Artist display logic in order:
  // 1) if selecting a new artist, display that until response returned.
  // 2) if this track has a reaction from a specific artist, show that artist
  // 3) default to showing the first artist able to rate this track
  const selectedArtistId = selectingArtistId || artistId || artistIds[0];
  const selectedArtist = artists.find(artist => artist.id === selectedArtistId);

  const onSelect = _artistId => {
    setSelectingArtistId(_artistId);

    updateTrackReaction(
      { artistId: _artistId, track },
      {
        onError: displayAlertError,
        onFinally: () => setSelectingArtistId(null)
      }
    );
  };

  return (
    <MenuToggler
      fixedPopover={fixed}
      onOpen={onOpen}
      onClose={onClose}
      renderDirection={renderDirection}
      renderToggle={({ toggle }) => (
        <ArtistPickerAvatar
          as={multipleArtists && 'button'}
          disabled={singleArtist || !!selectingArtistId}
          data-testid={PromoArtistPickerButton.TEST_IDS.BUTTON}
          data-value={`PickerButton-value:${artistId}`}
          onClick={multipleArtists ? toggle : null}
        >
          <ArtistAvatar
            imageUrl={selectedArtist.image_url}
            size={ArtistAvatar.SIZES.MEDIUM}
            lazy={false}
          />

          {multipleArtists && (
            <MultipleArtistIndicator>
              <Icon
                width={8}
                color={Icon.COLORS.WHITE}
                type={Icon.TYPES.ARROW_DOWN_CIRCLE}
              />
            </MultipleArtistIndicator>
          )}
        </ArtistPickerAvatar>
      )}
      renderActionMenu={({ isOpen, close }) => (
        <ArtistPickerActionMenu
          isOpen={isOpen}
          close={close}
          artists={artists}
          selectedArtistId={selectedArtistId}
          disabled={singleArtist || !!selectingArtistId}
          onArtistSelect={({ id }) => {
            if (isOpen) setTimeout(() => close(), 300);
            return onSelect(id, close);
          }}
        />
      )}
      renderPopoverContent={({ isOpen, close }) => (
        <ArtistDropdownWrapper data-testid={PromoArtistPickerButton.TEST_IDS.DROPDOWN}>
          <ArtistDropdownTitle>
            <TextHeader type={TextHeader.TYPES.MEDIUM}>React as</TextHeader>
          </ArtistDropdownTitle>

          <ArtistDropdownList>
            {artists.map(artist => {
              // Note (Rob) - Leaving here as reference for when I hopefully figure out
              // a better way to indicate the active row with artist avatars + names.
              const isSelected = artist.id === selectedArtistId;
              return (
                <DropdownMenu.Item
                  onClick={() => {
                    if (isOpen) setTimeout(() => close(), 300);
                    onSelect(artist.id);
                  }}
                  testId={PromoArtistPickerButton.TEST_IDS.getTestIdForArtistOption(
                    artist.id
                  )}
                  key={`promoartistbutton:${artist.id}`}
                >
                  {isSelected && <ActiveArtistIndicator />}
                  <ArtistAvatar
                    imageUrl={artist.image_url}
                    size={ArtistAvatar.SIZES.MEDIUM}
                    className="mr-1"
                    lazy={false}
                  />

                  <ArtistName>{artist.name}</ArtistName>
                </DropdownMenu.Item>
              );
            })}
          </ArtistDropdownList>
        </ArtistDropdownWrapper>
      )}
    />
  );
};

PromoArtistPickerButton.DIRECTIONS = DIRECTIONS;

PromoArtistPickerButton.TEST_IDS = {
  BUTTON: 'PickerButton',
  DROPDOWN: 'PromoArtistPicker-dropdown',
  getTestIdForArtistOption: artistId => `ArtistPicker-option--${artistId}`
};

PromoArtistPickerButton.SELECTORS = {
  ...generateSelectorsFromTestIds(PromoArtistPickerButton.TEST_IDS),
  confirmSelectedArtist: artistId => `[data-value="PickerButton-value:${artistId}"]`
};

PromoArtistPickerButton.propTypes = {
  track: PropTypes.shape({
    id: PropTypes.number
  }),
  multipleArtists: PropTypes.bool,
  renderDirection: PropTypes.string,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,

  // redux connect
  artistIds: PropTypes.arrayOf(PropTypes.number), // user artist ids that can rate this track
  artistId: PropTypes.number,
  userArtists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image_url: PropTypes.string
    })
  ),
  updateTrackReaction: PropTypes.func
};

export default connect(
  (state, { track }) => ({
    userArtists: selectUserArtistProfiles(state),
    reaction: selectPromoTrackReaction(state, track.id),
    artistId: selectPromoTrackReactionArtistId(state, track.release.id, track.id),
    artistIds: selectPromoTrackArtistIds(state, { releaseId: track.release.id })
  }),
  {
    updateTrackReaction: updateTrackReactionAction.call
  }
)(PromoArtistPickerButton);
