// import PropTypes from 'prop-types';
import React from 'react';
import PlayerButton from 'components/PlayerButton';
import { AUDIO_TYPES } from 'config/constants';

// Because "Column" component from react virtualized has to be a DIRECT child of Table, we just generate the common props
export const getTracklistOrderColumnProps = ({
  showOrderPlayButton,
  queueAudio,
  queueName,
  playerId,
  playerAudioType
}) => ({
  width: 20,
  minWidth: 20,
  label: '#',
  dataKey: 'tracklist_order',
  className: 'tracklist-order',
  cellRenderer: ({ cellData, rowData: track }) => {
    const playerProps = {
      queueAudio,
      queueName,
      queueIndex: track.__queueIndex,
      classes: 'PlayerButton--mini'
    };

    const trackIsPlaying = playerId === track.id && playerAudioType === AUDIO_TYPES.TRACK;

    // if track is playing, always show player button, else show tracklist order (unless hovering)
    if (showOrderPlayButton && trackIsPlaying) {
      return <PlayerButton {...playerProps} />;
    }

    // NOTE: we show/hide Play button vs tracklist_order on hover via css for performance
    return (
      <>
        {showOrderPlayButton && (
          <div className="play-button--hover">
            <PlayerButton {...playerProps} />
          </div>
        )}
        <div className="cell__value" data-testid="track-play-hover-trigger">
          {cellData}
        </div>
      </>
    );
  }
});
