const trackInitialState = {
  id: null,
  album_only: false,
  artists: [],
  audio: {
    low: {},
    medium: {},
    high: {}
  },
  compiled: false,
  no_compile: false,
  duration_seconds: null,
  explicit: false,
  genre: {},
  hidden: false,
  hidden_from_dj_stores: false,
  label: {},
  preorder_only: false,
  release: {
    coverArt: {
      original: {},
      thumb: {},
      small: {},
      medium: {},
      large: {}
    }
  },
  slug: '',
  stems: false,
  title: '',
  track_name: '',
  tracklist_order: null,
  version: ''
};

export default trackInitialState;
