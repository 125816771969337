import { createAction } from 'redux-act';
import { normalize } from 'normalizr';

import * as api from 'api';
import {
  selectLabelRecommendedSubscribersRoot,
  selectLabelSubscribersFetching,
  selectLabelSubscribersLastFetched
} from 'redux/selectors/labelSubscribers';

import { isDataStale } from 'helpers/data';

import * as schema from './schema';
import { createAsyncActions } from './utilities';
import { ASYNC_OPERATIONS } from 'config/constants';

// ****************** SUBSCRIBERS ******************
export const fetchLabelSubscribersActions = createAsyncActions(
  'LABEL_SUBSCRIBERS',
  ASYNC_OPERATIONS.FETCH
);

// Same as fetchLabelSubscribersActions.success, but for non-fetch actions
interface LabelSubscribersSuccessPayload {
  id: number;
  artistIds: number[];
  entities: any;
}
export const setLabelSubscribers = createAction<LabelSubscribersSuccessPayload>(
  'LABEL_SUBSCRIBERS_SET'
);

/**
 * [updateLabelSubscriber] - update an individual subscriber
 *
 * @param {number} labelId
 * @param {number} artistId
 * @param {object} data - new data to write to subscriber data in redux
 */

interface UpdateLabelSubscriberParams {
  labelId: number;
  artistId: number;
  data: any;
}
export const updateLabelSubscriber = createAction<UpdateLabelSubscriberParams>(
  'LABEL_SUBSCRIBER_UPDATE'
);

export const fetchLabelSubscribers = id => (dispatch, getState) => {
  const isFetching = selectLabelSubscribersFetching(getState(), {
    labelId: id
  });

  if (isFetching) return Promise.resolve();

  dispatch(fetchLabelSubscribersActions.request({ id }));

  return api.getLabelSubscribers(id).then(
    response => {
      const { result, entities } = normalize(response.data, schema.subscriberList);
      dispatch(fetchLabelSubscribersActions.success({ id, artistIds: result, entities }));
      return response.data;
    },
    error => {
      dispatch(fetchLabelSubscribersActions.error({ id, error }));
    }
  );
};

export const handleLabelSubscriberResponse = (labelId, data, dispatch) => {
  const { result, entities } = normalize(data, schema.subscriberList);
  // Response returns new list of all label subscribers:
  dispatch(setLabelSubscribers({ id: labelId, artistIds: result, entities }));
  return data;
};

// ADD SUBSCRIBERS
export const addSubscriberToLabel = (labelId, artistId) => dispatch =>
  api
    .addSubscriberToLabel(labelId, artistId)
    .then(({ data }) => handleLabelSubscriberResponse(labelId, data, dispatch));

// REMOVE SUBSCRIBERS
export const removeSubscriberFromLabel = (labelId, artistId) => dispatch =>
  api
    .removeSubscriberFromLabel(labelId, artistId)
    .then(({ data }) => handleLabelSubscriberResponse(labelId, data, dispatch));

/**
 * [fetchLabelSubscribersForLabels]
 * Fetches label subscribers for the label ids passed. Results will be stored in redux under labels.subscribersByLabelId
 *
 * @param {number[]} labelIds - label ids to fetch label subscribers for
 * @param {number} [staleMinutes] - if passed, will perform a stale check to determine if label subscribers
 *  should be fetched
 *
 * @returns {Promise} returns promise that resolves to an array of arrays with subscribers for each label
 */

export const fetchLabelSubscribersForLabels =
  (labelIds = [], staleMinutes) =>
  (dispatch, getState) => {
    const promises = labelIds.reduce((accum, labelId) => {
      const __lastFetched = selectLabelSubscribersLastFetched(getState(), { labelId });

      // If staleMinutes is defined and data is not stale, don't fetch
      if (staleMinutes && !isDataStale(__lastFetched, staleMinutes)) return accum;

      return [...accum, dispatch(fetchLabelSubscribers(labelId))];
    }, []);

    return Promise.all(promises);
  };

// ****************** RECOMMENDED SUBSCRIBERS ******************
export const fetchRecommendedSubscribersActions = createAsyncActions(
  'LABEL_RECOMMENDED_SUBSCRIBERS',
  ASYNC_OPERATIONS.FETCH
);

export const removeRecommendedSubscribers = createAction<{
  labelId: number;
  artistIds: number[];
}>('LABEL_RECOMMENDED_SUBSCRIBERS_REMOVE', (labelId, artistIds = []) => ({
  labelId,
  artistIds
}));

export const fetchRecommendedSubscribers = (labelId, limit) => (dispatch, getState) => {
  const { isFetching } = selectLabelRecommendedSubscribersRoot(getState(), {
    labelId
  });
  if (isFetching) return Promise.resolve();

  dispatch(fetchRecommendedSubscribersActions.request({ id: labelId }));

  return api
    .getLabelRecommendedSubscribers(labelId)
    .then(({ data }) => {
      // api doesn't support pagination yet, so grab what we need here to simulate
      const { entities } = limit
        ? normalize(data.slice(0, limit), schema.artistList)
        : normalize(data, schema.artistList);

      dispatch(
        fetchRecommendedSubscribersActions.success({
          id: labelId,
          data,
          entities
        })
      );

      return data;
    })
    .catch(error => {
      dispatch(fetchRecommendedSubscribersActions.error({ id: labelId, error }));
      throw error;
    });
};

export const removeRecommendedLabelSubscriber = (labelId, artistId) => dispatch =>
  api.removeRecommendedLabelSubscriber(labelId, artistId).then(({ data }) => {
    dispatch(removeRecommendedSubscribers(labelId, [artistId]));
    return data;
  });
