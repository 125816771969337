import { dismissRelease } from 'redux/actions/promoReleases';
import { store } from 'config/store';

import { getTrackActions } from './tracks';
import { getReleaseActions } from './release';
import { displayAlertError } from './alert';

const LINK_ICON = 'icon-link.svg';

export const getPromoTrackActions = ({
  track,
  user,
  isTrackPageActive,
  isReleasePageActive,
  dspLinks
}) => [
  ...getTrackActions({ track, user, isTrackPageActive, isReleasePageActive, dspLinks })
  // other actions specific to promo tracks here...
];

export const getPromoReleaseActions = (release, label) => [
  {
    name: 'Dismiss Promo',
    icon: LINK_ICON,
    onClick: () =>
      store.dispatch(
        dismissRelease.request(
          { releaseId: release.id },
          { onError: displayAlertError, optimistic: true }
        )
      )
  },
  ...getReleaseActions(release, label)
];
