import algoliasearch from 'algoliasearch';
import { v4 as uuidv4 } from 'uuid';

import * as api from 'api';
import { store } from 'config/store';
import { ALGOLIA } from 'config/constants';
import { isAdminUser } from 'helpers/user';

import {
  setSearchQuery,
  setSearchResults,
  handleSearchError,
  clearSearch
} from './index';
import findMatchingTracksFromMixes from './findMatchingTracksFromMixes';
import findMatchingGenres from './findMatchingGenres';
import searchUsers from './searchUsers';
import formatSearchResultByEntity from './formatSearchResultByEntity';

export const searchConfig = {
  tracklists: {
    resultCount: 100
  },
  artists: {
    resultCount: 20
  },
  shows: {
    resultCount: 20
  },
  users: {
    resultCount: 20
  },
  labels: {
    resultCount: 20
  }
};

const client = algoliasearch(ALGOLIA.APP_ID, ALGOLIA.API_KEY, {
  protocol: window.location.protocol
});

const adminUserLookup = ({ searchText }) => {
  if (!isAdminUser(store.getState().user)) return Promise.resolve([]);
  return api.adminLookupUser(searchText).then(response => [
    {
      ...response.data,
      category: 'userLookup',
      key: uuidv4() // unique iterator for UI rendering
    }
  ]);
};

export const getSearchResults = searchText => dispatch => {
  if (!searchText) return dispatch(clearSearch());
  dispatch(setSearchQuery(searchText));

  // Search multiple indexes
  // REF: https://www.algolia.com/doc/api-reference/api-methods/multiple-queries/
  const queries = [
    {
      indexName: ALGOLIA.MIX_INDEX,
      query: searchText,
      params: {
        restrictSearchableAttributes: [
          'algolia_tracklist.track',
          'algolia_tracklist.label',
          'algolia_tracklist.artist'
        ],
        // since saving mixes to redux store, grab all mix attributes
        hitsPerPage: searchConfig.tracklists.resultCount,
        restrictHighlightAndSnippetArrays: true
      }
    },
    {
      indexName: ALGOLIA.SHOW_INDEX,
      query: searchText,
      params: {
        restrictSearchableAttributes: ['name', 'artist_name'],
        attributesToRetrieve: ['name', 'slug', 'show_image', 'artist_name'],
        hitsPerPage: searchConfig.shows.resultCount,
        restrictHighlightAndSnippetArrays: true
      }
    },
    {
      indexName: ALGOLIA.ARTIST_INDEX,
      query: searchText,
      params: {
        restrictSearchableAttributes: ['name'],
        attributesToRetrieve: [
          'id',
          'name',
          'tracks_count',
          'mixes_count',
          'slug',
          'soundcloud_url',
          'image_url',
          'has_artist_image'
        ],
        hitsPerPage: searchConfig.artists.resultCount,
        restrictHighlightAndSnippetArrays: true,
        // NOTE: nested array reads as mixes_count > 0 OR tracks_count > 0
        numericFilters: [['mixes_count > 0', 'tracks_count > 0']]
      }
    },
    {
      indexName: ALGOLIA.LABEL_INDEX,
      query: searchText,
      params: {
        restrictSearchableAttributes: ['name'],
        attributesToRetrieve: ['id', 'image', 'name', 'slug']
      },
      hitsPerPage: searchConfig.labels.resultCount,
      restrictHighlightAndSnippetArrays: true
    }
  ];

  // If admin, perform user search
  const userSearch = searchUsers({
    query: searchText,
    hitsPerPage: searchConfig.users.resultCount
  });

  const adminLookupUser = adminUserLookup({
    searchText
  });

  const matchingPublicEntities = client
    .search(queries)
    .then(content => {
      return {
        tracklists: findMatchingTracksFromMixes(content.results[0]),
        genres: findMatchingGenres(searchText),
        shows: formatSearchResultByEntity(content.results[1], 'shows'),
        artists: formatSearchResultByEntity(content.results[2], 'artists'),
        labels: formatSearchResultByEntity(content.results[3], 'labels')
      };
    })
    .catch(err => {
      console.error('Search failed: ', err);
      dispatch(handleSearchError());
    });

  Promise.all([matchingPublicEntities, userSearch, adminLookupUser]).then(values => {
    const searchResults = {
      ...values[0],
      users: values[1],
      userLookup: values[2]
    };

    dispatch(setSearchResults(searchResults));
  });
};
