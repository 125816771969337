import { calculatePreviewPosition, getStreamingUrl } from 'helpers/player';
import { selectIsTrackOnPreview } from 'redux/selectors';

import { PLAYER_ID } from './AudioPlayer';

type QueuedAudio = {
  initialPosition: number;
  lastAllowedPosition: number;
  url: string;
  filesize: number;
  meta: {
    id: number;
    type: string;
    index: number;
    previewPositionSeconds: number;
  };
};

export type QueuedMix = QueuedAudio & {
  meta: QueuedAudio['meta'] & {
    type: 'Mix';
    mix_length: number;
  };
};

export type QueuedTrack = QueuedAudio & {
  meta: QueuedAudio['meta'] & {
    type: 'track';
    duration_seconds: number;
  };
};

const PREVIEW_WINDOW_LENGTH = 120;

const createPreviewWindow = track => {
  const previewPositionSeconds = calculatePreviewPosition(track.duration_seconds);

  return {
    previewPositionSeconds,
    initialPosition: previewPositionSeconds / track.duration_seconds,
    lastAllowedPosition:
      (previewPositionSeconds + PREVIEW_WINDOW_LENGTH) / track.duration_seconds
  };
};

const toPlaylist = (reduxState, queue, quality): QueuedMix[] | QueuedTrack[] => {
  const { jwt } = reduxState.token;

  return queue.map((x, i) => {
    // @ts-ignore
    const isPreview = selectIsTrackOnPreview(reduxState, { audio: x });

    const previewWindow =
      isPreview && x.__audioType === 'track'
        ? createPreviewWindow(x)
        : {
            initialPosition: 0,
            lastAllowedPosition: undefined,
            previewPositionSeconds: 0
          };

    return {
      initialPosition: previewWindow.initialPosition,
      lastAllowedPosition: previewWindow.lastAllowedPosition,
      url: getStreamingUrl({
        playerId: PLAYER_ID,
        audioQuality: quality,
        jwt,
        audio: x
      }),
      fileSize: x.__audioType === 'Mix' ? x.filesize : x.audio[quality]?.filesize,
      meta: {
        ...x,
        id: x.__audioId,
        type: x.__audioType,
        index: i,
        previewPositionSeconds: previewWindow.previewPositionSeconds
      }
    };
  });
};

export default toPlaylist;
