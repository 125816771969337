import PropTypes from 'prop-types';
import React from 'react';

import { ActionMenuHeader, MixHeader } from 'components/ActionMenu';

const TrackMenuHeader = ({ children, mix }) => (
  <ActionMenuHeader>
    <MixHeader {...mix} />

    {children}
  </ActionMenuHeader>
);

TrackMenuHeader.propTypes = {
  children: PropTypes.node,
  showCompile: PropTypes.bool,
  mix: PropTypes.shape()
};

export default TrackMenuHeader;
