import axios from 'axios';

import { API_BASE_V2, AUDIO_TYPES } from 'config/constants';

export const getRadio = () =>
  axios.get(`${API_BASE_V2}/radio/now_playing`).then(response => ({
    ...response,
    data: {
      ...response.data,
      // the actual audio type is returned in response.data.type
      __audioId: 1, // for now only supporting one radio with ID = 1
      __audioType: AUDIO_TYPES.RADIO
    }
  }));

// NOTE: Deprecated by websockets (See PushHandler)
export const getListeners = () => axios.get(`${API_BASE_V2}/radio/listener_count`);
