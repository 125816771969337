import { schema } from 'normalizr';

// GOAL: To ensure our api responses have the same shape and we can reliably save data into the redux store
// References:
// https://egghead.io/lessons/javascript-redux-normalizing-api-responses-with-normalizr
// https://github.com/paularmstrong/normalizr/tree/master/docs

export const label = new schema.Entity('labels');
export const labelList = new schema.Entity('label');
export const artist = new schema.Entity('artists');
export const artistList = new schema.Array(artist);

export const subscriber = new schema.Entity(
  'subscribers',
  // definition of the nested entities found within this entity. (subscribers contains artist entities)
  { artistId: artist },
  {
    idAttribute: value => value.artist.id,
    processStrategy: value => {
      const { artist: artistData, ...rest } = value;
      // rename artist to artistId in subscriber entity
      return { ...rest, artistId: artistData };
    }
  }
);
export const subscriberList = new schema.Array(subscriber);

// -- label/subscribers --
// artist: {
//   id: 3140,
//   name: "John Digweed",
//   slug: "/artists/3140/john-digweed",
//   image_url: "//retro.protonradio.com/images/bios/3140.jpg",
//   has_image: false,
//   ...
// }
// follow: false
// mute: false
// promo_access: true

export const promoTrack = new schema.Entity('promoTracks');
export const promoRelease = new schema.Entity(
  'promoReleases',
  {
    trackIds: [promoTrack]
  },
  {
    processStrategy: ({ id, tracks, ...rest }) => ({
      id,
      ...rest,
      // So that tracks are stored as trackIds in the releases entity, we rename tracks to trackIds.
      // Also, so that we can reference the releaseId from the track entity, we copy the releaseId into
      // the track data
      trackIds: tracks.map(track => ({ ...track, releaseId: id }))
    })
  }
);
export const promoReleaseList = new schema.Array(promoRelease);

/**
 * PROMO RELEASES example
 *
 * BEFORE NORMALIZE
 * [{
 *  id: 70338,
 *  bio_ids: [42772],
 *  tracks: [{id: 291566, reaction: null}, {id: 311134, reaction: null}, {id: 311135, reaction: null}]
 * }]
 *
 * AFTER NORMALIZE
 * {
 *  entities: {
 *    promoReleases: {
 *      70338: {
 *        id: 70338,
 *        bio_ids: [42772]
 *        trackIds: [291566, 311134, 311135]
 *      }
 *    },
 *    promoTracks: {
 *      291566: {
 *        id: 291566,
 *        releaseId: 70338,
 *        reaction: null
 *      },
 *      ...
 *    }
 *  },
 *  results: [70338]
 * }
 */
