import _ from 'lodash';

/**
 * [getTrackArtistsToDisplay] - determine artists that should be rendered in the feedback
 * menu.  Returns original artists, then remix artists, and will exclude the reviewing artist
 * in the event that they are reacting to a remix of one of their tracks.
 *
 * @param {object} track
 * @param {object} reviewingArtist
 * @returns {object[]}
 */

export const getTrackArtistsToDisplay = (track, reviewingArtist) => {
  const originalArtists = track.artists
    .filter(({ id, role_id }) => role_id === 1 && id !== reviewingArtist.id)
    .sort((a, b) => a.position - b.position);

  const remixArtists = track.artists
    .filter(({ id, role_id }) => role_id === 2 && id !== reviewingArtist.id)
    .sort((a, b) => a.position - b.position);

  return _.uniqBy([...originalArtists, ...remixArtists], 'id');
};

export const getTrackOriginalArtists = track =>
  track.artists
    .filter(({ role_id }) => role_id === 1)
    .sort((a, b) => a.position - b.position);
