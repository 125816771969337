import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { isUrlExternal } from 'config/routes/helpers';

import loadingIcon from 'images/icon-loading.svg';
import OpenExternal from 'components/Icons/OpenExternal';

const ButtonContents = ({
  children,
  submitText,
  showLoadingIcon,
  isSubmitting,
  isHovering,
  hoverText,
  append,
  prepend,
  prependAlt, // Deprecated, do not use.
  text,
  to,
  newTab
}) => {
  if (children && hoverText && isHovering) return hoverText;

  return (
    <>
      {children}

      {!children && (
        <>
          {isSubmitting ? (
            <>
              {submitText || 'Submitting...'}
              {showLoadingIcon && (
                <span className="Button__icon">
                  <img src={loadingIcon} alt="Loading icon" />
                </span>
              )}
            </>
          ) : (
            <>
              {prepend && typeof prepend === 'function' && (
                <span className="Button__icon Button__icon--prepend">
                  {prepend({ isHovering })}
                </span>
              )}

              {prepend &&
                typeof prepend === 'string' && ( // String type is deprecated, pass component or renderProp function instead
                  <span className="Button__icon">
                    <img src={prepend} alt={prependAlt} />
                  </span>
                )}

              {hoverText && isHovering ? hoverText : text || 'Submit'}

              {append && typeof append === 'function' && (
                <span className="Button__icon Button__icon--append">
                  {append({ isHovering })}
                </span>
              )}

              {append && typeof append === 'string' && <img src={append} alt="Icon" />}
            </>
          )}
        </>
      )}

      {to && newTab && !append && typeof append !== 'boolean' && (
        <OpenExternal
          className="ml-1"
          style={{ marginTop: '-0.2rem', opacity: 0.5 }}
          size={14}
          color="currentColor"
        />
      )}
    </>
  );
};

export const Button = props => {
  const [isHovering, setIsHovering] = useState(false);

  const {
    block,
    color = Button.COLORS.PRIMARY,
    className,
    'data-testid': dataTestId,
    expand,
    expandHeight,
    prepend,
    append,
    hoverText,
    newTab,
    onClick,
    outline,
    rounded,
    size,
    disabled,
    to,
    type = 'button',
    innerRef,
    isSubmitting,
    flex,
    uppercase,
    showLoadingIcon,
    submitText,
    ...rest
  } = props;

  const _classes = classnames(['Button', color, size, className], {
    'Button--rounded': rounded,
    'Button--outline': outline,
    'Button--block': block,
    'Button--flex': flex,
    'Button--uppercase': uppercase,
    'Button--expand': expand,
    'Button--expand-height': expandHeight,
    'Button--disabled': disabled
  });

  const commonProps = {
    onMouseEnter: () => setIsHovering(true),
    onMouseLeave: () => setIsHovering(false),
    className: _classes,
    disabled: disabled || isSubmitting,
    'data-testid': dataTestId
  };

  if (to) {
    // check to see if external link
    if (isUrlExternal(to)) {
      return (
        <a
          href={!disabled ? to : undefined}
          target={newTab ? '_blank' : undefined}
          rel={newTab ? 'noopener noreferrer' : undefined}
          {...commonProps}
          {...rest}
        >
          <ButtonContents isHovering={isHovering} {...props} />
        </a>
      );
    }

    return (
      <Link to={to} target={newTab ? '_blank' : undefined} {...commonProps} {...rest}>
        <ButtonContents isHovering={isHovering} {...props} />
      </Link>
    );
  }

  return (
    <button
      className={_classes}
      onClick={onClick}
      type={type}
      ref={innerRef}
      {...commonProps}
      {...rest}
    >
      <ButtonContents isHovering={isHovering} {...props} />
    </button>
  );
};

Button.COLORS = {
  PRIMARY: 'Button--primary',
  NEUTRAL: 'Button--neutral',
  WARNING: 'Button--warning',
  WHITE: 'Button--white',
  SUPER_LIGHT: 'Button--superlight',
  LIGHT: 'Button--light',
  TRANSPARENT: 'Button--transparent',
  TRANSLUCENT: 'Button--translucent',
  LINK: 'Button--link'
};

Button.SIZES = {
  MINIMAL: 'Button--minimal',
  SMALL: 'Button--small',
  MEDIUM: 'Button--medium',
  LARGE: 'Button--large'
};

const commonPropTypes = {
  append: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.bool]),
  appendAlt: PropTypes.string,
  children: PropTypes.node,
  prepend: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isSubmitting: PropTypes.bool,
  submitText: PropTypes.string,
  showLoadingIcon: PropTypes.bool,
  text: PropTypes.string
};

ButtonContents.propTypes = {
  isHovering: PropTypes.bool,
  ...commonPropTypes
};

Button.propTypes = {
  style: PropTypes.object,
  block: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(
    // eslint-disable-next-line no-unused-vars
    Object.entries(Button.COLORS).map(([key, value]) => value)
  ),
  hoverText: PropTypes.string,
  disabled: PropTypes.bool,
  expand: PropTypes.bool,
  expandHeight: PropTypes.bool,
  flex: PropTypes.bool,
  innerRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  newTab: PropTypes.bool,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  rounded: PropTypes.bool,
  size: PropTypes.oneOf(
    // eslint-disable-next-line no-unused-vars
    Object.entries(Button.SIZES).map(([key, value]) => value)
  ),
  type: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.shape({
        modal: PropTypes.bool
      })
    }),
    PropTypes.func
  ]),
  uppercase: PropTypes.bool,
  ...commonPropTypes
};

export default Button;
