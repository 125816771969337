import React, {
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Prompt } from 'react-router-dom';
import { FormikHelpers, FormikProps, FormikValues } from 'formik';
import { Schema } from 'yup';

import { en } from 'config/constants/localization';

import FormikWrapper, { RenderActionsProps } from '../Wrappers/FormikWrapper';
import { FormGroupRow } from './FormGroupRow';
import TextField from '../Inputs/Text/Text.Formik';

/*
 * [FormRenderer] - simple component that handles setting parent isDirty state when dirty prop changes.
 */

interface FormRendererProps<TValues extends FormikValues> {
  formProps: FormikProps<TValues> & { isDisabled?: boolean; close?: () => void };
  setIsDirty: React.Dispatch<SetStateAction<boolean>>;
  inputs?: InputField[];
  hideLabels?: boolean;
  inline?: boolean;
  children?: ReactNode | ((formProps: FormikProps<TValues>) => ReactNode);
}

function FormRenderer<TValues extends FormikValues>({
  formProps,
  inputs,
  hideLabels,
  inline,
  children,
  setIsDirty
}: FormRendererProps<TValues>) {
  useEffect(() => {
    setIsDirty(formProps.dirty);
    // ensure isDirty is set to false when unmounted
    return () => setIsDirty(false);
  }, [formProps.dirty, setIsDirty]);

  if (children && typeof children === 'function') {
    return children(formProps);
  } else if (children && typeof children === 'object') {
    return children;
  }

  return inputs?.map(input => (
    <TextField
      hideLabel={hideLabels}
      disabled={formProps.isSubmitting || formProps.isDisabled}
      {...input}
      key={input.label}
      inline={inline}
    />
  ));
}

// [FormGroupRowFormik] Formik based form to render within a FormGroupRow
/*
 * [FormGroupRowFormik]
 *
 * @param {boolean} [disabled] - force disabled state for inputs
 * @param {func || object[]} inputs - supports a render function that receieves formik props or an array of
 * json blobs defining the text inputs (see TextField)
 * @param {boolean} [warnUnsavedChanges] - if true, a warning will be displayed to the user if ther are any
 * unsubmitted changes (i.e. form is dirty) when navigating away from the page, unloading (refresh / tab close),
 * or before clicking "Done" which dismisses the form.
 * @param {...} FormGroupRow_Props - see FormGroupRow for documention on other props
 * @param {...} FormWrapper_Props - see FormWrapper for documention on other props
 */

interface FormGroupRowProps {
  title?: string;
  subtitle?: ReactNode;
  showSubtitleMobile?: boolean;
  value?: string;
  renderIcon?: (config: { size: number; color: string }) => ReactNode;
  bottomBorder?: boolean;
  editable?: boolean;
  initialEditState?: boolean;
  onEdit?: () => void;
  onDismiss?: () => void;
  isEditing?: boolean;
}

interface FormikAndFormProps<TValues> {
  hideLabels?: boolean;
  initialValues: TValues;
  inline?: boolean;
  validationSchema?: Schema;
  buttonText?: string;
  renderActions?: (props: RenderActionsProps<TValues>) => ReactNode;
  renderAside?: () => ReactNode;
  onSubmit: (values: TValues, actions: FormikHelpers<TValues>) => Promise<void>;
}

interface InputField {
  name: string;
  label: string;
  placeholder: string;
}

interface FormGroupRowWrapperProps<TValues extends FormikValues = FormikValues> {
  disabled: boolean;
  'data-testid': string;
  inputs?: InputField[];
  children: ReactNode | React.FC<FormikProps<TValues>>;
  warnUnsavedChanges: boolean;
  hideButton: boolean;
}

type FormGroupRowFormikProps<T extends FormikValues> = FormGroupRowProps &
  FormGroupRowWrapperProps<T> &
  FormikAndFormProps<T>;

type RenderEditProps = {
  isEditing: boolean;
  close: () => void;
};

export function FormGroupRowFormik<TValues extends FormikValues>({
  // FormGroupRowWrapperProps
  disabled,
  'data-testid': rowTestId,
  inputs,
  children,
  warnUnsavedChanges,
  hideButton,
  // FormikAndFormProps
  hideLabels,
  initialValues,
  inline,
  validationSchema,
  buttonText,
  renderActions,
  renderAside,
  onSubmit,
  // FormGroupRowProps
  title,
  subtitle,
  showSubtitleMobile,
  value,
  renderIcon,
  bottomBorder,
  editable,
  initialEditState,
  onEdit,
  onDismiss = () => null,
  isEditing
}: FormGroupRowFormikProps<TValues>) {
  const [isDirty, setIsDirty] = useState(false);
  const shouldWarnUnsavedChanges = isDirty && warnUnsavedChanges;

  const handleOnDismiss = useCallback(
    ({ close }: { close: () => void }) => {
      if (shouldWarnUnsavedChanges) {
        const canProceed = window.confirm(en.warnings.unsavedChangesContinue);
        if (!canProceed) return;
      }

      onDismiss();
      close();
    },
    [onDismiss, shouldWarnUnsavedChanges]
  );

  useEffect(() => {
    if (!shouldWarnUnsavedChanges) return;

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      // NOTE: custom messages aren't supported in modern browsers, but we try anyways
      event.returnValue = en.warnings.unsavedChangesLeave;
      return en.warnings.unsavedChangesLeave;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isDirty, shouldWarnUnsavedChanges]);

  return (
    <FormGroupRow
      title={title}
      subtitle={subtitle}
      showSubtitleMobile={showSubtitleMobile}
      value={value}
      data-testid={rowTestId}
      editable={editable}
      onDismiss={handleOnDismiss}
      onEdit={onEdit}
      isEditing={isEditing}
      renderIcon={renderIcon}
      renderAside={renderAside}
      bottomBorder={bottomBorder}
      initialEditState={initialEditState}
      renderEditingContent={({ isEditing, close }: RenderEditProps) => (
        <>
          <Prompt
            when={shouldWarnUnsavedChanges}
            message={en.warnings.unsavedChangesContinue}
          />

          {isEditing && (
            <FormikWrapper
              initialValues={initialValues}
              inline={inline}
              validationSchema={validationSchema}
              buttonText={buttonText}
              hideButton={hideButton}
              renderActions={renderActions}
              className="mt-2"
              onSubmit={async (values, actions) => {
                await onSubmit(values, actions);
                close();
              }}
              disabled={disabled}
            >
              {formProps => {
                return (
                  <FormRenderer
                    setIsDirty={setIsDirty}
                    formProps={{ ...formProps, close }}
                    inputs={inputs}
                    inline={inline}
                    hideLabels={hideLabels}
                  >
                    {children}
                  </FormRenderer>
                );
              }}
            </FormikWrapper>
          )}
        </>
      )}
    />
  );
}

export default FormGroupRowFormik;
