import { useEffect } from 'react';

/**
 * [usePreventInputZoomOnIOS] - hook that sets 'maximum-scale=1.0' for iOS devices. At the time of writing,
 * Proton's input font-size are 14px. This triggers a zoom effect on iOS devices when focusing on an input field.
 *
 * Setting 'maximum-scale=1.0' on Android prevents users from being able to pinch to zoom, but iOS v10+ ignore this this
 * for user initiated zoom, but it's still respected for the zoom on focused input fields. Therefore it's a good
 * solution for iOS devices where the issue is occuring. Ideally we'll consider increasing font-size on our inputs or
 * iOS will change the input focus behavior in the future.
 *
 * Reference S.O. post: https://stackoverflow.com/a/57527009/4262936
 */

const addMaximumScaleToMetaViewport = () => {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content');
    const regex = /maximum\-scale=[0-9\.]+/g;

    if (regex.test(content)) {
      content = content.replace(regex, 'maximum-scale=1.0');
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ');
    }

    el.setAttribute('content', content);
  }
};

// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
const checkIsIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

function usePreventInputZoomOnIOS() {
  useEffect(() => {
    if (checkIsIOS()) {
      addMaximumScaleToMetaViewport();
    }
  }, []);
}

export default usePreventInputZoomOnIOS;
