import { createSelector } from '@reduxjs/toolkit';

import { FETCHING_STATE } from 'redux/reducers/utilities';
import { ARTIST_INITIAL_STATE } from 'redux/reducers/initialState';

// NOTE: this selector will not return releases_on attribute with actual label data
const DEFAULT_ROOT_STATE = { data: ARTIST_INITIAL_STATE, ...FETCHING_STATE };

export const selectAllArtists = state => state.artists.byId;

export const selectArtistByIdRoot = (state, { artistId }) =>
  selectAllArtists(state)[artistId] || DEFAULT_ROOT_STATE;

export const selectArtistById = (state, { artistId }) =>
  selectArtistByIdRoot(state, { artistId }).data;

const selectTopArtistsIds = (state, { limit }) => {
  const topArtistsIds = state.artists.topArtistsIds.data;
  return limit ? topArtistsIds.slice(0, limit) : topArtistsIds;
};

/**
 * [selectTopArtists] - returns artist stored from top artist endpoint
 *
 * @param {number} [limit]
 * @returns {object[]} array of top artists in order
 */

export const selectTopArtists = createSelector(
  [selectAllArtists, selectTopArtistsIds],
  (artistsById, topArtistsIds) =>
    topArtistsIds.reduce((accum, id) => {
      const artist = artistsById[id] && artistsById[id].data;
      if (!artist) return accum;
      return [...accum, artist];
    }, [])
);
