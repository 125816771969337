import React from 'react';
import { useAppDispatch } from 'hooks/redux';

import { en } from 'config/constants/localization';
import Button from 'components/Button';
import { showAlert } from 'redux/actions/ui';
import Alert, { AlertType } from '../Alert';
import { confirmUserResend } from 'api';

interface ResendUserActivationProps {
  protonid: string;
  dismissAlert: () => void;
  type: AlertType;
}

export const ResendUserActivation = ({
  protonid,
  dismissAlert,
  type
}: ResendUserActivationProps) => {
  const [sentNewActivationEmail, setSentNewActivationEmail] = React.useState(false);
  const dispatch = useAppDispatch();

  const sendActivationEmail = () =>
    confirmUserResend(protonid)
      .then(() => setSentNewActivationEmail(true))
      .catch(e => dispatch(showAlert(e)));

  if (sentNewActivationEmail) {
    return (
      <>
        <Alert.Content>{en.account.confirmationEmailResent}</Alert.Content>

        <Alert.ButtonGroup>
          <Alert.DismissButton onClick={dismissAlert} type={type} />
        </Alert.ButtonGroup>
      </>
    );
  }

  return (
    <>
      <Alert.Content data-testid="ResendUserActivation">
        This account has not been activated yet. Check your email, or click the button
        below to request a new link.
      </Alert.Content>

      <Alert.ButtonGroup>
        <Alert.DismissButton onClick={dismissAlert} type={type} />

        <Button onClick={sendActivationEmail}>Resend Activation Email</Button>
      </Alert.ButtonGroup>
    </>
  );
};
