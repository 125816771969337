import React, { ForwardedRef, forwardRef } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import InputGroup from 'components/Form/InputGroup';
import { InputStyling } from '../styled';
import { BREAKPOINTS } from 'config/constants';
import { InputGroupProps } from 'components/Form/InputGroup/InputGroup';

const StyledInput = styled.input`
  ${InputStyling}
`;

const StyledTextAreaInput = styled.textarea<{ $expand?: boolean }>`
  ${InputStyling};

  height: ${({ $expand }) => !$expand && '10rem'};
  min-height: 6rem;
  width: 100%;

  @media screen and (max-width: ${BREAKPOINTS.MD}px) {
    width: 100%;
  }
`;

export type TextProps = Omit<InputGroupProps, 'name'> & {
  name?: string;
  disabled?: boolean;
  expand?: boolean;
  inputStyle?: React.CSSProperties;
  inputClassName?: string;
  type?: string;
  placeholder?: string;
  // TODO: we should figure out how to type this component properly by extending the input elements, but I ran
  // into typescript rabbit hole in attempting this. Typing here for now. - BTR 10/19/23
  readOnly?: boolean;
  value?: string;
  title?: string;
};

const Text = forwardRef((props: TextProps, ref) => {
  const {
    className, // InputGroup classname prop
    disabled,
    expand, // textarea prop
    formik,
    footer,
    hideLabel,
    inline,
    inputStyle,
    inputClassName,
    label,
    labelAside,
    name,
    style, // InputGroup style prop
    type,
    required,
    ...rest
  } = props;
  // Auto-convert input label to name field if no name is passed
  const inputName = name || _.snakeCase(label?.toLowerCase());

  const commonInputProps = {
    id: inputName,
    name: inputName,
    disabled,
    style: inputStyle,
    className: inputClassName,
    type,
    ...rest
  };

  return (
    <InputGroup
      className={className}
      label={label}
      hideLabel={hideLabel}
      labelAside={labelAside}
      formik={formik}
      footer={footer}
      inline={inline}
      name={inputName}
      style={style}
      required={required}
    >
      {type === 'textarea' ? (
        <StyledTextAreaInput
          ref={ref as ForwardedRef<HTMLTextAreaElement>}
          $expand={expand}
          {...commonInputProps}
        />
      ) : (
        <StyledInput ref={ref as ForwardedRef<HTMLInputElement>} {...commonInputProps} />
      )}
    </InputGroup>
  );
});

export default Text;
