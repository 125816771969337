import {
  GetReleaseDspInfoQuery,
  LabelSubscriberEdge,
  Maybe,
  PaginationInfo,
  PromoSubscriptionEdge,
  Release,
  ReleaseStatusEnum,
  StoreReleaseInfo
} from 'gql/graphql';
import { dspButtonTitle } from 'helpers/dspConnection';
import { DeepPartial, DspName, MakeKeyOptional } from 'types';

// We return the same array / object for empty data, so that the tranforms return the same
// reference and don't cause unnecessary re-renders.
const EMPTY_ARRAY = [];

const defaultPageInfo = {
  hasNextPage: false,
  hasPreviousPage: false,
  nodeCount: 0,
  page: 0,
  pageCount: 0,
  perPage: 0
};

type ViewerLabelSubscriptionsQuery<LabelsFilterKey extends string = 'labels'> = {
  viewer?: Maybe<{
    promoPool?: Maybe<{
      [K in LabelsFilterKey]: {
        edges: DeepPartial<LabelSubscriberEdge>[] | DeepPartial<PromoSubscriptionEdge>[];
        pageInfo?: Partial<PaginationInfo>;
      };
    }>;
  }>;
};

/**
 * Returns array of label promo pool invites that a user has received or an empty array
 * if a response value is null.
 *
 */
export function viewerLabelSubscriptionsTransform<
  Query extends ViewerLabelSubscriptionsQuery
>(
  responseData: Query | undefined
): NonNullable<NonNullable<Query['viewer']>['promoPool']>['labels']['edges'];
/**
 * Returns filtered array of label promo pool invites that a user has received or an empty array
 * if a response value is null.
 *
 * `alias` param determines filter group and can be one of 'invited' or 'following'
 *
 */
export function viewerLabelSubscriptionsTransform<
  Query extends ViewerLabelSubscriptionsQuery<Alias>,
  Alias extends 'invited' | 'following'
>(
  responseData: Query | undefined,
  alias: Alias | undefined
): NonNullable<NonNullable<Query['viewer']>['promoPool']>[Alias]['edges'];
/** Implementation */
export function viewerLabelSubscriptionsTransform(
  responseData: ViewerLabelSubscriptionsQuery<string> | undefined,
  alias?: 'invited' | 'following'
) {
  return responseData?.viewer?.promoPool?.[alias || 'labels'].edges || EMPTY_ARRAY;
}

/**
 * Returns array of label promo pool invites that a user has received, along with
 * pagination information about results.
 *
 */
export function viewerLabelSubscriptionsPaginationTransform<
  Query extends ViewerLabelSubscriptionsQuery
>(
  responseData: Query | undefined
): {
  data: NonNullable<NonNullable<Query['viewer']>['promoPool']>['labels']['edges'];
  pageInfo: NonNullable<NonNullable<Query['viewer']>['promoPool']>['labels']['pageInfo'];
};
/**
 * Returns filtered array of label promo pool invites that a user has received, along with
 * pagination information about results.
 *
 * `alias` param determines filter group and can be one of 'invited' or 'following'
 *
 */
export function viewerLabelSubscriptionsPaginationTransform<
  Query extends ViewerLabelSubscriptionsQuery<Alias>,
  Alias extends 'invited' | 'following'
>(
  responseData: Query | undefined,
  alias: Alias | undefined
): {
  data: NonNullable<NonNullable<Query['viewer']>['promoPool']>[Alias]['edges'];
  pageInfo: NonNullable<NonNullable<Query['viewer']>['promoPool']>[Alias]['pageInfo'];
};
/** Implementation */
export function viewerLabelSubscriptionsPaginationTransform(
  responseData: ViewerLabelSubscriptionsQuery<string> | undefined,
  alias?: 'invited' | 'following'
) {
  const edges = viewerLabelSubscriptionsTransform(responseData, alias);
  const pageInfo =
    responseData?.viewer?.promoPool?.[alias || 'labels'].pageInfo || defaultPageInfo;

  return { data: edges, pageInfo };
}

type LabelSubscribersQuery = {
  label?: Maybe<{
    subscribers?: Maybe<{
      edges: DeepPartial<LabelSubscriberEdge>[];
      pageInfo: Partial<PaginationInfo>;
    }>;
  }>;
};

/**
 * Returns array of artists that a label has invited to their promo pool, or an empty
 * array if a response value is null.
 *
 */
export const labelSubscribersTransform = <Query extends LabelSubscribersQuery>(
  responseData?: Query
): NonNullable<NonNullable<Query['label']>['subscribers']>['edges'] =>
  responseData?.label?.subscribers?.edges || EMPTY_ARRAY;

export const labelSubscribersPaginationTransform = <Query extends LabelSubscribersQuery>(
  responseData?: Query
): {
  data: NonNullable<NonNullable<Query['label']>['subscribers']>['edges'];
  pageInfo: NonNullable<NonNullable<Query['label']>['subscribers']>['pageInfo'];
} => {
  const subscribers = labelSubscribersTransform(responseData);
  const pageInfo = responseData?.label?.subscribers?.pageInfo || defaultPageInfo;
  return { data: subscribers, pageInfo };
};

export interface StoreInfoButton extends StoreReleaseInfo {
  name: Exclude<DspName, 'soundcloud' | 'apple_music'>;
  url: string;
  title: string;
  disabled: boolean;
}

const hasStoreUrl = (x: StoreReleaseInfo | null): x is StoreInfoButton =>
  Boolean(x && x.url);

export function releaseStoreInfoButtonsTransform<
  ReleaseStoreInfo extends { release?: Pick<Release, 'storeInfo'> | null }
>(releaseResponse?: ReleaseStoreInfo): StoreInfoButton[] {
  // ensure we order the store links as follows
  const DSP_LINK_ORDER: DspName[] = ['spotify', 'apple-music', 'beatport'];

  const storeInfo = releaseResponse?.release?.storeInfo || [];

  return DSP_LINK_ORDER.map(storeName => {
    const store = storeInfo.find(({ name }) => storeName === name);
    if (!store || store.status === ReleaseStatusEnum.NotAvailable) return null;

    const title = dspButtonTitle(store.status, storeName);
    const disabled = store.status === ReleaseStatusEnum.Unreleased;
    return { ...store, title, disabled };
  }).filter(hasStoreUrl);
}
