import React, { ReactNode } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

export interface ButtonGroupProps {
  vertical?: boolean;
  justify?: string;
  fit?: boolean;
  collapse?: boolean;
  expand?: boolean;
  className?: string;
  noMargin?: boolean;
  children: ReactNode;
  center?: boolean;
  border?: boolean;
  testId?: string;
  style?: React.CSSProperties;
}

const StyledContainer = styled.div<{ $justify?: string }>`
  ${({ $justify }) =>
    $justify &&
    `
    justify-content: ${$justify};
  `}
`;

export const ButtonGroup = ({
  vertical,
  justify,
  fit,
  collapse,
  expand,
  className,
  noMargin,
  children,
  center,
  border,
  testId,
  style
}: ButtonGroupProps) => {
  const _className = classnames('ButtonGroup', className, {
    'ButtonGroup--vertical': vertical,
    'ButtonGroup--horizontal': !vertical,
    'ButtonGroup--fit': fit,
    'ButtonGroup--collapse': collapse,
    'ButtonGroup--expand': expand,
    'ButtonGroup--bordered': border,
    'ButtonGroup--center': center,
    'mb-0': noMargin,
    'mt-0': noMargin
  });

  return (
    <StyledContainer
      data-testid={testId}
      $justify={justify}
      className={_className}
      style={style}
    >
      {children}
    </StyledContainer>
  );
};

export default ButtonGroup;
