import { findInGraphQLError } from 'graphql/helpers/utils';
import { logError } from 'helpers/logError';
import { CombinedError } from 'urql';

type ErrorPageConfig =
  | undefined
  | {
      code?: '401' | '404' | '500';
      title?: string;
    };

export function handleReleaseError(error: CombinedError | undefined): ErrorPageConfig {
  if (error) {
    const noAuth = findInGraphQLError(error, {
      extensionCode: 'NOT_AUTHORIZED',
      path: ['release']
    });

    if (noAuth) {
      return {
        code: '401'
      };
    }

    const notFound = findInGraphQLError(error, {
      extensionCode: 'NOT_FOUND',
      path: ['release']
    });

    if (notFound) {
      return {
        code: '404',
        title: 'Release not found'
      };
    }

    // Any other unanticipated errors should be logged to determine if there are other things we should handled
    logError(error, { severity: 'error' });

    return {
      code: '500',
      title: 'Error loading Release'
    };
  }
}
