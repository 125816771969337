import React from 'react';
import PropTypes from 'prop-types';

import { ICON_PROP_TYPES } from './config';

const Arrow = props => {
  const { style, direction = Arrow.DIRECTIONS.UP } = props;

  switch (direction) {
    case Arrow.DIRECTIONS.LEFT:
      return <Arrow.Right {...props} style={{ transform: 'rotate(180deg)', ...style }} />;

    case Arrow.DIRECTIONS.RIGHT:
      return <Arrow.Right {...props} />;

    case Arrow.DIRECTIONS.UP:
      return <Arrow.Right {...props} style={{ transform: 'rotate(270deg)', ...style }} />;

    case Arrow.DIRECTIONS.DOWN:
      return <Arrow.Right {...props} style={{ transform: 'rotate(90deg)', ...style }} />;

    default:
      return null;
  }
};

Arrow.Right = ({ color = 'currentColor', size = 18, className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    height={size}
    style={style}
    viewBox="0 0 512 512"
  >
    <title>Arrow</title>

    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="48"
      d="M268 112l144 144-144 144M392 256H100"
    />
  </svg>
);

const propTypes = {
  ...ICON_PROP_TYPES,
  direction: PropTypes.string
};

Arrow.Right.propTypes = propTypes;
Arrow.propTypes = propTypes;

Arrow.DIRECTIONS = {
  UP: 'up',
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right'
};

export default Arrow;
