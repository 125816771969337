import React from 'react';
import styled from 'styled-components';
import { ErrorMessage } from 'formik';

import { COLORS } from 'config/constants/styles';

const StyledErrorMessage = styled.div`
  color: ${COLORS.DANGER};
  margin-top: 0.4rem;
`;

export const InputErrorMessage = ({ errorText }: { errorText?: string }) => {
  return errorText ? <StyledErrorMessage>{errorText}</StyledErrorMessage> : null;
};

export const FormikInputErrorMessage = ({ name }: { name: string }) => (
  <ErrorMessage name={name}>
    {msg => (
      <StyledErrorMessage data-testid={`error-message--${name}`}>
        {msg}
      </StyledErrorMessage>
    )}
  </ErrorMessage>
);

export default FormikInputErrorMessage;
