import { createReducer } from 'redux-act';
import differenceInSeconds from 'date-fns/differenceInSeconds';

import {
  setSearchQuery,
  clearSearch,
  handleSearchError,
  setSearchResultsDisplay,
  setMobileSearchInputDisplay,
  setSearchResults
} from 'redux/actions/search';
import { garbageCollect } from 'redux/actions/utilities';

const initialState = {
  query: null,
  displayResults: false,
  displayMobileInput: false,
  results: {
    shows: [],
    genres: [],
    tracklists: [],
    artists: [],
    labels: [],
    users: [],
    userLookup: []
  },
  count: 0
};

export default createReducer(
  {
    [setSearchQuery]: (state, { query }) => ({
      ...state,
      query,
      isFetching: true,
      searchTimestamp: new Date().toISOString()
    }),
    // clear search input and results, but don't change display preferences
    [clearSearch]: state => ({
      ...state,
      query: initialState.query,
      results: initialState.results,
      count: initialState.count
    }),
    [handleSearchError]: state => ({
      ...state,
      isFetching: false
    }),
    [setSearchResultsDisplay]: (state, { display }) => {
      const displayResults =
        typeof display === 'boolean' ? display : !state.displayResults;

      return {
        ...state,
        displayResults
      };
    },
    [setMobileSearchInputDisplay]: (state, { display }) => {
      const displayInput =
        typeof display === 'boolean' ? display : !state.displayMobileInput;

      return {
        ...state,
        displayMobileInput: displayInput || !state.displayMobileInput
      };
    },
    [setSearchResults]: (state, { results }) => {
      let count = 0;
      Object.keys(results).forEach(type => {
        if (results[type].length > 0) {
          count += results[type].length;
        }
      });

      return {
        ...state,
        results,
        count,
        isFetching: false
      };
    },
    [garbageCollect]: state => {
      if (!state.searchTimestamp) return initialState;

      const staleSearchDuration = 60 * 60 * 24 * 2; // 2 days [seconds]
      const durationToLastSearch = differenceInSeconds(
        new Date(),
        new Date(state.searchTimestamp)
      ); // [seconds]
      if (durationToLastSearch > staleSearchDuration) {
        return initialState;
      }
      return state;
    }
  },
  initialState
);
