import { Keys } from 'react-keydown';

import { store } from 'config/store';
import { displayAlertError } from 'helpers';
import { trackDownloadConfig } from 'helpers/tracks';
import { updateTrackReaction } from 'redux/actions/promoReleases';
import { downloadTrack } from 'redux/actions/tracks';
import { selectPromoTrackReactionArtistId } from 'redux/selectors/promoReleases';

const SEEK_DURATION_SHORT = 10;
const SEEK_DURATION_LONG = 30;

const handlePromoPageKeydown = (e, currentUser, player) => {
  if (['INPUT', 'TEXTAREA'].includes(e.target.tagName)) return;

  // Ignore any events while command key is pressed
  // NOTE: if more control is required, consider making a mapping of currently pressed
  // keys using keypress and keyup handlers
  if (e.metaKey || e.ctrlKey || e.altKey) return;

  switch (e.keyCode) {
    case Keys.RIGHT:
      e.preventDefault();
      if (e.shiftKey) {
        player.send('seek', SEEK_DURATION_LONG);
      } else {
        player.send('seek', SEEK_DURATION_SHORT);
      }
      break;

    case Keys.LEFT:
      e.preventDefault();
      if (e.shiftKey) {
        player.send('seek', -SEEK_DURATION_LONG);
      } else {
        player.send('seek', -SEEK_DURATION_SHORT);
      }
      return;

    case Keys.DOWN:
      if (e.shiftKey) return;
      e.preventDefault();
      player.send('skip');
      break;

    case Keys.UP:
      if (e.shiftKey) return;
      e.preventDefault();
      player.send('back');
      break;

    case Keys['1']:
    case Keys['2']:
    case Keys['3']:
    case Keys['4']:
    case Keys['5']: {
      if (e.shiftKey) return;

      e.preventDefault();
      const rating = parseInt(e.key, 10);

      const track = player.currentTrack;
      if (!track) return;

      const state = store.getState();
      const artistId = selectPromoTrackReactionArtistId(
        state,
        track.release.id,
        track.id
      );

      const tryAutoDownload = () => {
        const autoDownloadSetting = currentUser.settings['AUTO_DOWNLOAD'];
        if (autoDownloadSetting.enabled && rating >= autoDownloadSetting.value) {
          return store.dispatch(
            downloadTrack.call(
              trackDownloadConfig(currentUser, { user: state.user, track })
            )
          );
        }
      };

      store.dispatch(
        updateTrackReaction.call(
          {
            track,
            artistId,
            rating
          },
          { onError: displayAlertError, onSuccess: tryAutoDownload, optimistic: true }
        )
      );

      break;
    }
  }
};

export default handlePromoPageKeydown;
