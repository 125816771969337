import { ENTITY_TYPES } from 'config/constants';

export const getEntityPath = (type, data) => {
  switch (type) {
    case ENTITY_TYPES.MIX:
      return data.mix_slug;

    case ENTITY_TYPES.DSP_ARTIST_PROFILE:
      return data.dsp_url;

    default:
      return data.slug;
  }
};
