import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import routeMap from 'config/routes';
import { COLORS } from 'config/constants';
import { generateSelectorsFromTestIds } from 'helpers/utilities';

import { TombstoneText } from 'components/Tombstone';
import Avatar, { ArtistAvatar } from 'components/Avatar';
import StarRating from 'components/StarRating';
import Card, { CardTitle } from 'components/Card';

import { StyledLink } from './styled';

const FeedbackHeader = styled.div`
  display: flex;
  margin-bottom: 1.6rem;
`;

const StyledArtistAvatar = styled(ArtistAvatar).attrs(() => ({
  size: Avatar.SIZES.LARGE
}))`
  margin-right: 1.2rem;
`;

const HeaderRight = styled.div`
  flex: 1 1 auto;
  ${CardTitle} {
    line-height: 1;
    margin-top: 0.3rem;
  }
`;

const UserText = styled.div`
  color: ${COLORS.GRAY_LIGHT};
  font-size: 1.2rem;
  padding-bottom: 0.4rem;
  padding-left: 0.1rem;
`;

const StyledStarRating = styled(StarRating)`
  width: 13rem;
  margin: 0;
`;

const FEEDBACK_ROUTES = routeMap.artists.feedback;

const FeedbackCardHeader = ({ artist, user, rating, linkToArtist, children }) => (
  <FeedbackHeader data-testid="FeedbackCardHeader">
    <StyledArtistAvatar name={artist.name} imageUrl={artist.image_url} lazy={false} />
    <HeaderRight>
      <Card.Title data-testid="FeedbackCardHeader--title">
        {linkToArtist ? (
          <StyledLink to={`${artist.slug}${FEEDBACK_ROUTES.root}`}>
            {artist.name}
          </StyledLink>
        ) : (
          <div>{artist.name}</div>
        )}
      </Card.Title>

      <UserText data-testid="FeedbackCardHeader--subtitle">
        {user && `${user.firstname} ${user.lastname}`}
      </UserText>

      <StyledStarRating rating={rating} size={StarRating.SIZE.LARGE} displayOnly />

      {children}
    </HeaderRight>
  </FeedbackHeader>
);

FeedbackCardHeader.TEST_IDS = {
  TITLE: 'FeedbackCardHeader--title',
  SUBTITLE: 'FeedbackCardHeader--subtitle'
};

FeedbackCardHeader.SELECTORS = generateSelectorsFromTestIds(FeedbackCardHeader.TEST_IDS);

FeedbackCardHeader.propTypes = {
  artist: PropTypes.shape({
    name: PropTypes.string,
    image_url: PropTypes.string,
    slug: PropTypes.string
  }),
  user: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string
  }),
  track: PropTypes.shape({
    title: PropTypes.string,
    version: PropTypes.string,
    slug: PropTypes.string,
    formatted_artists: PropTypes.string
  }),
  rating: PropTypes.number,
  linkToArtist: PropTypes.bool,
  children: PropTypes.node
};

FeedbackCardHeader.Tombstone = () => (
  <FeedbackHeader>
    <StyledArtistAvatar loading />
    <HeaderRight>
      <TombstoneText size={TombstoneText.SIZES.LARGE} style={{ width: '14rem' }} />
    </HeaderRight>
  </FeedbackHeader>
);

export default FeedbackCardHeader;
