import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';
import _ from 'lodash';

import { garbageCollect } from 'redux/actions/utilities';
import { clearUser } from 'redux/actions/user';
import {
  fetchPromoReleasesFollowing,
  updateTrackReaction,
  dismissRelease,
  updateTrackReactionOptimistic
} from 'redux/actions/promoReleases';
import {
  fetchInitialState,
  handleFetchError,
  handleFetch,
  handleFetchSuccess
} from './utilities';

const releasesById = createReducer(
  {
    [fetchPromoReleasesFollowing.success]: (state, { data }) => {
      const {
        entities: { promoReleases }
      } = data;

      return {
        ...state,
        ...promoReleases
      };
    },
    [dismissRelease.request]: (state, { releaseId }) => ({
      ...state,
      [releaseId]: {
        ...state[releaseId],
        __isDismissing: true
      }
    }),
    [dismissRelease.success]: (state, { releaseId, optimistic }) =>
      optimistic ? state : _.omit(state, releaseId),
    [dismissRelease.error]: (state, { releaseId, prevReleaseState, optimistic }) => {
      const releaseState = optimistic ? prevReleaseState : state[releaseId];

      return {
        ...state,
        [releaseId]: {
          ...releaseState,
          __isDismissing: false
        }
      };
    },
    // Ensure any releases no longer returned from promo endpoints are cleared from releasesById
    [garbageCollect]: (state, { persistPromoReleaseIds }) =>
      persistPromoReleaseIds.reduce((accum, releaseId) => {
        const release = state[releaseId];
        if (!release) return accum;

        return {
          ...accum,
          [releaseId]: release
        };
      }, {}),
    // if user logs out, clear out the releases
    [clearUser]: () => ({})
  },
  {}
);

/**
 * [tracksById] - reaction for a given track. null if no reaction exists (except if optimistic update in progress)
 *
 * NOTE: in order to know whether a reaction already exists (use POST vs PUT) when doing optimistic updates,
 * we store the last known api data in the attribute __optimistic.  This is an indicator that there is optimistic
 * data being displayed. If no reaction existed prior to opitmistic update, __optimistic will equal null
 *
 * WITHOUT optimistic update in progress
 * {
 *   323904: {
 *     id: 323904,
 *     reaction: {
 *       artistId: 42772,
 *       comment: "not bad",
 *       rating: 3
 *     }
 *   }
 * }
 *
 * WITH optimistic update in progress
 * {
 *   323904: {
 *     id: 323904,
 *     reaction: {
 *       artistId: 42772,
 *       comment: "not bad",
 *       rating: 1
 *       // NOTE: this holds the last reaction state returned by the api
 *       __optimistic: {
 *         rating: 3,
 *         artistId: 42772,
 *         comment: "not bad",
 *       }
 *     }
 *   }
 * }
 */

const tracksById = createReducer(
  {
    [fetchPromoReleasesFollowing.success]: (state, { data }) => {
      const {
        entities: { promoTracks }
      } = data;

      return {
        ...state,
        ...promoTracks
      };
    },

    [updateTrackReaction.request]: (state, { trackId }) => {
      const prevReactionState = state[trackId].reaction || {};

      return {
        ...state,
        [trackId]: {
          ...state[trackId],
          reaction: {
            ...prevReactionState,
            // unset _optimistic field if not optimistically updated
            __optimistic: undefined,
            __updating: true,
            __error: null
          }
        }
      };
    },
    [updateTrackReactionOptimistic]: (state, { track, ...rest }) => {
      const { id: trackId } = track;
      // if no reaction, value is null
      const prevReactionState = state[trackId].reaction;

      // If __optimistic field already exists, this is the last stored reaction state from the api and should be reused
      const prevOptimisticState = prevReactionState && prevReactionState.__optimistic;
      const optimisticState = prevOptimisticState === null ? null : prevReactionState;

      return {
        ...state,
        [trackId]: {
          ...state[trackId],
          reaction: {
            ...(prevReactionState || {}),
            ...rest,
            __updating: true,
            __error: null,
            __optimistic: optimisticState
          }
        }
      };
    },
    [updateTrackReaction.success]: (state, { trackId, reaction }) => {
      const prevReactionState = state[trackId].reaction || {};

      // We don't want to include __optimistic field (if it exists) since that was storing the last known
      // api data for this field. removing it indicates that we got a new api response
      const { __optimistic, ...reactionState } = prevReactionState;

      return {
        ...state,
        [trackId]: {
          ...state[trackId],
          reaction: {
            ...reactionState,
            ...reaction,
            __updating: false
          }
        }
      };
    },
    [updateTrackReaction.error]: (state, { trackId, errorMessage }) => {
      const prevReactionState = state[trackId].reaction || {};

      // if there was a stored state prior to update attempt, revert to that...
      // if previous __optimistic was null was, then the last update was optimistic
      const prevOptimisticState =
        prevReactionState.__optimistic || prevReactionState.__optimistic === null
          ? prevReactionState.__optimistic
          : prevReactionState;

      return {
        ...state,
        [trackId]: {
          ...state[trackId],
          reaction: {
            ...prevOptimisticState,
            __updating: false,
            __error: errorMessage
          }
        }
      };
    },
    // only delete tracks once the non-optimist success action is dispatched
    [dismissRelease.success]: (state, { trackIds, optimistic }) =>
      optimistic ? state : _.omit(state, trackIds),
    // Ensure any release tracks no longer returned from promo endpoints are cleared from tracksById
    [garbageCollect]: (state, { persistPromoTrackIds }) =>
      persistPromoTrackIds.reduce((accum, trackId) => {
        const track = state[trackId];
        if (!track) return accum;

        return {
          ...accum,
          [trackId]: track
        };
      }, {}),
    [clearUser]: () => ({})
  },
  {}
);

const releaseIdsFollowing = createReducer(
  {
    [fetchPromoReleasesFollowing.request]: state => handleFetch(state),
    [fetchPromoReleasesFollowing.success]: (state, { data }) =>
      handleFetchSuccess(state, {
        data: data.result,
        normalize: false,
        overwrite: true
      }),
    [fetchPromoReleasesFollowing.error]: (state, { errorMessage }) =>
      handleFetchError(state, { errorMessage }),
    [dismissRelease.success]: (state, { releaseId }) => ({
      ...state,
      data: state.data.filter(id => id !== releaseId)
    }),
    [dismissRelease.error]: (state, { prevPromoReleasesState, optimistic }) => {
      if (optimistic) {
        const prevState = prevPromoReleasesState.releaseIdsFollowing;
        return { ...prevState };
      }

      return state;
    },
    [garbageCollect]: state => ({
      ...state,
      __isFetching: false
    }),
    [clearUser]: () => fetchInitialState
  },
  fetchInitialState
);

// Note: this is a temporary workaround to support the following:
// The API `/api/v2/promos/tracks/following` endpoint had to be
// changed so that it includes releases that have been dismissed or fully
// reacted to because the Client uses that information in the Track Stack
// in order to enable/disable track downloads.
// The following reducer is a copy of `releaseIdsFollowing` with a filter that
// excludes those releases so that they don't get displayed in the Inbox.
// Asana ticket: https://app.asana.com/0/1202770185351932/1202821112077022/f
// GitHub PR: https://github.com/protonradio/client/pull/1405
// TODO: we should probably use 2 different endpoints instead.
const releaseIdsForInbox = createReducer(
  {
    [fetchPromoReleasesFollowing.request]: state => handleFetch(state),
    [fetchPromoReleasesFollowing.success]: (state, { data, rawData }) => {
      const releaseIds = data.result.filter(releaseId => {
        const release = rawData.find(release => release.id === releaseId);
        return !release || !release.tracks.every(track => !!track.reaction);
      });
      return handleFetchSuccess(state, {
        data: releaseIds,
        normalize: false,
        overwrite: true
      });
    },
    [fetchPromoReleasesFollowing.error]: (state, { errorMessage }) =>
      handleFetchError(state, { errorMessage }),
    [dismissRelease.success]: (state, { releaseId }) => ({
      ...state,
      data: state.data.filter(id => id !== releaseId)
    }),
    [dismissRelease.error]: (state, { prevPromoReleasesState, optimistic }) => {
      if (optimistic) {
        const prevState = prevPromoReleasesState.releaseIdsFollowing;
        return { ...prevState };
      }

      return state;
    },
    [garbageCollect]: state => ({
      ...state,
      __isFetching: false
    }),
    [clearUser]: () => fetchInitialState
  },
  fetchInitialState
);

const promoReleaseReducers = combineReducers({
  releasesById,
  tracksById,
  releaseIdsFollowing,
  releaseIdsForInbox
});

export default promoReleaseReducers;
