/* eslint-disable import/prefer-default-export */
import routeMap from 'config/routes';
import { isMobile } from 'helpers/screen';
import { setMobileSearchInputDisplay, setSearchResultsDisplay } from './index';
import { toggleOverlay } from '../ui';

// display => whether or not to hide the search results.
// Handle mobile input show/hide

/**
 * DESCRIPTION:
 * Function handles display of search results and mobile search inputs.
 * Contains logic that determines whether the overlay should be set
 * (mobile results) and handles mobile search input dipslay if
 * 'mobileInputDisplay' is not passed
 *
 * @param {bool} display - whether search results should be displayed or not
 * @param {bool} mobileInputDisplay - (optional) override default logic for display mobile search input
 */

export const toggleSearchResultsDisplay =
  (display, mobileInputDisplay) => (dispatch, getState) => {
    const searchPage = window.location.pathname === routeMap.search;
    const {
      ui: { overlayPresent },
      search: { displayResults }
    } = getState();

    let showMobileInput = display;
    if (typeof mobileInputDisplay === 'boolean') showMobileInput = mobileInputDisplay;
    dispatch(setMobileSearchInputDisplay(showMobileInput));

    // toggle result display as long as not on search page
    if (display && !searchPage && !displayResults) {
      dispatch(setSearchResultsDisplay(true));
      if (isMobile() && !overlayPresent) dispatch(toggleOverlay(true));
    }

    if (!display && displayResults) {
      dispatch(setSearchResultsDisplay(false));
      if (isMobile() && overlayPresent) dispatch(toggleOverlay(false));
    }
  };
