import React, { ReactNode } from 'react';

import ErrorMessage, { InputErrorMessage } from './ErrorMessage';
import Flex from 'components/Flex';

import {
  StyledInputGroup,
  StyledInputGroupLabel,
  InputFooterText,
  StyledInputRequiredIndicator
} from './styled';

export interface InputGroupProps {
  className?: string;
  children?: ReactNode;
  formik?: boolean;
  footer?: ReactNode;
  inline?: boolean;
  hideLabel?: boolean;
  label?: string;
  labelAside?: () => ReactNode;
  name: string;
  style?: React.CSSProperties;
  required?: boolean;
  abbreviateRequired?: boolean;
  errorText?: string; // manually pass an error message (not formik)
}

const InputGroup = ({
  className,
  children,
  formik,
  errorText,
  footer,
  hideLabel = false,
  inline,
  label,
  labelAside,
  name,
  style,
  required,
  abbreviateRequired
}: InputGroupProps) => {
  return (
    <StyledInputGroup
      $formik={formik}
      $inline={inline}
      className={className}
      style={style}
    >
      <div style={{ width: '100%' }}>
        <Flex>
          {label && (
            <StyledInputGroupLabel className={hideLabel ? 'sr-only' : ''} htmlFor={name}>
              {label}
            </StyledInputGroupLabel>
          )}
          {required && (
            <StyledInputRequiredIndicator>
              {abbreviateRequired ? 'Req.' : 'Required'}
            </StyledInputRequiredIndicator>
          )}
          {labelAside && (
            <Flex grow={1} align="flex-end" justify="flex-end">
              <StyledInputGroupLabel as="div">{labelAside()}</StyledInputGroupLabel>
            </Flex>
          )}
        </Flex>
        {children}

        {formik ? (
          <ErrorMessage name={name} />
        ) : (
          <InputErrorMessage errorText={errorText} />
        )}

        {footer && <InputFooterText className="mt-1">{footer}</InputFooterText>}
      </div>
    </StyledInputGroup>
  );
};

export default InputGroup;
