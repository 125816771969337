import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { COLORS, DIRECTIONS } from 'config/constants';
import { generateSelectorsFromTestIds } from 'helpers/utilities';
import { EllipsisButton } from 'components/Button';
import Popover from 'components/Popover';

import DropdownMenuItem from './DropdownMenuItem';

const Divider = styled.div`
  height: 1px;
  margin: 1rem 0;
  background: #f0f0f0;
`;

export const StyledDropdownMenu = styled.ul.attrs(() => ({
  'data-testid': DropdownMenu.TEST_IDS.CONTAINER
}))`
  background: ${({ background }) => background};
  border-radius: 4px;
  overflow: hidden;
  padding: 1rem 0;
  margin-top: 1rem;
`;

/**
 * [DropdownMenu]
 * Wrapper of Popover component to render a standardized menu inside.
 *
 * @prop {func} [renderToggle] pass if you want to show a different toggle button than the default ellipsis button
 * @prop {func} [onOpen] called when dropdown popover is opened.
 * @prop {func} [onClose] called when dropdown popover is close.
 * @prop {bool} [disabled] force disabled state of trigger button
 * @prop {enum} [renderDirection=LEFT] if displaying popover, will try to render in provided direction
 * @prop {enum} [background=WHITE] dropdown content background color
 * @prop {bool} [fixed] Applies positioning: fixed; to CSS of element
 * @prop {object[]} [actions] array of objects used to generate the dropdown items. Can be used instead of children.
 */

// eslint-disable-next-line react/display-name
const DropdownMenu = ({
  background = COLORS.WHITE,
  children,
  className,
  disabled,
  fixed,
  onOpen,
  onClose,
  renderDirection = Popover.DIRECTIONS.DOWN,
  renderToggle,
  triggerTestId = DropdownMenu.TEST_IDS.TOGGLE,
  actions
}) => (
  <Popover
    renderToggle={openerProps => {
      const { open, isOpen, isVisible } = openerProps;

      return (
        <>
          {renderToggle ? (
            renderToggle(openerProps)
          ) : (
            <EllipsisButton
              data-testid={triggerTestId}
              onClick={open}
              disabled={disabled}
              isActive={isOpen && isVisible}
            />
          )}
        </>
      );
    }}
    renderDirection={renderDirection}
    onOpen={onOpen}
    onClose={onClose}
    background={background}
    fixed={fixed}
  >
    {({ open, close }) => {
      const renderProps = {
        closeDropdown: close,
        openDropdown: open
      };

      return (
        <StyledDropdownMenu background={background} className={className}>
          {/* NOTE: Need to pass children a mechanism for closing the dropdown, if children is a 
                renderProp, pass as arguments, otherwise clone into children (used in DropdownItem) */}
          {children && typeof children === 'function'
            ? children(renderProps)
            : React.Children.map(children, child =>
                React.cloneElement(child, renderProps)
              )}

          {actions?.map(action => {
            if (typeof action.divider === 'boolean') {
              if (!action.divider) return null;
              return <Divider key="divider" />;
            }

            if (action.Component) {
              return <action.Component dropdownMenu key={action.name} {...action} />;
            }

            return (
              <DropdownMenuItem
                key={action.name}
                {...action}
                onClick={action.onClick ? () => action.onClick({ close }) : undefined}
                showIcon={false}
              >
                {action.name}
              </DropdownMenuItem>
            );
          })}
        </StyledDropdownMenu>
      );
    }}
  </Popover>
);

DropdownMenu.TEST_IDS = {
  TOGGLE: 'DropdownMenu-toggle',
  CONTAINER: 'DropdownMenu'
};

DropdownMenu.SELECTORS = generateSelectorsFromTestIds(DropdownMenu.TEST_IDS);

DropdownMenu.DIRECTIONS = DIRECTIONS;

DropdownMenu.Item = DropdownMenuItem;

DropdownMenu.Divider = () => <Divider />;

DropdownMenu.propTypes = {
  actions: PropTypes.any,
  background: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fixed: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  renderDirection: PropTypes.string,
  renderToggle: PropTypes.func,
  triggerTestId: PropTypes.string
};

export default DropdownMenu;
