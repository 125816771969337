import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Markdown from 'react-markdown';

import Modal from 'components/Modal';

import changelog from '../../../docs/change-log.md';
import { useAppDispatch } from 'hooks/redux';
import { addAlertMessage, removeAlertMessage } from 'redux/actions/ui';
import { POLICY_LAST_UPDATED } from 'config/constants';

export const PrivacyPolicyChangeLogModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname, search } = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const dismiss = () => {
    params.delete('changelog');
    setIsOpen(false);
    dispatch(addAlertMessage({ component: 'PrivacyPolicyConsent' }));
    history.push({ pathname, search: params.toString() });
  };

  useEffect(() => {
    setIsOpen(params.has('changelog'));
  }, [params]);

  useEffect(() => {
    if (isOpen) {
      dispatch(removeAlertMessage({ id: 'PrivacyPolicyConsent' }));
    }
  });

  if (!isOpen) return null;
  return (
    <Modal onClose={dismiss} testId={'ChangelogModal'}>
      <div
        className="ArticleText"
        style={{
          overflowY: 'auto',
          maxHeight: 'calc(80vh - 54px)',
          marginBottom: 'unset',
          scrollbarGutter: 'stable'
        }}
      >
        <h2 style={{ marginTop: '1rem' }}>
          Changelog -{' '}
          {POLICY_LAST_UPDATED.toLocaleDateString(undefined, {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
          })}
        </h2>
        <Markdown>{changelog}</Markdown>
      </div>
    </Modal>
  );
};
