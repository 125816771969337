import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { isFallbackFlyer } from 'helpers/shows';

const ShowSearchResult = ({ slug = '', show_image, matched, hideSearchResults }) => {
  const _flyerClasses = classnames('UniversalSearchResultRow__icon-flyer', {
    'is-default': isFallbackFlyer(show_image)
  });

  return (
    <Link to={slug} onClick={hideSearchResults}>
      <div className="UniversalSearchResultRow">
        <div className={_flyerClasses}>
          <img src={show_image} alt="Show flyer" />
        </div>
        <div className="UniversalSearchResultRow__info">
          <div>
            <div className="ShowSearchResult__show-title">
              <div dangerouslySetInnerHTML={{ __html: matched.name }} />
            </div>
            <div className="ShowSearchResult__show-artist">
              with <span dangerouslySetInnerHTML={{ __html: matched.artist_name }} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

ShowSearchResult.propTypes = {
  slug: PropTypes.string,
  show_image: PropTypes.string,
  artist_name: PropTypes.string,
  matched: PropTypes.shape({
    // show name
    name: PropTypes.string
  }),
  hideSearchResults: PropTypes.func
};

export default ShowSearchResult;
