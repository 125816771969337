import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { checkForRadioUpdate as checkForRadioUpdateAction } from 'redux/actions/radio';
import {
  addAlertMessage as addAlertMessageAction,
  removeAlertMessage as removeAlertMessageAction
} from 'redux/actions/ui';

class ConnectionStalker extends React.Component {
  state = {
    errorVisible: false,
    offline: false
  };

  componentDidMount() {
    window.addEventListener('offline', () => {
      this.setState(
        {
          offline: true
        },
        () => {
          this.props.addAlertMessage({
            id: 'offline',
            message: 'You are offline!',
            timeout: false,
            dismissable: false
          });
        }
      );
    });

    window.addEventListener('online', () => {
      this.setState(
        {
          offline: false
        },
        () => {
          this.props.removeAlertMessage({ id: 'offline' });
          this.props.checkForRadioUpdate();
        }
      );
    });
  }

  componentDidUpdate() {
    const ERROR_THRESHOLD = 3;

    if (
      this.props.errorCount >= ERROR_THRESHOLD &&
      !this.state.errorVisible &&
      !this.state.offline
    ) {
      this.setState(
        {
          errorVisible: true
        },
        () => {
          this.props.addAlertMessage({
            message:
              "It looks like we're having trouble connecting to our API – please hold or try refreshing the page.",
            timeout: false,
            id: 'connection-error',
            dismissable: false
          });
        }
      );
    }

    if (
      (this.state.offline && this.state.errorVisible) ||
      (this.props.errorCount < ERROR_THRESHOLD && this.state.errorVisible)
    ) {
      this.props.removeAlertMessage({
        id: 'connection-error'
      });

      this.setState({
        errorVisible: false
      });
    }
  }

  render() {
    return null;
  }
}

ConnectionStalker.propTypes = {
  errorCount: PropTypes.number.isRequired,
  addAlertMessage: PropTypes.func.isRequired,
  removeAlertMessage: PropTypes.func.isRequired,
  checkForRadioUpdate: PropTypes.func.isRequired
};

export default connect(
  state => ({
    errorCount: state.ui.errorCount
  }),
  {
    addAlertMessage: addAlertMessageAction,
    removeAlertMessage: removeAlertMessageAction,
    checkForRadioUpdate: checkForRadioUpdateAction
  }
)(ConnectionStalker);
