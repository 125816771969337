import { createReducer } from 'redux-act';

import { togglePromoKeyboardShortcuts, setUiPersist } from 'redux/actions/uiPersist';
import { AUDIO_FILE_TYPES } from 'config/constants';

const initialState = {
  promoKeyboardShortcuts: true,
  preferredAudioDownloadType: AUDIO_FILE_TYPES.MP3.VALUE
};

export default createReducer(
  {
    [togglePromoKeyboardShortcuts]: state => ({
      ...state,
      promoKeyboardShortcuts: !state.promoKeyboardShortcuts
    }),
    [setUiPersist]: (state, data) => ({
      ...state,
      ...data
    })
  },
  initialState
);
