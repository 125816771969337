import { createAction, batch } from 'redux-act';

import { searchAlgolia } from 'api/algolia';
import * as labelApi from 'api/label';

import { ALGOLIA, ASYNC_OPERATIONS } from 'config/constants';
import { createAsyncActions } from './utilities';

export const addLabels = createAction('LABELS_ADD', labels => ({ labels }));

export const updateLabel = createAction('LABEL_UPDATE');

export const fetchLabel = createAsyncActions('LABEL', ASYNC_OPERATIONS.FETCH);

export const fetchLabelArtists = createAsyncActions(
  'LABEL_ARTISTS',
  ASYNC_OPERATIONS.FETCH
);

export const fetchLabelTopArtists = createAsyncActions(
  'LABEL_ARTISTS_TOP',
  ASYNC_OPERATIONS.FETCH
);

// Async Actions
export const getLabel = id => dispatch => {
  dispatch(fetchLabel.request({ id }));

  return labelApi
    .getLabel(id)
    .then(({ data }) => {
      dispatch(fetchLabel.success({ labels: data }));
      return data;
    })
    .catch(error => dispatch(fetchLabel.error({ id, error })));
};

/**
 * [getLabelAlgolia] - fetch label(s) from algolia by their id
 *
 * @param {number || number[]} labelId - label id or array of label ids
 * @returns {object[]} labels
 */

export const getLabelAlgolia = labelId => dispatch => {
  const labelIds = Array.isArray(labelId) ? labelId : [labelId];
  const requestActions = labelIds.map(id => fetchLabel.request({ id }));
  dispatch(batch(requestActions));

  const facetFilters = [labelIds.map(id => `id:${id}`)];
  return searchAlgolia({
    index: ALGOLIA.LABEL_INDEX,
    facetFilters
  })
    .then(response => {
      if (Array.isArray(labelId)) {
        const labels = response.hits;

        if (labels.length !== labelIds.length) {
          throw new Error(`One or more label(s) could not be found`);
        }

        dispatch(fetchLabel.success({ labels }));
        return labels;
      }

      const label = response.hits[0];
      if (!label) {
        throw {
          message: `Label with id ${labelId} could not be found`,
          status: 404
        };
      }

      dispatch(fetchLabel.success({ labels: label }));
      return label;
    })
    .catch(error => {
      const errorActions = labelIds.map(id => fetchLabel.error({ id, error }));
      dispatch(batch(errorActions));
      throw error;
    });
};

export const getLabelArtists = id => dispatch => {
  dispatch(fetchLabelArtists.request({ id }));

  return labelApi
    .getLabelArtists(id)
    .then(({ data }) => {
      dispatch(fetchLabelArtists.success({ id, data, normalize: false }));
      return data;
    })
    .catch(error => dispatch(fetchLabelArtists.error({ id, error })));
};

export const getLabelTopArtists = (id, options) => dispatch => {
  dispatch(fetchLabelTopArtists.request({ id }));

  return labelApi
    .getLabelTopArtists(id, options)
    .then(({ data }) => {
      dispatch(fetchLabelTopArtists.success({ id, data, normalize: false }));
      return data;
    })
    .catch(error => dispatch(fetchLabelTopArtists.error({ id, error })));
};
