import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { AUDIO_TYPES, COLORS, PLAYER_STATES } from 'config/constants';
import useProtonPlayer from 'hooks/useProtonPlayer';
import pauseIcon from 'images/icon-pause.svg';
import playIcon from 'images/icon-play.svg';

import { StyledButtonTombstone } from './styled';

/**
 * [TrackPlayTile]
 * Image button that renders image and handle player state based on track passed
 * @prop {object} track - Track object to render player button from
 * @prop {number} playerId - audio id set in player
 * @prop {enum} playerState - see `PLAYER_STATES` ('PLAYING', 'PAUSED')
 * @prop {enum} playerAudioType - see `AUDIO_TYPES`
 */

const StyledWrapper = styled.div`
  ${({ $active }) =>
    $active &&
    `
    padding: 0.1rem;
    border-radius: 2px;
    border: solid 1px ${COLORS.PRIMARY};
    display: inline-block
  `}
`;

const StyledOverlay = styled.div`
  background: black;
  border-radius: 1px;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  /* Since an overlay, was conflicting with hover event in track table */
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.8s;
  width: 100%;

  /* This is the class of the parent row div in <TracksTableDesktop> */
  .Table__row:hover & {
    opacity: 0.8;
  }

  ${({ $isPlaying }) => $isPlaying && `opacity: 0.8`};
`;

const StyledTrackTile = styled.div`
  height: ${({ $width }) => ($width ? `${$width}px` : '4rem')};
  margin: 0.2rem; // offset border / padding applied to active button
  position: relative;
  width: ${({ $width }) => ($width ? `${$width}px` : '4rem')};

  ${({ $active }) =>
    $active &&
    `
    margin: 0;
    `}
`;

const StyledCoverArt = styled.img`
  border-radius: 1px;
  height: 100%;
`;

const StyledIcon = styled.img.attrs(({ $isPlaying }) => ({
  src: $isPlaying ? pauseIcon : playIcon,
  'data-testid': $isPlaying ? 'pause-icon' : 'play-icon'
}))`
  width: 1.6rem;
`;

const TrackPlayTile = ({ track, width }) => {
  const player = useProtonPlayer();
  const playing = player.currentTrack;

  const isActive = track.id === playing?.id && playing?.type === AUDIO_TYPES.TRACK;
  const isPlaying = isActive && player.state === PLAYER_STATES.PLAYING;
  const imageUrl = track.release.cover_art.thumb.url;

  return (
    <StyledWrapper $active={isActive}>
      <StyledTrackTile $active={isActive} $width={width}>
        <StyledCoverArt src={imageUrl} alt="Release cover art" />

        <StyledOverlay $isPlaying={isPlaying}>
          <StyledIcon $isPlaying={isPlaying} />
        </StyledOverlay>
      </StyledTrackTile>
    </StyledWrapper>
  );
};

TrackPlayTile.Tombstone = ({ width = 40 }) => <StyledButtonTombstone $width={width} />;

TrackPlayTile.propTypes = {
  track: PropTypes.shape({
    id: PropTypes.number,
    release: PropTypes.shape({
      cover_art: PropTypes.shape()
    })
  }),
  width: PropTypes.number
};

export default TrackPlayTile;
