import { graphql } from 'gql';

export const ReleaseDspQuery = graphql(`
  query getReleaseDSPInfo($id: ID!) {
    release(id: $id) {
      storeInfo {
        name
        url
        status
        __typename
      }
    }
  }
`);
