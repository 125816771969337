// The number of samples taken from the source material.
const STATIC_WAVEFORM_LENGTH = 1801;

// Generates waveform data for a simple wave to be used as a placeholder when
// the real waveform does not exist.
export const createPlaceholderWaveform = () => {
  const waveformResult = [];

  for (let i = 0; i < STATIC_WAVEFORM_LENGTH; i++) {
    waveformResult.push(Math.abs(((i + 5) % 100) - 50));
  }

  return waveformResult;
};
