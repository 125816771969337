import algoliasearch from 'algoliasearch';
import { SearchOptions } from '@algolia/client-search';
import { ALGOLIA, AUDIO_TYPES, AlgoliaIndex } from 'config/constants';
import { ObjectValues } from 'types';

const { APP_ID, API_KEY } = ALGOLIA;

/*
 * [searchAlgolia]
 * Generic function that searches index in algolia using passed index
 *
 * @param {string} index - (Required)
 * @param {string} [query]
 * @param {string} [filters]
 * @param {string[]} [facetFilters] - should match format in algolia docs
 * https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
 */

export type SearchAlgoliaParams = {
  index: AlgoliaIndex;
  limit?: number;
  searchableAttributes?: string[];
} & Omit<SearchOptions, 'restrictSearchableAttributes' | 'hitsPerPage'>;

export type BaseHit = { id: string | number };

const INDEX_AUDIO_TYPE = {
  [ALGOLIA.MIX_INDEX]: AUDIO_TYPES.MIX,
  [ALGOLIA.TRACK_INDEX]: AUDIO_TYPES.TRACK
} as const;

export const searchAlgolia = async <TData extends BaseHit = BaseHit>({
  index,
  facets = [],
  facetFilters = [],
  filters,
  limit = 100,
  page = 0,
  query = '',
  searchableAttributes = [],
  ...rest
}: SearchAlgoliaParams) => {
  const client = algoliasearch(APP_ID, API_KEY);
  const algoliaIndex = client.initIndex(index);

  const response = await algoliaIndex.search<TData>(query, {
    facets,
    page,
    hitsPerPage: limit,
    restrictSearchableAttributes: searchableAttributes,
    facetFilters,
    filters,
    typoTolerance: 'min',
    ...rest
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const audioType: ObjectValues<typeof INDEX_AUDIO_TYPE> | undefined =
    INDEX_AUDIO_TYPE[index];

  // if audio data is being returned, add '__audioType' attribute
  if (audioType) {
    return {
      ...response,
      hits: response.hits.map(hit => ({
        ...hit,
        __audioId: hit.id,
        __audioType: audioType
      }))
    };
  }

  return response;
};
