import { call, put, select, takeEvery } from 'redux-saga/effects';

import { addAlertMessage } from 'redux/actions/ui';
import { downloadTrack as downloadTrackAction } from 'redux/actions/tracks';
import { updateTrackReaction } from 'redux/actions/promoReleases';
import { selectLastKnownTrackRating } from 'redux/selectors/tracks';
import { selectIsTrackOnPromo } from 'redux/selectors/promoReleases';

import { downloadTrack } from 'api/track';

import { en } from 'config/constants/localization';

function* downloadTrackSaga({ payload, meta = {} }) {
  const { onError, onSuccess, onFinally } = meta;
  const { track, filetype, location } = payload;

  try {
    const trackId = track.id;

    const response = yield call(downloadTrack, {
      trackId,
      filetype,
      location
    });

    // NOTE: Currently we don't track downloads outside of promo.  We might in the future,
    // but would need to (and probably should anyways) incorporate in a separate reducer.
    const isPromoTrack = yield select(selectIsTrackOnPromo, { trackId });
    if (isPromoTrack) {
      // Update rating with manually set downloaded_at value
      const existingRating = yield select(selectLastKnownTrackRating, trackId);
      yield put(
        updateTrackReaction.success({
          trackId,
          reaction: {
            ...existingRating,
            downloaded_at: new Date().toISOString()
          }
        })
      );
    }

    // Nothing to do with the success response at this point in time.
    // Should change once we add handling for actual download states.
    if (onSuccess) onSuccess();

    return response;
  } catch (e) {
    if (onError) onError(e);

    yield put(addAlertMessage(en.errors.generic));
    return e;
  } finally {
    if (onFinally) onFinally();
  }
}

export function* watchDownloadTrack() {
  yield takeEvery(downloadTrackAction.call().type, downloadTrackSaga);
}
