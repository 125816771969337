import { batch } from 'react-redux';

import * as api from 'api';
import { ALGOLIA } from 'config/constants';
import { createAsyncActions } from './utilities';
import { ASYNC_OPERATIONS } from 'config/constants';

export const fetchShowActions = createAsyncActions('SHOWS', ASYNC_OPERATIONS.FETCH);

export const fetchShow = showId => dispatch => {
  dispatch(fetchShowActions.request({ id: showId }));

  return api
    .getShow(showId)
    .then(response => {
      const show = response.data;
      dispatch(fetchShowActions.success({ data: show, id: showId }));
      return show;
    })
    .catch(e => {
      dispatch(fetchShowActions.error({ id: showId, error: e }));
    });
};

export const topShowsActions = createAsyncActions('TOP_SHOWS', ASYNC_OPERATIONS.FETCH);

// Async Actions
export const fetchTopShows =
  ({ sinceDaysAgo }) =>
  dispatch => {
    dispatch(topShowsActions.request);

    return api
      .getTopShows({ sinceDaysAgo })
      .then(({ data }) => {
        dispatch(topShowsActions.success({ data }));
      })
      .catch(error => dispatch(topShowsActions.error({ error })));
  };

/**
 * [fetchShowAlgolia]
 *
 * @param {number || number[]} showId
 * @returns {object[]} shows
 */

export const fetchShowAlgolia = showId => dispatch => {
  const showIds = Array.isArray(showId) ? showId : [showId];

  const requestActions = showIds.map(id => fetchShowActions.request({ id }));
  batch(() => requestActions.forEach(dispatch));

  const facetFilters = [showIds.map(id => `objectID:${id}`)];
  return api
    .searchAlgolia({
      index: ALGOLIA.SHOW_INDEX,
      facetFilters
    })
    .then(response => {
      // handle multiple artists
      if (Array.isArray(showId)) {
        const shows = response.hits;

        if (shows.length !== showIds.length) {
          throw new Error(`One or more artist(s) could not be found`);
        }

        const successActions = shows.map(data =>
          fetchShowActions.success({ id: showId, data })
        );
        batch(() => successActions.forEach(dispatch));
        return shows;
      }

      // handle single artist
      const show = response.hits[0];
      if (!show) throw new Error(`Show with id ${showId} could not be found`);
      dispatch(fetchShowActions.success({ id: showId, data: show }));
      return show;
    })
    .catch(error => {
      const errorActions = showIds.map(id => fetchShowActions.error({ id, error }));
      batch(() => errorActions.forEach(dispatch));
    });
};
