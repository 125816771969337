import React from 'react';

import { TombstoneText } from 'components/Tombstone';
import PlayerButton from 'components/PlayerButton';
import PlayerVolume from './PlayerVolume';
import TracklistTrigger from './PlayerTracklist/TracklistTrigger';

const PlayerContainerTombstone = () => (
  <div className="Player">
    <div className="Player__content">
      <div className="Player__button-container">
        <PlayerButton classes="PlayerButton--primary" disabled />
      </div>

      <div className="Player__meta">
        <div className="Player__artist">
          <TombstoneText
            size={TombstoneText.SIZES.MEDIUM}
            color={TombstoneText.COLORS.DARK}
          />
        </div>

        <div className="Player__show">
          <TombstoneText
            size={TombstoneText.SIZES.XSMALL}
            color={TombstoneText.COLORS.DARK}
            className="mt-1"
          />
        </div>
      </div>

      <div className="Waveform" />

      <TracklistTrigger tracklistCount={0} />

      <div className="Player__volume-trigger-container hidden-xs hidden-sm">
        <PlayerVolume />
      </div>
    </div>
  </div>
);

export default PlayerContainerTombstone;
