import * as acknowledgementsApi from 'api/acknowledgements';
import { createAsyncActions } from './utilities';
import { ASYNC_OPERATIONS } from 'config/constants';

export const fetchAcknowledgementsActions = createAsyncActions(
  'ACKNOWLEDGEMENTS',
  ASYNC_OPERATIONS.FETCH
);

export const createAcknowledgementActions = createAsyncActions(
  'ACKNOWLEDGEMENT',
  ASYNC_OPERATIONS.CREATE
);

export const fetchAcknowledgements = () => dispatch => {
  const { request, success, error } = fetchAcknowledgementsActions;

  dispatch(request());

  return acknowledgementsApi
    .getAcknowledgements()
    .then(({ data }) => {
      dispatch(success({ data }));
      return data;
    })
    .catch(e => {
      dispatch(error({ error: e }));
    });
};

/**
 * [createAcknowledgement] - creates a new acknowledgment record for a given message type.
 * Scoped to current user.
 *
 * @param {string} messageType
 * @returns {object} acknowledgement record
 */

export const createAcknowledgement = messageType => dispatch => {
  const { success } = createAcknowledgementActions;

  return acknowledgementsApi
    .createAcknowledgement(messageType)
    .then(response => {
      console.warn('acknowledgementsApi.createAcknowledgement', response);
      const { data } = response;
      dispatch(success({ id: data.id, data }));
      return data;
    })
    .catch(e => {
      throw e;
    });
};
