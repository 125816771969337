import React from 'react';

import useBreakpoint from 'hooks/useBreakpoint';
import { BREAKPOINTS } from '../config/constants';

interface ResponsiveScreenProps {
  breakpoint: number;
  down?: boolean;
  children: React.ReactNode;
}

const ResponsiveScreen = ({ breakpoint, down, children }: ResponsiveScreenProps) => {
  const direction = down ? useBreakpoint.DIRECTIONS.DOWN : useBreakpoint.DIRECTIONS.UP;
  const shouldRender = useBreakpoint(breakpoint, direction);

  if (shouldRender) {
    return <>{children}</>;
  }

  return null;
};

interface StaticComponents {
  SMALL: React.FC<Omit<ResponsiveScreenProps, 'breakpoint'>>;
  MEDIUM: React.FC<Omit<ResponsiveScreenProps, 'breakpoint'>>;
  LARGE: React.FC<Omit<ResponsiveScreenProps, 'breakpoint'>>;
  X_LARGE: React.FC<Omit<ResponsiveScreenProps, 'breakpoint'>>;
}

const Screen: StaticComponents = {
  // Small devices (landscape phones, 576px and up)
  SMALL: props => <ResponsiveScreen {...props} breakpoint={BREAKPOINTS.SM} />,
  // Medium devices (tablets, 768px and up)
  MEDIUM: props => <ResponsiveScreen {...props} breakpoint={BREAKPOINTS.MD} />,
  // Large devices (desktops, 992px and up)
  LARGE: props => <ResponsiveScreen {...props} breakpoint={BREAKPOINTS.LG} />,
  // Extra large devices (large desktops, 1200px and up)
  X_LARGE: props => <ResponsiveScreen {...props} breakpoint={BREAKPOINTS.XL} />
};

export default Screen;
