import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'config/store';
import { getProtonJwtPayload } from 'helpers';

export const selectJwt = (state: RootState) => state.token.jwt;

/**
 * Returns a decoded jwt object. If no jwt or if jwt is invalid, returns a jwt object
 * with null values rather than throwing an exception
 */
export const selectJwtJson = createSelector([selectJwt], jwt => getProtonJwtPayload(jwt));

export const selectIsJwtExpired = createSelector([selectJwtJson], ({ exp }) => {
  if (!exp) return true;
  const currentTime = Date.now() / 1000;
  const remaining = exp - currentTime;
  return remaining <= 0;
});
