import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';

import { clearUser } from 'redux/actions/user';
import { fetchPaginatedDataActions } from 'redux/actions/utilities';
import {
  handlePaginationFetchSuccess,
  handlePaginationFetchError,
  handlePaginationFetchRequest
} from './utilities';

const byId = createReducer(
  {
    [fetchPaginatedDataActions.success]: (state, payload) =>
      handlePaginationFetchSuccess(state, payload, { normalizeReducer: 'feedback.byId' }),
    [clearUser]: () => ({})
  },
  {}
);

const createEntityReducer = paginationReducer => {
  const paginationConfig = { paginationReducer };

  return createReducer(
    {
      [fetchPaginatedDataActions.request]: (state, payload) =>
        handlePaginationFetchRequest(state, payload, paginationConfig),
      [fetchPaginatedDataActions.error]: (state, payload) =>
        handlePaginationFetchError(state, payload, paginationConfig),
      [fetchPaginatedDataActions.success]: (state, payload) =>
        handlePaginationFetchSuccess(state, payload, paginationConfig),
      [clearUser]: () => ({})
    },
    {}
  );
};

const byLabelId = createEntityReducer('feedback.byLabelId');
const byReleaseId = createEntityReducer('feedback.byReleaseId');
const byTrackId = createEntityReducer('feedback.byTrackId');
const byArtistIdGiven = createEntityReducer('feedback.byArtistIdGiven');
const byArtistIdReceived = createEntityReducer('feedback.byArtistIdReceived');

export default combineReducers({
  byId,
  byLabelId,
  byReleaseId,
  byTrackId,
  byArtistIdGiven,
  byArtistIdReceived
});
