import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { generatePath } from 'react-router-dom';

import { USER_PROFILE_TYPES } from 'config/constants';
import routeMap from 'config/routes';
import { isUserArtistMemberForArtist, isUserLabelManager } from 'helpers';
import { selectIsAdminUser, selectUser } from 'redux/selectors';

export const USER_VERIFICATION_ROLES = {
  ADMIN: 'admin',
  ARTIST_MEMBER: 'artist_member',
  LABEL_MANAGER: 'label_manager'
};

export const getUserFormValidationForRole = role => {
  const defaultValidations = {
    pro_email: Yup.string()
      .email('Please enter a valid email.')
      .required('An email is required.'),
    promo_email: Yup.string().email('Please enter a valid email.'),
    first_name: Yup.string().required('First name is required.'),
    last_name: Yup.string().required('Last name is required.'),
    city: Yup.string(),
    registration_email: Yup.string().email('Please enter a valid email.'),
    country: Yup.string().required('Country is required.'),
    username: Yup.string()
  };

  const { ADMIN, LABEL_MANAGER } = USER_VERIFICATION_ROLES;

  // Don't require country if label manager / admin role
  if ([ADMIN, LABEL_MANAGER].includes(role)) {
    return Yup.object().shape({
      ...defaultValidations,
      country: Yup.string()
    });
  }

  return Yup.object().shape(defaultValidations);
};

/**
 * [getProUserVerificationPath] - returns path to pro user verification page for the
 * current logged in user OR can manually pass the proUserId and artistId.
 *
 * @param {object} [currentUser] - current logged in user data. Pass if creating pro user verification link for current
 * user (who must be a pro user)
 * @param {number} [proUserId] - must be passed if current user is not passed
 * @param {number} [artistId] - must be passed if current user is not passed
 * @returns {string}
 */

export const getProUserVerificationPath = ({
  currentUser = { profiles: [] },
  proUserId: proUserIdArg,
  artistId: artistIdArg,
  destinationURL: destinationURLArg
}) => {
  const proUserId = currentUser.pro_user_id || proUserIdArg;

  const proUserPath = generatePath(routeMap.users.root + routeMap.users.verification, {
    id: proUserId
  });

  // get user's first artist since we can't be sure which should be verified
  const artist = currentUser.profiles.find(
    ({ type }) => type === USER_PROFILE_TYPES.ARTIST
  );
  const artistId = artistIdArg || artist?.id || '';

  const searchParams = new URLSearchParams({ artistId });
  if (destinationURLArg) {
    searchParams.set('dest', destinationURLArg);
  }
  return `${proUserPath}?${searchParams}`;
};

export const useGetUserRole = artistId => {
  const user = useSelector(selectUser);
  const isArtistMember = isUserArtistMemberForArtist(user, artistId);
  const isLabelManager = isUserLabelManager(user);
  const isAdminUser = useSelector(selectIsAdminUser);

  return useMemo(() => {
    // return USER_VERIFICATION_ROLES.ADMIN; // TODO: REMOVE - debugging only

    if (isArtistMember) return USER_VERIFICATION_ROLES.ARTIST_MEMBER;
    if (isAdminUser) return USER_VERIFICATION_ROLES.ADMIN;
    if (isLabelManager) return USER_VERIFICATION_ROLES.LABEL_MANAGER;
    return null;
  }, [isArtistMember, isAdminUser, isLabelManager]);
};
