import {
  followEntityApi,
  getFollowedEntitiesForUserApi,
  unfollowEntityApi
} from 'api/followed';
import { createAsyncActions } from './utilities';
import { ASYNC_OPERATIONS } from 'config/constants';

export const fetchFollowedEntityActions = createAsyncActions(
  'FOLLOWED_ENTITY',
  ASYNC_OPERATIONS.FETCH
);

export const createFollowedEntityActions = createAsyncActions(
  'FOLLOWED_ENTITY',
  ASYNC_OPERATIONS.CREATE
);

export const deleteFollowedEntityActions = createAsyncActions(
  'FOLLOWED_ENTITY',
  ASYNC_OPERATIONS.DELETE
);

export const getFollowedEntities = entityType => dispatch => {
  dispatch(fetchFollowedEntityActions.request({ entityType }));

  return getFollowedEntitiesForUserApi(entityType).then(({ data }) => {
    dispatch(fetchFollowedEntityActions.success({ data }));
  });
};

/**
 * [followEntity]
 *
 * @param {string} entityType
 * @param {number} entityId
 * @returns {object}
 */

export const followEntity = (entityType, entityId) => dispatch => {
  dispatch(createFollowedEntityActions.request({ entityId, entityType }));

  return followEntityApi(entityType, entityId)
    .then(({ data }) => {
      dispatch(createFollowedEntityActions.success({ entityId, entityType, data }));
      return data;
    })
    .catch(error => {
      dispatch(createFollowedEntityActions.error({ entityId, entityType, error }));
      throw error;
    });
};

/**
 * [unfollowEntity]
 *
 * @param {string} entityType
 * @param {number} entityId
 * @returns {object}
 */

export const unfollowEntity = (entityType, entityId) => dispatch => {
  dispatch(deleteFollowedEntityActions.request({ entityId, entityType }));

  return unfollowEntityApi(entityType, entityId)
    .then(() => {
      dispatch(
        deleteFollowedEntityActions.success({
          entityId,
          entityType
        })
      );
    })
    .catch(error => {
      dispatch(deleteFollowedEntityActions.error({ entityId, entityType, error }));
      throw error;
    });
};
