import React from 'react';

import { COLORS } from 'config/constants';
import { ICON_PROP_TYPES } from './config';

const Pacman = ({ color = COLORS.PRIMARY, size = 32, className, style }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    style={style}
  >
    <title>Pacman loader</title>

    <path d="M0 50A50 50 0 1 0 100 50" fill={color} transform="rotate(30 50 50)">
      <animateTransform
        attributeName="transform"
        type="rotate"
        dur="0.45s"
        repeatCount="indefinite"
        from="30 50 50"
        to="30 50 50"
        values="30 50 50;0 50 50;30 50 50"
      />
    </path>
    <path d="M0 50A50 50 0 1 1 100 50" fill={color} transform="rotate(-30 50 50)">
      <animateTransform
        attributeName="transform"
        type="rotate"
        dur="0.45s"
        repeatCount="indefinite"
        from="-30 50 50"
        to="-30 50 50"
        values="-30 50 50;0 50 50;-30 50 50"
      />
    </path>
  </svg>
);

Pacman.propTypes = ICON_PROP_TYPES;

export default Pacman;
